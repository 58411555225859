import React, { useState, useEffect } from "react";
import { constant } from "../../utility/constant";
import { getDataApi, postDataApi, deleteDataApi } from "../../utility/apiCall";
import featuredArtist from "../../assets/Images/featuredArtist.png";
import { colors } from "../../utility/colors";
import HomeFeaturedArtistsList from "../../components/HomeFeaturedArtistsList/HomeFeaturedArtistsList";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { successToast } from "../../utility/toastMessage";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { hashCodeUrl } from "../../utility/EncodeAndDecodeUrl";
import { AiOutlineHeart, AiFillHeart, AiOutlineMore } from "react-icons/ai";
import { VerifyLoginExistOrNot } from "../../utility/VerifyLoginExistOrNot";
import mike from "../../assets/Images/mike.svg";
import mikeFill from "../../assets/Images/mikeFill.svg";
import { FaRegCommentDots } from "react-icons/fa";
import AllArtists from "../../components/AllArtists/AllArtists";


function FeaturedArtists() {
  let history = useHistory();
  var path = useLocation();
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const [showViewMore, setShowViewMore] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(24);
  const [artistList, setArtistList] = useState([]);
  const [artistLikesList, setArtistLikesList] = useState([]);
  const [artistLikesData, setArtistLikesData] = useState([]);
  const [artistResetCount, setArtistResetCount] = useState(0);
  const [artId, setArtId] = useState(0);
  const [mainArtist, setMainArtist] = useState(undefined);
  // const getArtists = async (pageNumber) => {
  //   let resArtists = await getDataApi(
  //     constant.baseURL +
  //     // "user?filter=makeFeatured||$eq||1&filter=usertype||$eq||Artist&filter=status||$eq||1&filter=isDeleted||$eq||0&page=" +
  //     "user?filter=usertype||$eq||Artist&filter=status||$eq||1&filter=isDeleted||$eq||0&page=" +
  //     pageNumber +
  //     "&limit=" +
  //     limit
  //   );
  //   // console.log(
  //   //   constant.baseURL +
  //   //   // "user?filter=makeFeatured||$eq||1&filter=usertype||$eq||Artist&filter=status||$eq||1&filter=isDeleted||$eq||0&page=" +
  //   //   "user?filter=usertype||$eq||Artist&filter=status||$eq||1&filter=isDeleted||$eq||0&page=" +
  //   //   pageNumber +
  //   //   "&limit=" +
  //   //   limit
  //   // );
  //   console.log(resArtists);
  //   if (
  //     resArtists !== undefined &&
  //     resArtists.data !== undefined &&
  //     resArtists.data.length > 0
  //   ) {
  //     let artistIds = "";

  //     if (pageNumber == 1) {
  //       resArtists.data.map((item) => {
  //         artistIds += item.id + ",";
  //       });
  //       setArtistList([...resArtists.data]);
  //       getArtistLikesByUser(artistIds.substr(0, artistIds.lastIndexOf(",")));
  //     } else {
  //       setArtistList([...artistList, ...resArtists.data]);
  //       resArtists.data.map((item) => {
  //         artistIds += item.id + ",";
  //       });
  //       artistList.map((item) => {
  //         artistIds += item.id + ",";
  //       });
  //       getArtistLikesByUser(artistIds.substr(0, artistIds.lastIndexOf(",")));
  //     }
  //     //show hide view more page
  //     if (resArtists.page < resArtists.pageCount) {
  //       setShowViewMore(true);
  //     } else {
  //       setShowViewMore(false);
  //     }
  //   }
  // };
  const navigateToArtistDetails = (index, isMainArtist) => {
    if (isMainArtist === true && mainArtist !== undefined) {
      history.push({
        pathname: `FeaturedArtistDetails/${hashCodeUrl(mainArtist.id)}/`,
        state: {
          id: mainArtist.id,
          from: "featureartists"
        },
      });
    } else {
      if (artistList.length > 0) {
        let indexItem = artistList[index];
        history.push({
          pathname: `FeaturedArtistDetails/${hashCodeUrl(indexItem.user_id)}/`,
          state: {
            id: indexItem.user_id,
            from: "featureartists"
          },
        });
      }
    }
    window.scrollTo(0, 0);
  };
  // const onClickViewMore = () => {
  //   setPage(page + 1);
  //   getArtists(page + 1);
  // };
  // const getArtistLikesByUser = async (str) => {
  //   if (currentUserInfo !== undefined && str !== "") {
  //     let resArtistLikes = await getDataApi(
  //       constant.baseURL +
  //       "likes?filter=user.id||$eq||" +
  //       currentUserInfo.id +
  //       "&filter=artist.id||$in||" +
  //       str
  //     );
  //     console.log("resArtistLikes: ", resArtistLikes);
  //     let artistLikeItems = [];
  //     if (resArtistLikes !== undefined) {
  //       resArtistLikes.map((m) => {
  //         artistLikeItems.push(m.artist.id);
  //       });
  //       console.log("likeItems: ", artistLikeItems);
  //       setArtistLikesList(resArtistLikes);
  //       setArtistLikesData(resArtistLikes);
  //     }
  //   }
  // };
  // const createArtistLike = async (artistId) => {
  //   if (VerifyLoginExistOrNot()) {
  //     if (currentUserInfo !== undefined) {
  //       let jsonData = { user: currentUserInfo.id, artist: artistId };
  //       var formBody = [];
  //       for (var property in jsonData) {
  //         var encodedKey = encodeURIComponent(property);
  //         var encodedValue = encodeURIComponent(jsonData[property]);
  //         formBody.push(encodedKey + "=" + encodedValue);
  //       }
  //       formBody = formBody.join("&");
  //       var authHeader = {
  //         Accept: "application/json",
  //         "Content-Type": "application/x-www-form-urlencoded",
  //       };
  //       let respArtistLike = await postDataApi(
  //         constant.baseURL + "likes",
  //         formBody,
  //         authHeader
  //       );
  //       if (
  //         respArtistLike !== undefined &&
  //         respArtistLike.status !== undefined &&
  //         respArtistLike.status === 201
  //       ) {
  //         setLikes(artistId, respArtistLike.data);
  //       }
  //     }
  //   }
  // };

  // const resetArtistLike = async (artistId, likeId) => {
  //   if (VerifyLoginExistOrNot()) {
  //     var authHeader = {
  //       Accept: "application/json",
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     };
  //     let resp = await deleteDataApi(
  //       constant.baseURL + "likes/" + likeId,
  //       authHeader
  //     );
  //     if (
  //       resp !== undefined &&
  //       resp.status !== undefined &&
  //       resp.status === 200
  //     ) {
  //       setLikes(artistId);
  //     }
  //   }
  // };
  // const setLikes = (id, data) => {
  //   setArtId(id);
  //   setArtistResetCount(artistResetCount + 1);
  // };
  // const renderLikes = (id) => {
  //   if (artistLikesList.length !== 0) {
  //     let flt = artistLikesData.filter(
  //       (f) => f.artist !== undefined && f.artist.id === id
  //     );
  //     if (flt.length > 0) {
  //       return (
  //         <img
  //           src={mikeFill}
  //           key={id}
  //           onClick={() => {
  //             if (VerifyLoginExistOrNot()) resetArtistLike(id, flt[0].id);
  //             else {
  //               history.push({
  //                 pathname: `/login`,
  //                 state: {
  //                   prevPath: path.pathname,
  //                   userId:
  //                     currentUserInfo !== undefined ? currentUserInfo.id : 0,
  //                 },
  //               });
  //             }
  //           }}
  //           style={{ cursor: "pointer", width: 25 }}
  //         />
  //       );
  //     } else {
  //       return (
  //         <img
  //           src={mike}
  //           key={id}
  //           onClick={() => {
  //             if (VerifyLoginExistOrNot()) createArtistLike(id);
  //             else {
  //               history.push({
  //                 pathname: `/login`,
  //                 state: {
  //                   prevPath: path.pathname,
  //                   userId:
  //                     currentUserInfo !== undefined ? currentUserInfo.id : 0,
  //                 },
  //               });
  //             }
  //           }}
  //           style={{ cursor: "pointer", width: 25 }}
  //         />
  //       );
  //     }
  //   } else {
  //     return (
  //       <img
  //         src={mike}
  //         key={id}
  //         onClick={() => {
  //           if (VerifyLoginExistOrNot()) createArtistLike(id);
  //           else {
  //             history.push({
  //               pathname: `/login`,
  //               state: {
  //                 prevPath: path.pathname,
  //                 userId:
  //                   currentUserInfo !== undefined ? currentUserInfo.id : 0,
  //               },
  //             });
  //           }
  //         }}
  //         style={{ cursor: "pointer", width: 25 }}
  //       />
  //     );
  //   }
  // };
  // const renderArtists = () => {
  //   if (artistList.length > 0) {
  //     return artistList.map((item, index) => {
  //       return (
  //         <div
  //           className="col-6 col-md-3 col-lg-2"
  //           key={index}
  //           style={{ cursor: "pointer" }}
  //         >
  //           <img
  //             className="artist-image-annimation"
  //             style={{ width: "100%" }}
  //             onClick={() => navigateToArtistDetails(index)}
  //             src={
  //               item.urlImage !== undefined && item.urlImage !== "user.png"
  //                 ? constant.profileURL + item.urlImage
  //                 : constant.userImageURL
  //             }
  //             alt={item.firstName + " " + item.lastName}
  //           />
  //           <h6
  //             className="big-stage-song-name wrapText"
  //             title={item.firstName + " " + item.lastName}
  //           >
  //             {String(item.firstName + " " + item.lastName)}
  //           </h6>

  //           <div className="row big-stage-signer" style={{ marginBottom: 25 }}>
  //             <div className="col-8">
  //               {item.country !== undefined && item.country !== null
  //                 ? item.country.name
  //                 : ""}
  //             </div>
  //             <div style={{ marginLeft: -8 }} className="col-md-10 ">
  //               <div className="dis-flex justify-content-around">
  //                 <div>
  //                   {renderLikes(item.id)}
  //                   <span style={{ fontSize: "15px", paddingLeft: 5 }}>10</span>
  //                 </div>
  //                 <div>
  //                   <FaRegCommentDots
  //                     color="#b09b2f"
  //                     style={{ height: 20, width: 20 }}
  //                   />
  //                   <span style={{ fontSize: "15px", paddingLeft: 10 }}>
  //                     45
  //                   </span>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       );
  //     });
  //   } else {
  //     return (
  //       <div className="no-data-found" style={{ paddingLeft: 15 }}>
  //         {constant.noDataFoundLabel}
  //       </div>
  //     );
  //   }
  // };
  // useEffect(() => {
  //   //getArtists(page);
  // }, []);
  // useEffect(() => {
  //   let artistIds = "";
  //   artistList.map((item) => {
  //     artistIds += item.id + ",";
  //   });
  //   getArtistLikesByUser(artistIds.substr(0, artistIds.lastIndexOf(",")));
  // }, [artistResetCount]);
  const getMainArtist = async (pageNumber) => {
    let resMainArtist = await getDataApi(
      constant.baseURL +
      "user?filter=showMainArtist||$eq||1&filter=makeFeatured||$eq||1&filter=usertype||$eq||Artist&filter=status||$eq||1&filter=isDeleted||$eq||0&limit=1&sort=id,DESC"
    );
    //console.log("resMainArtist: ", resMainArtist);
    if (resMainArtist !== undefined && resMainArtist.length > 0) {
      setMainArtist(resMainArtist[0]);
    }
  };
  useEffect(() => {
    getMainArtist();
    //getArtists(page);
  }, []);
  return (
    <div>
      {/* <section className="community-room-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={featuredArtist}
                alt="Featured Artist"
              />
            </div>
            <div className="col-12 col-md-8">
              <div className="communicate-right-box">
                <div className="wrapText fs-40 fs-sm-30 mt-3 mt-md-0 fw-700">Listen Your</div>
                <div className="fs-25 fw-500 mt-3 mb-3">Favourite Artist</div>
                <p className="fs-15 fw-500 ">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren.
                </p>
                <AnchorLink href="#ExploreArtist">
                  <button className="btn btn-primary" type="submit">
                    Explore Artist
                  </button>
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="community-room-banner">
        <div className="container">
          <div className="row align-items-center" style={{ alignItems: "center" }}>
            <div className="col-12 col-md-4" style={{ textAlign: "center" }}>
              {/* <div> */}
              <img
                className="img-fluid"

                src={
                  mainArtist !== undefined &&
                    mainArtist !== null &&
                    mainArtist !== ""
                    ? constant.profileURL + mainArtist.urlImage
                    : featuredArtist
                }
                alt="Community"
              />
              {/* </div> */}
            </div>
            <div className="col-12 col-md-8">
              <div className="communicate-right-box" style={{ marginTop: "0" }}>
                <div className="wrapText fs-40 fs-sm-30 fw-700 mt-3 mt-md-0">Communicate With</div>
                <div className="fs-25 fw-500 mt-3 mb-3">
                {mainArtist !== undefined ? mainArtist?.firstName+" "+mainArtist?.lastName : "Your Favourite Artist"}
                </div>
                <p className="fs-15 fw-500 lh-20">
                 { mainArtist !== undefined ? mainArtist?.biography:`Here you can view the Top rated and Popular Artists and you can access their songs and videos directly.`}
                </p>
                {mainArtist !== undefined && (
                  <button
                    className="btn btn-primary"
                    onClick={() => navigateToArtistDetails(0, true)}
                  >
                    Explore Artist
                  </button>
                )}
                {mainArtist === undefined && (
                  <AnchorLink href="#ExploreArtist">
                    <button className="btn btn-primary">Explore Artist</button>
                  </AnchorLink>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <HomeFeaturedArtistsList isShowOnHome={false} height={170} width={230} /> */}
      <AllArtists isFeatured={true}></AllArtists>

      {/* <section className="artist" id="ExploreArtist">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-auto">
              <div
                className="featured-artists-title"
                style={{ marginTop: "10px" }}
              >
                Artists
              </div>
            </div>
            
          </div>
          <div className="row mt-3">{renderArtists()}</div>
          {artistList.length > 0 && showViewMore === true && (
            <div className="row justify-content-center mt-3">
              <div className="col-auto">
                <button className="btn dark" onClick={onClickViewMore}>
                  Show More
                </button>
              </div>
            </div>
          )}
        </div>
      </section> */}
    </div>
  );
}

export default FeaturedArtists;
