import React from "react";
import "./PageNotFound.css";
import smily from "../../assets/Images/smily.svg";

export default function PageNotFound() {
  return (





    <section class="emoji">
      <div class="container">
        <div class="page-not-found-content">
          <img
            className="img-fluid w-200 mt-3"
            src={smily}

          />
          <div class="main-font mt-3">404</div>
          <div class="page-not-found">Page Not Found</div>
          <div class="error-text">
            The Page you are looking for doesn't exist or another error occured,
            <br></br>
            <span class="highlight"> Go and </span>
            choose a <span class="highlight"> new direction.</span>
          </div>
        </div>
      </div>
    </section>
  );
}
