import React, { useState, useEffect } from "react";
import TinySlider from "tiny-slider-react";
import { constant } from "../../utility/constant";
import { getDataApi, postDataApi, deleteDataApi } from "../../utility/apiCall";
import { NavLink, useHistory } from "react-router-dom";
import moment from "moment";
import { hashCodeUrl } from "../../utility/EncodeAndDecodeUrl";
import mike from "../../assets/Images/mike.svg";
import { FaRegCommentDots } from "react-icons/fa";
import { VerifyLoginExistOrNot } from "../../utility/VerifyLoginExistOrNot";
import CommentsModal from "../CommentsModal/CommentsModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import mikeFill from "../../assets/Images/mikeFill.svg";
import { DisableButtonLoader } from "../../utility/CustomLoader";
import UploadHere from "../UploadHere/UploadHere";

const HomeNewsList = () => {
  let history = useHistory();
  var path = useLocation();
  const [showCommentTopNewsModal, setShowCommentTopNewsModal] = useState(false);
  const [commentTopNewsInputText, setCommentTopNewsInputText] = useState("");
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const [selectedTopNews, setselectedTopNews] = useState(undefined);
  const [update, setUpdate] = useState(false);
  const [newsList, setNewsList] = useState([]);
  const [commentsCount, setCommentsCount] = useState(0);
  const [newsLikesList, setNewsLikesList] = useState([]);
  const [newsLikesData, setNewsLikesData] = useState([]);
  const [newsResetCount, setNewsResetCount] = useState(0);
  const [disableItem, setDisableItem] = useState(false);
  const [nId, setNId] = useState(0);

  const settings = {
    controls: false,
    items: 5,
    loop: false,
    nav: false,
    mouseDrag: true,
    gutter: 10,
    responsiveClass: true,
    preventScrollOnTouch: "force",
    nested: "inner",
    responsive: {
      0: {
        items: 1,
        nav: false,
      },
      600: {
        items: 3,
        nav: false,
      },
      1000: {
        items: 4,
        nav: false,
        loop: false,
      },
    },
  };

  const getNews = async () => {

    let apiURL = constant.baseURL + "news/getLatestNewsWithUserLike" + '?page=' + 1 +
      '&limit=' + 20;
    if (currentUserInfo) {
      apiURL += "&userId=" + currentUserInfo.id;
    }
    let resNews = await getDataApi(apiURL);
    if (resNews !== undefined && resNews.data.length > 0) {
      //console.log("resNews", resNews);
      let itms = [];
      let newsIds = "";
      resNews.data.map((p, i) => {
        itms.push({
          id: p.id,
          name: p.title,
          img:
            p.urlImage !== undefined &&
              p.urlImage !== null &&
              p.urlImage !== ""
              ? constant.newsFileURL + p.urlImage
              : constant.musicURL,
          title: p.title,
          subtitle: p.subtitle,
          description: p.description,
          urlImage: p.urlImage,
          created: moment(p.created).format("DD-MMM-yyyy"),
          artistName:
            p.user !== undefined
              ? p.user.firstName + " " + p.user.lastName
              : "",
          likes: p.likes,
          comments: p.comments.length,
          userlike: p.userlike
        });
      });
      setNewsList(itms);

    }
  };
  const getNewsLikes = async (str) => {
    if (currentUserInfo !== undefined && str !== "") {
      let resNewsLikes = await getDataApi(
        constant.baseURL +
        "newslikes?filter=user.id||$eq||" +
        currentUserInfo?.id +
        "&filter=news.id||$in||" +
        str
      );
      if (resNewsLikes != undefined && resNewsLikes.length > 0) {
        setNewsLikesList(resNewsLikes);
        setNewsLikesData(resNewsLikes);
      }
      else {
        setNewsLikesList([]);
        setNewsLikesData([]);
      }
      setDisableItem(false);
    } else {
      setDisableItem(false);
    }
  };
  const createNewsLikes = async (newsId) => {
    if (VerifyLoginExistOrNot()) {
      setDisableItem(true);
      setNId(newsId);
      let jsonData = {
        user: currentUserInfo.id,
        news: newsId,
      };
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let respNewsLike = await postDataApi(
        constant.baseURL + "newslikes",
        formBody,
        authHeader
      );
      if (
        respNewsLike != undefined &&
        respNewsLike.status != undefined &&
        respNewsLike.status == 201 && respNewsLike.data !== undefined
      ) {
        setLikes(newsId, respNewsLike.data, undefined);
        setDisableItem(false);
        // console.log("ffffff")
        // setLikes(newsId);
      } else {
        setDisableItem(false);
      }
    } else {
      setDisableItem(false);
    }
  };
  const resetNewsLike = async (newsId, likeId) => {
    if (VerifyLoginExistOrNot()) {
      setDisableItem(true);
      setNId(newsId);
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resp = await deleteDataApi(
        constant.baseURL + "newslikes/" + likeId,
        authHeader
      );
      if (resp != undefined && resp.status != undefined && resp.status == 200) {
        setLikes(newsId, undefined, likeId);
        setDisableItem(false);
        // setLikes(newsId);
        // console.log("ffffff1111111")
      } else {
        setDisableItem(false);
      }
    } else {
      setDisableItem(false);
    }
  };
  // const setLikes = (id) => {
  //   setNewsResetCount(newsResetCount + 1);
  // };
  const navigateToNewsDetails = (index) => {
    let indexItem = newsList[index];
    history.push({
      // pathname: `NewsDetails/${hashCodeUrl(indexItem.id)}/${hashCodeUrl(
      //   indexItem.description
      // )}`,
      pathname: `NewsDetails/${hashCodeUrl(indexItem.id)}`,
      state: {
        id: indexItem.id,
      },
    });
    // history.push({
    //   pathname: `NewsDetails`,
    //   state: {
    //     id: indexItem.id,
    //   },
    // });
  };
  const setCommentUpdate = () => {
    setCommentsCount(commentsCount + 1);
  }
  // const createEpisodeLike = async (episodeId) => {
  //   if (currentUserInfo.id) {
  //     let jsonData = { user: currentUserInfo.id, news: episodeId };
  //     var formBody = [];
  //     for (var property in jsonData) {
  //       var encodedKey = encodeURIComponent(property);
  //       var encodedValue = encodeURIComponent(jsonData[property]);
  //       formBody.push(encodedKey + "=" + encodedValue);
  //     }
  //     formBody = formBody.join("&");
  //     var authHeader = {
  //       Accept: "application/json",
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     };
  //     let resBigStageLike = await postDataApi(
  //       constant.baseURL + "newslikes",
  //       formBody,
  //       authHeader
  //     );
  //     console.log(resBigStageLike)
  //     if (
  //       resBigStageLike?.data !== undefined
  //     ) {
  //       setLikes(episodeId, resBigStageLike.data, undefined);
  //     }
  //   }
  // else {
  //   history.push({
  //     pathname: `/login`,
  //     // state: {
  //     //   prevPath: path.pathname,
  //     //   userId: props.currentUserId,
  //     // },
  //   });
  //   }
  // };

  // const resetEpisodeLike = async (episodeId, likeId) => {
  //   console.log(episodeId,likeId)
  //   if (currentUserInfo.id) {
  //     var authHeader = {
  //       Accept: "application/json",
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     };
  //     let resp = await deleteDataApi(
  //       constant.baseURL + "newslikes/" + likeId,
  //       authHeader
  //     );
  //     if (
  //       resp !== undefined &&
  //       resp.status !== undefined &&
  //       resp.status === 200
  //     ) {
  //       setLikes(episodeId, undefined, likeId);
  //     }
  //   } else {
  //     history.push({
  //       pathname: `/login`,
  //       // state: {
  //       //   prevPath: path.pathname,
  //       //   userId: props.currentUserId,
  //       // },
  //     });
  //   }
  // };
  const setLikes = (id, data, likeId) => {
    let bgItems = [...newsList]
    //console.log(bgItems)
    if (data !== undefined) {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike.push(data);
          itm.likes.push(data);
        }
      });
    } else {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike = [];
          itm.likes.map((lkitm, index0) => {
            if (lkitm.id === likeId) {
              itm.likes.splice(index0, 1);
            }
          });
        }
      });
    }

    setNewsList(bgItems)

  };
  const renderLikes = (likeData) => {
    //console.log(likeData)
    if (likeData.userlike.length > 0) {
      return (
        <img className="w-24" src={mikeFill} alt="Unmike" style={{ cursor: "pointer" }}
          //onClick={() => resetEpisodeLike(likeData.id, likeData.userlike[0].id)}
          onClick={() => {
            if (VerifyLoginExistOrNot()) resetNewsLike(likeData.id, likeData.userlike[0].id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    } else {
      return (
        <img className="w-24" src={mike} alt="Mike" style={{ cursor: "pointer" }}
          //onClick={() => createEpisodeLike(likeData.id)} 
          onClick={() => {
            if (VerifyLoginExistOrNot()) createNewsLikes(likeData.id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    }
  };
  const renderNewsList = () => {
    if (newsList.length > 0) {
      return (
        <TinySlider settings={settings}>
          {newsList.map((item, index) => {
            return (
              <div
                className="item"
                key={index}
                style={{ width: "-webkit-fill-available" }}
              >
                <div className="card">
                  <img
                    className="card-img-top"
                    style={{
                      height: 159,
                      width: "-webkit-fill-available",
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      cursor: "pointer",
                    }}
                    onClick={() => navigateToNewsDetails(index)}
                    src={item.img}
                    alt="Card image cap"
                  />
                  <div className="event-date" style={{ bottom: 130 }}>
                    {item.created}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <h5
                          className="fs-16 mt-2 fw-600 wrapText "
                          title={
                            item.name !== undefined &&
                              item.name !== null &&
                              item.name !== ""
                              ? item.name
                              : ""
                          }
                        >
                          {item.name !== undefined &&
                            item.name !== null &&
                            item.name !== ""
                            ? String(item.name)
                            : ""}
                        </h5>
                        <p
                          className="fs-14 mt-3 fw-400 wrapText"
                          title={
                            item.description !== undefined &&
                              item.description !== null &&
                              item.description !== ""
                              ? item.description
                              : ""
                          }
                        >
                          {item.description !== undefined &&
                            item.description !== null &&
                            item.description !== ""
                            ? String(item.description)
                            : ""}
                        </p>
                        <div className="line"></div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-auto">
                        <div>
                          {disableItem && nId === item.id ? DisableButtonLoader() :
                            <>
                              {renderLikes(item)}{" "}
                              <span className="fs-14 fw-500 pl-1">
                                {item.likes.length}
                              </span>
                            </>
                          }
                        </div>
                      </div>
                      <div className="col-auto">
                        <div>
                          <FaRegCommentDots
                            color="#b09b2f"
                            title="Comments"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (VerifyLoginExistOrNot()) {
                                setselectedTopNews(item);
                                setShowCommentTopNewsModal(true);
                              } else {
                                history.push({
                                  pathname: `/login`,
                                  state: {
                                    prevPath: path.pathname,
                                    userId:
                                      currentUserInfo !== undefined
                                        ? currentUserInfo.id
                                        : 0,
                                  },
                                });
                              }
                            }}
                          />
                          <span className="fs-14 fw-500 pl-3">
                            {item.comments}
                          </span>
                        </div>
                      </div>


                    </div>

                  </div>
                </div>
              </div>
            );
          })}
        </TinySlider>
      );
    } else {
      return <div> <UploadHere
        message="Do you have any new Topic on News ?"
        buttonText="Create News"
        from="News"
      /></div>
      // { verifyLoginUserType() && 

      // :<div className="no-data-found">{constant.noDataFoundLabel}</div>} ;
    }
  };
  useEffect(() => {
    setNewsResetCount(0);
    setCommentsCount(0);
    getNews();
  }, []);
  useEffect(() => {
    getNews();
  }, [newsResetCount, commentsCount]);
  return (
    <section className="latest-news">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-auto">
            <div className="fs-20 fw-700" >
              Latest News
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="owl-carousel  owl-theme" id="latest-news">
              {renderNewsList()}
            </div>
          </div>
        </div>
      </div>
      {showCommentTopNewsModal === true && selectedTopNews !== undefined && (
        <CommentsModal
          update={update}
          setUpdate={setCommentUpdate}
          type="CommentTopNews"
          show={showCommentTopNewsModal}
          id={selectedTopNews.id}
          title={"Comment - " + selectedTopNews.name}
          currentUserId={currentUserInfo !== undefined ? currentUserInfo.id : 0}
          handleClose={() => {
            setShowCommentTopNewsModal(false);
          }}
          inputText={commentTopNewsInputText}
          setInputText={(val) => {
            setCommentTopNewsInputText(val);
          }}
          isComment={true}
        />
      )}
    </section>
  );
};
export default HomeNewsList;
