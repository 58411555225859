import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { patchDataApi } from "../../utility/apiCall";
import { constant } from "../../utility/constant";
import homeBg from "../../assets/Images/homeBg.png";
import { addSuccessMesage } from "../CustomToster/CustomToaster";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { addLoader, removeLoader } from "../../utility/CustomLoader";
import { Redirect, Route } from "react-router-dom/cjs/react-router-dom.min";

export default function ResetPassword() {
  const [inputVal, setInputVal] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordShow, setPasswordShow] = useState({
    isCreatePasswordShow: true,
    isRepeatPasswordShow: true,
  });
  let history = useHistory();
  useEffect(() => {
    if (history.action === "POP") {
      return history.push("/login");
    }
  }, []);
  const [newPasswordErr, setnewPasswordErr] = useState(false);
  const [newPasswordErrMsg, setnewPasswordErrMsg] = useState("");
  const [confirmPasswordErr, setconfirmPasswordErr] = useState(false);
  const [confirmPasswordErrMsg, setconfirmPasswordErrMsg] = useState("");
  const [repeatPasswordErr, setRepeatPasswordErr] = useState(false);
  const [repeatPasswordErrMsg, setRepeatPasswordErrMsg] = useState("");
  const getInputVal = (e) => {
    let text = e.target.value;
    let name = e.target.name;
    setInputVal({ ...inputVal, [name]: text });
    if (name === "newPassword") setnewPasswordErr(false);
    if (name === "confirmPassword") setconfirmPasswordErr(false);
  };

  const sendLogin = () => {
    //const passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    const passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;

    let flag = false;
    const { newPassword, confirmPassword } = inputVal;
    if (!newPassword && !confirmPassword) {
      setnewPasswordErr(true);
      setnewPasswordErrMsg("Enter password");
      setconfirmPasswordErr(true);
      setconfirmPasswordErrMsg("Enter password");
      flag = false;
    } else {
      flag = true;
      if (!newPassword) {
        setnewPasswordErr(true);
        setnewPasswordErrMsg("Enter password");
        flag = false;
      } else if (!passwordReg.test(newPassword)) {
        setnewPasswordErr(true);
        setnewPasswordErrMsg(
          constant.passwordValidationMsg
          //"Password should be min-6, max-20 characters long including(digits, special characters)"
        );
        flag = false;
      }
      if (!confirmPassword) {
        setconfirmPasswordErr(true);
        setconfirmPasswordErrMsg("Enter password");
        flag = false;
      }
      if (newPassword !== confirmPassword) {
        setRepeatPasswordErr(true);
        setRepeatPasswordErrMsg("Password should be same");
        flag = false;
      }
      if (flag) {
        addLoader();
        savePasswordUpdate();
      }
    }
  };
  const savePasswordUpdate = async () => {
    let jsonData0 = {
      password: inputVal.newPassword,
    };
    //console.log(jsonData0);
    var formBody = [];
    for (var property in jsonData0) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData0[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded", //"none",
    };
    let respChangePassword = await patchDataApi(
      constant.baseURL +
        "user/changePassword/" +
        localStorage.getItem("userId"),
      formBody,
      authHeader
    );
    //console.log("respChangePassword: ", respChangePassword);
    if (respChangePassword !== undefined) {
      localStorage.clear();
      history.push("login");

      removeLoader();
      addSuccessMesage("Password Changed Successfully");
    }
  };
  return (
    <section className="login-page">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-6 d-none d-xl-block">
            <div className="login-bg">
              <img src={homeBg} className="img-fluid" alt="" />
            </div>
          </div>
          <div className="col-xl-6">
            <div className="Forget-page">
              <div className="row justify-content-center align-items-center">
                <div className="col-12">
                  <div className="title">Reset Password</div>
                  <div className="login-sub-title">
                    Please create a new password
                  </div>
                  <div className="username mt-20">
                    <div className="floating-label">
                      <input
                        className="floating-input"
                        // type="password"
                        type={
                          !passwordShow.isCreatePasswordShow
                            ? "text"
                            : "password"
                        }
                        placeholder=" "
                        onChange={getInputVal}
                        name="newPassword"
                        value={inputVal.newPassword}
                        required={true}
                      />
                      {!passwordShow.isCreatePasswordShow ? (
                        <div
                          className="eye-icon-show-hide"
                          onClick={() => {
                            setPasswordShow((prevState) => {
                              return {
                                ...prevState,
                                isCreatePasswordShow: true,
                              };
                            });
                          }}
                        >
                          <BsEyeFill />
                        </div>
                      ) : (
                        <div
                          className="eye-icon-show-hide"
                          onClick={() => {
                            setPasswordShow((prevState) => {
                              return {
                                ...prevState,
                                isCreatePasswordShow: false,
                              };
                            });
                          }}
                        >
                          <BsEyeSlashFill />
                        </div>
                      )}
                      <span className="highlight"></span>
                      <label className="label">New Password</label>
                    </div>
                    {newPasswordErr && (
                      <div className="errorText">{newPasswordErrMsg}</div>
                    )}
                    </div>
                    <div className="username mt-20">
                    <div className="floating-label">
                      <input
                        className="floating-input"
                        type={
                          !passwordShow.isRepeatPasswordShow
                            ? "text"
                            : "password"
                        }
                        placeholder=" "
                        onChange={getInputVal}
                        name="confirmPassword"
                        value={inputVal.confirmPassword}
                        required={true}
                      />
                      {!passwordShow.isRepeatPasswordShow ? (
                        <div
                          className="eye-icon-show-hide"
                          onClick={() => {
                            setPasswordShow((prevState) => {
                              return {
                                ...prevState,
                                isRepeatPasswordShow: true,
                              };
                            });
                          }}
                        >
                          <BsEyeFill />
                        </div>
                      ) : (
                        <div
                          className="eye-icon-show-hide"
                          onClick={() => {
                            setPasswordShow((prevState) => {
                              return {
                                ...prevState,
                                isRepeatPasswordShow: false,
                              };
                            });
                          }}
                        >
                          <BsEyeSlashFill />
                        </div>
                      )}
                      <span className="highlight"></span>
                      <label className="label">Confirm Password</label>
                    </div>
                    {confirmPasswordErr && (
                      <div className="errorText">{confirmPasswordErrMsg}</div>
                    )}
                  </div>
                  {repeatPasswordErr && (
                    <div className="errorText">{repeatPasswordErrMsg}</div>
                  )}
                  <div className="row">
                    <div className="col-12">
                      <button
                        className="btn btn-color w100"
                        onClick={() => {
                          sendLogin();
                        }}
                      >
                        Reset Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
