import React, { useEffect, useState } from "react";
import homeBg from "../../assets/Images/homeBg.png";
import { constant } from "../../utility/constant";
import { getDataApi, postDataApi } from "../../utility/apiCall";
import { useHistory } from "react-router-dom";
import "./FirstAlbumMessage.css";

const FirstAlbumMessage = (props) => {
  //console.log("FirstAlbumMessage---", props.location.state);
  var history = useHistory();
  return (
    <>
      <section className="login-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 d-none d-xl-block">
              <div className="login-bg">
                <img src={homeBg} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-xl-6">
              <div className="create-an-account-page ">
                <div className="row justify-content-center align-items-center">
                  <div className="col-12">
                    <div className="title">
                      {/* Success */}
                    </div>
                    <div className="login-sub-title mb-5">
                      
                    </div>
                    <div className="row justify-content-center login-profile-upload">
                      <div className="col-auto">
                        <img className="login-uplaod"
                          src={constant.musicURL}
                          style={{
                            height: 100,
                            width: 100,
                            borderRadius: 55,
                          }}
                        />
                      </div>
                    </div>
                    <div className="login-sub-title mb-5" style={{textAlign: 'center', marginTop: 10}}>
                      Let's upload your first album to get started
                    </div>
                    <div>
                      <div className="row">
                        <div className={"col-12"}>
                          <button
                            className="btn btn-color w100 btnPadding"
                            onClick={() => {
                              history.push({
                                pathname: `createAlbum`,
                                state: {
                                  from: props.location.state.from,
                                },
                              });
                            }}
                          >
                            {"Upload"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default FirstAlbumMessage;
