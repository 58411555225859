import React, { useState, useEffect } from "react";
import TinySlider from "tiny-slider-react";
import { constant } from "../../utility/constant";
import { getDataApi, postDataApi, deleteDataApi } from "../../utility/apiCall";
import { NavLink, useHistory } from "react-router-dom";
import mike from "../../assets/Images/mike.svg";
import { FaRegCommentDots } from "react-icons/fa";
import moment from "moment";
import { hashCodeUrl } from "../../utility/EncodeAndDecodeUrl";
import CommentsModal from "../CommentsModal/CommentsModal";
import { VerifyLoginExistOrNot } from "../../utility/VerifyLoginExistOrNot";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import mikeFill from "../../assets/Images/mikeFill.svg";
import { DisableButtonLoader } from "../../utility/CustomLoader";

const HomeEventsList = () => {
  let history = useHistory();
  var path = useLocation();
  const [showCommentHomeEventModal, setShowCommentHomeEventModal] =
    useState(false);
  const [commentHomeEventInputText, setCommentHomeEventInputText] =
    useState("");
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const [selectedHomeEvent, setSelectedHomeEvent] = useState(undefined);
  const [update, setUpdate] = useState(false);
  const [eventsList, setEventsList] = useState([]);
  const [commentsCount, setCommentsCount] = useState(0);
  const [eventsLikesList, setEventsLikesList] = useState([]);
  const [eventsLikesData, setEventsLikesData] = useState([]);
  const [eventsResetCount, setEventsResetCount] = useState(0);
  const [disableItem, setDisableItem] = useState(false);
  const [eId, setEId] = useState(0);
  const settings = {
    controls: false,
    items: 5,
    loop: false,
    nav: false,
    mouseDrag: true,
    gutter: 10,
    responsiveClass: true,
    preventScrollOnTouch: "force",
    nested: "inner",
    responsive: {
      0: {
        items: 1.5,
        nav: false,
        loop: false,
      },
      600: {
        items: 3,
        nav: false,
      },
      1000: {
        items: 4.5,
        nav: false,
        loop: false,
      },
    },
  };
  const navigateToEventsDetails = (index) => {
    let indexItem = eventsList[index];
    //console.log(indexItem, "Events Data");
    history.push({
      // pathname: `EventsDetails/${hashCodeUrl(indexItem.id)}/${hashCodeUrl(
      //   indexItem.description
      // )}`,
      pathname: `EventsDetails/${hashCodeUrl(indexItem.id)}`,
      state: {
        id: indexItem.id,
      },
    });
    // history.push({
    //   pathname: `EventsDetails`,
    //   state: {
    //     id: indexItem.id,
    //   },
    // });
  };
  const getEvents = async () => {
    // let resEvents = await getDataApi(
    //   constant.baseURL +
    //     'events?filter=status||$eq||1&filter=eventdate||$gt||"' +
    //     new Date()
    //       .toISOString()
    //       .substr(0, new Date().toISOString().indexOf("T")) +
    //     //new Date().toISOString() +
    //     '"&sort=eventdate,DESC'
    // );
    let apiURL = constant.baseURL + "events/getUpcommingEventsWithUserLike" + '?page=' + 1 +
      '&limit=' + 20;
    if (currentUserInfo) {
      apiURL += "&userId=" + currentUserInfo.id;
    }

    let resEvents = await getDataApi(apiURL);
    //console.log("resEvents: ", resEvents);
    if (resEvents !== undefined && resEvents.data != undefined && resEvents.data.length > 0) {
      let itms = [];
      let eventsIds = "";
      resEvents.data.map((p, i) => {
        itms.push({
          id: p.id,
          img:
            p.urlImage !== undefined && p.urlImage !== null && p.urlImage !== ""
              ? constant.eventsFileURL + p.urlImage
              : "",
          address: p.address,
          eventDate:
            p.eventdate !== undefined &&
              p.eventdate !== null &&
              p.eventdate !== ""
              ? moment(p.eventdate).format("DD-MMM-yyyy")
              : "",
          name: p.name,
          description: p.description,
          // artistName: p.artist.firstName + " " + p.artist.lastName,
          created: moment(p.created).format("DD-MMM-yyyy"),
          likes: p.likes,
          comments: p.comments.length,
          userlike: p.userlike
        });
      });
      //console.log(itms)
      setEventsList(itms);

      // resEvents.map((item) => {
      //   eventsIds += item.id + ",";
      // });
      // getEventsLikes(
      //   eventsIds.substr(0, eventsIds.lastIndexOf(","))
      // );
    }
  };
  // const getEventsLikes = async (str) => {
  //   if (currentUserInfo !== undefined && str !== "") {
  //     let resEventsLikes = await getDataApi(
  //       constant.baseURL +
  //       "eventslikes?filter=user.id||$eq||" +
  //       currentUserInfo.id +
  //       "&filter=events.id||$in||" +
  //       str
  //     );
  //     //console.log("resEventsLikes: ", resEventsLikes);
  //     if (resEventsLikes != undefined && resEventsLikes.length > 0) {
  //       setEventsLikesList(resEventsLikes);
  //       setEventsLikesData(resEventsLikes);
  //     } else {
  //       setEventsLikesList([]);
  //       setEventsLikesData([]);
  //     }
  //     setDisableItem(false);
  //   } else {
  //     setDisableItem(false);
  //   }
  // };
  const createEventsLikes = async (eventsId) => {
    if (VerifyLoginExistOrNot()) {
      setDisableItem(true);
      setEId(eventsId);
      let jsonData = {
        user: currentUserInfo.id,
        events: eventsId,
      };
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let respEventsLike = await postDataApi(
        constant.baseURL + "eventslikes",
        formBody,
        authHeader
      );
      if (
        respEventsLike != undefined &&
        respEventsLike.status != undefined &&
        respEventsLike.status == 201 && respEventsLike.data !== undefined
      ) {
        setLikes(eventsId, respEventsLike.data, undefined);//setLikes(eventsId);
        setDisableItem(false);
      } else {
        setDisableItem(false);
      }
    } else {
      setDisableItem(false);
    }
  };
  const resetEventsLike = async (eventsId, likeId) => {
    if (VerifyLoginExistOrNot()) {
      setDisableItem(true);
      setEId(eventsId);
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resp = await deleteDataApi(
        constant.baseURL + "eventslikes/" + likeId,
        authHeader
      );
      if (resp != undefined && resp.status != undefined && resp.status == 200) {
        setLikes(eventsId, undefined, likeId);//setLikes(eventsId);
        setDisableItem(false);
      } else {
        setDisableItem(false);
      }
    } else {
      setDisableItem(false);
    }
  };

  const setCommentUpdate = () => {
    setCommentsCount(commentsCount + 1);
  }
  // const createEpisodeLike = async (episodeId) => {
  //   if (VerifyLoginExistOrNot()) {
  //     let jsonData = { user: currentUserInfo.id, events: episodeId };
  //     var formBody = [];
  //     for (var property in jsonData) {
  //       var encodedKey = encodeURIComponent(property);
  //       var encodedValue = encodeURIComponent(jsonData[property]);
  //       formBody.push(encodedKey + "=" + encodedValue);
  //     }
  //     formBody = formBody.join("&");
  //     var authHeader = {
  //       Accept: "application/json",
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     };
  //     let resBigStageLike = await postDataApi(
  //       constant.baseURL + "eventslikes",
  //       formBody,
  //       authHeader
  //     );
  //     console.log(resBigStageLike)
  //     if (
  //       resBigStageLike?.data !== undefined
  //     ) {
  //       setLikes(episodeId, resBigStageLike.data, undefined);
  //     }
  //   }
  //   else {
  //     history.push({
  //       pathname: `/login`,
  //       // state: {
  //       //   prevPath: path.pathname,
  //       //   userId: props.currentUserId,
  //       // },
  //     });
  //   }
  // };

  // const resetEpisodeLike = async (episodeId, likeId) => {
  //   if (VerifyLoginExistOrNot()) {
  //     var authHeader = {
  //       Accept: "application/json",
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     };
  //     let resp = await deleteDataApi(
  //       constant.baseURL + "eventslikes/" + likeId,
  //       authHeader
  //     );
  //     if (
  //       resp !== undefined &&
  //       resp.status !== undefined &&
  //       resp.status === 200
  //     ) {
  //       setLikes(episodeId, undefined, likeId);
  //     }
  //   } else {
  //     history.push({
  //       pathname: `/login`,
  //       // state: {
  //       //   prevPath: path.pathname,
  //       //   userId: props.currentUserId,
  //       // },
  //     });
  //   }
  // };
  const setLikes = (id, data, likeId) => {
    let bgItems = [...eventsList]
    //console.log(bgItems)
    if (data !== undefined) {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike.push(data);
          itm.likes.push(data);
        }
      });
    } else {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike = [];
          itm.likes.map((lkitm, index0) => {
            if (lkitm.id === likeId) {
              itm.likes.splice(index0, 1);
            }
          });
        }
      });
    }

    setEventsList(bgItems)

  };
  const renderLikes = (likeData) => {
    //console.log(likeData)
    if (likeData.userlike.length > 0) {
      return (
        <img className="w-24" src={mikeFill} alt="Unmike" style={{ cursor: "pointer" }}
          // onClick={() => resetEpisodeLike(likeData.id, likeData.userlike[0].id)} 
          onClick={() => {
            if (VerifyLoginExistOrNot()) resetEventsLike(likeData.id, likeData.userlike[0].id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    } else {
      return (
        <img className="w-24" src={mike} alt="Mike" style={{ cursor: "pointer" }}
          // onClick={() => createEpisodeLike(likeData.id)}
          onClick={() => {
            if (VerifyLoginExistOrNot()) createEventsLikes(likeData.id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    }
    // console.log("1111", eventsLikesList)
    // if (eventsLikesList.length !== 0) {
    //   let flt = eventsLikesData.filter(
    //     (f) => f.events.id !== undefined && f.events.id === id
    //   );
    //   console.log("2222", flt)
    //   if (flt.length > 0) {
    //     return (
    //       <img
    //         src={mikeFill}
    //         title={constant.unmic}
    //         key={id}
    //         onClick={() => {
    //           if (VerifyLoginExistOrNot()) resetEventsLike(id, flt[0].id);
    //           else {
    //             history.push({
    //               pathname: `/login`,
    //               state: {
    //                 prevPath: path.pathname,
    //                 userId:
    //                   currentUserInfo !== undefined ? currentUserInfo.id : 0,
    //               },
    //             });
    //           }
    //         }}
    //         style={{ cursor: "pointer", width: 25 }}
    //       />
    //     );
    //   } else {
    //     console.log("3333", flt)
    //     return (
    //       <img
    //         src={mike}
    //         title={constant.mic}
    //         key={id}
    //         onClick={() => {
    //           if (VerifyLoginExistOrNot()) createEventsLikes(id);
    //           else {
    //             history.push({
    //               pathname: `/login`,
    //               state: {
    //                 prevPath: path.pathname,
    //                 userId:
    //                   currentUserInfo !== undefined ? currentUserInfo.id : 0,
    //               },
    //             });
    //           }
    //         }}
    //         style={{ cursor: "pointer", width: 25 }}
    //       />
    //     );
    //   }
    // } else {
    //   return (
    //     <img
    //       src={mike}
    //       title={constant.mic}
    //       key={id}
    //       onClick={() => {
    //         if (VerifyLoginExistOrNot()) createEventsLikes(id);
    //         else {
    //           history.push({
    //             pathname: `/login`,
    //             state: {
    //               prevPath: path.pathname,
    //               userId:
    //                 currentUserInfo !== undefined ? currentUserInfo.id : 0,
    //             },
    //           });
    //         }
    //       }}
    //       style={{ cursor: "pointer", width: 25 }}
    //     />
    //   );
    // }
  };
  const renderEventsList = () => {
    if (eventsList.length > 0) {
      return (
        <TinySlider settings={settings}>
          {eventsList.map((item, index) => {
            return (
              // <NavLink to={{ pathname: '/my-event-details', eventId: item.id }}>
              <div
                className="item"
                key={index}
                style={{
                  //width: 230,
                  width: "-webkit-fill-available",
                }}
              >
                <div className="card">
                  <img
                    className="card-img-top"
                    style={{
                      height: 159,
                      //width: 211,
                      width: "-webkit-fill-available",
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      cursor: "pointer",
                    }}
                    src={item.img}
                    onClick={() => navigateToEventsDetails(index)}
                    alt="Card image cap"
                  />
                  <div className="event-date" style={{ bottom: 130 }}>
                    {item.eventDate}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <h5
                          className="fs-16 mt-2 fw-600 wrapText"
                          title={
                            item.name !== undefined &&
                              item.name !== null &&
                              item.name !== ""
                              ? item.name
                              : ""
                          }
                        >
                          {item.name !== undefined &&
                            item.name !== null &&
                            item.name !== ""
                            ? String(item.name)
                            : ""}
                        </h5>
                        <p
                          className="fs-14 mt-3 fw-400 wrapText"
                          title={
                            item.description !== undefined &&
                              item.description !== null &&
                              item.description !== ""
                              ? item.description
                              : ""
                          }
                        >
                          {item.description !== undefined &&
                            item.description !== null &&
                            item.description !== ""
                            ? String(item.description)
                            : ""}
                        </p>
                        <div className="line"></div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-auto">
                        <div>
                          {disableItem && eId === item.id ? DisableButtonLoader() :
                            <>
                              {renderLikes(item)}{" "}
                              <span className="fs-14 fw-500 pl-1">
                                {item.likes.length}
                              </span>
                            </>
                          }
                        </div>
                      </div>
                      <div className="col-auto">
                        <div>
                          <FaRegCommentDots
                            color="#b09b2f"
                            title="Comments"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (VerifyLoginExistOrNot()) {
                                setSelectedHomeEvent(item);
                                setShowCommentHomeEventModal(true);
                              } else {
                                history.push({
                                  pathname: `/login`,
                                  state: {
                                    prevPath: path.pathname,
                                    userId:
                                      currentUserInfo !== undefined
                                        ? currentUserInfo.id
                                        : 0,
                                  },
                                });
                              }
                            }}
                          />
                          <span className="fs-14 fw-500 pl-3">
                            {item.comments}
                          </span>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>

              // </NavLink>
            );
          })}
        </TinySlider>
      );
    } else {
      return <div className="no-data-found">{constant.noDataFoundLabel}</div>;
    }
  };
  useEffect(() => {
    setEventsResetCount(0);
    setCommentsCount(0);
    getEvents();
  }, []);
  useEffect(() => {
    //console.log("UseEffect - refresh");
    getEvents();
  }, [eventsResetCount, commentsCount]);
  return (
    <section className="upcoming-events">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-auto">
            <div
              className="fs-20 fw-700"
            >
              Upcoming Events
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="owl-carousel  owl-theme" id="upcoming-events">
              {renderEventsList()}
            </div>
          </div>
        </div>
      </div>
      {showCommentHomeEventModal === true && selectedHomeEvent !== undefined && (
        <CommentsModal
          update={update}
          setUpdate={setCommentUpdate}
          type="CommentHomeEvents"
          show={showCommentHomeEventModal}
          id={selectedHomeEvent.id}
          title={"Comment - " + selectedHomeEvent.name}
          currentUserId={currentUserInfo !== undefined ? currentUserInfo.id : 0}
          handleClose={() => {
            setShowCommentHomeEventModal(false);
          }}
          inputText={commentHomeEventInputText}
          setInputText={(val) => {
            setCommentHomeEventInputText(val);
          }}
          isComment={true}
        />
      )}
    </section>
  );
};
export default HomeEventsList;
