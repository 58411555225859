import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import logo from "../../assets/Images/logo.png";
import icon from "../../assets/Images/icon.png";
import { constant } from "../../utility/constant";
import {
  Navbar,
  Nav,
  NavDropdown,
} from "react-bootstrap";
import {
  VerifyLoginExistOrNot,
  verifyLoginUserType,
} from "../../utility/VerifyLoginExistOrNot";
import { GetPath } from "../../utility/GetPath";

import { BrowserView, MobileView, isBrowser, isMobile, IOSView, isAndroid } from 'react-device-detect'


const Header = () => {
  //console.log("--isMobile--", isMobile, "--isBrowser--", isBrowser, "--isAndroid--", isAndroid);
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const [loginVal, setLoginVal] = useState({
    rememberMe:
      localStorage.getItem("RememberMe") !== null
        ? JSON.parse(localStorage.getItem("RememberMe"))
        : false,
    userName:
      localStorage.getItem("userName") !== null &&
        localStorage.getItem("RememberMe") !== null
        ? JSON.parse(localStorage.getItem("RememberMe")) === true
          ? localStorage.getItem("userName")
          : ""
        : "",
    password:
      localStorage.getItem("pwd") !== null &&
        localStorage.getItem("RememberMe") !== null
        ? JSON.parse(localStorage.getItem("RememberMe")) === true
          ? localStorage.getItem("pwd")
          : ""
        : "",
  });
  const [open, setOpen] = useState(false);
  const [mScroll, setMScroll] = useState(0);

  var history = useHistory();
  var location = useLocation();


  const [showPlayStorePopup, setShowPlayStorePopup] = useState(true);
  const hidePlayStorePopup = () => {
    setShowPlayStorePopup(false);
  }
  const onClickInstallNow = () => {
    if (isAndroid) {
      window.open(constant.playStoreURL, "_blank");
    } else {
      window.open(constant.appStoreURL, "_blank");
    }
  }

  const onLogOut = () => {
    localStorage.clear();
    if (loginVal.rememberMe === true) {
      localStorage.setItem("RememberMe", loginVal.rememberMe);
      localStorage.setItem("userName", loginVal.userName);
      localStorage.setItem("pwd", loginVal.password);
    }
    window.location.replace("/");
    setTimeout(() => {
      window.location.reload()
    }, 500);

  };
  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 100;
      if (scrollCheck !== mScroll) {
        setMScroll(scrollCheck);
      }
    });
  });
  return (
    <>
      {GetPath() && (
        <section className="header-main">
          <Navbar
            collapseOnSelect
            expand="md"
            className={`navbar navbar-expand-lg navbar-light fixed-top custom-header-fix ${mScroll === true ? "stickHeader" : ""
              }`}
            style={{ zIndex: showPlayStorePopup && isMobile ? -1 : 1 }}
          >
            <div className="container">
              <Nav
                id="navbarDropdown"
                className="navbar-nav custom-navbar d-block d-lg-none"
              >
                {VerifyLoginExistOrNot() ? (
                  <NavDropdown
                    className="navbar-nav custom-navbar d-block d-lg-none"
                    style={{ position: "absolute", top: 6, width: 70 }}
                    title={
                      <img
                        className="img-fluid"
                        style={{ height: 45, width: 45, borderRadius: 30 }}
                        src={
                          currentUserInfo !== undefined &&
                            currentUserInfo.urlImage !== undefined &&
                            currentUserInfo.urlImage !== "" &&
                            currentUserInfo.urlImage !== "user.png"
                            ? constant.profileURL + currentUserInfo.urlImage
                            : currentUserInfo !== undefined && currentUserInfo.usertype == "Artist" ? constant.artistImageURL : constant.userImageURL
                        }
                        alt=""
                      />
                    }
                  >
                    <NavDropdown.Item>
                      <Link
                        to="/MyPlaylists"
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        {constant.myPlaylistsLabel}
                      </Link>
                    </NavDropdown.Item>
                    {currentUserInfo !== undefined &&
                      currentUserInfo.usertype == "Artist" && (
                        <>
                          <NavDropdown.Item>
                            <Link
                              to="/MyAlbums"
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              {constant.myMusicLabel}
                            </Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item>
                            <Link
                              to="/MyPodcasts"
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              {constant.myPodcastsLabel}
                            </Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item>
                            <Link
                              to="/MyNews"
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              {constant.myNewsLabel}
                            </Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item>
                            <Link
                              to="/MyEvents"
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              {constant.myEventsLabel}
                            </Link>
                          </NavDropdown.Item>
                        </>
                      )}
                    <NavDropdown.Divider />
                    <NavDropdown.Item>
                      <Link
                        to="/Profile"
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        {constant.profileLabel}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link
                        onClick={() => onLogOut()}
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        {constant.logoutLabel}
                      </Link>
                    </NavDropdown.Item>
                    {/* </NavDropdown.Item> */}
                  </NavDropdown>
                ) : (
                  <button
                    className="btn btn-color w100 btnPadding cus-login-btn"
                    onClick={() => {
                      history.push({
                        pathname: `/login`,
                        state: {
                          prevPath: location.pathname,
                        },
                      });
                    }}
                  >
                    {constant.loginLabel}
                  </button>
                )}
              </Nav>
              <NavLink to="/Home" className="navbar-brand d-block d-lg-none">
                <img
                  className="img-fluid custom-logo"
                  style={{ marginLeft: VerifyLoginExistOrNot() ? 60 : 0 }}
                  src={logo}
                  alt="logo"
                />
              </NavLink>
              <NavLink to="/Home" className="navbar-brand d-none d-lg-block">
                <img className="img-fluid custom-logo" src={logo} alt="logo" />
              </NavLink>
              <Navbar.Toggle />
              <Navbar.Collapse>
                <Nav className="mr-auto d-block d-md-none">
                  <ul className="navbar-nav ml-auto custom-navbar">
                    <li className="nav-item">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="1"
                          as={Link}
                          to="/Home"
                          activeClassName="activeLinkClass"
                        >
                          {constant.homeLabel}
                        </Nav.Link>
                      </Nav.Item>
                    </li>
                    <li className="nav-item">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="2"
                          as={Link}
                          to="/BigStage"
                          activeClassName="activeLinkClass"
                        >
                          {constant.theBigStageLabel}
                        </Nav.Link>
                      </Nav.Item>
                    </li>
                    <li className="nav-item active">
                      <Nav.Item>
                        <Nav.Link eventKey="3" as={Link} to="/CommunityRoom" activeClassName="activeLinkClass">
                          {constant.communityRoomLabel}
                        </Nav.Link>
                      </Nav.Item>
                    </li>
                    <li className="nav-item">
                      <Nav.Item>
                        <Nav.Link eventKey="4" as={Link} to="/FeaturedArtist" activeClassName="activeLinkClass">
                          {constant.featuredArtistLabel}
                        </Nav.Link>
                      </Nav.Item>
                    </li>
                    {verifyLoginUserType() && (
                      <li className="nav-item">
                        <Nav.Item>
                          <Nav.Link eventKey="5" as={Link} to="/UploadMusic" activeClassName="activeLinkClass">
                            {constant.uploadMusicLabel}
                          </Nav.Link>
                        </Nav.Item>
                      </li>
                    )}
                    <li className="nav-item">
                      <Nav.Item>
                        <Nav.Link eventKey="6" as={Link} to="/Podcast" activeClassName="activeLinkClass">
                          {constant.podcastLabel}
                        </Nav.Link>
                      </Nav.Item>
                    </li>
                    <li className="nav-item">
                      <Nav.Item>
                        <Nav.Link eventKey="7" as={Link} to="/News" activeClassName="activeLinkClass">
                          {constant.newsLabel}
                        </Nav.Link>
                      </Nav.Item>
                    </li>
                    <li className="nav-item">
                      <Nav.Item>
                        <Nav.Link eventKey="8" as={Link} to="/Events" activeClassName="activeLinkClass">
                          {constant.eventsLabel}
                        </Nav.Link>
                      </Nav.Item>
                    </li>
                    {VerifyLoginExistOrNot() ? (
                      <li className="nav-item dropdown d-none d-lg-block">
                        <NavDropdown
                          title={
                            <img
                              className="img-fluid"
                              style={{
                                height: 50,
                                width: 50,
                                borderRadius: 30,
                              }}
                              src={
                                currentUserInfo !== undefined &&
                                  currentUserInfo.urlImage !== undefined &&
                                  currentUserInfo.urlImage !== "" &&
                                  currentUserInfo.urlImage !== "user.png"
                                  ? constant.profileURL +
                                  currentUserInfo.urlImage
                                  : currentUserInfo !== undefined && currentUserInfo.usertype == "Artist" ? constant.artistImageURL : constant.userImageURL
                              }
                              alt=""
                            />
                          }
                          id="collasible-nav-dropdown"
                        >
                          <NavDropdown.Item>
                            <Link
                              to="/MyPlaylists"
                              style={{
                                color: "#212529",
                                textDecoration: "none",
                              }}
                            >
                              {constant.myPlaylistsLabel}
                            </Link>
                          </NavDropdown.Item>
                          {currentUserInfo !== undefined &&
                            currentUserInfo.usertype == "Artist" && (
                              <>
                                <NavDropdown.Item>
                                  <Link
                                    to="/MyAlbums"
                                    style={{
                                      color: "#212529",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {constant.myMusicLabel}
                                  </Link>
                                </NavDropdown.Item>
                                <NavDropdown.Item>
                                  <Link
                                    to="/MyPodcasts"
                                    style={{
                                      color: "#212529",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {constant.myPodcastsLabel}
                                  </Link>
                                </NavDropdown.Item>
                                <NavDropdown.Item>
                                  <Link
                                    to="/MyNews"
                                    style={{
                                      color: "#212529",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {constant.myNewsLabel}
                                  </Link>
                                </NavDropdown.Item>
                                <NavDropdown.Item>
                                  <Link
                                    to="/MyEvents"
                                    style={{
                                      color: "#212529",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {constant.myEventsLabel}
                                  </Link>
                                </NavDropdown.Item>
                              </>
                            )}
                          <NavDropdown.Divider />
                          <NavDropdown.Item>
                            <Link
                              to="/Profile"
                              style={{
                                color: "#212529",
                                textDecoration: "none",
                              }}
                            >
                              {constant.profileLabel}
                            </Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item>
                            <Link
                              onClick={() => onLogOut()}
                              style={{
                                color: "#212529",
                                textDecoration: "none",
                              }}
                            >
                              {constant.logoutLabel}
                            </Link>
                          </NavDropdown.Item>
                        </NavDropdown>
                      </li>
                    ) : (
                      <li className="nav-item dropdown d-none d-lg-block">
                        <button
                          className="btn btn-color w100 btnPadding marginTop0"
                          onClick={() => {
                            history.push({
                              pathname: `/login`,
                              state: {
                                prevPath: location.pathname,
                              },
                            });
                          }}
                        >
                          {constant.loginLabel}
                        </button>
                      </li>
                    )}
                  </ul>
                </Nav>
                <Nav className="mr-auto d-none d-md-block">
                  <ul className="navbar-nav ml-auto custom-navbar">
                    <li className="nav-item">
                      <Nav.Item>
                        <NavLink
                          to="/Home"
                          className="webHeaderText"
                          activeClassName="activeLinkClass"
                        >
                          {constant.homeLabel}
                        </NavLink>
                      </Nav.Item>
                    </li>
                    <li className="nav-item">
                      <Nav.Item>
                        <NavLink
                          to="/BigStage"
                          className="webHeaderText"
                          activeClassName="activeLinkClass"
                        >
                          {constant.theBigStageLabel}
                        </NavLink>
                      </Nav.Item>
                    </li>
                    <li className="nav-item active">
                      <Nav.Item>
                        <NavLink
                          to="/CommunityRoom"
                          className="webHeaderText"
                          activeClassName="activeLinkClass"
                        >
                          {constant.communityRoomLabel}
                        </NavLink>
                      </Nav.Item>
                    </li>
                    <li className="nav-item">
                      <Nav.Item>
                        <NavLink
                          to="/FeaturedArtist"
                          className="webHeaderText"
                          activeClassName="activeLinkClass"
                        >
                          {constant.featuredArtistLabel}
                        </NavLink>
                      </Nav.Item>
                    </li>
                    {verifyLoginUserType() && (
                      <li className="nav-item">
                        <Nav.Item>
                          <NavLink
                            to="/UploadMusic"
                            className="webHeaderText"
                            activeClassName="activeLinkClass"
                          >
                            {constant.uploadMusicLabel}
                          </NavLink>
                        </Nav.Item>
                      </li>
                    )}
                    <li className="nav-item">
                      <Nav.Item>
                        <NavLink
                          to="/Podcast"
                          className="webHeaderText"
                          activeClassName="activeLinkClass"
                        >
                          {constant.podcastLabel}
                        </NavLink>
                      </Nav.Item>
                    </li>
                    <li className="nav-item">
                      <Nav.Item>
                        <NavLink
                          to="/News"
                          className="webHeaderText"
                          activeClassName="activeLinkClass"
                        >
                          {constant.newsLabel}
                        </NavLink>
                      </Nav.Item>
                    </li>
                    <li className="nav-item">
                      <Nav.Item>
                        <NavLink
                          to="/Events"
                          className="webHeaderText"
                          activeClassName="activeLinkClass"
                        >
                          {constant.eventsLabel}
                        </NavLink>
                      </Nav.Item>
                    </li>
                    {VerifyLoginExistOrNot() ? (
                      <li className="nav-item dropdown d-none d-lg-block">
                        <NavDropdown
                          title={
                            <img
                              className="img-fluid"
                              style={{
                                height: 50,
                                width: 50,
                                borderRadius: 30,
                              }}
                              src={
                                currentUserInfo !== undefined &&
                                  currentUserInfo.urlImage !== undefined &&
                                  currentUserInfo.urlImage !== "" &&
                                  currentUserInfo.urlImage !== "user.png"
                                  ? constant.profileURL +
                                  currentUserInfo.urlImage
                                  : currentUserInfo !== undefined && currentUserInfo.usertype == "Artist" ? constant.artistImageURL : constant.userImageURL
                              }
                              alt=""
                            />
                          }
                          id="collasible-nav-dropdown"
                        >
                          <NavDropdown.Item>
                            <Link
                              to="/MyPlaylists"
                              style={{
                                color: "#212529",
                                textDecoration: "none",
                              }}
                            >
                              {constant.myPlaylistsLabel}
                            </Link>
                          </NavDropdown.Item>
                          {currentUserInfo !== undefined &&
                            currentUserInfo.usertype == "Artist" && (
                              <>
                                <NavDropdown.Item>
                                  <Link
                                    to="/MyAlbums"
                                    style={{
                                      color: "#212529",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {constant.myMusicLabel}
                                  </Link>
                                </NavDropdown.Item>
                                <NavDropdown.Item>
                                  <Link
                                    to="/MyPodcasts"
                                    style={{
                                      color: "#212529",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {constant.myPodcastsLabel}
                                  </Link>
                                </NavDropdown.Item>
                                <NavDropdown.Item>
                                  <Link
                                    to="/MyNews"
                                    style={{
                                      color: "#212529",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {constant.myNewsLabel}
                                  </Link>
                                </NavDropdown.Item>
                                <NavDropdown.Item>
                                  <Link
                                    to="/MyEvents"
                                    style={{
                                      color: "#212529",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {constant.myEventsLabel}
                                  </Link>
                                </NavDropdown.Item>
                              </>
                            )}
                          <NavDropdown.Divider />
                          <NavDropdown.Item>
                            <Link
                              to="/Profile"
                              style={{
                                color: "#212529",
                                textDecoration: "none",
                              }}
                            >
                              {constant.profileLabel}
                            </Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item>
                            <Link
                              onClick={() => onLogOut()}
                              style={{
                                color: "#212529",
                                textDecoration: "none",
                              }}
                            >
                              {constant.logoutLabel}
                            </Link>
                          </NavDropdown.Item>
                        </NavDropdown>
                      </li>
                    ) : (
                      <li className="nav-item dropdown d-none d-lg-block">
                        <button
                          className="btn btn-color w100 btnPadding marginTop0"
                          onClick={() => {
                            history.push({
                              pathname: `/login`,
                              state: {
                                prevPath: location.pathname,
                              },
                            });
                          }}
                        >
                          {constant.loginLabel}
                        </button>
                      </li>
                    )}
                  </ul>
                </Nav>
              </Navbar.Collapse>
            </div>
          </Navbar>

          <>
            <div className='Playsorepopup d-block d-lg-none' style={{ position: showPlayStorePopup && isMobile ? 'fixed' : 'absolute', zIndex: showPlayStorePopup && isMobile ? 1 : -1 }}>
              <div className='contain'>
                <div className='malti-logo'>
                  <img src={logo} className='multilogo'></img>
                </div>
                <div className='text-contain'>
                  <div className='text-heading'>Multi Music Moguls App</div>
                  <div className='text-title'>A digital music, podcast & video serice app</div>
                </div>
                <div className='aro' onClick={() => hidePlayStorePopup()}><span style={{ color: '#ffd874', fontSize: 19, fontWeight: 600 }}>X</span></div>
              </div>
              <div className='d-flex' style={{ justifyContent: 'space-around', padding: '0px 27px' }}>
                <button className=' btn btn-primary upload-btn w50' onClick={() => onClickInstallNow()}>Install Now</button>
              </div>
            </div>
          </>
        </section>
      )}
    </>
  );
};
export default Header;
