import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useHistory, useParams } from "react-router-dom";
import TinySlider from "tiny-slider-react";
import ReactPlayer from 'react-player';
import {
  increaseCounter,
  decreaseCounter,
} from "../../redux/Counter/counter.actions"
// import communicate from "../../assets/Images/communicate-1.jpg";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";
import Musics from "../../components/Musics/Musics";
import { getDataApi } from "../../utility/apiCall";
import { constant } from "../../utility/constant";
import {
  hashCodeUrl,
  hashCodeUrlDecode,
} from "../../utility/EncodeAndDecodeUrl";
import { connect } from "react-redux"

function MyAlbumsDetails(props) {
  //console.log(props)
  let searchData = useParams();
  let history = useHistory();
  const [showViewMore, setShowViewMore] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const [artistList, setArtistList] = useState([]);
  const [albumList, setAlbumList] = useState([]);
  const [selectedAlbumMusics, setSelectedAlbumMusics] = useState([]);
  const [showMusics, setShowMusics] = useState(false);
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const [playIndex, setPlayIndex] = useState(0);
  const [commentsList, setCommentsList] = useState([]);
  const [commentInputVal, setCommentInputVal] = useState("");
  const [errorCommentMessage, setCommentError] = useState(false);
  const [commentsPage, setCommentsPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [artistLikes, setArtistLikes] = useState(undefined);
  const [artistFollow, setArtistFollow] = useState(undefined);
  const [showReportArtistModal, setShowReportArtistModal] = useState(false);
  const [reportArtistInputText, setReportArtistInputText] = useState("");
  const [showShareToModal, setShowShareToModal] = useState(false);
  const [musicLikesList, setLikesList] = useState([]);
  const [musicList, setMusicList] = useState([]);
  const [selectedAlbum, setSelectedAlbum] = useState(undefined);
  const [albumActive, setalbumActive] = useState(false);
  const [albumImage, setalbumImage] = useState("");
  const [albumName, setalbumName] = useState("");
  const [albumtype1, setalbumtype1] = useState({});
  const [CurrentPlayMusic, setCurrentPlayMusic] = useState(false);

  // satyam

  var [countData, setcountData] = useState(0);
  const [currentALbumDetails, setcurrentALbumDetails] = useState(false);

  // satyam ENd

  const [albumActiveId, setalbumActiveId] = useState("");
  const AlbumClass = classNames({
    wd260: albumActive === false,
    wd239: albumActive === true,
  });
  var AlbumIdByUser = null;
  const albumSettings = {
    controls: false,
    items: 5,
    loop: false,
    nav: false,
    mouseDrag: true,
    gutter: 10,
    responsiveClass: true,
    preventScrollOnTouch: "force",
    nested: "inner",
    responsive: {
      0: {
        items: 1.5,
        nav: false,
      },
      600: {
        items: 3,
        nav: false,
      },
      1000: {
        items: 4,
        nav: false,
        loop: false,
      },
    },
  };
  const setLikes = (id, data,likeId) => {
    // if (musicLikesList.length !== 0) {
    //   let fltLikes = musicLikesList.filter(
    //     (f) => f.music != undefined && f.music.id == id
    //   );
    //   if (fltLikes.length == 0) {
    //     setLikesList([...musicLikesList, data]);
    //   } else {
    //     let newArr = musicLikesList.filter(
    //       (i) => i.music != undefined && i.music.id !== id
    //     );
    //     console.log("11111111111jayraj")
    //     setLikesList(newArr);
    //   }
    // } else {
    //   let arr = [];
    //   arr.push(data);
    //   setLikesList(arr);
    // }
    let bgItems = [...selectedAlbumMusics]
    //console.log(bgItems)
    if (data !== undefined) {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike.push(data);
          itm.likes.push(data);
        }
      });
    } else {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike = [];
          itm.likes.map((lkitm, index0) => {
            if (lkitm.id === likeId) {
              itm.likes.splice(index0, 1);
            }
          });
        }
      });
    }

    setSelectedAlbumMusics(bgItems)
  };
  const funsetcurrentPlaymusic = () =>{
    // setCurrentPlayMusic(false)
    setcurrentALbumDetails(false)
    setCurrentPlayMusic(false)
  }
  const getMusicByAlbumId = async () => {
    let resAlbumId = await getDataApi(
      constant.baseURL + "albums/" + hashCodeUrlDecode(searchData.itemId)
    );
    // console.log("resAlbumId", resAlbumId);
    if (resAlbumId !== undefined) {
      //console.log(resAlbumId)
      AlbumIdByUser = resAlbumId.id;
      setalbumImage(
        resAlbumId.urlImage !== ""
          ? constant.albumFileURL + resAlbumId.urlImage
          : constant.musicURL
      );
      setalbumName(resAlbumId.name);
      getAlbumsByArtist(resAlbumId?.user?.id);
      setalbumtype1(resAlbumId)
    }
  };
  const getAlbumsByArtist = async (id) => {
    let resAlbums = await getDataApi(
      constant.baseURL + "albums/getByArtistWithUserLike/" + id + "?page=1&limit=100" + '&userId=' + ((currentUserInfo) ? currentUserInfo.id : 0)
    );
    if (resAlbums !== undefined && resAlbums.data !== undefined) {
      setAlbumList([...resAlbums.data]);
      //console.log("|jayraj",...resAlbums.data)
      if (resAlbums.data.length > 0) {
        let musicAlbumByData = resAlbums.data.filter((item) => {
          return item.id == AlbumIdByUser;
        });
        //console.log("musicAlbumByData: ", musicAlbumByData[0]);
        setSelectedAlbum(musicAlbumByData[0]);
        let musicsData = [];
        let musicIds = "";
        if (musicAlbumByData[0].musics !== undefined) {
          musicAlbumByData[0].musics.map((item) => {
            musicIds +=
            item.id !== undefined ? item.id + "," : item.id + ",";
            musicsData.push({
              id: item.id,
              title: item.title,
              name: item.title,
              time: item.duration,
              size:
                item.size !== undefined && item.size !== null
                  ? item.size
                  : 0,
              singer:
                musicAlbumByData[0].user.firstName +
                " " +
                musicAlbumByData[0].user.lastName,
              cover: constant.musicURL,
              albumname: musicAlbumByData[0].name,
              urlImage:
                item.urlImage !== undefined ? item.urlImage : "",
              musicSrc:
                item.urlImage !== undefined
                  ? constant.musicFileURL + item.urlImage
                  : "",
              likes: item.likes,
              comments: item.comments,
              userlike: item.userlike
            });
          });
        }
        setcountData(countData + 1);
        setSelectedAlbumMusics([...musicsData]);
        setShowMusics(true);
        // getMusicsByAlbum(resAlbums.data[0]);
        setshowLoader(false);
        setcurrentALbumDetails(false);
        getMusicLikesByUser(musicIds.substr(0, musicIds.lastIndexOf(",")));
      } else {
        setSelectedAlbumMusics([]);
        setShowMusics(false);
        setLikesList([]);
      }
    }
  };
  const resetShowAudioPlayer = () => {
    setShowAudioPlayer(false);
  };
  // const renderAlbums = () => {
  //   return (
  //     <TinySlider settings={settings}>
  //       {albumList.map((item, index) => {
  //         return (
  //           <div
  //             key={index}
  //             className={
  //               "tns-item tns-slide-active" +
  //               " " +
  //               (albumActiveId === index && "albumActive")
  //             }
  //             onClick={() => onClickAlbum(index)}
  //             style={{
  //               width: 260,
  //               marginRight: 10,
  //               cursor: "pointer",
  //               marginLeft: 10,
  //             }}
  //           >
  //             {item.albumType === "music" && (
  //               <img
  //                 className={AlbumClass}
  //                 // className="wd260"
  //                 style={{ height: 193, cursor: "pointer" }}
  //                 src={
  //                   item.urlImage !== undefined &&
  //                   item.urlImage !== ""
  //                     ? constant.albumFileURL + item.urlImage
  //                     : constant.musicURL
  //                 }
  //                 alt={item.name}
  //               />
  //             )}
  //             {item.albumType === "video" && (
  //               <img
  //                 style={{ height: 193, cursor: "pointer" }}
  //                 className={AlbumClass}
  //                 src={
  //                   item.urlImage !== undefined &&
  //                   item.urlImage !== ""
  //                     ? constant.albumFileURL + item.urlImage
  //                     : constant.videoURL
  //                 }
  //                 alt={item.name}
  //               />
  //             )}
  //             <h6
  //               className="big-stage-song-name wrapText"
  //               title={item.name}
  //             >
  //               {String(item.name)}
  //             </h6>
  //             <p className="big-stage-signer">
  //               {item.musics !== undefined ? item.musics.length : 0} Tracks
  //             </p>
  //           </div>
  //         );
  //       })}
  //     </TinySlider>
  //   );
  // };
  const renderAlbums = () => {
    return (
      
      <TinySlider settings={albumSettings}>
        {albumList.map((item, index) => {
          //console.log(item)
          return (
            <div
              key={index}
              // className={
              //   "tns-item tns-slide-active" +
              //   " " +
              //   (albumActiveId === index && "albumActive")
              // }
              onClick={() => onClickAlbum(index,item)}
              style={{
                //width: 260, marginRight: 10,
                cursor: "pointer",
                width: "-webkit-fill-available",
                //marginLeft: 10
              }}
            >
              <div className="card mm-card-bg">
                {item.albumType === "music" && (
                  <img
                    className={
                      AlbumClass + (albumActiveId === index && " albumActive")
                    }
                    style={{
                      height: 193,
                      cursor: "pointer",
                      width: "-webkit-fill-available",
                    }}
                    src={
                      item.urlImage !== undefined &&
                      item.urlImage !== ""
                        ? constant.albumFileURL + item.urlImage
                        : constant.musicURL
                    }
                    alt={item.name}
                  />
                )}
                {item.albumType === "video" && (
                  <img
                    style={{
                      height: 193,
                      cursor: "pointer",
                      width: "-webkit-fill-available",
                    }}
                    className={
                      AlbumClass + (albumActiveId === index && " albumActive")
                    }
                    src={
                      item.urlImage !== undefined &&
                      item.urlImage !== ""
                        ? constant.albumFileURL + item.urlImage
                        : constant.videoURL
                    }
                    alt={item.name}
                  />
                )}
                <div className="mm-card-body">
                  <h6
                    className="big-stage-song-name wrapText"
                    title={item.name}
                  >
                    {String(item.name)}
                  </h6>
                  <p className="big-stage-signer">
                    {item.musics !== undefined ? item.musics.length : 0} Tracks
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </TinySlider>
    );
  };
  const onClickAlbum = (index,i) => {

    //console.log(index,i)
    if(i.albumType=="video"){
      let stateObj = { variant: "musicjs", variant1: "musicjs", id: i.id }
      history.push({
        pathname: "/VideoPlayer",
        state: stateObj,
      });
    }else{
      setShowMusics(false);
      window.scrollTo(0, 0);
      setalbumActiveId(index);
      setalbumActive(true);
      setTimeout(() => {
        let itm = albumList[index];
        //console.log("itm", itm);
        history.push(`/MyAlbumsDetails/${hashCodeUrl(itm.id)}`);
        setSelectedAlbum(itm);
        let musicIds = "";
        if (itm !== undefined) {
          let musicsData = [];
          setalbumName(itm.name);
          setalbumImage(
            itm.urlImage !== ""
              ? constant.albumFileURL + itm.urlImage
              : constant.musicURL
          );
  
          itm.musics.map((item) => {
            musicIds +=
              item.id !== undefined ? item.id + "," : item.id + ",";
            musicsData.push({
              id: item.id,
              title: item.title,
              name: item.title,
              singer: itm.user.firstName + " " + itm.user.lastName,
              cover: constant.musicURL,
              time: item.duration,
              size:
                item.size !== undefined && item.size !== null
                  ? item.size
                  : 0,
              albumname: itm.name,
              urlImage:
                item.urlImage !== undefined ? item.urlImage : "",
              musicSrc:
                item.urlImage !== undefined
                  ? constant.musicFileURL + item.urlImage
                  : "",
              likes: item.likes,
              comments: item.comments,    
              userlike: item.userlike
            });
          });
          setSelectedAlbumMusics([...musicsData]);
          getMusicLikesByUser(musicIds.substr(0, musicIds.lastIndexOf(",")));
          // return updateDurationInMusics(musicsData).then((resp) => {
          //   setshowLoader(false);
          //   console.log(resp);
          //   setSelectedAlbumMusics([...resp]);
          // });
  
          // satyam start
  
          setcountData(countData + 1);
          setcurrentALbumDetails(false);
          // satyam ENd
        }
        setShowMusics(true);
        // if(itm.albumType=="music"){
         
        // }
      }, 300);
      props.increasecounter(false,null,null,790,790)
    }
    
    // 
  };
  const getMusicLikesByUser = async (str) => {
    if (currentUserInfo !== undefined && str !== "") {
      let resMusicsLikes = await getDataApi(
        constant.baseURL +
        "musiclikes?filter=user.id||$eq||" +
        currentUserInfo.id +
        "&filter=music.id||$in||" +
        str
      );
      if (resMusicsLikes !== undefined && resMusicsLikes.length > 0) {
        let mlikeIds = [];
        resMusicsLikes.map((m) => {
          mlikeIds.push(m.music.id);
        });
        setLikesList(resMusicsLikes);
      }
    }
  };
  useEffect(() => {
    getMusicByAlbumId();
    props.increasecounter(false,null,null,790,790)
  }, []);

  return (
    <section className="community-room-banner custom-songlist">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
          
         {props?.show ? <ReactPlayer
                        className="reactPlayer"
                        width='100%'
                        controls={true}
                        playing={true}
                        // getAudioInstance={getAudioInstance}
                        //onEnablePIP={onEnablePIP}
                        //pip={false}
                        // playsinline={true}
                        url={props?.url}
                        config={{ file: { attributes: { disablepictureinpicture: 'true' }}}}
                        >
                    </ReactPlayer>: 
                     <img
              className="myAlbumDetailsImg"
              src={albumImage}
              alt=""
              // style={{ width: "100%", height: "70vh" }}
            /> 
            }
           
          </div>
          <div className="col-12 col-lg-6">
            <div className="uppload-music-right-box">
              <div
                className="album-song-title"
                style={{ marginBottom: 10, textTransform: "capitalize" }}
              >
                {albumName === "" ? "Album Songs" : albumName}
              </div>
            </div>
            <div>
              {" "}
              {/*  className="songlist-right-box" */}
              <div>
                {/*  className="song-list custom-songlist" */}
                <div className="row">
                  {showLoader === true ? (
                    <div style={{ textAlign: "center" }}>
                      <Loader
                        type="Circles"
                        // color="#00BFFF"
                        color="#ffd874"
                        // secondaryColor="blue"
                        height={100}
                        width={100}
                        // timeout={3000}
                      />
                    </div>
                  ) : (
                    <div className="col-12">
                      {showMusics == true && (
                        <Musics
                          variant="MyAlbum"
                          currentALbumDetails={currentALbumDetails}
                          currentPlayMusic={CurrentPlayMusic}
                          funsetcurrentPlaymusic = {funsetcurrentPlaymusic}
                          // setcountData={setcountData}
                          // countData={countData}
                          showMusicLike
                          data={selectedAlbumMusics}
                          // showAudioPlayer={showAudioPlayer}
                          playIndex={playIndex}
                          ArtistUserId={hashCodeUrlDecode(searchData.itemId)}
                          currentUserId={
                            currentUserInfo !== undefined
                              ? currentUserInfo.id
                              : 0
                          }
                          musicLikesList={musicLikesList}
                          setLikes={setLikes}
                          albumType={
                            selectedAlbum !== undefined
                              ? selectedAlbum.albumType
                              : "music"
                          }
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {/* {showAudioPlayer && (
            <AudioPlayer
              musicList={selectedAlbumMusics}
              playIndex={playIndex}
              setShowAudioPlayer={resetShowAudioPlayer}
            ></AudioPlayer>
          )} */}
        </div>
      </div>
      <section className="album">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-auto">
              <div className="podcasts-title">Albums</div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              {albumList.length > 0 && (
                <div className="owl-carousel owl-theme">{renderAlbums()}</div>
              )}
              {albumList.length === 0 && (
                <div className="no-data-found">{constant.noDataFoundLabel}</div>
              )}
            </div>
          </div>
          <br />
        </div>
      </section>
    </section>
  );
}

const mapStateToProps = state => {
  //console.log(state)
  return {
    show: state.counter.show,
    url: state.counter.url,
    title: state.counter.title,
    height: state.counter.height,
    width: state.counter.width,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    increasecounter: (p,q,r,s,t) => dispatch(increaseCounter(p,q,r,s,t)),

    // decreaseCounter: () => dispatch(decreaseCounter()),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(MyAlbumsDetails) ;