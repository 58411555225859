import React, { useState, useEffect } from "react";
import { constant } from "../../utility/constant";
import {
  getDataApi,
  postDataApi,
  patchDataApi,
  deleteDataApi,
} from "../../utility/apiCall";
import { colors } from "../../utility/colors";
import moment from "moment";
import { BsFillEyeFill, BsFillChatDotsFill } from "react-icons/bs";
import { AiOutlineHeart, AiFillHeart, AiFillCloseSquare } from "react-icons/ai";
import uploadImage1 from "../../assets/Images/uploadImage1.png";
import { useHistory } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { FileDrop } from "react-file-drop";
import ImageUploader from "react-images-upload";
import {
  addErrorMesage,
  addSuccessMesage,
} from "../../components/CustomToster/CustomToaster";
import { CircularProgressbar } from "react-circular-progressbar";
import { addLoader, removeLoader } from "../../utility/CustomLoader";

function CreateNews(props) {
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const { id, item } = props.location.state;
  let history = useHistory();
  const multiSelectStyle = {
    multiselectContainer: {
      color: "white",
      fontSize: 14,
    },
    option: {
      // To change css for dropdown options
      color: colors.colors13,
    },
    chips: {
      background: colors.colors13,
      fontSize: 15,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 8,
      paddingBottom: 8,
      borderRadius: 8,
    },
    searchBox: {
      border: "1px solid #8186AF",
      borderRadius: "2px",
      //fontFamily: 'Mr Eaves Mod OT',
      fontSize: 15,
      minHeight: 40,

      //width: 395,
    },
  };
  const [newsFiles, setNewsFiles] = useState([]);
  const [showSelectedNewsFile, setShowSelectedNewsFile] = useState(
    item !== undefined ? true : false
  );
  const [coverImg, setCoverImg] = useState(null);
  const [dragNDropText, setDragNDropText] = useState(
    "Drop your file here or Browse"
  );
  const [newsCategories, setNewsCategories] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [newsDetails, setNewsDetails] = useState({
    newsTitle: item !== undefined ? item.title : "",
    newsSubtitle: item !== undefined ? item.subtitle : "",
    newsDescription: item !== undefined ? item.description : "",
    newsurlImage: item !== undefined ? item.urlImage : "",
    categoryList: [],
    selectedCategoryList: [],
    existingCategoryList: [],
    id: id !== undefined ? id : 0,
  });
  const [isUploadNews, setUploadNews] = useState(false);
  const [isNewsTitleErrShow, setNewsTitleErr] = useState(false);
  const [isNewsSubTitleErrShow, setNewsSubTitleErr] = useState(false);
  const [isNewsDescErrShow, setNewsDescErr] = useState(false);
  const [isNewsImgeErrShow, setisNewsImgeErrShow] = useState(false);
  const [isAddCategoryErrShow, setAddCategoryErr] = useState(false);
  const onClickCancel = () => {
    history.push("/MyNews");
  };
  const onDrop = (file) => fileValidation(file[0]);
  const onUploadImg = (file) => fileValidation(file[0]);
  const fileValidation = (file) => {
    if (file !== undefined) {
      // Allowing file type
      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(file.name)) {
        addErrorMesage("Please upload a image file!");
        //enqueueSnackbar("Please upload a image file!", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right', } });
        file = "";
        setDragNDropText("Drop your image here or browse");
        return false;
      } else {
        let imageSrc = URL.createObjectURL(file);
        setDragNDropText("Drop your image here or browse");
        setCoverImg(imageSrc);
        let fileItem = [];
        fileItem.push(file);
        setNewsFiles([...fileItem, ...newsFiles]);
      }
    }
  };

  const onSelectCategory = (selectedList) => {
    //console.log("Selected Category: ", selectedList);
    setNewsDetails((prevState) => {
      return { ...prevState, selectedCategoryList: selectedList };
    });
    setAddCategoryErr(false);
  };

  const onRemoveSelectCategory = (selectedList) => {
    setNewsDetails((prevState) => {
      return { ...prevState, selectedCategoryList: selectedList };
    });
  };

  const onUpload = async () => {
    const { newsTitle, newsSubtitle, selectedCategoryList, newsDescription } =
      newsDetails;
    setisNewsImgeErrShow(false);
    if (
      !newsTitle &&
      !newsSubtitle &&
      !newsDescription &&
      selectedCategoryList.length === 0 &&
      id == undefined
    ) {
      setNewsTitleErr(true);
      setNewsSubTitleErr(true);
      setAddCategoryErr(true);
      setNewsDescErr(true);
      addErrorMesage("Upload a news file!");
      //enqueueSnackbar("Upload a news file!", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right', } });
    } else if (!newsTitle) {
      setNewsTitleErr(true);
    } else if (!newsSubtitle) {
      setNewsSubTitleErr(true);
    } else if (!newsDescription) {
      setNewsDescErr(true);
    } else if (selectedCategoryList.length === 0) {
      setAddCategoryErr(true);
    } else if (
      id === 0 ? newsFiles.length === 0 : newsDetails.newsurlImage.length === 0
    ) {
      //console.log(newsFiles);
      //console.log("error in News");
      setisNewsImgeErrShow(true);
    } else {
      //console.log("DONE");
      setUploadNews(true);
      setDragNDropText("Drop your file here or Browse");
      if (currentUserInfo !== undefined) {
        let respName = await getNewsByName();
        //console.log("respName: ", respName);
        if(id === 0 && respName.length > 0){
          addErrorMesage("News with the same title already exists!");
          return;
        }else if(id !== 0 && respName.length > 0 && id !== respName[0].id){
          addErrorMesage("News with the same title already exists!");
          return;
        }
        if (newsFiles.length > 0) {
          addLoader();
          uploadNewsFiles();
        } else {
          if (id !== 0) {
            setUploadProgress(25);
            saveNews(newsDetails.newsurlImage);
          } else {
            resetNews(0);
            history.push("/MyNews");
          }
        }
      } else {
        removeLoader();
        resetNews(0);
        history.push("/MyNews");
      }
    }
  };

  const resetNews = (progressValue) => {
    setUploadProgress(progressValue);
    setCoverImg(null);
    setNewsFiles([]);
    setNewsDetails({
      albumName: "",
      newsDescription: "",
      newsTitle: "",
      newsSubtitle: "",
      //categoryList: musicCategories,
      selectedCategoryList: [],
    });
  };

  const uploadNewsFiles = async () => {
    if (newsFiles.length > 0) {
      let customHeaders = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer ",
      };
      let data = new FormData();
      newsFiles.map((m) => {
        data.append(constant.uplFileParameter, m);
      });
      let res = await postDataApi(
        //constant.baseURL + "newsfiles",
        constant.newsFileUplodaURL,
        data,
        customHeaders
      );
      //console.log("Upload news Files: ", res.data);
      if (
        res !== undefined &&
        res.status !== undefined &&
        res.status === 201 &&
        res.data !== undefined &&
        res.data.data !== undefined
      ) {
        //console.log("Uploaded news file: ", res.data.data);
        let coverFileName = "";
        coverFileName =
          coverImg !== null
            ? res.data.data.filename
            : id !== 0
            ? newsDetails.newsurlImage
            : "";
        setUploadProgress(25);
        saveNews(coverFileName);
      } else {
        removeLoader();
        addErrorMesage("Error in uploading podcast files");
      }
    } else {
      setUploadProgress(25);
      saveNews(newsDetails.newsurlImage);
    }
  };
  const getNewsByName = async () =>{
    let resNews = await getDataApi(
      constant.baseURL + "news?filter=title||$eq||"+newsDetails.newsTitle
    );
    return resNews;
  }
  const saveNews = async (coverImageName) => {
    if (currentUserInfo != undefined) {
      addLoader();
      let jsonData = {
        title: newsDetails.newsTitle,
        subtitle: newsDetails.newsSubtitle,
        description: newsDetails.newsDescription,
        artist: currentUserInfo.id,
        urlImage: coverImageName,
      };
      //console.log(jsonData);
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let respNews;
      let sStatus = "";
      if (id !== 0) {
        respNews = await patchDataApi(
          constant.baseURL + "news/" + id,
          formBody,
          authHeader
        );
        if (
          respNews != undefined &&
          respNews.status !== undefined &&
          respNews.status == 200
        ) {
          sStatus = "Success";
        }
      } else {
        respNews = await postDataApi(
          constant.baseURL + "news",
          formBody,
          authHeader
        );
        if (
          respNews !== undefined &&
          respNews.status !== undefined &&
          respNews.status == 201
        ) {
          sStatus = "Success";
        }
      }
      //console.log("respNews: ", respNews);
      //if (respNews !== undefined && respNews.status !== undefined && respNews.status == 201) {
      if (sStatus == "Success") {
        setUploadProgress(75);
        return saveNewsCategories(respNews.data.id).then((response) => {
          if (
            response !== undefined &&
            response.length > 0 &&
            response[0] == true
          ) {
            removeLoader();
            setUploadProgress(100);
            addSuccessMesage(
              id !== undefined ? "News Updated" : "News Created"
            );
            resetNews(0);
            history.push("/MyNews");
          } else {
            removeLoader();
            resetNews(0);
            history.push("/MyNews");
          }
        });
      } else {
        removeLoader();
        addErrorMesage("Error encountered while creating news!");
        resetNews(0);
        history.push("/MyNews");
      }
    }
  };
  const getSubcategories = async () => {
    let resSubcategories = await getDataApi(
      constant.baseURL + "subcategories?filter=category.id||$eq||4&filter=isDeleted||$eq||0"
    );
    //console.log("resSubcategories: ", resSubcategories);
    if (resSubcategories != undefined && resSubcategories.length > 0) {
      setNewsCategories([...resSubcategories]);
      setNewsDetails((prevState) => {
        return {
          ...prevState,
          categoryList: [...resSubcategories],
        };
      });
      if (id !== 0) {
        getNewsSubcategories(resSubcategories);
      }
    }
  };
  const getNewsSubcategories = async (categories) => {
    //console.log(id);
    let resSubcategories = await getDataApi(
      constant.baseURL + "selectednewscategories?filter=news.id||$eq||" + id
    );
    //console.log("getNewsSubcategories: ", resSubcategories);
    if (resSubcategories != undefined && resSubcategories.length > 0) {
      let sItems = [];
      resSubcategories.map((p) => {
        let fltCategories = categories.filter((c) => c.id == p.subcategory.id);
        sItems.push(fltCategories[0]);
      });
      setNewsDetails((prevState) => {
        return {
          ...prevState,
          selectedCategoryList: [...sItems],
          existingCategoryList: [...resSubcategories],
        };
      });
    }
  };
  const saveNewsCategories = async (newsId) => {
    let promises_array = [];
    newsDetails.selectedCategoryList.map((item) => {
      promises_array.push(
        new Promise((resolve, reject) => {
          let jsonData = { news: newsId, subcategory: item.id };
          return createNewsCategories(jsonData)
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(false);
            });
        })
      );
    });
    if (id !== 0 && newsDetails.existingCategoryList.length > 0) {
      //console.log("existingCategoryList", newsDetails.existingCategoryList);
      newsDetails.existingCategoryList.map((item) => {
        promises_array.push(
          new Promise((resolve, reject) => {
            let fExists = newsDetails.selectedCategoryList.filter(
              (x) => x.id == item.subcategory.id
            );
            if (fExists.length == 0) {
              return deleteNewsCategory(item.id)
                .then(() => {
                  resolve(true);
                })
                .catch((error) => {
                  reject(false);
                });
            } else {
              resolve(true);
            }
          })
        );
      });
    }
    return Promise.all(promises_array);
  };
  const createNewsCategories = async (jsonData) => {
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let resExits = await getDataApi(
      constant.baseURL +
        "selectednewscategories?filter=news.id||$eq||" +
        jsonData.news +
        "&filter=subcategory.id||$eq||" +
        jsonData.subcategory
    );
    //console.log("resExits: ", resExits);
    if (resExits.length == 0) {
      return await Promise.resolve(
        postDataApi(
          constant.baseURL + "selectednewscategories",
          formBody,
          authHeader
        )
      )
        .then((response) => {
          if (
            response != undefined &&
            response.status != undefined &&
            response.status == 201
          ) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          return false;
        });
    } else {
      return false;
    }
  };
  const remomvePodCategories = async () => {
    let promises_array = [];
    if (id !== 0 && newsDetails.existingCategoryList.length > 0) {
      newsDetails.existingCategoryList.map((item) => {
        promises_array.push(
          new Promise((resolve, reject) => {
            let fExists = newsDetails.selectedCategoryList.filter(
              (x) => x.id == item.subcategory.id
            );
            if (fExists.length == 0) {
              return deleteNewsCategory(item.id)
                .then(() => {
                  resolve(true);
                })
                .catch((error) => {
                  reject(false);
                });
            } else {
              resolve(true);
            }
          })
        );
      });
    }
    return Promise.all(promises_array);
  };
  const deleteNewsCategory = async (id) => {
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return await Promise.resolve(
      await deleteDataApi(
        constant.baseURL + "selectedNewscategories/" + id,
        authHeader
      )
    )
      .then((resDel) => {
        //console.log("resDel: ", resDel);
        if (resDel != undefined && resDel.status == 200) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
  };
  useEffect(() => {
    getSubcategories();
  }, []);
  return (
    <div>
      <section className="community-room-banner">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="upload-music-background">
                <div className="row">
                  <div className="col-12 col-md-12">
                    {coverImg === null ? (
                      <FileDrop
                        onDragOver={() =>
                          setDragNDropText("Drop your image here!!")
                        }
                        onDragLeave={() =>
                          setDragNDropText("Drop your file here")
                        }
                        onDrop={onDrop}
                      >
                        <div className="upload-background">
                          <img
                            className="upload-image-icon"
                            src={
                              newsDetails.newsurlImage != ""
                                ? constant.newsFileURL +
                                  newsDetails.newsurlImage
                                : uploadImage1
                            }
                            alt="upload picture"
                          />
                          <div className="upload-text">Upload Cover Image</div>
                          <div className="upload-text-subtitle">
                            Drop your file here or browse
                          </div>
                          <div className="upload-text-subtitle">Or</div>
                          <ImageUploader
                            className="imageUploaderNew"
                            withIcon={false}
                            buttonText="Browse"
                            label=""
                            onChange={(file) => onUploadImg(file)}
                            imgExtension={constant.supportedImageFiles}
                            singleImage={true}
                          />
                        </div>
                      </FileDrop>
                    ) : (
                      <FileDrop
                        onDragOver={() =>
                          setDragNDropText("Drop your image here!!")
                        }
                        onDragLeave={() =>
                          setDragNDropText("Drop your file here")
                        }
                        onDrop={onDrop}
                      >
                        <div className="upload-background">
                          <img
                            className="upload-image-icon"
                            style={{ height: 70, width: 70 }}
                            onClick={() => setCoverImg(null)}
                            id="uploaded_cover_img"
                            src={coverImg}
                            alt="cover-image"
                          />
                          <ImageUploader
                            className="imageUploaderNew"
                            withIcon={false}
                            buttonText="Browse"
                            label=""
                            onChange={(file) => onUploadImg(file)}
                            imgExtension={constant.supportedImageFiles}
                            singleImage={true}
                          />
                        </div>
                      </FileDrop>
                    )}
                    {isNewsImgeErrShow && (
                      <div className="errorText" style={{ marginTop: "0px" }}>
                        Please Upload Image File
                      </div>
                    )}

                    <div className="row featured-artists-title">
                      <div className="col-10">
                        {id !== 0 ? "Update News Details" : "News Details"}
                      </div>
                      {/* {isUploadPodcast ?
                        <div className="col-2 progress_bar">
                          <CircularProgressbar
                            value={uploadProgress}
                            maxValue={100}
                            minValue={0}
                            text={`${uploadProgress}%`}
                          />
                        </div> 
                         : null
                      } */}
                    </div>
                    <div className="floating-label mt-5">
                      <input
                        className="floating-input"
                        type="text"
                        maxLength={255}
                        placeholder=" "
                        onChange={(e) => {
                          setNewsDetails({
                            ...newsDetails,
                            newsTitle: e.target.value,
                          });
                          setNewsTitleErr(false);
                        }}
                        value={newsDetails.newsTitle}
                        required={true}
                      />
                      <span className="highlight"></span>
                      <label className="label">Title</label>
                      {isNewsTitleErrShow === true && (
                        <div className="errorText" style={{ marginTop: "0px" }}>
                          Enter title
                        </div>
                      )}
                    </div>
                    <div className="floating-label mt-5">
                      <input
                        className="floating-input"
                        type="text"
                        maxLength={255}
                        placeholder=" "
                        onChange={(e) => {
                          setNewsDetails({
                            ...newsDetails,
                            newsSubtitle: e.target.value,
                          });
                          setNewsSubTitleErr(false);
                        }}
                        value={newsDetails.newsSubtitle}
                        required={true}
                      />
                      <span className="highlight"></span>
                      <label className="label">Sub Title</label>
                      {isNewsSubTitleErrShow === true && (
                        <div className="errorText" style={{ marginTop: "0px" }}>
                          Enter sub title
                        </div>
                      )}
                    </div>
                    <div className="floating-label mt-5">
                      <textarea
                        className="floating-input"
                        rows="4"
                        type="text"
                        placeholder=" "
                        onChange={(e) => {
                          setNewsDetails({
                            ...newsDetails,
                            newsDescription: e.target.value,
                          });
                          setNewsDescErr(false);
                        }}
                        value={newsDetails.newsDescription}
                        required={true}
                      ></textarea>
                      <span className="highlight"></span>
                      <label className="label">Description</label>
                      {isNewsDescErrShow === true && (
                        <div className="errorText" style={{ marginTop: "0px" }}>
                          Enter description
                        </div>
                      )}
                    </div>
                    <div className="add-category mt-5">
                      <div className="floating-label0">
                        <Multiselect
                          options={newsDetails.categoryList}
                          selectedValues={newsDetails.selectedCategoryList}
                          onSelect={onSelectCategory}
                          onRemove={onRemoveSelectCategory}
                          displayValue="name"
                          placeholder="Add Category"
                          style={multiSelectStyle}
                          // showCheckbox={true}
                          // closeIcon="circle"
                          customCloseIcon={
                            <AiFillCloseSquare className="multiselect-close-icon" />
                          }
                        />
                        {isAddCategoryErrShow === true && (
                          <div
                            className="errorText"
                            style={{ marginTop: "0px" }}
                          >
                            Select category
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row justify-content-around">
                      <div className="col-6 col-md-4">
                        <button
                          className="cancel-btn"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => onClickCancel()}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-6 col-md-4">
                        <button
                          className="add-to-playlist-btn"
                          onClick={onUpload}
                          style={{ width: "100%" }}
                        >
                          {id === 0 ? "Save" : "Update"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default CreateNews;
