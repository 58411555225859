export const colors = {
    color1: '#FFFFFF',
    color2: '#181A20',
    color3: '#E6E2EF',
    color4: '#A198B4',
    color5: 'rgba(49,49,56,.1)',
    color6: '#FFD874',
    color7: '#D8A318',
    color8: '#FFFFFF3B',
    color9: '#FFFFFF1C',
    color10: '#8186AF',
    color11: 'linear-gradient(#FFD874, #D8A318)',
    color12: '#262931',
    colors13: '#121216',
    colors14: '#707070',
    color15: '#3B5998',
    color16: '#FF543E',
    color17: '#FF6A22',
    color18: '#55ACEE',
    color19: '#212330',
    color20: '#5B5F81',
}