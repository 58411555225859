import React, { useEffect, useRef, useState } from "react";
import {
  getDataApi,
  patchDataApi,
  postDataApi,
  deleteDataApi,
} from "../../utility/apiCall";
import { constant } from "../../utility/constant";
import validator from "validator";
import Multiselect from "multiselect-react-dropdown";
import { colors } from "../../utility/colors";
import ImageUploader from "react-images-upload";
import avatarImg from "../../assets/Images/user.png";
import {
  addErrorMesage,
  addSuccessMesage,
} from "../../components/CustomToster/CustomToaster";
import { Tabs, Tab, Modal, ModalBody, Button } from "react-bootstrap";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { AiFillWarning, AiFillCloseSquare, AiFillEdit } from "react-icons/ai";
import { addLoader, removeLoader } from "../../utility/CustomLoader";
import { useHistory } from "react-router-dom";
import Follows from "../../components/Follows/Follows";
import OtpScreenForEmailVerify from "../../components/OtpScreenForEmailVerify/OtpScreenForEmailVerify";
//const passwordReg = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,20}$/;
const passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;

const regexURL =
  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
export default function Profile() {
  var history = useHistory();
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const [profileReadOnly, setprofileReadOnly] = useState(true);
  const [showFollows, setShowFollows] = useState(false);
  const [activeTab, setActiveTab] = useState("Followers");
  const [artistsFollowedData, setArtistsFollowedData] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedUserPictue, setSelectedUserPicture] = useState([]);
  const [artistsFollowingData, setArtistsFollowingData] = useState([]);
  const multiSelectStyle = {
    multiselectContainer: {
      color: "white",
      fontSize: 14,
    },
    option: {
      // To change css for dropdown options
      color: colors.colors13,
    },
    chips: {
      background: colors.colors13,
      fontSize: 15,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 8,
      paddingBottom: 8,
      borderRadius: 8,
    },
    searchBox: {
      border: "1px solid #8186AF",
      borderRadius: "2px",
      //fontFamily: 'Mr Eaves Mod OT',
      fontSize: 15,
      minHeight: 40,
      //width: 395,
    },
  };

  const [personalInfoData, setPersonalInfoData] = useState({
    id: 0,
    profilImg: avatarImg,
    profilePicture: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    // title: "",
    biography: "",
    // fullAddress: "",
    userName: "",
    subscribed: false,
    countryCode: "",
    city: "",
    state: "",
    country: "",
    cityList: [],
    stateList: [],
    countryList: [],
    type: [],
    category: [],
    urlImage: "",
    usertype: "",
    countryId: 1,
    stateId: 1,
    cityId: 1,
  });
  const [basicInfo, setBasicInfo] = useState({
    profilImg: avatarImg,
    fullName: "",
    phoneNo: "",
    playlist: 0,
    followers: 0,
    following: 0,
  });

  // satyam Agarwal 22/09/21

  const [emailConfirmDialog, setEmailConfirmDialog] = useState(false);

  //const [activeTab, setActiveTab] = useState("personalInfo");
  const [personalTabMode, setPersonalTabMode] = useState("view");
  const [selectedTypes, setTypes] = useState([]);
  const [selectedCategory, setCategory] = useState([]);
  // const [readOnlySelectedTypes, setReadyOnlySelectedTypes] = useState([]);
  // const [readOnlySelectedCategory, setReadOnlySelectedCategory] = useState([]);
  const [existingUserCategoryList, setExistingUserCategoryList] = useState([]);
  const [flag, validate] = useState(false);
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [firstNameErrMsg, setFirstNameErrMsg] = useState("");
  const [lastNameErr, setLastNameErr] = useState(false);
  const [lastNameErrMsg, setLastNameErrMsg] = useState("");
  const [emailErr, setEmailErr] = useState(false);

  // satyam Change Email
  const [changeEmailErr, setChangeEmailErr] = useState(false);
  const [otpScreenHideOrShow, setOtpScreenHideOrShow] = useState(false);
  const [OTPEmailVerifyDetails, setOTPEmailVerifyDetails] = useState(null);

  const [inputVal, setInputVal] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
  });
  const input1Ref = useRef();
  const input2Ref = useRef();
  const input3Ref = useRef();
  const input4Ref = useRef();
  const input5Ref = useRef();
  const [OTPError, setError] = useState({ isOtpErrorShow: false });
  const [OTPErrormsg, setOTPErrorMsg] = useState("");

  const [emailErrMsg, setEmailErrMsg] = useState("");
  const [userNameErr, setuserNameErr] = useState(false);
  const [userNameErrMsg, setuserNameErrMsg] = useState("");
  const [phoneNoErr, setPhoneNoErr] = useState(false);
  const [phoneNoErrMsg, setPhoneNoErrMsg] = useState("");
  const [titleErr, setTitleErr] = useState(false);
  const [titleErrMsg, setTitleErrMsg] = useState("");
  const [biographyErr, setBiographyErr] = useState(false);
  const [biographyErrMsg, setBiographyErrMsg] = useState("");
  // const [fullAddressErr, setFullAddressErr] = useState(false);
  // const [fullAddressErrMsg, setFullAddressErrMsg] = useState("");
  const [typesErr, setTypesErr] = useState(false);
  const [typesErrMsg, setTypesErrMsg] = useState("");
  const [categoryErr, setCategoryErr] = useState(false);
  const [categoryErrMsg, setCategoryErrMsg] = useState("");
  const [isPasswordShow, setPasswordShow] = useState(true);
  const [isPasswordShow1, setPasswordShow1] = useState(true);
  const [isPasswordShow2, setPasswordShow2] = useState(true);

  // -----------------------------------
  const [switchState, setSwitchState] = React.useState({
    switch1: false,
    switch2: false,
    switch3: false,
    switch4: false,
  });
  const [subcategories, setSubcategories] = useState([]);
  const [settingsTabState, setSettingsTabState] = React.useState({
    streamingQualityList: [
      { id: 1, name: "High" },
      { id: 2, name: "Low" },
    ],
    downloadQualityList: [
      { id: 1, name: "High" },
      { id: 2, name: "Low" },
    ],
    selectedStreamingQuality: "",
    selectedDownloadQuality: "",
    downloadUsingCellular: false,
    offlineMode: false,
    compressAllMyTracks: false,
    isPasswordChangeSecShow: false,
    isCurrentPasswordShow: false,
    isNewPasswordShow: false,
    isConfirmPasswordShow: false,
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [currentPasswordErr, setCurrentPasswordErr] = useState({
    isCurrentPasswordErrShow: false,
    currentPasswordErrMsg: "",
  });
  const [newPasswordErr, setNewPasswordErr] = useState({
    isNewPasswordErrShow: false,
    newPasswordErrMsg: "",
  });
  const [confirmPasswordErr, seConfirmPasswordErr] = useState({
    isConfirmPasswordErrShow: false,
    confirmPasswordErrMsg: "",
  });
  const [socialMediaProfileVal, setSocialMediaProfileVal] = useState({
    facebook: "",
    instagram: "",
    youtube: "",
    tiktok: "",
    spotify: "",
    itune: "",
    apple: "",
    applePodcastURL: ""

  });
  const [isFacebookErrShow, setFacebookErr] = useState(false);
  const [isInstagramErrShow, setInstagramErr] = useState(false);
  const [isTwitterErrShow, setTwitterErr] = useState(false);
  const [countryId, setcountryId] = useState(personalInfoData.countryId);
  const [stateId, setstateId] = useState(personalInfoData.stateId);
  const getUserMiniDashBoard = async () => {
    if (currentUserInfo != undefined) {
      let resUseInfo = await getDataApi(
        constant.baseURL + "user/getUserMiniDashboard/" + currentUserInfo.id
      );
      //console.log("resUseInfo: ", resUseInfo);
      if (resUseInfo != undefined) {
        setBasicInfo((prevState) => {
          return {
            ...prevState,
            // profilImg: avatarImg,
            fullName:
              currentUserInfo != undefined
                ? currentUserInfo.firstName + " " + currentUserInfo.lastName
                : "",
            phoneNo: currentUserInfo != undefined ? currentUserInfo.phone : "",
            followers: resUseInfo.followed !== undefined ? resUseInfo.followed.length : "",
            following: resUseInfo.following !== undefined ? resUseInfo.following.length : "",
            playlist: resUseInfo.playlists !== undefined ? resUseInfo.playlists.length : "",
          };
        });
      }
    }
  };
  const getCountries = async () => {
    //console.log("Before Get Countries: ", personalInfoData);
    let resCountries = await getDataApi(constant.baseURL + "countries/getActiveCountries");
    if (resCountries != undefined && resCountries.length > 0) {
      //setCountries([...resCountries]);
      let fUserCountry = resCountries.filter(
        (f) => f.id == currentUserInfo.countryId
      );
      let countryName =
        fUserCountry.length > 0 ? fUserCountry[0].name : resCountries[0].name;
      //setPersonalInfoData({...personalInfoData, country: countryName, countryList: [...resCountries] });

      //console.log("Country name: ", countryName);

      setPersonalInfoData((prevState) => {
        return {
          ...prevState,
          country: countryName,
          countryCode:
            fUserCountry.length > 0 ? "+" + fUserCountry[0].callingCodes : "",
          countryList: [...resCountries],
          stateList: [],
          cityList: [],
        };
      });
      getStates(
        fUserCountry.length > 0 ? fUserCountry[0].id : resCountries[0].id
      );
      //console.log("After Get Countries: ", personalInfoData);
    }
  };
  // -------------------------getStates
  const getStates = async (countryId) => {
    let resStates = await getDataApi(
      constant.baseURL + "states/getActiveStatesByCountry/" + countryId
    );
    //console.log(resStates);
    if (resStates != undefined && resStates.length > 0) {
      //setStates([...resStates]);
      let fUserState = resStates.filter(
        (f) => f.id == personalInfoData.stateId
      );
      //setPersonalInfoData({...personalInfoData, state: (fUserState.length > 0) ? fUserState[0].name : '', stateList: resStates });
      setPersonalInfoData((prevState) => {
        return {
          ...prevState,
          state: fUserState.length > 0 ? fUserState[0].name : resStates[0].name,
          stateList: [...resStates],
          cityList: [],
        };
      });
      getCities(resStates[0].id);
    } else {
      setPersonalInfoData((prevState) => {
        return {
          ...prevState,
          state: "",
          stateList: [],
        };
      });
    }
  };

  // --------------------------get Cities
  const getCities = async (stateId) => {
    // window.alert("hi");
    let resCities = await getDataApi(
      constant.baseURL + "cities/getActiveCitiesByState/" + stateId
    );
    //console.log(resCities);
    if (resCities != undefined && resCities.length > 0) {
      //setCities([...resCities]);
      let fUserCity = resCities.filter((f) => f.id == personalInfoData.cityId);
      //setPersonalInfoData({...personalInfoData, city: (fUserCity.length > 0) ? fUserCity[0].name : '', cityList:  resCities});
      //console.log(fUserCity);
      setPersonalInfoData((prevState) => {
        return {
          ...prevState,
          city: fUserCity.length > 0 ? fUserCity[0].name : resCities[0].name,
          cityList: [...resCities],
        };
      });
    } else {
      setPersonalInfoData((prevState) => {
        return {
          ...prevState,
          city: "",
          cityList: [],
        };
      });
    }
  };
  // -------------------------------------getCategories

  const getCategories = async () => {
    let resCategories = await getDataApi(
      constant.baseURL + "categories?filter=isDeleted||$eq||0"
    );
    if (resCategories != undefined && resCategories.length > 0) {
      //setPersonalInfoData({ ...personalInfoData,  type:  resCategories});
      //setCategories([...resCategories]);
      setPersonalInfoData((prevState) => {
        return { ...prevState, type: [...resCategories] };
      });
      //set user selected types and category in case of user is Artist
      let resUserTypes = await getDataApi(
        constant.baseURL +
        "selectedcategories?filter=user.id||$eq||" +
        currentUserInfo.id+"&filter=subcategory.status||$eq||1&filter=subcategory.isDeleted||$eq||0"
      );
      //console.log(resUserTypes);
      if (resUserTypes != undefined && resUserTypes.length > 0) {
        let subTypeItems = [];
        resUserTypes.map((t) => {
          if (t.subcategory !== null) {
            subTypeItems.push(t.subcategory);
          }
        });
        setCategory(subTypeItems);
        setExistingUserCategoryList(resUserTypes);

        let typeItems = [];
        resUserTypes.map((t) => {
          let fltType = typeItems.filter((f) => f.id === t.category.id);
          if (fltType.length === 0) {
            typeItems.push(t.category);
          }
        });

        //console.log("Type:", typeItems);
        setTypes(typeItems);

        getSubcategoriesByCategories(typeItems);
      }
    }
  };
  // -----------------------------------------getSubCategories
  const getSubcategoriesByCategories = async (categories) => {
    //console.log("categories: ", categories);
    let arrCategories = [];
    if (categories !== undefined && categories.length > 0) {
      categories.map((c) => {
        arrCategories.push(c.id);
      });
    }
    let resSubcategories = await getDataApi(
      constant.baseURL +
      "subcategories?filter=isDeleted||$eq||0&filter=category.id||$in||" +
      arrCategories
    );
    //console.log("SubCategories:", resSubcategories);
    if (resSubcategories != undefined && resSubcategories.length > 0) {
      setSubcategories([...resSubcategories]);
      setPersonalInfoData((prevState) => {
        return { ...prevState, category: [...resSubcategories] };
      });
    }
  };
  const onSelectType = (selectValArr) => {
    setTypes(selectValArr);
    setTypesErr(false);
    loadSubcategoriesByCategory(selectValArr);
  };
  const onRemoveSelectedType = (removedValArr) => {
    setTypes(removedValArr);
    loadSubcategoriesByCategory(removedValArr);
  };

  const onSelectCat = (selectValArr) => {
    setCategory(selectValArr);
    setCategoryErr(false);
  };
  const onRemoveSelectedCat = (removedValArr) => {
    setCategory(removedValArr);
  };
  const loadSubcategoriesByCategory = (arrCategories) => {
    if (arrCategories.length > 0) {
      // //remove category if type is removed
      // let removeItem = selectedCategory;
      // for (var i = 0; i < removeItem.length; i++) {
      //   let flt = arrCategories.filter(
      //     (f) => f.id === removeItem[i].category.id
      //   );
      //   if (flt.length === 0) {
      //     removeItem.splice(i, 1);
      //     i--;
      //   }
      // }
      // console.log("removeItem: ", removeItem);
      // setTimeout(() => {
      //   setCategory(removeItem);
      //   console.log("Set Category Updated");
      // }, 200);
      getSubcategoriesByCategories(arrCategories);
    }
    // else if (arrCategories.length > 1) {
    //   getSubcategoriesByCategories(arrCategories);
    // }
    else {
      setTimeout(() => {
        setSubcategories([]);
        setCategory([]);
      }, 200);
    }
  };
  const getInputVal = (e) => {
    let text = e.target.value;
    let name = e.target.name;
    if (name === "phoneNo") {
      setPersonalInfoData((prevState) => {
        return { ...prevState, [name]: text.replace(/[^0-9]/, "") };
      });
      setPhoneNoErr(false);
    } else {
      setPersonalInfoData((prevState) => {
        return { ...prevState, [name]: text };
      });
      setSocialMediaProfileVal({ ...socialMediaProfileVal, [name]: text });
      if (name == "firstName") {
        setFirstNameErr(false);
        setPersonalInfoData((prevState) => {
          return { ...prevState, [name]: text };
        });
      }
      if (name == "lastName") {
        setLastNameErr(false);
        setPersonalInfoData((prevState) => {
          return { ...prevState, [name]: text };
        });
      }
      if (name == "email") {
        setEmailErr(false);
        if (text.replace(/\s/g, "").length) {
          setPersonalInfoData((prevState) => {
            return { ...prevState, [name]: text.trim() };
          });
        }
      }
      // if (name == "changeEmail") {
      //   setChangeEmailErr(false);
      //   if (text.replace(/\s/g, "").length) {
      //     setPersonalInfoData((prevState) => {
      //       return { ...prevState, [name]: text.trim() };
      //     });
      //   }
      // }
      if (name == "userName") {
        setuserNameErr(false);
        setPersonalInfoData((prevState) => {
          return { ...prevState, [name]: text };
        });
      }
      // if (name == "title") {
      //   setTitleErr(false);
      //   setPersonalInfoData((prevState) => {
      //     return { ...prevState, [name]: text };
      //   });
      // }
      if (name == "biography") {
        setBiographyErr(false);
        setPersonalInfoData((prevState) => {
          return { ...prevState, [name]: text };
        });
      }
      // if (name == "fullAddress") {
      //   setFullAddressErr(false);
      //   setPersonalInfoData((prevState) => {
      //     return { ...prevState, [name]: text };
      //   });
      // }
      if (name === "currentPassword") {
        if (text == "" || text.replace(/\s/g, "").length) {
          return setSettingsTabState({
            ...settingsTabState,
            [name]: text.trim(),
          });
        }
      }
      if (name === "newPassword") {
        if (text == "" || text.replace(/\s/g, "").length) {
          setSettingsTabState({
            ...settingsTabState,
            [name]: text.trim(),
            isNewPasswordErrShow: false,
            newPasswordErrMsg: "",
          });
        }
      }
      if (name == "confirmPassword") {
        if (text == "" || text.replace(/\s/g, "").length) {
          setSettingsTabState({
            ...settingsTabState,
            [name]: text.trim(),
            isConfirmPasswordErrShow: false,
            confirmPasswordErrMsg: "",
          });
        }
      }
      if (name == "city") {
        setPersonalInfoData((prevState) => {
          return { ...prevState, [name]: text };
        });
      }
      if (name == "state") {
        personalInfoData.stateList.map((item, key) => {
          if (item.name === text) {
            setstateId(key + 1);
            getCities(item.id);
          }
        });
        setPersonalInfoData((prevState) => {
          return { ...prevState, [name]: text };
        });
      }
      if (name == "country") {
        personalInfoData.countryList.map((item, key) => {
          if (item.name === text) {
            setcountryId(key + 1);
            getStates(item.id);
          }
        });
        setPersonalInfoData((prevState) => {
          return { ...prevState, [name]: text };
        });
      }
      if (name === "facebook") {
        // if (text === "" || regexURL.test(text) === true) {
        //   setFacebookErr(false);
        setSocialMediaProfileVal({ ...socialMediaProfileVal, [name]: text });
        // } else {
        //   setFacebookErr(true);
        // }
      } else if (name === "instagram") {
        // if (text === "" || regexURL.test(text) === true) {
        //   setInstagramErr(false);
        setSocialMediaProfileVal({ ...socialMediaProfileVal, [name]: text });
        // } else {
        //   setInstagramErr(true);
        // }
      } else if (name === "youtube") {
        // if (text === "" || regexURL.test(text) === true) {
        //   setTwitterErr(false);
        setSocialMediaProfileVal({ ...socialMediaProfileVal, [name]: text });
        // } else {
        //   setTwitterErr(true);
        // }
      } else if (name === "tiktok") {
        // if (text === "" || regexURL.test(text) === true) {
        //   setTwitterErr(false);
        setSocialMediaProfileVal({ ...socialMediaProfileVal, [name]: text });
        // } else {
        //   setTwitterErr(true);
        // }
      } else if (name === "spotify") {
        // if (text === "" || regexURL.test(text) === true) {
        //   setTwitterErr(false);
        setSocialMediaProfileVal({ ...socialMediaProfileVal, [name]: text });
        // } else {
        //   setTwitterErr(true);
        // }
      } else if (name === "itune") {
        // if (text === "" || regexURL.test(text) === true) {
        //   setTwitterErr(false);
        setSocialMediaProfileVal({ ...socialMediaProfileVal, [name]: text });
        // } else {
        //   setTwitterErr(true);
        // }
      } else if (name === "apple") {
        // if (text === "" || regexURL.test(text) === true) {
        //   setTwitterErr(false);
        setSocialMediaProfileVal({ ...socialMediaProfileVal, [name]: text });
        // } else {
        //   setTwitterErr(true);
        // }
      } else if (name === "applePodcastURL") {
        // if (text === "" || regexURL.test(text) === true) {
        //   setTwitterErr(false);
        setSocialMediaProfileVal({ ...socialMediaProfileVal, [name]: text });
        // } else {
        //   setTwitterErr(true);
        // }
      }
    }
  };
  const handleEditProfile = () => {
    setprofileReadOnly(false);
  };
  const updatePassword = () => {
    let isValid = false;
    setCurrentPasswordErr({
      currentPasswordErrMsg: "",
    });
    setNewPasswordErr({
      newPasswordErrMsg: "",
    });
    seConfirmPasswordErr({
      confirmPasswordErrMsg: "",
    });
    const { currentPassword, newPassword, confirmPassword } = settingsTabState;
    if (!currentPassword && !newPassword && !confirmPassword) {
      isValid = false;
      setCurrentPasswordErr({
        isCurrentPasswordErrShow: true,
        currentPasswordErrMsg: constant.currentPasswordRequired,
      });
      setNewPasswordErr({
        isNewPasswordErrShow: true,
        newPasswordErrMsg: constant.newPasswordRequired,
      });
      seConfirmPasswordErr({
        isConfirmPasswordErrShow: true,
        confirmPasswordErrMsg: constant.confirmPasswordRequired,
      });
    } else {
      //console.log("ELSE...");
      if (!currentPassword) {
        isValid = false;
        setCurrentPasswordErr({
          isCurrentPasswordErrShow: true,
          currentPasswordErrMsg: constant.currentPasswordRequired,
        });
      }
      if (
        !passwordReg.test(currentPassword)
      ) {
        //console.log("currentPassword invalid...");
        isValid = false;
        setCurrentPasswordErr({
          isCurrentPasswordErrShow: true,
          currentPasswordErrMsg: constant.currentPasswordValidation,
        });
      }
      if (!newPassword) {
        isValid = false;
        setNewPasswordErr({
          isNewPasswordErrShow: true,
          newPasswordErrMsg: constant.newPasswordRequired,
        });
      }
      if (
        !passwordReg.test(newPassword)
      ) {
        isValid = false;
        setNewPasswordErr({
          isNewPasswordErrShow: true,
          newPasswordErrMsg: constant.currentPasswordValidation,
          //"Your password must be at least 8 characters long, contain at least one special character(excluding @), number and have a mixture of uppercase and lowercase letters.",
        });
        return false;
      }
      if (!confirmPassword) {
        isValid = false;
        seConfirmPasswordErr({
          isConfirmPasswordErrShow: true,
          confirmPasswordErrMsg: constant.confirmPasswordRequired,
        });
      } else if (confirmPassword) {
        if (newPassword !== confirmPassword) {
          isValid = false;
          seConfirmPasswordErr({
            isConfirmPasswordErrShow: true,
            confirmPasswordErrMsg: constant.newConfirmPasswordSame,
          });
        } else {
          isValid = true;
        }
      }
      if (isValid) {
        validCurrentPassword(currentPassword);
      }
    }
  };

  const validCurrentPassword = async (currentPassword) => {
    addLoader();
    let jsonData = {
      id: personalInfoData.id,
      currentPassword: currentPassword,
    };
    //console.log(jsonData);
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let respChangePassword = await postDataApi(
      constant.baseURL + "user/validateUserPassword/",
      formBody,
      authHeader
    );
    //console.log("respChangePassword: ", respChangePassword);
    if (
      respChangePassword != undefined &&
      respChangePassword.status != undefined &&
      respChangePassword.status == 201 &&
      respChangePassword.data !== "" &&
      respChangePassword.data.data !== null
    ) {
      changePassword(settingsTabState.newPassword);
    } else {
      removeLoader();
      addErrorMesage(constant.enterCorrectCurrentPwd);
    }
  };

  const changePassword = async (newPassword) => {
    //change password in database
    let jsonData = { password: newPassword };
    //console.log(jsonData);
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let respChangePassword = await patchDataApi(
      constant.baseURL + "user/changePassword/" + personalInfoData.id,
      formBody,
      authHeader
    );
    //console.log("respChangePassword: ", respChangePassword);
    if (
      respChangePassword != undefined &&
      respChangePassword.status != undefined &&
      respChangePassword.status == 200
    ) {
      setSettingsTabState({
        ...settingsTabState,
        isPasswordChangeSecShow: false,
      });
      removeLoader();
      addSuccessMesage(constant.passwordChanged);
      setSettingsTabState((prevState) => {
        return {
          ...prevState,
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        };
      });
    }
  };

  // ----------------------------------------------------------Notification Section

  const enableDisableNotification = async (e) => {
    //console.log(e);
    let jsonData = { enableNotification: e.target.checked };
    //console.log(jsonData);
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let respUpdateNotification = await patchDataApi(
      constant.baseURL +
      "user/enableDisableNotification/" +
      personalInfoData.id,
      formBody,
      authHeader
    );
    //console.log("respUpdateNotification: ", respUpdateNotification);
    if (
      respUpdateNotification != undefined &&
      respUpdateNotification.data != undefined
    ) {
      addSuccessMesage(constant.notificationUpdated);
      setSwitchState({ ...switchState, switch1: !switchState.switch1 });
      refreshUserInfoLocalStorage(respUpdateNotification.data);
    }
  };
  const enableDisableRecommendedMusic = async (e) => {
    let jsonData = { recommendedMusic: e.target.checked };
    //console.log(jsonData);
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let respUpdateRecoMusic = await patchDataApi(
      constant.baseURL +
      "user/enableDisableRecommendedMusic/" +
      personalInfoData.id,
      formBody,
      authHeader
    );
    //console.log("respUpdateRecoMusic: ", respUpdateRecoMusic);
    if (
      respUpdateRecoMusic != undefined &&
      respUpdateRecoMusic.data != undefined
    ) {
      addSuccessMesage(constant.notificationUpdated);
      setSwitchState({ ...switchState, switch2: !switchState.switch2 });
      refreshUserInfoLocalStorage(respUpdateRecoMusic.data);
    }
  };

  const enableDisablePlaylistUpdates = async (e) => {
    let jsonData = { playlistUpdates: e.target.checked };
    //console.log(jsonData);
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let respPlayListUpdates = await patchDataApi(
      constant.baseURL +
      "user/enableDisablePlaylistUpdates/" +
      personalInfoData.id,
      formBody,
      authHeader
    );
    //console.log("respPlayListUpdates: ", respPlayListUpdates);
    if (
      respPlayListUpdates != undefined &&
      respPlayListUpdates.data != undefined
    ) {
      addSuccessMesage(constant.notificationUpdated);
      setSwitchState({ ...switchState, switch3: !switchState.switch3 });
      refreshUserInfoLocalStorage(respPlayListUpdates.data);
    }
  };
  const enableDisableArtistUpdates = async (e) => {
    let jsonData = { artistUpdates: e.target.checked };
    //console.log(jsonData);
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let respArtistUpdates = await patchDataApi(
      constant.baseURL +
      "user/enableDisableArtistUpdates/" +
      personalInfoData.id,
      formBody,
      authHeader
    );
    //console.log("respArtistUpdates: ", respArtistUpdates);
    if (respArtistUpdates != undefined && respArtistUpdates.data != undefined) {
      addSuccessMesage(constant.notificationUpdated);
      setSwitchState({ ...switchState, switch4: !switchState.switch4 });
      refreshUserInfoLocalStorage(respArtistUpdates.data);
    }
  };
  const refreshUserInfoLocalStorage = (userInfo) => {
    //console.log("refreshUserInfoLocalStorage: ");
    if (localStorage.getItem("UserInfo") != null) {
      localStorage.removeItem("UserInfo");
      localStorage.setItem("UserInfo", JSON.stringify(userInfo));
      let updatedUserInfo = undefined;
      if (localStorage.getItem("UserInfo") != null) {
        updatedUserInfo = JSON.parse(localStorage.getItem("UserInfo"));
        setCurrentUserInfo(
          localStorage.getItem("UserInfo") !== null
            ? JSON.parse(localStorage.getItem("UserInfo"))
            : undefined
        );
        //console.log("Profile currentUserInfo: ", updatedUserInfo);
      }
      setPersonalInfoData((prevState) => {
        return {
          ...prevState,
          id: updatedUserInfo != undefined ? updatedUserInfo.id : 0,
          // profilImg: avatarImg,
          profilePicture: "",
          firstName:
            updatedUserInfo != undefined ? updatedUserInfo.firstName : "",
          lastName:
            updatedUserInfo != undefined ? updatedUserInfo.lastName : "",
          email: updatedUserInfo != undefined ? updatedUserInfo.email : "",
          phoneNo: updatedUserInfo != undefined ? updatedUserInfo.phone : "",
          userName:
            updatedUserInfo != undefined ? updatedUserInfo.userName : "",
          // title: updatedUserInfo != undefined ? updatedUserInfo.title : "",
          biography:
            updatedUserInfo != undefined ? updatedUserInfo.biography : "",
          // fullAddress:
          //   updatedUserInfo != undefined ? updatedUserInfo.fullAddress : "",
          subscribed:
            updatedUserInfo != undefined
              ? updatedUserInfo.subscribeYouTubeChannel
              : false,
          urlImage:
            updatedUserInfo != undefined ? updatedUserInfo.urlImage : "",
        };
      });
      setBasicInfo((prevState) => {
        return {
          ...prevState,
          // profilImg: avatarImg,
          fullName:
            updatedUserInfo != undefined
              ? updatedUserInfo.firstName + " " + updatedUserInfo.lastName
              : "",
          phoneNo: updatedUserInfo != undefined ? updatedUserInfo.phone : "",
        };
      });

      setSocialMediaProfileVal((prevState) => {
        return {
          ...prevState,
          facebook:
            updatedUserInfo != undefined ? updatedUserInfo.facebookURL : "",
          instagram:
            updatedUserInfo != undefined ? updatedUserInfo.instagramURL : "",
          youtube:
            updatedUserInfo != undefined ? updatedUserInfo.youtubeURL : "",
          tiktok:
            updatedUserInfo != undefined ? updatedUserInfo.tikTokURL : "",
          spotify:
            updatedUserInfo != undefined ? updatedUserInfo.spotifyURL : "",
          itune:
            updatedUserInfo != undefined ? updatedUserInfo.iTunesURL : "",
          apple:
            updatedUserInfo != undefined ? updatedUserInfo.appleMusicURL : "",
          applePodcastURL:
            updatedUserInfo != undefined ? updatedUserInfo.applePodcastURL : "",
        };
      });
    }

  };
  const saveChanges = (usertype) => {
    if (usertype == "Artist") {
      const {
        firstName,
        lastName,
        email,
        phoneNo,
        userName,
        // fullAddress,
        // title,
        biography,
      } = personalInfoData;
      const { facebook, instagram, youtube, tiktok, spotify, itune, apple, applePodcastURL } = socialMediaProfileVal;
      let typeCondition = selectedTypes.length > 0 ? true : false;
      let catCondition = selectedCategory.length > 0 ? true : false;

      if (
        firstName === "" &&
        lastName === "" &&
        email === "" &&
        phoneNo === "" &&
        // title == "" &&
        userName === "" &&
        biography == "" &&
        // fullAddress === "" &&
        typeCondition &&
        catCondition
        //&& !facebook &&
        // !instagram &&
        // !twitter
      ) {
        setFirstNameErr(true);
        setLastNameErr(true);
        setEmailErr(true);
        setPhoneNoErr(true);
        setuserNameErr(true);
        // setTitleErr(true);
        setBiographyErr(true);
        // setFullAddressErr(true);
        setTypesErr(true);
        setCategoryErr(true);
        validate(false);
        // setFacebookErr(true);
        // setInstagramErr(true);
        // setTwitterErr(true);
        setuserNameErrMsg(constant.commonUserNameRequired);
        setFirstNameErrMsg(constant.firstNameRequired);
        setLastNameErrMsg(constant.lastNameRequired);
        setEmailErrMsg(constant.emailRequired);
        setTitleErrMsg(constant.titleRequired);
        setBiographyErrMsg(constant.biographyRequired);
        setTypesErrMsg(constant.typeRequired);
      } else {
        //console.log(firstName);
        if (firstName === "" || firstName === null) {
          setFirstNameErr(true);
          setFirstNameErrMsg(constant.firstNameRequired);
          validate(false);
          return;
        }
        if (lastName === null || lastName === "") {
          setLastNameErr(true);
          setLastNameErrMsg(constant.lastNameRequired);
          validate(false);
          return;
        }
        if (email === null || email === "") {
          setEmailErr(true);
          setEmailErrMsg(constant.emailRequired);
          validate(false);
          return;
        } else if (validator.isEmail(email) === false) {
          setEmailErr(true);
          setEmailErrMsg(constant.invalidEmailAddress);
          validate(false);
          return;
        }
        if (phoneNo === null || phoneNo === "") {
          setPhoneNoErr(true);
          setPhoneNoErrMsg(constant.phoneRequired);
          validate(false);
          return;
        }
        // if (title === null || title === "") {
        //   setTitleErr(true);
        //   setTitleErrMsg(constant.titleRequired);
        //   validate(false);
        //   return;
        // }
        if (userName === null || userName === "") {
          setuserNameErr(true);
          setuserNameErrMsg(constant.commonUserNameRequired);
          validate(false);
          return;
        }
        if (biography === null || biography === "") {
          setBiographyErr(true);
          setBiographyErrMsg(constant.biographyRequired);
          validate(false);
          return;
        }
        // if (fullAddress === null || fullAddress === "") {
        //   setFullAddressErr(true);
        //   setFullAddressErrMsg(constant.streetRequired);
        //   validate(false);
        //   return;
        // }
        if (typeCondition == false) {
          setTypesErr(true);
          setTypesErrMsg(constant.typeRequired);
          validate(false);
          return;
        }
        if (catCondition == false) {
          setCategoryErr(true);
          setCategoryErrMsg(constant.categoryRequired);
          validate(false);
          return;
        }
        // if (facebook !== "" && regexURL.test(facebook) === false) {
        //   setFacebookErr(true);
        //   validate(false);
        //   return;
        // } else if (instagram !== "" && regexURL.test(instagram) === false) {
        //   setInstagramErr(true);
        //   validate(false);
        //   return;
        // } else if (twitter !== "" && regexURL.test(twitter) === false) {
        //   setTwitterErr(true);
        //   validate(false);
        //   return;
        // }
        else {
          validate(true);
          setFirstNameErrMsg("");
          setLastNameErrMsg("");
          setEmailErrMsg("");
          // setTitleErrMsg("");
          setBiographyErrMsg("");
          setTypesErrMsg("");
          setCategoryErrMsg("");
          setPhoneNoErrMsg("");
          setuserNameErrMsg("");

          setBasicInfo((prevState) => {
            return {
              ...prevState,
              profilImg: personalInfoData.profilImg,
              fullName:
                firstName != undefined && lastName != undefined
                  ? firstName + "" + lastName
                  : "",
              phoneNo: phoneNo != undefined ? phoneNo : "",
            };
          });
          updatePersonalProfileInfo(personalInfoData.urlImage, usertype);
          // if (personalInfoData.profilePicture != "") {
          //   uploadProfileImage(usertype);
          // } else {
          //   updatePersonalProfileInfo(personalInfoData.urlImage, usertype);
          // }
        }
      }
    } else {
      const { firstName, lastName, email, userName, phoneNo } =
        personalInfoData;
      if (
        firstName === "" &&
        lastName === "" &&
        email === "" &&
        userName === "" &&
        phoneNo === ""
      ) {
        setFirstNameErr(true);
        setLastNameErr(true);
        setEmailErr(true);
        setuserNameErr(true);
        setuserNameErrMsg(constant.commonUserNameRequired);
        setEmailErrMsg(constant.emailRequired);
        setFirstNameErrMsg(constant.firstNameRequired);
        setLastNameErrMsg(constant.lastNameRequired);
        validate(false);
        return;
      } else {
        //console.log(firstName);
        if (firstName === "" || firstName === null) {
          setFirstNameErr(true);
          setFirstNameErrMsg(constant.firstNameRequired);
          validate(false);
          return;
        }
        if (lastName === null || lastName === "") {
          setLastNameErr(true);
          setLastNameErrMsg(constant.lastNameRequired);
          validate(false);
          return;
        }
        if (userName === null || userName === "") {
          setuserNameErr(true);
          setuserNameErrMsg(constant.commonUserNameRequired);
          validate(false);
          return;
        }
        if (phoneNo === null || phoneNo === "") {
          setPhoneNoErr(true);
          setPhoneNoErrMsg(constant.phoneRequired);
          validate(false);
          return;
        }
        if (email === null || email === "") {
          setEmailErr(true);
          setEmailErrMsg(constant.emailRequired);
          validate(false);
          return;
        } else if (validator.isEmail(email) === false) {
          setEmailErr(true);
          setEmailErrMsg(constant.invalidEmailAddress);
          validate(false);
          return;
        } else {
          validate(true);
          setFirstNameErrMsg("");
          setLastNameErrMsg("");
          setEmailErrMsg("");
          setBasicInfo((prevState) => {
            return {
              ...prevState,
              profilImg: personalInfoData.profilImg,
              fullName: firstName + "" + lastName,
            };
          });
          updatePersonalProfileInfo(personalInfoData.urlImage, usertype);
          // if (personalInfoData.profilePicture != "") {
          //   uploadProfileImage(usertype);
          // } else {
          //   updatePersonalProfileInfo(personalInfoData.urlImage, usertype);
          // }
        }
      }
    }
  };

  // ----------------------------changeEmail Satyam

  const changeEmail = () => {
    // console.log(
    //   "changeEmail.value",
    //   changeEmail.value,
    //   "personalInfoData.email",
    //   personalInfoData.email,
    //   currentUserInfo.email
    // );
    const { email } = personalInfoData;
    let flag = 0;
    if (currentUserInfo.email === email) {
      flag = 1;
      return addErrorMesage("You input same email");
    } else if (email === null || email === "") {
      flag = 1;
      // console.log("hello I am satyam");
      setEmailErr(true);
      setEmailErrMsg(constant.emailRequired);
      validate(false);
      return;
    } else if (validator.isEmail(email) === false) {
      flag = 1;
      // console.log("hello I am Agarwal");
      setEmailErr(true);
      setEmailErrMsg(constant.invalidEmailAddress);
      validate(false);
      return;
    }
    if (flag === 0) {
      //console.log("hello");
      addLoader();
      verifyEmailAndSendOTP();
    }
  };
  const verifyEmailAndSendOTP = async () => {
    let allowToCreateUser = true;
    let respUser = await getDataApi(
      constant.baseURL + "user?filter=email||$eq||" + personalInfoData.email
    );
    // console.log(
    //   constant.baseURL + "user?filter=email||$eq||" + personalInfoData.email
    // );
    //console.log("respUser", respUser);
    if (respUser !== undefined && respUser.length > 0) {
      removeLoader();
      allowToCreateUser = false;
      setEmailErr(true);
      // removeLoader();
      setEmailErrMsg(constant.emailRegistered);
      addErrorMesage(constant.emailRegistered);
    }
    if (allowToCreateUser === true) {
      // let jsonData0 = {
      //   email: personalInfoData.email,
      // };
      // localStorage.setItem("userEmail", personalInfoData.email);
      // localStorage.setItem("createAccountData", JSON.stringify(jsonData0));
      let otpCode = Math.floor(10000 + Math.random() * 90000);
      let jsonData = {
        firstName: currentUserInfo.firstName,
        lastName: currentUserInfo.lastName,
        email: personalInfoData.email,
        code: otpCode,
        subject: constant.otpSubject,
        body: `You requested for a Change Email from <b>${currentUserInfo.email}</b> to <b> ${personalInfoData.email} </b>, kindly use this code <b>${otpCode} </b>  to your current account.`,
        // "You requested for a Change Email , kindly use this code <b>" +
        // otpCode +
        // "</b> to your current account."
      };
      //console.log(jsonData);
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let responseOTP = await postDataApi(
        constant.baseURL + "user/verifyEmailAndSendOTP",
        jsonData,
        authHeader
      );
      //console.log("responseOTP: ", responseOTP);
      if (
        responseOTP !== undefined &&
        responseOTP.data !== undefined &&
        responseOTP.data.status !== undefined &&
        responseOTP.data.status === true
      ) {
        removeLoader();
        // localStorage.setItem(
        //   "OTPEmailVerifyDetails",
        //   JSON.stringify(responseOTP)
        // );
        setOTPEmailVerifyDetails(responseOTP.data.code);
        // console.log("hhiiiiiiiiiiiii");
        setOtpScreenHideOrShow(true);
        // history.push("otpverification");
      } else {
        addErrorMesage(constant.unableToValidateEmail);
        removeLoader();
        // history.push("otpverification");
      }
    }
  };

  const getEmailChangeInput = (e) => {
    let text = e.target.value;
    let name = e.target.name;
    //console.log(input1Ref);
    let inputType = e.nativeEvent.inputType;
    let numReg = new RegExp("^[0-9]$");
    if (text === "" || (numReg.test(text) && text.length <= 1)) {
      setInputVal({ ...inputVal, [name]: text });
      // //console.log(text)
      if (name === "input1") {
        if (inputType === "deleteContentBackward") {
          input1Ref.current.blur();
        } else {
          input1Ref.current.blur();
          input2Ref.current.focus();
        }
      }
      if (name === "input2") {
        if (inputType === "deleteContentBackward") {
          input2Ref.current.blur();
          input1Ref.current.focus();
        } else {
          input2Ref.current.blur();
          input3Ref.current.focus();
        }
      }
      if (name === "input3") {
        if (inputType === "deleteContentBackward") {
          input3Ref.current.blur();
          input2Ref.current.focus();
        }
        input3Ref.current.blur();
        input4Ref.current.focus();
      }
      if (name === "input4") {
        if (inputType === "deleteContentBackward") {
          input4Ref.current.blur();
          input3Ref.current.focus();
        }
        input4Ref.current.blur();
        input5Ref.current.focus();
      }
      if (name === "input5") {
        if (inputType === "deleteContentBackward") {
          input5Ref.current.blur();
          input4Ref.current.focus();
        }
      }
    }
  };
  const onEmailChangeSubmit = () => {
    //addLoader();
    const { input1, input2, input3, input4, input5 } = inputVal;
    if ((!input1 && !input2 && !input3, !input4 && !input5)) {
      setError({ isOtpErrorShow: true });
      removeLoader();
    }
    if (!input1) {
      setError({ isOtpErrorShow: true });
    }
    if (!input2) {
      setError({ isOtpErrorShow: true });
    }
    if (!input3) {
      setError({ isOtpErrorShow: true });
    }
    if (!input4) {
      setError({ isOtpErrorShow: true });
    }
    if (!input5) {
      setError({ isOtpErrorShow: true });
    }
    if (input1 && input2 && input3 && input4 && input5) {
      addLoader();
      setError({ isOtpErrorShow: false });

      if (
        OTPEmailVerifyDetails !== null &&
        OTPEmailVerifyDetails !== undefined
      ) {
        let otCode = input1 + input2 + input3 + input4 + input5;
        // console.log(
        //   "otCode",
        //   otCode,
        //   "hii",
        //   otCode.toString() === OTPEmailVerifyDetails.toString()
        // );
        if (otCode.toString() === OTPEmailVerifyDetails.toString()) {
          // addSuccessMesage("OTP verified");
          updateEmailByUser();
        } else {
          setInputVal({
            input1: "",
            input2: "",
            input3: "",
            input4: "",
            input5: "",
          });
          addErrorMesage("Entered code is not valid");
          removeLoader();
          //console.log("Entered code is not valid");
        }
      }
    }
  };
  const updateEmailByUser = async () => {
    let jsonData = {
      email: personalInfoData.email,
    };
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    // let apiName =
    //   personalInfoData.usertype == "Artist"
    //     ? "user/updatePersonalProfileInfo/"
    //     : "user/updateUserProfile/";

    //console.log("json", jsonData, constant.updateEmail + personalInfoData.id);
    let respUpdate = await patchDataApi(
      // constant.baseURL + apiName + personalInfoData.id,
      // formBody,
      // authHeader
      constant.updateEmail + personalInfoData.id,
      formBody,
      authHeader
    );
    if (
      respUpdate !== undefined &&
      respUpdate.data !== undefined &&
      respUpdate.status != undefined &&
      respUpdate.status == 200
    ) {
      //console.log("respUpdate", respUpdate);
      setSettingsTabState({
        ...settingsTabState,
        isPasswordChangeSecShow: false,
      });
      addSuccessMesage(constant.emailUpdated);
      removeLoader();
      refreshUserInfoLocalStorage(respUpdate.data);
    } else {
      removeLoader();
      addErrorMesage("Error in Email Update");
    }
  };

  const resendOTP = async () => {
    addLoader();

    let otpCode = Math.floor(10000 + Math.random() * 90000);
    let jsonData = {
      firstName: currentUserInfo.firstName,
      lastName: currentUserInfo.lastName,
      email: personalInfoData.email,
      code: otpCode,
      subject: constant.otpSubject,
      body: `You requested for a Change Email from <b>${currentUserInfo.email}</b> to <b>${personalInfoData.email}</b> , kindly use this code <b>${otpCode} </b> to your current account.`,
      // "You requested for a Change Email from <b> , kindly use this code <b>" +
      // otpCode +
      // "</b> to your current account.",
    };
    //console.log(jsonData);
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let responseOTP = await postDataApi(
      constant.baseURL + "user/verifyEmailAndSendOTP",
      jsonData,
      authHeader
    );
    //console.log("responseOTP: ", responseOTP);
    if (
      responseOTP !== undefined &&
      responseOTP.data !== undefined &&
      responseOTP.data.status !== undefined &&
      responseOTP.data.status == true
    ) {
      localStorage.setItem("OTPDetails", JSON.stringify(responseOTP));
      removeLoader();
      setOTPEmailVerifyDetails(responseOTP.data.code);
      addSuccessMesage("OTP Sent Successfully");
    } else {
      //console.log("Please Not");
      removeLoader();

      addErrorMesage("Unable to validate email!");
    }
  };

  const updatePersonalProfileInfo = async (profileImageName, usertype) => {
    let jsonData;
    addLoader();
    if (usertype == "Artist") {
      let fltCountries = personalInfoData.countryList.filter(
        (c) => c.name == personalInfoData.country
      );
      let fltStates = personalInfoData.stateList.filter(
        (c) => c.name == personalInfoData.state
      );
      let fltCities = personalInfoData.cityList.filter(
        (c) => c.name == personalInfoData.city
      );
      if (
        fltCountries.length > 0 &&
        fltCities.length > 0 &&
        fltStates.length > 0
      ) {
        jsonData = {
          email:
            personalInfoData.email != undefined &&
              personalInfoData.email != null
              ? personalInfoData.email.toString().trim()
              : "",
          firstName:
            personalInfoData.firstName != undefined &&
              personalInfoData.firstName != null
              ? personalInfoData.firstName.toString().trim()
              : "",
          lastName:
            personalInfoData.lastName != undefined &&
              personalInfoData.lastName != null
              ? personalInfoData.lastName.toString().trim()
              : "",
          urlImage: profileImageName,
          phone:
            personalInfoData.phoneNo != undefined &&
              personalInfoData.phoneNo != null
              ? personalInfoData.phoneNo.toString().trim()
              : "",
          // title:
          //   personalInfoData.title != undefined &&
          //   personalInfoData.title != null
          //     ? personalInfoData.title.toString().trim()
          //     : "",
          title: "",
          biography:
            personalInfoData.biography != undefined &&
              personalInfoData.biography != null
              ? personalInfoData.biography.toString().trim()
              : "",
          userName:
            personalInfoData.userName != undefined &&
              personalInfoData.userName != null
              ? personalInfoData.userName.toString().trim()
              : "",
          // fullAddress:
          //   personalInfoData.fullAddress != undefined &&
          //   personalInfoData.fullAddress != null
          //     ? personalInfoData.fullAddress.toString().trim()
          //     : "",
          fullAddress: "",
          countryId: fltCountries[0].id,
          stateId: fltStates[0].id,
          cityId: fltCities[0].id,
          subscribeYouTubeChannel: personalInfoData.subscribed == true ? 1 : 0,
          facebookURL:
            socialMediaProfileVal.facebook != undefined &&
              socialMediaProfileVal.facebook != null &&
              socialMediaProfileVal.facebook != ""
              ? socialMediaProfileVal.facebook
              : "",
          instagramURL:
            socialMediaProfileVal.instagram != undefined &&
              socialMediaProfileVal.instagram != null &&
              socialMediaProfileVal.instagram != ""
              ? socialMediaProfileVal.instagram
              : "",
          youtubeURL:
            socialMediaProfileVal.youtube != undefined &&
              socialMediaProfileVal.youtube != null &&
              socialMediaProfileVal.youtube != ""
              ? socialMediaProfileVal.youtube
              : "",
          tikTokURL:
            socialMediaProfileVal.tiktok != undefined &&
              socialMediaProfileVal.tiktok != null &&
              socialMediaProfileVal.tiktok != ""
              ? socialMediaProfileVal.tiktok
              : "",
          spotifyURL:
            socialMediaProfileVal.spotify != undefined &&
              socialMediaProfileVal.spotify != null &&
              socialMediaProfileVal.spotify != ""
              ? socialMediaProfileVal.spotify
              : "",
          iTunesURL:
            socialMediaProfileVal.itune != undefined &&
              socialMediaProfileVal.itune != null &&
              socialMediaProfileVal.itune != ""
              ? socialMediaProfileVal.itune
              : "",
          appleMusicURL:
            socialMediaProfileVal.apple != undefined &&
              socialMediaProfileVal.apple != null &&
              socialMediaProfileVal.apple != ""
              ? socialMediaProfileVal.apple
              : "",
          applePodcastURL:
            socialMediaProfileVal.applePodcastURL != undefined &&
              socialMediaProfileVal.applePodcastURL != null &&
              socialMediaProfileVal.applePodcastURL != ""
              ? socialMediaProfileVal.applePodcastURL
              : "",
        };
      }
    } else {
      jsonData = {
        email:
          personalInfoData.email != undefined && personalInfoData.email != null
            ? personalInfoData.email.toString().trim()
            : "",
        firstName:
          personalInfoData.firstName != undefined &&
            personalInfoData.firstName != null
            ? personalInfoData.firstName.toString().trim()
            : "",
        lastName:
          personalInfoData.lastName != undefined &&
            personalInfoData.lastName != null
            ? personalInfoData.lastName.toString().trim()
            : "",
        userName:
          personalInfoData.userName != undefined &&
            personalInfoData.userName != null
            ? personalInfoData.userName.toString().trim()
            : "",
        phone:
          personalInfoData.phoneNo != undefined &&
            personalInfoData.phoneNo != null
            ? personalInfoData.phoneNo.toString().trim()
            : "",
        urlImage: profileImageName,
        subscribeYouTubeChannel: personalInfoData.subscribed === true ? 1 : 0,
      };
    }
    //console.log(jsonData);
    if (jsonData != undefined) {
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let apiName =
        usertype == "Artist"
          ? "user/updatePersonalProfileInfo/"
          : "user/updateUserProfile/";
      let respUpdate = await patchDataApi(
        constant.baseURL + apiName + personalInfoData.id,
        formBody,
        authHeader
      );
      //console.log("respUpdate: ", respUpdate);
      if (
        respUpdate != undefined &&
        respUpdate.status != undefined &&
        respUpdate.status == 200
      ) {
        //console.log("respUpdate.data", respUpdate.data);
        refreshUserInfoLocalStorage(respUpdate.data);
        if (usertype == "Artist") {
          return deleteSetSelectedTypesAndCategoriesForUser().then((res) => {
            if (res == true) {
              return setSelectedTypesAndCategoriesForUser().then((response) => {
                if (
                  response != undefined &&
                  response.length > 0 &&
                  response[0] == true
                ) {
                  removeLoader();
                  addSuccessMesage(constant.profileUpdated);
                  setPersonalTabMode("view");
                  setprofileReadOnly(true);
                } else {
                  setPersonalTabMode("view");
                  setprofileReadOnly(true);
                }
              });
            } else {
              setPersonalTabMode("view");
              setprofileReadOnly(true);
            }
          });
        } else {
          removeLoader();
          addSuccessMesage(constant.profileUpdated);
          setPersonalTabMode("view");
          setprofileReadOnly(true);
        }
      } else {
        removeLoader();

        addErrorMesage(constant.errorWhileProfileUpdate);
        setPersonalTabMode("view");
        setprofileReadOnly(true);
      }
    }
  };
  const deleteSetSelectedTypesAndCategoriesForUser = async (id) => {
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return await Promise.resolve(
      deleteDataApi(
        constant.baseURL +
        "selectedcategories/deleteMultipleByUser/" +
        personalInfoData.id,
        authHeader
      )
    )
      .then((respDelete) => {
        //console.log("Delete Selected Category for User: ", respDelete);
        if (
          respDelete != undefined &&
          respDelete.status != undefined &&
          respDelete.status == 200
        ) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
  };
  const setSelectedTypesAndCategoriesForUser = async () => {
    let promises_array = [];
    //find main categogy which is not present in subcategory selection
    let citems = [];
    selectedTypes.map((c) => {
      let fltCategory = subcategories.filter(
        (f) => f.category !== undefined && f.category.id === c.id
      );
      if (fltCategory.length > 0) {
        let fltTypes = selectedCategory.filter(
          (f) => f.id === fltCategory[0].id
        );
        if (fltTypes.length == 0) {
          citems.push(c);
        }
      }
    });
    //console.log("citems:", citems);
    selectedCategory.map((catItem) => {
      promises_array.push(
        new Promise((resolve, reject) => {
          let fltCategory = subcategories.filter((f) => f.id == catItem.id);
          if (fltCategory.length > 0) {
            let jsonData = {
              user: personalInfoData.id,
              category: fltCategory[0].category.id,
              subcategory: fltCategory[0].id,
            };
            return callSetSelectedTypesAndCategoriesForUser(jsonData)
              .then(() => {
                resolve(true);
              })
              .catch((error) => {
                reject(false);
              });
          } else {
            resolve(true);
          }
        })
      );
    });
    //add only selected categories
    citems.map((catItem) => {
      promises_array.push(
        new Promise((resolve, reject) => {
          //let fltCategory = subcategories.filter((f) => f.id == catItem.id);
          //if (fltCategory.length > 0) {
          let jsonData = {
            user: personalInfoData.id,
            category: catItem.id,
          };
          return callSetSelectedTypesAndCategoriesForUser(jsonData)
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(false);
            });
          // } else {
          //   resolve(true);
          // }
        })
      );
    });

    //remove unselected categories that are present in database
    if (existingUserCategoryList.length > 0) {
      existingUserCategoryList.map((item) => {
        promises_array.push(
          new Promise((resolve, reject) => {
            let fExists = selectedTypes.filter(
              (x) => item.subcategory !== null && x.id == item.subcategory.id
            );
            if (fExists.length == 0) {
              return deleteUserCategories(item.id)
                .then(() => {
                  resolve(true);
                })
                .catch((error) => {
                  reject(false);
                });
            } else {
              resolve(true);
            }
          })
        );
      });
    }
    return Promise.all(promises_array);
  };
  const callSetSelectedTypesAndCategoriesForUser = async (jsonData) => {
    //console.log(jsonData);
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let resExits = await getDataApi(
      constant.baseURL +
      "selectedcategories?filter=category.id||$eq||" +
      jsonData.category +
      "&filter=subcategory.id||$eq||" +
      jsonData.subcategory +
      "&filter=user.id||$eq||" +
      jsonData.user
    );
    //console.log("resExits: ", resExits);
    if (resExits.length == 0) {
      return await Promise.resolve(
        postDataApi(
          constant.baseURL + "selectedcategories",
          formBody,
          authHeader
        )
      )
        .then((response) => {
          if (
            response != undefined &&
            response.status != undefined &&
            response.status == 201
          ) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          return false;
        });
    } else {
      return false;
    }
  };
  const deleteUserCategories = async (id) => {
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return await Promise.resolve(
      await deleteDataApi(
        constant.baseURL + "selectedcategories/" + id,
        authHeader
      )
    )
      .then((resDel) => {
        //console.log("resDel: ", resDel);
        if (resDel != undefined && resDel.status == 200) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
  };

  const confirmUploadProfilePic = () => {
    var imgSrc = "";
    //console.log("selectedUserPictue", selectedUserPictue);
    if (selectedUserPictue.length !== 0) {
      imgSrc = URL.createObjectURL(selectedUserPictue[0]);
    }

    // setPersonalInfoData((prevState) => {
    //   return {
    //     ...prevState,
    //     profilImg: imgSrc,
    //     profilePicture: selectedUserPictue,
    //   };
    // });
    uploadProfileImage(selectedUserPictue);
  };
  const cancelUploadProfilePic = () => {
    setShowConfirmModal(false);
  };
  const onUploadImg = (picture) => {
    //console.log("Selected Profile Image: ", picture);
    if (picture.length > 0) {
      setShowConfirmModal(true);
      setSelectedUserPicture(picture);
    }
  };
  const uploadProfileImage = async (selPicture) => {
    addLoader();
    setShowConfirmModal(false);
    let customHeaders = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer ",
    };
    let data = new FormData();
    data.append(constant.uplFileParameter, selPicture[0]);
    let res = await postDataApi(
      //constant.baseURL + "profilefiles",
      constant.profileFileUploadURL,
      data,
      customHeaders
    );
    //console.log("res", res);
    if (res.data.status === 200) {
      setPersonalInfoData((prevState) => {
        return { ...prevState, profilePicture: "" };
      });
      setSelectedUserPicture([]);
      updateUserProfilePicture(res.data.data.filename);
    } else {
      removeLoader();
    }
  };
  // ----------------added New Function
  const updateUserProfilePicture = async (profileImageName) => {
    let jsonData;
    jsonData = {
      urlImage: profileImageName,
    };
    //console.log(jsonData);
    if (jsonData != undefined) {
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let apiName = "user/updateUserProfilePicture/";

      let respUpdate = await patchDataApi(
        constant.baseURL + apiName + personalInfoData.id,
        formBody,
        authHeader
      );
      //console.log("respUpdate: ", respUpdate);
      if (
        respUpdate != undefined &&
        respUpdate.status != undefined &&
        respUpdate.status == 200
      ) {
        refreshUserInfoLocalStorage(respUpdate.data);
        removeLoader();
        addSuccessMesage(constant.profileUpdated);
        setShowConfirmModal(false);
        window.location.reload()
      } else {
        removeLoader();
        addErrorMesage(constant.errorWhileProfileUpdate);
        setShowConfirmModal(false);
      }
    }
  };
  // end

  const onUpdateCancel = () => {
    setprofileReadOnly(!profileReadOnly);
    setPersonalInfoData((prevState) => {
      return {
        ...prevState,
        id: currentUserInfo != undefined ? currentUserInfo.id : 0,
        profilImg: avatarImg,
        profilePicture: "",
        firstName:
          currentUserInfo != undefined ? currentUserInfo.firstName : "",
        lastName: currentUserInfo != undefined ? currentUserInfo.lastName : "",
        email: currentUserInfo != undefined ? currentUserInfo.email : "",
        phoneNo: currentUserInfo != undefined ? currentUserInfo.phone : "",
        userName: currentUserInfo != undefined ? currentUserInfo.userName : "",
        // title: currentUserInfo != undefined ? currentUserInfo.title : "",
        biography:
          currentUserInfo != undefined ? currentUserInfo.biography : "",
        // fullAddress:
        //   currentUserInfo != undefined ? currentUserInfo.fullAddress : "",
        subscribed:
          currentUserInfo != undefined
            ? currentUserInfo.subscribeYouTubeChannel
            : false,
        urlImage: currentUserInfo != undefined ? currentUserInfo.urlImage : "",
        usertype: currentUserInfo != undefined ? currentUserInfo.usertype : "",
        stateId: currentUserInfo != undefined ? currentUserInfo.stateId : "",
        countryId:
          currentUserInfo != undefined ? currentUserInfo.countryId : "",
        cityId: currentUserInfo != undefined ? currentUserInfo.cityId : "",
      };
    });
    setSocialMediaProfileVal((prevState) => {
      return {
        ...prevState,
        facebook:
          currentUserInfo != undefined ? currentUserInfo.facebookURL : "",
        instagram:
          currentUserInfo != undefined ? currentUserInfo.instagramURL : "",
        youtube: currentUserInfo != undefined ? currentUserInfo.youtubeURL : "",
        tiktok: currentUserInfo != undefined ? currentUserInfo.tikTokURL : "",
        spotify: currentUserInfo != undefined ? currentUserInfo.spotifyURL : "",
        itune: currentUserInfo != undefined ? currentUserInfo.iTunesURL : "",
        apple: currentUserInfo != undefined ? currentUserInfo.appleMusicURL : "",
        applePodcastURL: currentUserInfo != undefined ? currentUserInfo.applePodcastURL : "",
      };
    });
    setBasicInfo((prevState) => {
      return {
        ...prevState,
        profilImg: avatarImg,
        fullName:
          currentUserInfo != undefined
            ? currentUserInfo.firstName + " " + currentUserInfo.lastName
            : "",
        phoneNo: currentUserInfo != undefined ? currentUserInfo.phone : "",
      };
    });
    setSwitchState((prevState) => {
      return {
        ...prevState,
        switch1:
          currentUserInfo != undefined
            ? currentUserInfo.enableNotification
            : false,
        switch2:
          currentUserInfo != undefined
            ? currentUserInfo.recommendedMusic
            : false,
        switch3:
          currentUserInfo != undefined
            ? currentUserInfo.playlistUpdates
            : false,
        switch4:
          currentUserInfo != undefined ? currentUserInfo.artistUpdates : false,
      };
    });
    getCountries();
    getCategories();
    getUserMiniDashBoard();
  };
  const navigateToPlaylists = () => {
    history.push({
      pathname: `MyPlaylists`,
    });
  };
  const getArtistFollowers = async () => {
    //http://45.80.152.23:4527/follows?filter=follower.id||$eq||3
    if (currentUserInfo != undefined) {
      let resFollowed = await getDataApi(
        constant.baseURL +
        "follows?filter=follower.id||$eq||" +
        currentUserInfo.id
      );
      //console.log("resFollowed: ", resFollowed);
      if (resFollowed != undefined) {
        setArtistsFollowedData(resFollowed);
      }
    }
  };
  const getArtistFollowing = async () => {
    //http://45.80.152.23:4527/follows?filter=user.id||$eq||3
    if (currentUserInfo != undefined) {
      let resFollowing = await getDataApi(
        constant.baseURL + "follows?filter=user.id||$eq||" + currentUserInfo.id
      );
      //console.log("resFollowing: ", resFollowing);
      if (resFollowing != undefined) {
        setArtistsFollowingData(resFollowing);
      }
    }
  };
  const onClickGoPremium = () => {
    addErrorMesage("Comming Soon...");
  };
  useEffect(() => {
    if (
      localStorage.getItem("UserToken") === undefined ||
      localStorage.getItem("UserToken") === null ||
      localStorage.getItem("UserToken") === ""
    ) {
      history.push("/Home")
    }
    else {
      setPersonalInfoData((prevState) => {
        return {
          ...prevState,
          id: currentUserInfo != undefined ? currentUserInfo.id : 0,
          profilImg: avatarImg,
          profilePicture: "",
          firstName:
            currentUserInfo != undefined ? currentUserInfo.firstName : "",
          lastName: currentUserInfo != undefined ? currentUserInfo.lastName : "",
          userName: currentUserInfo != undefined ? currentUserInfo.userName : "",
          email: currentUserInfo != undefined ? currentUserInfo.email : "",
          phoneNo: currentUserInfo != undefined ? currentUserInfo.phone : "",
          // title: currentUserInfo != undefined ? currentUserInfo.title : "",
          biography:
            currentUserInfo != undefined ? currentUserInfo.biography : "",
          // fullAddress:
          //   currentUserInfo != undefined ? currentUserInfo.fullAddress : "",
          subscribed:
            currentUserInfo != undefined
              ? currentUserInfo.subscribeYouTubeChannel
              : false,
          urlImage: currentUserInfo != undefined ? currentUserInfo.urlImage : "",
          usertype: currentUserInfo != undefined ? currentUserInfo.usertype : "",
          stateId: currentUserInfo != undefined ? currentUserInfo.stateId : "",
          countryId:
            currentUserInfo != undefined ? currentUserInfo.countryId : "",
          cityId: currentUserInfo != undefined ? currentUserInfo.cityId : "",
        };
      });
      setSocialMediaProfileVal((prevState) => {
        return {
          ...prevState,
          facebook:
            currentUserInfo != undefined ? currentUserInfo.facebookURL : "",
          instagram:
            currentUserInfo != undefined ? currentUserInfo.instagramURL : "",
          youtube: currentUserInfo != undefined ? currentUserInfo.youtubeURL : "",
          tiktok: currentUserInfo != undefined ? currentUserInfo.tikTokURL : "",
          spotify: currentUserInfo != undefined ? currentUserInfo.spotifyURL : "",
          itune: currentUserInfo != undefined ? currentUserInfo.iTunesURL : "",
          apple: currentUserInfo != undefined ? currentUserInfo.appleMusicURL : "",
          applePodcastURL: currentUserInfo != undefined ? currentUserInfo.applePodcastURL : "",
        };
      });
      setBasicInfo((prevState) => {
        return {
          ...prevState,
          profilImg: avatarImg,
          fullName:
            currentUserInfo != undefined
              ? currentUserInfo.firstName + " " + currentUserInfo.lastName
              : "",
          phoneNo: currentUserInfo != undefined ? currentUserInfo.phone : "",
        };
      });
      setSwitchState((prevState) => {
        return {
          ...prevState,
          switch1:
            currentUserInfo != undefined
              ? currentUserInfo.enableNotification
              : false,
          switch2:
            currentUserInfo != undefined
              ? currentUserInfo.recommendedMusic
              : false,
          switch3:
            currentUserInfo != undefined
              ? currentUserInfo.playlistUpdates
              : false,
          switch4:
            currentUserInfo != undefined ? currentUserInfo.artistUpdates : false,
        };
      });
      getCountries();
      getCategories();
      getUserMiniDashBoard();
      getArtistFollowers();
      getArtistFollowing();
    }

  }, []);
  return (
    <section className="profile-banner">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4">
            <div className="profile-box-left">
              <div
                className="row justify-content-center"
                style={{ marginTop: 20 }}
              >
                <div className="col-auto">
                  <img
                    src={
                      personalInfoData.profilePicture == ""
                        ? personalInfoData.urlImage == undefined || personalInfoData.urlImage == null || personalInfoData.urlImage == "" ? personalInfoData.profilImg : constant.profileURL + personalInfoData.urlImage
                        : personalInfoData.profilImg
                    }
                    style={{
                      height: 100,
                      width: 100,
                      borderRadius: 55,
                    }}
                    alt={
                      personalInfoData.firstName +
                      " " +
                      personalInfoData.lastName
                    }
                  />
                </div>
                {/* <div className="edit-profile-icon"> */}

                <div id="divProfileImageUpload">
                  <ImageUploader
                    withIcon={false}
                    buttonClassName="plusButton"
                    buttonText={
                      <AiFillEdit
                        style={{
                          color: "white",
                          fontSize: "23px",
                        }}
                      />
                    }
                    label=""
                    onChange={(pic) => onUploadImg(pic)}
                    imgExtension={constant.supportedImageFiles}
                    singleImage={true}
                  ></ImageUploader>
                </div>
              </div>
              {/* <img
                className="profile-main-image"
                src={
                  personalInfoData.urlImage != undefined &&
                  personalInfoData.urlImage != ""
                    ? constant.profileURL + personalInfoData.urlImage
                    : constant.userImageURL
                }
                style={{ height: 143, borderRadius: 77 }}
                alt=""
              /> */}
              <div className="featured-artists-title mt-5">
                {basicInfo.fullName}
              </div>
              {personalInfoData.usertype === "Artist" && (
                <div className="biogrphy-detail mt-1 mb-2">
                  {personalInfoData.countryCode} {basicInfo.phoneNo}
                </div>
              )}
              {/* satyam agarwal  */}
              {/* {profileReadOnly === true ? (
                <button
                  className="add-to-playlist-btn"
                  onClick={() => handleEditProfile()}
                >
                  Edit Profile
                </button>
              ) : (
                <>
                  <div
                    className="row w100"
                    style={{ textAlign: "-webkit-center" }}
                  >
                    <div className="col-6">
                      <button
                        className="add-to-playlist-btn"
                        onClick={() => {
                          onUpdateCancel(); //setprofileReadOnly(!profileReadOnly);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        className="add-to-playlist-btn"
                        onClick={() => saveChanges(personalInfoData.usertype)}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </>
              )} */}

              <div className="profile-follower">
                <div className="row justify-content-center">
                  {(personalInfoData.usertype === "Artist" ||
                    personalInfoData.usertype === "User") && (
                     <> <div
                        className="col-3 profile-playlist cursor"
                        onClick={() => navigateToPlaylists()}
                      >
                        {basicInfo.playlist}{" "}
                        <span className="playlist-title">Playlist(s)</span>
                      </div>
                      <div className="col-1 playlist-title-2">|</div>
                      <div
                        className="col-3 profile-playlist cursor"
                        onClick={() => {
                          setActiveTab("Following");
                          setShowFollows(true);
                        }}
                      >
                        {basicInfo.following}{" "}
                        <span className="playlist-title">Following</span>
                      </div>
                      {showFollows === true && (
                        <Follows
                          show={showFollows}
                          handleClose={() => {
                            setShowFollows(false);
                          }}
                          activeTab={activeTab}
                          followedData={artistsFollowedData}
                          followingData={artistsFollowingData}
                          refreshData={() => {
                            getArtistFollowers();
                            getUserMiniDashBoard();
                          }}
                          flag="user"
                        />
                      )}
                      </>

                    )}
                  {personalInfoData.usertype === "Artist" && (
                    <>
                      <div className="col-1 playlist-title-2">|</div>
                      <div
                        className="col-3 profile-playlist cursor"
                        onClick={() => {
                          setActiveTab("Followers");
                          setShowFollows(true);
                        }}
                      >
                        {basicInfo.followers}{" "}
                        <span className="playlist-title">Follower(s)</span>
                      </div>
                      
                      {showFollows === true && (
                        <Follows
                          show={showFollows}
                          handleClose={() => {
                            setShowFollows(false);
                          }}
                          activeTab={activeTab}
                          followedData={artistsFollowedData}
                          followingData={artistsFollowingData}
                          refreshData={() => {
                            getArtistFollowers();
                            getUserMiniDashBoard();
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="profile-follower mt-3">
                <div
                  className="featured-artists-title"
                  style={{ minWidth: 281, padding: 10, textAlign: "center" }}
                >
                  Free Account
                </div>
                {/* <button className="premium-btn" onClick={onClickGoPremium}>Go Premium</button> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <div className="profile-box-right divTabs">
              <div style={{ width: "100%" }}>
                <Tabs
                  defaultActiveKey="PersonalInfo"
                  transition={false}
                  className="mb-3"
                >
                  <Tab eventKey="PersonalInfo" title="Personal Info">
                    <section>
                      <div className="profile-main">
                        {/* <div
                          className="row justify-content-center"
                          style={{ marginTop: 20 }}
                        >
                          <div className="col-auto">
                            <img
                              src={
                                personalInfoData.profilePicture == ""
                                  ? constant.profileURL +
                                    personalInfoData.urlImage
                                  : personalInfoData.profilImg
                              }
                              style={{
                                height: 100,
                                width: 100,
                                borderRadius: 55,
                              }}
                              alt={
                                personalInfoData.firstName +
                                " " +
                                personalInfoData.lastName
                              }
                            />
                          </div>
                         
                          {profileReadOnly == false && (
                            <div id="divProfileImageUpload">
                              <ImageUploader
                                withIcon={false}
                                buttonText="+"
                                label=""
                                onChange={(pic) => onUploadImg(pic)}
                                imgExtension={constant.supportedImageFiles}
                                singleImage={true}
                              ></ImageUploader>
                            </div>
                          )}
                        </div> */}
                      </div>
                      {/* ------------------------------------------------------profile Menu */}
                      {profileReadOnly === true ? (
                        <div className="profile-center">
                          <div className="profile-right-page">
                            <div className="row mt-0 mt-md-3">
                              <div className="col-12 col-md-6">
                                <div className="floating-label mmt ">
                                  <input
                                    className="floating-input"
                                    type="text"
                                    placeholder=" "
                                    value={personalInfoData.firstName}
                                    maxLength={25}
                                    readOnly
                                  />
                                  <span className="highlight"></span>
                                  <label className="label">First Name</label>
                                </div>
                              </div>
                              <div className="col-12 col-md-6">
                                <div className="floating-label mmt ">
                                  <input
                                    className="floating-input"
                                    type="text"
                                    placeholder=" "
                                    value={personalInfoData.lastName}
                                    maxLength={25}
                                    readOnly
                                  />
                                  <span className="highlight"></span>
                                  <label className="label">Last Name</label>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-0 mt-md-3">
                              <div
                                className={`${personalInfoData.usertype === "Artist"
                                  ? "col-12 col-md-6"
                                  : "col-12 col-md-6"
                                  }`}
                              >
                                <div className="floating-label mmt ">
                                  <input
                                    className="floating-input"
                                    type="text"
                                    placeholder=" "
                                    readOnly
                                    value={personalInfoData.email}
                                    maxLength={50}
                                  />
                                  <span className="highlight"></span>
                                  <label className="label">Email</label>
                                </div>
                              </div>
                              {/* {personalInfoData.usertype === "Artist" && ( */}
                              <div className="col-12 col-md-6">
                                <div className="floating-label mmt ">
                                  <input
                                    className="floating-input"
                                    type="text"
                                    placeholder=" "
                                    readOnly
                                    value={personalInfoData.phoneNo}
                                    maxLength={10}
                                  />
                                  <span className="highlight"></span>
                                  <label className="label">Phone Number</label>
                                </div>
                              </div>
                              {/* )} */}

                              <div className="col-12 mt-3">
                                <div className="floating-label">
                                  <input
                                    className="floating-input"
                                    type="text"
                                    placeholder=" "
                                    readOnly
                                    value={personalInfoData.userName}
                                  />
                                  <span className="highlight"></span>
                                  <label className="label">
                                    {personalInfoData.usertype === "Artist"
                                      ? "Artist Name"
                                      : "User Name"}
                                  </label>
                                </div>
                              </div>
                            </div>
                            {personalInfoData.usertype === "Artist" && (
                              <div>
                                <div className="row mt-3">
                                  <div className="col-12">
                                    <div className="floating-label">
                                      <textarea
                                        placeholder=" "
                                        className="floating-input"
                                        maxLength={255}
                                        readOnly
                                        value={personalInfoData.biography}
                                      >
                                        {/* {personalInfoData.biography} */}
                                      </textarea>
                                      {/* <input
                                    className="floating-input"
                                    style={{ height: "fit-content" }}
                                    type="text"
                                    placeholder=" "
                                    readOnly
                                    rows="4"
                                    value={personalInfoData.biography}
                                    maxLength={255}
                                  /> */}
                                      <span className="highlight"></span>
                                      <label className="label">Bio</label>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="row mt-3">
                                  <div className="col-12">
                                    <div className="floating-label">
                                      <textarea
                                        placeholder=" "
                                        className="floating-input"
                                        maxLength={255}
                                        readOnly
                                        value={personalInfoData.title}
                                      ></textarea>

                                      <span className="highlight"></span>
                                      <label className="label">Title</label>
                                    </div>
                                  </div>
                                </div> */}
                                <div className="row mt-0 mt-md-3">
                                  <div className="col-12">
                                    {/* satyam Agarwal Start 20/09/21 */}
                                    {/* <div className="floating-label">
                                      <textarea
                                        placeholder=" "
                                        className="floating-input"
                                        maxLength={255}
                                        readOnly
                                        value={personalInfoData.fullAddress}
                                      >
                                      </textarea>
                                     
                                      <span className="highlight"></span>
                                      <label className="label">Street</label>
                                    </div> */}
                                    {/* satyam Agarwal End 20/09/21 */}
                                  </div>
                                </div>
                                <div className="row mt-- mt-md-3">
                                  <div className="col-12 col-md-4">
                                    <div className="floating-label mmt">
                                      <input
                                        className="floating-input"
                                        type="text"
                                        placeholder=" "
                                        value={personalInfoData.country}
                                        readOnly
                                      />
                                      <span className="highlight"></span>
                                      <label className="label">Country</label>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-4">
                                    <div className="floating-label mmt">
                                      <input
                                        className="floating-input"
                                        type="text"
                                        placeholder=" "
                                        readOnly
                                        value={personalInfoData.state}
                                      />
                                      <span className="highlight"></span>
                                      <label className="label">State</label>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-4">
                                    <div className="floating-label mmt ">
                                      <input
                                        className="floating-input"
                                        type="text"
                                        placeholder=" "
                                        readOnly
                                        // value="hello"
                                        value={personalInfoData.city}
                                      />
                                      <span className="highlight"></span>
                                      <label className="label">City</label>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mt-3">
                                  <div className="col-12">
                                    <div className="floating-label mmt ">
                                      <Multiselect
                                        selectedValues={selectedTypes}
                                        displayValue="name"
                                        placeholder="Type"
                                        style={multiSelectStyle}
                                        disable
                                        customCloseIcon={
                                          <AiFillCloseSquare className="multiselect-close-icon" />
                                        }
                                      />
                                      <span className="highlight"></span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mt-3">
                                  <div className="col-12">
                                    <div className="floating-label">
                                      <Multiselect
                                        selectedValues={selectedCategory}
                                        displayValue="name"
                                        placeholder="Category"
                                        style={multiSelectStyle}
                                        disable
                                        customCloseIcon={
                                          <AiFillCloseSquare className="multiselect-close-icon" />
                                        }
                                      />
                                      <span className="highlight"></span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mt-0 mt-md-3">
                                  <div className="col-auto">
                                    <div className="social-media">
                                      Social Media
                                    </div>
                                  </div>
                                </div>

                                <div className="row mt-3">
                                  <div className="col-12">
                                    <div className="floating-label">
                                      <input
                                        className="floating-input"
                                        type="text"
                                        placeholder=" "
                                        value={
                                          socialMediaProfileVal.facebook === ""
                                            ? ""
                                            : socialMediaProfileVal.facebook
                                        }
                                        readOnly
                                      />
                                      <span className="highlight"></span>
                                      <label className="label">Facebook</label>
                                    </div>
                                  </div>
                                  <div className="col-6 mt-3"></div>
                                  <div className="col-12 mt-0 mt-md-3">
                                    <div className="floating-label mmt">
                                      <input
                                        className="floating-input"
                                        type="text"
                                        placeholder=" "
                                        value={
                                          socialMediaProfileVal.instagram === ""
                                            ? ""
                                            : socialMediaProfileVal.instagram
                                        }
                                        readOnly
                                      />
                                      <span className="highlight"></span>
                                      <label className="label">Instagram</label>
                                    </div>
                                  </div>
                                  <div className="col-6 mt-3"></div>
                                  <div className="col-12 mt-0 mt-md-3">
                                    <div className="floating-label mmt">
                                      <input
                                        className="floating-input"
                                        type="text"
                                        placeholder=" "
                                        value={
                                          socialMediaProfileVal.youtube === ""
                                            ? ""
                                            : socialMediaProfileVal.youtube
                                        }
                                        readOnly
                                      />
                                      <span className="highlight"></span>
                                      <label className="label">Youtube</label>
                                    </div>
                                  </div>
                                  <div className="col-6 mt-3"></div>
                                  <div className="col-12 mt-0 mt-md-3">
                                    <div className="floating-label mmt">
                                      <input
                                        className="floating-input"
                                        type="text"
                                        placeholder=" "
                                        value={
                                          socialMediaProfileVal.tiktok === ""
                                            ? ""
                                            : socialMediaProfileVal.tiktok
                                        }
                                        readOnly
                                      />
                                      <span className="highlight"></span>
                                      <label className="label">Tiktok</label>
                                    </div>
                                  </div>
                                  <div className="col-6 mt-3"></div>
                                  <div className="col-12 mt-0 mt-md-3">
                                    <div className="floating-label mmt">
                                      <input
                                        className="floating-input"
                                        type="text"
                                        placeholder=" "
                                        value={
                                          socialMediaProfileVal.spotify === ""
                                            ? ""
                                            : socialMediaProfileVal.spotify
                                        }
                                        readOnly
                                      />
                                      <span className="highlight"></span>
                                      <label className="label">Spotify</label>
                                    </div>
                                  </div>
                                  <div className="col-6 mt-3"></div>
                                  <div className="col-12 mt-0 mt-md-3">
                                    <div className="floating-label mmt">
                                      <input
                                        className="floating-input"
                                        type="text"
                                        placeholder=" "
                                        value={
                                          socialMediaProfileVal.itune === ""
                                            ? ""
                                            : socialMediaProfileVal.itune
                                        }
                                        readOnly
                                      />
                                      <span className="highlight"></span>
                                      <label className="label">iTune</label>
                                    </div>
                                  </div>
                                  <div className="col-6 mt-3"></div>
                                  <div className="col-12 mt-0 mt-md-3">
                                    <div className="floating-label mmt">
                                      <input
                                        className="floating-input"
                                        type="text"
                                        placeholder=" "
                                        value={
                                          socialMediaProfileVal.apple === ""
                                            ? ""
                                            : socialMediaProfileVal.apple
                                        }
                                        readOnly
                                      />
                                      <span className="highlight"></span>
                                      <label className="label">Apple music</label>
                                    </div>
                                  </div>
                                  <div className="col-6 mt-3"></div>
                                  <div className="col-12 mt-0 mt-md-3">
                                    <div className="floating-label mmt">
                                      <input
                                        className="floating-input"
                                        type="text"
                                        placeholder=" "
                                        value={
                                          socialMediaProfileVal.applePodcastURL === ""
                                            ? ""
                                            : socialMediaProfileVal.applePodcastURL
                                        }
                                        readOnly
                                      />
                                      <span className="highlight"></span>
                                      <label className="label">Apple Podcast</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="row mt-3">
                              <div className="col-12">
                                <div className="selection-page-2">
                                  Subscribe to our YouTube Channel
                                  <span>
                                    <div className="custom-control custom-checkbox mr-sm-2">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customControlAutosizing"
                                        checked={
                                          personalInfoData.subscribed === true
                                        }
                                        readOnly
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="customControlAutosizing"
                                      ></label>
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        //   --------------------------------------------user Read Only False
                        <div className="profile-center">
                          <div className="profile-right-page">
                            <div className="row mt-3">
                              <div className="col-12 col-md-6">
                                <div className="floating-label">
                                  <input
                                    className="floating-input"
                                    type="text"
                                    placeholder=" "
                                    value={personalInfoData.firstName}
                                    onChange={getInputVal}
                                    name="firstName"
                                    autoFocus
                                    maxLength={25}
                                  />
                                  <span className="highlight"></span>
                                  <label className="label">First Name</label>
                                </div>
                                {firstNameErr === true && (
                                  <div className="errorText">
                                    {firstNameErrMsg}
                                  </div>
                                )}
                              </div>
                              <div className="col-12 col-md-6">
                                <div className="floating-label">
                                  <input
                                    className="floating-input"
                                    type="text"
                                    placeholder=" "
                                    onChange={getInputVal}
                                    name="lastName"
                                    maxLength={25}
                                    value={personalInfoData.lastName}
                                  />
                                  <span className="highlight"></span>
                                  <label className="label">Last Name</label>
                                </div>
                                {lastNameErr === true && (
                                  <div className="errorText">
                                    {lastNameErrMsg}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div
                                className={`${personalInfoData.usertype === "Artist"
                                  ? "col-12 col-md-6"
                                  : "col-12"
                                  }`}
                              >
                                {/* <div className="floating-label">
                                  <input
                                    className="floating-input"
                                    type="text"
                                    placeholder=" "
                                    onChange={getInputVal}
                                    name="email"
                                    maxLength={50}
                                    value={personalInfoData.email}
                                    readOnly
                                  />
                                  <span className="highlight"></span>
                                  <label className="label">Email</label>
                                </div>
                                {emailErr === true && (
                                  <div className="errorText">{emailErrMsg}</div>
                                )} */}
                              </div>
                              {/* {personalInfoData.usertype === "Artist" && ( */}
                              <div className="col-12 col-md-12">
                                <div className="floating-label">
                                  <input
                                    className="floating-input"
                                    type="text"
                                    placeholder=" "
                                    onChange={getInputVal}
                                    name="phoneNo"
                                    maxLength={10}
                                    value={personalInfoData.phoneNo}
                                  />
                                  <span className="highlight"></span>
                                  <label className="label">Phone Number</label>
                                </div>
                                {phoneNoErr === true && (
                                  <div className="errorText">
                                    {phoneNoErrMsg}
                                  </div>
                                )}
                              </div>
                              <div className="col-12 mt-3">
                                <div className="floating-label">
                                  <input
                                    className="floating-input"
                                    type="text"
                                    placeholder=" "
                                    value={personalInfoData.userName}
                                    onChange={getInputVal}
                                    name="userName"
                                  />
                                  <span className="highlight"></span>
                                  <label className="label">
                                    {personalInfoData.usertype === "Artist"
                                      ? "Artist Name"
                                      : "User Name"}
                                  </label>
                                </div>
                                {userNameErr === true && (
                                  <div className="errorText">
                                    {userNameErrMsg}
                                  </div>
                                )}
                              </div>
                              {/* )} */}
                            </div>
                            {personalInfoData.usertype === "Artist" && (
                              <div>
                                <div className="row mt-3">
                                  <div className="col-12">
                                    <div className="floating-label">
                                      <textarea
                                        placeholder=" "
                                        className="floating-input"
                                        maxLength={255}
                                        onChange={getInputVal}
                                        name="biography"
                                        value={personalInfoData.biography}
                                      ></textarea>
                                      <span className="highlight"></span>
                                      <label className="label">Bio</label>
                                    </div>
                                    {biographyErr === true && (
                                      <div className="errorText">
                                        {biographyErrMsg}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {/* <div className="row mt-3">
                                  <div className="col-12">
                                    <div className="floating-label">
                                      <textarea
                                        placeholder=" "
                                        className="floating-input"
                                        maxLength={255}
                                        value={personalInfoData.title}
                                        onChange={getInputVal}
                                        name="title"
                                      ></textarea>
                                      <span className="highlight"></span>
                                      <label className="label">Title</label>
                                    </div>
                                    {titleErr === true && (
                                      <div className="errorText">
                                        {titleErrMsg}
                                      </div>
                                    )}
                                  </div>
                                </div> */}
                                <div className="row mt-3">
                                  {/* satyam Agarwal Start 20/09/21 */}
                                  {/* <div className="col-12">
                                    <div className="floating-label">
                                      <textarea
                                        placeholder=" "
                                        className="floating-input"
                                        maxLength={255}
                                        onChange={getInputVal}
                                        value={personalInfoData.fullAddress}
                                        name="fullAddress"
                                      ></textarea>
                                      <span className="highlight"></span>
                                      <label className="label">Street</label>
                                    </div>
                                    {fullAddressErr === true && (
                                      <div className="errorText">
                                        {fullAddressErrMsg}
                                      </div>
                                    )}
                                  </div> */}
                                  {/* satyam Agarwal End 20/09/21 */}
                                </div>
                                <div className="row mt-3">
                                  <div className="col-12 col-md-4">
                                    <div className="floating-label">
                                      <select
                                        className="floating-input floating-select1"
                                        placeholder=" "
                                        name="country"
                                        value={personalInfoData.country}
                                        onChange={getInputVal}
                                      >
                                        {personalInfoData.countryList.map(
                                          (option) => (
                                            <option
                                              key={option.name}
                                              value={option.name}
                                            >
                                              {option.name}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <span className="highlight"></span>
                                      <label className="label">Country</label>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-4">
                                    <div className="floating-label">
                                      <select
                                        className="floating-input floating-select1"
                                        placeholder=" "
                                        name="state"
                                        value={personalInfoData.state}
                                        onChange={getInputVal}
                                      >
                                        {personalInfoData.stateList.map(
                                          (option) => (
                                            <option
                                              key={option.name}
                                              value={option.name}
                                            >
                                              {option.name}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <span className="highlight"></span>
                                      <label className="label">State</label>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-4">
                                    <div className="floating-label">
                                      <select
                                        className="floating-input floating-select1"
                                        placeholder=" "
                                        name="city"
                                        value={personalInfoData.city}
                                        onChange={getInputVal}
                                      >
                                        {personalInfoData.cityList.map(
                                          (option, key) => (
                                            <option
                                              key={key}
                                              value={option.name}
                                            >
                                              {option.name}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <span className="highlight"></span>
                                      <label className="label">City</label>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mt-3">
                                  <div className="col-12">
                                    <div className="floating-label0">
                                      <Multiselect
                                        options={personalInfoData.type}
                                        selectedValues={selectedTypes}
                                        onSelect={onSelectType}
                                        onRemove={onRemoveSelectedType}
                                        displayValue="name"
                                        placeholder="Type"
                                        style={multiSelectStyle}
                                        // closeIcon="circle"
                                        customCloseIcon={
                                          <AiFillCloseSquare className="multiselect-close-icon" />
                                        }
                                      />
                                    </div>
                                    {typesErr === true && (
                                      <div className="errorText">
                                        {typesErrMsg}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="row mt-3">
                                  <div className="col-12">
                                    <div className="floating-label0">
                                      <Multiselect
                                        options={personalInfoData.category}
                                        selectedValues={selectedCategory}
                                        onSelect={onSelectCat}
                                        onRemove={onRemoveSelectedCat}
                                        displayValue="name"
                                        placeholder="Category"
                                        style={multiSelectStyle}
                                        customCloseIcon={
                                          <AiFillCloseSquare className="multiselect-close-icon" />
                                        }
                                      />
                                    </div>
                                    {categoryErr === true && (
                                      <div className="errorText">
                                        {categoryErrMsg}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="row mt-3">
                                  <div className="col-auto">
                                    <div className="social-media">
                                      Social Media
                                    </div>
                                  </div>
                                </div>
                                <div className="row mt-3">
                                  <div className="col-12">
                                    <div className="floating-label">
                                      <input
                                        className="floating-input"
                                        type="text"
                                        placeholder=" "
                                        name="facebook"
                                        onChange={getInputVal}
                                        value={socialMediaProfileVal.facebook}
                                      />
                                      <span className="highlight"></span>
                                      <label className="label">Facebook</label>
                                    </div>
                                    {/* <div className="infoMessage">
                                        Type facebook name only
                                      </div> */}
                                    {/* {isFacebookErrShow === true && (
                                      <div className="errorText">
                                        {constant.invalidURL}
                                      </div>
                                    )} */}
                                  </div>
                                  <div className="col-12 mt-3">
                                    <div className="floating-label">
                                      <input
                                        className="floating-input"
                                        type="text"
                                        placeholder=" "
                                        name="instagram"
                                        onChange={getInputVal}
                                        value={socialMediaProfileVal.instagram}
                                      />
                                      <span className="highlight"></span>
                                      <label className="label">Instagram</label>
                                    </div>
                                    {/* {isInstagramErrShow === true && (
                                      <div className="errorText">
                                        {constant.invalidURL}
                                      </div>
                                    )} */}
                                  </div>
                                  <div className="col-12 mt-3">
                                    <div className="floating-label">
                                      <input
                                        className="floating-input"
                                        type="text"
                                        placeholder=" "
                                        name="youtube"
                                        onChange={getInputVal}
                                        value={socialMediaProfileVal.youtube}
                                      />
                                      <span className="highlight"></span>
                                      <label className="label">Youtube</label>
                                    </div>
                                    {/* {isTwitterErrShow === true && (
                                      <div className="errorText">
                                        {constant.invalidURL}
                                      </div>
                                    )} */}
                                  </div>
                                  <div className="col-12 mt-3">
                                    <div className="floating-label">
                                      <input
                                        className="floating-input"
                                        type="text"
                                        placeholder=" "
                                        name="tiktok"
                                        onChange={getInputVal}
                                        value={socialMediaProfileVal.tiktok}
                                      />
                                      <span className="highlight"></span>
                                      <label className="label">Tiktok</label>
                                    </div>
                                    {/* {isTwitterErrShow === true && (
                                      <div className="errorText">
                                        {constant.invalidURL}
                                      </div>
                                    )} */}
                                  </div>
                                  <div className="col-12 mt-3">
                                    <div className="floating-label">
                                      <input
                                        className="floating-input"
                                        type="text"
                                        placeholder=" "
                                        name="spotify"
                                        onChange={getInputVal}
                                        value={socialMediaProfileVal.spotify}
                                      />
                                      <span className="highlight"></span>
                                      <label className="label">Spotify</label>
                                    </div>
                                    {/* {isTwitterErrShow === true && (
                                      <div className="errorText">
                                        {constant.invalidURL}
                                      </div>
                                    )} */}
                                  </div>
                                  <div className="col-12 mt-3">
                                    <div className="floating-label">
                                      <input
                                        className="floating-input"
                                        type="text"
                                        placeholder=" "
                                        name="itune"
                                        onChange={getInputVal}
                                        value={socialMediaProfileVal.itune}
                                      />
                                      <span className="highlight"></span>
                                      <label className="label">iTune</label>
                                    </div>
                                    {/* {isTwitterErrShow === true && (
                                      <div className="errorText">
                                        {constant.invalidURL}
                                      </div>
                                    )} */}
                                  </div>
                                  <div className="col-12 mt-3">
                                    <div className="floating-label">
                                      <input
                                        className="floating-input"
                                        type="text"
                                        placeholder=" "
                                        name="apple"
                                        onChange={getInputVal}
                                        value={socialMediaProfileVal.apple}
                                      />
                                      <span className="highlight"></span>
                                      <label className="label">Apple music</label>
                                    </div>
                                    {/* {isTwitterErrShow === true && (
                                      <div className="errorText">
                                        {constant.invalidURL}
                                      </div>
                                    )} */}
                                  </div>
                                  <div className="col-12 mt-3">
                                    <div className="floating-label">
                                      <input
                                        className="floating-input"
                                        type="text"
                                        placeholder=" "
                                        name="applePodcastURL"
                                        onChange={getInputVal}
                                        value={socialMediaProfileVal.applePodcastURL}
                                      />
                                      <span className="highlight"></span>
                                      <label className="label">Apple Podcast</label>
                                    </div>
                                    {/* {isTwitterErrShow === true && (
                                      <div className="errorText">
                                        {constant.invalidURL}
                                      </div>
                                    )} */}
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="row mt-3">
                              <div className="col-12">
                                <div className="selection-page-2">
                                  Subscribe to our YouTube Channel
                                  <span>
                                    <div className="custom-control custom-checkbox mr-sm-2">
                                      {personalInfoData.subscribed === true ? (
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="customControlAutosizing"
                                          onClick={() => {
                                            setPersonalInfoData((prevState) => {
                                              return {
                                                ...prevState,
                                                subscribed: false,
                                              };
                                            });
                                          }}
                                          checked={
                                            personalInfoData.subscribed === true
                                          }
                                        />
                                      ) : (
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="customControlAutosizing"
                                          onClick={() => {
                                            setPersonalInfoData((prevState) => {
                                              return {
                                                ...prevState,
                                                subscribed: true,
                                              };
                                            });
                                          }}
                                          checked={
                                            personalInfoData.subscribed === true
                                          }
                                        />
                                      )}
                                      <label
                                        className="custom-control-label"
                                        htmlFor="customControlAutosizing"
                                      ></label>
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {profileReadOnly === true ? (
                        <div className="profile-end">
                          <div className="row justify-content-center mt-3">
                            <div
                              className="col-5"
                              style={{ textAlign: "-webkit-center" }}
                            >
                              <button
                                className="add-to-playlist-btn"
                                onClick={() => handleEditProfile()}
                              >
                                Edit Profile
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="profile-end">
                          <div
                            className="row justify-content-center mt-3"
                            style={{ textAlign: "-webkit-center" }}
                          >
                            <div className="col-5">
                              <button
                                className="add-to-playlist-btn"
                                onClick={() => {
                                  onUpdateCancel(); //setprofileReadOnly(!profileReadOnly);
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="col-5">
                              <button
                                className="add-to-playlist-btn"
                                onClick={() =>
                                  saveChanges(personalInfoData.usertype)
                                }
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </section>
                  </Tab>
                  {personalInfoData.usertype === "Artist" && (
                    <Tab eventKey="Notification" title="Notification">
                      <section>
                        <div className="profile-main">
                          <div className="profile-notification">
                            <div className="row">
                              <div className="col-12">
                                <div className="row justify-content-between align-items-center">
                                  <div className="col-auto profile-notification-text">
                                    Enable Notification
                                  </div>
                                  <div className="col-auto">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        checked={switchState.switch1}
                                        onChange={(e) =>
                                          enableDisableNotification(e)
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="profile-notification mt-3">
                            <div className="row">
                              <div className="col-12">
                                <div className="row justify-content-between align-items-center">
                                  <div className="col-auto profile-notification-text">
                                    Recommended Music
                                  </div>
                                  <div className="col-auto">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>
                                          enableDisableRecommendedMusic(e)
                                        }
                                        checked={switchState.switch2}
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="profile-notification mt-3">
                            <div className="row">
                              <div className="col-12">
                                <div className="row justify-content-between align-items-center">
                                  <div className="col-auto profile-notification-text">
                                    Playlist Updates
                                  </div>
                                  <div className="col-auto">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>
                                          enableDisablePlaylistUpdates(e)
                                        }
                                        checked={switchState.switch3}
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="profile-notification mt-3">
                            <div className="row">
                              <div className="col-12">
                                <div className="row justify-content-between align-items-center">
                                  <div className="col-auto profile-notification-text">
                                    Artist Updates
                                  </div>
                                  <div className="col-auto">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        checked={switchState.switch4}
                                        onChange={(e) =>
                                          enableDisableArtistUpdates(e)
                                        }
                                      />

                                      <span className="slider round"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </Tab>
                  )}
                  <Tab eventKey="Settings" title="Settings">
                    <section>
                      {settingsTabState.isPasswordChangeSecShow === false ? (
                        <div className="setting-main-page">
                          <div
                            className="profile-setting"
                            style={{ backgroundColor: "transparent" }}
                          >
                            <div
                              className="row p-2"
                              style={{ backgroundColor: "#121216" }}
                            >
                              <div className="col-12">
                                <div className="row align-items-center">
                                  <div className="col-6 col-md-8 profile-notification-text">
                                    Password
                                  </div>
                                  <div className="col-6 col-md-4">
                                    <button
                                      id="forgot-pass"
                                      className="change-password-button"
                                      onClick={() => {
                                        // addLoader()
                                        setCurrentPasswordErr({
                                          currentPasswordErrMsg: "",
                                        });
                                        setNewPasswordErr({
                                          newPasswordErrMsg: "",
                                        });
                                        seConfirmPasswordErr({
                                          confirmPasswordErrMsg: "",
                                        });
                                        setSettingsTabState((prevState) => {
                                          return {
                                            ...prevState,
                                            isPasswordChangeSecShow: true,
                                          };
                                        });
                                      }}
                                    >
                                      Change Password
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* satyam 22/09/2021 Start */}
                            {/* <div className="setting-main-page">
                              <div className="profile-setting"> */}
                            <div
                              className="row p-2 mt-3"
                              style={{ backgroundColor: "#121216" }}
                            >
                              <div className="col-12">
                                <div className="row align-items-center">
                                  <div className="col-6 col-md-8 profile-notification-text">
                                    Change Email Address
                                  </div>
                                  <div className="col-6 col-md-4">
                                    <button
                                      id="forgot-pass"
                                      className="change-password-button"
                                      onClick={() => {
                                        // // addLoader()
                                        // setCurrentPasswordErr({
                                        //   currentPasswordErrMsg: "",
                                        // });
                                        // setNewPasswordErr({
                                        //   newPasswordErrMsg: "",
                                        // });
                                        // seConfirmPasswordErr({
                                        //   confirmPasswordErrMsg: "",
                                        // });
                                        setOtpScreenHideOrShow(false);

                                        setEmailConfirmDialog(true);
                                        setSettingsTabState((prevState) => {
                                          return {
                                            ...prevState,
                                            isPasswordChangeSecShow: true,
                                          };
                                        });
                                      }}
                                    >
                                      Change Email
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* </div>
                            </div> */}
                            {/* satyam 22/09/21 End */}
                          </div>
                          {/* <div className="row mt-3">
                            <div className="col-auto">
                              <div className="audiio-quality-title">
                                Audio Quality{" "}
                                <span className="audio-subtitle">
                                  (Premium Feature)
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12 col-md-6">
                              <div className="profile-setting">
                                <div className="row align-items-center">
                                  <div className="col-7 col-md-6">
                                    <div className="profile-notification-text">
                                      Streaming Quality
                                    </div>
                                  </div>
                                  <div className="col-5 col-md-6">
                                    <div className="floating-label">
                                      <select
                                        className="floating-input floating-select1"
                                        placeholder=" "
                                        id="exampleFormControlSelect1"
                                      >
                                        <option>High</option>
                                        <option>Medium</option>
                                        <option>Low</option>
                                      </select>
                                      <span className="highlight"></span>
                                    </div>
                                    <div className="row-"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 mt-3 mt-md-0">
                              <div className="profile-setting">
                                <div className="row align-items-center">
                                  <div className="col-7 col-md-6">
                                    <div className="profile-notification-text">
                                      Download Quality
                                    </div>
                                  </div>
                                  <div className="col-5 col-md-6">
                                    <div className="floating-label">
                                      <select
                                        className="floating-input floating-select1"
                                        placeholder=" "
                                        id="exampleFormControlSelect1"
                                      >
                                        <option>High</option>
                                        <option>Medium</option>
                                        <option>Low</option>
                                      </select>
                                      <span className="highlight"></span>
                                    </div>
                                    <div className="row-"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12">
                              <div className="profile-setting">
                                <div className="row justify-content-between align-items-center">
                                  <div className="col-auto profile-notification-text">
                                    Download Using Cellular
                                  </div>
                                  <div className="col-auto">
                                    <label className="switch">
                                      <input type="checkbox" />
                                      <span className="slider round"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-auto">
                              <div className="audiio-quality-title">
                                Playback{" "}
                                <span className="audio-subtitle">
                                  (Premium Feature)
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12">
                              <div className="profile-setting">
                                <div className="row justify-content-between align-items-center">
                                  <div className="col-auto profile-notification-text">
                                    Download Using Cellular
                                  </div>
                                  <div className="col-auto">
                                    <label className="switch">
                                      <input type="checkbox" />
                                      <span className="slider round"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12">
                              <div className="profile-setting">
                                <div className="row justify-content-between align-items-center">
                                  <div className="col-auto profile-notification-text">
                                    Download Using Cellular
                                  </div>
                                  <div className="col-auto">
                                    <label className="switch">
                                      <input type="checkbox" />
                                      <span className="slider round"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      ) : (
                        <>
                          {emailConfirmDialog === false ? (
                            // satyam 22/09/21
                            <div
                              className="setting-main-page"
                              style={{ maxHeight: 230 }}
                            >
                              <div>
                                <div className="row mt-3">
                                  <div className="col-auto">Password</div>
                                </div>
                                <div style={{ height: 350 }}>
                                  <div className="row mt-3">
                                    <div className="col-12 col-md-6">
                                      <div className="floating-label">
                                        <input
                                          className="floating-input"
                                          type={
                                            !isPasswordShow
                                              ? "text"
                                              : "password"
                                          }
                                          placeholder=" "
                                          onChange={getInputVal}
                                          name="currentPassword"
                                          value={
                                            settingsTabState.currentPassword
                                          }
                                        />
                                        {!isPasswordShow ? (
                                          <div
                                            className="eye-icon-show-hide"
                                            onClick={() => {
                                              setPasswordShow(!isPasswordShow);
                                            }}
                                          >
                                            <BsEyeFill />
                                          </div>
                                        ) : (
                                          <div
                                            className="eye-icon-show-hide"
                                            onClick={() => {
                                              setPasswordShow(!isPasswordShow);
                                            }}
                                          >
                                            <BsEyeSlashFill />
                                          </div>
                                        )}
                                        <span className="highlight"></span>
                                        <label className="label">
                                          Current Password
                                        </label>
                                      </div>
                                      {currentPasswordErr.isCurrentPasswordErrShow ===
                                        true && (
                                          <div className="errorText">
                                            {
                                              currentPasswordErr.currentPasswordErrMsg
                                            }
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  <div className="row mt-0 mt-md-3">
                                    <div className="col-12 col-md-6 mt-3 mt-md-0">
                                      <div className="floating-label">
                                        <input
                                          className="floating-input"
                                          type={
                                            !isPasswordShow1
                                              ? "text"
                                              : "password"
                                          }
                                          placeholder=" "
                                          name="newPassword"
                                          onChange={getInputVal}
                                          value={settingsTabState.newPassword}
                                        />
                                        {!isPasswordShow1 ? (
                                          <div
                                            className="eye-icon-show-hide"
                                            onClick={() => {
                                              setPasswordShow1(
                                                !isPasswordShow1
                                              );
                                            }}
                                          >
                                            <BsEyeFill />
                                          </div>
                                        ) : (
                                          <div
                                            className="eye-icon-show-hide"
                                            onClick={() => {
                                              setPasswordShow1(
                                                !isPasswordShow1
                                              );
                                            }}
                                          >
                                            <BsEyeSlashFill />
                                          </div>
                                        )}
                                        <span className="highlight"></span>
                                        <label className="label">
                                          New Password
                                        </label>
                                      </div>
                                      {newPasswordErr.isNewPasswordErrShow ===
                                        true && (
                                          <div className="errorText">
                                            {newPasswordErr.newPasswordErrMsg}
                                          </div>
                                        )}
                                    </div>
                                    <div className="col-12 col-md-6 mt-3 mt-md-0">
                                      <div className="floating-label">
                                        <input
                                          className="floating-input"
                                          type={
                                            !isPasswordShow2
                                              ? "text"
                                              : "password"
                                          }
                                          placeholder=" "
                                          onChange={getInputVal}
                                          name="confirmPassword"
                                          value={
                                            settingsTabState.confirmPassword
                                          }
                                        />
                                        {!isPasswordShow2 ? (
                                          <div
                                            className="eye-icon-show-hide"
                                            onClick={() => {
                                              setPasswordShow2(
                                                !isPasswordShow2
                                              );
                                            }}
                                          >
                                            <BsEyeFill />
                                          </div>
                                        ) : (
                                          <div
                                            className="eye-icon-show-hide"
                                            onClick={() => {
                                              setPasswordShow2(
                                                !isPasswordShow2
                                              );
                                            }}
                                          >
                                            <BsEyeSlashFill />
                                          </div>
                                        )}
                                        <span className="highlight"></span>
                                        <label className="label">
                                          Confirm Password
                                        </label>
                                      </div>
                                      {confirmPasswordErr.isConfirmPasswordErrShow ===
                                        true && (
                                          <div className="errorText">
                                            {
                                              confirmPasswordErr.confirmPasswordErrMsg
                                            }
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="row justify-content-center"
                                // style={{ marginTop: 230, maxHeight: 230 }}
                                >
                                  <div className="col-6">
                                    <button
                                      className="change-password-button w100"
                                      onClick={() => {
                                        setSettingsTabState((prevState) => {
                                          return {
                                            ...prevState,
                                            isPasswordChangeSecShow: false,
                                          };
                                        });
                                      }}
                                    >
                                      Back
                                    </button>
                                  </div>
                                  <div className="col-6">
                                    <button
                                      className="change-password-button w100"
                                      onClick={updatePassword}
                                    >
                                      Change Password
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            // satyam Email Changed Logic

                            <div
                              className="setting-main-page"
                              style={{ maxHeight: 230 }}
                            >
                              <div>
                                {/* <div className="row mt-3">
                                  <div className="col-auto">Email</div>
                                </div> */}
                                <div style={{ height: 350 }}>
                                  {
                                    otpScreenHideOrShow === false ? (
                                      <div className="row mt-3">
                                        <div className="col-12 col-md-6">
                                          <div className="floating-label">
                                            <input
                                              className="floating-input"
                                              type="text"
                                              placeholder=" "
                                              onChange={getInputVal}
                                              name="email"
                                              disabled={
                                                otpScreenHideOrShow === true
                                                  ? true
                                                  : false
                                              }
                                              value={personalInfoData.email}
                                            />

                                            <span className="highlight"></span>
                                            <label className="label">
                                              Email Address
                                            </label>
                                          </div>
                                          {emailErr === true && (
                                            <div className="errorText">
                                              {emailErrMsg}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <section
                                      // className="login-page"
                                      >
                                        <div className="container-fluid">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <div className="">
                                                <div className="row justify-content-center align-items-center">
                                                  <div className="col-12">
                                                    <div className="title">
                                                      OTP Verification
                                                    </div>
                                                    <div className="login-sub-title">
                                                      We have sent you One Time
                                                      Password on{" "}
                                                      <span className="otp-verification-highlight">
                                                        {personalInfoData.email}
                                                      </span>{" "}
                                                      to verify your email id.
                                                    </div>
                                                    <div
                                                      className="verrification-box col-12 "
                                                      style={{
                                                        textAlign:
                                                          "-webkit-center",
                                                      }}
                                                    >
                                                      <div
                                                        className="form-row mt-5 col-md-6"
                                                        style={{
                                                          paddingRight: 0,
                                                          paddingLeft: 0,
                                                        }}
                                                      >
                                                        <div className="col">
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            onChange={
                                                              getEmailChangeInput
                                                            }
                                                            name="input1"
                                                            maxLength={1}
                                                            value={
                                                              inputVal.input1
                                                            }
                                                            ref={input1Ref}
                                                            style={{
                                                              height: 35,
                                                              width: 35,
                                                              color: "white",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          />
                                                        </div>
                                                        <div className="col">
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            onChange={
                                                              getEmailChangeInput
                                                            }
                                                            name="input2"
                                                            maxLength={1}
                                                            value={
                                                              inputVal.input2
                                                            }
                                                            ref={input2Ref}
                                                            style={{
                                                              height: 35,
                                                              width: 35,
                                                              color: "white",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          />
                                                        </div>
                                                        <div className="col">
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            onChange={
                                                              getEmailChangeInput
                                                            }
                                                            name="input3"
                                                            value={
                                                              inputVal.input3
                                                            }
                                                            maxLength={1}
                                                            ref={input3Ref}
                                                            style={{
                                                              height: 35,
                                                              width: 35,
                                                              color: "white",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          />
                                                        </div>
                                                        <div className="col">
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            onChange={
                                                              getEmailChangeInput
                                                            }
                                                            name="input4"
                                                            maxLength={1}
                                                            value={
                                                              inputVal.input4
                                                            }
                                                            ref={input4Ref}
                                                            style={{
                                                              height: 35,
                                                              width: 35,
                                                              color: "white",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          />
                                                        </div>
                                                        <div className="col">
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            onChange={
                                                              getEmailChangeInput
                                                            }
                                                            name="input5"
                                                            maxLength={1}
                                                            value={
                                                              inputVal.input5
                                                            }
                                                            ref={input5Ref}
                                                            style={{
                                                              height: 35,
                                                              width: 35,
                                                              color: "white",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          />
                                                        </div>
                                                      </div>

                                                      <div className="row">
                                                        {/* <div className="col-12">
                                                          <button
                                                            className="btn btn-color w100 btnPadding"
                                                            onClick={
                                                              onEmailChangeSubmit
                                                            }
                                                          >
                                                            Submit
                                                          </button>
                                                        </div> */}
                                                      </div>
                                                      <div className="row justify-content-center">
                                                        <div className="col-auto">
                                                          <div className="text-center custom-verification-text">
                                                            <span className="d-block mobile-text didntreceiveotp mt-2">
                                                              {OTPError.isOtpErrorShow ===
                                                                true ? (
                                                                <div className="errorText mt-2">
                                                                  Please Fill
                                                                  OTP Details.
                                                                </div>
                                                              ) : (
                                                                <div>
                                                                  {OTPErrormsg.length !==
                                                                    0 ? (
                                                                    <div className="errorText">
                                                                      {
                                                                        OTPErrormsg
                                                                      }
                                                                    </div>
                                                                  ) : (
                                                                    ""
                                                                  )}
                                                                </div>
                                                              )}
                                                              Didn't receive the
                                                              OTP?{" "}
                                                              <span
                                                                className="resend-text"
                                                                onClick={
                                                                  resendOTP
                                                                }
                                                              >
                                                                Resend
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </section>
                                    )
                                    //  ---------------------------otp Page end
                                  }
                                </div>
                                <div
                                  className="row justify-content-center"
                                // style={{ marginTop: 230, maxHeight: 230 }}
                                >
                                  <div className="col-6">
                                    <button
                                      className="change-password-button w100"
                                      onClick={() => {
                                        setEmailConfirmDialog(false);
                                        setOtpScreenHideOrShow(false);
                                        // onUpdateCancel();
                                        setPersonalInfoData((prevState) => {
                                          return {
                                            ...prevState,
                                            id:
                                              currentUserInfo != undefined
                                                ? currentUserInfo.id
                                                : 0,
                                            profilImg: avatarImg,
                                            profilePicture: "",
                                            firstName:
                                              currentUserInfo != undefined
                                                ? currentUserInfo.firstName
                                                : "",
                                            lastName:
                                              currentUserInfo != undefined
                                                ? currentUserInfo.lastName
                                                : "",
                                            email:
                                              currentUserInfo != undefined
                                                ? currentUserInfo.email
                                                : "",
                                            phoneNo:
                                              currentUserInfo != undefined
                                                ? currentUserInfo.phone
                                                : "",
                                            userName:
                                              currentUserInfo != undefined
                                                ? currentUserInfo.userName
                                                : "",
                                            // title:
                                            //   currentUserInfo != undefined
                                            //     ? currentUserInfo.title
                                            //     : "",
                                            biography:
                                              currentUserInfo != undefined
                                                ? currentUserInfo.biography
                                                : "",
                                            // fullAddress:
                                            //   currentUserInfo != undefined ? currentUserInfo.fullAddress : "",
                                            subscribed:
                                              currentUserInfo != undefined
                                                ? currentUserInfo.subscribeYouTubeChannel
                                                : false,
                                            urlImage:
                                              currentUserInfo != undefined
                                                ? currentUserInfo.urlImage
                                                : "",
                                            usertype:
                                              currentUserInfo != undefined
                                                ? currentUserInfo.usertype
                                                : "",
                                            stateId:
                                              currentUserInfo != undefined
                                                ? currentUserInfo.stateId
                                                : "",
                                            countryId:
                                              currentUserInfo != undefined
                                                ? currentUserInfo.countryId
                                                : "",
                                            cityId:
                                              currentUserInfo != undefined
                                                ? currentUserInfo.cityId
                                                : "",
                                          };
                                        });
                                        // setPersonalInfoData((prevState) => {
                                        //   return {
                                        //     ...prevState,
                                        //     email:
                                        //       currentUserInfo != undefined
                                        //         ? currentUserInfo.email
                                        //         : "",
                                        //   };
                                        // });
                                        setSettingsTabState((prevState) => {
                                          return {
                                            ...prevState,
                                            isPasswordChangeSecShow: false,
                                          };
                                        });
                                      }}
                                    >
                                      Back
                                    </button>
                                  </div>
                                  <div className="col-6">
                                    <button
                                      className="change-password-button w100"
                                      onClick={
                                        otpScreenHideOrShow === true
                                          ? onEmailChangeSubmit
                                          : changeEmail
                                      }
                                    >
                                      {otpScreenHideOrShow === true
                                        ? "Submit"
                                        : "Change Email"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </section>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={showConfirmModal}
          onHide={cancelUploadProfilePic}
          backdrop={"static"}
          keyboard={false}
          className="userProfileModalDialog"
        >
          <Modal.Header
            // closeButton={!buttonDisable}
            style={{ backgroundColor: "#313131", border: 0 }}
          >
            <Modal.Title>
              <AiFillWarning size={22} /> Please Confirm
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#313131" }}>
            <div style={{ fontSize: 13 }}>
              {" "}
              Are you sure you want to change profile picture?
            </div>
            <div className="form-row ">
              <div className="col-6">
                <button
                  className="cancel-btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    cancelUploadProfilePic();
                  }}
                >
                  No
                </button>
              </div>
              <div className="col-6">
                <button
                  className="add-to-playlist-btn"
                  onClick={confirmUploadProfilePic}
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </section>
  );
}
