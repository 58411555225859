import React, { useState, useEffect, useContext } from "react";
import { BsPlayFill, BiPauseCircle, BsPauseFill } from "react-icons/bs";
import { FiMoreVertical } from "react-icons/fi";
import { constant } from "../../utility/constant";
import { getDataApi, postDataApi, deleteDataApi } from "../../utility/apiCall";
import { AiOutlineHeart, AiFillHeart, AiOutlineMore } from "react-icons/ai";
import { MdReport } from "react-icons/md";
import { colors } from "../../utility/colors";
import TinySlider from "tiny-slider-react";
import user from "../../assets/Images/user.png";
import Musics from "../../components/Musics/Musics";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";
import Comments from "../../components/Comments/Comments";
import moment from "moment";
import { Dropdown, DropdownButton, NavDropdown } from "react-bootstrap";
import CommentsModal from "../../components/CommentsModal/CommentsModal";
import ShareModal from "../../components/ShareModal/ShareModal";
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import twitter from "../../assets/Images/twitter.png";
import social from "../../assets/Images/social.png";
import facebook from "../../assets/Images/facebook.png";
import location from "../../assets/Images/Location.svg";
import phone from "../../assets/Images/Call.svg";
import mail from "../../assets/Images/Mail.svg";
import { FaShareAlt, FaPlay } from "react-icons/fa";
import { RiUserFollowLine, RiUserUnfollowLine } from "react-icons/ri";
import { VerifyLoginExistOrNot } from "../../utility/VerifyLoginExistOrNot";
import Loader from "react-loader-spinner";
import classNames from "classnames";
import {
  hashCodeUrl,
  hashCodeUrlDecode,
} from "../../utility/EncodeAndDecodeUrl";
import blackmike from "../../assets/Images/blackmike.svg";
import blackmikefill from "../../assets/Images/blackmikefill.svg";
// import {
//   FacebookIcon,
//   FacebookShareButton,
//   TwitterIcon,
//   TwitterShareButton,
//   InstapaperShareButton,
//   InstapaperIcon,
// } from "react-share";
import {
  addErrorMesage,
  addSuccessMesage,
} from "../../components/CustomToster/CustomToaster";
import { DisableButtonLoader } from "../../utility/CustomLoader";
import mike from "../../assets/Images/mike.svg";
import mikeFill from "../../assets/Images/mikeFill.svg";
import facebookIcon from "../../assets/Images/facebook.svg";
import twitterIcon from "../../assets/Images/twitterIcon.svg";
import youtube from "../../assets/Images/youtube.svg";
import itune from "../../assets/Images/itune.svg";
import spotify from "../../assets/Images/shopify.svg";
import tiktok from "../../assets/Images/titktok.svg";
import apple from "../../assets/Images/applemusic.svg";
import instagramIcon from "../../assets/Images/instagram.svg";
import podcaste from "../../assets/Images/podcaste.svg";
function FeaturedArtistDetails(props) {
  //console.log(props);
  let history = useHistory();
  let path = useLocation();
  let searchData = useParams();
  const [buttonDisable, setbuttonDisable] = useState(false);
  const [buttonfollowDisable, setbuttonfollowDisable] = useState(false);
  let shareUrl = "google.com" + location.pathname;
  //console.log(searchData, "Search Data", searchData);
  const { id } = hashCodeUrlDecode(searchData.itemId);
  const [showViewMore, setShowViewMore] = useState(false);
  const [idd, setidd] = useState(hashCodeUrlDecode(searchData.itemId));

  // const { id } = hashCodeUrlDecode(searchData.itemId);


  // satyam Start
  const [currentPlayMusic, setCurrentPlayMusic] = useState(false);
  const [currentALbumDetails, setcurrentALbumDetails] = useState(false);
  const [albumIndex, setAlbumIndex] = useState(null);
  // satyam End
  const [showLoader, setshowLoader] = useState(false);
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const [artistList, setArtistList] = useState([]);
  const [albumList, setAlbumList] = useState([]);
  const [selectedAlbumMusics, setSelectedAlbumMusics] = useState([]);
  const [showMusics, setShowMusics] = useState(false);
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const [playIndex, setPlayIndex] = useState(0);
  const [commentsList, setCommentsList] = useState([]);
  const [commentInputVal, setCommentInputVal] = useState("");
  const [errorCommentMessage, setCommentError] = useState(false);
  const [commentsPage, setCommentsPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [artistLikes, setArtistLikes] = useState(undefined);
  const [artistFollow, setArtistFollow] = useState(undefined);
  const [showReportArtistModal, setShowReportArtistModal] = useState(false);
  const [reportArtistInputText, setReportArtistInputText] = useState("");
  const [showShareToModal, setShowShareToModal] = useState(false);
  const [musicLikesList, setLikesList] = useState([]);
  const [musicList, setMusicList] = useState([]);
  const [selectedAlbum, setSelectedAlbum] = useState(undefined);
  const [albumLikesList, setAlbumLikesList] = useState([]);
  const [albumLikesData, setAlbumLikesData] = useState([]);
  const [albumResetCount, setAlbumResetCount] = useState(0);
  const [albumResetCount1, setAlbumResetCount1] = useState(0);
  const [albmId, setAlbmId] = useState(0);
  // satyam
  var [countData, setcountData] = useState(0);
  // const [isPlayMusic, setIsPlayMusic] = useState(true);
  const [artistData, setArtistData] = useState({
    id: id !== undefined ? id : 0,
    firstName: "",
    lastName: "",
    artistLiked: false,
    biography: "",
    location: "",
    email: "",
    phoneNo: "",
    title: "",
    country: "",
    callingCodes: "",
    followed: false,
    image: "",
    like: false,
    faceookURL: "",
    twitterURL: "",
    instagramURL: "",
    appleMusicURL: "",
    applePodcastURL: "",
    iTunesURL: "",
    spotifyURL: "",
    tikTokURL: "",
    youtubeURL: ""

  });
  // satyam
  var count = 0;
  const settingsArtist = {
    controls: false,
    items: 5,
    loop: false,
    nav: false,
    mouseDrag: true,
    gutter: 2,
    responsiveClass: true,
    preventScrollOnTouch: "force",
    nested: "inner",
    responsive: {
      0: {
        items: 5,
        nav: false,
      },
      600: {
        items: 3,
        nav: false,
      },
      1000: {
        items: 5,
        nav: false,
        loop: false,
        margin: 20,
      },
    },
  };

  const albumSettings = {
    controls: false,
    items: 5,
    loop: false,
    nav: false,
    mouseDrag: true,
    gutter: 10,
    responsiveClass: true,
    preventScrollOnTouch: "force",
    nested: "inner",
    responsive: {
      0: {
        items: 1.5,
        nav: false,
      },
      600: {
        items: 3,
        nav: false,
      },
      1000: {
        items: 4,
        nav: false,
        loop: false,
      },
    },
  };

  const [albumActive, setalbumActive] = useState(false);
  const [albumActiveId, setalbumActiveId] = useState("");
  const AlbumClass = classNames({
    wd260: albumActive === false,
    wd239: albumActive === true,
  });
  const navigateToMoreInfo = () => {
    history.push({
      pathname: `MoreInfo`,
      state: {
        id: id,
      },
    });
  };
  const getArtistDetails = async (id) => {
    let resArtist = await getDataApi(constant.baseURL + "user/" + id);
    //console.log(resArtist)
    if (resArtist !== undefined) {
      setArtistData((prevState) => {
        return {
          ...prevState,
          id: resArtist.id,
          firstName:
            resArtist.firstName !== undefined && resArtist.firstName !== null
              ? resArtist.firstName
              : "",
          lastName:
            resArtist.lastName !== undefined && resArtist.lastName !== null
              ? resArtist.lastName
              : "",
          email:
            resArtist.email !== undefined && resArtist.email !== null
              ? resArtist.email
              : "",
          phoneNo:
            resArtist.phone !== undefined && resArtist.phone !== null
              ? resArtist.phone
              : "",
          title:
            resArtist.title !== undefined && resArtist.title !== null
              ? resArtist.title
              : "",
          biography:
            resArtist.biography !== undefined && resArtist.biography !== null
              ? resArtist.biography
              : "",
          location:
            resArtist.fullAddress !== undefined &&
              resArtist.fullAddress !== null
              ? resArtist.fullAddress
              : "",
          country:
            resArtist.country !== undefined && resArtist.country != null
              ? resArtist.country.name
              : "",
          callingCodes:
            resArtist.country !== undefined && resArtist.country != null
              ? "+" + resArtist.country.callingCodes
              : "",
          image:
            resArtist.urlImage !== undefined &&
              resArtist.urlImage !== null &&
              resArtist.urlImage !== "" &&
              resArtist.urlImage !== "user.png"
              ? constant.profileURL + resArtist.urlImage
              : resArtist.usertype == "Artist" ? constant.artistImageURL : constant.userImageURL,
          faceookURL:
            resArtist.facebookURL !== undefined &&
              resArtist.facebookURL !== null
              ? resArtist.facebookURL
              : "",
          twitterURL:
            resArtist.twitterURL !== undefined && resArtist.twitterURL !== null
              ? resArtist.twitterURL
              : "",
          instagramURL:
            resArtist.instagramURL !== undefined &&
              resArtist.instagramURL !== null
              ? resArtist.instagramURL
              : "",
          appleMusicURL:
            resArtist.appleMusicURL !== undefined &&
              resArtist.appleMusicURL !== null
              ? resArtist.appleMusicURL
              : "",
          applePodcastURL:
            resArtist.applePodcastURL !== undefined &&
              resArtist.applePodcastURL !== null
              ? resArtist.applePodcastURL
              : "",
          iTunesURL:
            resArtist.iTunesURL !== undefined &&
              resArtist.iTunesURL !== null
              ? resArtist.iTunesURL
              : "",
          spotifyURL:
            resArtist.spotifyURL !== undefined &&
              resArtist.spotifyURL !== null
              ? resArtist.spotifyURL
              : "",
          tikTokURL:
            resArtist.tikTokURL !== undefined &&
              resArtist.tikTokURL !== null
              ? resArtist.tikTokURL
              : "",
          youtubeURL:
            resArtist.youtubeURL !== undefined &&
              resArtist.youtubeURL !== null
              ? resArtist.youtubeURL
              : "",
        };
      });
    }
  };
  const getArtists = async () => {
    let resArtists
    //console.log(props?.location?.state?.from)
    if (props?.location?.state?.from == "featureartistsh") {
      let ext1 = await getDataApi(
        constant.baseURL + 'user/getArtistsWithUserLike?page=' +
        1 + '&limit=' +
        100 + '&isFeatured=Yes' + '&userId=' + ((currentUserInfo) ? currentUserInfo.id : 0)
      );
      resArtists = ext1.data
      // let apiURL = constant.baseURL + "user/getHomeFeaturedArtistsWithUserLike";
      // let jsonData = { userId: 0 }
      // let ext = await postDataApi(apiURL, jsonData);
      // resArtists = ext.data.data
    }
    else if (props?.location?.state?.from == "featureartists") {
      let ext1 = await getDataApi(
        constant.baseURL + 'user/getArtistsWithUserLike?page=' +
        1 + '&limit=' +
        100 + '&isFeatured=Yes' + '&userId=' + ((currentUserInfo) ? currentUserInfo.id : 0)
      );
      resArtists = ext1.data
    }
    else if (props?.location?.state?.from == "featureartists10") {
      let ext2 = await getDataApi(constant.baseURL + 'user/getAllArtistsWithUserLike?page=' +
        1 + '&limit=' +
        100 + '&userId=' + ((currentUserInfo) ? currentUserInfo.id : 0)
      );
      resArtists = ext2.data
    }
    else if (props?.location?.state?.from == "featureartists20") {
      let ext2 = await getDataApi(constant.baseURL + 'user/getAllArtistsWithUserLike?page=' +
        1 + '&limit=' +
        100 + '&userId=' + ((currentUserInfo) ? currentUserInfo.id : 0)
      );
      resArtists = ext2.data
    }
    else {
      let ext2 = await getDataApi(constant.baseURL + 'user/getAllArtistsWithUserLike?page=' +
        1 + '&limit=' +
        100 + '&userId=' + ((currentUserInfo) ? currentUserInfo.id : 0)
      );
      resArtists = ext2.data
    }
    //console.log(resArtists)
    if (resArtists !== undefined && resArtists.length > 0) {
      setArtistList([...resArtists]);
    }
  };
  const getAlbumsByArtist = async (id) => {
    //console.log("aaa")
    let resAlbums = await getDataApi(
      constant.baseURL + "albums/getByArtistWithUserLike/" + id + "?page=1&limit=100" + '&userId=' + ((currentUserInfo) ? currentUserInfo.id : 0)
    );
    //console.log("resAlbums: ", resAlbums);
    if (resAlbums !== undefined && resAlbums.data !== undefined) {
      setAlbumList([...resAlbums.data]);
      if (resAlbums.data.length > 0) {
        setSelectedAlbum(resAlbums.data[0]);
        let musicsData = [];
        resAlbums.data[0].musics.map((item) => {
          musicsData.push({
            id: item.id,
            title: item.title,
            name: item.title,
            time: item.duration,
            size:
              item.size !== undefined && item.size !== null
                ? item.size
                : 0,
            singer:
              resAlbums.data[0].user.firstName +
              " " +
              resAlbums.data[0].user.lastName,
            cover: constant.musicURL,
            albumname: resAlbums.data[0].name,
            albumId: resAlbums.data[0].id,
            urlImage:
              item.urlImage !== undefined ? item.urlImage : "",
            musicSrc:
              item.urlImage !== undefined
                ? constant.musicFileURL + item.urlImage
                : "",
            likes: item.likes,
            comments: item.comments,
            user: resAlbums.data[0]?.user,
            userlike: item.userlike
          });
        });
        let albumIds = "";
        resAlbums.data.map((item) => {
          albumIds += item.id + ",";
        });

        if (searchData.albumId !== undefined) {
          resAlbums.data.map((item, index) => {
            if (
              item.id.toString() === hashCodeUrlDecode(searchData.albumId)
            ) {
              getMusicsByAlbumUrlId(resAlbums.data[index], resAlbums.data[index].user);
              setSelectedAlbum(resAlbums.data[index]);
              setalbumActiveId(index);
              setShowMusics(true);
              //window.scrollTo(0, 1000);
            }
          });
        } else {
          setcountData(countData + 1);
          setSelectedAlbumMusics([...musicsData]);
          setShowMusics(true);
          getMusicsByAlbum(resAlbums.data[0]);
          setshowLoader(false);
          getAlbumLikesByUser(albumIds.substr(0, albumIds.lastIndexOf(",")));
        }
      } else {
        setSelectedAlbumMusics([]);
        setShowMusics(false);
        setLikesList([]);
      }
    }
  };

  const getMusicsByAlbumUrlId = async (objAlbumUrl, j) => {
    //console.log("bbb", j,)
    let mItems = [];
    let count = 0;
    let musicIds = "";
    let arrMusics = [];
    if (objAlbumUrl.musics !== undefined) {
      arrMusics = [...objAlbumUrl.musics];
    } else {
      arrMusics = [...objAlbumUrl];
    }

    let musicsData = [];
    arrMusics.map((item) => {
      musicIds +=
        item.id !== undefined ? item.id + "," : item.id + ",";
      musicsData.push({
        id: item.id,
        title: item.title,
        name: item.title,
        singer: objAlbumUrl.user.firstName + " " + objAlbumUrl.user.lastName,
        cover: constant.musicURL,
        time: item.duration,
        size:
          item.size !== undefined && item.size !== null
            ? item.size
            : 0,
        albumname: objAlbumUrl.name,
        albumId: objAlbumUrl.id,
        urlImage:
          item.urlImage !== undefined ? item.urlImage : "",
        musicSrc:
          item.urlImage !== undefined
            ? constant.musicFileURL + item.urlImage
            : "",
        likes: item.likes,
        comments: item.comments,
        user: j,
        userlike: item.userlike
      });
      count++;
    });
    setcountData(countData + 1);
    setSelectedAlbumMusics([...musicsData]);
    setcurrentALbumDetails(true);
    getMusicLikesByUser(musicIds.substr(0, musicIds.lastIndexOf(",")));
  };

  const getMusicsByAlbum = async (objAlbum) => {
    //console.log("objAlbum: ", objAlbum);
    let mItems = [];
    let count = 0;
    let musicIds = "";
    let arrMusics = [];
    if (objAlbum.musics !== undefined) {
      arrMusics = [...objAlbum.musics];
    } else {
      arrMusics = [...objAlbum];
    }
    //console.log("arrMusics", arrMusics);
    arrMusics.map((mitem) => {
      musicIds +=
        mitem.id !== undefined ? mitem.id + "," : mitem.id + ",";
      mItems.push({
        id: mitem.id !== undefined ? mitem.id : mitem.id,
        rank: count + 1,
        title:
          mitem.title !== undefined ? mitem.title : mitem.title,
        album:
          objAlbum.name !== undefined
            ? objAlbum.name
            : mitem.album.name,
        albumId:
          objAlbum.id !== undefined
            ? objAlbum.id
            : mitem.album.id,
        time: mitem.duration,
        size:
          mitem.size !== undefined && mitem.size !== null
            ? mitem.size
            : 0,
        like: false,
        musicType: mitem.musicType,
        urlImage:
          mitem.urlImage !== undefined
            ? mitem.urlImage
            : mitem.urlImage,
        musicSrc:
          mitem.urlImage !== undefined
            ? constant.musicFileURL + mitem.urlImage
            : "",
        likes: mitem.likes,
        comments: mitem.comments,
        user: mitem?.user,
        userlike: mitem.userlike
      });
      count++;
    });
    //console.log("Musiclist: ", mItems);
    setalbumActiveId(0);
    setalbumActive(true);

    setMusicList([...mItems]);
    getMusicLikesByUser(musicIds.substr(0, musicIds.lastIndexOf(",")));
  };
  const loadSelectedArtist = (index) => {
    let itm = artistList[index];
    if (itm !== undefined) {

      getArtistDetails(itm.id);
      getAlbumsByArtist(itm.id);
      getCommentsByArtist(itm.id, commentsPage);
      getLikesByArtist(itm.id);
      getFollowByArtist(itm.id);
      setidd(itm.id)
      history.push({
        pathname: `/FeaturedArtistDetails/${hashCodeUrl(itm.id)}`,
        // pathname: `/FeaturedArtistDetails/${hashCodeUrl(itm.id)}/${hashCodeUrl(
        //   itm.biography
        // )}`,
      });
      window.scrollTo(0, 0);
    }
  };
  const onClickAlbum = (index) => {
    //console.log("ccc")
    setalbumActiveId(index);
    setalbumActive(true);
    //setShowAudioPlayer(false);
    //setshowLoader(true);
    setTimeout(() => {
      let itm = albumList[index];
      //console.log("New songs", itm);
      setSelectedAlbum(itm);
      let musicIds = "";
      if (itm !== undefined) {
        let musicsData = [];
        itm.musics.map((item) => {
          musicIds +=
            item.id !== undefined ? item.id + "," : item.id + ",";
          musicsData.push({
            id: item.id,
            title: item.title,
            name: item.title,
            singer: itm.user.firstName + " " + itm.user.lastName,
            cover: constant.musicURL,
            time: item.duration,
            size:
              item.size !== undefined && item.size !== null
                ? item.size
                : 0,
            albumname: itm.name,
            albumId: itm.id,
            urlImage:
              item.urlImage !== undefined ? item.urlImage : "",
            musicSrc:
              item.urlImage !== undefined
                ? constant.musicFileURL + item.urlImage
                : "",
            likes: item.likes,
            comments: item.comments,
            user: itm?.user,
            userlike: item.userlike

          });
        });
        setcountData(countData + 1);
        setSelectedAlbumMusics([...musicsData]);
        // setCurrentPlayMusic(true);
        // setShowAudioPlayer(false);
        setcurrentALbumDetails(true);
        getMusicLikesByUser(musicIds.substr(0, musicIds.lastIndexOf(",")));
        //console.log("item", itm);
        history.push(`${hashCodeUrl(itm.id)}`);
      }
      setShowMusics(true);
    }, 300);
  };
  const funsetcurrentPlaymusic = () => {
    setCurrentPlayMusic(false)
  }
  const updateDurationInMusics = async (musicItems) => {
    let promises_array = [];
    musicItems.map((item) => {
      promises_array.push(
        new Promise((resolve, reject) => {
          return loadFileDuration(item.musicSrc)
            .then((resp) => {
              item["time"] = resp;
              resolve(item);
            })
            .catch((error) => {
              reject(undefined);
            });
        })
      );
    });
    return Promise.all(promises_array);
    //console.log("updateDurationInMusics: ", selectedAlbumMusics)
    // return musicItems;
  };
  const loadFileDuration = async (url) => {
    let audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    const response = await fetch(url);
    //console.log(response);
    const arrayBuffer = await response.arrayBuffer();
    if (arrayBuffer) {
      let audioBuffer = await audioCtx.decodeAudioData(arrayBuffer);
      return audioBuffer.duration;
    } else {
      return "0:00";
    }
  };

  const playAlbum = () => {
    // setShowMusics(false)
    // setCurrentPlayMusic(false);
    //console.log("dffffsdfdfsdf", selectedAlbumMusics)

    if (selectedAlbumMusics.length > 0) {
      //console.log("Play1")
      //setIsPlayMusic(false);
      //console.log(showLoader, showMusics, "uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu")
      // satyam Changed
      // setShowAudioPlayer(true);
      setShowMusics(true);
      setcurrentALbumDetails(true)
      // setShowAudioPlayer(true);
      // setCurrentPlayMusic(true);

    }
    setAlbumResetCount1(albumResetCount1 + 1)
    // else{
    //   setIsPlayMusic(true);
    // }
  };

  //console.log(showLoader, showMusics, "uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu")
  // const pauseAlbum = () => {
  //   setIsPlayMusic(true);
  // }
  const resetShowAudioPlayer = () => {
    setShowAudioPlayer(false);
    //setIsPlayMusic(true);
  };
  // const resetIsPlayMusic = (flag) => {
  //   setIsPlayMusic(flag);
  // }

  //Likes - START
  const getLikesByArtist = async (id) => {
    //console.log("getLikesByArtist - currentUserInfo: ", currentUserInfo);
    if (currentUserInfo !== undefined) {
      let resArtistLikes = await getDataApi(
        constant.baseURL +
        "likes?filter=user.id||$eq||" +
        currentUserInfo.id +
        "&filter=artist.id||$eq||" +
        id
      );
      //console.log("getLikesByArtist - resArtistLikes: ", resArtistLikes);
      if (resArtistLikes !== undefined && resArtistLikes.length > 0) {
        setArtistLikes(resArtistLikes[0]);
        setArtistData((prevState) => {
          return {
            ...prevState,
            artistLiked: true,
          };
        });
      } else {
        setArtistData((prevState) => {
          return {
            ...prevState,
            artistLiked: false,
          };
        });
      }
    }
  };
  const createArtistLike = async () => {
    if (VerifyLoginExistOrNot()) {
      if (currentUserInfo !== undefined) {
        setbuttonDisable(true);
        let jsonData = { user: currentUserInfo.id, artist: artistData.id };
        var formBody = [];
        for (var property in jsonData) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(jsonData[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        var authHeader = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        };
        let respArtistLike = await postDataApi(
          constant.baseURL + "likes",
          formBody,
          authHeader
        );
        if (
          respArtistLike !== undefined &&
          respArtistLike.status !== undefined &&
          respArtistLike.status === 201
        ) {
          setbuttonDisable(false);
          setArtistLikes(respArtistLike.data);
          setArtistData((prevState) => {
            return {
              ...prevState,
              artistLiked: true,
            };
          });
        }
      }
    } else {
      history.push({
        pathname: `/login`,
        state: {
          prevPath: path.pathname,
          userId: artistData.id,
        },
      });
    }
  };

  const resetArtistLike = async () => {
    if (VerifyLoginExistOrNot()) {
      if (artistLikes !== undefined) {
        setbuttonDisable(true);
        var authHeader = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        };
        let resp = await deleteDataApi(
          constant.baseURL + "likes/" + artistLikes.id,
          authHeader
        );
        if (
          resp !== undefined &&
          resp.status !== undefined &&
          resp.status === 200
        ) {
          setbuttonDisable(false);
          setArtistLikes(undefined);
          setArtistData((prevState) => {
            return {
              ...prevState,
              artistLiked: false,
            };
          });
        }
      }
    } else {
    }
  };
  const getMusicLikesByUser = async (str) => {
    if (currentUserInfo !== undefined && str !== "") {
      let resMusicsLikes = await getDataApi(
        constant.baseURL +
        "musiclikes?filter=user.id||$eq||" +
        currentUserInfo.id +
        "&filter=music.id||$in||" +
        str
      );
      if (resMusicsLikes !== undefined && resMusicsLikes.length > 0) {
        let mlikeIds = [];
        resMusicsLikes.map((m) => {
          mlikeIds.push(m.music.id);
        });
        setLikesList(resMusicsLikes);
      }
    }
  };
  const setLikes = (id, data, likeId) => {
    // if (musicLikesList.length !== 0) {
    //   let fltLikes = musicLikesList.filter(
    //     (f) => f.music !== undefined && f.music.id === id
    //   );
    //   if (fltLikes.length == 0) {
    //     setLikesList([...musicLikesList, data]);
    //   } else {
    //     let newArr = musicLikesList.filter(
    //       (i) => i.music !== undefined && i.music.id !== id
    //     );
    //     setLikesList(newArr);
    //   }
    // } else {
    //   let arr = [];
    //   arr.push(data);
    //   setLikesList(arr);
    // }
    let bgItems = [...selectedAlbumMusics]
    //console.log(bgItems)
    if (data !== undefined) {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike.push(data);
          itm.likes.push(data);
        }
      });
    } else {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike = [];
          itm.likes.map((lkitm, index0) => {
            if (lkitm.id === likeId) {
              itm.likes.splice(index0, 1);
            }
          });
        }
      });
    }

    setSelectedAlbumMusics(bgItems)
  };
  //Likes - END
  //Follow - START
  const getFollowByArtist = async (id) => {
    if (currentUserInfo !== undefined) {
      let resArtistFollow = await getDataApi(
        constant.baseURL +
        "follows?filter=user.id||$eq||" +
        currentUserInfo.id +
        "&filter=follower.id||$eq||" +
        id
      );
      //console.log("resArtistFollow: ", resArtistFollow);
      if (resArtistFollow !== undefined && resArtistFollow.length > 0) {
        setArtistFollow(resArtistFollow[0]);
        setArtistData((prevState) => {
          return {
            ...prevState,
            followed: true,
          };
        });
      }
    }
  };
  // satyam Add Condition
  const createFollow = async () => {
    if (VerifyLoginExistOrNot()) {
      if (currentUserInfo !== undefined) {
        setbuttonfollowDisable(true);
        let jsonData = { user: currentUserInfo.id, follower: artistData.id };
        var formBody = [];
        for (var property in jsonData) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(jsonData[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        var authHeader = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        };
        //console.log(jsonData);
        let respFollow = await postDataApi(
          constant.baseURL + "follows",
          formBody,
          authHeader
        );
        //console.log("respFollow: ", respFollow);
        if (
          respFollow !== undefined &&
          respFollow.status !== undefined &&
          respFollow.status == 201
        ) {
          setbuttonfollowDisable(false);
          setArtistFollow(respFollow.data);
          setArtistData((prevState) => {
            return {
              ...prevState,
              followed: true,
            };
          });
        } else {
          setArtistData((prevState) => {
            return {
              ...prevState,
              followed: false,
            };
          });
        }
      }
    } else {
      history.push({
        pathname: `/login`,
        state: {
          prevPath: path.pathname,
          userId: artistData.id,
        },
      });
    }
  };
  // satyam End
  const resetFollow = async () => {
    if (artistFollow !== undefined) {
      setbuttonfollowDisable(true);
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resp = await deleteDataApi(
        constant.baseURL + "follows/" + artistFollow.id,
        authHeader
      );
      if (
        resp !== undefined &&
        resp.status !== undefined &&
        resp.status === 200
      ) {
        setbuttonfollowDisable(false);
        setArtistFollow(undefined);
        setArtistData((prevState) => {
          return {
            ...prevState,
            followed: false,
          };
        });
      }
    }
  };
  //Follow - END
  //Report Artist - START
  const displayReportArtistModal = () => {
    if (VerifyLoginExistOrNot()) setShowReportArtistModal(true);
    else {
      history.push({
        pathname: `/login`,
        state: {
          prevPath: path.pathname,
          userId: artistData.id,
        },
      });
    }
  };
  const hideReportArtistModal = () => {
    setShowReportArtistModal(false);
  };
  //Report Artist - END

  const getCommentsByArtist = async (id, pageNumber) => {
    let resArtistComments = await getDataApi(
      constant.baseURL +
      "artistcomments?filter=artist.id||$eq||" +
      id +
      "&page=" +
      pageNumber +
      "&limit=" +
      limit +
      "&sort=id,DESC"
    );
    //console.log("resArtistComments: ", resArtistComments);
    if (
      resArtistComments !== undefined &&
      resArtistComments.data !== undefined &&
      resArtistComments.data.length > 0
    ) {
      let itms = [];
      resArtistComments.data.map((c) => {
        itms.push({
          id: c.id,
          img:
            c.user.urlImage !== undefined &&
              c.user.urlImage !== null &&
              c.user.urlImage !== "" &&
              c.user.urlImage !== "user.png"
              ? constant.profileURL + c.user.urlImage
              : c.user.usertype == "Artist" ? constant.artistImageURL : constant.userImageURL,
          name: c.user.firstName + " " + c.user.lastName,
          location: c.user.address,
          comment: c.comments,
          date: moment(c.created).format("DD MMM yyyy"),
        });
      });
      if (pageNumber == 1) {
        setCommentsList(itms);
      } else {
        setCommentsList([...commentsList, ...itms]);
      }
      //show hide view more page
      if (resArtistComments.page < resArtistComments.pageCount) {
        setShowViewMore(true);
      } else {
        setShowViewMore(false);
      }
    } else {
      setCommentsList([]);
    }
    //}
  };
  const setComments = (data) => {
    setCommentsList([
      {
        id: data.id,
        img:
          data.user.urlImage !== undefined &&
            data.user.urlImage !== null &&
            data.user.urlImage !== "" &&
            data.user.urlImage !== "user.png"
            ? constant.profileURL + data.user.urlImage
            : data.user.usertype == "Artist" ? constant.artistImageURL : constant.userImageURL,
        name: data.user.firstName + " " + data.user.lastName,
        location: data.user.address,
        comment: data.comments,
        date: moment(data.created).format("DD MMM yyyy"),
      },
      ...commentsList,
    ]);
    setCommentInputVal("");
  };
  const viewMoreComments = () => {
    setCommentsPage(commentsPage + 1);
    getCommentsByArtist(artistData.id, commentsPage + 1);
  };

  const getAlbumLikesByUser = async (str) => {
    if (currentUserInfo !== undefined && str !== undefined && str !== "") {
      let resAlbumLikes = await getDataApi(
        constant.baseURL +
        "albumlikes?filter=user.id||$eq||" +
        currentUserInfo.id +
        "&filter=album.id||$in||" +
        str
      );
      //console.log("resAlbumLikes: ", resAlbumLikes);
      let albumLikeItems = [];
      if (resAlbumLikes !== undefined) {
        resAlbumLikes.map((m) => {
          albumLikeItems.push(m.album.id);
        });
        //console.log("likeItems: ", albumLikeItems);
        //setTimeout(() => {
        setAlbumLikesList(resAlbumLikes);
        setAlbumLikesData(resAlbumLikes);
        setbuttonDisable(false);
        //}, 100);
      } else {
        setbuttonDisable(false);
      }
    } else {
      setbuttonDisable(false);
    }
  };
  const createAlbumLike = async (albumId) => {
    if (VerifyLoginExistOrNot()) {
      setbuttonDisable(true);
      setAlbmId(albumId);
      if (currentUserInfo !== undefined) {
        let jsonData = { user: currentUserInfo.id, album: albumId };
        var formBody = [];
        for (var property in jsonData) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(jsonData[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        var authHeader = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        };
        let respAlbumLike = await postDataApi(
          constant.baseURL + "albumlikes",
          formBody,
          authHeader
        );
        if (
          respAlbumLike !== undefined &&
          respAlbumLike.status !== undefined &&
          respAlbumLike.status === 201
        ) {
          setLikes1(albumId, respAlbumLike.data, undefined);//setLikes(artistId, respArtistLike.data);
          setbuttonDisable(false);
        } else {
          setbuttonDisable(false);
        }
      } else {
        setbuttonDisable(false);
      }
    } else {
      setbuttonDisable(false);
    }
  };
  const resetAlbumLike = async (albumId, likeId) => {
    if (VerifyLoginExistOrNot()) {
      setbuttonDisable(true);
      setAlbmId(albumId);
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resp = await deleteDataApi(
        constant.baseURL + "albumlikes/" + likeId,
        authHeader
      );
      if (
        resp !== undefined &&
        resp.status !== undefined &&
        resp.status === 200
      ) {
        setLikes1(albumId, undefined, likeId);//setLikes(artistId);
        setbuttonDisable(false);
      } else {
        setbuttonDisable(false);
      }
    } else {
      setbuttonDisable(false);
    }
  };
  const setLikes1 = (id, data, likeId) => {
    let bgItems = [...albumList]
    //console.log(bgItems)
    if (data !== undefined) {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike.push(data);
          itm.likes.push(data);
        }
      });
    } else {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike = [];
          itm.likes.map((lkitm, index0) => {
            if (lkitm.id === likeId) {
              itm.likes.splice(index0, 1);
            }
          });
        }
      });
    }

    setAlbumList(bgItems)

  };
  const onSetAlbumLikes = (id, data) => {
    //setAlbmId(id);
    setAlbumResetCount(albumResetCount + 1);
  };
  const renderAlbumLikes = (likeData) => {
    //console.log(likeData)
    // if (albumLikesList.length !== 0) {
    //   let flt = albumLikesData.filter(
    //     (f) => f.album !== undefined && f.album.id === id
    //   );
    //   if (flt.length > 0) {
    //     return (
    //       <>
    //         <img
    //           src={mikeFill}
    //           title={constant.unmic}
    //           key={id}
    //           onClick={() => {
    //             if (VerifyLoginExistOrNot()) resetAlbumLike(id, flt[0].id);
    //             else {
    //               history.push({
    //                 pathname: `/login`,
    //                 state: {
    //                   prevPath: path.pathname,
    //                   userId:
    //                     currentUserInfo !== undefined ? currentUserInfo.id : 0,
    //                 },
    //               });
    //             }
    //           }}
    //           style={{ cursor: "pointer", width: 20 }}
    //         />
    //         {/* &nbsp;{flt.length} */}
    //       </>
    //     );
    //   } else {
    //     return (
    //       <>
    //         <img
    //           src={mike}
    //           title={constant.mic}
    //           key={id}
    //           onClick={() => {
    //             if (VerifyLoginExistOrNot()) createAlbumLike(id);
    //             else {
    //               history.push({
    //                 pathname: `/login`,
    //                 state: {
    //                   prevPath: path.pathname,
    //                   userId:
    //                     currentUserInfo !== undefined ? currentUserInfo.id : 0,
    //                 },
    //               });
    //             }
    //           }}
    //           style={{ cursor: "pointer", width: 20 }}
    //         />
    //         {/* &nbsp;0 */}
    //       </>
    //     );
    //   }
    // } else {
    //   return (
    //     <>
    //       <img
    //         src={mike}
    //         title={constant.mic}
    //         key={id}
    //         onClick={() => {
    //           if (VerifyLoginExistOrNot()) createAlbumLike(id);
    //           else {
    //             history.push({
    //               pathname: `/login`,
    //               state: {
    //                 prevPath: path.pathname,
    //                 userId:
    //                   currentUserInfo !== undefined ? currentUserInfo.id : 0,
    //               },
    //             });
    //           }
    //         }}
    //         style={{ cursor: "pointer", width: 20 }}
    //       />
    //       {/* &nbsp;0 */}
    //     </>
    //   );
    // }
    if (likeData?.userlike?.length > 0) {
      return (
        <img className="w-24" src={mikeFill} alt="Unmike" style={{ cursor: "pointer" }}
          //onClick={() => resetEpisodeLike(likeData.id, likeData.userlike[0].id)} 
          onClick={() => {
            if (VerifyLoginExistOrNot()) resetAlbumLike(likeData.id, likeData.userlike[0].id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    } else {
      return (
        <img className="w-24" src={mike} alt="Mike" style={{ cursor: "pointer" }}
          //onClick={() => createEpisodeLike(likeData.id)} 
          onClick={() => {
            if (VerifyLoginExistOrNot()) createAlbumLike(likeData.id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    }
  };
  const renderArtistList = () => {
    if (artistList.length > 0) {
      return (
        <TinySlider settings={settingsArtist}>
          {artistList.map((item, index) => {
            return (
              item?.id != idd ? <div
                key={index}
                className="item"
                onClick={() => loadSelectedArtist(index)}
                style={{ cursor: "pointer" }}
              >
                <img
                  style={{
                    height: 60,
                    width: 60,
                    marginRight: 10,
                    cursor: "pointer",
                    borderRadius: 44,
                  }}
                  src={
                    item.urlImage !== undefined && item.urlImage !== "user.png"
                      ? constant.profileURL + item.urlImage
                      : item.usertype == "Artist" ? constant.artistImageURL : constant.userImageURL
                  }
                  alt={item.firstName + " " + item.lastName}
                />
              </div> : <></>
            );
          })}
        </TinySlider>
      );
    } else {
      return <div className="no-data-found">{constant.noDataFoundLabel}</div>;
    }
  };
  const renderAlbums = () => {
    return (
      <TinySlider settings={albumSettings}>
        {albumList.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                //width: 260, marginRight: 10,
                cursor: "pointer",
                width: "-webkit-fill-available",
                //marginLeft: 10
              }}
            >
              <div className="card mm-card-bg">
                {item.albumType === "music" && (
                  <img
                    onClick={() => onClickAlbum(index)}
                    className={
                      AlbumClass + (albumActiveId === index && " albumActive")
                    }
                    style={{
                      height: 193,
                      cursor: "pointer",
                      width: "-webkit-fill-available",
                    }}
                    src={
                      item.urlImage !== undefined &&
                        item.urlImage !== ""
                        ? constant.albumFileURL + item.urlImage
                        : constant.musicURL
                    }
                    alt={item.name}
                  />
                )}
                {item.albumType === "video" && (
                  <img
                    onClick={() => onClickAlbum(index)}
                    style={{
                      height: 193,
                      cursor: "pointer",
                      width: "-webkit-fill-available",
                    }}
                    className={
                      AlbumClass + (albumActiveId === index && " albumActive")
                    }
                    src={
                      item.urlImage !== undefined &&
                        item.urlImage !== ""
                        ? constant.albumFileURL + item.urlImage
                        : constant.videoURL
                    }
                    alt={item.name}
                  />
                )}
                <div className="card-body">
                  {/* <h6
                    className="big-stage-song-name wrapText"
                    title={item.name}
                  >
                    {String(item.name)}
                  </h6> */}

                  <div className="form-row align-items-center">
                    <div className="col-9 col-md-9">
                      <h6
                        className="fs-16 mt-1 fw-600 wrapText"
                        title={item.name}
                      >
                        {String(item.name)}
                      </h6>
                    </div>
                    <div className="col-md-3 col-3">
                      {buttonDisable && albmId === item.id ? DisableButtonLoader() :
                        <>
                          {renderAlbumLikes(item)}
                          <span className="fs-14 fw-400 pl-1">
                            {item.likes.length}
                          </span>
                        </>
                      }
                    </div>
                  </div>
                  <p className="fs-14 mt-2 fw-400 wrapText">
                    {item.musics !== undefined ? item.musics.length : 0} Tracks
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </TinySlider>
    );
  };
  const navigateToExternalLink = (url) => {
    //console.log("url: ", url);
    if (url.indexOf("http://") !== -1 || url.indexOf("https://") !== -1) {
      window.open(url, "_blank");
      return null;
    } else {
      addErrorMesage("URL should start with http or https");
      return;
    }
  };

  //console.log(currentPlayMusic,showMusics)


  useEffect(() => {
    getArtistDetails(hashCodeUrlDecode(searchData.itemId));
    getArtists();
    getLikesByArtist(hashCodeUrlDecode(searchData.itemId));
    getAlbumsByArtist(hashCodeUrlDecode(searchData.itemId));
    getFollowByArtist(hashCodeUrlDecode(searchData.itemId));
    getCommentsByArtist(hashCodeUrlDecode(searchData.itemId), commentsPage);
    setidd(hashCodeUrlDecode(searchData.itemId))
  }, []);
  useEffect(() => {
    // let albumIds = "";
    // albumList.map((item) => {
    //   albumIds += item.id + ",";
    // });
    // getAlbumLikesByUser(albumIds.substr(0, albumIds.lastIndexOf(",")));
    getAlbumsByArtist(hashCodeUrlDecode(searchData.itemId));
  }, [albumResetCount]);

  // useEffect(() => {
  //   getAlbumsByArtist(hashCodeUrlDecode(searchData.itemId));
  // }, [albumList]);
  return (
    <div>
      <section
        className="feature-banner-2"
        style={{
          backgroundImage: `url(${artistData !== undefined ? encodeURI(artistData.image) : encodeURI(user)
            })`,
        }}
      >
        <div className="container">
          <div className="playlist playlist-bottom ">
            <div
              className=" wrapText fs-40 fw-700"
              title={
                artistData !== undefined
                  ? String(artistData.firstName + " " + artistData.lastName)
                  : ""
              }
            >
              {artistData !== undefined
                ? String(artistData.firstName + " " + artistData.lastName)
                : ""}
            </div>
            <div className="row">
              <div className="col-12 col-md-10">
                <p
                  className="fs-15 lh-20 fw-500 d-none d-md-block"
                >
                  {artistData !== undefined ? String(artistData.biography) : ""}
                </p>
              </div>
            </div>

            <div className="form-row" style={{ marginRight: 0 }}>
              {selectedAlbumMusics.length > 0 && (
                <div className="col-auto" title="Play">
                  <button
                    className="btn btn-primary round-btn "
                    onClick={() => playAlbum()}
                  >
                    <>
                      <BsPlayFill size={22} />
                      <span className="play-btn d-mobile-none ">Play</span>
                    </>
                  </button>
                </div>
              )}
              {/* satyam Start */}
              <div
                className="col-auto "
                title={artistData.artistLiked === true ? constant.unmic : constant.mic}
              >
                {artistData.artistLiked === true ? (
                  <button
                    className="btn btn-primary round-btn"
                    disabled={buttonDisable}
                    onClick={() => {
                      resetArtistLike();
                    }}
                  >

                    <img src={blackmikefill} title={constant.unmic} style={{ width: 25 }} />
                    <span className="play-btn d-mobile-none">{constant.unmic}</span>
                  </button>
                ) : (
                  <button
                    disabled={buttonDisable}
                    className="btn btn-primary round-btn"
                    onClick={() => createArtistLike()}
                  >
                    {/* <AiOutlineHeart size={22} /> */}
                    <img src={blackmike} title={constant.mic} style={{ width: 23 }} />
                    <span className="play-btn d-mobile-none">{constant.mic}</span>
                  </button>
                )}
              </div>
              <div
                className="col-auto"
                title={artistData.followed === true ? "UnFollow" : "Follow"}
              >
                <button
                  className="btn btn-primary round-btn"
                  disabled={buttonfollowDisable}
                  onClick={() =>
                    artistData.followed === true ? resetFollow() : createFollow()
                  }
                >
                  {artistData.followed === true ? (
                    <RiUserUnfollowLine size={22} />
                  ) : (
                    <RiUserFollowLine size={22} />
                  )}
                  <span className="play-btn d-mobile-none">
                    {artistData.followed === true ? "UnFollow" : "Follow"}
                  </span>
                </button>
              </div>
              <div className="col-auto d-none d-md-block">
                <DropdownButton
                  className="artistMore"
                  variant="Secondary"
                  title={
                    <button className="btn btn-like ">
                      <FiMoreVertical size={20} color={colors.color6} />
                    </button>
                  }
                >
                  <Dropdown.Item
                    onClick={() => {
                      setShowShareToModal(true);
                    }}
                  >
                    Share
                  </Dropdown.Item>
                  <Dropdown.Item onClick={displayReportArtistModal}>
                    Report Artist
                  </Dropdown.Item>
                  {/* {artistData.appleMusicURL !== "" && (
                    <Dropdown.Item
                      onClick={() => {
                        navigateToExternalLink(artistData.appleMusicURL);
                      }}
                    >
                      Apple Music
                    </Dropdown.Item>
                  )}
                  {artistData.applePodcastURL !== "" && (
                    <Dropdown.Item
                      onClick={() => {
                        navigateToExternalLink(artistData.applePodcastURL);
                      }}
                    >
                      Apple Podcast
                    </Dropdown.Item>
                  )}
                  {artistData.faceookURL !== "" && (
                    <Dropdown.Item
                      onClick={() => {
                        navigateToExternalLink(artistData.faceookURL);
                      }}
                    >
                      Facebook
                    </Dropdown.Item>
                  )}
                  {artistData.instagramURL !== "" && (
                    <Dropdown.Item
                      onClick={() => {
                        navigateToExternalLink(artistData.instagramURL);
                      }}
                    >
                      Instagram
                    </Dropdown.Item>
                  )}
                  {artistData.iTunesURL !== "" && (
                    <Dropdown.Item
                      onClick={() => {
                        navigateToExternalLink(artistData.iTunesURL);
                      }}
                    >
                      ITunes
                    </Dropdown.Item>
                  )}

                  {artistData.spotifyURL !== "" && (
                    <Dropdown.Item
                      onClick={() => {
                        navigateToExternalLink(artistData.spotifyURL);
                      }}
                    >
                      Spotify
                    </Dropdown.Item>
                  )}
                  {artistData.tikTokURL !== "" && (
                    <Dropdown.Item
                      onClick={() => {
                        navigateToExternalLink(artistData.tikTokURL);
                      }}
                    >
                      TikTok
                    </Dropdown.Item>
                  )}

                  {artistData.youtubeURL !== "" && (
                    <Dropdown.Item
                      onClick={() => {
                        navigateToExternalLink(artistData.youtubeURL);
                      }}
                    >
                      Youtube
                    </Dropdown.Item>
                  )} */}
                </DropdownButton>
              </div>
              <CommentsModal
                type="ReportArtist"
                show={showReportArtistModal}
                id={artistData.id}
                title={"Report Artist"}
                currentUserId={
                  currentUserInfo !== undefined ? currentUserInfo.id : 0
                }
                handleClose={hideReportArtistModal}
                inputText={reportArtistInputText}
                setInputText={(val) => {
                  setReportArtistInputText(val);
                }}
                isComment={false}
                setUpdate={() => { 
                  //console.log("DONE"); 
                }}
              />
              <ShareModal
                show={showShareToModal}
                id={artistData.id}
                title={"Share To"}
                currentUserId={
                  currentUserInfo !== undefined ? currentUserInfo.id : 0
                }
                handleClose={() => {
                  setShowShareToModal(false);
                }}
                from="FeatureArtistDetails"
              />
              <div
                className="col-auto d-block d-md-none "
                title="Share"
              >
                <button
                  className="btn btn-primary round-btn  "
                  onClick={() => {
                    setShowShareToModal(true);
                  }}
                >
                  <FaShareAlt size={22} />
                  <span className="play-btn d-mobile-none">Share</span>
                </button>
              </div>
              <div
                className="col-auto showmore-btn  dropdown show dropdown-arrow-hide d-block d-md-none"
              // style={{ paddingLeft: 7 }}
              >
                <DropdownButton
                  className=""
                  variant="Secondary"
                  title={
                    <button className="btn custom-more-btn round-btn ">
                      <FiMoreVertical size={20} />
                    </button>
                  }
                >
                  <Dropdown.Item onClick={displayReportArtistModal}>
                    Report Artist
                  </Dropdown.Item>
                  {/* {artistData.appleMusicURL !== "" && (
                    <Dropdown.Item
                      onClick={() => {
                        navigateToExternalLink(artistData.appleMusicURL);
                      }}
                    >
                      Apple Music
                    </Dropdown.Item>
                  )}
                  {artistData.applePodcastURL !== "" && (
                    <Dropdown.Item
                      onClick={() => {
                        navigateToExternalLink(artistData.applePodcastURL);
                      }}
                    >
                      Apple Podcast
                    </Dropdown.Item>
                  )}
                  {artistData.faceookURL !== "" && (
                    <Dropdown.Item
                      onClick={() => {
                        navigateToExternalLink(artistData.faceookURL);
                      }}
                    >
                      Facebook
                    </Dropdown.Item>
                  )}
                  {artistData.instagramURL !== "" && (
                    <Dropdown.Item
                      onClick={() => {
                        navigateToExternalLink(artistData.instagramURL);
                      }}
                    >
                      Instagram
                    </Dropdown.Item>
                  )}
                  {artistData.iTunesURL !== "" && (
                    <Dropdown.Item
                      onClick={() => {
                        navigateToExternalLink(artistData.iTunesURL);
                      }}
                    >
                      ITunes
                    </Dropdown.Item>
                  )}

                  {artistData.spotifyURL !== "" && (
                    <Dropdown.Item
                      onClick={() => {
                        navigateToExternalLink(artistData.spotifyURL);
                      }}
                    >
                      Spotify
                    </Dropdown.Item>
                  )}
                  {artistData.tikTokURL !== "" && (
                    <Dropdown.Item
                      onClick={() => {
                        navigateToExternalLink(artistData.tikTokURL);
                      }}
                    >
                      TikTok
                    </Dropdown.Item>
                  )}

                  {artistData.youtubeURL !== "" && (
                    <Dropdown.Item
                      onClick={() => {
                        navigateToExternalLink(artistData.youtubeURL);
                      }}
                    >
                      Youtube
                    </Dropdown.Item>
                  )} */}
                </DropdownButton>
              </div>
            </div>
            <div className="row" style={{ marginRight: 0, marginTop: 10 }}>
            </div>
            <div className="row" style={{ marginRight: 0 }}>
              <div className="col-auto">
                <div className="social-link">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="moreinfo-detail" style={{ padding: 0 }}>
        <div className="container">
          <div className="row">

            <div className="col-12 col-lg-6">
              {/* <div className="biography-title" style={{ marginTop: 12 }}>
                BIOGRAPHY
              </div> */}
              {/* <div className="biogrphy-detail">
                {artistData !== undefined ? artistData.biography : ""}
              </div> */}
              <div className="row">
                <div className="col-auto">
                  <div className="biography-title fs-20 fw-600">Connect with Artist</div>
                </div>
              </div>
              <div className="form-row mt-4">
                {artistData.appleMusicURL && (<div className="col-auto">
                  <div
                    title="Apple Music"
                    className="cursor"
                    onClick={() => {
                      navigateToExternalLink(artistData.appleMusicURL);
                    }}
                  >
                    <img
                      style={{ height: 40, width: 40 }}
                      src={apple}
                      alt="Location"
                    ></img>
                  </div>
                </div>
                )}
                {artistData.applePodcastURL && (<div className="col-auto">
                  <div
                    title="Apple Podcast"
                    className="cursor"
                    onClick={() => {
                      navigateToExternalLink(artistData.applePodcastURL);
                    }}
                  >
                    <img
                      style={{ height: 40, width: 40 }}
                      src={podcaste}
                      alt="Location"
                    ></img>
                  </div>
                </div>
                )}
                {artistData.faceookURL && (<div className="col-auto">
                  <div
                    title="Facebook"
                    className="cursor"
                    onClick={() => {
                      navigateToExternalLink(artistData.faceookURL);
                    }}
                  >
                    <img
                      style={{ height: 40, width: 40 }}
                      src={facebookIcon}
                      alt="Location"
                    ></img>
                  </div>
                </div>
                )}
                {artistData.instagramURL && (<div className="col-auto">
                  <div
                    title="Instagram"
                    className="cursor"
                    onClick={() => {
                      navigateToExternalLink(artistData.instagramURL);
                    }}
                  >
                    <img
                      style={{ height: 40, width: 40 }}
                      src={instagramIcon}
                      alt="Location"
                    ></img>
                  </div>
                </div>)}
                {artistData.iTunesURL && (<div className="col-auto">
                  <div
                    title="iTune"
                    className="cursor"
                    onClick={() => {
                      navigateToExternalLink(artistData.iTunesURL);
                    }}
                  >
                    <img
                      style={{ height: 40, width: 40 }}
                      src={itune}
                      alt="Location"
                    ></img>
                  </div>
                </div>)}

                {artistData.spotifyURL && (<div className="col-auto">
                  <div
                    title="Spotify"
                    className="cursor"
                    onClick={() => {
                      navigateToExternalLink(artistData.spotifyURL);
                    }}
                  >
                    <img
                      style={{ height: 40, width: 40 }}
                      src={spotify}
                      alt="Location"
                    ></img>
                  </div>
                </div>)}
                {artistData.tikTokURL && (<div className="col-auto">
                  <div
                    title="Tiktok"
                    className="cursor"
                    onClick={() => {
                      navigateToExternalLink(artistData.tikTokURL);
                    }}
                  >
                    <img
                      style={{ height: 40, width: 40 }}
                      src={tiktok}
                      alt="Location"
                    ></img>
                  </div>
                </div>)}
                {artistData.youtubeURL && (<div className="col-auto">
                  <div
                    title="Youtube"
                    className="cursor"
                    onClick={() => {
                      navigateToExternalLink(artistData.youtubeURL);
                    }}
                  >
                    <img
                      style={{ height: 40, width: 40 }}
                      src={youtube}
                      alt="Location"
                    ></img>

                  </div>
                </div>)}
              </div>
            </div>
            <div className="col-12 col-lg-6 ">
              <div className="row mt-5 mt-md-0">
                <div className="col-auto">
                  <div className="biography-title fs-20 fw-600">Similar Artist</div>
                </div>
              </div>
              <div
                className=""
                style={{ paddingTop: artistList.length ? 12 : 12 }}
              >
                <div className="">
                  <div className="owl-carousel owl-theme">
                    {renderArtistList()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="album">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-auto">
              <div className="podcasts-title">Albums</div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              {albumList.length > 0 && (
                <div className="owl-carousel owl-theme">{renderAlbums()}</div>
              )}
              {albumList.length === 0 && (
                <div className="no-data-found">{constant.noDataFoundLabel}</div>
              )}
            </div>
          </div>
          <br />

          {showLoader === true ? (
            <div style={{ textAlign: "center" }}>
              <Loader
                type="Circles"
                // color="#00BFFF"
                color="#ffd874"
                // secondaryColor="blue"
                height={100}
                width={100}
              // timeout={3000}
              />
            </div>
          ) : (
            <div>
              {showMusics == true && (
                <Musics
                  currentPlayMusic={currentPlayMusic}
                  funsetcurrentPlaymusic={funsetcurrentPlaymusic}
                  currentALbumDetails={currentALbumDetails}
                  data={selectedAlbumMusics}
                  albumIndex={albumIndex}
                  count={count}
                  countData={countData}
                  setcountData={setcountData}
                  // showAudioPlayer={showAudioPlayer}
                  playIndex={playIndex}
                  ArtistUserId={hashCodeUrlDecode(searchData.itemId)}
                  currentUserId={
                    currentUserInfo !== undefined ? currentUserInfo.id : 0
                  }
                  musicLikesList={musicLikesList}
                  setLikes={setLikes}
                  albumType={
                    selectedAlbum !== undefined
                      ? selectedAlbum.albumType
                      : "music"
                  }
                />
              )}
            </div>
          )}
        </div>
        <br />
        <Comments
          type="artist"
          data={commentsList}
          setComments={setComments}
          currentUserId={currentUserInfo !== undefined ? currentUserInfo.id : 0}
          id={artistData.id}
          errorCommentMessage={errorCommentMessage}
          commentInputVal={commentInputVal}
          viewMoreComments={viewMoreComments}
          showViewMore={showViewMore}
          setCommentError={() => setCommentError(true)}
          setCommentInputVal={(val) => {
            setCommentInputVal(val);
            errorCommentMessage && setCommentError(false);
          }}
        />
      </section>

    </div>
  );
}

export default FeaturedArtistDetails;
