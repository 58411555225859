import base64url from "base64url";
var urlencode = require("urlencode");
export function encodeURL(data) {
  let data1 = urlencode(data, "gbk");
  return data1;
}
export function decodeURL(data) {
  return urlencode.decode(data);
}
export function hashCodeUrl(data) {
  let data1 = base64url(String(data));
  return data1;
}
export function hashCodeUrlDecode(datatodecode) {
  if(datatodecode !== undefined && datatodecode !== null && datatodecode !== ""){
    let data = base64url.decode(datatodecode);
    return data !== undefined ? data : "";
  }else{
    return "";
  }
  
}
