import React, { useState, useEffect } from "react";
import {
  Link,
  NavLink,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import moment from "moment";
import { constant } from "../../utility/constant";
import { getDataApi } from "../../utility/apiCall";
import { Modal, ModalBody, Button } from "react-bootstrap";
import twitter from "../../assets/Images/twitter.png";
import social from "../../assets/Images/social.png";
import facebook from "../../assets/Images/facebook.png";
import { BiLinkAlt } from 'react-icons/bi'; 
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  InstapaperShareButton,
  InstapaperIcon,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";
import { hashCodeUrlDecode } from "../../utility/EncodeAndDecodeUrl";
import { addSuccessMesage } from "../CustomToster/CustomToaster";

const ShareModal1 = ({ show, handleClose, title, id, currentUserId, from }) => {
  const [shareUrl, setshareUrl] = useState(null)
  let location = useLocation();
  let searchData = useParams();
  const [desc, setDesc] = useState("");
 
  const getBigstageDetails = async () => {
    setshareUrl("https://multimusicmoguls.com/videoPlayer?variant=homebigstagejs&id="+id)
  };
  const getPodcastDeatils = async () => {
    setshareUrl("https://multimusicmoguls.com/videoPlayer?variant=podcast1&id="+id)
  };
  const getAlbumDeatils = async () => {
    setshareUrl("https://multimusicmoguls.com/videoPlayer?variant=musicjs&id="+id)
  };
  const copylink = () =>{
    // const el = shareUrl
    // el.select()
    // document.execCommand("copy")
    var textField = document.createElement('textarea')
    textField.innerText = shareUrl
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    addSuccessMesage("Link Copy Successfully")

  }
  useEffect(() => {
    //console.log(from)
   if (from === "homebigstagejs") {
      getBigstageDetails();
    } else if (from === "musicjs") {
      getAlbumDeatils();
    } else if (from === "podcast1") {
      getPodcastDeatils();
    }
  }, []);
//console.log(id,shareUrl)
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop={"static"}
        keyboard={false}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#313131", border: 0 }}
        >
          <Modal.Title id="example-custom-modal-styling-title">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#313131" }}>
          <div className="row justify-content-center align-items-center mb-5">
            {/* <div className="col-auto">
              <img src={facebook} alt="Facebook" />
            </div> */}
            {/* <button style={{ marginRight: 10 }} onClick={()=>copylink()}>copylink</button> */}
            <BiLinkAlt size={32} round={true} style={{ marginRight: 10 }} onClick={()=>copylink()}/>
            <FacebookShareButton
              style={{ marginRight: 10 }}
              url={shareUrl}
              
              hashtag="#MultiMusicMoguls"
            >
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton
            style={{ marginRight: 10 }}
              url={shareUrl}
              
              // title={"hello World"}
              hashtag="#MultiMusicMoguls"
            >
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <WhatsappShareButton
            style={{ marginRight: 10 }}
             url={shareUrl}
              
             // title={"hello World"}
             hashtag="#MultiMusicMoguls"
             >

                 <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>
            {/* <InstapaperShareButton
              style={{ marginRight: 10 }}
              url={shareUrl}
              // title={"hello World"}
              hashtag="#MultiMusicMoguls"
            >
              <InstapaperIcon size={32} round={true} />
            </InstapaperShareButton> */}
            {/* <div className="col-auto">
              <img src={twitter} alt="Twitter" />
            </div> */}
            {/* <div className="col-auto">
              <img src={social} alt="Social" />
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ShareModal1;
