import { Route, BrowserRouter, Redirect, Switch } from "react-router-dom";
import HomeBigStage from '../../components/HomeBigStage/HomeBigStage';
import HomeBigStageList from "../../components/HomeBigStageList/HomeBigStageList";
import HomeFeaturedArtistsList from '../../components/HomeFeaturedArtistsList/HomeFeaturedArtistsList';
import HomeEventsList from '../../components/HomeEventsList/HomeEventsList';
import HomeNewsList from '../../components/HomeNewsList/HomeNewsList';
import HomePodcastsList from '../../components/HomePodcastsList/HomePodcastsList';
import { getDataApi } from "../../utility/apiCall";
import { constant } from "../../utility/constant";
import { useEffect } from "react";
import PlayStorePopup from "../../components/PlayStorePopup/PlayStorePopup";

function Home() {
  var idd = JSON.parse(localStorage.getItem("UserInfo"))
  const getBigStageList = async () => {
    let resBigStages = await getDataApi(constant.baseURL + "user/" + idd?.id);
    //console.log(resBigStages)
    if (idd?.id && resBigStages.isDeleted) {
      localStorage.clear();
      window.location.replace("/");
      setTimeout(() => {
        window.location.reload()
      }, 500);
    }
  }
  useEffect(() => {
    getBigStageList();
  }, [idd?.id])

  return (
    <div>
      {/* <PlayStorePopup /> */}
      <HomeBigStage />
      <HomeBigStageList />
      <HomeFeaturedArtistsList isShowOnHome={true} height={135} width={215} />
      <HomePodcastsList from="Home" />
      <HomeEventsList />
      <HomeNewsList />

    </div>
  );
}

export default Home;
