import React, { useState, useEffect } from "react";
import { constant } from "../../utility/constant";
import { deleteDataApi, getDataApi, postDataApi } from "../../utility/apiCall";
import communitybg from "../../assets/Images/communitybg.png";
// import HomeNewsList from '../../components/HomeNewsList/HomeNewsList';
import { FaHeart } from "react-icons/fa";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { colors } from "../../utility/colors";
import HomeEventsList from "../../components/HomeEventsList/HomeEventsList";
import { useHistory } from "react-router-dom";
import TopArtists from "../../components/TopArtists/TopArtists";
import { encodeURL, hashCodeUrl } from "../../utility/EncodeAndDecodeUrl";
import { addSuccessMesage } from "../../components/CustomToster/CustomToaster";
import AnchorLink from "react-anchor-link-smooth-scroll";
import mike from "../../assets/Images/mike.svg";
import mikeFill from "../../assets/Images/mikeFill.svg";
import { VerifyLoginExistOrNot } from "../../utility/VerifyLoginExistOrNot";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { FaRegCommentDots } from "react-icons/fa";
import CommentsModal from "../../components/CommentsModal/CommentsModal";
import AllArtists from "../../components/AllArtists/AllArtists";

function CommunityRoom() {
  let history = useHistory();
  let path = useLocation();
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const [updated, setUpdated] = useState(false);
  const [showViewMore, setShowViewMore] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [limit, setLimit] = useState(18);
  const [artistList, setArtistList] = useState([]);
  const [artistLikesList, setArtistLikesList] = useState([]);
  const [mainArtist, setMainArtist] = useState(undefined);
  const [artistLikesData, setArtistLikesData] = useState([]);
  const [artistResetCount, setArtistResetCount] = useState(0);
  const [artId, setArtId] = useState(0);
  const [likeUnlikeCount, setlikeUnlikeCount] = useState(null);

  const [showCommentHomeArtistModal, setShowCommentHomeArtistModal] = useState(false);
  const [commentHomeArtistInputText, setCommentHomeArtistInputText] = useState("");
  const [update, setUpdate] = useState(false);
  const [commentsCount, setCommentsCount] = useState(0);
  const [selectedArtistItem, setSelectedArtistItem] = useState(undefined);

  var artistCount = [];
  const getMainArtist = async (pageNumber) => {
    let resMainArtist = await getDataApi(
      constant.baseURL +
      "user?filter=showMainArtist||$eq||1&filter=makeFeatured||$eq||0&filter=usertype||$eq||Artist&filter=status||$eq||1&filter=isDeleted||$eq||0&limit=1&sort=id,DESC"
    );
    //console.log("resMainArtist: ", resMainArtist);
    if (resMainArtist !== undefined && resMainArtist.length > 0) {
      setMainArtist(resMainArtist[0]);
    }
  };
  const setCommentUpdate = () => {
    setCommentsCount(commentsCount + 1);
  }
  const navigateToArtistDetails = (index, isMainArtist) => {
    if (isMainArtist === true && mainArtist !== undefined) {
      history.push({
        pathname: `FeaturedArtistDetails/${hashCodeUrl(mainArtist.id)}/`,
        state: {
          id: mainArtist.id,
        },
      });
    } else {
      if (artistList.length > 0) {
        let indexItem = artistList[index];
        history.push({
          pathname: `FeaturedArtistDetails/${hashCodeUrl(indexItem.user_id)}/`,
          state: {
            id: indexItem.user_id,
          },
        });
      }
    }
    window.scrollTo(0, 0);
  };

  // const getArtists = async (pageNumber) => {
  //   let resAllArtists = await getDataApi(
  //     constant.baseURL +
  //     "user/getAllArtists?page=" +
  //     pageNumber +
  //     "&limit=" +
  //     limit +
  //     "&sort=id,DESC"
  //   );
  //   console.log("resAllArtists: ", resAllArtists);
  //   if (
  //     resAllArtists !== undefined &&
  //     resAllArtists.data !== undefined &&
  //     resAllArtists.data.length > 0
  //   ) {
  //     let artistIds = "";
  //     let artistCount = [];

  //     if (pageNumber == 1) {
  //       resAllArtists.data.map((item) => {
  //         artistIds += item.user_id + ",";
  //         artistCount.push({
  //           artistId: item.user_id,
  //           totalCount: item.likes.length,
  //         });
  //       });
  //       setlikeUnlikeCount(artistCount);
  //       // console.log("artistCount", artistCount);
  //       setArtistList([...resAllArtists.data]);
  //       getArtistLikesByUser(artistIds.substr(0, artistIds.lastIndexOf(",")));
  //     } else {
  //       setArtistList([...artistList, ...resAllArtists.data]);
  //       resAllArtists.data.map((item) => {
  //         artistIds += item.user_id + ",";
  //         artistCount.push({
  //           artistId: item.user_id,
  //           totalCount: item.likes.length,
  //         });
  //       });
  //       artistList.map((item) => {
  //         artistIds += item.user_id + ",";
  //       });
  //       getArtistLikesByUser(artistIds.substr(0, artistIds.lastIndexOf(",")));
  //     }
  //     //show hide view more page
  //     // console.log(
  //     //   Math.round(resAllArtists.totalCount / limit),
  //     //   "showhide",
  //     //   resAllArtists.page < Math.round(resAllArtists.totalCount / limit),
  //     //   resAllArtists.page
  //     // );

  //     if (
  //       resAllArtists.page < resAllArtists.totalCount &&
  //       resAllArtists.data.length === limit
  //     ) {
  //       setShowViewMore(true);
  //     } else {
  //       setShowViewMore(false);
  //     }
  //   } else {
  //     setShowViewMore(false);
  //   }
  // };
  // // const setLikes = (id) => {
  // //   if (artistLikesList.length !== 0) {
  // //     if (!artistLikesList.includes(id)) {
  // //       setLikesList([...artistLikesList, id]);
  // //     } else {
  // //       let newArr = artistLikesList.filter((i) => i !== id);
  // //       setLikesList(newArr);
  // //     }
  // //   } else {
  // //     let arr = [];
  // //     arr.push(id);
  // //     setLikesList(arr);
  // //   }
  // // };
  // const onClickViewMore = () => {
  //   setPage(page + 1);
  //   getArtists(page + 1);
  // };

  // // ------------------------------------------satyam Agarwal
  // const getArtistLikesByUser = async (str) => {
  //   // console.log("currentUserInfo", currentUserInfo, "str", str);
  //   if (currentUserInfo !== undefined && str !== "") {
  //     let resArtistLikes = await getDataApi(
  //       constant.baseURL +
  //       "likes?filter=user.id||$eq||" +
  //       currentUserInfo.id +
  //       "&filter=artist.id||$in||" +
  //       str
  //     );
  //     // console.log("resArtistLikes: ", resArtistLikes);
  //     let artistLikeItems = [];

  //     if (resArtistLikes !== undefined) {
  //       resArtistLikes.map((m) => {
  //         artistLikeItems.push(m.artist.id);
  //       });
  //       // console.log("likeItems: ", artistLikeItems);
  //       setArtistLikesList(resArtistLikes);
  //       setArtistLikesData(resArtistLikes);
  //     }
  //   }
  // };
  // const createArtistLike = async (artistId) => {
  //   // console.log(artistId);
  //   if (VerifyLoginExistOrNot()) {
  //     if (currentUserInfo !== undefined) {
  //       let jsonData = { user: currentUserInfo.id, artist: artistId };
  //       var formBody = [];
  //       for (var property in jsonData) {
  //         var encodedKey = encodeURIComponent(property);
  //         var encodedValue = encodeURIComponent(jsonData[property]);
  //         formBody.push(encodedKey + "=" + encodedValue);
  //       }
  //       formBody = formBody.join("&");
  //       var authHeader = {
  //         Accept: "application/json",
  //         "Content-Type": "application/x-www-form-urlencoded",
  //       };
  //       let respArtistLike = await postDataApi(
  //         constant.baseURL + "likes",
  //         formBody,
  //         authHeader
  //       );
  //       if (
  //         respArtistLike !== undefined &&
  //         respArtistLike.status !== undefined &&
  //         respArtistLike.status === 201
  //       ) {
  //         setUpdated(!updated);
  //         setLikes(artistId, respArtistLike.data);
  //       }
  //     }
  //   }
  // };
  // const resetArtistLike = async (artistId, likeId) => {
  //   if (VerifyLoginExistOrNot()) {
  //     var authHeader = {
  //       Accept: "application/json",
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     };
  //     let resp = await deleteDataApi(
  //       constant.baseURL + "likes/" + likeId,
  //       authHeader
  //     );
  //     if (
  //       resp !== undefined &&
  //       resp.status !== undefined &&
  //       resp.status === 200
  //     ) {
  //       setUpdated(!updated);
  //       setLikes(artistId);
  //     }
  //   }
  // };
  // const setLikes = (id, data) => {
  //   setArtId(id);
  //   setArtistResetCount(artistResetCount + 1);
  // };
  // const renderLikes = (id) => {
  //   // console.log(id);
  //   // console.log("artistLikesList", artistLikesList);
  //   if (artistLikesList.length !== 0) {
  //     let flt = artistLikesData.filter(
  //       (f) => f.artist !== undefined && f.artist.id === id
  //     );
  //     // console.log("flt", flt);
  //     if (flt.length > 0) {
  //       return (
  //         <img
  //           src={mikeFill}
  //           title={constant.unmic}
  //           key={id}
  //           onClick={() => {
  //             if (VerifyLoginExistOrNot()) resetArtistLike(id, flt[0].id);
  //             else {
  //               history.push({
  //                 pathname: `/login`,
  //                 state: {
  //                   prevPath: path.pathname,
  //                   userId:
  //                     currentUserInfo !== undefined ? currentUserInfo.id : 0,
  //                 },
  //               });
  //             }
  //           }}
  //           style={{ cursor: "pointer", width: 25 }}
  //         />
  //       );
  //     } else {
  //       return (
  //         <img
  //           src={mike}
  //           title={constant.mic}
  //           key={id}
  //           onClick={() => {
  //             if (VerifyLoginExistOrNot()) createArtistLike(id);
  //             else {
  //               history.push({
  //                 pathname: `/login`,
  //                 state: {
  //                   prevPath: path.pathname,
  //                   userId:
  //                     currentUserInfo !== undefined ? currentUserInfo.id : 0,
  //                 },
  //               });
  //             }
  //           }}
  //           style={{ cursor: "pointer", width: 25 }}
  //         />
  //       );
  //     }
  //   } else {
  //     return (
  //       <img
  //         src={mike}
  //         title={constant.mic}
  //         key={id}
  //         onClick={() => {
  //           if (VerifyLoginExistOrNot()) createArtistLike(id);
  //           else {
  //             history.push({
  //               pathname: `/login`,
  //               state: {
  //                 prevPath: path.pathname,
  //                 userId:
  //                   currentUserInfo !== undefined ? currentUserInfo.id : 0,
  //               },
  //             });
  //           }
  //         }}
  //         style={{ cursor: "pointer", width: 25 }}
  //       />
  //     );
  //   }
  // };
  // const renderLikesCount = (id) => {
  //   // console.log("likeUnlikeCount", likeUnlikeCount);
  //   setTimeout(() => {
  //     console.log("SetTimeout");
  //     if (likeUnlikeCount !== null) {
  //       likeUnlikeCount.map((item, index) => {
  //         if (item.artistId === id) {
  //           return (
  //             <div>
  //               {console.log("right", item.totalCount)}
  //               <div>{item.totalCount}</div>
  //             </div>
  //           );
  //         }
  //       });
  //     }
  //   }, 5000);

  // };
  // const renderArtists = () => {
  //   // console.log("artistList", artistList);
  //   if (artistList.length > 0 && likeUnlikeCount !== null) {
  //     return artistList.map((item, index) => {
  //       return (
  //         <div className="col-6 col-md-3 col-lg-2" key={index}>
  //           <div className="communityRoomArtist">
  //             <div className="card mm-card-bg">
  //               <img
  //                 style={{
  //                   width: "100%",
  //                   //width: "-webkit-fill-available",
  //                   cursor: "pointer",
  //                   height: 140,
  //                   //objectPosition: 'top',
  //                 }}
  //                 onClick={() => navigateToArtistDetails(index, false)}
  //                 src={
  //                   item.user_urlImage !== undefined &&
  //                     item.user_urlImage !== null &&
  //                     item.user_urlImage !== ""
  //                     ? constant.profileURL + item.user_urlImage
  //                     : constant.userImageURL
  //                 }
  //                 alt=""
  //               />
  //               <div className="mm-card-body">
  //                 <div className="row">
  //                   <div className="col-12">
  //                     <h6
  //                       className="big-stage-song-name wrapText"
  //                       title={String(
  //                         item.user_firstName + " " + item.user_lastName
  //                       )}
  //                     >
  //                       {String(item.user_firstName + " " + item.user_lastName)}
  //                     </h6>
  //                     <p className="big-stage-signer">
  //                       {item.albums.length} Albums
  //                     </p>
  //                     <div className="line"></div>
  //                   </div>
  //                 </div>

  //                 <div className="row">
  //                   <div className="col-md-12">
  //                     <div className="row">
  //                       <div className="col-auto">
  //                         <div>
  //                           {renderLikes(item.user_id)}
  //                           <span className="likes">
  //                             {item.likes.length}

  //                           </span>
  //                         </div>
  //                       </div>
  //                       <div className="col-auto">
  //                         <div>

  //                           <FaRegCommentDots
  //                             color="#b09b2f"
  //                             title="Comments"
  //                             style={{ cursor: "pointer" }}
  //                             onClick={() => {
  //                               if (VerifyLoginExistOrNot()) {
  //                                 setSelectedArtistItem(item);
  //                                 setShowCommentHomeArtistModal(true);
  //                               } else {
  //                                 history.push({
  //                                   pathname: `/login`,
  //                                   state: {
  //                                     prevPath: path.pathname,
  //                                     userId:
  //                                       currentUserInfo !== undefined
  //                                         ? currentUserInfo.id
  //                                         : 0,
  //                                   },
  //                                 });
  //                               }
  //                             }}
  //                           />
  //                           <span style={{ fontSize: "15px", paddingLeft: 10 }}>
  //                             {item.comments.length}
  //                           </span>

  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div >
  //       );
  //     });
  //   } else {
  //     return (
  //       <div className="no-data-found" style={{ paddingLeft: 15 }}>
  //         {constant.noDataFoundLabel}
  //       </div>
  //     );
  //   }
  // };
  // const renderArtists1 = () => {
  //   if (artistList.length > 0) {
  //     return artistList.map((item, index) => {
  //       return (
  //         <div
  //           className="col-12 col-lg-2"
  //           key={index}
  //           style={{
  //             marginBottom: 25,
  //             marginRight: 2,
  //             // border: "10px solid #181A20",
  //             //marginRight: 8,
  //             border: "5px solid #181A20",
  //             padding: "5px",
  //             //width: '70%'
  //           }}
  //         >
  //           <div
  //             className="latest-video-main"
  //             style={{ cursor: "pointer" }}
  //             onClick={() => navigateToArtistDetails(index, false)}
  //           >
  //             <div className="video-card" style={{ width: "100%" }}>
  //               <img
  //                 className="img-fluid latest-video-images"
  //                 style={{ height: 140, width: "100%" }}
  //                 src={
  //                   item.user_urlImage !== undefined &&
  //                     item.user_urlImage !== null &&
  //                     item.user_urlImage !== ""
  //                     ? constant.profileURL + item.user_urlImage
  //                     : constant.userImageURL
  //                 }
  //                 alt=""
  //               />
  //             </div>
  //             <div className="video-page-backdrop">
  //               <div className="hover-content">
  //                 <div className="play-icon-video">
  //                   {/* <i className="far fa-play-circle"></i> */}
  //                 </div>
  //               </div>
  //               <div className="bg-color"></div>
  //             </div>
  //           </div>
  //           <h6
  //             className="big-stage-song-name wrapText"
  //             title={String(item.user_firstName + " " + item.user_lastName)}
  //           >
  //             {String(item.user_firstName + " " + item.user_lastName)}
  //           </h6>
  //           <p className="big-stage-signer">{item.albums.length} Albums</p>
  //           <div className="row justify-content-between">
  //             <div className="col-auto">
  //               {renderLikes(item.user_id)}
  //               <span className="likes">{item.likes.length}</span>
  //             </div>
  //             <div className="col-auto">
  //               {/* <a href=""><i className="fas fa-ellipsis-v share-btn-white"></i></a> */}
  //             </div>
  //           </div>
  //         </div>
  //       );
  //     });
  //   } else {
  //     return <div className="col-12 col-lg-3 no-data-found">{constant.noDataFoundLabel}</div>;
  //   }
  // };

  // useEffect(
  //   () => {
  //     getArtists(page);
  //   },
  //   [artistResetCount],
  //   [artistList],
  // );
  useEffect(() => {
    setCommentsCount(0);
    getMainArtist();
    //getArtists(page);
  }, []);
  return (
    <div>
      <section className="community-room-banner">
        <div className="container">
          <div className="row align-items-center" style={{ alignItems: "center" }}>
            <div className="col-12 col-md-4" style={{ textAlign: "center" }}>
              {/* <div> */}
              <img
                className="img-fluid"

                src={
                  mainArtist !== undefined &&
                    mainArtist !== null &&
                    mainArtist !== ""
                    ? constant.profileURL + mainArtist.urlImage
                    : communitybg
                }
                alt="Community"
              />
              {/* </div> */}
            </div>
            <div className="col-12 col-md-8">
              <div className="communicate-right-box" style={{ marginTop: "0" }}>
                <div className="wrapText fs-40 fs-sm-30 fw-700 mt-3 mt-md-0">Communicate With</div>
                <div className="fs-25 fw-500 mt-3 mb-3">
                {mainArtist !== undefined ? mainArtist?.firstName+" "+mainArtist?.lastName : "Your Favourite Artist"}
                </div>
                <p className="fs-15 fw-500 lh-20">
                 { mainArtist !== undefined ? mainArtist?.biography:`Multi Music Moguls is the only platform where you can communicate directly with your favorite artist. You can follow them and show your love by Likes (Mic) and comments. Stay tuned.`}
                </p>
                {mainArtist !== undefined && (
                  <button
                    className="btn btn-primary"
                    onClick={() => navigateToArtistDetails(0, true)}
                  >
                    Explore Artist
                  </button>
                )}
                {mainArtist === undefined && (
                  <AnchorLink href="#ExploreArtist">
                    <button className="btn btn-primary">Explore Artist</button>
                  </AnchorLink>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="ExploreArtist">
        <TopArtists
          isShowOnHome={true}
          height={160}
          width={195}
          height10={191}
          width10={256}
          updated={updated}
        />
      </section>
      <AllArtists  isFeatured={false}></AllArtists>
      {/* <section className="upload-section-singer-name">
        <div className="container">
          <div className="featured-artists-title">Artists</div>
          <div className="row mt-2 mt-md5">{renderArtists()}</div>
          {artistList.length > 0 && showViewMore === true && (
            <div className="row justify-content-center mt-3">
              <div className="col-auto">
                <button className="btn dark" onClick={onClickViewMore}>
                  Show More
                </button>
              </div>
            </div>
          )}
        </div>
      </section> */}
      {/* <HomeNewsList/> */}
      {/* <HomeEventsList /> */}
      {showCommentHomeArtistModal === true &&
        <CommentsModal
          update={update}
          setUpdate={setCommentUpdate}
          type="CommentHomeArtist"
          show={showCommentHomeArtistModal}
          id={selectedArtistItem.user_id}
          title={"Comment - " + selectedArtistItem.user_firstName + " " + selectedArtistItem.user_lastName}
          currentUserId={currentUserInfo !== undefined ? currentUserInfo.id : 0}
          handleClose={() => {
            setShowCommentHomeArtistModal(false);
          }}
          inputText={commentHomeArtistInputText}
          setInputText={(val) => {
            setCommentHomeArtistInputText(val);
          }}
          isComment={true}
        />
      }
    </div>
  );
}

export default CommunityRoom;
