import React, { useState, useEffect } from "react";
import { constant } from "../../utility/constant";
import {
  getDataApi,
  postDataApi,
  patchDataApi,
  deleteDataApi,
} from "../../utility/apiCall";
import { colors } from "../../utility/colors";
import { useHistory } from "react-router-dom";
import uploadImage1 from "../../assets/Images/uploadImage1.png";
import Multiselect from "multiselect-react-dropdown";
import { FileDrop } from "react-file-drop";
import ImageUploader from "react-images-upload";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  addErrorMesage,
  addSuccessMesage,
} from "../CustomToster/CustomToaster";
import { AiFillCloseSquare } from "react-icons/ai";
import { addLoader, removeLoader } from "../../utility/CustomLoader";

function CreateEvents(props) {
  const calldata = async() => {
    let resNews = await getDataApi(constant.baseURL + "privacypolicy");
    //console.log(resNews)
    document.getElementById("demo").innerHTML = resNews[0].title
  }
    useEffect(() => {
      calldata()
      
    }, [])
    
     
  return (
    <div>
      <section className="community-room-banner">
        <div className="container">
        <div className="wrapText fs-40 fs-sm-30 mt-3 mt-md-0 fw-700">Privacy Policy</div>
          <div className="row align-items-center profile-center-abooutus">
            
            <div className="col-12 col-md-12">
              <div className="communicate-right-box">
                <div id="demo"></div>
                {/* <div className="fs-25 fw-500 mt-3 mb-3">With Latest Podcasts</div>
                <p className="fs-15 fw-500 lh-20">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr
                </p> */}
                {/* <AnchorLink href="#ExplorePodcast">
                  <button className="btn btn-primary" type="submit">
                    Explore Podcast
                  </button>
                </AnchorLink> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CreateEvents;
