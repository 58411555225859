import React, { useState, useEffect, useRef } from "react";
import TinySlider from "tiny-slider-react";
import { constant } from "../../utility/constant";
import { postDataApi } from "../../utility/apiCall";
import { NavLink, useHistory } from "react-router-dom";
import homeBg from "../../assets/Images/homeBg.png";
import {
  addErrorMesage,
  addSuccessMesage,
} from "../CustomToster/CustomToaster";
import { addLoader, removeLoader } from "../../utility/CustomLoader";

// import {
//   addErrorMessage,
//   addLoader,
//   addSuccessMessage,
//   removeLoader,
// } from "../../utility/LoadingContent";

const OTPVerification = () => {
  const [inputVal, setInputVal] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
  });
  const input1Ref = useRef();
  const input2Ref = useRef();
  const input3Ref = useRef();
  const input4Ref = useRef();
  const input5Ref = useRef();
  const [OTPError, setError] = useState({ isOtpErrorShow: false });
  const [OTPErrormsg, setOTPErrorMsg] = useState("");
  var history = useHistory();

  //let userEmail = localStorage.getItem('userEmail');
  let createAccountData = {};
  let otpDetails = {};
  

  if (localStorage.getItem("createAccountData") !== null) {
    createAccountData = JSON.parse(localStorage.getItem("createAccountData"));
    //console.log("createAccountData: ", createAccountData);
  }

  if (localStorage.getItem("OTPDetails") !== null) {
    otpDetails = JSON.parse(localStorage.getItem("OTPDetails"));
    //console.log("otpDetails: ", otpDetails);
  }

  useEffect(() => {
    if (history.action === "POP") {
      return history.push("/login");
    }
  }, []);

  const getInputVal = (e) => {
    let text = e.target.value;
    let name = e.target.name;
    //console.log(input1Ref);
    let inputType = e.nativeEvent.inputType;
    let numReg = new RegExp("^[0-9]$");
    if (text === "" || (numReg.test(text) && text.length <= 1)) {
      setInputVal({ ...inputVal, [name]: text });
      // console.log(text)
      if (name === "input1") {
        if (inputType === "deleteContentBackward") {
          input1Ref.current.blur();
        } else {
          input1Ref.current.blur();
          input2Ref.current.focus();
        }
      }
      if (name === "input2") {
        if (inputType === "deleteContentBackward") {
          input2Ref.current.blur();
          input1Ref.current.focus();
        } else {
          input2Ref.current.blur();
          input3Ref.current.focus();
        }
      }
      if (name === "input3") {
        if (inputType === "deleteContentBackward") {
          input3Ref.current.blur();
          input2Ref.current.focus();
        }
        input3Ref.current.blur();
        input4Ref.current.focus();
      }
      if (name === "input4") {
        if (inputType === "deleteContentBackward") {
          input4Ref.current.blur();
          input3Ref.current.focus();
        }
        input4Ref.current.blur();
        input5Ref.current.focus();
      }
      if (name === "input5") {
        if (inputType === "deleteContentBackward") {
          input5Ref.current.blur();
          input4Ref.current.focus();
        }
      }
    }
  };

  const onSubmit = () => {
    //addLoader();
    const { input1, input2, input3, input4, input5 } = inputVal;
    if ((!input1 && !input2 && !input3, !input4 && !input5)) {
      setError({ isOtpErrorShow: true });
      //removeLoader();
    }
    if (!input1) {
      setError({ isOtpErrorShow: true });
    }
    if (!input2) {
      setError({ isOtpErrorShow: true });
    }
    if (!input3) {
      setError({ isOtpErrorShow: true });
    }
    if (!input4) {
      setError({ isOtpErrorShow: true });
    }
    if (!input5) {
      setError({ isOtpErrorShow: true });
    }
    if ((input1 && input2 && input3, input4 && input5)) {
      setError({ isOtpErrorShow: false });
      //console.log(localStorage.getItem("fromComponent") === "Login");

      if (localStorage.getItem("fromComponent") === "Login") {
        //console.log("hiiiii");
        let otCode = input1 + input2 + input3 + input4 + input5;
        if (otpDetails !== undefined && otpDetails.data !== undefined) {
          if (otCode.toString() == otpDetails.data.code.toString()) {
            //dispatch(showHomePageRightCont('ResetPassword'));
            // console.log("hello");
            //addSuccessMessage("OTP Verified");
            history.push("resetpassword");
            addSuccessMesage("OTP verified");
          } else {
            //removeLoader();
            // input1Ref.innerText = "";
            setInputVal({
              input1: "",
              input2: "",
              input3: "",
              input4: "",
              input5: "",
            });
            // setInputVal((prevState) => {
            //   return {
            //     ...prevState,

            //   };
            // });
            addErrorMesage("Entered code is not valid");
            // addErrorMessage("Entered code is not valid");
            //console.log("Entered code is not valid");
          }
        }
      } else {
        //dispatch(showHomePageRightCont('CompleteProfile'));
        let otCode = input1 + input2 + input3 + input4 + input5;
        if (otpDetails !== undefined && otpDetails.data !== undefined) {
          if (otCode.toString() == otpDetails.data.code.toString()) {
            //addSuccessMessage("OTP Verified");

            //console.log("Verified User By User");
            createUser();
          } else {
            //console.log("Entered code is not valid");
            setInputVal({
              input1: "",
              input2: "",
              input3: "",
              input4: "",
              input5: "",
            });
            //console.log("otp incorrect");
            // setInputVal((prevState) => {
            //   return {
            //     ...prevState,
            //     input1: "",
            //     input2: "",
            //     input3: "",
            //     input4: "",
            //     input5: "",
            //   };
            // });
            setOTPErrorMsg("Entered code is not valid.");
          }
        }
      }
    }
  };

// //upload profile picture
// const onUploadProfilePictureAndCreateUser = async () => {
//   console.log("Selected Profile Image: ", createAccountData.urlImage);
//   addLoader();
//   if (createAccountData.urlImage.length > 0) {
//     let customHeaders = {
//       Accept: "application/json",
//       "Content-Type": "multipart/form-data",
//       Authorization: "Bearer ",
//     };
//     let data = new FormData();
//     data.append("image", createAccountData.urlImage[0]);
//     let res = await postDataApi(
//       constant.baseURL + "profilefiles",
//       data,
//       customHeaders
//     );
//     console.log("res", res);
//     if (res.data.status === 200) {
//       createUser(res.data.data.filename);
//     }else{
//       removeLoader();
//     }
//   }
// };


  const createUser = async () => {
    addLoader();
    let jsonData = {
      firstName: createAccountData.firstName,
      lastName: createAccountData.lastName,
      userName: createAccountData.userName,
      email: createAccountData.email,
      phone: createAccountData.phone,
      password: createAccountData.password,
      usertype: createAccountData.usertype,
      subscribeYouTubeChannel: createAccountData.subscribeYouTubeChannel,
      isAdminManaged:0,
      urlImage: createAccountData.urlImage
    };
    //console.log(jsonData);
    var formBody = [];
    for (var property in createAccountData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(createAccountData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded", //"none",
    };
    let responseUser = await postDataApi(
      constant.baseURL + "auth/signup",
      formBody,
      authHeader
    );
    //console.log("responseUser: ", responseUser);
    if (
      responseUser !== undefined &&
      responseUser.status !== undefined &&
      responseUser.status === 201
    ) {
      //dispatch(setUserObject(responseUser));
      localStorage.setItem("newUser", JSON.stringify(responseUser.data));
      removeLoader();
      // addSuccessMessage("Account Created!");
      history.push("completeProfile");
      addSuccessMesage("Account Created!");
      // enqueueSnackbar('Account Created!', {
      //     variant: 'success', anchorOrigin: {
      //         vertical: 'top',
      //         horizontal: 'right',
      //     },
      // });
      if (
        responseUser.data !== undefined &&
        responseUser.data.usertype === "Artist"
      ) {
        removeLoader();
        //addSuccessMessage("Account Created!");
        history.push("completeProfile");
        //dispatch(showHomePageRightCont('CompleteProfile'));
      } else {
        //dispatch(showHomePageRightCont('Login'));
        localStorage.clear();
        history.push("login");
      }
    }
  };
  const resendOTP = async () => {
    addLoader();
    // let jsonData0 = {
    //   email: createAccountData.email,
    // };
    // console.log(jsonData0);
    // localStorage.setItem("userEmail", inputVal.email);
    // localStorage.setItem("createAccountData", JSON.stringify(jsonData0));

    let otpCode = Math.floor(10000 + Math.random() * 90000);
    let jsonData = {
      email: createAccountData.email,
      code: otpCode,
      subject: "OTP For Email Verification",
      body:
        "You requested for a Open Your account, kindly use this code <b>" +
        otpCode +
        "</b> to Open your account.",
    };
    //console.log(jsonData);
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let responseOTP = await postDataApi(
      constant.baseURL + "user/verifyEmailAndSendOTP",
      jsonData,
      authHeader
    );
    //console.log("responseOTP: ", responseOTP);
    if (
      responseOTP !== undefined &&
      responseOTP.data !== undefined &&
      responseOTP.data.status !== undefined &&
      responseOTP.data.status == true
    ) {
      localStorage.setItem("OTPDetails", JSON.stringify(responseOTP));
      removeLoader();
      addSuccessMesage("OTP Sent Successfully");
      // addSuccessMessage("OTP Sent Successfully");
      history.push("otpverification");
      // dispatch(showHomePageRightCont('OTPVerification'));
    } else {
      //console.log("Please Not");
      removeLoader();
      //addErrorMessage("Unable to Validate email");
      //unable to send otp code
      addErrorMesage("Unable to validate email!");
      // addErrorMesage("Unable to validate email!");
      // enqueueSnackbar('Unable to validate email!', {
      //     variant: 'error', anchorOrigin: {
      //         vertical: 'top',
      //         horizontal: 'right',
      //     },
      // });
    }
  };
  return (
    <section className="login-page">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-6 d-none d-xl-block">
            <div className="login-bg">
              <img src={homeBg} className="img-fluid" alt="" />
            </div>
          </div>
          <div className="col-xl-6">
            <div className="Forget-page">
              <div className="row justify-content-center align-items-center">
                <div className="col-12">
                  <div className="title">OTP Verification</div>
                  <div className="login-sub-title">
                    We have sent you One Time Password on{" "}
                    <span className="otp-verification-highlight">
                      {createAccountData.email}
                    </span>{" "}
                    to verify your email id.
                  </div>
                  <div className="verrification-box">
                    <div className="form-row mt-5">
                      <div className="col">
                        <input
                          type="text"
                          className="form-control"
                          onChange={getInputVal}
                          name="input1"
                          maxLength={1}
                          value={inputVal.input1}
                          ref={input1Ref}
                          style={{
                            height: 45,
                            width: 45,
                            color: "white",
                            textAlign: "center",
                          }}
                        />
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          className="form-control"
                          onChange={getInputVal}
                          name="input2"
                          maxLength={1}
                          value={inputVal.input2}
                          ref={input2Ref}
                          style={{
                            height: 45,
                            width: 45,
                            color: "white",
                            textAlign: "center",
                          }}
                        />
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          className="form-control"
                          onChange={getInputVal}
                          name="input3"
                          value={inputVal.input3}
                          maxLength={1}
                          ref={input3Ref}
                          style={{
                            height: 45,
                            width: 45,
                            color: "white",
                            textAlign: "center",
                          }}
                        />
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          className="form-control"
                          onChange={getInputVal}
                          name="input4"
                          maxLength={1}
                          value={inputVal.input4}
                          ref={input4Ref}
                          style={{
                            height: 45,
                            width: 45,
                            color: "white",
                            textAlign: "center",
                          }}
                        />
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          className="form-control"
                          onChange={getInputVal}
                          name="input5"
                          maxLength={1}
                          value={inputVal.input5}
                          ref={input5Ref}
                          style={{
                            height: 45,
                            width: 45,
                            color: "white",
                            textAlign: "center",
                          }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <button
                          className="btn btn-color w100 btnPadding"
                          onClick={onSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-auto">
                        <div className="text-center custom-verification-text mt-5">
                          <span className="d-block mobile-text didntreceiveotp">
                            {OTPError.isOtpErrorShow === true ? (
                              <div className="errorText">
                                Please Fill OTP Details.
                              </div>
                            ) : (
                              <div>
                                {OTPErrormsg.length !== 0 ? (
                                  <div className="errorText">{OTPErrormsg}</div>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                            Didn't receive the OTP?{" "}
                            <span className="resend-text" onClick={resendOTP}>
                              Resend
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default OTPVerification;
