import React, { useState, useEffect } from "react";
// import {FaRegPlayCircle} from 'react-icons/fa';
// import {Carousel} from 'react-bootstrap';
// import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from "react-icons/hi";
// import podcast from '../../assets/Images/podcast.png';
// import { colors } from '../../utility/colors';
import TinySlider from "tiny-slider-react";
import { constant } from "../../utility/constant";
import { deleteDataApi, getDataApi, postDataApi } from "../../utility/apiCall";
import { useHistory } from "react-router-dom";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { FaRegCommentDots } from "react-icons/fa";
import { colors } from "../../utility/colors";
import { hashCodeUrl } from "../../utility/EncodeAndDecodeUrl";
import mike from "../../assets/Images/mike.svg";
import mikeFill from "../../assets/Images/mikeFill.svg";
import { VerifyLoginExistOrNot } from "../../utility/VerifyLoginExistOrNot";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { DisableButtonLoader } from "../../utility/CustomLoader";
import CommentsModal from "../CommentsModal/CommentsModal";

const TopArtists = (props) => {
  let history = useHistory();
  var path = useLocation();
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const [topTenArtists, setTopTenArtists] = useState([]);
  const [topTwentyArtists, setTopTwentyArtists] = useState([]);
  const [artistLikesList, setLikesList] = useState([]);
  const [disableItem, setDisableItem] = useState(false);
  const [selectedArtistItem, setSelectedArtistItem] = useState(undefined);
  const [showCommentHomeArtistModal, setShowCommentHomeArtistModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [commentsCount, setCommentsCount] = useState(0);
  const [commentHomeArtistInputText, setCommentHomeArtistInputText] = useState("");
  const [nId, setNId] = useState(0);
  const top10Settings = {
    controls: false,
    items: 5,
    loop: false,
    nav: false,
    mouseDrag: true,
    gutter: 10,
    responsiveClass: true,
    preventScrollOnTouch: "force",
    nested: "inner",
    responsive: {
      0: {
        items: 1.2,
        nav: false,
      },
      600: {
        items: 1,
        nav: false,
      },
      1000: {
        items: 4.5,
        nav: false,
        loop: false,
      },
    },
  };
  const top20Settings = {
    controls: false,
    items: 5,
    loop: false,
    nav: false,
    mouseDrag: true,
    gutter: 10,
    responsiveClass: true,
    preventScrollOnTouch: "force",
    nested: "inner",
    responsive: {
      0: {
        items: 1.5,
        nav: false,
      },
      600: {
        items: 1,
        nav: false,
      },
      1000: {
        items: 5.2,
        nav: false,
        loop: false,
      },
    },
  };
  const setCommentUpdate = () => {
    getTop1020Artists();
    setCommentsCount(commentsCount + 1);
  }
  const getTop1020Artists = async () => {
    //let resTop10 = await getDataApi(constant.baseURL + 'user?filter=showOnTopTen||$eq||1&usertype||$eq||Artist&limit=1&sort=id,DESC');
    let apiURL = constant.baseURL + "user/getTop10ArtistsWithUserLike";
    let jsonData = { userId: (currentUserInfo) ? currentUserInfo.id : 0 }
    let resTop10 = await postDataApi(apiURL, jsonData);
    // let resTop10 = await getDataApi(constant.baseURL + "user/getTop10Artists");
    //console.log("Top 10:", resTop10);
    if (
      resTop10 !== undefined &&
      resTop10.data !== undefined &&
      resTop10.data.data.length > 0
    ) {
      setTopTenArtists([...resTop10.data.data]);
    }
    // let resTop20 = await getDataApi(constant.baseURL + 'user?filter=showOnTopTwenty||$eq||1&usertype||$eq||Artist&limit=1&sort=id,DESC');
    let apiURL1 = constant.baseURL + "user/getTop20ArtistsWithUserLike";
    let jsonData1 = { userId: (currentUserInfo) ? currentUserInfo.id : 0 }
    let resTop20 = await postDataApi(apiURL1, jsonData1);
    //console.log("Top 20:", resTop20);
    if (
      resTop20 !== undefined &&
      resTop20.data !== undefined &&
      resTop20.data.data.length > 0
    ) {
      setTopTwentyArtists([...resTop20.data.data]);
    }
  };
  const navigateToArtistDetails = (index, isTop20) => {
    let indexItem = undefined;
    if (isTop20 == true) {
      indexItem = topTwentyArtists[index];
    } else {
      indexItem = topTenArtists[index];
    }
    //console.log(indexItem);
    // history.push({
    //   pathname: `FeaturedArtistDetails/${hashCodeUrl(
    //     indexItem.id
    //   )}/${hashCodeUrl(indexItem.biography)}`,
    //   state: {
    //     id: indexItem.id,
    //   },
    // });
    history.push({
      // pathname: `FeaturedArtistDetails/${
      //   indexItem !== undefined ? hashCodeUrl(indexItem.id) : 0
      // }/${
      //   indexItem !== undefined ? hashCodeUrl(indexItem.biography) : ""
      // }`,
      pathname: `FeaturedArtistDetails/${indexItem !== undefined ? hashCodeUrl(indexItem.id) : 0
        }/`,
      state: {
        id: indexItem !== undefined ? indexItem.id : 0,
        from:isTop20==true?"featureartists20":"featureartists10"
      },
    });
    window.scrollTo(0, 0);
  };
  // const setLikes = (id) => {
  //   if (artistLikesList.length !== 0) {
  //     if (!artistLikesList.includes(id)) {
  //       setLikesList([...artistLikesList, id]);
  //     } else {
  //       let newArr = artistLikesList.filter((i) => i !== id);
  //       setLikesList(newArr);
  //     }
  //   } else {
  //     let arr = [];
  //     arr.push(id);
  //     setLikesList(arr);
  //   }
  // };
  const createArtistLike = async (artistId, type) => {
    if (VerifyLoginExistOrNot()) {
      setDisableItem(true);
      setNId(artistId);
      let jsonData = { user: currentUserInfo.id, artist: artistId };
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resBigStageLike = await postDataApi(
        constant.baseURL + "likes",
        formBody,
        authHeader
      );
      console.log(resBigStageLike)
      if (
        resBigStageLike?.data !== undefined
      ) {
        setLikes(artistId, resBigStageLike.data, undefined, type);
        setDisableItem(false);
      }
    }
    else {
      history.push({
        pathname: `/login`,
        // state: {
        //   prevPath: path.pathname,
        //   userId: props.currentUserId,
        // },
      });
      setDisableItem(false);
    }

  };

  const resetArtistLike = async (artistId, likeId, type) => {
    if (VerifyLoginExistOrNot()) {
      setDisableItem(true);
      setNId(artistId);
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resp = await deleteDataApi(
        constant.baseURL + "likes/" + likeId,
        authHeader
      );
      if (
        resp !== undefined &&
        resp.status !== undefined &&
        resp.status === 200
      ) {
        setLikes(artistId, undefined, likeId, type);
        setDisableItem(false);

      }
    } else {
      history.push({
        pathname: `/login`,
        // state: {
        //   prevPath: path.pathname,
        //   userId: props.currentUserId,
        // },
      });
      setDisableItem(false);

    }
  };
  const setLikes = (id, data, likeId, type) => {
    let bgItems
    // console.log(bigStageList20)
    if (type) {
      bgItems = [...topTwentyArtists]
    } else {
      bgItems = [...topTenArtists]
    }
    //console.log(bgItems)
    if (data !== undefined) {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike.push(data);
          itm.likes.push(data);
        }
      });
    } else {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike = [];
          itm.likes.map((lkitm, index0) => {
            if (lkitm.id === likeId) {
              itm.likes.splice(index0, 1);
            }
          });
        }
      });
    }
    //console.log(bgItems)
    if (type) {
      setTopTwentyArtists(bgItems)
    } else {
      setTopTenArtists(bgItems)
    }
  };
  const renderLikes = (likeData, type) => {
    //console.log(likeData)
    if (likeData.userlike.length > 0) {
      return (
        <img className="w-24" src={mikeFill} alt="Unmike" style={{ cursor: "pointer" }}
        //onClick={() => resetEpisodeLike(likeData.id, likeData.userlike[0].id, type)} 
        onClick={() => {
          if (VerifyLoginExistOrNot()) resetArtistLike(likeData.id, likeData.userlike[0].id);
          else {
            history.push({
              pathname: `/login`,
              state: {
                prevPath: path.pathname,
                userId:
                  currentUserInfo !== undefined ? currentUserInfo.id : 0,
              },
            });
          }
        }}
        />
      )
    } else {
      return (
        <img className="w-24" src={mike} alt="Mike" style={{ cursor: "pointer" }}
        //onClick={() => createEpisodeLike(likeData.id, type)} 
        onClick={() => {
          if (VerifyLoginExistOrNot()) createArtistLike(likeData.id);
          else {
            history.push({
              pathname: `/login`,
              state: {
                prevPath: path.pathname,
                userId:
                  currentUserInfo !== undefined ? currentUserInfo.id : 0,
              },
            });
          }
        }}
        />
      )
    }
  };
  const renderTopArtists = (data, isTop20) => {
    if (data.length > 0) {
      return (
        <TinySlider settings={isTop20 ? top20Settings : top10Settings}>
          {data.map((item, index) => {
            return (
              <div
                className="item"
                key={index}
                style={{
                  //width: isTop20 ? props.width : props.width10,
                  width: "-webkit-fill-available",
                  // marginRight: 20,
                  // border: "10px solid #181A20",
                  // padding: "5px",
                }}
              >
                <div className="card mm-card-bg">
                  <img
                    style={{
                      cursor: "pointer",
                      height: isTop20 ? props.height : props.height10,
                      //width: isTop20 ? props.width - 30 : props.width10 - 30,
                      width: "-webkit-fill-available",
                      //objectPosition: 'top',
                    }}
                    onClick={() => navigateToArtistDetails(index, isTop20)}
                    src={
                      item.urlImage !== undefined &&
                        item.urlImage !== "user.png"
                        ? constant.profileURL + item.urlImage
                        : item.usertype == "Artist" ? constant.artistImageURL : constant.userImageURL
                    }
                    alt={item.firstName + " " + item.lastName}
                  />
                  <div className="card-body">
                    <h6 className="fs-16 mt-3 fw-600 wrapText">
                      {item.firstName + " " + item.lastName}
                    </h6>
                    <p className="fs-14 mt-3 fw-400 wrapText">
                      {item.albums.length} Albums
                    </p>
                    <div className="line"></div>
                    <div className="row ">
                      <div className="col-auto">
                      {disableItem && nId === item.id ? DisableButtonLoader() :
                            <>
                        {renderLikes(item, isTop20)}
                        <span className="likes">{item.likes !== undefined && item.likes !== null ? item.likes.length : 0}</span>
                        </>
                          }
                      </div>

                      <div>
                        <FaRegCommentDots color="#b09b2f" title="Comments"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (VerifyLoginExistOrNot()) {
                                  setSelectedArtistItem(item);
                                  setShowCommentHomeArtistModal(true);
                                } else {
                                  history.push({
                                    pathname: `/login`,
                                    state: {
                                      prevPath: path.pathname,
                                      userId:
                                        currentUserInfo !== undefined
                                          ? currentUserInfo.id
                                          : 0,
                                    },
                                  });
                                }
                              }} />
                        <span style={{ fontSize: "15px", paddingLeft: 10 }}>
                          {item.comments !== undefined && item.comments !== null ? item.comments.length : 0}
                        </span>
                        {/* <a href=""><i className="fas fa-ellipsis-v share-btn-white"></i></a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </TinySlider>
      );
    } else {
      return <div className="col-md-12 no-data-found">{constant.noDataFoundLabel}</div>;
    }
  };
  useEffect(() => {
    getTop1020Artists();
  }, []);
  useEffect(() => {
    getTop1020Artists();
  }, [props.updated]);

  return (
    <section className="featured-artists">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-auto">
            <div className="featured-artists-title">Top 10 Artists</div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="owl-carousel owl-theme" id="featured-artists">
              {renderTopArtists(topTenArtists, false)}
            </div>
          </div>
        </div>
        {topTwentyArtists.length > 0 ? <><div className="row justify-content-between mt-5">
          <div className="col-auto">
            <div className="featured-artists-title">Top 20 Artists</div>
          </div>
        </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <div className="owl-carousel owl-theme" id="featured-artists">
                {renderTopArtists(topTwentyArtists, true)}
              </div>
            </div>
          </div></> : null}
      </div>
      {showCommentHomeArtistModal === true &&
        <CommentsModal
          update={update}
          setUpdate={setCommentUpdate}
          type="CommentHomeArtist"
          show={showCommentHomeArtistModal}
          id={selectedArtistItem.id}
          title={"Comment - " + selectedArtistItem.firstName + " " + selectedArtistItem.lastName}
          currentUserId={currentUserInfo !== undefined ? currentUserInfo.id : 0}
          handleClose={() => {
            setShowCommentHomeArtistModal(false);
            getTop1020Artists();
          }}
          inputText={commentHomeArtistInputText}
          setInputText={(val) => {
            setCommentHomeArtistInputText(val);
          }}
          isComment={true}
        />
      }
    </section>
    
  );
};
export default TopArtists;
