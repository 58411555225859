import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import line from "../../assets/Images/line.png";
import { Redirect, useHistory } from "react-router-dom";

const UploadHere = (props) => {
  let history = useHistory();
  const onButtonClick = () => {
    let pageName = "";
    if (props.from === "News") {
      pageName = `CreateNews`;
    } else if (props.from === "Events") {
      pageName = `CreateEvents`;
    } else if (props.from === "Podcast") {
      pageName = `UploadPodcast`;
    }
    history.push({
      pathname: pageName,
      state: {
        id: 0,
      },
    });
  };
  return (
    <section className="upload-section mt-4">
      <div className="container">
        <div className="upload-box mb-4">
          <div className="upload-box-contant">{props.message}</div>
          <div className="upload-here d-none d-md-block">UPLOAD HERE</div>
          <div className="upload-wave d-none d-md-block img-fluid">
            <img
              className="img-fluid custom-line"
              src={line}
              alt={props.buttonText}
            />
          </div>
          <button
            className="btn btn-primary-2"
            onClick={() => {
              onButtonClick();
            }}
          >
            {props.buttonText}
          </button>
        </div>
      </div>
    </section>
  );
};
export default UploadHere;
