import React, { useState, useEffect } from "react";
import TinySlider from "tiny-slider-react";
import { constant } from "../../utility/constant";
import { deleteDataApi, getDataApi, postDataApi } from "../../utility/apiCall";
import { useHistory, useLocation } from "react-router-dom";
import { FiMoreVertical } from "react-icons/fi";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { colors } from "../../utility/colors";
import CommentsModal from "../../components/CommentsModal/CommentsModal";
import { VerifyLoginExistOrNot } from "../../utility/VerifyLoginExistOrNot";
import { FaRegCommentDots } from "react-icons/fa";
import mike from "../../assets/Images/mike.svg";
import mikeFill from "../../assets/Images/mikeFill.svg";
import { DisableButtonLoader } from "../../utility/CustomLoader";

const HomeBigStageList = () => {
  let history = useHistory();
  var path = useLocation();
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const [showCommentBigStageModal, setShowCommentBigStageModal] =
    useState(false);
  const [commentBigStageInputText, setCommentBigStageInputText] = useState("");
  const [mode, setmode] = useState("");
  const [showReportBigStageModal, setShowReportBigStageModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [reportBigStageInputText, setReportBigStageInputText] = useState("");
  const [selectedBigStage, setSelectedBigStage] = useState(undefined);
  const [bigStageList, setBigStageList] = useState([]);
  const [bigStage20List, setBigStage20List] = useState([]);
  const [bigStageLikesList, setBigStageLikesList] = useState([]);
  const [bigStageLikesData, setBigStageLikesData] = useState([]);
  const [bigStageResetCount, setBigStageResetCount] = useState(0);

  const [bigStageLikesList20, setBigStageLikesList20] = useState([]);
  const [bigStageLikesData20, setBigStageLikesData20] = useState([]);
  const [bigStageResetCount20, setBigStageResetCount20] = useState(0);

  const [bgId, setBgId] = useState(0);
  const [disableItem, setDisableItem] = useState(false);
  const top10Settings = {
    controls: false,
    items: 5,
    loop: false,
    nav: false,
    mouseDrag: true,
    gutter: 10,
    responsiveClass: true,
    preventScrollOnTouch: "force",
    nested: "inner",
    responsive: {
      0: {
        items: 1.2,
        nav: false,
      },
      600: {
        items: 2,
        nav: false,
      },
      1000: {
        items: 2.5,
        nav: false,
        loop: false,
      },
    },
  };
  const top20Settings = {
    controls: false,
    items: 5,
    loop: false,
    nav: false,
    mouseDrag: true,
    gutter: 10,
    responsiveClass: true,
    preventScrollOnTouch: "force",
    nested: "inner",
    responsive: {
      0: {
        items: 1.5,
        nav: false,
      },
      600: {
        items: 2,
        nav: false,
      },
      1000: {
        items: 3.5,
        nav: false,
        loop: false,
      },
    },
  };
  // const getTop10BigStageList = async () => {
  //   let apiURL = constant.baseURL + "bigstages/getTop10BigstagesWithUserLike";
  //   let jsonData = { userId: (userDetails.length > 0) ? userDetails[0].id : 0 }
  //   let resBigStages = await getApiWithData(apiURL, jsonData);
  //   console.log("----------resBigStages-----------", resBigStages);
  //   if (resBigStages !== undefined && resBigStages.length > 0) {
  //     setBigStageList([...resBigStages]);
  //     setHomeBigStageItem(resBigStages[0]);
  //   } else {
  //     setBigStageList([]);
  //     setHomeBigStageItem(undefined);
  //   }
  // };
  const getTop10BigStageList = async () => {
    let apiURL = constant.baseURL + "bigstages/getTop10BigstagesWithUserLike";
    let jsonData = { userId: (currentUserInfo) ? currentUserInfo.id : 0 }
    let resBigStages = await postDataApi(apiURL, jsonData);
    //console.log("Top 10:", resBigStages);
    if (resBigStages !== undefined && resBigStages.data.length > 0) {
      setBigStageList([...resBigStages.data]);
      // let bigstageIds = "";
      // resBigStages.data.map((item) => {
      //   bigstageIds += item.id + ",";
      // });

      // getBigStageLikesByUser(
      //   bigstageIds.substr(0, bigstageIds.lastIndexOf(",")), "Top10"
      // );
    }
  };
  // const createEpisodeLike = async (episodeId, type) => {
  //   if (currentUserInfo.id) {
  //     let jsonData = { user: currentUserInfo.id, bigstage: episodeId };
  //     var formBody = [];
  //     for (var property in jsonData) {
  //       var encodedKey = encodeURIComponent(property);
  //       var encodedValue = encodeURIComponent(jsonData[property]);
  //       formBody.push(encodedKey + "=" + encodedValue);
  //     }
  //     formBody = formBody.join("&");
  //     var authHeader = {
  //       Accept: "application/json",
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     };
  //     let resBigStageLike = await postDataApi(
  //       constant.baseURL + "bigstagelikes",
  //       formBody,
  //       authHeader
  //     );
  //     console.log(resBigStageLike)
  //     if (
  //       resBigStageLike.data !== undefined
  //     ) {
  //       setLikes(episodeId, resBigStageLike.data, undefined, type);
  //     }
  //   }
  //   else {
  //     history.push({
  //       pathname: `/login`,
  //       // state: {
  //       //   prevPath: path.pathname,
  //       //   userId: props.currentUserId,
  //       // },
  //     });
  //   }
  // };

  // const resetEpisodeLike = async (episodeId, likeId, type) => {
  //   console.log(episodeId, likeId)
  //   if (currentUserInfo.id) {
  //     var authHeader = {
  //       Accept: "application/json",
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     };
  //     let resp = await deleteDataApi(
  //       constant.baseURL + "bigstagelikes/" + likeId,
  //       authHeader
  //     );
  //     if (
  //       resp !== undefined &&
  //       resp.status !== undefined &&
  //       resp.status === 200
  //     ) {
  //       setLikes(episodeId, undefined, likeId, type);
  //     }
  //   } else {
  //     history.push({
  //       pathname: `/login`,
  //       // state: {
  //       //   prevPath: path.pathname,
  //       //   userId: props.currentUserId,
  //       // },
  //     });
  //   }
  // };
  const setLikes = (id, data, likeId, type) => {
    let bgItems
    // console.log(bigStageList20)
    if (type == "Top20") {
      bgItems = [...bigStage20List]
    }
    {
      bgItems = [...bigStageList]
    }
    //console.log(bgItems)
    if (data !== undefined) {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike.push(data);
          itm.likes.push(data);
        }
      });
    } else {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike = [];
          itm.likes.map((lkitm, index0) => {
            if (lkitm.id === likeId) {
              itm.likes.splice(index0, 1);
            }
          });
        }
      });
    }
    //console.log(bgItems)
    if (type == "Top20") {
      setBigStage20List(bgItems)
    } else {
      setBigStageList(bgItems)
    }
  };

  const getTop20BigStageList = async () => {
    // let resBigStages20 = await getDataApi(
    //   constant.baseURL + "bigstages/getTop20Bigstage"
    // );
    let apiURL = constant.baseURL + "bigstages/getTop20BigstagesWithUserLike";
    let jsonData = { userId: (currentUserInfo) ? currentUserInfo.id : 0 }
    let resBigStages20 = await postDataApi(apiURL, jsonData);
    if (resBigStages20 !== undefined && resBigStages20.length > 0) {
      setBigStage20List([...resBigStages20.data]);
      // let bigstageIds = "";
      // resBigStages20.map((item) => {
      //   bigstageIds += item.id + ",";
      // });
      // getBigStageLikesByUser(
      //   bigstageIds.substr(0, bigstageIds.lastIndexOf(",")), "Top20"
      // );
    }
  };

  const navigateBigStage = (index, i) => {
    //console.log(i)
    history.push({
      pathname: `Videoplayer`,
      state: { variant: "homebigstagejs", url: constant.bigStageVideoURL + i.url }
    });
  };
  // const getBigStageLikesByUser = async (str, type) => {
  //   if (currentUserInfo !== undefined && str !== "") {
  //     let resBigStageLikes = await getDataApi(
  //       constant.baseURL +
  //       "bigstagelikes?filter=user.id||$eq||" +
  //       currentUserInfo.id +
  //       "&filter=bigstage.id||$in||" +
  //       str
  //     );
  //     if (resBigStageLikes !== undefined) {
  //       if (type === "Top10") {
  //         setBigStageLikesList(resBigStageLikes);
  //         setBigStageLikesData(resBigStageLikes);
  //       } else if (type === "Top20") {
  //         setTimeout(() => {
  //           setBigStageLikesList20(resBigStageLikes);
  //           setBigStageLikesData20(resBigStageLikes);
  //         }, 200);

  //       }
  //     }
  //     setDisableItem(false);
  //   } else {
  //     setDisableItem(false);
  //   }
  // };

  const createBigStageLike = async (bigStageId, type) => {
    if (VerifyLoginExistOrNot()) {
      setDisableItem(true);
      setBgId(bigStageId);
      if (currentUserInfo !== undefined) {
        let jsonData = { user: currentUserInfo.id, bigstage: bigStageId };
        var formBody = [];
        for (var property in jsonData) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(jsonData[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        var authHeader = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        };
        let resBigStageLike = await postDataApi(
          constant.baseURL + "bigstagelikes",
          formBody,
          authHeader
        );
        //console.log("resBigStageLike", resBigStageLike);
        if (
          resBigStageLike != undefined &&
          resBigStageLike.status != undefined &&
          resBigStageLike.status == 201 && resBigStageLike.data !== undefined
        ) {
          setLikes(bigStageId, resBigStageLike.data, undefined, type);//setLikes(bigStageId, type);
          setDisableItem(false);
        } else {
          setDisableItem(false);
        }
      } else {
        setDisableItem(false);
      }
    }
  };
  const resetBigStageLike = async (bigStageId, likeId, type) => {
    if (VerifyLoginExistOrNot()) {
      setDisableItem(true);
      setBgId(bigStageId);
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resp = await deleteDataApi(
        constant.baseURL + "bigstagelikes/" + likeId,
        authHeader
      );
      //console.log("resp", resp);
      if (resp != undefined && resp.status != undefined && resp.status == 200) {
        setLikes(bigStageId, undefined, likeId, type);//setLikes(bigStageId, type);
        setDisableItem(false);
      } else {
        setDisableItem(false);
      }
    } else {
      setDisableItem(false);
    }
  };
  // const setLikes = (id, type) => {
  //   //setBgId(id);
  //   if (type === "Top10") {
  //     setBigStageResetCount(bigStageResetCount + 1);
  //   } else if (type === "Top20") {
  //     setBigStageResetCount20(bigStageResetCount20 + 1);
  //   }
  // };
  const renderLikes = (id, type, likeData) => {
    //console.log(likeData)
    if (likeData.userlike.length > 0) {
      return (
        <img className="w-24" src={mikeFill} alt="Unmike" style={{ cursor: "pointer" }}
          //onClick={() => resetEpisodeLike(likeData.id, likeData.userlike[0].id, type)}
          onClick={() => {
            if (VerifyLoginExistOrNot()) resetBigStageLike(likeData.id, likeData.userlike[0].id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    } else {
      return (
        <img className="w-24" src={mike} alt="Mike" style={{ cursor: "pointer" }}
          //onClick={() => createEpisodeLike(likeData.id, type)} 
          onClick={() => {
            if (VerifyLoginExistOrNot()) createBigStageLike(likeData.id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    }
    // if (likeData.length !== 0) {
    //   let flt = likeData.filter(
    //     (f) => f.id === id
    //   );
    //   if (flt.length > 0) {
    //     return (
    //       <img
    //         src={mikeFill}
    //         title={constant.unmic}
    //         key={id}
    //         //onClick={() => resetBigStageLike(id, flt[0].id)}
    //         onClick={() => {
    //           if (VerifyLoginExistOrNot()) resetBigStageLike(id, flt[0].id, type);
    //           else {
    //             history.push({
    //               pathname: `/login`,
    //               state: {
    //                 prevPath: path.pathname,
    //                 userId:
    //                   currentUserInfo !== undefined ? currentUserInfo.id : 0,
    //               },
    //             });
    //           }
    //         }}
    //         style={{ cursor: "pointer", width: 25 }}
    //       />
    //     );
    //   } else {
    //     return (
    //       <img
    //         src={mike}
    //         title={constant.mic}
    //         key={id}
    //         onClick={() => {
    //           if (VerifyLoginExistOrNot()) createBigStageLike(id, type);
    //           else {
    //             history.push({
    //               pathname: `/login`,
    //               state: {
    //                 prevPath: path.pathname,
    //                 userId:
    //                   currentUserInfo !== undefined ? currentUserInfo.id : 0,
    //               },
    //             });
    //           }
    //         }}
    //         style={{ cursor: "pointer", width: 25 }}
    //       />
    //     );
    //   }
    // } else {
    //   return (
    //     <img
    //       src={mike}
    //       title={constant.mic}
    //       key={id}
    //       onClick={() => {
    //         if (VerifyLoginExistOrNot()) createBigStageLike(id, type);
    //         else {
    //           history.push({
    //             pathname: `/login`,
    //             state: {
    //               prevPath: path.pathname,
    //               userId:
    //                 currentUserInfo !== undefined ? currentUserInfo.id : 0,
    //             },
    //           });
    //         }
    //       }}
    //       style={{ cursor: "pointer", width: 25 }}
    //     />
    //   );
    // }
  };
  const renderBigStageList = (data, isTop20) => {
    if (data.length > 0) {
      return (
        <TinySlider settings={isTop20 ? top20Settings : top10Settings}>
          {data.map((item, index) => {
            return (
              <div
                className="item"
                key={index}
                style={{
                  cursor: "pointer",
                  width: "-webkit-fill-available",
                }}
              >
                <div className="card mm-card-bg">
                  <img
                    src={
                      constant.bigStageThumbnailURL + item.thumbnailURL
                    }
                    style={{
                      height: isTop20 ? 159 : 200,
                      //width: isTop20 ? 200 : 400,
                      width: "-webkit-fill-available",
                    }}
                    onClick={() => navigateBigStage(index, item)}
                    alt={
                      item.user !== undefined && item.user !== null
                        ? item.user.firstName + " " + item.user.lastName
                        : ""
                    }
                  />
                  <div className="mm-card-body">
                    {/* {isTop20 === true && (
                      <> */}
                    <div className="row" style={{ alignItems: "center" }}>
                      <div className="col-12">
                        <h6
                          className="fs-16 mt-3 fw-600 wrapText"
                          title={
                            item.name !== undefined
                              ? item.name
                              : ""
                          }
                        >
                          {item.name !== undefined
                            ? String(item.name)
                            : ""}
                        </h6>
                        <div className="line"></div>
                      </div>
                      <div className="col-12">
                        <h6
                          className="fs-16 mt-3 fw-600 wrapText"
                          title={
                            item?.artist !== undefined
                              ? item?.artist
                              : ""
                          }
                        >
                          {item?.artist !== undefined
                            ? String(item?.artist)
                            : ""}
                        </h6>
                        <div className="line"></div>
                      </div>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-auto">
                            <div>
                              {disableItem && bgId === item.id ? DisableButtonLoader() :
                                <>
                                  {renderLikes(item.id, isTop20 ? "Top20" : "Top10", item)}
                                  <span className="fs-14 fw-500 pl-1">
                                    {" "}
                                    {item.likes.length}
                                  </span>
                                </>
                              }
                            </div>
                          </div>
                          <div className="col-auto">
                            <div>
                              <FaRegCommentDots
                                color="#b09b2f"
                                title="Comment"
                                fontSize={20}
                                onClick={() => {
                                  if (VerifyLoginExistOrNot()) {
                                    setSelectedBigStage(item);
                                    setShowCommentBigStageModal(true);
                                    setmode(isTop20 ? "Top20" : "Top10")
                                  } else {
                                    history.push({
                                      pathname: `/login`,
                                      state: {
                                        prevPath: path.pathname,
                                        userId:
                                          currentUserInfo !== undefined
                                            ? currentUserInfo.id
                                            : 0,
                                      },
                                    });
                                  }
                                }}
                              />
                              <span className="fs-14 fw-500 pl-3"

                              >
                                {item.comments.length}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </>
                    )} */}
                    {/* {isTop20 === false && (
                      <>
                        <div className="row" style={{ alignItems: "center" }}>
                          <div className="col-md-6 col-6">
                            <h6
                              className="big-stage-song-name wrapText"
                              title={
                                item.name !== undefined
                                  ? item.name
                                  : ""
                              }
                            >
                              {item.name !== undefined
                                ? item.name
                                : ""}
                            </h6>
                          </div>
                          <div className="col-md-6 col-6">
                            <div className="dis-flex justify-content-around">
                              <div>
                                {renderLikes(item.id, "Top10")}
                                <span style={{ fontSize: "15px" }}>
                                  {item.likes.length}
                                </span>
                              </div>
                              <div>
                                <FaRegCommentDots
                                  color="#b09b2f"
                                  title="Comment"
                                  onClick={() => {
                                    if (VerifyLoginExistOrNot()) {
                                      setSelectedBigStage(item);
                                      setShowCommentBigStageModal(true);
                                    } else {
                                      history.push({
                                        pathname: `/login`,
                                        state: {
                                          prevPath: path.pathname,
                                          userId:
                                            currentUserInfo !== undefined
                                              ? currentUserInfo.id
                                              : 0,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <span
                                  style={{ fontSize: "15px", paddingLeft: 10 }}
                                >
                                  {item.comments.length}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )} */}
                  </div>
                </div>
              </div>
            );
          })}
        </TinySlider>
      );
    } else {
      return <div className="col-md-12  no-data-found">{constant.noDataFoundLabel}</div>;
    }
  };
  const setBigsatgeUpdate = () => {
    if (mode === "Top10") {
      setBigStageResetCount(bigStageResetCount + 1);
    } else if (mode === "Top20") {
      setBigStageResetCount20(bigStageResetCount20 + 1);
    }
  }
  useEffect(() => {
    //console.log("useEffect - Top 10 refresh");
    // let bigstageIds = "";
    // bigStageList.map((item) => {
    //   bigstageIds += item.id + ",";
    // });
    // bigStage20List.map((item) => {
    //   bigstageIds += item.id + ",";
    // });
    // getBigStageLikesByUser(bigstageIds.substr(0, bigstageIds.lastIndexOf(",")));
    getTop10BigStageList();
  }, [bigStageResetCount]);

  useEffect(() => {
    //console.log("useEffect - Top 20 refresh");
    getTop20BigStageList();
  }, [bigStageResetCount20]);

  useEffect(() => {
    getTop10BigStageList();
    getTop20BigStageList();
  }, []);
  return (
    <section className="the-big-stage">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-auto">
            <div className="fs-20 fw-700">The Big Stage</div>
          </div>
        </div>
        <div className="row mt-3 mt-md-3">
          <div className="col-md-12">
            <div
              className="mb-3 f-s18 fw-600"

            >
              Top 10
            </div>
            <div className="owl-carousel owl-theme" id="big-stage">
              {renderBigStageList(bigStageList, false)}
            </div>
          </div>
        </div>
        {bigStage20List.length > 0 ? <div className="row mt-4 mt-md-4">
          <div className="col-md-12">
            <div
              className="mb-3 f-s18 fw-600"
            >
              Top 20
            </div>
            <div className="owl-carousel owl-theme" id="big-stage">
              {renderBigStageList(bigStage20List, true)}
            </div>
          </div>
        </div> : null}
      </div>
      {showCommentBigStageModal === true && selectedBigStage !== undefined && (
        <CommentsModal
          update={update}
          setUpdate={setBigsatgeUpdate}
          type="CommentHomeBigStage"
          show={showCommentBigStageModal}
          id={selectedBigStage.id}
          title={"Comment - " + selectedBigStage.name}
          currentUserId={currentUserInfo !== undefined ? currentUserInfo.id : 0}
          handleClose={() => {
            setShowCommentBigStageModal(false);
          }}
          inputText={commentBigStageInputText}
          setInputText={(val) => {
            setCommentBigStageInputText(val);
          }}
          // setUpdate={() => { console.log("DONE"); }}
          isComment={true}
        />
      )}
      {showReportBigStageModal === true && selectedBigStage !== undefined && (
        <CommentsModal
          type="ReportBigStage"
          show={showReportBigStageModal}
          id={selectedBigStage.id}
          title={"Report BigStage - " + selectedBigStage.name}
          currentUserId={currentUserInfo !== undefined ? currentUserInfo.id : 0}
          handleClose={() => {
            setShowReportBigStageModal(false);
          }}
          inputText={reportBigStageInputText}
          setInputText={(val) => {
            setReportBigStageInputText(val);
          }}
          setUpdate={() => { 
            //console.log("DONE"); 
          }}
          isComment={false}
        />
      )}
    </section>
  );
};
export default HomeBigStageList;
