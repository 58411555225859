import React, { useState, useEffect } from "react";
import { constant } from "../../utility/constant";
import { getDataApi, postDataApi, deleteDataApi } from "../../utility/apiCall";
import { colors } from "../../utility/colors";
import newzBanner from "../../assets/Images/newzBanner.png";
import { AiFillRead } from "react-icons/ai";
import { FaShareAlt } from "react-icons/fa";
import UploadHere from "../../components/UploadHere/UploadHere";
import HomeNewsList from "../../components/HomeNewsList/HomeNewsList";
import { useHistory } from "react-router-dom";
import NewsEventCard from "../../components/NewsEventCard/NewsEventCard";
import moment from "moment";
import ShareModal from "../../components/ShareModal/ShareModal";
import CommentsModal from "../../components/CommentsModal/CommentsModal";
import {
  VerifyLoginExistOrNot,
  verifyLoginUserType,
} from "../../utility/VerifyLoginExistOrNot";
import { hashCodeUrl } from "../../utility/EncodeAndDecodeUrl";
import mike from "../../assets/Images/mike.svg";
import { FaRegCommentDots } from "react-icons/fa";
import mikeFill from "../../assets/Images/mikeFill.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { DisableButtonLoader } from "../../utility/CustomLoader";

function News() {
  let history = useHistory();
  var path = useLocation();
  const [showViewMore, setShowViewMore] = useState(false);
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const [update, setUpdate] = useState(false);
  const [showShareToModal, setShowShareToModal] = useState(false);
  const [newsList, setNewsList] = useState([]);
  const [showCommentTopNewsModal, setShowCommentTopNewsModal] = useState(false);
  const [commentTopNewsInputText, setCommentTopNewsInputText] = useState("");
  const [commentsCount, setCommentsCount] = useState(0);
  const [newsLikesList, setNewsLikesList] = useState([]);
  const [newsLikesData, setNewsLikesData] = useState([]);
  const [newsResetCount, setNewsResetCount] = useState(0);
  const [selectedNews, setSelectedNews] = useState(undefined);
  const [latestNewsItem, setLatestNewsItem] = useState({
    id: 0,
    name: "No latest news found",
    img: "",
    title: "",
    subtitle: "",
    description: "",
    urlImage: "",
    created: "",
    artistName: "",
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [disableItem, setDisableItem] = useState(false);
  const [mId, setMId] = useState(0);
  const getLatestNews = async () => {
    let resLatestNews = await getDataApi(
      constant.baseURL +
      "news?filter=status||$eq||1&filter=isDeleted||$eq||0&limit=1&sort=id,DESC"
    );
    //console.log("Latest News", resLatestNews);
    if (resLatestNews.length > 0) {
      let nItem = resLatestNews[0];
      setLatestNewsItem((prevState) => {
        return {
          ...prevState,
          id: nItem.id,
          name: nItem.title,
          img:
            nItem.urlImage != undefined &&
              nItem.urlImage != null &&
              nItem.urlImage != ""
              ? constant.newsFileURL + nItem.urlImage
              : constant.musicURL,
          title: nItem.title,
          subtitle: nItem.subtitle,
          description: nItem.description,
          urlImage: nItem.urlImage,
          created: moment(nItem.created).format("DD-MMM-yyyy"),
          artistName:
            nItem.artist != undefined
              ? nItem.artist.firstName + " " + nItem.artist.lastName
              : "",
        };
      });
    }
  };
  const getNews = async (pageNumber) => {
    let apiurl = constant.baseURL +
      "news/getLatestNewsWithUserLike?page=" +
      pageNumber +
      "&limit=" +
      limit

    if (currentUserInfo) {
      apiurl += "&userId=" + currentUserInfo.id;
    }
    let resNews = await getDataApi(
      apiurl
    );
    //console.log("resNews", resNews);
    if (resNews !== undefined && resNews.data !== undefined) {
      let itms = [];
      let newsIds = "";
      resNews.data.map((p, i) => {
        itms.push({
          id: p.id,
          name: p.title,
          img:
            p.urlImage != undefined && p.urlImage != null && p.urlImage != ""
              ? constant.newsFileURL + p.urlImage
              : constant.musicURL,
          title: p.title,
          subtitle: p.subtitle,
          description: p.description,
          urlImage: p.urlImage,
          created: moment(p.created).format("DD-MMM-yyyy"),
          artistName:
            p.artist != undefined
              ? p.artist.firstName + " " + p.artist.lastName
              : "",
          likes: p.likes,
          comments: p.comments,
          userlike: p.userlike
        });
      });
      if (pageNumber == 1) {
        setNewsList(itms);
      } else {
        setNewsList([...newsList, ...itms]);
      }
      //show hide view more page
      if (resNews.page < resNews.pageCount) {
        setShowViewMore(true);
      } else {
        setShowViewMore(false);
      }

    }
  };
  // const getNewsLikes = async (str) => {
  //   let resNewsLikes = await getDataApi(
  //     constant.baseURL +
  //     "newslikes?filter=user.id||$eq||" +
  //     currentUserInfo?.id +
  //     "&filter=news.id||$in||" +
  //     str
  //   );
  //   if (resNewsLikes != undefined && resNewsLikes.length > 0) {
  //     setTimeout(() => {
  //       setNewsLikesList(resNewsLikes);
  //       setNewsLikesData(resNewsLikes);
  //     }, 100);

  //   }
  // };
  const createNewsLikes = async (newsId) => {
    if (VerifyLoginExistOrNot()) {
      setDisableItem(true);
      setMId(newsId);
      let jsonData = {
        user: currentUserInfo.id,
        news: newsId,
      };
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let respNewsLike = await postDataApi(
        constant.baseURL + "newslikes",
        formBody,
        authHeader
      );
      if (
        respNewsLike != undefined &&
        respNewsLike.status != undefined &&
        respNewsLike.status == 201 && respNewsLike.data !== undefined
      ) {
        setLikes(newsId, respNewsLike.data, undefined);//setLikes(newsId);
        setDisableItem(false);
      
      }
      setDisableItem(false);

    }
  };
  const resetNewsLike = async (newsId, likeId) => {
    if (VerifyLoginExistOrNot()) {
      setDisableItem(true);
      setMId(newsId);
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resp = await deleteDataApi(
        constant.baseURL + "newslikes/" + likeId,
        authHeader
      );
      if (resp != undefined && resp.status != undefined && resp.status == 200) {
        setLikes(newsId, undefined, likeId);//setLikes(newsId);
        setDisableItem(false);
      
      }
      setDisableItem(false);
    
    }
  };
  // const setLikes = (id) => {
  //   setNewsResetCount(newsResetCount + 1);
  // };
  const setCommentUpdate = () => {
    setCommentsCount(commentsCount + 1);
  }
  const navigateToNewsDetails = (index) => {
    let indexItem = newsList[index];
    //console.log(indexItem, "news Data");
    history.push({
      // pathname: `NewsDetails/${hashCodeUrl(indexItem.id)}/${hashCodeUrl(
      //   indexItem.description
      // )}`,
      pathname: `NewsDetails/${hashCodeUrl(indexItem.id)}`,
      state: {
        id: indexItem.id,
      },
    });
  };
  const renderNewsList = () => {
    if (newsList.length > 0) {
      return newsList.map((item, index) => {
        return (
          <div
            className="item newItemDiv"
            key={index}
            style={{ marginBottom: 20 }}
          >
            <div className="card" style={{ marginRight: 0 }}>
              <img
                className="card-img-top"
                style={{
                  height: 159,
                  width: "100%",
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  cursor: "pointer",
                }}
                onClick={() => navigateToNewsDetails(index)}
                src={item.img}
                alt="Card image cap"
              />
              <div className="event-date" style={{ bottom: 128 }}>
                {item.created}
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h5
                      className="fs-16 mt-1 fw-600 wrapText"
                      title={
                        item.name !== undefined &&
                          item.name !== null &&
                          item.name !== ""
                          ? item.name
                          : ""
                      }
                    >
                      {item.name !== undefined &&
                        item.name !== null &&
                        item.name !== ""
                        ? String(item.name)
                        : ""}
                    </h5>
                    <p
                      className="fs-14 mt-3 fw-400 wrapText"
                      title={
                        item.description !== undefined &&
                          item.description !== null &&
                          item.description !== ""
                          ? item.description
                          : ""
                      }
                    >
                      {item.description !== undefined &&
                        item.description !== null &&
                        item.description !== ""
                        ? String(item.description)
                        : ""}
                    </p>
                    <div className="line"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-auto">
                    <div>
                    {disableItem && mId === item.id ? DisableButtonLoader() :
                          <>
                      {renderLikes(item)}{" "}
                      <span style={{ fontSize: "15px" }}>
                        {item.likes.length}
                      </span>
                      </>
                        }
                    </div>
                  </div>
                  <div className="col-auto">
                    <div>
                      <FaRegCommentDots
                        color="#b09b2f"
                        title="Comments"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (VerifyLoginExistOrNot()) {
                            setSelectedNews(item);
                            setShowCommentTopNewsModal(true);
                          } else {
                            history.push({
                              pathname: `/login`,
                              state: {
                                prevPath: path.pathname,
                                userId:
                                  currentUserInfo !== undefined
                                    ? currentUserInfo.id
                                    : 0,
                              },
                            });
                          }
                        }}
                      />
                      <span style={{ fontSize: "15px", paddingLeft: 10 }}>
                        {item.comments.length}
                      </span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        );
      });
    } else {
      return <div className="no-data-found">{constant.noDataFoundLabel}</div>;
    }
  };
  const onClickViewMore = () => {
    setPage(page + 1);
    getNews(page + 1);
  };
  // const createEpisodeLike = async (episodeId) => {
  //   if (currentUserInfo.id) {
  //     let jsonData = { user: currentUserInfo.id, news: episodeId };
  //     var formBody = [];
  //     for (var property in jsonData) {
  //       var encodedKey = encodeURIComponent(property);
  //       var encodedValue = encodeURIComponent(jsonData[property]);
  //       formBody.push(encodedKey + "=" + encodedValue);
  //     }
  //     formBody = formBody.join("&");
  //     var authHeader = {
  //       Accept: "application/json",
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     };
  //     let resBigStageLike = await postDataApi(
  //       constant.baseURL + "newslikes",
  //       formBody,
  //       authHeader
  //     );
  //     console.log(resBigStageLike)
  //     if (
  //       resBigStageLike?.data !== undefined
  //     ) {
  //       setLikes(episodeId, resBigStageLike.data, undefined);
  //     }
  //   }
  // else {
  //   history.push({
  //     pathname: `/login`,
  //     // state: {
  //     //   prevPath: path.pathname,
  //     //   userId: props.currentUserId,
  //     // },
  //   });
  //   }
  // };

  // const resetEpisodeLike = async (episodeId, likeId) => {
  //   console.log(episodeId,likeId)
  //   if (currentUserInfo.id) {
  //     var authHeader = {
  //       Accept: "application/json",
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     };
  //     let resp = await deleteDataApi(
  //       constant.baseURL + "newslikes/" + likeId,
  //       authHeader
  //     );
  //     if (
  //       resp !== undefined &&
  //       resp.status !== undefined &&
  //       resp.status === 200
  //     ) {
  //       setLikes(episodeId, undefined, likeId);
  //     }
  //   } else {
  //     history.push({
  //       pathname: `/login`,
  //       // state: {
  //       //   prevPath: path.pathname,
  //       //   userId: props.currentUserId,
  //       // },
  //     });
  //   }
  // };
  const setLikes = (id, data, likeId) => {
    let bgItems = [...newsList]
    //console.log(bgItems)
    if (data !== undefined) {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike.push(data);
          itm.likes.push(data);
        }
      });
    } else {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike = [];
          itm.likes.map((lkitm, index0) => {
            if (lkitm.id === likeId) {
              itm.likes.splice(index0, 1);
            }
          });
        }
      });
    }

    setNewsList(bgItems)

  };
  const renderLikes = (likeData) => {
    // if (newsLikesList.length !== 0) {
    //   let flt = newsLikesData.filter(
    //     (f) => f.news.id !== undefined && f.news.id === id
    //   );
    //   if (flt.length > 0) {
    //     return (
    //       <img
    //         src={mikeFill}
    //         title={constant.unmic}
    //         key={id}
    //         onClick={() => {
    //           if (VerifyLoginExistOrNot()) resetNewsLike(id, flt[0].id);
    //           else {
    //             history.push({
    //               pathname: `/login`,
    //               state: {
    //                 prevPath: path.pathname,
    //                 userId:
    //                   currentUserInfo !== undefined ? currentUserInfo.id : 0,
    //               },
    //             });
    //           }
    //         }}
    //         style={{ cursor: "pointer", width: 25 }}
    //       />
    //     );
    //   } else {
    //     return (
    //       <img
    //         src={mike}
    //         title={constant.mic}
    //         key={id}
    //         onClick={() => {
    //           if (VerifyLoginExistOrNot()) createNewsLikes(id);
    //           else {
    //             history.push({
    //               pathname: `/login`,
    //               state: {
    //                 prevPath: path.pathname,
    //                 userId:
    //                   currentUserInfo !== undefined ? currentUserInfo.id : 0,
    //               },
    //             });
    //           }
    //         }}
    //         style={{ cursor: "pointer", width: 25 }}
    //       />
    //     );
    //   }
    // } else {
    //   return (
    //     <img
    //       src={mike}
    //       title={constant.mic}
    //       key={id}
    //       onClick={() => {
    //         if (VerifyLoginExistOrNot()) createNewsLikes(id);
    //         else {
    //           history.push({
    //             pathname: `/login`,
    //             state: {
    //               prevPath: path.pathname,
    //               userId:
    //                 currentUserInfo !== undefined ? currentUserInfo.id : 0,
    //             },
    //           });
    //         }
    //       }}
    //       style={{ cursor: "pointer", width: 25 }}
    //     />
    //   );
    // }
    //console.log(likeData)
    if (likeData.userlike.length > 0) {
      return (
        <img className="w-24" src={mikeFill} alt="Unmike" style={{ cursor: "pointer" }}
          //onClick={() => resetEpisodeLike(likeData.id, likeData.userlike[0].id)} 
          onClick={() => {
            if (VerifyLoginExistOrNot()) resetNewsLike(likeData.id, likeData.userlike[0].id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    } else {
      return (
        <img className="w-24" src={mike} alt="Mike" style={{ cursor: "pointer" }}
          //onClick={() => createEpisodeLike(likeData.id)} 
          onClick={() => {
            if (VerifyLoginExistOrNot()) createNewsLikes(likeData.id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    }
  };
  useEffect(() => {
    setNewsResetCount(0);
    setCommentsCount(0);
    setTimeout(() => {
      getLatestNews();
    });
    getNews(page);
  }, []);
  useEffect(() => {
    getNews(page);
  }, [newsResetCount, commentsCount]);
  return (
    <div>
      <section className="community-room-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 order-2 order-md-1">
              <div className="communicate-right-box">
                <div
                  className="wrapText fs-40 fs-sm-30 mt-3 mt-md-0 fw-700 wrapText"
                  style={{ width: "100%" }}
                  title={latestNewsItem.name}
                >
                  {String(latestNewsItem.name)}
                </div>
                <div
                  className="fs-25 fw-500 mt-3 mb-3 wrapText"
                  style={{ width: "100%" }}
                  title={
                    latestNewsItem.subtitle !== ""
                      ? latestNewsItem.subtitle
                      : ""
                  }
                >
                  {String(latestNewsItem.subtitle)}
                </div>
                <p
                  className="fs-15 fw-500 lh-20 wrapText"
                  style={{ width: "100%" }}
                  title={
                    latestNewsItem.description !== ""
                      ? latestNewsItem.description
                      : ""
                  }
                >
                  {String(latestNewsItem.description)}
                </p>
                <div className="form-row">
                  <div className="col-auto">
                    {latestNewsItem.name !== "" && latestNewsItem.id !== 0 && (
                      <button
                        className="btn btn-primary"
                        onClick={() => navigateToNewsDetails(0)}
                      >
                        <AiFillRead size={22} />
                        <span className="play-btn">Read More</span>
                      </button>
                    )}
                  </div>
                  <div className="col-auto ">
                    {latestNewsItem.name !== "" && latestNewsItem.id !== 0 && (
                      <button
                        className="btn btn-primary "
                        onClick={() => {
                          setShowShareToModal(true);
                        }}
                      >
                        <FaShareAlt size="22px" />
                        <span className="play-btn">Share</span>
                      </button>
                    )}
                    <ShareModal
                      show={showShareToModal}
                      id={latestNewsItem.id}
                      title={"Share To"}
                      currentUserId={
                        currentUserInfo !== undefined ? currentUserInfo.id : 0
                      }
                      handleClose={() => {
                        setShowShareToModal(false);
                      }}
                      from="News"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 order-1 order-md-2">
              <img
                className="img-fluid "
                src={
                  latestNewsItem.img !== "" ? latestNewsItem.img : newzBanner
                }
                alt="News"
              />
            </div>
          </div>
        </div>
      </section>
      {verifyLoginUserType() && (
        <UploadHere
          message="Do you have any new Topic on News ?"
          buttonText="Create News"
          from="News"
        />
      )}

      {/* <HomeNewsList /> */}
      <section className="latest-news">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-auto">
              <div className="fs-20 fw-700">News</div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <div className={newsList.length > 0 ? "card-deck" : ""}>
                {renderNewsList()}
              </div>
              {newsList.length > 0 && showViewMore === true && (
                <div className="row justify-content-center mt-3">
                  <div className="col-auto">
                    <button className="btn dark" onClick={onClickViewMore}>
                      Show More
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {showCommentTopNewsModal === true && selectedNews !== undefined && (
        <CommentsModal
          update={update}
          setUpdate={setCommentUpdate}
          type="CommentTopNews"
          show={showCommentTopNewsModal}
          id={selectedNews.id}
          title={"Comment - " + selectedNews.name}
          currentUserId={currentUserInfo !== undefined ? currentUserInfo.id : 0}
          handleClose={() => {
            setShowCommentTopNewsModal(false);
          }}
          inputText={commentTopNewsInputText}
          setInputText={(val) => {
            setCommentTopNewsInputText(val);
          }}
          isComment={true}
        />
      )}
    </div>
  );
}

export default News;
