import React, { useEffect, useState } from "react";
import moment from "moment";
import { constant } from "../../utility/constant";
import { getDataApi, postDataApi } from "../../utility/apiCall";
import { Modal } from "react-bootstrap";
import {
  addErrorMesage,
  addSuccessMesage,
} from "../CustomToster/CustomToaster";
import { DisableButtonLoader } from "../../utility/CustomLoader";
import { VerifyLoginExistOrNot } from "../../utility/VerifyLoginExistOrNot";
import { useHistory } from "react-router-dom";
import send from "../../assets/Images/send.svg";

const CommentsModal1 = ({
  type,
  show,
  handleClose,
  title,
  id,
  currentUserId,
  inputText,
  setInputText,
  update,
  setUpdate,
  props,
  isModel,
  isComment
}) => {
  var history = useHistory();
  const [isInputTextErrShow, setInputTextErr] = useState(false);
  const [inputTextErrMessage, setInputTextErrMessage] = useState("");
  const [buttonDisable, setbuttonDisable] = useState(false);
  const [commentsList, setCommentsList] = useState([]);
  const [showViewMore, setShowViewMore] = useState(false);
  var [pageNumber, setPageNumber] = useState(1);
  const onSubmit = () => {
    let textRegex = /^[/\a-zA-Z0-9\s\[\]\{\}\.\`\~\!\@\$\^\&\(\)\_\+\-\=\;\,]*$/;


    if (VerifyLoginExistOrNot()) {
      if (!inputText) {
        setInputTextErr(true);
        setInputTextErrMessage(constant.requiredLabel);
        return;
      } else if (inputText !== undefined && inputText !== null && inputText !== "" && inputText.trim() === "") {
        setInputTextErr(true);
        setInputTextErrMessage(constant.requiredLabel);
        return;
      } 
      // else if ((type === "ReportArtist" || type === "ReportSong" || type === "ReportPodcast" || type === "ReportBigStage" || type == "ReportEpisode") && inputText !== undefined && inputText !== null && inputText !== "" ) {
      //   setInputTextErr(true);
      //   setInputTextErrMessage(constant.invalidText);
      //   return;
      // }
      else {
        if (type === "ReportArtist" && id !== 0 && currentUserId !== 0) {
          executeAPI(
            { reporteduser: currentUserId, artist: id, comment: inputText },
            "Artist Reported",
            constant.baseURL + "reportartists"
          );
        }
        if (type === "ReportSong" && id !== 0 && currentUserId !== 0) {
          executeAPI(
            { reporteduser: currentUserId, music: id, comment: inputText },
            "Song Reported",
            constant.baseURL + "reportsongs"
          );
        }
        if (type === "CommentSong" && id !== 0 && currentUserId !== 0) {
          executeAPI(
            { reporteduser: currentUserId, music: id, comment: inputText },
            "Comment Submitted",
            constant.baseURL + "musiccomments"
          );
        }
        if (type === "ReportPodcast" && id !== 0 && currentUserId !== 0) {
          executeAPI(
            { reporteduser: currentUserId, podcasts: id, comment: inputText },
            "Podcast Reported",
            constant.baseURL + "reportpodcasts"
          );
        }
        if (type === "ReportBigStage" && id !== 0 && currentUserId !== 0) {
          executeAPI(
            { reporteduser: currentUserId, bigstage: id, comment: inputText },
            "BigStage Reported",
            constant.baseURL + "reportbigstage"
          );
        }
        if (type === "ReportEpisode" && id !== 0 && currentUserId !== 0) {
          executeAPI(
            { reporteduser: currentUserId, episode: id, comment: inputText },
            "Episode Reported",
            constant.baseURL + "reportepisode"
          );
        }
        if (
          (type === "CommentBigStage" || type === "CommentHomeBigStage") &&
          id !== 0 &&
          currentUserId !== 0
        ) {
          executeAPI(
            { user: currentUserId, bigstage: id, comment: inputText },
            "Comment Submitted",
            constant.baseURL + "bigstagecomments"
          );
        }
        if (type === "CommentTopNews" && id !== 0 && currentUserId !== 0) {
          executeAPI(
            { user: currentUserId, news: id, comment: inputText },
            "Comment Submitted",
            constant.baseURL + "newscomments"
          );
        }

        if (type === "CommentHomePodcast" && id !== 0 && currentUserId !== 0) {
          executeAPI(
            { user: currentUserId, podcasts: id, comment: inputText },
            "Comment Submitted",
            constant.baseURL + "podcastcomments"
          );
        }

        if (type === "CommentHomeEvents" && id !== 0 && currentUserId !== 0) {
          executeAPI(
            { user: currentUserId, events: id, comment: inputText },
            "Comment Submitted",
            constant.baseURL + "eventscomments"
          );
        }

        if (type === "CommentHomeArtist" && id !== 0 && currentUserId !== 0) {
          executeAPI(
            { user: currentUserId, artist: id, comments: inputText },
            "Comment Submitted",
            constant.baseURL + "artistcomments"
          );
        }
        if (type === "CommentEpisode" && id !== 0 && currentUserId !== 0) {
          executeAPI(
            { user: currentUserId, episode: id, comment: inputText },
            "Comment Submitted",
            constant.baseURL + "episodecomments"
          );
        }
      }
    } else {
      history.push({
        pathname: `/login`
      });
    }
  };
  const handleClose1 = () => {
    setInputText("");
    handleClose()
  }
  const calldata = () => {
    if (type === "ReportArtist" && id !== 0 && currentUserId !== 0) {
      getCommentApiUrl(
        constant.baseURL +
        "reportartists?filter=artist.id||$eq||" +
        id +
        "&page=" +
        pageNumber +
        "&limit=100" +
        "&sort=id,DESC"
      );
    }
    if (type === "ReportSong" && id !== 0 && currentUserId !== 0) {
      getCommentApiUrl(
        constant.baseURL +
        "reportsongs?filter=music.id||$eq||" +
        id +
        "&page=" +
        pageNumber +
        "&limit=100" +
        "&sort=id,DESC"
      );
    }
    if (type === "CommentSong" && id !== 0 && currentUserId !== 0) {
      getCommentApiUrl(
        constant.baseURL +
        "musiccomments?filter=music.id||$eq||" +
        id +
        "&page=" +
        pageNumber +
        "&limit=100" +
        "&sort=id,DESC"
      );
    }
    if (type === "ReportPodcast" && id !== 0 && currentUserId !== 0) {
      getCommentApiUrl(
        constant.baseURL +
        "reportpodcasts?filter=podcasts.id||$eq||" +
        id +
        "&page=" +
        pageNumber +
        "&limit=100" +
        "&sort=id,DESC"
      );
    }
    if (type === "ReportBigStage" && id !== 0 && currentUserId !== 0) {
      getCommentApiUrl(
        constant.baseURL +
        "reportbigstage?filter=bigstage.id||$eq||" +
        id +
        "&page=" +
        pageNumber +
        "&limit=100" +
        "&sort=id,DESC"
      );
    }
    if (type === "ReportEpisode" && id !== 0 && currentUserId !== 0) {
      getCommentApiUrl(
        constant.baseURL +
        "reportepisode?filter=episode.id||$eq||" +
        id +
        "&page=" +
        pageNumber +
        "&limit=5" +
        "&sort=id,DESC"
      );
    }
    if (
      (type === "CommentBigStage" || type === "CommentHomeBigStage") &&
      id !== 0 &&
      currentUserId !== 0
    ) {
      getCommentApiUrl(
        constant.baseURL +
        "bigstagecomments?filter=bigstage.id||$eq||" +
        id +
        "&page=" +
        pageNumber +
        "&limit=100" +
        "&sort=id,DESC"
      );
    }
    if (type === "CommentTopNews" && id !== 0 && currentUserId !== 0) {
      getCommentApiUrl(
        constant.baseURL +
        "newscomments?filter=news.id||$eq||" +
        id +
        "&page=" +
        pageNumber +
        "&limit=100" +
        "&sort=id,DESC"
      );
    }

    if (type === "CommentHomePodcast" && id !== 0 && currentUserId !== 0) {
      getCommentApiUrl(
        constant.baseURL +
        "podcastcomments?filter=podcasts.id||$eq||" +
        id +
        "&page=" +
        pageNumber +
        "&limit=100" +
        "&sort=id,DESC"
      );
    }
    if (type === "CommentHomeEvents" && id !== 0 && currentUserId !== 0) {
      getCommentApiUrl(
        constant.baseURL +
        "eventscomments?filter=events.id||$eq||" +
        id +
        "&page=" +
        pageNumber +
        "&limit=100" +
        "&sort=id,DESC"
      );
    }
    if (type === "CommentHomeArtist" && id !== 0 && currentUserId !== 0) {
      getCommentApiUrl(
        constant.baseURL +
        "artistcomments?filter=artist.id||$eq||" +
        id +
        "&page=" +
        pageNumber +
        "&limit=100" +
        "&sort=id,DESC"
      );
    }
    if (type === "CommentEpisode" && id !== 0 && currentUserId !== 0) {
      getCommentApiUrl(
        constant.baseURL +
        "episodecomments?filter=episode.id||$eq||" +
        id +
        "&page=" +
        pageNumber +
        "&limit=5" +
        "&sort=id,DESC"
      );
    }
  }

  useEffect(() => {
    //console.log("jayraj")
    calldata()
  }, [show, pageNumber, currentUserId]);

  const getCommentApiUrl = async (commentDataApi) => {
    //console.log(commentDataApi)
    let resArtistComments = await getDataApi(commentDataApi);
    //console.log("resArtistComments: ", commentDataApi, resArtistComments);
    if (
      resArtistComments !== undefined &&
      resArtistComments.data !== undefined &&
      resArtistComments.data.length > 0
    ) {
      let itms = [];
      resArtistComments.data.map((c) => {
        itms.push({
          id: c.id,
          img:
            c.user !== undefined && c.user !== null && c.user.urlImage !== undefined &&
              c.user.urlImage !== null &&
              c.user.urlImage !== ""
              ? constant.profileURL + c.user.urlImage
              : (c.reporteduser !== undefined && c.reporteduser !== null && c.reporteduser.urlImage !== undefined && c.reporteduser.urlImage !== null && c.reporteduser.urlImage !== "user.png") ? constant.profileURL + c.reporteduser.urlImage : c.reporteduser !== undefined && c.reporteduser !== null && c.reporteduser.usertype == "Artist" ? constant.artistImageURL : constant.userImageURL,
          name: c.user !== undefined && c.user !== null ? c.user.firstName + " " + c.user.lastName : (c.reporteduser !== undefined && c.reporteduser !== null) ? c.reporteduser.firstName + " " + c.reporteduser.lastName : "",
          location: c.user !== undefined && c.user !== null ? c.user.address : (c.reporteduser !== undefined && c.reporteduser !== null) ? c.reporteduser.address : "",
          comment: c.comments !== undefined && c.comments !== null && c.comments !== "" ? c.comments : c.comment,
          date: moment(c.created).format("DD MMM yyyy"),
        });
      });
      if (pageNumber == 1) {
        setCommentsList(itms);
      } else {
        setCommentsList([...commentsList, ...itms]);
      }
      //show hide view more page
      if (resArtistComments.page < resArtistComments.pageCount) {
        setShowViewMore(true);
      } else {
        setShowViewMore(false);
      }
    } else {
      setCommentsList([]);
    }
  };

  const executeAPI = async (jsonData, message, apiURL) => {
    // addLoader();
    setbuttonDisable(true);
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    let respAPI = await postDataApi(apiURL, formBody, authHeader);
    //console.log("respAPI: ", respAPI);
    if (
      respAPI !== undefined &&
      respAPI.status !== undefined &&
      respAPI.status === 201
    ) {
      setbuttonDisable(false);
      addSuccessMesage(message);
      if (isModel != "videoplayer") {
        setUpdate()
      } else {
        calldata()
      }

    } else {
      setbuttonDisable(false);
      addErrorMesage("Error encountered while submitting comment");
    }
    setInputText("");
    handleClose();
  };
  const renderComments = () => {
    if (commentsList.length !== 0) {
      return commentsList.map((item, index) => {
        return (
          <div key={index} className="feature-episode-box-2">
            <div className="form-row">
              <div className="col-12">
                <div className="form-row">
                  <div className="col-2 col-md-1">
                    <img
                      className="episode-img"
                      src={item.img}
                      alt={item.name}
                    />
                  </div>
                  <div className="col-7 col-md-8 col-lg-9">
                    <div className="form-row">
                      <div className="col-12">
                        <div className="episode-title">{item.name}</div>
                        <div className="episode-sub-title">{item.location}</div>
                      </div>
                      <div className="col-12">
                        {/* d-none d-md-block */}
                        <div className="song-discription comment-discription">{item.comment}</div>
                      </div>
                    </div>

                  </div>
                  <div className="col-3 col-md-3 col-lg-2">
                    <div className="comment-date">{item.date}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        );
      });
    } else {
      return <div className="no-data-found">{constant.noDataFoundLabel}</div>;
    }
  };
  const viewMoreComments = () => {
    setPageNumber(pageNumber++);
  };
  const renderismodel = () => {
    return <>
      <div className="mobile-comment-body mt-0  mt-md-3">
        <div className="row">
          <div className="col-12 col-md-8">
            <div className="form-row">
              <div className="col">
                <div className="username mt-3">
                  <div className="floating-label">
                    <input
                      name="inputText"
                      onChange={(e) => { setInputText(e.target.value); setInputTextErrMessage("") }}
                      value={inputText}
                      className="floating-input"
                      type="text"
                      placeholder=" "
                    ></input>
                    <span className="highlight"></span>
                    <label className="label">Comments</label>
                  </div>
                  {isInputTextErrShow === true &&
                    <div className="errorText"
                      style={{ marginTop: "0px" }}>
                      {inputTextErrMessage}
                    </div>
                  }
                </div>
              </div>
              <div className="col-auto mt-3">
                <img
                  className="img-fluid "
                  src={send}
                  alt="Add"
                  onClick={onSubmit}
                  style={{ cursor: "pointer", height: 40, width: 40 }}

                />
                {buttonDisable && DisableButtonLoader()}
              </div>
            </div>
          </div>
        </div>

      </div>
      {
        isComment === true &&
        <div className="row">
          <div className="col-12 col-md-8">
            <div className="form-row">
              <div className="col-12 col-md-12">
                <section className="comment-main">


                  <div
                    className="row"
                    style={{
                      maxHeight: props?.data?.length !== 0 ? 450 : 100,
                      overflowX: "hidden",
                      overflowY: "auto",
                    }}
                  >
                    <div></div>
                    <div className="col-12 col-lg-12" style={{ fontSize: 14 }}>
                      {renderComments()}
                    </div>
                  </div>
                  {/* <div className="row justify-content-center">
                <div className="col-auto">
                  {commentsList !== undefined &&
                    commentsList.length > 0 &&
                    showViewMore === true && (
                      <button className="btn dark" onClick={viewMoreComments}>
                        View More
                      </button>
                    )}
                </div>
              </div> */}

                </section>
              </div>
            </div>
          </div>
        </div>

      }

    </>
  }
  //console.log(isModel)
  return (
    <>
      {isModel != "videoplayer"
        ? <Modal className="comment-dialouge mobile-comment-list-scroll video-player-dialog"
          show={show}
          onHide={handleClose1}
          backdrop={"static"}
          keyboard={false}
        >
          <Modal.Header
            closeButton={!buttonDisable}
            style={{ backgroundColor: "#fff", border: 0 }}
          >
            <Modal.Title className="wrapText fs-16">{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#313131" }}>
            {renderismodel()}

          </Modal.Body>
        </Modal> : renderismodel()}
    </>
  );
};
export default CommentsModal1;