import React, { useState, useEffect } from "react";
import { constant } from "../../utility/constant";
import { getDataApi } from "../../utility/apiCall";
import { colors } from "../../utility/colors";
import { MdEvent } from "react-icons/md";
import { AiFillRead } from "react-icons/ai";
import { FaShareAlt } from "react-icons/fa";
import UploadHere from "../../components/UploadHere/UploadHere";
import HomeEventsList from "../../components/HomeEventsList/HomeEventsList";
import { useHistory } from "react-router-dom";
import ShareModal from "../../components/ShareModal/ShareModal";
import moment from "moment";
import eventsBanner from "../../assets/Images/eventsBanner.png";
import {
  VerifyLoginExistOrNot,
  verifyLoginUserType,
} from "../../utility/VerifyLoginExistOrNot";
import { hashCodeUrl } from "../../utility/EncodeAndDecodeUrl";

function Events() {
  let history = useHistory();
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const [showShareToModal, setShowShareToModal] = useState(false);
  const [noEvent, setNoEvent] = useState(false);
  const [eventsList, setEventsList] = useState([]);
  const [uniqeid, setuniqeid] = useState(0);
  const [eventItem, setEventItem] = useState({
    id: 0,
    name: "No upcoming events found",
    description: "",
    address: "",
    eventDate: "",
    img: eventsBanner,
    eventlink: "",
    artist: "",
    categories: [],
  });
  //console.log(eventItem)
  const getLatestEvent = async () => {
    // let resp = await getDataApi(
    //   constant.baseURL + "events?filter=status||$eq||1&filter=isDeleted||$eq||0&limit=1&sort=id,DESC"
    // );
    let resp = await getDataApi(constant.baseURL + "events/getUpcommingEvents/10");
    //console.log("resEvent: ", resp);
    if (resp !== undefined && resp.length > 0) {
      setuniqeid(resp[resp.length - 1].id)
      let resEvent = resp[resp.length - 1];
      setEventItem((prevState) => {
        return {
          ...prevState,
          id: resEvent.events_id,
          name: resEvent.events_name,
          description:
            resEvent.events_description !== undefined ? resEvent.events_description : "",
          address: resEvent.events_address !== undefined ? resEvent.events_address : "",
          eventDate: resEvent.events_eventdate, // != undefined && resEvent.eventdate !== null && resEvent.eventdate !== "" ? moment(resEvent.eventdate).format('DD-MMM-yyyy hh:mm A') : "",
          img:
            resEvent.events_urlImage !== undefined &&
              resEvent.events_urlImage !== null &&
              resEvent.events_urlImage !== ""
              ? constant.eventsFileURL + resEvent.events_urlImage
              : "",
          eventlink: resEvent.events_eventlink,
          artist:
            resEvent.artist !== undefined
              ? resEvent.artist.firstName + " " + resEvent.artist.lastName
              : "",
        };
      });
      
    }
  };
  // const getLatestEvent = async () => {
  //   let resp = await getDataApi(
  //     constant.baseURL + "events?filter=status||$eq||1&filter=isDeleted||$eq||0&limit=1&sort=id,DESC"
  //   );
  //   console.log("resEvent: ", resp);
  //   if (resp !== undefined && resp.length > 0) {
  //     let resEvent = resp[0];
  //     setEventItem((prevState) => {
  //       return {
  //         ...prevState,
  //         id: resEvent.id,
  //         name: resEvent.name,
  //         description:
  //           resEvent.description !== undefined ? resEvent.description : "",
  //         address: resEvent.address !== undefined ? resEvent.address : "",
  //         eventDate: resEvent.eventdate, // != undefined && resEvent.eventdate !== null && resEvent.eventdate !== "" ? moment(resEvent.eventdate).format('DD-MMM-yyyy hh:mm A') : "",
  //         img:
  //           resEvent.urlImage !== undefined &&
  //             resEvent.urlImage !== null &&
  //             resEvent.urlImage !== ""
  //             ? constant.eventsFileURL + resEvent.urlImage
  //             : "",
  //         eventlink: resEvent.eventlink,
  //         artist:
  //           resEvent.artist !== undefined
  //             ? resEvent.artist.firstName + " " + resEvent.artist.lastName
  //             : "",
  //       };
  //     });
  //   }
  // };
  const navigateToEventDetails = () => {
    history.push({
      // pathname: `EventsDetails/${hashCodeUrl(eventItem.id)}/${hashCodeUrl(
      //   eventItem.description
      // )}`,
      pathname: `EventsDetails/${hashCodeUrl(eventItem.id)}`,
      state: {
        id: eventItem.id,
      },
    });
    // history.push({
    //   pathname: `EventsDetails`,
    //   state: {
    //     id: eventItem.id,
    //   },
    // });
  };
  useEffect(() => {
    getLatestEvent();
  }, []);
  return (
    <div>
      <section
        className="events-detail-banner"
        style={{ backgroundImage: `url(${encodeURI(eventItem.img)})` }}
      >
        <div className="container">
          <div className="events-main">
            <div className="row">
              <div className="col-12 col-md-7">
                <div className="events-text">
                  <div className="event-wrapText fs-40 fw-700 wrapText">
                    {eventItem.name}
                  </div>
                  <div className="fs-25 fw-500 mt-3 mb-3 ">
                    {eventItem.eventDate !== undefined &&
                      eventItem.eventDate !== null &&
                      eventItem.eventDate !== ""
                      ? moment(eventItem.eventDate).format(
                        "dddd, MMM DD yyyy [at] hh:mmA"
                      )
                      : ""}
                  </div>
                  <p className="fs-15 fw-500 lh-20 wrapText">
                    {eventItem.description}
                  </p>
                  <div className="form-row">
                    <div className="col-auto">
                      {eventItem.name !== "" && eventItem.id !== 0 && (
                        <button className="btn btn-primary ">
                          <MdEvent size="22px" />
                          <a href={eventItem.eventlink} target="_blank" style={{color:"black"}}><span className="play-btn">Book Now</span></a>
                        </button>
                      )}
                    </div>
                    <div className="col-auto">
                      {eventItem.name !== "" && eventItem.id !== 0 && (
                        <button
                          className="btn btn-primary "
                          onClick={navigateToEventDetails}
                        >
                          <AiFillRead size="22px" />
                          <span className="play-btn">Read More</span>
                        </button>
                      )}
                    </div>
                    <div className="col-auto custom-pad">
                      {eventItem.name !== "" && eventItem.id !== 0 && (
                        <button
                          className="btn btn-primary "
                          onClick={() => {
                            setShowShareToModal(true);
                          }}
                        >
                          <FaShareAlt size="22px" />
                          <span className="play-btn">Share</span>
                        </button>
                      )}
                      {eventItem.id>0?<ShareModal
                        show={showShareToModal}
                        id={eventItem.id}
                        title={"Share To"}
                        currentUserId={
                          currentUserInfo !== undefined ? currentUserInfo.id : 0
                        }
                        handleClose={() => {
                          setShowShareToModal(false);
                        }}
                        from="Events"
                        flag="0"
                        uniqeid={eventItem.id}
                      />:null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {verifyLoginUserType() && (
        <UploadHere
          message="Do you want to have any new Concert or Event?"
          buttonText="Create Event"
          from="Events"
        />
      )}

      <HomeEventsList />
    </div>
  );
}

export default Events;
