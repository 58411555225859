import React, { useState, useEffect } from "react";
import TinySlider from "tiny-slider-react";
import { constant } from "../../utility/constant";
import {
  increaseCounter,
  decreaseCounter,
} from "../../redux/Counter/counter.actions"
import { connect } from "react-redux"
import {
  postDataApi,
  getDataApiWithBlob,
  deleteDataApi,
} from "../../utility/apiCall";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { colors } from "../../utility/colors";
import { FiMoreVertical } from "react-icons/fi";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import {
  FaRegCommentDots,
} from "react-icons/fa";
import { BiPlay, BiPause } from "react-icons/bi";
import { Dropdown, DropdownButton } from "react-bootstrap";
import CommentsModal from "../../components/CommentsModal/CommentsModal";
import AddPlayListModal from "../../components/AddPlayListModal/AddPlayListModal";
import { VerifyLoginExistOrNot } from "../../utility/VerifyLoginExistOrNot";
import VideoModal from "../../components/VideoModal/VideoModal";
import ShareModal from "../../components/ShareModal/ShareModal";
import mike from "../../assets/Images/mike.svg";
import mikeFill from "../../assets/Images/mikeFill.svg";
import { DisableButtonLoader } from "../../utility/CustomLoader";
import { GetPath } from "../../utility/GetPath";

const Musics = (props) => {
  //console.log("props: ", props)
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const [musicAlbumSongList, setmusicAlbumList] = useState(null);
  const [playOrPauseShow, setplayOrPauseShow] = useState(false);
  const [musicList, setmusicList] = useState(null);
  const [currentMusic, setcurrentMusic] = useState(false);
  const [audioInstance, setAudioInstance] = useState(null);
  const [playOrPauseMusic, setplayOrPauseMusic] = useState(false);
  const [barHide, setbarHide] = useState(false);
  const [currentIndex, setcurrentIndex] = useState(false);
  const [playOrPauseMusicindex, setplayOrPauseMusicindex] = useState(null);
  const [showCommentSongModal, setShowCommentSongModal] = useState(false);
  const [showShareToModal, setShowShareToModal] = useState(false);
  const [commentSongInputText, setCommentSongInputText] = useState("");
  const [showReportSongModal, setShowReportSongModal] = useState(false);
  const [reportSongInputText, setReportSongInputText] = useState("");
  const [showAddPlayListModal, setShowAddPlayListModal] = useState(false);
  const [selectedSong, setSelectedSong] = useState("");
  const [selectedMusicItem, setSelectedMusicItem] = useState(undefined);
  const [playIndex, setPlayIndex] = useState(0);
  const [disableItem, setDisableItem] = useState(false);
  const [commentindex, setcommentindex] = useState(null);
  //console.log(playIndex)
  const [mId, setMId] = useState(0);
  var history = useHistory();
  var itemCount = 0;
  var path = useLocation();

  const resetShowAudioPlayer = () => {
    setShowAudioPlayer(false);
    props.funsetcurrentPlaymusic()
    setplayOrPauseShow(false);
    setplayOrPauseMusic(false)
    //console.log(props.from)
    if (props.from == "playlistdetails") {
      setcurrentMusic(false)
    }
    // props.currentPlayMusic=false;
  };
  //console.log(props);
  const playMusic = (index) => {
    // console.log("jay2")
    if (musicAlbumSongList !== null) {
      setmusicList(props.data);
      itemCount = 0;
    }

    let item = props.data[index];
    // console.log("selected song item: ", item);
    setSelectedSong(item);
    if (props.albumType === "music") {
      setTimeout(() => {
        if (
          props.showAudioPlayer === undefined ||
          props.showAudioPlayer === false
        ) {
          setPlayIndex(index);
          setShowAudioPlayer(true);
          // satyam Agarwal
          setcurrentMusic(true);
          setplayOrPauseMusic(true);
          setplayOrPauseShow(true);
          itemCount = itemCount + 1;

          setplayOrPauseMusicindex(index);
        } else {
          setTimeout(() => {
            setShowAudioPlayer(true);
            setPlayIndex(index);
            // satyam Agarwal
            setcurrentMusic(true);
            setplayOrPauseMusic(true);
            setplayOrPauseShow(true);
            setplayOrPauseMusicindex(index);
          });
        }
        onPlay();
      }, 10);
    } else if (props.albumType === "video") {
      // setShowAudioPlayer(true);
      if (props.variant === "MyAlbum") {
        props.increasecounter(true, item.musicSrc, item.name, 790, 790)

      }
      //console.log(item)
      history.push({ pathname: "/VideoPlayer", state: { variant: "musicjs", url: item.musicSrc, list: props.data, details: item } })
      // show={showAudioPlayer}
      //     url={selectedSong.musicSrc}
      //     title={selectedSong.name}
      //     height={790}
      //     width={790}
    }
  };
  const downloadMusic = async (item) => {
    //console.log(item);
    if (item.urlImage !== "") {
      let respFile = await getDataApiWithBlob(
        constant.musicFileURL + item.urlImage
      );
      // console.log(respFile);
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(
        new Blob([respFile], { type: "application/octet-stream" })
      );
      link.download = item.urlImage;
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(link);
        createMusicDownload(item.id);
      }, 200);
    }
  };
  const createMusicDownload = async (musicId) => {
    let jsonData = { user: props.currentUserId, music: musicId };
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let respMusicDownload = await postDataApi(
      constant.baseURL + "musicdownloads",
      formBody,
      authHeader
    );
    // console.log("respMusicDownload: ", respMusicDownload);
    if (
      respMusicDownload !== undefined &&
      respMusicDownload.status !== undefined &&
      respMusicDownload.status === 201
    ) {
      // console.log("Music download recorded..");
    }
  };
  const updatecomment = () => {
    //console.log(commentindex)
    if (props.data[commentindex].extra1) {
      props.data[commentindex].extra1 = props.data[commentindex].extra1 + 1
    } else {
      props.data[commentindex].extra1 = props.data[commentindex].comments.length + 1
    }

  }
  const createMusicLike = async (musicId, i) => {
    // if (props.data[i].extra) {
    //   props.data[i].extra = props.data[i].extra + 1
    // }
    // else {
    //   props.data[i].extra = props.data[i].likes.length + 1
    // }

    setDisableItem(true);
    setMId(musicId);
    let jsonData = { user: props.currentUserId, music: musicId };
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let respMusicLike = await postDataApi(
      constant.baseURL + "musiclikes",
      formBody,
      authHeader
    );
    if (
      respMusicLike != undefined &&
      respMusicLike.status != undefined &&
      respMusicLike.status == 201
    ) {
      props.setLikes(musicId, respMusicLike.data, undefined);
    }
    setDisableItem(false);
  };
  // console.log( props.data)
  const resetMusicLike = async (musicId, likeId, i) => {
    // if (props.data[i].extra) {
    //   props.data[i].extra = props.data[i].extra - 1
    // } else {
    //   props.data[i].extra = props.data[i].likes.length - 1
    // }

    setDisableItem(true);
    setMId(musicId);
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let resp = await deleteDataApi(
      constant.baseURL + "musiclikes/" + likeId,
      authHeader
    );
    if (resp != undefined && resp.status != undefined && resp.status == 200) {
      props.setLikes(musicId, undefined, likeId);
    }
    setDisableItem(false);
  };
  const renderLikes = (likeData, i) => {
    //console.log("props.musicLikesList: ", props.musicLikesList, id);
    // if (props.musicLikesList.length !== 0) {
    //   let flt = props.musicLikesList.filter(
    //     (f) => f.music !== undefined && f.music.id === id
    //   );
    //   if (flt.length > 0) {
    //     return (
    //       <img
    //         src={mikeFill}
    //         title={constant.unmic}
    //         key={id}
    //         onClick={() => {
    //           if (VerifyLoginExistOrNot()) resetMusicLike(id, flt[0].id, i);
    //           else {
    //             history.push({
    //               pathname: `/login`,
    //               state: {
    //                 prevPath: path.pathname,
    //                 userId: props.currentUserId,
    //               },
    //             });
    //           }
    //         }}
    //         style={{ cursor: "pointer", width: 25 }}
    //       />
    //     );
    //   } else {
    //     return (
    //       <img
    //         src={mike}
    //         title={constant.mic}
    //         key={id}
    //         onClick={() => {
    //           if (VerifyLoginExistOrNot()) createMusicLike(id, i);
    //           else {
    //             history.push({
    //               pathname: `/login`,
    //               state: {
    //                 prevPath: path.pathname,
    //                 userId: props.currentUserId,
    //               },
    //             });
    //           }
    //         }}
    //         style={{ cursor: "pointer", width: 25 }}
    //       />
    //     );
    //   }
    // } else {
    //   return (
    //     <img
    //       src={mike}
    //       title={constant.mic}
    //       key={id}
    //       onClick={() => {
    //         if (VerifyLoginExistOrNot()) createMusicLike(id, i);
    //         else {
    //           history.push({
    //             pathname: `/login`,
    //             state: {
    //               prevPath: path.pathname,
    //               userId: props.ArtistUserId,
    //             },
    //           });
    //         }
    //       }}
    //       style={{ cursor: "pointer", width: 25 }}
    //     />
    //   );
    // }
    if (likeData?.userlike?.length > 0) {
      return (
        <img className="w-24" src={mikeFill} alt="Unmike" style={{ cursor: "pointer" }}
          //onClick={() => resetEpisodeLike(likeData.id, likeData.userlike[0].id)} 
          onClick={() => {
            if (VerifyLoginExistOrNot()) resetMusicLike(likeData.id, likeData.userlike[0].id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    } else {
      return (
        <img className="w-24" src={mike} alt="Mike" style={{ cursor: "pointer" }}
          //onClick={() => createEpisodeLike(likeData.id)} 
          onClick={() => {
            if (VerifyLoginExistOrNot()) createMusicLike(likeData.id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    }
  };
  const onPlay = () => {
    // console.log("Musics: On PLay");
  };
  const formatTime = (second) => {
    var i = 0;
    var h = 0;
    var s = parseInt(second);
    if (s >= 60) {
      i = parseInt(s / 60);
      s = parseInt(s % 60);

      if (i >= 60) {
        h = parseInt(i / 60);
        i = parseInt(i % 60);
      }
    }
    if (h > 0) return [zero(h), zero(i), zero(s)].join(":");
    return [zero(i), zero(s)].join(":");
    //console.log("formatTime- ",[zero(i), zero(s)].join(':'));
  };
  const zero = (v) => {
    return v >> 0 < 10 ? "0".concat(v) : v;
  };
  // const updateDurationInMusics = () => {
  //   let promises_array = [];
  //   props.data.map((item) => {
  //     promises_array.push(
  //       new Promise((resolve, reject) => {
  // 		  return loadFileDuration(item.musicSrc)
  //         .then((resp) => {
  //           item["time"] = resp;
  //           //console.log("new music item: ", item);
  //           resolve(true);
  //         })
  //         .catch((error) => {
  //           reject(false);
  //         });
  //       })
  //     );
  //   });
  //   console.log("Completed: ", props.data);
  // }
  // const loadFileDuration = async (url) => {
  //   let audioCtx = new (window.AudioContext || window.webkitAudioContext)();
  //   const response = await fetch(url);
  //   //console.log(response);
  //   const arrayBuffer = await response.arrayBuffer();
  //   if (arrayBuffer) {
  //     let audioBuffer = await audioCtx.decodeAudioData(arrayBuffer);
  //     //console.log('url: ', url , '-- audioBuffer.duration:', audioBuffer.duration);
  //     return audioBuffer.duration;
  //   }else{
  //     return "0:00";
  //   }
  // };
  // useEffect(() => {
  //   console.log("Musics - useEffect - ", props.data);
  //   setTimeout(() => {
  //     updateDurationInMusics();
  //   }, 200);
  //   //loadFileDuration(props.data[0].musicSrc);
  // }, [props.data]);

  // ----------------Satyam Agarwal
  //console.log("44444455858", currentMusic)
  useEffect(() => {
    //console.log(props.currentPlayMusic)
    //console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
    if (props.currentALbumDetails === true && currentMusic === false) {
      //console.log("jay1")
      playMusic(0)
      setShowAudioPlayer(true);
      setcurrentMusic(true);
      setPlayIndex(0);
      // satyam Agarwal
      setcurrentMusic(true);
      setplayOrPauseMusic(true);
      setplayOrPauseShow(true);
      setplayOrPauseMusicindex(0);
      if (props.variant !== "MyAlbum") {
        window.scrollTo(0, 1200);
      }

    }
    if (props.currentPlayMusic === true && currentMusic === true) {
      //console.log("jay6")
      playMusic(0)
      setShowAudioPlayer(true);
      setcurrentMusic(true);
      setPlayIndex(0);
      // satyam Agarwal
      setcurrentMusic(true);
      setplayOrPauseMusic(true);
      setplayOrPauseShow(true);
      setplayOrPauseMusicindex(0);
      if (props.variant !== "MyAlbum") {
        window.scrollTo(0, 1200);
      }
    }
    if (props.currentALbumDetails === true && currentMusic === true) {
      //console.log("jay2")
      setmusicAlbumList(props.data);
      if (props.countData > 1) {
        //console.log("jay3")
        itemCount = 0;
        props.setcountData(1);
        setplayOrPauseMusicindex(null);
      }
    } else {
      //("jay4")
      // console.log("datta not found");
      setmusicList(props.data);
    }
    //console.log("jay5")
  }, [
    props.currentPlayMusic,
    props.currentALbumDetails,
    musicList,
    currentMusic,
    itemCount,
    props.countData,
    playOrPauseMusicindex,
  ]);


  const getAudioInstance = (instance) => {
    // console.log("Getting audio instance", instance);
    setAudioInstance(instance);
  };

  const onAudioPlay = () => {
    audioInstance.play();
    setplayOrPauseShow(true);
    setbarHide(false);
  };
  const onAudioPause = () => {
    audioInstance.pause();
    setplayOrPauseShow(false);
    setbarHide(true);
  };
  const renderMusics = () => {
    if (props.data.length > 0) {
      return props.data.map((item, index) => {
        let mItem = item;
        //console.log(index)
        return (
          <div className="row mt-3" key={index}>
            <div className="col-12 col-md-12">
              <div className="song-table d-none d-md-block" >
                <div className="row align-items-center align-items-md-center">
                  <div className="col-12 col-md-1">
                    {playOrPauseMusic === false ? (
                      <BiPlay
                        title="Click to play"
                        className="play-song"
                        style={{
                          cursor: "pointer",
                          marginLeft: props.showMusicLike ? 0 : 25,
                        }}
                        onClick={() => playMusic(index)}
                      />
                    ) : (
                      <div>
                        {playOrPauseMusicindex === index && itemCount === 0 ? (
                          <div>
                            {playOrPauseShow === true ? (
                              <BiPause
                                title="Click to pause"
                                className="play-song"
                                style={{
                                  cursor: "pointer",
                                  marginLeft: props.showMusicLike ? 0 : 25,
                                }}
                                onClick={() => {
                                  // console.log("pause Circle");
                                  onAudioPause(index);
                                }}
                              />
                            ) : (
                              <BiPlay
                                title="Click to play"
                                className="play-song"
                                style={{
                                  cursor: "pointer",
                                  marginLeft: props.showMusicLike ? 0 : 25,
                                }}
                                onClick={() => {
                                  // console.log("play Circle");
                                  onAudioPlay(index);
                                }}
                              />
                            )}
                          </div>
                        ) : (
                          <BiPlay
                            title="Click to play"
                            className=" play-song"
                            style={{
                              cursor: "pointer",
                              marginLeft: props.showMusicLike ? 0 : 25,
                            }}
                            onClick={() => playMusic(index)}
                          />
                        )}
                      </div>
                    )}
                  </div>
                  {props.showMusicLike === true && (
                    <div className="col-12 col-md-3">
                      <div className="fs-16  fw-600 wrapText " title={item.title}>
                        {String(item.title)}
                      </div>
                    </div>
                  )}
                  {props.showMusicLike === false && (
                    <div className="col-12 col-md-2">
                      <div className="fs-16  fw-600 wrapText" title={item.title}>
                        {String(item.title)}
                      </div>
                    </div>
                  )}
                  <div
                    className={`col-10 col-md-3 ${props.showMusicLike === true ? "d-block d-md-none" : ""
                      }`}
                  >
                    <div className="fs-14 mt-4 mb-4  fw-400 wrapText" title={item.albumname}>
                      {String(item.albumname)}
                    </div>
                  </div>
                  <div className="col-md-1 d-none d-md-block">
                    {showAudioPlayer === false && <div>&nbsp;</div>}
                    {showAudioPlayer === true &&
                      playOrPauseMusicindex === index
                      &&
                      itemCount === 0 &&
                      (
                        <>
                          <div id="bars">
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                          </div>
                        </>
                      )}
                  </div>
                  <div className={`col-3 ${props.showMusicLike === true ? "col-md-2" : "col-md-1"}`}>
                    <div className="song-time">
                      {item.time !== undefined &&
                        item.time !== null &&
                        item.time !== NaN
                        ? formatTime(item.time)
                        : "0:00"}
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <div className="dis-flex justify-content-around">
                      <div>
                        {disableItem && mId === item.id ? DisableButtonLoader() :
                          <>
                            {renderLikes(item, index)}
                            <span style={{ fontSize: "15px", paddingLeft: 5 }}>
                              {item.extra == undefined ? item?.likes?.length : item?.extra}
                            </span>
                          </>
                        }
                      </div>
                      <div>
                        <FaRegCommentDots
                          color="#b09b2f"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (VerifyLoginExistOrNot()) {
                              setcommentindex(index)
                              setSelectedMusicItem(item);
                              setShowCommentSongModal(true);
                            } else {
                              history.push({
                                pathname: `/login`,
                                state: {
                                  prevPath: path.pathname,
                                  userId: props.currentUserId,
                                },
                              });
                            }
                          }}
                        />
                        <span style={{ fontSize: "15px", paddingLeft: 10 }}>
                          {/* {item.comments !== undefined ? item.comments.length : 0} */}
                          {item.extra1 == undefined ? item?.comments?.length : item?.extra1}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-3 col-md-1">
                    <div className="dropdown show dropdown-arrow-hide">
                      <DropdownButton
                        className="artistMore"
                        variant="Secondary"
                        title={
                          <FiMoreVertical
                            style={{ cursor: "pointer" }}
                            size="22px"
                            color={colors.color1}
                          ></FiMoreVertical>
                        }
                      >
                        <Dropdown.Item
                          onClick={() => {
                            if (VerifyLoginExistOrNot()) {
                              setSelectedMusicItem(item);
                              setShowAddPlayListModal(true);
                            } else {
                              history.push({
                                pathname: `/login`,
                                state: {
                                  prevPath: path.pathname,
                                  userId: props.currentUserId,
                                },
                              });
                            }
                          }}
                        >
                          Add To Playlist
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            if (VerifyLoginExistOrNot()) downloadMusic(item);
                            else {
                              history.push({
                                pathname: `/login`,
                                state: {
                                  prevPath: path.pathname,
                                  userId: props.currentUserId,
                                },
                              });
                            }
                          }}
                        >
                          Download Song
                        </Dropdown.Item>
                        {props.variant !== "MyAlbum" && (
                          <Dropdown.Item
                            onClick={() => {
                              setSelectedMusicItem(item);
                              setShowShareToModal(true);
                            }}
                          >
                            Share
                          </Dropdown.Item>
                        )}
                        {/* <Dropdown.Item>Share</Dropdown.Item> */}

                        <Dropdown.Item
                          onClick={() => {
                            if (VerifyLoginExistOrNot()) {
                              setSelectedMusicItem(item);
                              setShowReportSongModal(true);
                            } else {
                              history.push({
                                pathname: `/login`,
                                state: {
                                  prevPath: path.pathname,
                                  userId: props.currentUserId,
                                },
                              });
                            }
                          }}
                        >
                          Report Song
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                    {showCommentSongModal === true &&
                      selectedMusicItem !== undefined && (
                        <CommentsModal
                          type="CommentSong"
                          show={showCommentSongModal}
                          id={selectedMusicItem.id}
                          title={"Comment - " + selectedMusicItem.title}
                          currentUserId={
                            currentUserInfo !== undefined
                              ? currentUserInfo.id
                              : 0
                          }
                          handleClose={() => {
                            setShowCommentSongModal(false);
                          }}
                          inputText={commentSongInputText}
                          setInputText={(val) => {
                            setCommentSongInputText(val);
                          }}
                          isComment={true}
                          setUpdate={() => updatecomment()}
                        />
                      )}

                    {showShareToModal === true &&
                      selectedMusicItem !== undefined && (
                        <>
                          <ShareModal
                            show={showShareToModal}
                            id={selectedMusicItem.id}
                            title={"Share To"}
                            currentUserId={
                              currentUserInfo !== undefined
                                ? currentUserInfo.id
                                : 0
                            }
                            handleClose={() => {
                              setShowShareToModal(false);
                            }}
                            from="Music"
                            uniqeid={props.extra}
                          />
                        </>
                      )}

                    {showReportSongModal === true &&
                      selectedMusicItem !== undefined && (
                        <CommentsModal
                          type="ReportSong"
                          show={showReportSongModal}
                          id={selectedMusicItem.id}
                          title={"Report Song - " + selectedMusicItem.title}
                          currentUserId={
                            currentUserInfo !== undefined
                              ? currentUserInfo.id
                              : 0
                          }
                          handleClose={() => {
                            setShowReportSongModal(false);
                          }}
                          inputText={reportSongInputText}
                          setInputText={(val) => {
                            setReportSongInputText(val);
                          }}
                          isComment={false}
                          setUpdate={() => { 
                            //console.log("DONE"); 
                          }}
                        />
                      )}
                    {showAddPlayListModal === true &&
                      selectedMusicItem !== undefined && (
                        <AddPlayListModal
                          show={showAddPlayListModal}
                          title={"Add To Playlist - " + selectedMusicItem.title}
                          id={selectedMusicItem.id}
                          type={props?.albumType}
                          handleClose={() => {
                            setShowAddPlayListModal(false);
                          }}
                          extra={props.extra}
                        ></AddPlayListModal>
                      )}
                  </div>
                  <div className="col-2 d-md-none">
                    {/* {showAudioPlayer === false && <div>&nbsp;</div>} */}
                    {showAudioPlayer === false && <div>&nbsp;</div>}
                    {showAudioPlayer === true &&
                      playOrPauseMusicindex === index &&
                      itemCount === 0 && (
                        <>
                          <div id="bars">
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
              <div className="song-table d-block d-md-none" >
                <div className="form-row align-items-center align-items-md-center">
                  <div className="col-auto">
                    {playOrPauseMusic === false ? (
                      <BiPlay
                        title="Click to play"
                        className=" play-song"
                        style={{
                          cursor: "pointer",
                          marginLeft: props.showMusicLike ? 0 : 0,
                        }}
                        onClick={() => playMusic(index)}
                      />
                    ) : (
                      <div>
                        {playOrPauseMusicindex === index && itemCount === 0 ? (
                          <div>
                            {playOrPauseShow === true ? (
                              <BiPause
                                title="Click to pause"
                                className=" play-song"
                                style={{
                                  cursor: "pointer",
                                  marginLeft: props.showMusicLike ? 0 : 0,
                                }}
                                onClick={() => {
                                  // console.log("pause Circle");
                                  onAudioPause(index);
                                }}
                              />
                            ) : (
                              <BiPlay
                                title="Click to play"
                                className="play-song"
                                style={{
                                  cursor: "pointer",
                                  marginLeft: props.showMusicLike ? 0 : 0,
                                }}
                                onClick={() => {
                                  // console.log("play Circle");
                                  onAudioPlay(index);
                                }}
                              />
                            )}
                          </div>
                        ) : (
                          <BiPlay
                            title="Click to play"
                            className=" play-song"
                            style={{
                              cursor: "pointer",
                              marginLeft: props.showMusicLike ? 0 : 0,
                            }}
                            onClick={() => playMusic(index)}
                          />
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-6">
                    <div className="form-row">
                      {props.showMusicLike === true && (
                        <div className="col-12 col-md-3">
                          <div className="fs-14 lh-20  fw-500 wrapText " title={item.title}>
                            {String(item.title)}
                          </div>
                        </div>
                      )}
                      {props.showMusicLike === false && (
                        <div className="col-12 col-md-2">
                          <div className="fs-14 lh-20 fw-500 wrapText" title={item.title}>
                            {String(item.title)}
                          </div>
                        </div>
                      )}
                      <div
                        className={`col-10 col-md-3 ${props.showMusicLike === true ? "d-block d-md-none" : ""
                          }`}
                      >
                        <div className="fs-12  fw-400 color-light-dark wrapText" title={item.albumname}>
                          {String(item.albumname)}
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="col-auto">
                    <div className="form-row">
                      <div className="col-auto">
                        <div>
                          {disableItem && mId === item.id ? DisableButtonLoader() :
                            <>
                              {renderLikes(item, index)}
                              <span style={{ fontSize: "15px", paddingLeft: 5 }}>
                                {item.extra == undefined ? item?.likes?.length : item?.extra}
                              </span>
                            </>
                          }
                        </div>
                      </div>
                      <div className="col-auto">
                        <div>
                          <FaRegCommentDots
                            color="#b09b2f"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (VerifyLoginExistOrNot()) {
                                setcommentindex(index)
                                setSelectedMusicItem(item);
                                setShowCommentSongModal(true);
                              } else {
                                history.push({
                                  pathname: `/login`,
                                  state: {
                                    prevPath: path.pathname,
                                    userId: props.currentUserId,
                                  },
                                });
                              }
                            }}
                          />
                          <span style={{ fontSize: "15px", paddingLeft: 10 }}>
                            {/* {item.comments !== undefined ? item.comments.length : 0} */}
                            {item.extra1 == undefined ? item?.comments?.length : item?.extra1}
                          </span>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="dropdown show dropdown-arrow-hide">
                          <DropdownButton
                            className="artistMore"
                            variant="Secondary"
                            title={
                              <FiMoreVertical
                                style={{ cursor: "pointer" }}
                                size="22px"
                                color={colors.color1}
                              ></FiMoreVertical>
                            }
                          >
                            <Dropdown.Item
                              onClick={() => {
                                if (VerifyLoginExistOrNot()) {
                                  setSelectedMusicItem(item);
                                  setShowAddPlayListModal(true);
                                } else {
                                  history.push({
                                    pathname: `/login`,
                                    state: {
                                      prevPath: path.pathname,
                                      userId: props.currentUserId,
                                    },
                                  });
                                }
                              }}
                            >
                              Add To Playlist
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                if (VerifyLoginExistOrNot()) downloadMusic(item);
                                else {
                                  history.push({
                                    pathname: `/login`,
                                    state: {
                                      prevPath: path.pathname,
                                      userId: props.currentUserId,
                                    },
                                  });
                                }
                              }}
                            >
                              Download Song
                            </Dropdown.Item>
                            {props.variant !== "MyAlbum" && (
                              <Dropdown.Item
                                onClick={() => {
                                  setSelectedMusicItem(item);
                                  setShowShareToModal(true);
                                }}
                              >
                                Share
                              </Dropdown.Item>
                            )}
                            {/* <Dropdown.Item>Share</Dropdown.Item> */}

                            <Dropdown.Item
                              onClick={() => {
                                if (VerifyLoginExistOrNot()) {
                                  setSelectedMusicItem(item);
                                  setShowReportSongModal(true);
                                } else {
                                  history.push({
                                    pathname: `/login`,
                                    state: {
                                      prevPath: path.pathname,
                                      userId: props.currentUserId,
                                    },
                                  });
                                }
                              }}
                            >
                              Report Song
                            </Dropdown.Item>
                          </DropdownButton>
                        </div>
                        {showCommentSongModal === true &&
                          selectedMusicItem !== undefined && (
                            <CommentsModal
                              type="CommentSong"
                              show={showCommentSongModal}
                              id={selectedMusicItem.id}
                              title={"Comment - " + selectedMusicItem.title}
                              currentUserId={
                                currentUserInfo !== undefined
                                  ? currentUserInfo.id
                                  : 0
                              }
                              handleClose={() => {
                                setShowCommentSongModal(false);
                              }}
                              inputText={commentSongInputText}
                              setInputText={(val) => {
                                setCommentSongInputText(val);
                              }}
                              isComment={true}
                              setUpdate={() => updatecomment()}
                            />
                          )}

                        {showShareToModal === true &&
                          selectedMusicItem !== undefined && (
                            <>
                              <ShareModal
                                show={showShareToModal}
                                id={selectedMusicItem.id}
                                title={"Share To"}
                                currentUserId={
                                  currentUserInfo !== undefined
                                    ? currentUserInfo.id
                                    : 0
                                }
                                handleClose={() => {
                                  setShowShareToModal(false);
                                }}
                                from="Music"
                                uniqeid={props.extra}
                              />
                            </>
                          )}

                        {showReportSongModal === true &&
                          selectedMusicItem !== undefined && (
                            <CommentsModal
                              type="ReportSong"
                              show={showReportSongModal}
                              id={selectedMusicItem.id}
                              title={"Report Song - " + selectedMusicItem.title}
                              currentUserId={
                                currentUserInfo !== undefined
                                  ? currentUserInfo.id
                                  : 0
                              }
                              handleClose={() => {
                                setShowReportSongModal(false);
                              }}
                              inputText={reportSongInputText}
                              setInputText={(val) => {
                                setReportSongInputText(val);
                              }}
                              isComment={false}
                              setUpdate={() => { 
                                //console.log("DONE"); 
                              }}
                            />
                          )}
                        {showAddPlayListModal === true &&
                          selectedMusicItem !== undefined && (
                            <AddPlayListModal
                              show={showAddPlayListModal}
                              title={"Add To Playlist - " + selectedMusicItem.title}
                              id={selectedMusicItem.id}
                              type={props?.albumType}
                              handleClose={() => {
                                setShowAddPlayListModal(false);
                              }}
                              extra={props.extra}
                            ></AddPlayListModal>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <div className="dis-flex justify-content-around">
                    </div>
                  </div>
                </div>
                {/* <div className="song-bars">
                  {showAudioPlayer === false && <div>&nbsp;</div>}
                  {showAudioPlayer === true &&
                    playOrPauseMusicindex === index
                    &&
                    itemCount === 0 &&
                    (
                      <>
                        <div id="bars">
                          <div
                            className={
                              barHide === true ? "bar animat-pause" : "bar"
                            }
                          ></div>
                          <div
                            className={
                              barHide === true ? "bar animat-pause" : "bar"
                            }
                          ></div>
                          <div
                            className={
                              barHide === true ? "bar animat-pause" : "bar"
                            }
                          ></div>
                          <div
                            className={
                              barHide === true ? "bar animat-pause" : "bar"
                            }
                          ></div>
                          <div
                            className={
                              barHide === true ? "bar animat-pause" : "bar"
                            }
                          ></div>
                          <div
                            className={
                              barHide === true ? "bar animat-pause" : "bar"
                            }
                          ></div>
                          <div
                            className={
                              barHide === true ? "bar animat-pause" : "bar"
                            }
                          ></div>
                          <div
                            className={
                              barHide === true ? "bar animat-pause" : "bar"
                            }
                          ></div>
                          <div
                            className={
                              barHide === true ? "bar animat-pause" : "bar"
                            }
                          ></div>
                          <div
                            className={
                              barHide === true ? "bar animat-pause" : "bar"
                            }
                          ></div>
                        </div>
                      </>
                    )}
                </div> */}
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="no-data-found" style={{ marginTop: 30 }}>
          {constant.noDataFoundLabel}
        </div>
      );
    }
  };
  return (

    <div>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-2 d-none d-lg-block">
          <div className="title-header">Title</div>
        </div>

        <div
          className={`col-3 d-none ${props.showMusicLike === false ? "d-lg-block" : ""
            }`}
        >
          <div className="title-header">Album</div>
        </div>

        <div className="col-1"></div>
        <div className="col-1 d-none d-lg-block">
          <div className="title-header">Time</div>
        </div>
        <div className="col-2"></div>
      </div>
      <div className="songlist-right-box">{renderMusics()}</div>
      {showAudioPlayer === true && props.albumType === "music" && (
        <AudioPlayer
          getAudioInstance={getAudioInstance}
          playOrPauseShow={playOrPauseShow}
          setplayOrPauseShow={setplayOrPauseShow}
          onAudioPlay={onAudioPlay}
          onAudioPause={onAudioPause}
          setplayOrPauseMusicindex={setplayOrPauseMusicindex}
          musicList={musicList}
          playIndex={playIndex}
          setPlayIndex={setPlayIndex}
          setShowAudioPlayer={resetShowAudioPlayer}
          onPlay={onPlay}
          close={false}
        ></AudioPlayer>
      )}
      {selectedSong !== undefined && props.albumType === "video" && props.variant !== "MyAlbum" && (

        <VideoModal
          // getAudioInstance={getAudioInstance}
          show={showAudioPlayer}
          url={selectedSong.musicSrc}
          title={selectedSong.name}
          height={790}
          width={790}
          handleClose={resetShowAudioPlayer}
        ></VideoModal>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    count: state.counter.count,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    increasecounter: (p, q, r, s, t) => dispatch(increaseCounter(p, q, r, s, t)),

    // decreaseCounter: () => dispatch(decreaseCounter()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Musics);
Musics.defaultProps = {
  showMusicLike: false,
};
