import React, { useState, useEffect } from "react";
import TinySlider from "tiny-slider-react";
import { constant } from "../../utility/constant";
import { getDataApi } from "../../utility/apiCall";
import { NavLink, useHistory } from "react-router-dom";

const Categories = ({ onClick }) => {
  let history = useHistory();
  const [dataList, setDataList] = useState([]);
  const settings = {
    controls: false,
    items: 5,
    loop: true,
    nav: false,
    mouseDrag: true,
    margin: 10,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: false,
      },
      600: {
        items: 3,
        nav: false,
      },
      1000: {
        items: 2.5,
        nav: false,
        loop: false,
        margin: 20,
      },
    },
  };
  const getSubcategories = async () => {
    let resSubcategories = await getDataApi(
      constant.baseURL + "subcategories?filter=category.id||$eq||2&filter=isDeleted||$eq||0&filter=status||$eq||1"
    );
    //console.log("resSubcategories: ", resSubcategories);
    if (resSubcategories != undefined && resSubcategories.length > 0) {
      let itms = [
        {
          id: 0,
          img: constant.podcastCategoryURL,
          category: "Latest Podcasts",
        },
      ];
      resSubcategories.map((c) => {
        itms.push({
          id: c.id,
          img: constant.podcastCategoryURL,
          category: c.name,
          url:c.urlImage
        });
      });
      setDataList(itms);
    }
  };
  const renderCategories = () => {
    if (dataList.length > 0) {
      return (
        //<TinySlider settings={settings}>{
        dataList.map((item, index) => {
          return (
            //style={{width: 220, marginRight: 25}}
            <div
              key={index}
              className="col-6 col-sm-4 col-md-3 col-lg-2 mt-4"
              style={{ cursor: "pointer" }}
              onClick={() => onClick(item.category, item.id)}
            >
              <div className="podcast-img">
                <img
                  className="pod-category-img"
                  src={item.url ? constant.podcastcategorythumbnailFileURL + item.url : item.img}
                  alt={item.artist}
                />
                <div className="pod-category-text">{item.category}</div>
              </div>
            </div>
          );
        })
        //}</TinySlider>
      );
    } else {
      return <div className="col-md-12 no-data-found">{constant.noDataFoundLabel}</div>;
    }
  };
  // const renderCategories = () => {
  //     if (dataList.length > 0) {
  //        return( <TinySlider settings={settings}>
  //             {   dataList.map((item, index) => {
  //                     return (
  //                         <div key={index} className="col-6 col-sm-4 col-md-3 col-lg-2 mt-4">
  //                             <div className="podcast-img">
  //                                 <img className="pod-category-img" src={item.img} alt={item.artist}/>
  //                                 <div className="pod-category-text">{item.category}</div>
  //                             </div>
  //                         </div>
  //                     )
  //                 })
  //             }
  //             </TinySlider>);
  //     }else{
  //         return(<div className="col-md-12" style={{fontSize: 13}}>{constant.noDataFoundLabel}</div>)
  //     }
  // }
  useEffect(() => {
    getSubcategories();
  }, []);
  return (
    <section className="podcast-categories">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-auto">
            <div className="fs-20 fw-700">Categories</div>
          </div>
          {/* <div className="col-auto">
            <div className="artist-icon">
              <div className="search-btn">
                <i className="fas fa-search"></i>
              </div>
            </div>
          </div> */}
        </div>
        <div className="row">{renderCategories()}</div>
      </div>
    </section>
  );
};
export default Categories;
