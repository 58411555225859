import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player';
import Accordion from 'react-bootstrap/Accordion'
import { BiChevronDown, BiCaretRight, BiLike, BiDislike, BiShare, BiDotsVerticalRounded, } from "react-icons/bi";
import { FaShareAlt } from "react-icons/fa";
import { VscReport } from "react-icons/vsc";
import songprofile from "../../assets/Images/featuredArtist.png";
import { constant } from '../../utility/constant';
import smily from "../../assets/Images/Lata-Mangeshkar.jpg";
import { hashCodeUrlDecode } from '../../utility/EncodeAndDecodeUrl';
import Comments from "../../components/Comments/Comments";
import Comments1 from "../../components/Comments/Comments1";
import { deleteDataApi, getDataApi, postDataApi } from '../../utility/apiCall';
import mikeFill from "../../assets/Images/mikeFill.svg";
import { VerifyLoginExistOrNot } from "../../utility/VerifyLoginExistOrNot";
import { useHistory, useLocation } from 'react-router-dom';
import blackmike from "../../assets/Images/blackmike.svg";
import blackmikefill from "../../assets/Images/blackmikefill.svg";
import { RiUserFollowLine, RiUserUnfollowLine } from "react-icons/ri";
import mike from "../../assets/Images/mike.svg";
import commentimg from "../../assets/Images/featuredArtist.png";
import videothumb from "../../assets/Images/hqdefault.jpg";
import { Modal } from "react-bootstrap";
import CommentsModal from '../../components/CommentsModal/CommentsModal';
import CommentsModal1 from '../../components/CommentsModal/CommentsModal1';
import queryString from "query-string";
import ShareModal1 from "../../components/ShareModal/ShareModal1";
import { FiMoreVertical } from 'react-icons/fi';
import { Dropdown, DropdownButton, NavDropdown } from "react-bootstrap";
import { addErrorMesage } from '../../components/CustomToster/CustomToaster';
// import  useAccordionButton  from 'react-bootstrap/AccordionButton';
import facebookIcon from "../../assets/Images/facebook.svg";
import twitterIcon from "../../assets/Images/twitterIcon.svg";
import youtube from "../../assets/Images/youtube.svg";
import itune from "../../assets/Images/itune.svg";
import spotify from "../../assets/Images/shopify.svg";
import podcaste from "../../assets/Images/podcaste.svg";



import tiktok from "../../assets/Images/titktok.svg";
import apple from "../../assets/Images/applemusic.svg";
import instagramIcon from "../../assets/Images/instagram.svg";
import { DisableButtonLoader } from '../../utility/CustomLoader';
const VideoPlayer = (props) => {
  //console.log(props)
  const [currentVideo, setcurrentVideo] = useState("")
  const [currentVideoList, setcurrentVideoList] = useState([])
  const [currentItem, setcurrentItem] = useState({})
  const [webview, setwebview] = useState(true)
  const [show, setshow] = useState(false)
  const [count, setcount] = useState(0)
  const [reportshow, setreportshow] = useState(false);
  const [reportshow1, setreportshow1] = useState(false);
  const [commentshow, setcommentshow] = useState(false);
  const [disableItem, setDisableItem] = useState(false);
  const [variant, setvariant] = useState(null);
  const [mId, setMId] = useState(0);
  const [albumId, setalbumId] = useState(null);
  const [varient, setvarient] = useState(false);
  const [commentSongInputText, setCommentSongInputText] = useState("");
  const [showShareToModal, setShowShareToModal] = useState(false);
  const [artistlikes, setartistlikes] = useState(false);
  const [artistfollow, setartistfollow] = useState(false);
  const [artistlikesId, setartistlikesId] = useState(false);
  const [artistfollowId, setartistfollowId] = useState(false);
  var history = useHistory();
  var path = useLocation();
  var idd = JSON.parse(localStorage.getItem("UserInfo"))
  //console.log(idd);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);

  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  var AlbumIdByUser = null;
  const handleClose = () => {
    setshow(false)
  }
  //console.log(artistlikes)
  const getMusicByAlbumId = async (i) => {
    // let resAlbumId = await getDataApi(
    //   constant.baseURL + "albums/" + i
    // );
    // console.log("resAlbumId", resAlbumId);
    // if (resAlbumId !== undefined) {
    //   console.log(resAlbumId)
    //   AlbumIdByUser = resAlbumId.id;

    //   getAlbumsByArtist(resAlbumId?.user?.id, i);
    // }

    let apiURL = constant.baseURL + "musics/getMusicsByAlbumWithUserLike?albumId=" + i +
      "page=" + page + "&limit=" + limit;
    if (currentUserInfo !== undefined) {
      apiURL += '&userId=' + currentUserInfo.id;
    }
    let resAlbumVideos = await getDataApi(apiURL);
    //console.log("----videos by abum----", resAlbumVideos)
    if (resAlbumVideos !== undefined) {
      //AlbumIdByUser = resAlbumId.id;

      let musicAlbumByData = resAlbumVideos.data.filter((item) => {
        return item.album.id == i;
      });

      let itms = [];
      resAlbumVideos.data.map((item) => {
        itms.push({
          id: item.id,
          title: item.title,
          name: item.title,
          time: item.duration,
          size:
            item.size !== undefined && item.size !== null
              ? item.size
              : 0,
          singer:
            item.album.user.firstName +
            " " +
            item.album.user.lastName,
          cover: constant.musicURL,
          albumname: item.album.name,
          musicSrc:
            item.urlImage !== undefined
              ? constant.musicFileURL + item.urlImage
              : "",
          likes: item.likes,
          comments: item.comments,
          user: item.album.user,
          albumId: i,
          userlike: item.userlike,
        });
      });
      //getLikesByArtist(musicAlbumByData[0].user?.id)

      // setartistlikes(musicAlbumByData[0]?.userlike.length > 0 ? true : false);
      // setartistlikesId(musicAlbumByData[0]?.userlike.length > 0
      //   ? musicAlbumByData[0]?.userlike[0].id : 0);

      getFollowByArtist(musicAlbumByData[0].album.user?.id)
      getLikesByArtist(musicAlbumByData[0].album.user?.id)
      setcurrentVideoList([...itms])
      setcurrentVideo(itms[0].musicSrc)
      setcurrentItem(itms[0])
      setcount(count + 1)

    }
  };
  // const getAlbumsByArtist = async (id, i) => {
  //   let resAlbums = await getDataApi(
  //     constant.baseURL + "albums/getByArtist/" + id + "?page=1&limit=100"
  //   );
  //   if (resAlbums !== undefined && resAlbums.data !== undefined) {
  //     console.log("|jayraj", ...resAlbums.data)
  //     if (resAlbums.data.length > 0) {
  //       let musicAlbumByData = resAlbums.data.filter((item) => {
  //         return item.album_id == AlbumIdByUser;
  //       });
  //       let musicsData = [];
  //       let musicIds = "";
  //       if (musicAlbumByData[0].musics !== undefined) {
  //         musicAlbumByData[0].musics.map((item) => {
  //           musicIds +=
  //             item.musics_id !== undefined ? item.musics_id + "," : item.id + ",";
  //           musicsData.push({
  //             id: item.musics_id,
  //             title: item.musics_title,
  //             name: item.musics_title,
  //             time: item.musics_duration,
  //             size:
  //               item.musics_size !== undefined && item.musics_size !== null
  //                 ? item.musics_size
  //                 : 0,
  //             singer:
  //               musicAlbumByData[0].user.firstName +
  //               " " +
  //               musicAlbumByData[0].user.lastName,
  //             cover: constant.musicURL,
  //             albumname: musicAlbumByData[0].album_name,
  //             musicSrc:
  //               item.musics_urlImage !== undefined
  //                 ? constant.musicFileURL + item.musics_urlImage
  //                 : "",
  //             likes: item.likes,
  //             comments: item.comments,
  //             user: musicAlbumByData[0].user,
  //             albumId: i
  //           });
  //         });
  //       }
  //       // setcountData(countData + 1);
  //       getLikesByArtist(musicAlbumByData[0].user?.id)
  //       getFollowByArtist(musicAlbumByData[0].user?.id)
  //       setcurrentVideoList([...musicsData])
  //       setcurrentVideo(musicsData[0].musicSrc)
  //       setcurrentItem(musicsData[0])
  //       setcount(count + 1)
  //     }
  //   }
  // };
  const createLike = async (Id, i) => {
    //console.log("jjjjjj")
    // if(props.data[i].extra){
    //   props.data[i].extra=props.data[i].extra + 1
    // }
    // else{
    //   props.data[i].extra=props.data[i].likes.length + 1
    // }

    setDisableItem(true);
    setMId(Id);
    let jsonData = props?.location?.state?.variant === "musicjs" ? { user: idd?.id, music: Id } : props?.location?.state?.variant === "homebigstagejs" ? { user: idd?.id, bigstage: Id } : props?.location?.state?.variant === "homepodcastjs" ? { user: idd?.id, podcasts: Id } : props?.location?.state?.variant === "podcast1" ? { user: idd?.id, episode: Id } : null;
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let apicom = variant === "musicjs" ? "musiclikes" : variant === "homebigstagejs" ? "bigstagelikes" : variant === "homepodcastjs" ? "episodelikes" : variant === "podcast1" ? "episodelikes" : null

    let respMusicLike = await postDataApi(
      constant.baseURL + apicom,
      formBody,
      authHeader
    );

    //console.log(respMusicLike)
    if (
      respMusicLike != undefined &&
      respMusicLike.status != undefined &&
      respMusicLike.status == 201
    ) {
      // let obj = {}
      // if (variant === "musicjs") {
      //   obj = {
      //     MusicLikes_id: respMusicLike.data.id,
      //     MusicLikes_userId: respMusicLike.data.user.id
      //   }
      //   // currentItem?.likes.splice(currentItem?.likes.findIndex((f) => f.MusicLikes_id !== undefined && f.MusicLikes_id === likeId) , 1)

      // } else if (variant === "homebigstagejs") {
      //   obj = {
      //     bigstagelikes_id: respMusicLike.data.id,
      //     bigstagelikes_userId: respMusicLike.data.user.id
      //   }

      //   // currentItem?.likes.splice(currentItem?.likes.findIndex((f) => f.bigstagelikes_id !== undefined && f.bigstagelikes_id === likeId) , 1)
      //   // currentVideoList[currentVideoList.findIndex((f) => f.id === currentItem.id)].likes.push(obj)
      // } else if (variant === "homepodcastjs") {
      //   obj = {
      //     podcastlikes_id: respMusicLike.data.id,
      //     podcastlikes_userId: respMusicLike.data.user.id
      //   }

      //   // currentItem?.likes.splice(currentItem?.likes.findIndex((f) => f.bigstagelikes_id !== undefined && f.bigstagelikes_id === likeId) , 1)
      //   // currentVideoList[currentVideoList.findIndex((f) => f.id === currentItem.id)].likes.push(obj)
      // } else if (variant === "podcast1") {
      //   obj = {
      //     episodelikes_id: respMusicLike.data.id,
      //     episodelikes_userId: respMusicLike.data.user.id
      //   }

      //   // currentItem?.likes.splice(currentItem?.likes.findIndex((f) => f.bigstagelikes_id !== undefined && f.bigstagelikes_id === likeId) , 1)
      //   // currentVideoList[currentVideoList.findIndex((f) => f.id === currentItem.id)].likes.push(obj)
      // }
      // currentVideoList[currentVideoList.findIndex((f) => f.id === currentItem.id)].likes.push(obj)
      // setcount(count + 1)
      // props.setLikes(musicId, respMusicLike.data);
      setLikes(Id, respMusicLike.data, undefined);
      setDisableItem(false)
    }
    setDisableItem(false);
  };
  //console.log(props?.location?.state?.variant)
  const resetMusicLike = async (musicId, likeId) => {
    // setcurrentItem()
    setDisableItem(true);
    setMId(musicId);
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    //console.log(variant)
    let apicom = variant === "musicjs" ? "musiclikes/" : variant === "homebigstagejs" ? "bigstagelikes/" : variant === "homepodcastjs" ? "episodelikes/" : variant === "podcast1" ? "episodelikes/" : null
    //console.log(apicom)
    let resp = await deleteDataApi(
      constant.baseURL + apicom + likeId,
      authHeader
    );

    if (resp != undefined && resp.status != undefined && resp.status == 200) {
      // if (variant === "musicjs") {
      //   // currentItem?.likes.splice(currentItem?.likes.findIndex((f) => f.MusicLikes_id !== undefined && f.MusicLikes_id === likeId) , 1)
      //   currentVideoList[currentVideoList.findIndex((f) => f.id === currentItem.id)].likes.splice(currentVideoList[currentVideoList.findIndex((f) => f.id === currentItem.id)].likes.findIndex((f) => f.MusicLikes_id !== undefined && f.MusicLikes_id === likeId), 1)
      // } else if (variant === "homebigstagejs") {
      //   // currentItem?.likes.splice(currentItem?.likes.findIndex((f) => f.bigstagelikes_id !== undefined && f.bigstagelikes_id === likeId) , 1)
      //   currentVideoList[currentVideoList.findIndex((f) => f.id === currentItem.id)].likes.splice(currentVideoList[currentVideoList.findIndex((f) => f.id === currentItem.id)].likes.findIndex((f) => f.bigstagelikes_id !== undefined && f.bigstagelikes_id === likeId), 1)
      // } else if (variant === "homepodcastjs") {

      //   currentVideoList[currentVideoList.findIndex((f) => f.id === currentItem.id)].likes.splice(currentVideoList[currentVideoList.findIndex((f) => f.id === currentItem.id)].likes.findIndex((f) => f.podcastlikes_id !== undefined && f.podcastlikes_id === likeId), 1)
      // } else if (variant === "podcast1") {

      //   currentVideoList[currentVideoList.findIndex((f) => f.id === currentItem.id)].likes.splice(currentVideoList[currentVideoList.findIndex((f) => f.id === currentItem.id)].likes.findIndex((f) => f.episodelikes_id !== undefined && f.episodelikes_id === likeId), 1)
      // }


      // // currentVideoList.splice(currentVideoList.findIndex((f) => f.MusicLikes_id !== undefined && f.MusicLikes_id === likeId) , 1)
      // // props.setLikes(musicId);
      // ////console.log(currentItem)
      // ////console.log(currentVideoList)
      // setcount(count + 1)
      setLikes(musicId, undefined, likeId)
      setDisableItem(false);

    }
    setDisableItem(false);
  };
  const setLikes = (id, data, likeId) => {

    let bgItems = [...currentVideoList]
    //console.log(bgItems)
    if (data !== undefined) {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike.push(data);
          itm.likes.push(data);
        }
      });
    } else {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike = [];
          itm.likes.map((lkitm, index0) => {
            if (lkitm.id === likeId) {
              itm.likes.splice(index0, 1);
            }
          });
        }
      });
    }

    setcurrentVideoList(bgItems)
  };
  //console.log(currentItem)
  const renderLikes = (id, likeData) => {
    //console.log("props.musicLikesList: ", currentItem?.likes, id);
    //console.log(currentItem?.likes, currentItem)
    // if (currentItem?.likes?.length > 0) {
    //   //console.log(currentItem.likes)
    //   let flt
    //   if (props?.location?.state?.variant == "musicjs") {
    //     flt = currentItem?.likes.filter(
    //       (f) => f.MusicLikes_userId !== undefined && f.MusicLikes_userId === idd?.id
    //     );
    //   } else if (props?.location?.state?.variant == "homebigstagejs") {
    //     flt = currentItem?.likes.filter(
    //       (f) => f.bigstagelikes_userId !== undefined && f.bigstagelikes_userId === idd?.id
    //     );
    //   } else if (props?.location?.state?.variant == "homepodcastjs") {
    //     flt = currentItem?.likes.filter(
    //       (f) => f.podcastlikes_userId !== undefined && f.podcastlikes_userId === idd?.id
    //     );
    //   } else if (props?.location?.state?.variant == "podcast1") {
    //     flt = currentItem?.likes.filter(
    //       (f) => f.episodelikes_userId !== undefined && f.episodelikes_userId === idd?.id
    //     );
    //   }
    //   let likeid = flt?.length > 0 && props?.location?.state?.variant == "musicjs" ? flt[0].MusicLikes_id : flt?.length > 0 && props?.location?.state?.variant == "homebigstagejs" ? flt[0]?.bigstagelikes_id : flt?.length > 0 && props?.location?.state?.variant == "homepodcastjs" ? flt[0]?.podcastlikes_id : flt?.length > 0 && props?.location?.state?.variant == "podcast1" ? flt[0]?.episodelikes_id : null

    //   //console.log(flt)
    //   if (flt?.length > 0) {
    //     return (
    //       <img
    //         src={mikeFill}
    //         title={constant.unmic}
    //         key={id}
    //         onClick={() => {
    //           if (VerifyLoginExistOrNot()) resetMusicLike(likeid, i);
    //           else {
    //             history.push({
    //               pathname: `/login`,
    //               state: {
    //                 prevPath: path.pathname,
    //                 userId: idd?.id,
    //               },
    //             });
    //           }
    //         }}
    //         style={{ cursor: "pointer", width: 25 }}
    //       />
    //     );
    //   } else {
    //     return (
    //       <img
    //         src={mike}
    //         title={constant.mic}
    //         key={id}
    //         onClick={() => {
    //           if (VerifyLoginExistOrNot()) createLike(id, i);
    //           else {
    //             history.push({
    //               pathname: `/login`,
    //               state: {
    //                 prevPath: path.pathname,
    //                 userId: idd?.id,
    //               },
    //             });
    //           }
    //         }}
    //         style={{ cursor: "pointer", width: 25 }}
    //       />
    //     );
    //   }
    // } else {
    //   return (
    //     <img
    //       src={mike}
    //       title={constant.mic}
    //       key={id}
    //       onClick={() => {
    //         if (VerifyLoginExistOrNot()) createLike(id, i);
    //         else {
    //           history.push({
    //             pathname: `/login`,
    //             state: {
    //               prevPath: path.pathname,
    //               userId: props.ArtistUserId,
    //             },
    //           });
    //         }
    //       }}
    //       style={{ cursor: "pointer", width: 25 }}
    //     />
    //   );
    // }
    if (likeData?.userlike?.length > 0) {
      return (
        <img className="w-24" src={mikeFill} alt="Unmike" style={{ cursor: "pointer" }}
          //onClick={() => resetEpisodeLike(likeData.id, likeData.userlike[0].id)} 
          onClick={() => {
            if (VerifyLoginExistOrNot()) resetMusicLike(likeData.id, likeData.userlike[0].id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    } else {
      return (
        <img className="w-24" src={mike} alt="Mike" style={{ cursor: "pointer" }}
          //onClick={() => createEpisodeLike(likeData.id)} 
          onClick={() => {
            if (VerifyLoginExistOrNot()) createLike(likeData.id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    }

  };
  const formatTime = (second) => {
    var i = 0;
    var h = 0;
    var s = parseInt(second);
    if (s >= 60) {
      i = parseInt(s / 60);
      s = parseInt(s % 60);

      if (i >= 60) {
        h = parseInt(i / 60);
        i = parseInt(i % 60);
      }
    }
    if (h > 0) return [zero(h), zero(i), zero(s)].join(":");
    return [zero(i), zero(s)].join(":");
    //console.log("formatTime- ",[zero(i), zero(s)].join(':'));
  };
  const callbigsatge = async (i, j) => {
    let apiURL = constant.baseURL +
      'bigstages/getAllActiveBigstageWithUserLike?page=' +
      page +
      '&limit=' +
      limit +
      '&sort=id,DESC';
    if (currentUserInfo !== undefined) {
      apiURL += "&userId=" + currentUserInfo.id;
    }
    // let resBigStages = await getDataApi(
    //   constant.baseURL +
    //   "bigstages/getAllActiveBigstage?page=" +
    //   1 +
    //   "&limit=" +
    //   50
    // );
    let resBigStages = await getDataApi(apiURL);
    //console.log(resBigStages)
    let itms = [];
    await resBigStages.data.map((p) => {
      itms.push({
        id: p.id,
        name: p.name,
        musicSrc:
          p.videoURL != undefined &&
            p.videoURL != null &&
            p.videoURL != ""
            ? constant.bigStageVideoURL + p.videoURL : '',
        time: p.duration,
        albumname: p.user.firstName !== "admin" ? p.user.firstName + ' ' + p.user.lastName : '',
        comments: p.comments,
        likes: p.likes,
        thumb: constant.bigStageThumbnailURL + p.thumbnailURL,
        user: p.user,
        userlike: p.userlike
      });
      // itms.push({
      //   id: p.bigstage_id,
      //   name: p.bigstage_name,
      //   musicSrc:
      //     p.bigstage_videoURL != undefined &&
      //       p.bigstage_videoURL != null &&
      //       p.bigstage_videoURL != ""
      //       ? constant.bigStageVideoURL + p.bigstage_videoURL : '',
      //   time: p.bigstage_duration,
      //   albumname: '',
      //   comments: p.comments,
      //   likes: p.likes,
      //   thumb: constant.bigStageThumbnailURL + p.bigstage_thumbnailURL,
      //   user: p.user,

      // });
    });
    //console.log(itms, j)
    // let i = itms.findIndex((f) => f.musicSrc === currentVideo)
    if (i) {
      //console.log(itms, i)
      setcurrentItem(itms[itms.findIndex((f) => f.musicSrc === i)])
      //getLikesByArtist(itms[itms.findIndex((f) => f.musicSrc === i)]?.user?.id)
      // setartistlikes(itms[itms.findIndex((f) => f.musicSrc === i)]?.userlike.length > 0 ? true : false);
      // setartistlikesId(itms[itms.findIndex((f) => f.musicSrc === i)]?.userlike.length > 0
      //   ? itms[itms.findIndex((f) => f.musicSrc === i)]?.userlike[0].id : 0);
      getFollowByArtist(itms[itms.findIndex((f) => f.musicSrc === i)]?.user?.id)
      getLikesByArtist(itms[itms.findIndex((f) => f.musicSrc === i)]?.user?.id)
      // getFollowByArtist(itms[itms.findIndex((f) => f.id === parseInt(j))]?.user?.id)
    } else {
      setcurrentItem(itms[itms.findIndex((f) => f.id === parseInt(j))])
      setcurrentVideo(itms[itms.findIndex((f) => f.id === parseInt(j))]?.musicSrc)
      // //getLikesByArtist(itms[itms.findIndex((f) => f.id === parseInt(j))]?.user?.id)
      // setartistlikes(itms[itms.findIndex((f) => f.musicSrc === j)]?.userlike.length > 0 ? true : false);
      // setartistlikesId(itms[itms.findIndex((f) => f.musicSrc === i)]?.userlike.length > 0 
      // ? itms[itms.findIndex((f) => f.musicSrc === i)]?.userlike[0].id : 0);

      getFollowByArtist(itms[itms.findIndex((f) => f.id === parseInt(j))]?.user?.id)
      getLikesByArtist(itms[itms.findIndex((f) => f.id === parseInt(j))]?.user?.id)

    }

    setcurrentVideoList([...itms])

    setcount(count + 1)
    // setcurrentItem(itms[0])
    // setcurrentItem(itms[0])
  }
  const callpodcast = async (i) => {
    //console.log(i)
    // let resPodcasts = await getDataApi(
    //   constant.baseURL +
    //   "podcasts/getByType/video?page=" +
    //   1 +
    //   "&limit=" +
    //   50 +
    //   "&sort=id,DESC"
    // );

    // let apiURL = constant.baseURL + '/podcasts/getByTypeWithUserLike/video' + '?page=' + page + '&limit=' + limit
    // if (currentUserInfo !== undefined) {
    //   apiURL += "&userId=" + currentUserInfo.id;
    // }
    // let resPodcasts = await getDataApi(apiURL);

    let apiURL = constant.baseURL + "episode/getEpisodesByPodcastsWithUserLike?page=" + page + "&limit=" + limit + "&podcastsId=" + i;
    if (currentUserInfo !== undefined) {
      apiURL += "&userId=" + currentUserInfo.id
    }
    let resEpisodes = await getDataApi(apiURL);

    //console.log(resEpisodes)
    let itms = [];
    resEpisodes.data.map((p) => {
      p.musicSrc = p.urlImage !== undefined
        ? constant.podcastepisodeFileURL + p.urlImage
        : ""
      p.time = p.duration
      p.name = p.title
      p.singer =
        p.artist
      p.user =
        p.podcasts.artist
      p.cover = constant.podcastepisodeFileURL + p.thumbnailUrlImage
      // itms.push({
      //   id: p.podcasts_id,
      //   name: p.podcasts_name,
      //   musicSrc:
      //     p.podcasts_urlPodcast != undefined &&
      //       p.podcasts_urlPodcast != null &&
      //       p.podcasts_urlPodcast != ""
      //       ? constant.podcastFileURL + p.podcasts_urlPodcast : '',
      //   time: p.podcasts_duration,
      //   albumname: '',
      //   comments: p.comments,
      //   likes: p.likes,
      //   user: p.user,
      //   thumb: constant.podcastFileURL + p.podcasts_urlImage
      // });
    });
    //console.log(resEpisodes.data)
    setcurrentVideo(resEpisodes.data[0].musicSrc)
    setcurrentVideoList(resEpisodes.data)
    setcurrentItem(resEpisodes.data[0])
    setcount(count + 1)
    getLikesByArtist(resEpisodes.data[0].user?.id);
    getFollowByArtist(resEpisodes.data[0].user?.id);
    // setvarient("podcast1") 
    //console.log(resPodcasts, itms)
    // let x = itms.findIndex((f) => f.musicSrc === currentVideo)
    // if (i) {
    //   //setcurrentItem(itms[itms.findIndex((f) => f.musicSrc === i)])
    //   //getLikesByArtist(itms[itms.findIndex((f) => f.musicSrc === i)]?.user?.id)
    //   setcurrentItem(itms[0]);
    //   setartistlikes(itms[0]?.userlike.length > 0 ? true : false);
    //   setartistlikesId(itms[0]?.userlike.length > 0
    //     ? itms[0]?.userlike[0].id : 0);
    //   getFollowByArtist(itms[0]?.user?.id)
    //   setcurrentVideo(itms[0].musicSrc);
    // } else {
    //   setcurrentItem(itms[itms.findIndex((f) => f.id === parseInt(j))])
    //   setcurrentVideo(itms[itms.findIndex((f) => f.id === parseInt(j))]?.musicSrc)
    //   getLikesByArtist(itms[itms.findIndex((f) => f.id === parseInt(j))]?.user?.id)
    //   getFollowByArtist(itms[itms.findIndex((f) => f.id === parseInt(j))]?.user?.id)
    // }
    // setcurrentItem(itms[x])
    // setcurrentVideoList([...itms])
    // console.log(itms.findIndex((f) => f.musicSrc === currentVideo))


    setcount(count + 1)
  }
  const zero = (v) => {
    return v >> 0 < 10 ? "0".concat(v) : v;
  };
  const updatecomment = () => {
    setcount(count + 1)
    // setcurrentItem(currentItem)
    // console.log(commentindex)
    // if(props.data[commentindex].extra1){
    //   props.data[commentindex].extra1=props.data[commentindex].extra1 + 1
    // }else{
    //   props.data[commentindex].extra1=props.data[commentindex].comments.length+1
    // }

  }
  const callnewsong = (song) => {
    //console.log(song)
    setcurrentVideo(song.musicSrc)
    setcurrentItem(song)
    getLikesByArtist(song?.user?.id)
    getFollowByArtist(song?.user?.id)
    setcount(count + 1)

  }

  //console.log(window.screen.width)
  const navigateToExternalLink = (url) => {
    //console.log("url: ", url);
    if (url.indexOf("http://") !== -1 || url.indexOf("https://") !== -1) {
      window.open(url, "_blank");
      return null;
    } else {
      addErrorMesage("URL should start with http or https");
      return;
    }
  };
  const createFollow = async () => {
    if (VerifyLoginExistOrNot()) {
      if (idd !== undefined) {
        let jsonData = { user: idd.id, follower: currentItem?.user?.id };
        var formBody = [];
        for (var property in jsonData) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(jsonData[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        var authHeader = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        };
        //console.log(jsonData);
        let respFollow = await postDataApi(
          constant.baseURL + "follows",
          formBody,
          authHeader
        );
        //console.log("respFollow: ", respFollow);
        if (
          respFollow !== undefined &&
          respFollow.status !== undefined &&
          respFollow.status == 201
        ) {
          setartistfollowId(respFollow.data.id)
          setartistfollow(true)
        } else {
          setartistfollow(false)
        }
      }
    } else {
      history.push({
        pathname: `/login`,

      });
    }
  };
  // satyam End
  const resetFollow = async () => {
    if (currentItem?.user !== undefined) {
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resp = await deleteDataApi(
        constant.baseURL + "follows/" + artistfollowId,
        authHeader
      );
      if (
        resp !== undefined &&
        resp.status !== undefined &&
        resp.status === 200
      ) {
        setartistfollow(false)
      }
    }
  };
  const createArtistLike = async () => {
    if (VerifyLoginExistOrNot()) {
      if (idd !== undefined) {
        let jsonData = { user: idd?.id, artist: currentItem?.user?.id };
        var formBody = [];
        for (var property in jsonData) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(jsonData[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        var authHeader = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        };
        let respArtistLike = await postDataApi(
          constant.baseURL + "likes",
          formBody,
          authHeader
        );
        //console.log(respArtistLike)
        if (
          respArtistLike !== undefined &&
          respArtistLike.status !== undefined &&
          respArtistLike.status === 201
        ) {
          setartistlikesId(respArtistLike.data.id)
          setartistlikes(true)
        }
      }
    } else {
      history.push({
        pathname: `/login`,
        // state: {
        //   prevPath: path.pathname,
        //   userId: currentItem?.user.id,
        // },
      });
    }
  };
  const resetArtistLike = async () => {
    if (VerifyLoginExistOrNot()) {
      if (currentItem?.user !== undefined) {
        // setbuttonDisable(true);
        var authHeader = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        };
        let resp = await deleteDataApi(
          constant.baseURL + "likes/" + artistlikesId,
          authHeader
        );
        if (
          resp !== undefined &&
          resp.status !== undefined &&
          resp.status === 200
        ) {
          setartistlikes(false)
        }
      }
    } else {
    }
  };
  const getFollowByArtist = async (id) => {
    if (idd !== undefined) {
      let resArtistFollow = await getDataApi(
        constant.baseURL +
        "follows?filter=user.id||$eq||" +
        idd?.id +
        "&filter=follower.id||$eq||" +
        id
      );
      //console.log("resArtistFollow: ", resArtistFollow);
      if (resArtistFollow !== undefined && resArtistFollow.length > 0) {
        setartistfollow(true)
        setartistfollowId(resArtistFollow[0].id)
      } else {
        setartistfollow(false)
      }
    }
  };
  const getLikesByArtist = async (id) => {
    //console.log("getLikesByArtist - idd: ", idd);
    if (idd !== undefined) {
      let resArtistLikes = await getDataApi(
        constant.baseURL +
        "likes?filter=user.id||$eq||" +
        idd?.id +
        "&filter=artist.id||$eq||" +
        id
      );
      //console.log("getLikesByArtist - resArtistLikes: ", resArtistLikes);
      if (resArtistLikes !== undefined && resArtistLikes.length > 0) {
        setartistlikes(true)
        setartistlikesId(resArtistLikes[0].id)
      } else {
        setartistlikes(false)
      }
    }
  };
  const onProgress = (i) => {
    //console.log(i)
    if (i.played == 1) {
      //console.log(currentItem)
      const index = currentVideoList.findIndex(object => {
        return object.id === currentItem.id;
      });
      //console.log(index)
      if (index !== (currentVideoList.length - 1)) {
        callnewsong(currentVideoList[index + 1])
      }
    }
  }
  useEffect(() => {
    const idValue = queryString.parse(window.location.search)
    //console.log("idValue---", idValue)
    //console.log(props?.location?.state, window.screen.width)
    if (window.screen.width > 767) {
      setwebview(true)
    } else {
      setwebview(false)
    }
    // console.log(searchData.itemId)
    setvariant(props?.location?.state?.variant)
    if (props?.location?.state?.variant == "musicjs" || idValue?.variant == "musicjs") {
      if (props?.location?.state?.variant1 == "musicjs" || idValue?.variant == "musicjs") {
        if (idValue?.id) {
          getMusicByAlbumId(idValue?.id)
        } else {
          getMusicByAlbumId(props?.location?.state?.id)
        }

      } else {
        setcurrentVideo(props?.location?.state?.url)
        setcurrentVideoList(props.location.state?.list)
        setcurrentItem(props?.location?.state?.details)
        setcount(count + 1)
        getLikesByArtist(props?.location?.state?.details?.user?.id);
        getFollowByArtist(props?.location?.state?.details?.user?.id);
      }
      setvarient("musicjs")
    } else if (props?.location?.state?.variant == "homebigstagejs" || idValue?.variant == "homebigstagejs") {
      setcurrentVideo(props?.location?.state?.url)
      callbigsatge(props?.location?.state?.url, idValue?.id)
      setvarient("homebigstagejs")
    } else if (props?.location?.state?.variant == "homepodcastjs" || idValue?.variant == "homepodcastjs") {
      setcurrentVideo(props?.location?.state?.url)
      //callpodcast(props?.location?.state?.url, idValue?.id)
      callpodcast(props?.location?.state?.id, idValue?.id);
      setvarient("homepodcastjs")
    } else if (props?.location?.state?.variant == "podcast1" || idValue?.variant == "podcast1") {
      if (idValue?.id) {
        callpodcast(idValue?.id)
      } else {
        setcurrentVideo(props?.location?.state?.url)
        setcurrentVideoList(props.location.state?.list)
        setcurrentItem(props?.location?.state?.details)
        setcount(count + 1)
        getLikesByArtist(props?.location?.state?.details?.user?.id);
        getFollowByArtist(props?.location?.state?.details?.user?.id);
        setvarient("podcast1")
      }

    }

  }, [])
  //console.log(currentItem)
  // const decoratedOnClick = useAccordionButton(eventKey, onClick);
  return (
    <>
      {webview ? <div className='web-view d-none d-md-block'>
        <section className=" custom-songlist custom-videoplayer-list">
          <div className="container">
            <div className="form-row">
              <div className="col-12 col-lg-8">
                <div className=''>
                  {/* {renderplayer()} */}
                  <ReactPlayer
                    className="reactPlayer d-none d-md-block"
                    width='100%'
                    height='415px'
                    max-heigh="415px"
                    object-fit="cover"
                    controls={true}
                    playing={true}
                    onProgress={onProgress}
                    // getAudioInstance={getAudioInstance}
                    //onEnablePIP={onEnablePIP}
                    //pip={false}
                    // playsinline={true}
                    url={currentVideo}
                    config={{ file: { attributes: { disablepictureinpicture: 'true' } } }}
                  >
                  </ReactPlayer>
                </div>
              </div>
              <div className="col-12 col-lg-4 ">
                <div className='main-player'>
                  <div className='form-row'>
                    <div className='col-12'>
                      <div className='player-header'>
                        <div className='form-row justify-content-between align-items-center'>
                          <div className='col-auto'>
                            <p className='fs-13  text-gray-100 mb-0 wrapText max-text'>{props?.location?.state?.variant == "homebigstagejs" ? "Big Stage" : props?.location?.state?.variant == "homepodcastjs" ? currentVideoList[0]?.albumname : props?.location?.state?.variant == "musicjs" ? currentVideoList?.length > 0 && currentVideoList[0]?.albumname : props?.location?.state?.variant == "podcast1" ? currentVideoList?.length > 0 && currentVideoList[0]?.albumname : "My Music"}</p>
                            {/* <p className='fs-12 m-0 text-white-200'>Youtube</p> */}
                          </div>
                          <div className='col-auto'>
                            <span className='fs-30'><BiChevronDown /></span>
                          </div>
                        </div>
                      </div>
                      <div className='main-list'>
                        {currentVideoList?.length > 0 && currentVideoList.map((song, index) => {
                          return (
                            <div className='player-list' onClick={() => callnewsong(song)}>
                              <div className='form-row align-items-center'>
                                <div className='col-1'>
                                  <div className='running-song '><span >{song.musicSrc == currentVideo ? <BiCaretRight /> : null}</span></div>
                                </div>
                                <div className='col-3'>
                                  {props?.location?.state?.variant == "homebigstagejs" || props?.location?.state?.variant == "homepodcastjs" ? <img
                                    className="player-list-img"
                                    src={song.thumb}
                                  /> :
                                    <video
                                      className="player-list-img"
                                      src={song.musicSrc + "#t=10"}
                                    />}
                                  <div className='player-list-time '> {song.time !== undefined &&
                                    song.time !== null &&
                                    song.time !== NaN
                                    ? formatTime(song.time)
                                    : "0:00"}</div>
                                </div>
                                <div className='col-8'>
                                  <p className='fs-13 mb-1 text-gray-100 mmm-playlist wrapText'>{song.name}</p>
                                  <p className='fs-12 m-0 text-white-200 wrapText'>
                                    {song.albumname}</p>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row pt-4">
              <div className="col-12 col-md-8">
                <div className='video-details'>
                  <div className='row mt-3 mt-md-0'>
                    <div className='col-12 col-md-auto'>
                      <div className='video-title fs-16 wrapText'>{currentItem?.name}
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className='row mt-3 aling-items-center justify-content-between d-small-none '>
                    <div className='col-12 col-md-auto'>
                      {/* <div className='video-total-view fs-13'>414,630 views <span className='ml-3'>Dec 6, 2020</span>
                      </div> */}
                    </div>
                    <div className='col-12 col-md-auto'>

                      <div className='fs-13'>
                        <div className='form-row align-items-center'>
                          <div className='col-auto'>
                            <div>
                              {disableItem && mId === currentItem.id ? DisableButtonLoader() :
                                <>
                                  <span>{renderLikes(currentItem?.id, currentItem)}</span>
                                  <span style={{ fontSize: "15px", paddingLeft: 5 }}>{currentItem?.likes?.length}</span>
                                </>
                              }
                              {/* </div> */}
                            </div>
                          </div>
                          <div className='col-auto'>
                            <span className='fs-20 line-height-0 ml-4 mr-2 icon-yellow' title="Share" style={{ cursor: 'pointer' }}><FaShareAlt onClick={() => setShowShareToModal(true)} /></span>
                          </div>
                          <div className='col-auto'>
                            <span className='fs-20 line-height-0 ml-4 mr-2 icon-yellow' title="Report" style={{ cursor: 'pointer' }}><VscReport onClick={() => setreportshow(true)} /></span>
                          </div>

                        </div>
                        {/* <div className="dis-flex justify-content-around"> */}

                        {/* <span className='fs-20 line-height-0 ml-4 mr-2'><BiDislike /></span>2k */}


                      </div>
                    </div>
                  </div>
                  {/* <div className='row mt-3 mt-md-0'> */}
                  {
                    currentItem?.user !== undefined && currentItem?.user?.firstName !== undefined
                    && currentItem?.user?.firstName !== null && currentItem?.user?.firstName !== ""
                    && currentItem?.user?.firstName !== "admin" &&

                    <div>
                      <div className='row align-items-center'>
                        <div className='col-auto'>
                          <img
                            className="img-fluid br-100 w-sm-50 "
                            src={currentItem?.user?.urlImage ? constant.profileURL + currentItem?.user?.urlImage : commentimg}
                          />
                        </div>
                        <div className='col-auto'>
                          <div
                            className=" wrapText fs-40 fw-700"
                            title={
                              currentItem?.user !== undefined
                                ? String(currentItem?.user?.firstName + " " + currentItem?.user?.lastName)
                                : ""
                            }
                          >
                            {currentItem?.user !== undefined
                              ? String(currentItem?.user?.firstName + " " + currentItem?.user?.lastName)
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-12">
                          <p
                            className="fs-15 lh-20 fw-500 d-none d-md-block"
                          >
                            {currentItem?.user?.biography !== null && currentItem?.user?.biography !== "null" && currentItem?.user?.biography !== "undefined"
                              ? String(currentItem?.user?.biography) : ""}
                          </p>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-12 col-lg-8">
                          <div className='form-row mb-4'>
                            <div
                              className="col-auto "
                              title={artistlikes === true ? constant.unmic : constant.mic}
                            >
                              {artistlikes === true ? (
                                <button
                                  className="btn btn-primary round-btn"
                                  onClick={() => {
                                    resetArtistLike();
                                  }}
                                >

                                  <img src={blackmikefill} title={constant.unmic} style={{ width: 25 }} />
                                  <span className="play-btn d-mobile-none">{constant.unmic}</span>
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary round-btn"
                                  onClick={() => createArtistLike()}
                                >
                                  {/* <AiOutlineHeart size={22} /> */}
                                  <img src={blackmike} title={constant.mic} style={{ width: 23 }} />
                                  <span className="play-btn d-mobile-none">{constant.mic}</span>
                                </button>
                              )}
                            </div>
                            {/* <div
                className="col-auto"
                title={artistfollow === true ? "UnFollow" : "Follow"}
              >
                <button
                  className="btn btn-primary round-btn"
                  disabled={buttonfollowDisable}
                  onClick={() =>
                    artistfollow === true ? resetFollow() : createFollow()
                  }
                >
                  {artistfollow === true ? (
                    <RiUserUnfollowLine size={22} />
                  ) : (
                    <RiUserFollowLine size={22} />
                  )}
                  <span className="play-btn d-mobile-none">
                    {artistfollow === true ? "UnFollow" : "Follow"}
                  </span>
                </button>
              </div> */}
                            <div
                              className="col-auto"
                              title={artistfollow === true ? "UnFollow" : "Follow"}
                            >
                              <button
                                className="btn btn-primary round-btn"
                                onClick={() =>
                                  artistfollow === true ? resetFollow() : createFollow()
                                }
                              >
                                {artistfollow === true ? (
                                  <RiUserUnfollowLine size={22} />
                                ) : (
                                  <RiUserFollowLine size={22} />
                                )}
                                <span className="play-btn d-mobile-none">
                                  {artistfollow === true ? "UnFollow" : "Follow"}
                                </span>
                              </button>
                            </div>
                            {/* <div className='col-auto'>
                            <button className="btn btn-like mt-10">
                              <FiMoreVertical size={20} />
                            </button>
                          </div> */}
                          </div>
                          <div className="row">
                            <div className="col-auto">
                              <div className="biography-title fs-20 fw-600">Connect with Artist</div>
                            </div>
                          </div>
                          {/* <div className="form-row mt-4">
                          <div className='col-auto'>
                            <img
                              style={{ height: 40, width: 40 }}
                              src={apple}
                              alt="Location"
                            ></img>
                          </div>
                          <div className='col-auto'>
                            <img
                              style={{ height: 40, width: 40 }}
                              src={facebookIcon}
                              alt="Location"
                            ></img>
                          </div>
                          <div className='col-auto'>
                            <img
                              style={{ height: 40, width: 40 }}
                              src={instagramIcon}
                              alt="Location"
                            ></img>
                          </div>

                          <div className='col-auto'>
                            <img
                              style={{ height: 40, width: 40 }}
                              src={itune}
                              alt="Location"
                            ></img>
                          </div>

                          <div className='col-auto'>
                            <img
                              style={{ height: 40, width: 40 }}
                              src={spotify}
                              alt="Location"
                            ></img>
                          </div>
                          <div className='col-auto'>
                            <img
                              style={{ height: 40, width: 40 }}
                              src={tiktok}
                              alt="Location"
                            ></img>
                          </div>
                          <div className='col-auto'>
                            <img
                              style={{ height: 40, width: 40 }}
                              src={youtube}
                              alt="Location"
                            ></img>
                          </div>
                          <div className='col-auto'>
                            <img
                              style={{ height: 40, width: 40 }}
                              src={podcaste}
                              alt="Location"
                            ></img>
                          </div>
                        </div> */}
                          {/* <div className="col-12 col-lg-6"> */}
                          {/* <div className="biography-title" style={{ marginTop: 12 }}>
                BIOGRAPHY
              </div> */}
                          {/* <div className="biogrphy-detail">
                {currentItem?.user? !== undefined ? currentItem?.user?.biography : ""}
              </div> */}
                          {/* <div className="row">
                <div className="col-auto">
                  <div className="biography-title fs-20 fw-600">Connect with Artist</div>
                </div>
              </div> */}
                          <div className="form-row mt-4">
                            {currentItem?.user?.appleMusicURL && (<div className="col-auto">
                              <div
                                title="Apple Music"
                                className="cursor"
                                onClick={() => {
                                  navigateToExternalLink(currentItem?.user?.appleMusicURL);
                                }}
                              >
                                <img
                                  style={{ height: 40, width: 40 }}
                                  src={apple}
                                  alt="Location"
                                ></img>
                              </div>
                            </div>
                            )}
                            {currentItem?.user?.applePodcastURL && (<div className="col-auto">
                              <div
                                title="Apple Podcast"
                                className="cursor"
                                onClick={() => {
                                  navigateToExternalLink(currentItem?.user?.applePodcastURL);
                                }}
                              >
                                <img
                                  style={{ height: 40, width: 40 }}
                                  src={podcaste}
                                  alt="Location"
                                ></img>
                              </div>
                            </div>
                            )}
                            {currentItem?.user?.faceookURL && (<div className="col-auto">
                              <div
                                title="Facebook"
                                className="cursor"
                                onClick={() => {
                                  navigateToExternalLink(currentItem?.user?.faceookURL);
                                }}
                              >
                                <img
                                  style={{ height: 40, width: 40 }}
                                  src={facebookIcon}
                                  alt="Location"
                                ></img>
                              </div>
                            </div>
                            )}
                            {currentItem?.user?.instagramURL && (<div className="col-auto">
                              <div
                                title="Instagram"
                                className="cursor"
                                onClick={() => {
                                  navigateToExternalLink(currentItem?.user?.instagramURL);
                                }}
                              >
                                <img
                                  style={{ height: 40, width: 40 }}
                                  src={instagramIcon}
                                  alt="Location"
                                ></img>
                              </div>
                            </div>)}
                            {currentItem?.user?.iTunesURL && (<div className="col-auto">
                              <div
                                title="iTune"
                                className="cursor"
                                onClick={() => {
                                  navigateToExternalLink(currentItem?.user?.iTunesURL);
                                }}
                              >
                                <img
                                  style={{ height: 40, width: 40 }}
                                  src={itune}
                                  alt="Location"
                                ></img>
                              </div>
                            </div>)}

                            {currentItem?.user?.spotifyURL && (<div className="col-auto">
                              <div
                                title="Spotify"
                                className="cursor"
                                onClick={() => {
                                  navigateToExternalLink(currentItem?.user?.spotifyURL);
                                }}
                              >
                                <img
                                  style={{ height: 40, width: 40 }}
                                  src={spotify}
                                  alt="Location"
                                ></img>
                              </div>
                            </div>)}
                            {currentItem?.user?.tikTokURL && (<div className="col-auto">
                              <div
                                title="Tiktok"
                                className="cursor"
                                onClick={() => {
                                  navigateToExternalLink(currentItem?.user?.tikTokURL);
                                }}
                              >
                                <img
                                  style={{ height: 40, width: 40 }}
                                  src={tiktok}
                                  alt="Location"
                                ></img>
                              </div>
                            </div>)}
                            {currentItem?.user?.youtubeURL && (<div className="col-auto">
                              <div
                                title="Youtube"
                                className="cursor"
                                onClick={() => {
                                  navigateToExternalLink(currentItem?.user?.youtubeURL);
                                }}
                              >
                                <img
                                  style={{ height: 40, width: 40 }}
                                  src={youtube}
                                  alt="Location"
                                ></img>

                              </div>
                            </div>)}
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  }
                  <div className='row mt-3 aling-items-center justify-content-between d-block d-md-none'>
                    <div className='col-12 col-md-auto'>
                      <div className='video-total-view fs-13'>414,630 views <span className='ml-3'>Dec 6, 2020</span>
                      </div>
                    </div>
                    <div className='col-12 col-md-auto'>
                      <div className='row mt-3 justify-content-between  ' >
                        <div className='col-auto'>
                          <div className='row flex-column '>
                            <div className='col fs-13 text-center'><span className='fs-20 icon-yellow '><BiLike /></span></div>
                            <div className='col fs-13'> 127k </div>
                          </div>
                        </div>
                        <div className='col-auto'>
                          <div className='row flex-column'>
                            <div className='col fs-13 text-center'><span className='fs-20  icon-yellow'><BiDislike /></span></div>
                            <div className='col fs-13'> Dislike </div>
                          </div>
                        </div>
                        <div className='col-auto'>
                          <div className='row flex-column'>
                            <div className='col fs-13 text-center'><span className='fs-20 icon-yellow  '><FaShareAlt /></span></div>
                            <div className='col fs-13'> Share </div>
                          </div>
                        </div>
                        <div className='col-auto'>
                          <div className='row flex-column'>
                            <div className='col fs-13 fs-20 text-center'><span className='fs-20  icon-yellow'><VscReport /></span>   </div>
                            <div className='col fs-13'>Report</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='footer-line mt-4'></div>
              </div>
            </div>
            <div className='form-row'></div>
          </div>
        </section>
        <section className="comment-main pt-4 mb-5" style={{ backgroundColor: '#121216' }}>
          <div className="container">
            <div className="row justify-content-between">
              <div className='col-8'>
                <div className='row justify content-between'>
                  <div className="col-auto">
                    <div className="fs-16">{currentItem?.comments?.length > 0 ? currentItem?.comments?.length + " Comments" : "Comments"}</div>
                  </div>

                </div>
              </div>

            </div>
            {currentItem?.id ? <CommentsModal1
              type={props?.location?.state?.variant == "homebigstagejs" ? "CommentBigStage" : props?.location?.state?.variant == "homepodcastjs" ? "CommentEpisode" : props?.location?.state?.variant == "podcast1" ? "CommentEpisode" : "CommentSong"}
              isModel="videoplayer"
              show={false}
              id={currentItem?.id}
              title={"Comment - " + currentItem.name}
              currentUserId={
                idd !== undefined
                  ? idd?.id
                  : 0
              }
              handleClose={() => {
                // setShowCommentSongModal(false);
              }}
              inputText={commentSongInputText}
              setInputText={(val) => {
                setCommentSongInputText(val);
              }}
              isComment={true}
            // setUpdate={() => updatecomment()}
            /> : null}
          </div>

        </section>
      </div> :
        <section className='mobile-video d-block d-md-none '>
          <div className=' '>
            {/* {renderplayer()} */}
            <ReactPlayer
              className="reactPlayer"
              width='100%'
              height='100%'
              controls={true}
              playing={true}
              // getAudioInstance={getAudioInstance}
              //onEnablePIP={onEnablePIP}
              //pip={false}
              // playsinline={true}
              url={currentVideo}
              config={{ file: { attributes: { disablepictureinpicture: 'true' } } }}
            >
            </ReactPlayer>
          </div>
          <div className='container'>
            <div className='mobile-view-scroll'>
              <div className="form-row">
                <div className="col-12 col-md-8">
                  <div className='video-details'>
                    <div className='row mt-3 mt-md-0'>
                      <div className='col-12 col-md-auto'>
                        <div className='video-title fs-16 wrapText'>{currentItem?.name}
                        </div>
                      </div>
                    </div>
                    <div className='row mt-3 aling-items-center justify-content-between d-small-none '>
                      <div className='col-12 col-md-auto'>
                        {/* <div className='video-total-view fs-13'>414,630 views <span className='ml-3'>Dec 6, 2020</span>
                      </div> */}
                      </div>
                      {/* <div className='col-12 col-md-auto'>
                      <div className='fs-13'><span className='fs-20 line-height-0 mr-2 ml-4'>{renderLikes(currentItem?.id,currentItem)}</span>{currentItem?.likes?.length}
                        <span className='fs-20 line-height-0 ml-4 mr-2'><BiDislike /></span>2k
                        <span className='fs-20 line-height-0 ml-4 mr-2'><BiShare /></span>2k
                        <span className='fs-20 line-height-0 ml-4 mr-2'><VscReport onClick={()=>setreportshow(true)} /></span>
                      </div>
                    </div> */}
                    </div>
                    <div className='row mt-3 aling-items-center justify-content-between d-block d-md-none'>
                      <div className='col-12 col-md-auto'>
                        {/* <div className='video-total-view fs-13'>414,630 views <span className='ml-3'>Dec 6, 2020</span>
                      </div> */}
                      </div>
                      <div className='col-12 col-md-auto'>
                        <div className='row mt-3 justify-content-between  align-items-center ' >
                          <div className='col-auto'>
                            <div className='dis-flex flex-column justify-content-around align-items-center'>
                              {disableItem && mId === currentItem.id ? DisableButtonLoader() :
                                <>
                                  <div className='col pr-3 fs-13 text-center'><span className='fs-20 '>{renderLikes(currentItem?.id, currentItem)}</span></div>
                                  <div className='col  fs-13'>{currentItem?.likes?.length}</div>
                                </>
                              }
                            </div>
                          </div>
                          {/* <div className='col-auto'>
                          <div className='row flex-column'>
                            <div className='col fs-13 text-center'><span className='fs-20  '><BiDislike /></span></div>
                            <div className='col fs-13'> Dislike </div>
                          </div>
                        </div> */}
                          <div className='col-auto'>
                            <div className='row flex-column'>
                              <div className='col fs-13 text-center'><span className='fs-20  icon-yellow'><FaShareAlt onClick={() => setShowShareToModal(true)} /></span></div>
                              <div className='col fs-13'> Share </div>
                            </div>
                          </div>
                          <div className='col-auto'>
                            <div className='row flex-column'>
                              <div className='col fs-13 fs-20 text-center'><span className='fs-20 icon-yellow '><VscReport onClick={() => setreportshow1(true)} /></span>   </div>
                              <div className='col fs-13'>Report</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='footer-line mt-4'></div>
                </div>

                {
                  currentItem?.user !== undefined && currentItem?.user?.firstName !== undefined
                  && currentItem?.user?.firstName !== null && currentItem?.user?.firstName !== ""
                  && currentItem?.user?.firstName !== "admin" &&
                  <div className='col-12'>
                    <div className='row align-items-center mt-3'>
                      <div className='col-auto'>
                        <img
                          className="img-fluid br-100 w-sm-50 "
                          src={currentItem?.user?.urlImage ? constant.profileURL + currentItem?.user?.urlImage : commentimg}
                        />
                      </div>
                      <div className='col-auto'>
                        <div
                          className=" wrapText fs-40 fw-700"
                          title={
                            currentItem?.user !== undefined
                              ? String(currentItem?.user?.firstName + " " + currentItem?.user?.lastName)
                              : ""
                          }
                        >
                          {currentItem?.user !== undefined
                            ? String(currentItem?.user?.firstName + " " + currentItem?.user?.lastName)
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className="col-12 col-md-12">
                        <p
                          className="fs-15 lh-20 fw-500 "
                        >
                          {currentItem?.user?.biography !== null && currentItem?.user?.biography !== "null" && currentItem?.user?.biography !== "undefined" ? String(currentItem?.user?.biography) : ""}
                        </p>
                      </div>
                    </div>

                    <div className='form-row mt-3 mb-4'>
                      <div
                        className="col-auto "
                        title={artistlikes === true ? constant.unmic : constant.mic}
                      >
                        {artistlikes === true ? (
                          <button
                            className="btn btn-primary round-btn"
                            onClick={() => {
                              resetArtistLike();
                            }}
                          >

                            <img src={blackmikefill} title={constant.unmic} style={{ width: 25 }} />
                            <span className="play-btn d-mobile-none">{constant.unmic}</span>
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary round-btn"
                            onClick={() => createArtistLike()}
                          >
                            {/* <AiOutlineHeart size={22} /> */}
                            <img src={blackmike} title={constant.mic} style={{ width: 23 }} />
                            <span className="play-btn d-mobile-none">{constant.mic}</span>
                          </button>
                        )}
                      </div>
                      <div
                        className="col-auto"
                        title={artistfollow === true ? "UnFollow" : "Follow"}
                      >
                        <button
                          className="btn btn-primary round-btn"
                          onClick={() =>
                            artistfollow === true ? resetFollow() : createFollow()
                          }
                        >
                          {artistfollow === true ? (
                            <RiUserUnfollowLine size={22} />
                          ) : (
                            <RiUserFollowLine size={22} />
                          )}
                          <span className="play-btn d-mobile-none">
                            {artistfollow === true ? "UnFollow" : "Follow"}
                          </span>
                        </button>
                      </div>
                      {/* <div className='col-auto '>
                      <button className="btn  custom-more-btn round-btn  ">
                        <FiMoreVertical size={20} />
                      </button>
                    </div> */}
                    </div>
                  </div>
                }
              </div>
              {
                currentItem?.user !== undefined && currentItem?.user?.firstName !== undefined
                && currentItem?.user?.firstName !== null && currentItem?.user?.firstName !== ""
                && currentItem?.user?.firstName !== "admin" &&
                <div className="row">
                  <div className="col-auto">
                    <div className="biography-title fs-20 fw-600">Connect with Artist</div>
                  </div>
                </div>
              }
              <div className="form-row mt-2">
                {currentItem?.user?.appleMusicURL && (<div className="col-auto">
                  <div
                    title="Apple Music"
                    className="cursor"
                    onClick={() => {
                      navigateToExternalLink(currentItem?.user?.appleMusicURL);
                    }}
                  >
                    <img
                      style={{ height: 40, width: 40 }}
                      src={apple}
                      alt="Location"
                    ></img>
                  </div>
                </div>
                )}
                {currentItem?.user?.applePodcastURL && (<div className="col-auto">
                  <div
                    title="Apple Podcast"
                    className="cursor"
                    onClick={() => {
                      navigateToExternalLink(currentItem?.user?.applePodcastURL);
                    }}
                  >
                    <img
                      style={{ height: 40, width: 40 }}
                      src={podcaste}
                      alt="Location"
                    ></img>
                  </div>
                </div>
                )}
                {currentItem?.user?.faceookURL && (<div className="col-auto">
                  <div
                    title="Facebook"
                    className="cursor"
                    onClick={() => {
                      navigateToExternalLink(currentItem?.user?.faceookURL);
                    }}
                  >
                    <img
                      style={{ height: 40, width: 40 }}
                      src={facebookIcon}
                      alt="Location"
                    ></img>
                  </div>
                </div>
                )}
                {currentItem?.user?.instagramURL && (<div className="col-auto">
                  <div
                    title="Instagram"
                    className="cursor"
                    onClick={() => {
                      navigateToExternalLink(currentItem?.user?.instagramURL);
                    }}
                  >
                    <img
                      style={{ height: 40, width: 40 }}
                      src={instagramIcon}
                      alt="Location"
                    ></img>
                  </div>
                </div>)}
                {currentItem?.user?.iTunesURL && (<div className="col-auto">
                  <div
                    title="iTune"
                    className="cursor"
                    onClick={() => {
                      navigateToExternalLink(currentItem?.user?.iTunesURL);
                    }}
                  >
                    <img
                      style={{ height: 40, width: 40 }}
                      src={itune}
                      alt="Location"
                    ></img>
                  </div>
                </div>)}

                {currentItem?.user?.spotifyURL && (<div className="col-auto">
                  <div
                    title="Spotify"
                    className="cursor"
                    onClick={() => {
                      navigateToExternalLink(currentItem?.user?.spotifyURL);
                    }}
                  >
                    <img
                      style={{ height: 40, width: 40 }}
                      src={spotify}
                      alt="Location"
                    ></img>
                  </div>
                </div>)}
                {currentItem?.user?.tikTokURL && (<div className="col-auto">
                  <div
                    title="Tiktok"
                    className="cursor"
                    onClick={() => {
                      navigateToExternalLink(currentItem?.user?.tikTokURL);
                    }}
                  >
                    <img
                      style={{ height: 40, width: 40 }}
                      src={tiktok}
                      alt="Location"
                    ></img>
                  </div>
                </div>)}
                {currentItem?.user?.youtubeURL && (<div className="col-auto">
                  <div
                    title="Youtube"
                    className="cursor"
                    onClick={() => {
                      navigateToExternalLink(currentItem?.user?.youtubeURL);
                    }}
                  >
                    <img
                      style={{ height: 40, width: 40 }}
                      src={youtube}
                      alt="Location"
                    ></img>

                  </div>
                </div>)}
              </div>

              <div className="row justify-content-between mt-3 align-items-center">

                <div className="col-auto">
                  <div className="fs-16">{currentItem?.comments?.length > 0 ? currentItem?.comments?.length + " Comments" : "Comments"}</div>
                </div>
                <div className='col-auto'>
                  <span className='fs-20 tranform-45  '><BiChevronDown onClick={() => setcommentshow(true)} /></span>
                </div>
              </div>
              <div className="row justify-content-between mt-3 align-items-center">

                <div className="col-auto">
                  <div className="fs-16">{props?.location?.state?.variant == "homebigstagejs" ? "Big Stage" : props?.location?.state?.variant == "homepodcastjs" ? currentVideoList[0]?.albumname : props?.location?.state?.variant == "musicjs" ? currentVideoList?.length && currentVideoList[0]?.albumname : props?.location?.state?.variant == "podcast1" ? currentVideoList?.length && currentVideoList[0]?.albumname : "My Music"}</div>
                </div>
              </div>
              <div className='footer-line mt-3 mb-3 d-block d-md-none'></div>
              {currentVideoList?.length > 0 && currentVideoList.map((song, index) => {
                return (

                  <div className='videoplayer-list ' onClick={() => callnewsong(song)}>
                    <div className='video-card-list' >
                      <div className='video-thumb'>
                        {props?.location?.state?.variant == "homebigstagejs" || props?.location?.state?.variant == "homepodcastjs" ? <img
                          className="img-fluid"
                          src={song.thumb}
                        /> :
                          <video
                            className="img-fluid"
                            src={song.musicSrc + "#t=10"}
                          />}
                        <div className='mobile-list-time'>{song.time !== undefined &&
                          song.time !== null &&
                          song.time !== NaN
                          ? formatTime(song.time)
                          : "0:00"}</div>
                      </div>
                    </div>
                    <div className='video-card-details mt-3 pb-3'>
                      <div className='form-row align-items-center justify-content-between'>
                        {props?.location?.state?.variant !== "homebigstagejs" ? <div className='col-2'>   <div className='comment-profile'>
                          <img
                            className="episode-img"
                            src={song?.user?.urlImage ? constant.profileURL + song?.user?.urlImage : commentimg}
                          />
                        </div></div> : null}
                        <div className='col-9'>   <div className='comment-profile'>
                          <div className='video-title fs-16 wrapText'>{song.name}</div>
                          <div className='video-title fs-13 wrapText text-gray'>{song.albumname}</div>
                        </div></div>
                        <div className=' col-1'>   <div className='comment-profile'>
                          {/* <span className='fs-20'><BiDotsVerticalRounded /></span> */}
                        </div></div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          <Modal
            show={show}
            onHide={handleClose}
            backdrop={"static"}
            keyboard={false}
            aria-labelledby="example-custom-modal-styling-title"
            size={"lg"}
            padding={"0"}
            className={"video-player-dialog"}
          >
            <Modal.Header
              closeButton={true}
              style={{ backgroundColor: "#313131", border: 0 }}
            >Comments
              <Modal.Title id="example-custom-modal-styling-title">
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#313131", padding: 0 }}>
              <div className='video-comment-list'>
                <div className="row mt-4 ">
                  <div className="col-12 col-lg-8">
                    <div className='form-row align-items-baseline'>
                      <div className='col-2 col-md-1'>   <div className='comment-profile'>
                        <img
                          className="episode-img"
                          src={commentimg}
                        />
                      </div></div>
                      <div className='col-10 col-md-11'>    <div className="leave-comment">
                        <div className="floating-label">
                          <input
                            className="floating-input"
                            type="text"
                            placeholder=" "
                            row="1"
                            name="commentInputVal"
                          ></input>
                          <span className="highlight"></span>
                          <label className="label">Comment</label>
                        </div>
                      </div></div>
                    </div>
                    <div className='form-row mb-2 '>
                      <div className='col-2'></div>
                      <div className='col-10'>
                        <div className='form-row'>
                          <div className='col-6'>
                            <button
                              className="btn btn-outline-main dis-flex w-100"
                            >
                              <span className="">Cancel</span>
                            </button>
                          </div>
                          <div className='col-6'>
                            <button
                              className="btn btn-primary dis-flex w-100"
                            >
                              <span className="">Post Comment</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className='mobile-comment-lsist-scrool'>
                <div className='video-comments-sm mt-3'>
                  <div className='row align-items-center'>
                    <div className='col-2'>
                      <img
                        className="episode-img"
                        src={commentimg}
                      />
                    </div>
                    <div className='col-10'>
                      <div className='row justify-content-between'>
                        <div className='col-12'>
                          <div className='row justify-content-between'>
                            <div className='col-auto text-light-gray fs-12 lh-20'>Dharmik Patel</div>
                            <div className='col-auto fs-12 text-light-gray lh-20'>20 Dec 2022</div>
                            <div className='col-12 fs-12 text-light-gray'>In publishing and graphic design, Lorem ipsum is a placeholder text commonly</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className='video-comments-sm mt-3'>
                  <div className='row align-items-center'>
                    <div className='col-2'>
                      <img
                        className="episode-img"
                        src={commentimg}
                      />
                    </div>
                    <div className='col-10'>
                      <div className='row justify-content-between'>
                        <div className='col-12'>
                          <div className='row justify-content-between'>
                            <div className='col-auto text-light-gray fs-12 lh-20'>Dharmik Patel</div>
                            <div className='col-auto fs-12 text-light-gray lh-20'>20 Dec 2022</div>
                            <div className='col-12 fs-12 text-light-gray'>In publishing and graphic design, Lorem ipsum is a placeholder text commonly</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className='video-comments-sm mt-3'>
                  <div className='row align-items-center'>
                    <div className='col-2'>
                      <img
                        className="episode-img"
                        src={commentimg}
                      />
                    </div>
                    <div className='col-10'>
                      <div className='row justify-content-between'>
                        <div className='col-12'>
                          <div className='row justify-content-between'>
                            <div className='col-auto text-light-gray fs-12 lh-20'>Dharmik Patel</div>
                            <div className='col-auto fs-12 text-light-gray lh-20'>20 Dec 2022</div>
                            <div className='col-12 fs-12 text-light-gray'>In publishing and graphic design, Lorem ipsum is a placeholder text commonly</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className='video-comments-sm mt-3'>
                  <div className='row align-items-center'>
                    <div className='col-2'>
                      <img
                        className="episode-img"
                        src={commentimg}
                      />
                    </div>
                    <div className='col-10'>
                      <div className='row justify-content-between'>
                        <div className='col-12'>
                          <div className='row justify-content-between'>
                            <div className='col-auto text-light-gray fs-12 lh-20'>Dharmik Patel</div>
                            <div className='col-auto fs-12 text-light-gray lh-20'>20 Dec 2022</div>
                            <div className='col-12 fs-12 text-light-gray'>In publishing and graphic design, Lorem ipsum is a placeholder text commonly</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className='video-comments-sm mt-3'>
                  <div className='row align-items-center'>
                    <div className='col-2'>
                      <img
                        className="episode-img"
                        src={commentimg}
                      />
                    </div>
                    <div className='col-10'>
                      <div className='row justify-content-between'>
                        <div className='col-12'>
                          <div className='row justify-content-between'>
                            <div className='col-auto text-light-gray fs-12 lh-20'>Dharmik Patel</div>
                            <div className='col-auto fs-12 text-light-gray lh-20'>20 Dec 2022</div>
                            <div className='col-12 fs-12 text-light-gray'>In publishing and graphic design, Lorem ipsum is a placeholder text commonly</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
              </div>




            </Modal.Body>
          </Modal>

        </section>}
      {currentItem?.id ? <CommentsModal
        type={props?.location?.state?.variant == "homebigstagejs" ? "ReportBigStage" : props?.location?.state?.variant == "homepodcastjs" ? "ReportPodcast" : props?.location?.state?.variant == "podcast1" ? "ReportEpisode" : "ReportSong"}
        show={reportshow}
        id={currentItem?.id}
        title={"Report - " + currentItem.name}
        currentUserId={
          idd !== undefined
            ? idd?.id
            : 0
        }
        handleClose={() => {
          setreportshow(false);
        }}
        inputText={commentSongInputText}
        setInputText={(val) => {
          setCommentSongInputText(val);
        }}
        isComment={false}
        setUpdate={() => updatecomment()}
      /> : null}
      {currentItem?.id ? <CommentsModal1
        type={props?.location?.state?.variant == "homebigstagejs" ? "ReportBigStage" : props?.location?.state?.variant == "homepodcastjs" ? "ReportPodcast" : props?.location?.state?.variant == "podcast1" ? "ReportEpisode" : "ReportSong"}
        show={reportshow1}
        id={currentItem?.id}
        title={"Report - " + currentItem.name}
        currentUserId={
          idd !== undefined
            ? idd?.id
            : 0
        }
        handleClose={() => {
          setreportshow1(false);
        }}
        inputText={commentSongInputText}
        setInputText={(val) => {
          setCommentSongInputText(val);
        }}
        isComment={false}
        setUpdate={() => updatecomment()}
      /> : null}
      {currentItem?.id ? <CommentsModal1
        type={props?.location?.state?.variant == "homebigstagejs" ? "CommentBigStage" : props?.location?.state?.variant == "homepodcastjs" ? "CommentEpisode" : props?.location?.state?.variant == "podcast1" ? "CommentEpisode" : "CommentSong"}
        show={commentshow}
        id={currentItem?.id}

        currentUserId={
          idd !== undefined
            ? idd?.id
            : 0
        }
        handleClose={() => {
          setcommentshow(false)
          // setShowCommentSongModal(false);
        }}
        inputText={commentSongInputText}
        setInputText={(val) => {
          setCommentSongInputText(val);
        }}
        isComment={true}
        setUpdate={() => updatecomment()}
      /> : null}

      {showShareToModal === true && (
        <>
          <ShareModal1
            show={showShareToModal}
            id={varient == "musicjs" ? currentItem?.albumId : varient == "podcast1" ? currentItem?.podcasts?.id : currentItem?.id}
            title={"Share To"}
            currentUserId={
              idd !== undefined
                ? idd?.id
                : 0
            }
            handleClose={() => {
              setShowShareToModal(false);
            }}
            from={varient}
          />
        </>
      )}
    </>
  )
}

export default VideoPlayer




