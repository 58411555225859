import React, { useState, useEffect } from "react";
import { constant } from "../../utility/constant";
import { getDataApi, postDataApi, deleteDataApi } from "../../utility/apiCall";
import { FaRegPlayCircle } from "react-icons/fa";
import VideoModal from "../../components/VideoModal/VideoModal";
import { FiMoreVertical } from "react-icons/fi";
import { FaRegCommentDots } from "react-icons/fa";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { colors } from "../../utility/colors";
import CommentsModal from "../../components/CommentsModal/CommentsModal";
import { VerifyLoginExistOrNot } from "../../utility/VerifyLoginExistOrNot";
import { useHistory, useLocation } from "react-router-dom";
import bigstageBanner from "../../assets/Images/bigstageBanner.png";
import mike from "../../assets/Images/mike.svg";
import mikeFill from "../../assets/Images/mikeFill.svg";
import { DisableButtonLoader } from "../../utility/CustomLoader";
import ReactPlayer from 'react-player';
function BigStage() {
  let history = useHistory();
  var path = useLocation();
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const [buttonDisable, setbuttonDisable] = useState(false);
  const [showViewMore, setShowViewMore] = useState(false);
  const [showCommentBigStageModal, setShowCommentBigStageModal] =
    useState(false);
  const [commentBigStageInputText, setCommentBigStageInputText] = useState("");
  const [showReportBigStageModal, setShowReportBigStageModal] = useState(false);
  const [reportBigStageInputText, setReportBigStageInputText] = useState("");
  const [selectedBigStage, setSelectedBigStage] = useState(undefined);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [limit, setLimit] = useState(20);
  const [playVideo, setPlayVideo] = useState(false);
  const [playVideo1, setPlayVideo1] = useState(false);
  const [homeBigStageItem, setHomeBigStageItem] = useState(undefined);
  const [bigStageList, setBigStageList] = useState([]);
  const [indexext, setindex] = useState(null);
  const [bigStageItem, setBigStageItem] = useState({
    videoURL: "",
    thumbnailURL: bigstageBanner,
    name: "",
    artisitName: "",
  });
  
  const [bigStageLikesList, setBigStageLikesList] = useState([]);
  const [bigStageLikesData, setBigStageLikesData] = useState([]);
  const [bigStageResetCount, setBigStageResetCount] = useState(0);
  const [bgId, setBgId] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);


  //   let apiURL = constant.baseURL +
  //   'bigstages/getAllActiveBigstageWithUserLike?page=' +
  //   pageNumber +
  //   '&limit=' +
  //   limit +
  //   '&sort=id,DESC';
  // if (userDetails.length > 0) {
  //   apiURL += "&userId=" + userDetails[0].id;
  // }
  // let resBigStages = await getApi(apiURL);

  const getHomeBigStage = async () => {
    //let apiURL = constant.baseURL + "bigstages/getTop10BigstagesWithUserLike";
    let apiURL = constant.baseURL + "bigstages/getMainBigstagesWithUserLike";
    let jsonData = { userId: 0 }
    let resBigStages = await postDataApi(apiURL, jsonData);
    //console.log("resBigStages: ", resBigStages);
    if (resBigStages !== undefined && resBigStages.data.length > 0) {
      setHomeBigStageItem(resBigStages.data[0]);//setHomeBigStageItem(resBigStages.data[resBigStages.data.length - 1]);
      setBigStageItem((prevState) => {
        return {
          ...prevState,
          videoURL:
            constant.bigStageVideoURL +
            resBigStages.data[0].videoURL,
          thumbnailURL:
            constant.bigStageThumbnailURL +
            resBigStages.data[0].thumbnailURL,
          name: resBigStages.data[0].name,
          artisitName:
            resBigStages.data[0].user !== null
              ? resBigStages.data[0].user.firstName +
              " " +
              resBigStages.data[0].user.lastName
              : "",
        };
      });
    }
  }


  const getBigStageList = async (pageNumber) => {
    let apiURL = constant.baseURL +
      'bigstages/getAllActiveBigstageWithUserLike?page=' +
      pageNumber +
      '&limit=' +
      limit +
      '&sort=id,DESC';
    if (currentUserInfo !== undefined) {
      apiURL += "&userId=" + currentUserInfo.id;
    }
    let resBigStages = await getDataApi(
      apiURL
    );

    // console.log(
    //   "resBigStages: ",
    //   resBigStages,
    //   constant.baseURL +
    //   "bigstages/getAll?filter=status||$eq||1&filter=isDeleted||$eq||0&page=" +
    //   pageNumber +
    //   "&limit=" +
    //   limit +
    //   "&sort=id,DESC"
    // );
    // console.log(resBigStages)
    if (
      resBigStages !== undefined &&
      resBigStages.data !== undefined &&
      resBigStages.data.length > 0
    ) {
      setPageCount(resBigStages.pageCount);
      //let bigstageIds = "";
      if (pageNumber == 1) {
        //setHomeBigStageItem(resBigStages.data[0]);
        // setBigStageItem((prevState) => {
        //   return {
        //     ...prevState,
        //     videoURL:
        //       constant.bigStageVideoURL +
        //       resBigStages.data[0].videoURL,
        //     thumbnailURL:
        //       constant.bigStageThumbnailURL +
        //       resBigStages.data[0].thumbnailURL,
        //     name: resBigStages.data[0].name,
        //     artisitName:
        //       resBigStages.data[0].user !== null
        //         ? resBigStages.data[0].user.firstName +
        //         " " +
        //         resBigStages.data[0].user.lastName
        //         : "",
        //   };
        // });
        // resBigStages.data.map((item) => {
        //   bigstageIds += item.id + ",";
        // });
        setBigStageList([...resBigStages.data]);
        // getBigStageLikesByUser(
        //   bigstageIds.substr(0, bigstageIds.lastIndexOf(","))
        // );
      } else {
        setBigStageList([...bigStageList, ...resBigStages.data]);
        // resBigStages.data.map((item) => {
        //   bigstageIds += item.id + ",";
        // });
        // bigStageList.map((item) => {
        //   bigstageIds += item.id + ",";
        // });
        // getBigStageLikesByUser(
        //   bigstageIds.substr(0, bigstageIds.lastIndexOf(","))
        // );
      }
      //show hide view more page
      if (resBigStages.page < resBigStages.pageCount) {
        setShowViewMore(true);
        setbuttonDisable(false);
      } else {
        setShowViewMore(false);
        setbuttonDisable(false);
      }
    } else {
      setShowViewMore(false);
      setbuttonDisable(false);
    }
  };
  const getBigStageList1 = async (pageNumber) => {
    let apiURL = constant.baseURL +
      "bigstages/getAllActiveBigstageWithUserLike?page=" +
      1 +
      "&limit=" +
      (limit * pageNumber) +
      '&sort=id,DESC';
    if (currentUserInfo !== undefined) {
      apiURL += "&userId=" + currentUserInfo.id;
    }
    let resBigStages = await getDataApi(
      apiURL
    );

    if (
      resBigStages !== undefined &&
      resBigStages.data !== undefined &&
      resBigStages.data.length > 0
    ) {
      setPageCount(resBigStages.pageCount);
      let bigstageIds = "";
      if (pageNumber == 1) {
        //setHomeBigStageItem(resBigStages.data[0]);
        // setBigStageItem((prevState) => {
        //   return {
        //     ...prevState,
        //     videoURL:
        //       constant.bigStageVideoURL +
        //       resBigStages.data[0].videoURL,
        //     thumbnailURL:
        //       constant.bigStageThumbnailURL +
        //       resBigStages.data[0].thumbnailURL,
        //     name: resBigStages.data[0].name,
        //     artisitName:
        //       resBigStages.data[0].user !== null
        //         ? resBigStages.data[0].user.firstName +
        //         " " +
        //         resBigStages.data[0].user.lastName
        //         : "",
        //   };
        // });

        setBigStageList([...resBigStages.data]);

      }
      else {
        setBigStageList([...resBigStages.data]);

      }
      //show hide view more page
      // if (resBigStages.page < resBigStages.totalCount) {
      //   setShowViewMore(true);
      //   setbuttonDisable(false);
      // } else {
      //   setShowViewMore(false);
      //   setbuttonDisable(false);
      // }
    } else {
      setShowViewMore(false);
      setbuttonDisable(false);
    }
  };
  // const getBigStageLikesByUser = async (str) => {
  //   if (currentUserInfo !== undefined && str !== "") {
  //     let resBigStageLikes = await getDataApi(
  //       constant.baseURL +
  //       "bigstagelikes?filter=user.id||$eq||" +
  //       currentUserInfo.id +
  //       "&filter=bigstage.id||$in||" +
  //       str
  //     );
  //     if (resBigStageLikes !== undefined) {
  //       setBigStageLikesList(resBigStageLikes);
  //       setBigStageLikesData(resBigStageLikes);
  //     }
  //     setbuttonDisable(false);
  //   } else {
  //     setbuttonDisable(false);
  //   }
  // };
  const playBigStageVideo = (item, isFirst, i) => {
    history.push({
      pathname: `Videoplayer`,
      state: { variant: "homebigstagejs", url: constant.bigStageVideoURL + item.videoURL }
    });
    // setPlayVideo(true);
    // setPlayVideo1(false);
    // setindex(i)
    // if (isFirst == true) {
    //   setBigStageItem(item);
    // } else {
    //   setBigStageItem({
    //     videoURL: constant.bigStageVideoURL + item.videoURL,
    //     thumbnailURL:
    //       constant.bigStageThumbnailURL + item.thumbnailURL,
    //     name: item.name,
    //     artisitName:
    //       item.user !== undefined && item.user !== null
    //         ? item.user.firstName + " " + item.user.lastName
    //         : "",
    //   });
    // }
  };
  const playBigStageVideo1 = (item, isFirst) => {
    //console.log(item)
    history.push({
      pathname: `Videoplayer`,
      state: { variant: "homebigstagejs", url: item.videoURL }
    });
    // setPlayVideo1(true);
    // setPlayVideo(false);
    // if (isFirst == true) {
    //   setBigStageItem(item);
    // } else {
    //   setBigStageItem({
    //     videoURL: constant.bigStageVideoURL + item.videoURL,
    //     thumbnailURL:
    //       constant.bigStageThumbnailURL + item.thumbnailURL,
    //     name: item.name,
    //     artisitName:
    //       item.user !== undefined && item.user !== null
    //         ? item.user.firstName + " " + item.user.lastName
    //         : "",
    //   });
    // }
  };
  const onClickViewMore = () => {
    setbuttonDisable(true);
    setPage(page + 1);
    getBigStageList(page + 1);
  };
  // const closeVideoModal = () => {
  //   setPlayVideo(false);
  // };
  const createBigStageLike = async (bigStageId) => {
    if (VerifyLoginExistOrNot()) {
      setbuttonDisable(true);
      setBgId(bigStageId);
      if (currentUserInfo !== undefined) {
        let jsonData = { user: currentUserInfo.id, bigstage: bigStageId };
        var formBody = [];
        for (var property in jsonData) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(jsonData[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        var authHeader = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        };
        let resBigStageLike = await postDataApi(
          constant.baseURL + "bigstagelikes",
          formBody,
          authHeader
        );
        if (
          resBigStageLike != undefined &&
          resBigStageLike.status != undefined &&
          resBigStageLike.status == 201 && resBigStageLike.data !== undefined
        ) {
          setLikes(bigStageId, resBigStageLike.data, undefined);//setLikes(bigStageId, resBigStageLike.data);
          setbuttonDisable(false);
        } else {
          setbuttonDisable(false);
        }
      } else {
        setbuttonDisable(false);
      }
    }
  };
  const resetBigStageLike = async (bigStageId, likeId) => {
    if (VerifyLoginExistOrNot()) {
      setbuttonDisable(true);
      setBgId(bigStageId);
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resp = await deleteDataApi(
        constant.baseURL + "bigstagelikes/" + likeId,
        authHeader
      );
      if (resp != undefined && resp.status != undefined && resp.status == 200) {
        setLikes(bigStageId, undefined, likeId);//setLikes(bigStageId);
        setbuttonDisable(false);
      } else {
        setbuttonDisable(false);
      }
    } else {
      setbuttonDisable(false);
    }
  };
  // const createEpisodeLike = async (episodeId) => {
  //   if (currentUserInfo.id) {
  //     let jsonData = { user: currentUserInfo.id, bigstage: episodeId };
  //     var formBody = [];
  //     for (var property in jsonData) {
  //       var encodedKey = encodeURIComponent(property);
  //       var encodedValue = encodeURIComponent(jsonData[property]);
  //       formBody.push(encodedKey + "=" + encodedValue);
  //     }
  //     formBody = formBody.join("&");
  //     var authHeader = {
  //       Accept: "application/json",
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     };
  //     let resBigStageLike = await postDataApi(
  //       constant.baseURL + "bigstagelikes",
  //       formBody,
  //       authHeader
  //     );
  //     console.log(resBigStageLike)
  //     if (
  //       resBigStageLike?.data !== undefined
  //     ) {
  //       setLikes(episodeId, resBigStageLike.data, undefined);
  //     }
  //   }
  //   else {
  //     history.push({
  //       pathname: `/login`,
  //       // state: {
  //       //   prevPath: path.pathname,
  //       //   userId: props.currentUserId,
  //       // },
  //     });
  //   }
  // };

  // const resetEpisodeLike = async (episodeId, likeId) => {
  //   console.log(episodeId, likeId)
  //   if (currentUserInfo.id) {
  //     var authHeader = {
  //       Accept: "application/json",
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     };
  //     let resp = await deleteDataApi(
  //       constant.baseURL + "bigstagelikes/" + likeId,
  //       authHeader
  //     );
  //     if (
  //       resp !== undefined &&
  //       resp.status !== undefined &&
  //       resp.status === 200
  //     ) {
  //       setLikes(episodeId, undefined, likeId);
  //     }
  //   } else {
  //     history.push({
  //       pathname: `/login`,
  //       // state: {
  //       //   prevPath: path.pathname,
  //       //   userId: props.currentUserId,
  //       // },
  //     });
  //   }
  // };
  const setLikes = (id, data, likeId) => {
    let bgItems = [...bigStageList]

    //console.log(bgItems)
    if (data !== undefined) {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike.push(data);
          itm.likes.push(data);
        }
      });
    } else {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike = [];
          itm.likes.map((lkitm, index0) => {
            if (lkitm.id === likeId) {
              itm.likes.splice(index0, 1);
            }
          });
        }
      });
    }

    setBigStageList(bgItems)

  };
  const setCommentUpdate = () => {
    setCommentsCount(commentsCount + 1);
  }
  const renderLikes = (likeData) => {
    //console.log(likeData)
    if (likeData?.userlike?.length > 0) {
      return (
        <img className="w-24" src={mikeFill} alt="Unmike" style={{ cursor: "pointer" }}
        //onClick={() => resetEpisodeLike(likeData.id, likeData.userlike[0].id)} 
        onClick={() => {
          if (VerifyLoginExistOrNot()) resetBigStageLike(likeData.id, likeData.userlike[0].id);
          else {
            history.push({
              pathname: `/login`,
              state: {
                prevPath: path.pathname,
                userId:
                  currentUserInfo !== undefined ? currentUserInfo.id : 0,
              },
            });
          }
        }}
        />
      )
    } else {
      return (
        <img className="w-24" src={mike} alt="Mike" style={{ cursor: "pointer" }}
        //onClick={() => createEpisodeLike(likeData.id)} 
        onClick={() => {
          if (VerifyLoginExistOrNot()) createBigStageLike(likeData.id);
          else {
            history.push({
              pathname: `/login`,
              state: {
                prevPath: path.pathname,
                userId:
                  currentUserInfo !== undefined ? currentUserInfo.id : 0,
              },
            });
          }
        }}
        />
      )
    }
    // if (likeData.length !== 0) {
    //   let flt = likeData.filter(
    //     (f) => f.id === id
    //   );
    //   if (flt.length > 0) {
    //     return (
    //       <img
    //         src={mikeFill}
    //         title={constant.unmic}
    //         key={id}
    //         //onClick={() => resetBigStageLike(id, flt[0].id)}
    //         onClick={() => {
    //           if (VerifyLoginExistOrNot()) resetBigStageLike(id, flt[0].id, type);
    //           else {
    //             history.push({
    //               pathname: `/login`,
    //               state: {
    //                 prevPath: path.pathname,
    //                 userId:
    //                   currentUserInfo !== undefined ? currentUserInfo.id : 0,
    //               },
    //             });
    //           }
    //         }}
    //         style={{ cursor: "pointer", width: 25 }}
    //       />
    //     );
    //   } else {
    //     return (
    //       <img
    //         src={mike}
    //         title={constant.mic}
    //         key={id}
    //         onClick={() => {
    //           if (VerifyLoginExistOrNot()) createBigStageLike(id, type);
    //           else {
    //             history.push({
    //               pathname: `/login`,
    //               state: {
    //                 prevPath: path.pathname,
    //                 userId:
    //                   currentUserInfo !== undefined ? currentUserInfo.id : 0,
    //               },
    //             });
    //           }
    //         }}
    //         style={{ cursor: "pointer", width: 25 }}
    //       />
    //     );
    //   }
    // } else {
    //   return (
    //     <img
    //       src={mike}
    //       title={constant.mic}
    //       key={id}
    //       onClick={() => {
    //         if (VerifyLoginExistOrNot()) createBigStageLike(id, type);
    //         else {
    //           history.push({
    //             pathname: `/login`,
    //             state: {
    //               prevPath: path.pathname,
    //               userId:
    //                 currentUserInfo !== undefined ? currentUserInfo.id : 0,
    //             },
    //           });
    //         }
    //       }}
    //       style={{ cursor: "pointer", width: 25 }}
    //     />
    //   );
    // }
  };
  const renderLatestBigStage = () => {
    if (bigStageList.length > 0) {
      return bigStageList.map((item, index) => {
        if (index <= 1 && (item.mainBigstage === undefined || item.mainBigstage === false)) {
          return (
            <div className="col-12 col-xl-6" key={index}>
              <div className="latest-video-container big-stage-react-video">
                <div className="latest-video-main">
                  <div className="video-card">
                    {bigStageItem !== undefined && playVideo && index === indexext ?
                      <ReactPlayer
                        className="reactPlayer"
                        width='100%'
                        controls={true}
                        playing={true}
                        // getAudioInstance={getAudioInstance}
                        //onEnablePIP={onEnablePIP}
                        //pip={false}
                        // playsinline={true}
                        url={bigStageItem.videoURL}
                        config={{ file: { attributes: { disablepictureinpicture: 'true' } } }}
                      >
                      </ReactPlayer>
                      // <VideoModal
                      //   show={playVideo}
                      //   url={bigStageItem.videoURL}
                      //   title={bigStageItem !== undefined ? bigStageItem.name : ""}
                      //   height={790}
                      //   width={790}
                      //   handleClose={closeVideoModal}
                      // ></VideoModal>
                      : <img
                        className="latest-video-item-images"
                        src={
                          constant.bigStageThumbnailURL + item.thumbnailURL
                        }
                        alt={
                          item.user !== undefined && item.user !== null
                            ? item.user.firstName + " " + item.user.lastName
                            : ""
                        }
                      />}
                  </div>
                  {bigStageList.length > 0 && index !== indexext && (
                    <div className="video-page-backdrop">
                      <div className="hover-content">
                        <div
                          className="play-icon-video"
                          onClick={() => playBigStageVideo(item, false, index)}
                        >
                          <FaRegPlayCircle />
                        </div>
                      </div>
                      <div className="bg-color"></div>
                    </div>
                  )}
                </div>
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-12">
                      <h6
                        className="fs-16 mt-1 fw-600 wrapText"
                        title={item.name}
                      >
                        {item.name}
                      </h6>
                      <div className="line"></div>
                      {/* <p className="big-stage-signer wrapText" title={item.user !== null ? String(item.user.firstName + ' ' + item.user.lastName) : ""}>{item.user !== null ? item.user.firstName + ' ' + item.user.lastName : ''}</p> */}
                    </div>
                    <div className="col-12">
                      <h6
                        className="fs-16 mt-1 fw-600 wrapText"
                        title={item?.artist}
                      >
                        {item?.artist}
                      </h6>
                      <div className="line"></div>
                      {/* <p className="big-stage-signer wrapText" title={item.user !== null ? String(item.user.firstName + ' ' + item.user.lastName) : ""}>{item.user !== null ? item.user.firstName + ' ' + item.user.lastName : ''}</p> */}
                    </div>
                    <div className="col-12">
                      <div className="row justify-content-between">
                        <div className="col-auto">
                          <div className="row">
                            <div className="col-auto">
                              <div>
                                {buttonDisable && bgId === item.id ? DisableButtonLoader() :
                                  <>
                                    {renderLikes(item)}{" "}
                                    <span className="fs-14 fw-500 pl-1">
                                      {item.likes.length}
                                    </span>
                                  </>
                                }
                              </div>
                            </div>
                            <div className="col-auto">
                              <div>
                                <FaRegCommentDots
                                  color="#b09b2f"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (VerifyLoginExistOrNot()) {
                                      setSelectedBigStage(item);
                                      setShowCommentBigStageModal(true);
                                    } else {
                                      history.push({
                                        pathname: `/login`,
                                        state: {
                                          prevPath: path.pathname,
                                          userId:
                                            currentUserInfo !== undefined
                                              ? currentUserInfo.id
                                              : 0,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <span className="fs-14 fw-500 pl-3">
                                  {item.comments.length}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          <div>
                            <div className=" cts-dro-si">
                              <DropdownButton
                                variant="Secondary"
                                title={
                                  <FiMoreVertical
                                    style={{ cursor: "pointer" }}
                                    size={22}
                                    color={colors.color1}
                                  ></FiMoreVertical>
                                }
                              >
                                <Dropdown.Item
                                  onClick={() => {
                                    if (VerifyLoginExistOrNot()) {
                                      setSelectedBigStage(item);
                                      setShowReportBigStageModal(true);
                                    } else {
                                      history.push({
                                        pathname: `/login`,
                                        state: {
                                          prevPath: path.pathname,
                                          userId:
                                            currentUserInfo !== undefined
                                              ? currentUserInfo.id
                                              : 0,
                                        },
                                      });
                                    }
                                  }}
                                >
                                  Report
                                </Dropdown.Item>
                              </DropdownButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      });
    } else {
      return (
        <div className="no-data-found" style={{ paddingLeft: 15 }}>
          {constant.noDataFoundLabel}
        </div>
      );
    }
  };
  const renderBigStages = () => {
    return bigStageList.map((item, index) => {
      if (index > 1 && (item.mainBigstage === undefined || item.mainBigstage === false)) {
        return (
          <div className="col-12 col-xl-4" key={index}>
            <div className="latest-video-container">
              <div className="latest-video-main">
                <div className="video-card">
                  {bigStageItem !== undefined && playVideo && index === indexext ?
                    <ReactPlayer
                      className="reactPlayer"
                      width='100%'
                      controls={true}
                      playing={true}
                      // getAudioInstance={getAudioInstance}
                      //onEnablePIP={onEnablePIP}
                      //pip={false}
                      // playsinline={true}
                      url={bigStageItem.videoURL}
                      config={{ file: { attributes: { disablepictureinpicture: 'true' } } }}
                    >
                    </ReactPlayer>
                    // <VideoModal
                    //   show={playVideo}
                    //   url={bigStageItem.videoURL}
                    //   title={bigStageItem !== undefined ? bigStageItem.name : ""}
                    //   height={790}
                    //   width={790}
                    //   handleClose={closeVideoModal}
                    // ></VideoModal>
                    : <img
                      className="latest-video-item-images"
                      src={
                        constant.bigStageThumbnailURL + item.thumbnailURL
                      }
                      alt={
                        item.user !== undefined && item.user !== null
                          ? item.user.firstName + " " + item.user.lastName
                          : ""
                      }
                    />}

                </div>
                {bigStageList.length > 0 && index !== indexext && (
                  <div className="video-page-backdrop">
                    <div className="hover-content">
                      <div
                        className="play-icon-video"
                        onClick={() => playBigStageVideo(item, false, index)}
                      >
                        <FaRegPlayCircle />
                      </div>
                    </div>
                    <div className="bg-color"></div>
                  </div>
                )}
              </div>
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-12">
                    <h6
                      className="fs-16 mt-1 fw-600 wrapText"
                      title={item.name}
                    >
                      {item.name}
                    </h6>
                    <div className="line"></div>
                    {/* <p className="big-stage-signer wrapText" title={item.user !== null ? String(item.user.firstName + ' ' + item.user.lastName) : ""}>{item.user !== null ? item.user.firstName + ' ' + item.user.lastName : ''}</p> */}
                  </div>
                  <div className="col-12">
                    <h6
                      className="fs-16 mt-1 fw-600 wrapText"
                      title={item?.artist}
                    >
                      {item?.artist}
                    </h6>
                    <div className="line"></div>
                    {/* <p className="big-stage-signer wrapText" title={item.user !== null ? String(item.user.firstName + ' ' + item.user.lastName) : ""}>{item.user !== null ? item.user.firstName + ' ' + item.user.lastName : ''}</p> */}
                  </div>
                  <div className="col-12 ">
                    <div className="row justify-content-between">
                      <div className="col-auto">
                        <div className="row">
                          <div className="col-auto">
                            <div>
                              {buttonDisable && bgId === item.id ? DisableButtonLoader() :
                                <>
                                  {renderLikes(item)}{" "}
                                  <span className="fs-14 fw-500 pl-1">
                                    {item.likes.length}
                                  </span>
                                </>
                              }
                            </div>
                          </div>
                          <div className="col-auto">
                            <div>
                              <FaRegCommentDots
                                color="#b09b2f"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (VerifyLoginExistOrNot()) {
                                    setSelectedBigStage(item);
                                    setShowCommentBigStageModal(true);
                                  } else {
                                    history.push({
                                      pathname: `/login`,
                                      state: {
                                        prevPath: path.pathname,
                                        userId:
                                          currentUserInfo !== undefined
                                            ? currentUserInfo.id
                                            : 0,
                                      },
                                    });
                                  }
                                }}
                              />
                              <span className="fs-14 fw-500 pl-3">
                                {item.comments.length}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div>
                          <div className=" cts-dro-si">
                            <DropdownButton
                              variant="Secondary"
                              title={
                                <FiMoreVertical
                                  style={{ cursor: "pointer" }}
                                  size={22}
                                  color={colors.color1}
                                ></FiMoreVertical>
                              }
                            >
                              <Dropdown.Item
                                onClick={() => {
                                  if (VerifyLoginExistOrNot()) {
                                    setSelectedBigStage(item);
                                    setShowReportBigStageModal(true);
                                  } else {
                                    history.push({
                                      pathname: `/login`,
                                      state: {
                                        prevPath: path.pathname,
                                        userId:
                                          currentUserInfo !== undefined
                                            ? currentUserInfo.id
                                            : 0,
                                      },
                                    });
                                  }
                                }}
                              >
                                Report
                              </Dropdown.Item>
                            </DropdownButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  };
  useEffect(() => {
    //setBigStageResetCount(0);
    setCommentsCount(0);
    getHomeBigStage();
    getBigStageList(page);
  }, []);
  useEffect(() => {
    //console.log("useEffect - refresh");
    // let bigstageIds = "";
    // bigStageList.map((item) => {
    //   bigstageIds += item.id + ",";
    // });
    // getBigStageLikesByUser(bigstageIds.substr(0, bigstageIds.lastIndexOf(","))); 
    getBigStageList1(page);
  }, [commentsCount]);
  return (
    <div>
      <section
        className="banner-big-stage big-stage-main"
        style={playVideo1 === false ? {
          backgroundImage: `url(${encodeURI(bigStageItem.thumbnailURL)})`,
        } : null}
      >
        <div className="header big-stage-react-player">
          <div className="container">

            {playVideo1 === undefined ||
              playVideo1 === false ?
              <div className="playlist cust-playlist">
                <div
                  className="playlist-namne wrapText fs-40 fw-700"
                  title={
                    bigStageItem !== undefined ? String(bigStageItem.name) : ""
                  }
                >
                  {bigStageItem !== undefined ? bigStageItem.name : ""}
                </div>
                {bigStageList.length > 0 && (
                  <div
                    className="play-icon"
                    onClick={() => playBigStageVideo1(bigStageItem, true)}
                  >
                    <FaRegPlayCircle style={{ cursor: "pointer" }} />
                  </div>
                )}
              </div>
              :
              <div className="playlist cust-playlist big-stage-player">
                <ReactPlayer
                  className="reactPlayer"
                  width='100%'
                  controls={true}
                  playing={true}
                  // getAudioInstance={getAudioInstance}
                  //onEnablePIP={onEnablePIP}
                  //pip={false}
                  // playsinline={true}
                  url={bigStageItem.videoURL}
                  config={{ file: { attributes: { disablepictureinpicture: 'true' } } }}
                />
              </div>
            }
          </div>
        </div>
      </section>
      <section className="big-stage-latest-video">
        <div className="container">
          <div className="row">
            <div className="col-auto">
              <div className="fs-20 fw-700">Latest Video</div>
            </div>
          </div>
          <div className="row mt-3">{renderLatestBigStage()}</div>
          <div className="row mt-3">
            {renderBigStages(
              currentUserInfo !== undefined ? currentUserInfo.id : 0
            )}
          </div>
          {bigStageList.length > 0 && showViewMore === true && (
            <div className="row justify-content-center mt-3">
              <div className="col-auto">
                <button className="btn dark"
                  disabled={buttonDisable}
                  onClick={onClickViewMore}
                  style={{ cursor: "pointer", width: "100%", display: 'flex' }}>
                  {buttonDisable && DisableButtonLoader()}
                  <span style={{ paddingLeft: 3 }}>{constant.showMoreLabel}</span>
                </button>
              </div>
            </div>
          )}
        </div>
        {showCommentBigStageModal === true && selectedBigStage !== undefined && (
          <CommentsModal
            type="CommentBigStage"
            show={showCommentBigStageModal}
            id={selectedBigStage.id}
            title={"Comment - " + selectedBigStage.name}
            currentUserId={
              currentUserInfo !== undefined ? currentUserInfo.id : 0
            }
            handleClose={() => {
              setShowCommentBigStageModal(false);
            }}
            inputText={commentBigStageInputText}
            setInputText={(val) => {
              setCommentBigStageInputText(val);
            }}
            setUpdate={setCommentUpdate}
            isComment={true}
          />
        )}
        {showReportBigStageModal === true && selectedBigStage !== undefined && (
          <CommentsModal
            type="ReportBigStage"
            show={showReportBigStageModal}
            id={selectedBigStage.id}
            title={"Report BigStage - " + selectedBigStage.name}
            currentUserId={
              currentUserInfo !== undefined ? currentUserInfo.id : 0
            }
            handleClose={() => {
              setShowReportBigStageModal(false);
            }}
            inputText={reportBigStageInputText}
            setInputText={(val) => {
              setReportBigStageInputText(val);
            }}
            isComment={false}
            setUpdate={() => { 
              //console.log("DONE"); 
            }}
          />
        )}
      </section>
    </div>
  );
}

export default BigStage;
