import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getDataApi, postDataApi } from "../../utility/apiCall";
import { constant } from "../../utility/constant";
import homeBg from "../../assets/Images/homeBg.png";
import {
  addErrorMesage,
  addSuccessMesage,
} from "../CustomToster/CustomToaster";
import { addLoader, removeLoader } from "../../utility/CustomLoader";

export default function ForgotPassword() {
  const [emailErr, setEmailErr] = useState(false);
  const [emailErrMsg, setEmailErrMsg] = useState("");
  const [inputVal, setInputVal] = useState({
    email: "",
  });
  var history = useHistory();
  useEffect(() => {
    if (history.action === "POP") {
      return history.push("/login");
    }
  }, []);
  const getInputVal = (e) => {
    let text = e.target.value;
    let name = e.target.name;
    setInputVal({ ...inputVal, [name]: text });
    if (name === "email") setEmailErr(false);
  };

  const onSendOTP = () => {
    const { email } = inputVal;
    const emailReg =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let flag = false;

    if (!email) {
      setEmailErr(true);
      setEmailErrMsg("Enter email");
      flag = false;
    } else if (!emailReg.test(email)) {
      setEmailErr(true);
      setEmailErrMsg("Invalid email address");
      flag = false;
    } else {
      flag = true;
      addLoader();
      verifyEmailAndSendOTP();
    }
  };
  const verifyEmailAndSendOTP = async () => {
    //check if user email exists
    let allowToSendOTP = false;
    let respUser = await getDataApi(
      constant.baseURL + "user?filter=email||$eq||" + inputVal.email
    );
    if (respUser !== undefined && respUser.length > 0) {
      allowToSendOTP = true;
    } else {
      removeLoader();
      setEmailErr(true);
      //console.log("Email Id Already Exists.");
      addErrorMesage("Please Enter Correct Email Id!");
    }
    if (allowToSendOTP == true) {
      let jsonData0 = {
        email: inputVal.email,
      };
      //console.log(jsonData0);
      localStorage.setItem("userEmail", inputVal.email);
      localStorage.setItem("createAccountData", JSON.stringify(jsonData0));

      let otpCode = Math.floor(10000 + Math.random() * 90000);
      let jsonData = {
        email: inputVal.email,
        code: otpCode,
        subject: "OTP For Email Verification",
        body:
          "You requested for a Open Your account, kindly use this code <b>" +
          otpCode +
          "</b> to Open your account.",
      };
      //console.log(jsonData);
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let responseOTP = await postDataApi(
        constant.baseURL + "user/verifyEmailAndSendOTP",
        jsonData,
        authHeader
      );
      //console.log("responseOTP: ", responseOTP);
      if (
        responseOTP !== undefined &&
        responseOTP.data !== undefined &&
        responseOTP.data.status !== undefined &&
        responseOTP.data.status === true
      ) {
        localStorage.setItem("OTPDetails", JSON.stringify(responseOTP));
        localStorage.setItem("userId", respUser[0].id);
        localStorage.setItem("fromComponent", "Login");
        removeLoader();
        addSuccessMesage("OTP Sent Successfully");
        //addSuccessMessage("OTP Sent Successfully");
        //removeLoader();
        history.push("otpverification");
        // dispatch(showHomePageRightCont('OTPVerification'));
      } else {
        removeLoader();
        //console.log("Please Not");
        //unable to send otp code
        addErrorMesage("Unable to validate email!");

        // enqueueSnackbar('Unable to validate email!', {
        //     variant: 'error', anchorOrigin: {
        //         vertical: 'top',
        //         horizontal: 'right',
        //     },
        // });
      }
    }
  };
  return (
    <section className="login-page">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-6 d-none d-xl-block">
            <div className="login-bg">
              <img src={homeBg} className="img-fluid" alt="" />
            </div>
          </div>
          <div className="col-xl-6">
            <div className="Forget-page">
              <div className="row justify-content-center align-items-center">
                <div className="col-12">
                  <div className="title">Forgot Password</div>
                  <div className="login-sub-title">
                    Please type your Email below and we will send you OTP to
                    help you reset password
                  </div>
                  <div className="username mt-20">
                    <div className="floating-label">
                      <input
                        className="floating-input"
                        type="text"
                        placeholder=" "
                        onChange={getInputVal}
                        name="email"
                        value={inputVal.email}
                        required={true}
                      />
                      <span className="highlight"></span>
                      <label className="label">Email</label>
                    </div>
                    {emailErr && <div className="errorText">{emailErrMsg}</div>}
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <button
                        className="btn btn-color w100 btnPadding"
                        onClick={() => onSendOTP()}
                      >
                        Send
                      </button>
                    </div>

                    <div className="col-12 option-text">
                      Back to{" "}
                      <span
                        className="login-highligh"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          history.push("login");
                        }}
                      >
                        Login
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
