import dotenv from  'dotenv'
// // const url = 'http://192.168.29.211:3000/';
// const prod = "https://api.multimusicmoguls.com/";
// const dev = "http://45.80.152.23:4527/";
const cdnURL = process.env.REACT_APP_CDN_URL;//"https://cdn.multimusicmoguls.com/";
const url = process.env.REACT_APP_API_BASE_URL;//process.env.REACT_APP_NODE_ENV === "development" ? dev : prod;
const isAWS = process.env.REACT_APP_ISAWS === "Yes" ? true : false;

// console.log("constants - process.env.REACT_APP_ISAWS: ", process.env.REACT_APP_ISAWS)
// console.log("constants - isAWS: ", isAWS)

const siteURL = process.env.REACT_APP_SITEURL; //Hitendra

export const constant = {
  baseURL: url, //process.env.REACT_APP_NODE_ENV === "development" ? dev : prod,
  isAws: isAWS,
  siteURL: siteURL, //Hitendra
  // uplFileParameter: isAWS ? 'image' : 'file',
  uplFileParameter: 'file',
  musicFileUploadURL: isAWS ? url + 'awsmusicfiles' : url + 'musicfiles/uploadMultipleFiles',
  podcastFileUploadURL: isAWS ? url + 'awspodcastfiles' : url + 'podcastfiles/uploadMultipleFiles',
  podcastepisodeFileUploadURL: isAWS ? url + 'awsepisodefiles' : url + 'episodefiles/uploadMultipleFiles',
  newsFileUplodaURL: isAWS ? url+'awsnewsfiles' : url+'newsfiles',
  eventsFileUploadURL: isAWS ? url+'awseventsfiles' : url+'eventsfiles',
  profileFileUploadURL: isAWS ? url+'awsprofilefiles' : url+'profilefiles',
  albumFileUploadURL: isAWS ? url+'awsalbumfiles' : url+'albumfiles',


  downloadURL: url + "files/",
  userImageURL: isAWS ? cdnURL + "uploads/profile/user.png" : url + "files/user.png",
  artistImageURL: isAWS ? cdnURL + "uploads/profile/artist.png" : url + "files/artist.png",
  musicURL: isAWS ? cdnURL + "uploads/music.png" : url + "files/music.png",
  videoURL: isAWS ? cdnURL + "uploads/video.png" : url + "files/video.png",
  profileURL: isAWS ? cdnURL + "uploads/profile/" : url + "profilefiles/",
  bigStageThumbnailURL: isAWS ? cdnURL + "uploads/bigstage/thumbnails/" : url + "bigstagethumbnailfiles/",
  bigStageVideoURL: isAWS ? cdnURL + "uploads/bigstage/videos/" : url + "bigstagevideofiles/",
  albumFileURL: isAWS ? cdnURL + "uploads/album/" : url + "albumfiles/",
  musicFileURL: isAWS ? cdnURL + "uploads/music/" : url + "musicfiles/",
  podcastFileURL: isAWS ? cdnURL + "uploads/podcast/" : url + "podcastfiles/",
  podcastcategorythumbnailFileURL: isAWS ? cdnURL + "uploads/subcategory/" : url + "subcategoryfiles/",
  podcastepisodeFileURL: isAWS ? cdnURL + "uploads/episode/" : url + "episodefiles/",
  newsFileURL: isAWS ? cdnURL + "uploads/news/" : url + "newsfiles/",
  podcastCategoryURL: isAWS ? cdnURL + "uploads/podcastcategory.png" : url + "files/podcastcategory.png",
  eventsFileURL: isAWS ? cdnURL + "uploads/events/" : url + "eventsfiles/",
  

  appStoreURL: "https://apps.apple.com/us/app/multimusicmoguls/id1627684377",
  playStoreURL: "https://play.google.com/store/apps/details?id=com.mmm.app",
  
  //static image path
  loginBackgroundImageURL: cdnURL + "uploads/images/loginbackground.png",
  
  validateToken: url + "auth/validateToken",
  updateEmail: url + "user/updateUserEmailAddress/",
  // satyam Start 16/09/2021
  deletePodcast: url + "podcasts/deletePodcast",
  deleteNews: url + "news/deleteNews",
  deleteAlbum: url + "albums/deleteAlbum",
  deletePlaylist: url + "playlists/deletePlaylists",
  deleteEvent: url + "events/deleteEvents",
  deleteEpisode: url + "episode/deleteEpisode",
  // satyam End 16/09/2021
  facebookURL: "https://www.facebook.com/",
  instagramURL: "https://www.instagram.com/",
  twitterURL: "https://twitter.com/",

  acceptedAudio:
    "audio/opus,audio/flac,audio/webm,audio/weba,audio/wav,audio/ogg,audio/m4a,audio/mp3,audio/oga,audio/mid,audio/amr,audio/aiff,audio/wma,audio/au,audio/aac,audio/mp4,audio/avi,audio/mov,audio/*",
  acceptedVideo:
    "video/mp3,video/mpeg,video/ogm,video/mpg,video/wmv,video/webm,video/mp4,video/avi,video/mov,video/x-m4v,video/*",
  supportedImageFiles: [".jpg", ".jpeg", ".png"],
  supportedVideoFiles: [
    ".ogm",
    ".wmv",
    ".mpg",
    ".webm",
    ".ogv",
    ".mov",
    ".asx",
    ".mpeg",
    ".mp4",
    ".m4v",
    ".avi",
  ],
  supportedAudioFiles: [
    ".opus",
    ".flac",
    ".webm",
    ".weba",
    ".wav",
    ".ogg",
    ".m4a",
    ".mp3",
    ".oga",
    ".mid",
    ".amr",
    ".aiff",
    ".wma",
    ".au",
    ".aac",
    ".mp4",
    ".avi",
    ".mov",
  ],
  supportedAudioVideoFiles: [
    ".opus",
    ".flac",
    ".weba",
    ".oga",
    ".mid",
    ".amr",
    ".aiff",
    ".au",
    ".ogm",
    ".wmv",
    ".mpg",
    ".webm",
    ".ogv",
    ".mp4",
    ".mp3",
    ".avi",
    ".mov",
    ".wav",
    ".wma",
    ".aac",
    ".ogg",
    ".flv",
    ".m4v",
    ".m4p",
  ],

  //Messages
  passwordReg: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/,
  createAccountTitle: "Please tell us what you want to be.",
  loginTitle: "Welcome",
  loginSubtitle: "Enter your email and password to play your music",
  invalidCredentials: "Invalid Credentials",
  invalidEmailAddress: "Invalid email",
  emailRequired: "Email Required",
  passwordRequired: "Password Required",
  firstNameRequired: "FirstName Required",
  lastNameRequired: "LastName Required",
  passwordValidationMsg: "Password must be at least 8 characters long, contain at least one special character, number and have a mixture of uppercase and lowercase letters.",
    //"Password should be min-8, max-20 characters long including(digits, special characters)",
  passwordShouldSame: "Password should be same",
  emailRegistered: "Email is already registered",
  otpSubject: "OTP For Email Verification",
  unableToValidateEmail: "Unable to validate email",
  createAnAccountTitle: "Create An Account",
  createAnAccountSubtitle: "Join us and Listen to your favourite music",
  tickOnTermCondition: "Please Tick on Terms and Condition",
  onlyAlphanumericAllowed: "Only alphanumeric chars are allowed",
  invalidText: "Invalid Text",
  phoneRequired: "Phone Required",
  titleRequired: "Title Required",
  biographyRequired: "Biography Required",
  streetRequired: "Street Required",
  cityRequired: "City Required",
  stateRequired: "State Required",
  countryRequired: "Country Required",
  phoneLength: "Phone should be 10 chars long.",
  titleLength: "Title should be 255 chars long",
  biographyLength: "Biography should be 255 chars long",
  streetLength: "Street should be 255 chars long",
  typeRequired: "Type Required",
  categoryRequired: "Category Required",
  completeProfileTitle: "Complete Profile",
  completedProfileSubtitle:
    "Please fill below details to complete your profile.",
  invalidURL: "Invalid URL. (type url like http://www.sample.com)", //Invalid URL. (Start URL with http:// or  https://)
  currentPasswordRequired: "Current password required",
  newPasswordRequired: "New password required",
  confirmPasswordRequired: "Confirm password required",
  currentPasswordValidation:"Your password must be at least 8 characters long, contain at least one special character, number and have a mixture of uppercase and lowercase letters.",
    //"Your password must be at least 8 characters long, contain at least one special character(excluding @), number and have a mixture of uppercase and lowercase letters.",
  newConfirmPasswordSame: "New and confirm password should be same",
  enterCorrectCurrentPwd: "Please enter correct current password",
  passwordChanged: "Password Changed",
  notificationUpdated: "Notification Updated",
  profileUpdated: "Profile Updated",
  errorWhileProfileUpdate: "Error in profile update",
  addressRequired: "Address Required",
  emailUpdated: "Email Updated",
  commonUserNameRequired:"User Name Required",
  onlyNumbersAllowed: "Only Numbers Allowed",
  mic: "Mic",
  unmic: "Unmic",

  myMusicLabel: "My Music",
  myPlaylistsLabel: "My Playlists",
  myPodcastsLabel: "My Podcasts",
  myNewsLabel: "My News",
  myEventsLabel: "My Events",
  profileLabel: "Profile",
  logoutLabel: "Logout",
  loginLabel: "Login",
  homeLabel: "Home",
  supportLabel: "Support",
  aboutusLabel: "About us",
  privacyLabel: "Privacy Policy",
  theBigStageLabel: "The Big Stage",
  communityRoomLabel: "Community Room",
  featuredArtistLabel: "Featured Artist",
  uploadMusicLabel: "Upload Music",
  podcastLabel: "Podcast",
  newsLabel: "News",
  eventsLabel: "Events",
  requiredLabel: "Required",
  showMoreLabel: "Show More",
  noDataFoundLabel: "Coming Soon...",
  profilePictureRequiredLabel: "Profile picture required",
  youTubeSubscribeURL: "https://m.youtube.com/channel/UC6xoVqjS4cw-XpSEQl-ydig?sub_confirmation=1",
};
