import React, { useState, useEffect } from "react";
import {
  Link,
  NavLink,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import moment from "moment";
import { constant } from "../../utility/constant";
import { getDataApi } from "../../utility/apiCall";
import { Modal, ModalBody, Button } from "react-bootstrap";
import twitter from "../../assets/Images/twitter.png";
import social from "../../assets/Images/social.png";
import facebook from "../../assets/Images/facebook.png";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  InstapaperShareButton,
  InstapaperIcon,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";
import { hashCodeUrlDecode ,hashCodeUrl} from "../../utility/EncodeAndDecodeUrl";
import { addSuccessMesage } from "../CustomToster/CustomToaster";
import { BiLinkAlt } from "react-icons/bi";
import { propTypes } from "react-bootstrap/esm/Image";

const ShareModal = ({ show, handleClose, title, id, currentUserId, from,flag,uniqeid }) => {
  let location = useLocation();
  let searchData = useParams();
  const [shareUrl, setshareUrl] = useState(null)
  const [desc, setDesc] = useState("");
  const getArtistDetails = async (id) => {
    let resArtist = await getDataApi(
      constant.baseURL + "user/" + hashCodeUrlDecode(searchData.itemId)
    );
    if (resArtist !== undefined) {
      setDesc(
        resArtist.biography !== undefined && resArtist.biography !== null
          ? resArtist.biography
          : ""
      );
    }
    //console.log(window.location.href)
    setshareUrl(window.location.href)
  };
  const getEventDetails = async () => {
    let resEvent = await getDataApi(
      constant.baseURL + "events/" + hashCodeUrlDecode(searchData.itemId)
    );
    //console.log(resEvent)
    if (resEvent !== undefined) {
      setDesc(
        resEvent.name !== undefined && resEvent.name !== null
          ? resEvent.name
          : ""
      );
    }
    if(flag=="0"){
      // http://localhost:4535/EventsDetails/MTU4
      // https://multimusicmoguls.com/EventsDetails/MzQ
      //console.log(uniqeid)
      setshareUrl(`https://multimusicmoguls.com/EventsDetails/${hashCodeUrl(uniqeid)}`)
    }else{
      setshareUrl(window.location.href)
    }
  };
  const getNewsDetails = async () => {
    let resNews = await getDataApi(
      constant.baseURL + "news/" + hashCodeUrlDecode(searchData.itemId)
    );
    if (resNews !== undefined) {
      setDesc(
        resNews.title !== undefined && resNews.title !== null
          ? resNews.title
          : ""
      );
    }
    setshareUrl(window.location.href)
  };
  const getPodcastDeatils = async () => {
    let resPodcast = await getDataApi(
      constant.baseURL + "podcasts/" + hashCodeUrlDecode(searchData.itemId)
    );
    if (resPodcast != undefined) {
      setDesc(
        resPodcast.name !== undefined &&
          resPodcast.name !== null &&
          resPodcast.name !== ""
          ? resPodcast.name
          : ""
      );
    }
    setshareUrl(window.location.href)
  };
  const getAlbumDeatils = async () => {

    let musicData = await getDataApi(constant.baseURL+"musics/"+id);
    //console.log("--musicData--", musicData);

    let resAlbumData = await getDataApi(
      //constant.baseURL + "albums/" + hashCodeUrlDecode(searchData.albumId)
      constant.baseURL + "albums/" + musicData.album.id
    );
    //console.log("resAlbumData: ", resAlbumData);
    if (resAlbumData !== undefined) {
      setDesc(
        resAlbumData.name !== undefined &&
          resAlbumData.name !== null &&
          resAlbumData.name !== ""
          ? resAlbumData.name + " #Type: " + resAlbumData.albumType
          : ""
      );
      setshareUrl(`${constant.siteURL}FeaturedArtistDetails/${hashCodeUrl(resAlbumData.user.id)}/${hashCodeUrl(resAlbumData.id)}`);
    }
    else{
      setshareUrl(window.location.href)
    }
  };
  const copylink = () => {
    // const el = shareUrl
    // el.select()
    // document.execCommand("copy")
    var textField = document.createElement('textarea')
    textField.innerText = shareUrl
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    addSuccessMesage("Link Copy Successfully")

  }
  useEffect(() => {
    if (from === "FeatureArtistDetails") {
      getArtistDetails();
    } else if (from === "Events" || from === "EventsDetails") {
      getEventDetails();
    } else if (from === "News" || from === "NewsDetails") {
      getNewsDetails();
    } else if (from === "Music") {
      getAlbumDeatils();
    } else if (from === "PodcastDetails") {
      getPodcastDeatils();
    } else if (from === "Episode") {
      setshareUrl(window.location.href)
    }
  }, []);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop={"static"}
        keyboard={false}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#313131", border: 0 }}
        >
          <Modal.Title id="example-custom-modal-styling-title">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#313131" }}>
          <div className="row justify-content-center align-items-center mb-5">
            {/* <div className="col-auto">
              <img src={facebook} alt="Facebook" />
            </div> */}
            <BiLinkAlt className='btn-pad-10 cursor-pointer' size={32} round={true} onClick={() => copylink()} />
            <FacebookShareButton

              url={shareUrl}
              quote={
                desc !== undefined ? hashCodeUrlDecode(desc) : ""
                // searchData.description !== undefined
                //   ? hashCodeUrlDecode(searchData.description)
                //   : ""
              }
              hashtag="#MultiMusicMoguls"
            >
              <FacebookIcon className='btn-pad-10 cursor-pointer' size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton
              url={shareUrl}
              title={
                desc !== undefined ? hashCodeUrlDecode(desc) : ""
                // searchData.description !== undefined
                //   ? hashCodeUrlDecode(searchData.description)
                //   : ""
              }
              // title={"hello World"}
              hashtag="#MultiMusicMoguls"
            >
              <TwitterIcon className='btn-pad-10 cursor-pointer' size={32} round={true} />
            </TwitterShareButton>
            <WhatsappShareButton

              url={shareUrl}

              // title={"hello World"}
              hashtag="#MultiMusicMoguls"
            >

              <WhatsappIcon className='btn-pad-10 cursor-pointer' size={32} round={true} />
            </WhatsappShareButton>
            {/* <InstapaperShareButton
              url={shareUrl}
              title={
                searchData.description !== undefined
                  ? hashCodeUrlDecode(searchData.description)
                  : ""
              }
              // title={"hello World"}
              hashtag="#MultiMusicMoguls"
            >
              <InstapaperIcon size={32} round={true} />
            </InstapaperShareButton> */}
            {/* <div className="col-auto">
              <img src={twitter} alt="Twitter" />
            </div> */}
            {/* <div className="col-auto">
              <img src={social} alt="Social" />
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ShareModal;
