import React, { useState, useEffect, useRef } from "react";
import homeBg from "../../assets/Images/homeBg.png";
import { constant } from "../../utility/constant";
import { getDataApi, patchDataApi, postDataApi } from "../../utility/apiCall";
import { colors } from "../../utility/colors";
import Multiselect from "multiselect-react-dropdown";
import { useHistory } from "react-router-dom";
import {
  addErrorMesage,
  addSuccessMesage,
} from "../CustomToster/CustomToaster";
import { AiFillCloseSquare } from "react-icons/ai";
import { addLoader, removeLoader } from "../../utility/CustomLoader";
import validator from "validator";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const CompleteProfile = () => {
  var history = useHistory();
  //console.log("history", history);
  const multiSelectStyle = {
    multiselectContainer: {
      color: "white",
      fontSize: 14,
    },
    option: {
      // To change css for dropdown options
      color: colors.colors13,
    },
    chips: {
      background: colors.colors13,
      fontSize: 15,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 8,
      paddingBottom: 8,
      borderRadius: 8,
    },
    searchBox: {
      border: "1px solid #8186AF",
      borderRadius: "2px",
      fontSize: 15,
      minHeight: 40,
    },
  };
  const [showProfile1, setShowProfile1] = useState(true);
  const [showProfile2, setShowProfile2] = useState(false);
  let newUser;
  if (localStorage.getItem("newUser") !== null) {
    newUser = JSON.parse(localStorage.getItem("newUser"));
  }
  const [inputVal, setInputVal] = useState({
    // phoneNo: "",
    // street: "",
    city: "",
    state: "",
    country: "",
    // title: "",
    biography: "",
    facebookUrl: "",
    youtubeUrl: "",
    instagramUrl: "",
    ituneUrl: "",
    spotifyUrl: "",
    tiktokUrl: "",
    appleUrl: "",
    applePodcastURL:"",
  });
  const [selectedTypes, setTypes] = useState(false);
  const [selectedCategory, setCategory] = useState(false);
  const [typesErr, setTypesErr] = useState(false);
  const [categoryErr, setCategoryErr] = useState(false);
  // const [phoneNoErr, setPhoneNoErr] = useState(false);
  // const [titleErr, setTitleErr] = useState(false);
  const [biographyErr, setBiographyErr] = useState(false);
  // const [streetErr, setStreetErr] = useState(false);
  const [cityErr, setCityErr] = useState(false);
  const [stateErr, setStateErr] = useState(false);
  const [countryErr, setCountryErr] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [states, setStates] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [cities, setCities] = useState([]);
  const [facebookErr, setFacebookErr] = useState(false);
  const [TwitterErr, setTwitterErr] = useState(false);
  const [InstagramErr, setInstagramErr] = useState(false);
  var [ErrorMsg, setErrorMsg] = useState({
    // phoneErrorMsg: null,
    // titleErrorMsg: null,
    CityErrorMsg: null,
    // streetErrMsg: null,
    stateErrMsg: null,
    countryErrMsg: null,
    biographyErrMsg: null,
    typesErrMsg: null,
    categoryErrMsg: null,
    facebookUrlErrMsg: null,
    youtubeUrlErrMsg: null,
    ituneUrlErrMsg: null,
    spotifyUrlErrMsg: null,
    tiktokUrlErrMsg: null,
    appleUrlErrMsg: null,
    applePodcastURLErrMsg:null,
    instagramUrlErrMsg: null,
  });
  const onNext = () => {
    const textRegex =
      /^[/\a-zA-Z0-9\s\[\]\{\}\.\'\`\~\!\@\$\^\&\(\)\_\+\-\=\;\,]*$/;
    const {
      // phoneNo,
      //  street,
      // city,
      // state,
      // country,
      // title,
      biography,
    } = inputVal;
    // let phonenoReg = /^[789]\d{9}$/;
    let typeCondition = !selectedTypes || selectedTypes.length === 0;
    let catCondition = !selectedCategory || selectedCategory.length === 0;

    if (
      // !phoneNo &&
      // !street &&
      //!city &&
      //!state &&
      //!country &&
      // !title &&
      !biography &&
      !typeCondition &&
      !catCondition
    ) {
      setTypesErr(true);
      setCategoryErr(true);
      setBiographyErr(true);
      setErrorMsg({
        //   phoneErrorMsg: constant.phoneRequired,
        //   titleErrorMsg: constant.titleRequired,
        biographyErrMsg: constant.biographyRequired,
        // streetErrMsg: constant.streetRequired,
        //CityErrorMsg: constant.cityRequired,
        //stateErrMsg: constant.stateRequired,
        //countryErrMsg: constant.countryRequired,
      });

      setErrorMsg({ typesErrMsg: constant.typeRequired });
      setErrorMsg({ categoryErrMsg: constant.categoryRequired });
      // setStreetErr(true);
      // setCityErr(true);
      // setStateErr(true);
      // setCountryErr(true);
    } else {
      // if (!phoneNo) {
      //   setPhoneNoErr(true);
      //   setErrorMsg({ phoneErrorMsg: constant.phoneRequired });
      // } else if (phoneNo.length !== 10) {
      //   setPhoneNoErr(true);
      //   setErrorMsg({ phoneErrorMsg: constant.phoneLength });
      // } else if (!title) {
      //   setTitleErr(true);
      //   setErrorMsg({ titleErrorMsg: constant.titleRequired });
      // }
      //  else if (title.length > 255) {
      //   setBiographyErr(true);
      //   setErrorMsg({ titleErrorMsg: constant.titleLength });

      if (!biography) {
        setBiographyErr(true);
        setErrorMsg({ biographyErrMsg: constant.biographyRequired });
      } else if (biography.length > 255) {
        setBiographyErr(true);
        setErrorMsg({ biographyErrMsg: constant.biographyLength });
      } else if (textRegex.test(biography) === false) {
        setBiographyErr(true);
        setErrorMsg({ biographyErrMsg: constant.invalidText });
      } else if (typeCondition) {
        setTypesErr(true);
        setErrorMsg({ typesErrMsg: constant.typeRequired });
      } else if (catCondition) {
        setCategoryErr(true);
        setErrorMsg({ categoryErrMsg: constant.categoryRequired });
      }
      //  else if (textRegex.test(title) === false) {
      //   setTitleErr(true);
      //   setErrorMsg({ titleErrorMsg: constant.invalidText });
      // } else if (!biography) {
      //   setBiographyErr(true);
      //   setErrorMsg({ biographyErrMsg: constant.biographyRequired });
      // } else if (biography.length > 255) {
      //   setBiographyErr(true);
      //   setErrorMsg({ biographyErrMsg: constant.biographyLength });
      // } else if (textRegex.test(biography) === false) {
      //   setBiographyErr(true);
      //   setErrorMsg({ biographyErrMsg: constant.invalidText });
      // }
      //  else if (!street) {
      //   setStreetErr(true);
      //   setErrorMsg({ streetErrMsg: constant.streetRequired });

      // } else if (street.length > 255) {
      //   setStreetErr(true);
      //   setErrorMsg({ streetErrMsg: constant.streetLength });
      // }
      //  else if (textRegex.test(street) === false) {
      //   setStreetErr(true);
      //   setErrorMsg({ streetErrMsg: constant.invalidText });
      // }
      // else if (selectedCity === "") {
      //   setCityErr(true);
      //   setErrorMsg({ CityErrorMsg: constant.cityRequired });
      // } else if (selectedState === "") {
      //   setStateErr(true);
      //   setErrorMsg({ stateErrMsg: constant.stateRequired });
      // } else if (selectedCountry === "") {
      //   setCountryErr(true);
      //   setErrorMsg({ countryErrMsg: constant.countryRequired });
      // }
      else {
        setShowProfile1(false);
        setShowProfile2(true);
      }
    }
  };
  const onBack = () => {
    setShowProfile1(true);
    setShowProfile2(false);
  };

  const getInputVal = (e) => {
    let text = e.target.value;
    let name = e.target.name;
    // let phonenoReg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (name === "biography") {
      setBiographyErr(false);
      setInputVal({ ...inputVal, [name]: text });
      if (!text.replace(/\s/g, "").length) {
        setInputVal({ ...inputVal, [name]: text.trim() });
      }
    } else {
      setInputVal({ ...inputVal, [name]: text });
      // if (name === "title") {
      //   setTitleErr(false);
      //   if (!text.replace(/\s/g, "").length) {
      //     setInputVal({ ...inputVal, [name]: text.trim() });
      //   }
      // }
      if (name === "facebookUrl") {
        if (!text.replace(/\s/g, "").length) {
          setInputVal({ ...inputVal, [name]: text.trim() });
        }
      }
      if (name === "youtubeUrl") {
        if (!text.replace(/\s/g, "").length) {
          setInputVal({ ...inputVal, [name]: text.trim() });
        }
      }
      if (name === "ituneUrl") {
        if (!text.replace(/\s/g, "").length) {
          setInputVal({ ...inputVal, [name]: text.trim() });
        }
      }if (name === "spotifyUrl") {
        if (!text.replace(/\s/g, "").length) {
          setInputVal({ ...inputVal, [name]: text.trim() });
        }
      }if (name === "tiktokUrl") {
        if (!text.replace(/\s/g, "").length) {
          setInputVal({ ...inputVal, [name]: text.trim() });
        }
      }if (name === "appleUrl") {
        if (!text.replace(/\s/g, "").length) {
          setInputVal({ ...inputVal, [name]: text.trim() });
        }
      }
      if (name === "applePodcastURL") {
        if (!text.replace(/\s/g, "").length) {
          setInputVal({ ...inputVal, [name]: text.trim() });
        }
      }
      if (name === "instagramUrl") {
        if (!text.replace(/\s/g, "").length) {
          setInputVal({ ...inputVal, [name]: text.trim() });
        }
      }

      // if (name === "street") {
      //   setStreetErr(false);
      //   if (!text.replace(/\s/g, "").length) {
      //     setInputVal({ ...inputVal, [name]: text.trim() });
      //   }
      // }
      if (name === "city") {
        setCityErr(false);
        if (!text.replace(/\s/g, "").length) {
          setInputVal({ ...inputVal, [name]: text.trim() });
        }
      }
      if (name === "state") {
        setStateErr(false);
        if (!text.replace(/\s/g, "").length) {
          setInputVal({ ...inputVal, [name]: text.trim() });
        }
      }
      if (name === "country") {
        setSelectedCountry(text);
        let sCountry = countries.filter((f) => f.name === text);
        if (sCountry.length > 0) {
          getStates(sCountry[0].id);
          setCities([]);
        }
      }
      if (name === "state") {
        setSelectedState(text);
        let sState = states.filter((f) => f.name === text);
        if (sState.length > 0) {
          getCities(sState[0].id);
        }
      }
      if (name === "city") {
        setSelectedCity(text);
      }
    }
  };
  const onSelectType = (selectValArr) => {
    setTypes(selectValArr);
    setTypesErr(false);
    loadSubcategoriesByCategory(selectValArr);
  };
  // changed By Hitebdra Sir
  // const onRemoveSelectedType = (removedValArr) => {
  //   setTypes(removedValArr);
  //   // console.log(removedArr)
  //   loadSubcategoriesByCategory(removedValArr);
  // };
  const onRemoveSelectedType = (removedValArr) => {
    setTypes(removedValArr);
    loadSubcategoriesByCategory(removedValArr);
    //remove category if type is removed
    for (var i = 0; i < selectedCategory.length; i++) {
      let flt = removedValArr.filter(
        (f) => f.id === selectedCategory[i].category.id
      );
      if (flt.length === 0) {
        selectedCategory.splice(i, 1);
        i--;
      }
    }
  };
  const loadSubcategoriesByCategory = (arrCategories) => {
    if (arrCategories.length > 0) {
      getSubcategories(arrCategories);
    } else {
      setSubcategories([]);
    }
  };
  const onSelectCat = (selectValArr) => {
    setCategory(selectValArr);
    setCategoryErr(false);
  };
  const onRemoveSelectedCat = (removedValArr) => {
    setCategory(removedValArr);
  };
  const onCompleteProfile = async () => {
    const urlRegex =
      /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
    const { phoneNo, street, city, state, country, title, biography } =
      inputVal;

    // let typeCondition = !selectedTypes || selectedTypes.length === 0;
    // let catCondition = !selectedCategory || selectedCategory.length === 0;
    if (!city && !state && !country) {
      setCityErr(true);
      setStateErr(true);
      setCountryErr(true);
      setErrorMsg({
        CityErrorMsg: constant.cityRequired,
        stateErrMsg: constant.stateRequired,
        countryErrMsg: constant.countryRequired,
      });
    } else {
      if (selectedCity === "") {
        setCityErr(true);
        setErrorMsg({ CityErrorMsg: constant.cityRequired });
      } else if (selectedState === "") {
        setStateErr(true);
        setErrorMsg({ stateErrMsg: constant.stateRequired });
      } else if (selectedCountry === "") {
        setCountryErr(true);
        setErrorMsg({ countryErrMsg: constant.countryRequired });
      }
      // else if (typeCondition) {
      //   setTypesErr(true);
      //   setErrorMsg({ typesErrMsg: constant.typeRequired });
      // } else if (catCondition) {
      //   setCategoryErr(true);
      //   setErrorMsg({ categoryErrMsg: constant.categoryRequired });
      // }
      // else if (inputVal.facebookUrl !== "" && urlRegex.test(inputVal.facebookUrl) === false) {
      //   setFacebookErr(true);
      //   setErrorMsg({ facebookUrlErrMsg: constant.invalidURL });
      // } else if (inputVal.instagramUrl !== "" && urlRegex.test(inputVal.instagramUrl) === false) {
      //   setInstagramErr(true);
      //   setErrorMsg({ instagramUrlErrMsg: constant.invalidURL });
      // } else if (inputVal.twitterUrl !== "" && urlRegex.test(inputVal.twitterUrl) === false) {
      //   setTwitterErr(true);
      //   setErrorMsg({ twitterUrlErrMsg: constant.invalidURL });
      // }
      else {
        //console.log("complete profile newUser: ", newUser);
        if (newUser !== undefined && newUser.length !== 0) {
          let fltCountries = countries.filter(
            (c) => c.name === selectedCountry
          );
          let fltStates = states.filter((c) => c.name === selectedState);
          let fltCities = cities.filter((c) => c.name === selectedCity);
          if (
            fltCountries.length > 0 &&
            fltCities.length > 0 &&
            fltStates.length > 0
          ) {
            let jsonData = {
              fullAddress: inputVal.street,
              countryId: fltCountries[0].id,
              stateId: fltStates[0].id,
              cityId: fltCities[0].id,
              biography: inputVal.biography,
              selectedCategory: selectedCategory,
              subcategories: subcategories,
              instagramURL: inputVal.instagramUrl,
              youtubeURL: inputVal.youtubeUrl,
              iTunesURL: inputVal.ituneUrl,
              spotifyURL: inputVal.spotifyUrl,
              tikTokURL: inputVal.tiktokUrl,
              appleMusicURL: inputVal.appleUrl,
              applePodcastURL: inputVal.applePodcastURL,
              facebookURL: inputVal.facebookUrl,
            };
            //console.log(jsonData);
            var formBody = [];
            for (var property in jsonData) {
              var encodedKey = encodeURIComponent(property);
              var encodedValue = encodeURIComponent(jsonData[property]);
              formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
            var authHeader = {
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded",
            };
            // console.log(
            //   constant.baseURL + "user/completeProfile/" + newUser.id
            // );
            addLoader();
            let responseUser = await patchDataApi(
              constant.baseURL + "user/completeProfile/" + newUser.id,
              formBody,
              authHeader
            );
            //console.log(responseUser);
            if (
              responseUser !== undefined &&
              responseUser.status !== undefined &&
              responseUser.status !== 201
            ) {
              //console.log("New User: ", responseUser);
              if (
                responseUser.data !== undefined &&
                responseUser.data.id !== undefined
              ) {
                return setSelectedTypesAndCategoriesForUser(
                  responseUser.data
                ).then((response) => {
                  if (
                    response !== undefined &&
                    response.length > 0 &&
                    response[0] === true
                  ) {
                    ////Allow artist to create album - Hitendra

                    // localStorage.clear();
                    // removeLoader();
                    // window.open("/login", "_self");
                    
                    ////Allow artist to create album - Hitendra

                    removeLoader();
                    addSuccessMesage("Account Created");
                    //console.log("Account Created");

                    //Hitendra
                    history.push({
                      pathname: `firstAlbumMessage`,
                      state: {
                        from: "CompleteProfile",
                      },
                    });
                    //Hitendra
                  } else {
                    removeLoader();
                    localStorage.clear();
                    window.open("/login", "_self");
                  }
                });
              } else {
                removeLoader();
                window.open("/login", "_self");
                localStorage.clear();
              }
            } else {
              removeLoader();
              addErrorMesage("Error encountered while creating account!");
            }
          }
        }
      }
    }
  };
  const setSelectedTypesAndCategoriesForUser = async (objUser) => {
    let promises_array = [];
    selectedCategory.map((catItem) => {
      promises_array.push(
        new Promise((resolve, reject) => {
          let fltCategory = subcategories.filter((f) => f.id == catItem.id);
          if (fltCategory.length > 0) {
            let jsonData = {
              user: objUser.id,
              category: fltCategory[0].category.id,
              subcategory: fltCategory[0].id,
            };
            return callSetSelectedTypesAndCategoriesForUser(jsonData)
              .then(() => {
                resolve(true);
              })
              .catch((error) => {
                reject(false);
              });
          } else {
            resolve(true);
          }
        })
      );
    });
    return Promise.all(promises_array);
  };
  const callSetSelectedTypesAndCategoriesForUser = async (jsonData) => {
    //console.log(jsonData);
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let resExits = await getDataApi(
      constant.baseURL +
      "selectedcategories?filter=category.id||$eq||" +
      jsonData.category +
      "&filter=subcategory.id||$eq||" +
      jsonData.subcategory +
      "&filter=user.id||$eq||" +
      jsonData.user
    );
    //console.log("resExits: ", resExits);
    if (resExits.length == 0) {
      return await Promise.resolve(
        postDataApi(
          constant.baseURL + "selectedcategories",
          formBody,
          authHeader
        )
      )
        .then((response) => {
          if (
            response !== undefined &&
            response.status !== undefined &&
            response.status === 201
          ) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          return false;
        });
    } else {
      return false;
    }
  };
  const getCategories = async () => {
    let resCategories = await getDataApi(
      constant.baseURL + "categories?filter=isDeleted||$eq||0"
    );
    //console.log(resCategories);
    if (resCategories !== undefined && resCategories.length > 0) {
      setCategories([...resCategories]);
    }
  };
  const getSubcategories = async (categories) => {
    let arrCategories = [];
    if (categories.length > 0) {
      categories.map((c) => {
        arrCategories.push(c.id);
      });
    }
    let resSubcategories = await getDataApi(
      constant.baseURL +
      "subcategories?filter=isDeleted||$eq||0&filter=category.id||$in||" +
      arrCategories
    );
    if (resSubcategories !== undefined && resSubcategories.length > 0) {
      setSubcategories([...resSubcategories]);
    }
  };
  const getCountries = async () => {
    let resCountries = await getDataApi(constant.baseURL + "countries/getActiveCountries");
    if (resCountries !== undefined && resCountries.length > 0) {
      // setSelectedCountry(resCountries[0].name);
      setCountries([...resCountries]);
      getStates(resCountries[0].id);
    }
  };
  const getStates = async (countryId) => {
    setStates([]);
    let resStates = await getDataApi(
      constant.baseURL + "states/getActiveStatesByCountry/" + countryId
    );
    if (resStates !== undefined && resStates.length > 0) {
      // setSelectedState(resStates[0].name);
      setStates([...resStates]);
      getCities(resStates[0].id);
    }
  };
  const getCities = async (stateId) => {
    setCities([]);
    let resCities = await getDataApi(
      constant.baseURL + "cities/getActiveCitiesByState/" + stateId
    );
    if (resCities !== undefined && resCities.length > 0) {
      // setSelectedCity(resCities[0].name);
      setCities([...resCities]);
    }
  };

  useEffect(() => {
    // console.log(history.action === "POP");
    if (history.action === "POP") {
      return history.push("/login");
    } else {
      getCategories();
      getCountries();
    }

    // getStates();
    // getCities();
  }, []);
  return (
    <section className="login-page">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-6 d-none d-xl-block">
            {/* <div>satyam</div> */}
            <div className="login-bg">
              <img src={homeBg} className="img-fluid" alt="" />
            </div>
          </div>
          <div className="col-xl-6">
            <div className="login-area">
              <div className="row justify-content-center align-items-center">
                <div className="col-12">
                  <div className="title">{constant.completeProfileTitle}</div>
                  <div className="login-sub-title mb-md-5">
                    {constant.completedProfileSubtitle}
                  </div>
                  {showProfile1 === true && (
                    <div>
                      {/* <div className="username">
                        <div className="floating-label">
                          <input
                            className="floating-input"
                            type="text"
                            placeholder=" "
                            onChange={getInputVal}
                            name="phoneNo"
                            maxLength={10}
                            value={inputVal.phoneNo}
                            required={true}
                          />
                          <span className="highlight"></span>
                          <label className="label">Phone Number</label>
                        </div>
                        {phoneNoErr === true && (
                          <div className="errorText">
                            {ErrorMsg.phoneErrorMsg}
                          </div>
                        )}
                      </div> */}
                      {/* <div className="">
                        <div className="floating-label">
                          <input
                            className="floating-input"
                            type="text"
                            placeholder=" "
                            onChange={getInputVal}
                            onChange={getInputVal}
                            name="title"
                            value={inputVal.title}
                            maxLength={255}
                            required={true}
                          />
                          <span className="highlight"></span>
                          <label className="label">Title</label>
                        </div>
                        {titleErr === true && (
                          <div className="errorText">
                            {ErrorMsg.titleErrorMsg}
                          </div>
                        )}
                      </div> */}
                      <div className="">
                        <div className="floating-label mt-10">
                          <input
                            className="floating-input"
                            type="text"
                            placeholder=" "
                            onChange={getInputVal}
                            name="biography"
                            value={inputVal.biography}
                            required={true}
                            maxLength={255}
                          />
                          <span className="highlight"></span>
                          <label className="label">Bio</label>
                        </div>
                        {biographyErr === true && (
                          <div className="errorText">
                            {ErrorMsg.biographyErrMsg}
                          </div>
                        )}
                      </div>
                      <div className="row">
                        {/* satyam Agarwal start 20/09/21 */}
                        {/* <div className="col-12 col-md-6">
                          <div className="floating-label">
                            <input
                              className="floating-input"
                              type="text"
                              placeholder=" "
                              onChange={getInputVal}
                              name="street"
                              value={inputVal.street}
                              maxLength={255}
                              required={true}
                            />
                            <span className="highlight"></span>
                            <label className="label">Street</label>
                          </div>
                          {streetErr === true && (
                            <div className="errorText">
                              {ErrorMsg.streetErrMsg}
                            </div>
                          )}
                        </div> */}
                        {/* Satyam End 20/09/2021 */}
                        <div className="col-12 col-md-12">
                          <div className="complete-profile">
                            <div className="username">
                              <div className="floating-label0 mt-10">
                                <Multiselect
                                  options={categories}
                                  onSelect={onSelectType}
                                  onRemove={onRemoveSelectedType}
                                  selectedValues={selectedTypes}
                                  displayValue="name"
                                  placeholder="Select Type *"
                                  style={multiSelectStyle}
                                  customCloseIcon={
                                    <AiFillCloseSquare className="multiselect-close-icon" />
                                  }
                                />
                              </div>
                            </div>
                            {typesErr === true && (
                              <div
                                className="errorText"
                                style={{ marginTop: "0px" }}
                              >
                                {ErrorMsg.typesErrMsg}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="complete-profile">
                            <div className="username">
                              <div className="floating-label0">
                                <Multiselect
                                  options={subcategories}
                                  onSelect={onSelectCat}
                                  selectedValues={selectedCategory}
                                  onRemove={onRemoveSelectedCat}
                                  displayValue="name"
                                  placeholder="Select Category *"
                                  style={multiSelectStyle}
                                  customCloseIcon={
                                    <AiFillCloseSquare className="multiselect-close-icon" />
                                  }
                                />
                              </div>
                            </div>
                            {categoryErr === true && (
                              <div
                                className="errorText"
                                style={{ marginTop: "0px" }}
                              >
                                {ErrorMsg.categoryErrMsg}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <button
                            className="btn btn-color w100 btnPadding "
                            style={{ marginTop: "100px" }}
                            onClick={onNext}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {showProfile2 === true && (
                    <div className="">
                      <div className="row mt-4 mt-md-0">
                        <div className="col-12 col-md-12">
                          <div className="complete-profile">
                            <div className="floating-label">
                              <select
                                className="floating-input floating-select1"
                                placeholder="Select Country"
                                name="country"
                                value={selectedCountry}
                                onChange={getInputVal}
                              >
                                <option selected value="Select Country" >Select Country</option>
                                {countries.map((option) => (<>
                                  
                                  <option value={option.name}>
                                    {option.name}
                                  </option>
                                  </>
                                ))}
                                
                              </select>
                              <span className="highlight"></span>
                              <label className="label">Country</label>
                            </div>
                            {countryErr === true && (
                              <div className="errorText">
                                {ErrorMsg.countryErrMsg}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="complete-profile ">
                            <div className="floating-label mt-20">
                              <select
                                className="floating-input floating-select1"
                                placeholder=" "
                                name="state"
                                value={selectedState}
                                onChange={getInputVal}
                              >
                                <option selected>Select State</option>
                                {states.map((option) => (
                                  <option key={option.name} value={option.name}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                              <span className="highlight"></span>
                              <label className="label">State</label>
                            </div>
                            {stateErr === true && (
                              <div className="errorText">
                                {ErrorMsg.stateErrMsg}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="complete-profile">
                            <div className="floating-label mt-20">
                              <select
                                className="floating-input floating-select1"
                                placeholder=" "
                                name="city"
                                value={selectedCity}
                                onChange={getInputVal}
                              >
                                <option selected>Select City</option>
                                {cities.map((option) => (
                                  <option key={option.name} value={option.name}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                              <span className="highlight"></span>
                              <label className="label">City</label>
                            </div>
                            {cityErr === true && (
                              <div className="errorText">
                                {ErrorMsg.CityErrorMsg}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row justify-content-between mt-10">
                        <div className="col-auto col-md-12 mb-md-3">
                          <div className="social-media">Social Media</div>
                        </div>
                      </div>
                      <div className="row justify-content-between">
                      <div className="col-12 col-md-12 mt-10">
                          <div className="username">
                            <div className="floating-label">
                              <input
                                className="floating-input"
                                type="text"
                                placeholder=" "
                                name="youtubeUrl"
                                onChange={getInputVal}
                                value={inputVal.youtubeUrl}
                                maxLength={255}
                              />
                              <span className="highlight"></span>
                              <label className="label">Youtube</label>
                            </div>
                            {/* {InstagramErr === true && (
                          <div className="errorText">
                            {ErrorMsg.instagramUrlErrMsg}
                          </div>
                        )} */}
                          </div>
                        </div>
                        <div className="col-12 col-md-12 mt-10">
                          <div className="username">
                            <div className="floating-label">
                              <input
                                className="floating-input"
                                type="text"
                                placeholder=" "
                                name="ituneUrl"
                                onChange={getInputVal}
                                value={inputVal.ituneUrl}
                                maxLength={255}
                              />
                              <span className="highlight"></span>
                              <label className="label">ITunes</label>
                            </div>
                            {/* {InstagramErr === true && (
                          <div className="errorText">
                            {ErrorMsg.instagramUrlErrMsg}
                          </div>
                        )} */}
                          </div>
                        </div>
                        <div className="col-12 col-md-12 mt-10">
                          <div className="username">
                            <div className="floating-label">
                              <input
                                className="floating-input"
                                type="text"
                                placeholder=" "
                                name="spotifyUrl"
                                onChange={getInputVal}
                                value={inputVal.spotifyUrl}
                                maxLength={255}
                              />
                              <span className="highlight"></span>
                              <label className="label">Spotify</label>
                            </div>
                            {/* {InstagramErr === true && (
                          <div className="errorText">
                            {ErrorMsg.instagramUrlErrMsg}
                          </div>
                        )} */}
                          </div>
                        </div>
                        
                        <div className="col-12 col-md-12 mt-10">
                          <div className="username">
                            <div className="floating-label">
                              <input
                                className="floating-input"
                                type="text"
                                placeholder=" "
                                name="instagramUrl"
                                onChange={getInputVal}
                                value={inputVal.instagramUrl}
                                maxLength={255}
                              />
                              <span className="highlight"></span>
                              <label className="label">Instagram</label>
                            </div>
                            {/* {InstagramErr === true && (
                          <div className="errorText">
                            {ErrorMsg.instagramUrlErrMsg}
                          </div>
                        )} */}
                          </div>
                        </div>
                        <div className="col-12 col-md-12 mt-10">
                          <div className="username">
                            <div className="floating-label">
                              <input
                                className="floating-input"
                                type="text"
                                placeholder=" "
                                name="facebookUrl"
                                onChange={getInputVal}
                                value={inputVal.facebookUrl}
                                maxLength={255}
                              />
                              <span className="highlight"></span>
                              <label className="label">Facebook</label>
                            </div>
                            {/* {facebookErr === true && (
                          <div className="errorText">
                            {ErrorMsg.facebookUrlErrMsg}
                          </div>
                        )} */}
                          </div>
                        </div>
                        <div className="col-12 col-md-12 mt-10">
                          <div className="username">
                            <div className="floating-label">
                              <input
                                className="floating-input"
                                type="text"
                                placeholder=" "
                                name="tiktokUrl"
                                onChange={getInputVal}
                                value={inputVal.tiktokUrl}
                                maxLength={255}
                              />
                              <span className="highlight"></span>
                              <label className="label">TikTok</label>
                            </div>
                            {/* {InstagramErr === true && (
                          <div className="errorText">
                            {ErrorMsg.instagramUrlErrMsg}
                          </div>
                        )} */}
                          </div>
                        </div>
                        <div className="col-12 col-md-12 mt-10">
                          <div className="username">
                            <div className="floating-label">
                              <input
                                className="floating-input"
                                type="text"
                                placeholder=" "
                                name="appleUrl"
                                onChange={getInputVal}
                                value={inputVal.appleUrl}
                                maxLength={255}
                              />
                              <span className="highlight"></span>
                              <label className="label">Apple</label>
                            </div>
                            {/* {InstagramErr === true && (
                          <div className="errorText">
                            {ErrorMsg.instagramUrlErrMsg}
                          </div>
                        )} */}
                          </div>
                        </div>
                        <div className="col-12 col-md-12 mt-10">
                          <div className="username">
                            <div className="floating-label">
                              <input
                                className="floating-input"
                                type="text"
                                placeholder=" "
                                name="applePodcastURL"
                                onChange={getInputVal}
                                value={inputVal.applePodcastURL}
                                maxLength={255}
                              />
                              <span className="highlight"></span>
                              <label className="label">Apple Podcast</label>
                            </div>
                            {/* {InstagramErr === true && (
                          <div className="errorText">
                            {ErrorMsg.instagramUrlErrMsg}
                          </div>
                        )} */}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <button
                            className="btn btn-color btnPadding w100"
                            onClick={onBack}
                          >
                            Back
                          </button>
                        </div>
                        <div className="col-6">
                          <button
                            className="btn btn-color btnPadding w100 cursor"
                            onClick={() => onCompleteProfile()}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CompleteProfile;
