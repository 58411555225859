import React, { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import moment from "moment";
import { constant } from "../../utility/constant";
import { deleteDataApi } from "../../utility/apiCall";
import { Modal, ModalBody, Button } from "react-bootstrap";
import {
  addErrorMesage,
  addSuccessMesage,
} from "../CustomToster/CustomToaster";
import { Tabs, Tab } from "react-bootstrap";

const Follows = ({
  show,
  handleClose,
  activeTab,
  followedData,
  followingData,
  refreshData,
  flag
}) => {
  
    const resetFollow = async (index) => {
        let followItem = followedData[index];
        if (followItem !== undefined) {
          var authHeader = {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          };
          let resp = await deleteDataApi(
            constant.baseURL + "follows/" + followItem.id,
            authHeader
          );
          if (
            resp !== undefined &&
            resp.status !== undefined &&
            resp.status === 200
          ) {
            addSuccessMesage("UnFollowed");
            refreshData();
          }else{
            addErrorMesage("Error while unfollow");
            refreshData();
          }
        }
    };
    const renderFollowedData = () => {
        if (followedData.length > 0) {
            return followedData.map((item, index) => {
                return (
                    <div key={index} className="row justify-content-center mt-3">
                        <div className="col-11">
                            <div className="follower-box">
                                <div className="row align-items-center ">
                                    <div className="col-2">
                                        <img className="follower-box-img"
                                        style={{borderRadius: 30}} 
                                        src={item.user.urlImage !== undefined &&
                                            item.user.urlImage !== "" &&
                                            item.user.urlImage !== "user.png"
                                            ? constant.profileURL + item.user.urlImage
                                            : item.user.usertype=="Artist"?constant.artistImageURL : constant.userImageURL}
                                        alt=""/>
                                    </div>
                                    <div className="col-6 pad-md-0">
                                        <div className="episode-title">
                                            {item.user.firstName+" "+item.user.lastName}
                                        </div>
                                        <div className="episode-sub-title">{item.user.phone}</div>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn follow-btn" onClick={()=>resetFollow(index)}>UnFollow</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })
        } else {
            return <div className="no-data-found">{constant.noDataFoundLabel}</div>;
        }  
    }
    const renderFollowingData = () => {
        if (followingData.length > 0) {
            return followingData.map((item, index) => {
                return (
                    <div key={index} className="row justify-content-center mt-3">
                        <div className="col-11">
                            <div className="follower-box">
                                <div className="row align-items-center ">
                                    <div className="col-2">
                                        <img className="follower-box-img" 
                                        style={{borderRadius: 30}}
                                        src={item.follower.urlImage !== undefined &&
                                            item.follower.urlImage !== "" &&
                                            item.follower.urlImage !== "user.png"
                                            ? constant.profileURL + item.follower.urlImage
                                            : item.follower.usertype=="Artist"?constant.artistImageURL : constant.userImageURL}
                                        alt=""/>
                                    </div>
                                    <div className="col-6 pad-md-0">
                                        <div className="episode-title">
                                            {item.follower.firstName+" "+item.follower.lastName}
                                        </div>
                                        <div className="episode-sub-title">{item.follower.phone}</div>
                                    </div>
                                    <div className="col-4">
                                        {/* <a className="following-btn" href="">Following</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })
        } else {
            return <div className="no-data-found">{constant.noDataFoundLabel}</div>;
        }  
    }
    return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop={"static"}
        keyboard={false}
        size={"md"}
      >
        {/* <Modal.Header
          closeButton
          style={{ backgroundColor: "#313131", border: 0 }}
        >
          <Modal.Title id="example-custom-modal-styling-title" className="wrapText">
            {title}
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body style={{ backgroundColor: "#313131" }}>
          <div className="divTabs" style={{ width: "100%" }}>
            <Tabs
              defaultActiveKey={activeTab}
              transition={false}
              className="mb-3"
            >
              {flag=="user"?null:<Tab eventKey="Followers" title="Follower(s)">
                <section id="tab-follower" className="tab-panel"
                style={{height: 400, overflowX: 'hidden', overflowY:'auto'}}>
                    <div className="follower-scroll">
                        <div className="profile-follower-page">
                            {renderFollowedData()}
                        </div>
                    </div>
                </section>
              </Tab>}
              <Tab eventKey="Following" title="Following">
                <section id="tab-following" className="tab-panel"style={{height: 400, overflowX: 'hidden', overflowY:'auto'}}>
                    <div className="follower-scroll">
                        <div className="profile-follower-page">
                            {renderFollowingData()}
                        </div>
                    </div>
                </section>
              </Tab>
            </Tabs>  
          </div>  
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#313131", border: 0 }}>
            <div className="col-12 centerTextInDiv">
                <button className="btn btn-primary w70" onClick={handleClose}>Close</button>
            </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Follows;
