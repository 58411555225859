import React, { useState, useEffect } from "react";
import {BsPlayFill} from 'react-icons/bs';
import {FiMoreVertical} from 'react-icons/fi';
import { constant } from '../../utility/constant';
import { getDataApi, postDataApi, deleteDataApi} from '../../utility/apiCall';
import { AiOutlineHeart, AiFillHeart, AiOutlineMore } from "react-icons/ai";
import { FaShareAlt } from 'react-icons/fa';
import {colors} from '../../utility/colors';
import user from '../../assets/Images/user.png';
import AudioPlayer from '../../components/AudioPlayer/AudioPlayer';
import moment from 'moment';
import ShareModal from "../../components/ShareModal/ShareModal";
import twitter from '../../assets/Images/twitter.png'
import social from '../../assets/Images/social.png'
import facebook from '../../assets/Images/facebook.png'
import location from '../../assets/Images/location.png';
import phone from '../../assets/Images/phone.png';
import mail from '../../assets/Images/mail.png';


function MoreInfo(props) {
    let loginUserId = 0;
    const[currentUserInfo, setCurrentUserInfo] = useState(undefined);
    const { id } = props.location.state;
    const [albumList, setAlbumList] = useState([]);
    const [artistFollow, setArtistFollow] = useState(undefined);
    const [musicType, setMusicType] = useState([]);
    const [showShareToModal, setShowShareToModal] = useState(false);
    const [artistData, setArtistData] = useState(
    {
        id: id !== undefined ? id : 0,
        firstName: "",
        lastName: "",
        artistLiked: false,
        biography: "",
        location: "",
        email: "",
        phoneNo: "",
        title:"",
        country:"",
        callingCodes:"",
        followed: false,
        image: "",
        like: false,
    }
    );
    const getArtistDetails = async (id) => {
        let resArtist = await getDataApi(constant.baseURL + 'user/'+ id);
        if (resArtist !== undefined) {
            setArtistData(prevState => {
                return {
                    ...prevState,
                    id: resArtist.id,
                    firstName: (resArtist.firstName !== undefined) ? resArtist.firstName : '',
                    lastName: (resArtist.lastName !== undefined) ? resArtist.lastName : '',
                    email: (resArtist.email !== undefined) ? resArtist.email : '',
                    phoneNo: (resArtist.phone !== undefined) ? resArtist.phone : '',
                    title: (resArtist.title !== undefined) ? resArtist.title : '',
                    biography: (resArtist.biography !== undefined) ? resArtist.biography : '',
                    location: (resArtist.fullAddress !== undefined) ? resArtist.fullAddress : '',
                    country:(resArtist.country !== undefined) ? resArtist.country.name : '',
                    callingCodes:(resArtist.country !== undefined) ? '+' +resArtist.country.callingCodes : '',
                    image: (resArtist.urlImage !== undefined && resArtist.urlImage !== '' && resArtist.urlImage !== 'user.png') ? constant.profileURL + resArtist.urlImage : resArtist.usertype=="Artist"?constant.artistImageURL : constant.userImageURL,
                }
            });
        }
    }
    const getCategoriesByArtist = async (id) => {
        let resUserTypes = await getDataApi(constant.baseURL + 'selectedcategories?filter=user.id||$eq||' + id);
        //console.log(resUserTypes);
        if (resUserTypes !== undefined && resUserTypes.length > 0) {
            let typeItems = [];
            resUserTypes.map((t) => {
                let fltType = typeItems.filter(f => f.id == t.category.id);
                if (fltType.length == 0) {
                    typeItems.push(t.category);
                }
            });
            setMusicType(typeItems);
        }
    }
    const getAlbumsByArtist = async (id) => {
        let resAlbums = await getDataApi(constant.baseURL + 'albums/getByArtist/'+id+'?page=1&limit=4');
        //console.log("resAlbums: ", resAlbums);
        if (resAlbums !== undefined && resAlbums.data !== undefined) {
          setAlbumList([...resAlbums.data]);
        }
    }
    //Follow - START
    const getFollowByArtist = async (id) => {
        let resArtistFollow = await getDataApi(constant.baseURL + 'follows?filter=user.id||$eq||' + loginUserId + '&follower.id||$eq||'+id);
        //console.log("resArtistFollow: ",resArtistFollow);
        if (resArtistFollow !== undefined && resArtistFollow.length > 0) {
        setArtistFollow(resArtistFollow[0]);
        setArtistData(prevState => {
            return {
                ...prevState,
                followed: true
            }
        });
        }
    }
    const createFollow = async () => {
        let jsonData = { user: loginUserId, follower: id }
        var formBody = [];
        for (var property in jsonData) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(jsonData[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        var authHeader = {
            "Accept": "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
        };
        let respFollow = await postDataApi(constant.baseURL + 'follows', formBody, authHeader);
        //console.log("respFollow: ", respFollow);
        if (respFollow !== undefined && respFollow.status !== undefined && respFollow.status === 201) {
            // enqueueSnackbar('Followed Successfully!', {
            //   variant: 'success', anchorOrigin: {
            //       vertical: 'top',
            //       horizontal: 'right',
            //   },
            // });
            setArtistFollow(respFollow.data);
            setArtistData(prevState => {
            return {
                ...prevState,
                followed: true
            }
            });
        }else{
            setArtistData(prevState => {
            return {
                ...prevState,
                followed: false
            }
            });
        }
    }
    const resetFollow = async () => {
    if(artistFollow !== undefined){
        var authHeader = {
            "Accept": "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
        };
        let resp = await deleteDataApi(constant.baseURL + 'follows/'+artistFollow.id, authHeader);
        //console.log("resp: ", resp);
        if (resp !== undefined && resp.status !== undefined && resp.status == 200) {
            // enqueueSnackbar('Unfollow Successfully!', {
            //   variant: 'success', anchorOrigin: {
            //     vertical: 'top',
            //     horizontal: 'right',
            //   },
            // });
            setArtistFollow(undefined);
            setArtistData(prevState => {
                return {
                    ...prevState,
                    followed: false
                }
            });
        }
        }
    }
    //Follow - END
    const renderMusicType = () => {
        if (typeof musicType !== 'undefined' && musicType.length !== 0) {
            return musicType.map((item, index) => {
                return (
                    <div key={index} className="col-auto">
                        <button className="btn btn-hip-hop">{item.name}</button>
                    </div>
                )
            })
        }
    }
    const renderAlbumList = () => {
        if (typeof albumList !== 'undefined' && albumList.length !== 0) {
            return albumList.map((item, index) => {
                return (
                    <div className="row" key={index}>
                        <div className="col-auto">
                            <div className="news-box">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                       {
                                            item.album_albumType === "music" &&
                                            <img style={{height: 68, width: 68}} src={item.album_urlImage !== undefined && item.album_urlImage !== "" ? constant.albumFileURL + item.album_urlImage : constant.musicURL} alt={item.album_name}/>
                                       }
                                       {
                                            item.album_albumType === "video" &&
                                            <img style={{height: 68, width: 68}} src={item.album_urlImage !== undefined && item.album_urlImage !== "" ? constant.albumFileURL + item.album_urlImage : constant.videoURL} alt={item.album_name}/>
                                       }
                                    </div>
                                    <div className="col-auto">
                                        <div className="latest-album-name" title={item.album_name}>{item.album_name.length <= 10 ? item.album_name : item.album_name.substring(0,10)}</div>
                                        <div className="album-release-year">{item.musics !== undefined ? item.musics.length : 0} Tracks | 2019</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
    }
    useEffect(() => {
        if(localStorage.getItem("UserInfo") !== undefined && localStorage.getItem("UserInfo") !== null){
            setCurrentUserInfo(JSON.parse(localStorage.getItem("UserInfo")));
            loginUserId = JSON.parse(localStorage.getItem("UserInfo")).id;
        }
        getArtistDetails(id);
        getCategoriesByArtist(id);
        getAlbumsByArtist(id);
        getFollowByArtist(id);
    }, []);
    return (
        <div>
            <section className="feature-banner-2" style={{backgroundImage: `url(${artistData !== undefined ? encodeURI(artistData.image) : encodeURI(user)})`}}>
                <div className="playlist">
                    <div className="feature-playlist-namne">{artistData !== undefined ? artistData.firstName + " " + artistData.lastName : ""}</div>
                    <p className="selena-gomez-about">{artistData !== undefined ? artistData.title : ""}</p>
                    <div className="row">
                        {renderMusicType()}
                    </div>
                    <div className="moreinfo-follow-row row">
                        <div className="col-auto">
                            <button className="btn btn-primary custom-play-icon-2" onClick={() =>(artistData.followed === true) ? resetFollow() : createFollow()}>{(artistData.followed === true) ? 'UnFollow' : 'Follow'}</button>
                        </div>
                        <div className="col-auto custom-pad">
                            <button className="btn btn-share-more" onClick={()=> {setShowShareToModal(true)}}><FaShareAlt/></button>
                        </div>
                        <ShareModal show={showShareToModal} id={id} title={"Share To"} currentUserId={loginUserId} handleClose={()=>{setShowShareToModal(false)}}/>
                    </div>
                </div>
            </section>
            <section className="moreinfo-detail">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-9">
                            <div className="biography-title">BIOGRAPHY</div>
                            <div className="biogrphy-detail">{artistData !== undefined ? artistData.biography : ""}</div>
                            <div className="row mt-4">
                                <div className="col-auto">
                                    <div className="biography-title">Contact</div>
                                </div>
                            </div>
                            <div className="row mt-4 align-items-center">
                                <div className="col-auto">
                                    <div className="location-btn"><img style={{height: 32, width: 32}} src={location} alt="Location"></img></div>
                                </div>
                                <div className="col-auto">
                                    <div className="location-text">{artistData !== undefined ? artistData.location : ""}</div>
                                </div>
                            </div>
                            <div className="row mt-4 align-items-center">
                                <div className="col-auto">
                                    <div className="location-btn-2"><img style={{height: 32, width: 32}} src={mail} alt="Location"></img></div>
                                </div>
                                <div className="col-auto">
                                    <div className="location-text">{artistData !== undefined ? artistData.email : ""}</div>
                                </div>
                            </div>
                            <div className="row mt-4 align-items-center">
                                <div className="col-auto">
                                    <div className="location-btn-2"><img style={{height: 32, width: 32}} src={phone} alt="Location"></img></div>
                                </div>
                                <div className="col-auto">
                                    <div className="location-text">{artistData !== undefined ? artistData.callingCodes : ""} {artistData !== undefined ? artistData.phoneNo : ""}</div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-auto">
                                    <div className="biography-title">Social Media</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-auto">
                                    <div className="social-link">
                                        <img className="" src={facebook} alt=""/>
                                        <img className="social-link-gap" src={social} alt=""/>
                                        <img className="social-link-gap" src={twitter} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className="biography-title">Latest Album</div>
                            {renderAlbumList()}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default MoreInfo;
