import React, { useState, useEffect } from "react";
import { FaRegPlayCircle } from 'react-icons/fa';
import { constant } from '../../utility/constant';
import { getDataApi, postDataApi, } from '../../utility/apiCall';
import ReactPlayer from 'react-player';
import VideoModal from "../VideoModal/VideoModal";
import bigstageBanner from "../../assets/Images/bigstageBanner.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const HomeBigStage = () => {
    let history = useHistory();
    const [homeBigStageItem, setHomeBigStageItem] = useState(undefined);
    const [playVideo, setPlayVideo] = useState(false);
    const getBigStageList = async () => {
        //let apiURL = constant.baseURL + "bigstages/getTop10BigstagesWithUserLike";
        let apiURL = constant.baseURL + "bigstages/getMainBigstagesWithUserLike";
        let jsonData = { userId: 0 }
        let resBigStages = await postDataApi(apiURL, jsonData);
        // let resBigStages = await getDataApi(constant.baseURL + 'bigstages?filter=status||$eq||1&filter=isDeleted||$eq||0&limit=1&sort=order,ASC');
        //console.log("resBigStages: ", resBigStages);
        if (resBigStages !== undefined && resBigStages.data.length > 0) {
            //setHomeBigStageItem(resBigStages.data[resBigStages.data.length - 1]);
            setHomeBigStageItem(resBigStages.data[0]);
        }
    }
    //console.log(homeBigStageItem)
    const playBigStageVideo = () => {

        history.push({
            pathname: `Videoplayer`,
            state: { variant: "homebigstagejs", url: constant.bigStageVideoURL + homeBigStageItem.videoURL }
        });

    }
    const closeVideoModal = () => {
        setPlayVideo(false);
    }
    useEffect(() => {
        getBigStageList();
    }, []);
    return (
        <section className="banner" style={{ backgroundImage: `url(${homeBigStageItem !== undefined ? encodeURI(constant.bigStageThumbnailURL + homeBigStageItem.thumbnailURL) : encodeURI(bigstageBanner)})` }}>
            <div className="container">
                {
                    homeBigStageItem !== undefined && playVideo &&
                    <VideoModal show={playVideo} url={constant.bigStageVideoURL + homeBigStageItem.videoURL} title={homeBigStageItem !== undefined ? homeBigStageItem.name : ""} height={790} width={790} handleClose={closeVideoModal}></VideoModal>
                }
                {playVideo === undefined || playVideo === false &&
                    <div className="playlist">
                        <div className="playlist-namne wrapText fs-40 fw-700"
                            title={homeBigStageItem !== undefined ? homeBigStageItem.name : ""}>
                            {homeBigStageItem !== undefined ? String(homeBigStageItem.name) : ""}
                        </div>
                        {homeBigStageItem !== undefined &&
                            <div className="play-icon" style={{ width: 30 }} onClick={() => playBigStageVideo()}>
                                <FaRegPlayCircle style={{ cursor: 'pointer' }} />
                            </div>
                        }
                    </div>
                }
            </div>
        </section>
        // <section className="banner" >
        //     <img
        //         className="banner-img"
        //         src={homeBigStageItem !== undefined ? encodeURI(constant.bigStageThumbnailURL + homeBigStageItem.thumbnailURL) : encodeURI(bigstageBanner)}

        //     />
        //     <div className="container">
        //         {
        //             homeBigStageItem !== undefined && playVideo &&
        //             <VideoModal show={playVideo} url={constant.bigStageVideoURL + homeBigStageItem.videoURL} title={homeBigStageItem !== undefined ? homeBigStageItem.name : ""} height={790} width={790} handleClose={closeVideoModal}></VideoModal>
        //         }
        //         {playVideo === undefined || playVideo === false &&
        //             <div className="playlist">
        //                 <div className="playlist-namne wrapText fs-40 fw-700"
        //                     title={homeBigStageItem !== undefined ? homeBigStageItem.name : ""}>
        //                     {homeBigStageItem !== undefined ? String(homeBigStageItem.name) : ""}
        //                 </div>
        //                 {homeBigStageItem !== undefined &&
        //                     <div className="play-icon" style={{ width: 30 }} onClick={() => playBigStageVideo()}>
        //                         <FaRegPlayCircle style={{ cursor: 'pointer' }} />
        //                     </div>
        //                 }
        //             </div>
        //         }
        //     </div>
        // </section>
    );
};
export default HomeBigStage;