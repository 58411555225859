import React, { useState, useRef, } from "react";
import { constant } from "../../utility/constant";
import { postDataApi, getDataApi } from "../../utility/apiCall";
import { useHistory } from "react-router-dom";
import homeBg from "../../assets/Images/homeBg.png";
import { addErrorMesage } from "../CustomToster/CustomToaster";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import {
  hashCodeUrl,
  hashCodeUrlDecode,
} from "../../utility/EncodeAndDecodeUrl";
import { addLoader, removeLoader } from "../../utility/CustomLoader";
import validator from "validator";

const Login = (props) => {
  const prevLocation =
    props.location.state === undefined ? "/" : props.location.state.prevPath;
  const prevUserId =
    props.location.state === undefined ? "/" : props.location.state.userId;
  const [isPasswordShow, setPasswordShow] = useState(true);
  const [isRememberMe, setRememberMe] = useState(
    localStorage.getItem("RememberMe") !== null
      ? JSON.parse(localStorage.getItem("RememberMe"))
      : false
  );
  const [inputVal, setInputVal] = useState({
    userName:
      localStorage.getItem("userName") !== null &&
        localStorage.getItem("RememberMe") !== null
        ? JSON.parse(localStorage.getItem("RememberMe")) === true
          ? hashCodeUrlDecode(localStorage.getItem("userName"))
          : ""
        : "",
    password:
      localStorage.getItem("pwd") !== null &&
        localStorage.getItem("RememberMe") !== null
        ? JSON.parse(localStorage.getItem("RememberMe")) === true
          ? hashCodeUrlDecode(localStorage.getItem("pwd"))
          : ""
        : "",
  });
  const [errorMessage, setError] = useState({
    isUserNameErrorShow: false,
    isPasswordErrorShow: false,
    userNameErrorMsg: "",
    passwordErrMsg: "",
  });
  const [emailErrMsg, setEmailErrMsg] = useState("");
  const userNameRef = useRef();
  const passwordRef = useRef();
  var history = useHistory();
  //Called on change of email and password input box
  const getInputVal = (e) => {
    let text = e.target.value;
    let name = e.target.name;
    setInputVal({ ...inputVal, [name]: text });
    if (name === "userName") {
      setError({ ...errorMessage, isUserNameErrorShow: false });
    }
    if (name === "password") {
      setError({ ...errorMessage, isPasswordErrorShow: false });
    }
    if (!text.replace(/\s/g, "").length) {
      setInputVal({ ...inputVal, [name]: text });
    }
  };
  const onLogin = () => {
    if (!inputVal.userName && !inputVal.password) {
      setError({
        ...errorMessage, isUserNameErrorShow: true, isPasswordErrorShow: true,
        userNameErrorMsg: constant.emailRequired, passwordErrMsg: constant.passwordRequired,
      });
    } else if (!inputVal.userName) {
      setError({ ...errorMessage, isUserNameErrorShow: true, userNameErrorMsg: constant.emailRequired });
    } else if (!inputVal.password) {
      setError({ isPasswordErrorShow: true, passwordErrMsg: constant.passwordRequired, });
    } else if (validator.isEmail(inputVal.userName) === false) {
      setError({ ...errorMessage, isUserNameErrorShow: true, userNameErrorMsg: constant.invalidEmailAddress });
    } else {
      signIn();
      addLoader();
    }
  };
  const signIn = async () => {
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let jsonData = { email: inputVal.userName, password: inputVal.password };
    //console.log(jsonData);
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    let responseData = await postDataApi(
      constant.baseURL + "auth/signin",
      jsonData,
      authHeader
    );
    //console.log("responseData: ", responseData);
    // console.log(
    //   responseData !== undefined &&
    //   responseData.data !== undefined &&
    //   responseData.data.accessToken !== ""
    // );
    if (
      responseData !== undefined &&
      responseData.data !== undefined &&
      responseData.data.accessToken !== ""
    ) {
      

      //set remember me options
      //console.log("RemeberMe: ", isRememberMe);
      if (isRememberMe === true) {
        localStorage.setItem("RememberMe", isRememberMe);
        localStorage.setItem("userName", hashCodeUrl(inputVal.userName));
        localStorage.setItem("pwd", hashCodeUrl(inputVal.password));
      } else {
        localStorage.setItem("RememberMe", isRememberMe);
        localStorage.setItem("userName", "");
        localStorage.setItem("pwd", "");
      }
      removeLoader();
      ///Hitendra
      // history.push({
      //   pathname: prevUserId === undefined ? "/" : prevLocation,
      //   state: {
      //     id: prevUserId,
      //   },
      // });
      // window.location.reload();


      ////Check if artist album created or not
      let resAlbums = await getDataApi(
        constant.baseURL + "albums?filter=user.id||$eq||" + responseData.data.user.id + "&filter=isDeleted||$eq||0"
      );
      //console.log(resAlbums)
      if ((resAlbums != undefined && resAlbums.length > 0) || responseData.data.user.usertype=="User"){
        localStorage.setItem("UserInfo", JSON.stringify(responseData.data.user));
      localStorage.setItem("UserToken", responseData.data.accessToken);
      //console.log("window.location: ", window.location);
        history.push({
          pathname: prevUserId === undefined ? "/" : prevLocation,
          state: {
            id: prevUserId,
          },
        });
        window.location.reload();
      } else {
        localStorage.setItem("newUser", JSON.stringify(responseData.data.user));
        history.push({
          pathname: `firstAlbumMessage`,
          state: {
            from: "Login",
            prevUserId: prevUserId,
            prevLocation: prevLocation,
          },
        });
      }


      ////Hitendra

    } else {
      removeLoader();
      if (responseData?.data?.accessToken == "") {
        addErrorMesage(constant.invalidCredentials);
      } else {
        addErrorMesage("Something Network Issue")
      }
      //addErrorMesage("Invalid Credentials!");
    }
  };


  return (
    <section className="login-page">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 d-none d-xl-block">
            <div className="login-bg">
              {/* <img src={constant.isAws ? constant.loginBackgroundImageURL : homeBg} className="img-fluid" alt="Image" /> */}
              <img src={constant.loginBackgroundImageURL} className="img-fluid" alt="Image" />
            </div>
          </div>
          <div className="col-xl-6">
            <div className="login-area">
              <div className="row justify-content-center align-items-center">
                <div className="col-12">
                  <div className="title">{constant.loginTitle}</div>
                  <div className="login-sub-title mb-5">
                    {constant.loginSubtitle}
                  </div>
                  <div className="username">
                    <div className="floating-label">
                      <input
                        className="floating-input"
                        type="text"
                        placeholder=" "
                        onChange={getInputVal}
                        name="userName"
                        value={inputVal.userName}
                        required={true}
                        ref={userNameRef}
                      ></input>
                      <span className="highlight"></span>
                      <label className="label">Email</label>
                      {errorMessage.isUserNameErrorShow && (
                        <span className="errorMessage">{errorMessage.userNameErrorMsg}</span>
                      )}
                    </div>
                  </div>
                  <div className="username mt-20">
                    <div className="floating-label">
                      <input
                        className="floating-input"
                        // type="password"
                        placeholder=" "
                        type={!isPasswordShow ? "text" : "password"}
                        onChange={getInputVal}
                        name="password"
                        value={inputVal.password}
                        required={true}
                        ref={passwordRef}
                      ></input>

                      {!isPasswordShow ? (
                        <div
                          className="eye-icon-show-hide"
                          onClick={() => {
                            setPasswordShow(!isPasswordShow);
                          }}
                        >
                          <BsEyeFill />
                        </div>
                      ) : (
                        <div
                          className="eye-icon-show-hide"
                          onClick={() => {
                            setPasswordShow(!isPasswordShow);
                          }}
                        >
                          <BsEyeSlashFill />
                        </div>
                      )}
                      <span className="highlight"></span>
                      <label className="label">Password</label>
                      {errorMessage.isPasswordErrorShow && (
                        <span className="errorMessage">
                          {errorMessage.passwordErrMsg}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row justify-content-between">
                    <div className="col-md-auto col-auto">
                      <div className="custom-control custom-checkbox mr-sm-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customControlAutosizing"
                          checked={isRememberMe}
                          onChange={() => setRememberMe(!isRememberMe)}
                        />
                        <label
                          className="custom-control-label rememberMe"
                          htmlFor="customControlAutosizing"
                        >
                          Remember Me
                        </label>
                      </div>
                    </div>
                    <div className="col-md-auto col-auto">
                      <div
                        className="forget-password"
                        style={{ cursor: "pointer", fontSize: 13 }}
                        onClick={() => {
                          history.push("forgotpassword");
                        }}
                      >
                        Forgot Password?
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <button
                        onClick={onLogin}
                        className="btn btn-color w100 btnPadding"
                      >
                        Login
                      </button>
                    </div>
                  </div>
                  <div className="option-text">Or</div>
                  <div className="option-text">Don't have an Account?</div>
                  <div className="row">
                    <div className="col-12">
                      <button
                        className="custom-btn custom-btn-color"
                        onClick={() => {
                          history.push("createAccount");
                        }}
                      >
                        Create Account
                      </button>
                    </div>
                  </div>
                  <div className="option-text cursor"
                    onClick={() => {
                      history.push("/");
                    }}>
                    Back To Home
                  </div>
                  {/* <div className="row">
                    <div className="col-12">
                      <button
                        className="custom-btn custom-btn-color"
                        onClick={() => {
                          history.push("/");
                        }}
                      >
                        Back To Home
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {
        errorMessage.isUserNameErrorShow &&
        <CustomToast
          show={errorMessage.isUserNameErrorShow}
          message={"This is a toast message"}
          color="Success"
        />
      } */}
    </section>
  );
};
export default Login;
