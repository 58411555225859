import { INCREMENT, DECREMENT } from './counter.types';


    const INITIAL_STATE = {

        show: false,
    };

    const reducer = (state = INITIAL_STATE, action) => {
//console.log(action)
        switch (action.type) {

            case INCREMENT:

               return {

                 ...state,
                 show:action.data.p,
                 url:action.data.q,
                 title:action.data.r,
                 height:action.data.s,
                 width:action.data.t,


               };

            case DECREMENT:

               return {
                  ...state,
               };

             default: return state;

        }

    };

    export default reducer;