import React, { useState, useEffect } from "react";
import MyItems from "../../components/MyItems/MyItems";

function MyEvents() {
  return (
    <>
        <MyItems type="Events" headerTitle="My Events" showLikes={true}/>
    </>    
  );
}

export default MyEvents;
