import React, { useState } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { constant } from "../../utility/constant";
import { postDataApi } from "../../utility/apiCall";
import { VerifyLoginExistOrNot } from "../../utility/VerifyLoginExistOrNot";
import {
  addErrorMesage,
  addSuccessMesage,
} from "../CustomToster/CustomToaster";
import {
  addLoader,
  DisableButtonLoader,
  removeLoader,
} from "../../utility/CustomLoader";
import Loader from "react-loader-spinner";

const Comments = (props) => {
  var history = useHistory();
  var path = useLocation();
  const [buttonDisable, setbuttonDisable] = useState(false);
  const postComment = () => {
    const textRegex =
      /^[/\a-zA-Z0-9\s\[\]\{\}\.\`\~\!\@\$\^\&\(\)\_\+\-\=\;\,]*$/;
    //console.log("button Disable");
    if (VerifyLoginExistOrNot()) {
      //console.log("jayrajjjjjjjk", props.commentInputVal, props.commentInputVal.trim(), textRegex.test(props.commentInputVal))
      if (!props.commentInputVal ||  !props.commentInputVal.trim()) {
        //console.log("jjjjjo0oojj", props.commentInputVal)
        props.setCommentError();
      } else {
        if (props.type === "podcast" && props.id !== undefined) {
          createComments(constant.baseURL + "podcastcomments", {
            user: props.currentUserId,
            podcasts: props.id,
            comment: props.commentInputVal,
          });
        } else if (props.type === "artist" && props.id !== undefined) {
          createComments(constant.baseURL + "artistcomments", {
            user: props.currentUserId,
            artist: props.id,
            comments: props.commentInputVal,
          });
        } else if (props.type === "news" && props.id !== undefined) {
          createComments(constant.baseURL + "newscomments", {
            user: props.currentUserId,
            news: props.id,
            comment: props.commentInputVal,
          });
        }
      }
    } else {
      // window.alert("Please Login FIrst");
      history.push({
        pathname: `/login`,
        state: {
          prevPath: path.pathname,
          userId: props.id,
        },
      });
    }
  };
  const createComments = async (apiURL, jsonData) => {
    if (props.currentUserId !== undefined) {
      // addLoader();
      setbuttonDisable(true);
      //console.log(jsonData);
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let respComments = await postDataApi(apiURL, formBody, authHeader);
      //console.log("respComments: ", respComments);
      if (
        respComments !== undefined &&
        respComments.status !== undefined &&
        respComments.status == 201
      ) {
        // removeLoader();
        setbuttonDisable(false);
        addSuccessMesage("Comments Submitted!");
        // props.enqueueSnackbar('Comments Submitted!', {
        //     variant: 'success', anchorOrigin: {
        //         vertical: 'top',
        //         horizontal: 'right',
        //     },
        // });
        props.setComments(respComments.data);
      } else {
        // removeLoader();
        setbuttonDisable(false);
        addErrorMesage("Error encountered while submitting comment!");
        // props.enqueueSnackbar('Error encountered while submitting comment!', {
        //     variant: 'error', anchorOrigin: {
        //         vertical: 'top',
        //         horizontal: 'right',
        //     },
        // });
      }
    }
  };
  const onChangeCommentText = (e) => {
    if (e.target.value == "") {
      props.setCommentError(true);
    }
    props.setCommentInputVal(e.target.value);
  };
  const renderComments = () => {
    if (props.data.length !== 0) {
      return props.data.map((item, index) => {
        return (
          <div key={index} className="feature-episode-box-2">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-2 col-md-1">
                    <img
                      className="episode-img"
                      src={item.img}
                      alt={item.name}
                    />
                  </div>
                  <div className="col-7 col-md-8 col-lg-9">
                    <div className="row">
                      <div className="col-12">
                        <div className="episode-title">{item.name}</div>
                        <div className="episode-sub-title">{item.location}</div>
                      </div>
                      <div className="col-12">
                        {/* d-none d-md-block */}
                        <div className="song-discription ">{item.comment}</div>
                      </div>
                    </div>

                  </div>
                  <div className="col-3 col-md-3 col-lg-2">
                    <div className="comment-date">{item.date}</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        );
      });
    } else {
      return <div className="no-data-found">{constant.noDataFoundLabel}</div>;
    }
  };
  return (
    <div>
      <section className="comment-main" style={{ backgroundColor: '#121216' }}>
        <div className="container">

          {/* <section className="comments"> */}
          <div className="web-container pt-5" >
            <div className="row justify-content-between">
              <div className="col-auto">
                <div className="featured-artists-title">{props.data.length > 0 ? props.data.length + " Comments" : "Comments"}</div>
              </div>
            </div>

            <div className="row mt-3">
              <div></div>
              <div className="col-12 col-lg-12">
                <div className="leave-comment">
                  <div className="floating-label">
                    <input
                      className="floating-input"
                      type="text"
                      placeholder=" "
                      name="commentInputVal"
                      onChange={(e) => onChangeCommentText(e)}
                      value={props.commentInputVal}
                    ></input>
                    {props.errorCommentMessage === true && (
                      <span style={{ fontSize: 13, color: "red" }}>
                        Please enter comment
                      </span>
                    )}
                    <span className="highlight"></span>
                    <label className="label">Comment</label>
                  </div>
                </div>
              </div>

            </div>
            <div className="row">
              <div></div>
              <div className="col-12 col-lg-10">
                <button
                  className="btn btn-primary dis-flex"
                  disabled={buttonDisable}
                  onClick={() => postComment()}
                >
                  {buttonDisable && DisableButtonLoader()}
                  <span className="play-btn">Post Comment</span>
                </button>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
          {/* </section> */}
          <div
            className="row mt-5"
            style={{
              maxHeight: props.data.length !== 0 ? 460 : 100,
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            {/* cus-comment-sm */}
            <div></div>
            <div className="col-12 col-lg-12" style={{ fontSize: 14 }}>
              {renderComments()}
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-auto">
              {props.data !== undefined &&
                props.data.length > 0 &&
                props.showViewMore === true && (
                  <button className="btn dark" onClick={props.viewMoreComments}>
                    View More
                  </button>
                )}
            </div>
          </div>
        </div>
      </section>
      {/* <section className="comments">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-auto">
              <div className="featured-artists-title">Leave A Comment</div>
            </div>
          </div>
          <div className="row mt-5">
            <div></div>
            <div className="col-12 col-lg-10">
              <div className="leave-comment">
                <div className="floating-label">
                  <textarea
                    className="floating-input"
                    type="text"
                    placeholder=" "
                    name="commentInputVal"
                    onChange={(e) => onChangeCommentText(e)}
                    value={props.commentInputVal}
                  ></textarea>
                  {props.errorCommentMessage === true && (
                    <span style={{ fontSize: 13, color: "red" }}>
                      Please enter comment
                    </span>
                  )}
                  <span className="highlight"></span>
                  <label className="label">Comment</label>
                </div>
              </div>
            </div>
            <div className="col-1"></div>
          </div>
          <div className="row">
            <div></div>
            <div className="col-12 col-lg-10">
              <button
                className="btn btn-primary dis-flex"
                disabled={buttonDisable}
                onClick={() => postComment()}
              >
                {buttonDisable && DisableButtonLoader()}
                <span className="play-btn">Post Comment</span>
              </button>
            </div>
            <div className="col-1"></div>
          </div>
        </div>
      </section> */}
    </div>
  );
};
export default Comments;