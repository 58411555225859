import React, { useState, useEffect } from "react";
import { constant } from "../../utility/constant";
import {
  getDataApi,
  postDataApi,
  patchDataApi,
  deleteDataApi,
} from "../../utility/apiCall";
import { colors } from "../../utility/colors";
import { useHistory } from "react-router-dom";
import uploadImage1 from "../../assets/Images/uploadImage1.png";
import Multiselect from "multiselect-react-dropdown";
import { FileDrop } from "react-file-drop";
import ImageUploader from "react-images-upload";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  addErrorMesage,
  addSuccessMesage,
} from "../../components/CustomToster/CustomToaster";
import { AiFillCloseSquare } from "react-icons/ai";
import { addLoader, removeLoader } from "../../utility/CustomLoader";

function CreateEvents(props) {
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const { id, item } = props.location.state;
  //console.log("item: ", item);
  let history = useHistory();
  const multiSelectStyle = {
    multiselectContainer: {
      color: "white",
      fontSize: 14,
    },
    option: {
      // To change css for dropdown options
      color: colors.colors13,
    },
    chips: {
      background: colors.colors13,
      fontSize: 15,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 8,
      paddingBottom: 8,
      borderRadius: 8,
    },
    searchBox: {
      border: "1px solid #8186AF",
      borderRadius: "2px",
      //fontFamily: 'Mr Eaves Mod OT',
      fontSize: 15,
      minHeight: 40,
      //width: 395,
    },
  };
  const [coverImg, setCoverImg] = useState(null);
  //const [selectedDate, handleDateChange] = useState(item !== undefined && item.eventdate !== undefined && item.eventdate !== null && item.eventdate !== "" ? new Date(item.eventdate) : new Date());
  const [selectedDate, handleDateChange] = useState(
    item !== undefined && item !== null && item !== "" && item.eventdate !== undefined && item.eventdate !== null && item.eventdate !== ""
      ? new Date(item.eventdate)
      : null
  );
  const [podFiles, setPodFiles] = useState([]);
  const [dragNDropText, setDragNDropText] = useState("Drop your file here");
  const [eventCategories, setEventCategories] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [eventDetails, setEventDetails] = useState({
    eventName: item !== undefined ? item.title : "",
    eventLink: item !== undefined ? item.eventLink : "",
    eventDescription: item !== undefined ? item.description : "",
    eventurlImage: item !== undefined ? item.img : "",
    urlImage: item !== undefined ? item.urlImage : "",
    eventdate: item !== undefined ? new Date(item.eventdate) : "",
    eventAddress: item !== undefined ? item.address : "",
    categoryList: [],
    selectedCategoryList: [],
    existingCategoryList: [],
    id: id,
  });
  const [isUploadEvents, setUploadEvents] = useState(false);
  const [isEventNameErrShow, setEventNameErr] = useState(false);
  const [isEventAddressErrShow, setEventAddressErr] = useState(false);
  const [isEventDateErrShow, setEventDateErr] = useState(false);
  const [isEventLinkErrShow, setEventLinkErr] = useState(false);
  const [isEventDescErrShow, setEventDescErr] = useState(false);
  const [isAddCategoryErrShow, setAddCategoryErr] = useState(false);
  const [isEventImgeErrShow, setisEventImgeErrShow] = useState(false);
  const onDrop = (file) => fileValidation(file[0]);
  const onUploadImg = (file) => fileValidation(file[0]);
  const fileValidation = (file) => {
    // Allowing file type
    let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if (!allowedExtensions.exec(file.name)) {
      addErrorMesage("Please upload a image file!");
      //enqueueSnackbar("Please upload a image file!", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right', } });
      file = "";
      setDragNDropText("Drop your image here or browse");
      return false;
    } else {
      let imageSrc = URL.createObjectURL(file);
      setDragNDropText("Drop your image here or browse");
      setCoverImg(imageSrc);
      let fileItem = [];
      fileItem.push(file);
      setPodFiles([...fileItem, ...podFiles]);
    }
  };
  const onSelectCategory = (selectedList) => {
    //console.log("Selected Category: ", selectedList);
    setEventDetails((prevState) => {
      return { ...prevState, selectedCategoryList: selectedList };
    });
    setAddCategoryErr(false);
  };

  const onRemoveSelectCategory = (selectedList) => {
    setEventDetails((prevState) => {
      return { ...prevState, selectedCategoryList: selectedList };
    });
  };

  const onUpload = async () => {
    const {
      eventName,
      eventLink,
      selectedCategoryList,
      eventDescription,
      eventdate,
      eventAddress,
    } = eventDetails;
    //console.log(eventdate,eventDetails)
    setisEventImgeErrShow(false);
    if (
      !eventName &&
      !eventLink &&
      !eventDescription &&
      !eventdate &&
      !eventAddress &&
      selectedCategoryList.length === 0 &&
      id === undefined
    ) {
      setEventNameErr(true);
      setEventLinkErr(true);
      setEventDateErr(true);
      setEventAddressErr(true);
      setAddCategoryErr(true);
      setEventDescErr(true);
      addErrorMesage("Upload a event file!");
      //enqueueSnackbar("Upload a event file!", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right', } });
    } else if (!eventName) {
      setEventNameErr(true);
    } else if (!eventLink) {
      setEventLinkErr(true);
    } else if (!eventDescription) {
      setEventDescErr(true);
    } else if (selectedCategoryList.length === 0) {
      setAddCategoryErr(true);
    } else if (!eventdate) {
      setEventDateErr(true);
    } else if (!eventAddress) {
      setEventAddressErr(true);
    } else if (
      id === 0 ? podFiles.length === 0 : eventDetails.eventurlImage.length === 0
    ) {
      //console.log("error in Events");
      setisEventImgeErrShow(true);
    } else {
      //Check if event with same name exists or not
      let respName = await getEventsByName();
      //console.log("respName: ", respName);
      if(id === 0 && respName.length > 0){
        addErrorMesage("Event with the same name already exists!");
        return;
      }else if(id !== 0 && respName.length > 0 && id !== respName[0].id){
        addErrorMesage("Event with the same name already exists!");
        return;
      }
      //Check if event with same name exists or not
      if(currentUserInfo !== undefined){
        setUploadEvents(true);
        setDragNDropText("Drop your file here");
        if (podFiles.length > 0) {
          addLoader();
          uploadEventsFiles();
        } else {
          if (id !== 0) {
            addLoader();
            setUploadProgress(25);
            createEvents(eventDetails.urlImage);
          } else {
            removeLoader();
            resetEvents(0);
          }
        }
      }
    }
  };
  const getEventsByName = async () =>{
    let resEvent = await getDataApi(
      constant.baseURL + "events?filter=name||$eq||"+eventDetails.eventName+"&filter=isDeleted||$eq||0&filter=status||$eq||1"
     
    );
    return resEvent;
  }
  const resetEvents = (progressValue) => {
    setUploadProgress(progressValue);
    setCoverImg(null);
    setPodFiles([]);
    setEventDetails({
      albumName: "",
      eventDescription: "",
      eventName: "",
      eventLink: "",
      eventdate:"",
      //categoryList: musicCategories,
      selectedCategoryList: [],
    });
  };
  const uploadEventsFiles = async () => {
    if (podFiles.length > 0) {
      let customHeaders = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer ",
      };
      let data = new FormData();
      podFiles.map((m) => {
        data.append(constant.uplFileParameter, m);
      });
      let res = await postDataApi(
        //constant.baseURL + "eventsfiles",
        constant.eventsFileUploadURL,
        data,
        customHeaders
      );
      //console.log("Upload events Files: ", res.data);
      if (
        res != undefined &&
        res.status != undefined &&
        res.status === 201 &&
        res.data != undefined &&
        res.data.data != undefined
      ) {
        //console.log("Uploaded events file: ", res.data.data);
        let coverFileName = "";
        coverFileName =
          coverImg != null
            ? res.data.data.filename
            : id !== 0
            ? eventDetails.eventurlImage
            : "";
        setUploadProgress(25);
        createEvents(coverFileName);
      } else {
        removeLoader();
        addErrorMesage("Error in uploading event files");
        // enqueueSnackbar('Error in uploading event files', {
        //     variant: 'error', anchorOrigin: {
        //     vertical: 'top',
        //     horizontal: 'right',
        //     },
        // });
      }
    } else {
      setUploadProgress(25);
      createEvents(eventDetails.eventurlImage);
    }
  };
  const createEvents = async (coverImageName) => {
    let jsonData = {
      name: eventDetails.eventName,
      eventlink: eventDetails.eventLink,
      description: eventDetails.eventDescription,
      artist: currentUserInfo.id,
      urlImage: coverImageName,
      eventdate: eventDetails.eventdate, //eventDetails.eventdate,
      address: eventDetails.eventAddress,
    };
    //console.log(jsonData);
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let respEvents;
    let sStatus = "";
    if (id !== 0) {
      respEvents = await patchDataApi(
        constant.baseURL + "events/" + id,
        formBody,
        authHeader
      );
      if (
        respEvents != undefined &&
        respEvents.status != undefined &&
        respEvents.status == 200
      ) {
        sStatus = "Success";
      }
    } else {
      respEvents = await postDataApi(
        constant.baseURL + "events",
        formBody,
        authHeader
      );
      if (
        respEvents != undefined &&
        respEvents.status != undefined &&
        respEvents.status == 201
      ) {
        sStatus = "Success";
      }
    }
    //console.log("respEvents: ", respEvents);
    //if (respEvents != undefined && respEvents.status != undefined && respEvents.status == 201) {
    if (sStatus == "Success") {
      setUploadProgress(75);
      return saveEventsCategories(respEvents.data.id).then((response) => {
        if (
          response != undefined &&
          response.length > 0 &&
          response[0] == true
        ) {
          removeLoader();
          setUploadProgress(100);
          addSuccessMesage(id !== 0 ? "Event Updated" : "Event Created");
          resetEvents(0);
          history.goBack();
        } else {
          removeLoader();
          resetEvents(0);
          history.goBack();
        }
      });
    } else {
      removeLoader();
      addErrorMesage("Error encountered while creating event!");
      resetEvents(0);
      if (id !== 0) {
        history.goBack();
      }
    }
  };
  const getSubcategories = async () => {
    let resSubcategories = await getDataApi(
      constant.baseURL + "subcategories?filter=category.id||$eq||3&filter=isDeleted||$eq||0"
    );
    //console.log("resSubcategories: ", resSubcategories);
    if (resSubcategories != undefined && resSubcategories.length > 0) {
      setEventCategories([...resSubcategories]);
      setEventDetails((prevState) => {
        return {
          ...prevState,
          categoryList: [...resSubcategories],
        };
      });
      if (id !== 0) {
        getEventsSubcategories(resSubcategories);
      }
    }
  };
  const getEventsSubcategories = async (categories) => {
    let resSubcategories = await getDataApi(
      constant.baseURL + "selectedeventcategories?filter=events.id||$eq||" + id
    );
    //console.log("getEventsSubcategories: ", resSubcategories);
    if (resSubcategories != undefined && resSubcategories.length > 0) {
      let sItems = [];
      resSubcategories.map((p) => {
        let fltCategories = categories.filter((c) => c.id == p.subcategory.id);
        sItems.push(fltCategories[0]);
      });
      setEventDetails((prevState) => {
        return {
          ...prevState,
          selectedCategoryList: [...sItems],
          existingCategoryList: [...resSubcategories],
        };
      });
    }
  };
  const saveEventsCategories = async (eventId) => {
    let promises_array = [];
    eventDetails.selectedCategoryList.map((item) => {
      promises_array.push(
        new Promise((resolve, reject) => {
          let jsonData = { events: eventId, subcategory: item.id };
          return createEventsCategories(jsonData)
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(false);
            });
        })
      );
    });
    if (id !== 0 && eventDetails.existingCategoryList.length > 0) {
      eventDetails.existingCategoryList.map((item) => {
        promises_array.push(
          new Promise((resolve, reject) => {
            let fExists = eventDetails.selectedCategoryList.filter(
              (x) => x.id == item.subcategory.id
            );
            if (fExists.length == 0) {
              return deleteEventCategory(item.id)
                .then(() => {
                  resolve(true);
                })
                .catch((error) => {
                  reject(false);
                });
            } else {
              resolve(true);
            }
          })
        );
      });
    }
    return Promise.all(promises_array);
  };
  const createEventsCategories = async (jsonData) => {
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let resExits = await getDataApi(
      constant.baseURL +
        "selectedeventcategories?filter=events.id||$eq||" +
        jsonData.events +
        "&filter=subcategory.id||$eq||" +
        jsonData.subcategory
    );
    //console.log("resExits: ", resExits);
    if (resExits.length == 0) {
      return await Promise.resolve(
        postDataApi(
          constant.baseURL + "selectedeventcategories",
          formBody,
          authHeader
        )
      )
        .then((response) => {
          if (
            response != undefined &&
            response.status != undefined &&
            response.status == 201
          ) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          return false;
        });
    } else {
      return false;
    }
  };
  const deleteEventCategory = async (id) => {
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return await Promise.resolve(
      await deleteDataApi(
        constant.baseURL + "selectedEventcategories/" + id,
        authHeader
      )
    )
      .then((resDel) => {
        //console.log("resDel: ", resDel);
        if (resDel != undefined && resDel.status == 200) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
  };
  const cancelEvents = () => {
    history.goBack();
  };
  useEffect(() => {
    getSubcategories();
  }, []);
  return (
    <div>
      <section className="community-room-banner event-upload-page">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="upload-music-background">
                <div className="row">
                  <div className="col-12 col-md-12">
                    {coverImg === null ? (
                      <FileDrop
                        onDragOver={() =>
                          setDragNDropText("Drop your image here!!")
                        }
                        onDragLeave={() =>
                          setDragNDropText("Drop your file here")
                        }
                        onDrop={onDrop}
                      >
                        <div className="event-upload-page">
                          <img
                            className="upload-image-icon"
                            src={
                              eventDetails.eventurlImage != undefined &&
                              eventDetails.eventurlImage != ""
                                ? eventDetails.eventurlImage
                                : uploadImage1
                            }
                            alt="upload picture"
                            style={{ width: 70, height: 70 }}
                          />
                          <div className="upload-text">Upload Cover Image</div>
                          <div className="upload-text-subtitle">
                            Drop your file here or browse
                          </div>
                          <div className="upload-text-subtitle">Or</div>
                          <ImageUploader
                            className="imageUploaderNew"
                            withIcon={false}
                            buttonText="Browse"
                            label=""
                            onChange={(file) => onUploadImg(file)}
                            imgExtension={constant.supportedImageFiles}
                            singleImage={true}
                          />
                        </div>
                      </FileDrop>
                    ) : (
                      <FileDrop
                        onDragOver={() =>
                          setDragNDropText("Drop your image here!!")
                        }
                        onDragLeave={() =>
                          setDragNDropText("Drop your file here")
                        }
                        onDrop={onDrop}
                      >
                        <div className="event-upload-page">
                          <img
                            className="upload-image-icon"
                            style={{ height: 70, width: 70 }}
                            onClick={() => setCoverImg(null)}
                            id="uploaded_cover_img"
                            src={coverImg}
                            alt="cover-image"
                          />
                          <ImageUploader
                            className="imageUploaderNew"
                            withIcon={false}
                            buttonText="Browse"
                            label=""
                            onChange={(file) => onUploadImg(file)}
                            imgExtension={constant.supportedImageFiles}
                            singleImage={true}
                          />
                        </div>
                      </FileDrop>
                    )}
                    {isEventImgeErrShow && (
                      <div className="errorText" style={{ marginTop: "0px" }}>
                        Please Upload Image File
                      </div>
                    )}
                    
                    <br />
                    <div className="featured-artists-title">Event Details</div>
                    <div className="floating-label mt-4">
                      <input
                        className="floating-input"
                        type="text"
                        placeholder=" "
                        maxLength={255}
                        onChange={(e) => {
                          setEventDetails({
                            ...eventDetails,
                            eventName: e.target.value,
                          });
                          setEventNameErr(false);
                        }}
                        value={eventDetails.eventName}
                      />
                      <span className="highlight"></span>
                      <label className="label">Event Name</label>
                      {isEventNameErrShow && (
                        <div className="errorText" style={{ marginTop: "0px" }}>
                          Enter name
                        </div>
                      )}
                    </div>
                    <div className="floating-label mt-4">
                      <textarea
                        className="floating-input"
                        rows="4"
                        type="text"
                        maxLength={255}
                        placeholder=" "
                        onChange={(e) => {
                          setEventDetails({
                            ...eventDetails,
                            eventAddress: e.target.value,
                          });
                          setEventAddressErr(false);
                        }}
                        value={eventDetails.eventAddress}
                      ></textarea>
                      <span className="highlight"></span>
                      <label className="label">Address</label>
                      {isEventAddressErrShow && (
                        <div className="errorText" style={{ marginTop: "0px" }}>
                          Enter event address
                        </div>
                      )}
                    </div>
                    <div className="floating-label mt-4">
                      <textarea
                        className="floating-input"
                        rows="4"
                        type="text"
                        placeholder=" "
                        onChange={(e) => {
                          setEventDetails({
                            ...eventDetails,
                            eventDescription: e.target.value,
                          });
                          setEventDescErr(false);
                        }}
                        value={eventDetails.eventDescription}
                      ></textarea>
                      <span className="highlight"></span>
                      <label className="label">Description</label>
                      {isEventDescErrShow && (
                        <div className="errorText" style={{ marginTop: "0px" }}>
                          Enter event descrition
                        </div>
                      )}
                    </div>
                    <div className="floating-label mt-4">
                      <input
                        className="floating-input"
                        type="text"
                        placeholder=" "
                        maxLength={255}
                        onChange={(e) => {
                          setEventDetails({
                            ...eventDetails,
                            eventLink: e.target.value,
                          });
                          setEventLinkErr(false);
                        }}
                        value={eventDetails.eventLink}
                      />
                      <span className="highlight"></span>
                      <label className="label">Booking Link</label>
                      {isEventLinkErrShow && (
                        <div className="errorText" style={{ marginTop: "0px" }}>
                          Enter event link
                        </div>
                      )}
                    </div>
                    <div className="floating-label mt-4">
                      <DatePicker
                        selected={eventDetails.eventdate}
                        onChange={(e) => {
                          //console.log(e)
                          setEventDetails({
                            ...eventDetails,
                            eventdate: e,
                          });
                          setEventDateErr(false);
                        }}
                        showTimeSelect
                        minDate={new Date()}
                        dateFormat="MMMM d, yyyy h:mm aa"
                      />
                      
                      {isEventDateErrShow && (
                        <div className="errorText" style={{ marginTop: "0px" }}>
                          Enter event date
                        </div>
                      )}
                    </div>
                    <div className="floating-label0 mt-4">
                      <Multiselect
                        options={eventDetails.categoryList}
                        selectedValues={eventDetails.selectedCategoryList}
                        onSelect={onSelectCategory}
                        onRemove={onRemoveSelectCategory}
                        displayValue="name"
                        placeholder="Add Category"
                        style={multiSelectStyle}
                        // showCheckbox={true}
                        // closeIcon="circle"
                        customCloseIcon={
                          <AiFillCloseSquare className="multiselect-close-icon" />
                        }
                      />
                      {isAddCategoryErrShow && (
                        <div className="errorText" style={{ marginTop: "0px" }}>
                          Add Category
                        </div>
                      )}
                    </div>

                    <div className="row justify-content-around">
                      <div className="col-6 col-md-4">
                        <button
                          className="cancel-btn"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={cancelEvents}
                        >
                          <span aria-hidden="true">Cancel</span>
                        </button>
                      </div>
                      <div className="col-6 col-md-4">
                        <button
                          className="add-to-playlist-btn"
                          onClick={onUpload}
                          style={{ width: "100%" }}
                        >
                          {id !== 0 ? "Update" : "Create"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CreateEvents;
