import React, { useState, useEffect } from "react";
import MyItems from "../../components/MyItems/MyItems";

function MyPodcasts() {
  return (
    <>
        <MyItems type="Podcasts" headerTitle="My Podcasts" showLikes={true}/>
    </>    
  );
}

export default MyPodcasts;
