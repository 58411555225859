import { INCREMENT, DECREMENT } from './counter.types';


export const increaseCounter = (p,q,r,s,t) => {

    return {

        type: INCREMENT,
        data:{p,q,r,s,t}

    };

};

export const decreaseCounter = () => {

    return {

       type: DECREMENT,

    };

};