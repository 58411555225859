import React, { useState, useEffect } from "react";
import { constant } from "../../utility/constant";
import { getDataApi, postDataApi, deleteDataApi } from "../../utility/apiCall";
import { colors } from "../../utility/colors";
import podcastBanner from "../../assets/Images/podcastBanner.png";
import { FaShareAlt } from "react-icons/fa";
import UploadHere from "../../components/UploadHere/UploadHere";
import HomeNewsList from "../../components/HomeNewsList/HomeNewsList";
import { useHistory } from "react-router-dom";
import NewsEventCard from "../../components/NewsEventCard/NewsEventCard";
import moment from "moment";
import Categories from "../../components/Categories/Categories";
import {
  VerifyLoginExistOrNot,
  verifyLoginUserType,
} from "../../utility/VerifyLoginExistOrNot";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { hashCodeUrl } from "../../utility/EncodeAndDecodeUrl";
import mike from "../../assets/Images/mike.svg";
import { FaRegCommentDots } from "react-icons/fa";
import mikeFill from "../../assets/Images/mikeFill.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CommentsModal from "../../components/CommentsModal/CommentsModal";
import { DisableButtonLoader } from "../../utility/CustomLoader";

function Podcast() {
  let history = useHistory();
  let path = useLocation();
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const [showViewMore, setShowViewMore] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCategory, setPageCategory] = useState(1);
  const [limit, setLimit] = useState(20);
  const [podcastList, setPodcastlist] = useState([]);
  const [categoryName, setCategoryName] = useState("Latest Podcasts");
  const [categoryId, setCategoryId] = useState(0);
  const [latestPodcastList, setLatestPodcastList] = useState([]);
  const [update, setUpdate] = useState(false);

  const [commentTopPodcastsInputText, setCommentTopPodcastsInputText] = useState("");
  const [commentsCount, setCommentsCount] = useState(0);
  const [podcastsLikesList, setPodcastsLikesList] = useState([]);
  const [podcastsLikesData, setPodcastsLikesData] = useState([]);
  const [podcastsResetCount, setPodcastsResetCount] = useState(0);
  const [selectedPodcasts, setSelectedPodcasts] = useState(undefined);
  const [showCommentTopPodcastsModal, setShowCommentTopPodcastsModal] = useState(false);

  const [podcastsResetCountByCategory, setPodcastsResetCountByCategory] = useState(0);
  const [commentsCountByCategory, setCommentsCountByCategory] = useState(0);
  const [disableItem, setDisableItem] = useState(false);
  const [mId, setMId] = useState(0);


  const getPodcasts = async (pageNumber) => {
    let apiurl = constant.baseURL +
      "podcasts/getPodcastsWithUserLike?page=" +
      pageNumber +
      "&limit=" +
      limit
    if (currentUserInfo) {
      apiurl += "&userId=" + currentUserInfo.id;
    }
    let resPodcasts = await getDataApi(apiurl);
    //console.log("resPodcasts: ", resPodcasts);
    if (
      resPodcasts !== undefined &&
      resPodcasts.data !== undefined &&
      resPodcasts.data.length > 0
    ) {
      let itms = [];
      //let podcastsIds = "";
      resPodcasts.data.map((p) => {
        let dfltURL =
          p.podcastType == "video" ? constant.videoURL : constant.musicURL;
        itms.push({
          id: p.id,
          img:
            p.urlImage != undefined && p.urlImage != null && p.urlImage != ""
              ? constant.podcastFileURL + p.urlImage
              : dfltURL,
          title: p.name,
          artistName: p.artist.firstName + " " + p.artist.lastName,
          likes: p.likes,
          comments: p.comments,
          podcastType: p.podcastType,
          urlPodcast: p.urlPodcast,
          userlike: p.userlike
        });
      });
      if (pageNumber === 1) {
        setPodcastlist(itms);
        setLatestPodcastList(itms);
      } else {
        setPodcastlist([...podcastList, ...itms]);
        setLatestPodcastList([...latestPodcastList, ...itms]);
      }
      //show hide view more page
      if (resPodcasts.page < resPodcasts.pageCount) {
        setShowViewMore(true);
      } else {
        setShowViewMore(false);
      }

    }
  };
  const getPodcastsLikes = async (str) => {
    let resPodcastsLikes = await getDataApi(
      constant.baseURL +
      "podcastlikes?filter=user.id||$eq||" +
      currentUserInfo?.id +
      "&filter=podcasts.id||$in||" +
      str
    );
    if (resPodcastsLikes != undefined && resPodcastsLikes.length > 0) {
      setTimeout(() => {
        setPodcastsLikesList(resPodcastsLikes);
        setPodcastsLikesData(resPodcastsLikes);
      }, 50);

    } else {
      setPodcastsLikesList([]);
      setPodcastsLikesData([]);
    }
  };
  const createPodcastsLikes = async (podcastsId) => {
    if (VerifyLoginExistOrNot()) {
      let jsonData = {
        user: currentUserInfo.id,
        podcasts: podcastsId,
      };
      setDisableItem(true);
      setMId(podcastsId);
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resppodcastsLike = await postDataApi(
        constant.baseURL + "podcastlikes",
        formBody,
        authHeader
      );
      if (
        resppodcastsLike != undefined &&
        resppodcastsLike.status != undefined &&
        resppodcastsLike.status == 201 && resppodcastsLike.data !== undefined
      ) {
        setLikes(podcastsId, resppodcastsLike.data, undefined);//setLikes(podcastsId);
        setDisableItem(false);
      }else{
        setDisableItem(false);
      }
    }
    setDisableItem(false);
  };
  const resetPodcastsLike = async (podcastsId, likeId) => {
    if (VerifyLoginExistOrNot()) {
      setDisableItem(true);
      setMId(podcastsId);
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resp = await deleteDataApi(
        constant.baseURL + "podcastlikes/" + likeId,
        authHeader
      );
      if (resp != undefined && resp.status != undefined && resp.status == 200) {
        setLikes(podcastsId, undefined, likeId);//setLikes(podcastsId);
        setDisableItem(false);
      }else{
        setDisableItem(false);
      }

    }
    setDisableItem(false);
  };

  const setCommentUpdate = () => {
    if (categoryId === 0) {
      setCommentsCount(commentsCount + 1);
    } else {
      setCommentsCountByCategory(commentsCountByCategory + 1);
    }
  }
  const getPodcastsByCategory = async (pageNumber, id) => {
    let apiURL = constant.baseURL +
      "podcasts/getPodcastsByCategoryWithUserLike" +
      "?page=" +
      pageNumber +
      "&limit=" +
      limit + '&categoryId=' + id
    if (currentUserInfo) {
      apiURL += "&userId=" + currentUserInfo.id;
    }
    let resPodcastsByCategory = await getDataApi(
      apiURL
      // constant.baseURL +
      // "selectedPodcastcategories/getPodcastsByCategory/" +
      // id +
      // "?&page=" +
      // pageNumber +
      // "&limit=" +
      // limit
    );
    //console.log("resPodcastsByCategory: ", resPodcastsByCategory);
    if (
      resPodcastsByCategory !== undefined &&
      resPodcastsByCategory.data !== undefined &&
      resPodcastsByCategory.data.length > 0
    ) {
      let itms = [];
      //let podcastsIds = "";
      resPodcastsByCategory.data.map((p) => {
        //c.podcasts.map((p) => {
        let dfltURL =
          p.podcastType == "video"
            ? constant.videoURL
            : constant.musicURL;
        itms.push({
          id: p.id,
          img:
            p.urlImage !== undefined &&
              p.urlImage !== null &&
              p.urlImage !== ""
              ? constant.podcastFileURL + p.urlImage
              : dfltURL,
          title: p.name,
          artistName:
            p.artist.firstName + " " + p.artist.lastName,
          likes: p.likes,
          comments: p.comments,
          podcastType: p.podcastType,
          urlPodcast: p.urlPodcast,
          userlike: p.userlike
        });
        //});
      });
      if (pageNumber == 1) {
        setPodcastlist(itms);
      } else {
        setPodcastlist([...podcastList, ...itms]);
      }
      //show hide view more page
      if (
        resPodcastsByCategory.page <
        Math.round(resPodcastsByCategory.totalCount / limit)
      ) {
        setShowViewMore(true);
      } else {
        setShowViewMore(false);
      }
      // let pItms = [...podcastList, ...itms];
      // pItms.map((item) => {
      //   podcastsIds += item.id + ",";
      // });
      // getPodcastsLikes(
      //   podcastsIds.substr(0, podcastsIds.lastIndexOf(","))
      // );
    } else {
      setPodcastlist([]);
    }
  };
  const onClickViewMore = () => {
    if (categoryId === 0) {
      setPage(page + 1);
      getPodcasts(page + 1);
    } else {
      setPageCategory(pageCategory + 1);
      getPodcastsByCategory(pageCategory + 1, categoryId);
    }
  };
  const loadPodCastByCategory = (category, id) => {
    window.scrollTo(0, 800);
    //console.log("Podcast Category: ", category);
    setCategoryName(category);
    setCategoryId(id);
    if (id === 0) {
      setPage(1);
      getPodcasts(1);
    } else {
      setPageCategory(1);
      getPodcastsByCategory(1, id);
    }
  };
  const navigateToPodcastDetails = (index) => {
    let indexItem;
    indexItem =
      categoryId !== 0 ? podcastList[index] : latestPodcastList[index];
    //console.log(indexItem)
    //console.log(indexItem, "podcast");
    history.push({
      // pathname: `PodcastDetails/${hashCodeUrl(indexItem.id)}/${hashCodeUrl(
      //   indexItem.title
      // )}`,
      // indexItem.podcastType == "video" ? 'Videoplayer' : 
      // indexItem.podcastType == "video" ? { variant: "homepodcastjs", url: constant.podcastFileURL + indexItem.urlPodcast } :
      pathname: `PodcastDetails/${hashCodeUrl(indexItem.id)}`,
      state: {
        id: indexItem.id,
        item: indexItem,
      },
    });
  };
  useEffect(() => {
    setPodcastsResetCount(0);
    setCommentsCount(0);
    getPodcasts(page);
  }, []);
  useEffect(() => {
    getPodcasts(page);
  }, [commentsCount]);

  useEffect(() => {
    getPodcastsByCategory(pageCategory, categoryId);
  }, [podcastsResetCountByCategory, commentsCountByCategory]);
  // const createEpisodeLike = async (episodeId) => {
  //   if (currentUserInfo) {
  //     let jsonData = { user: currentUserInfo.id, podcasts: episodeId };
  //     var formBody = [];
  //     for (var property in jsonData) {
  //       var encodedKey = encodeURIComponent(property);
  //       var encodedValue = encodeURIComponent(jsonData[property]);
  //       formBody.push(encodedKey + "=" + encodedValue);
  //     }
  //     formBody = formBody.join("&");
  //     var authHeader = {
  //       Accept: "application/json",
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     };
  //     let resBigStageLike = await postDataApi(
  //       constant.baseURL + "podcastlikes",
  //       formBody,
  //       authHeader
  //     );
  //     console.log(resBigStageLike)
  //     if (
  //       resBigStageLike?.data !== undefined
  //     ) {
  //       setLikes(episodeId, resBigStageLike.data, undefined);
  //     }
  //   }
  //   else {
  //     history.push({
  //       pathname: `/login`,
  //       // state: {
  //       //   prevPath: path.pathname,
  //       //   userId: props.currentUserId,
  //       // },
  //     });
  //   }
  // };

  // const resetEpisodeLike = async (episodeId, likeId) => {
  //   console.log(episodeId, likeId)
  //   if (currentUserInfo) {
  //     var authHeader = {
  //       Accept: "application/json",
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     };
  //     let resp = await deleteDataApi(
  //       constant.baseURL + "podcastlikes/" + likeId,
  //       authHeader
  //     );
  //     if (
  //       resp !== undefined &&
  //       resp.status !== undefined &&
  //       resp.status === 200
  //     ) {
  //       setLikes(episodeId, undefined, likeId);
  //     }
  //   } else {
  //     history.push({
  //       pathname: `/login`,
  //       // state: {
  //       //   prevPath: path.pathname,
  //       //   userId: props.currentUserId,
  //       // },
  //     });
  //   }
  // };
  const setLikes = (id, data, likeId) => {
    let bgItems = [...podcastList]
    //console.log(bgItems)
    if (data !== undefined) {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike.push(data);
          itm.likes.push(data);
        }
      });
    } else {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike = [];
          itm.likes.map((lkitm, index0) => {
            if (lkitm.id === likeId) {
              itm.likes.splice(index0, 1);
            }
          });
        }
      });
    }
    setPodcastlist(bgItems)
  };
  const renderLikes = (likeData) => {
    //console.log(likeData)
    if (likeData.userlike.length > 0) {
      return (
        <img className="w-24" src={mikeFill} alt="Unmike" style={{ cursor: "pointer" }}
          //onClick={() => resetEpisodeLike(likeData.id, likeData.userlike[0].id)} 
          onClick={() => {
            if (VerifyLoginExistOrNot()) resetPodcastsLike(likeData.id, likeData.userlike[0].id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    } else {
      return (
        <img className="w-24" src={mike} alt="Mike" style={{ cursor: "pointer" }}
          //onClick={() => createEpisodeLike(likeData.id)} 
          onClick={() => {
            if (VerifyLoginExistOrNot()) createPodcastsLikes(likeData.id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    }
  };
  const renderPodcasts = (data) => {
    if (data.length > 0) {
      return data.map((item, index) => {
        return (
          <div
            key={index}
            className="col-6 col-lg-3"
            style={{ marginBottom: 15 }}

          >
            <div className="card">
              <img
                className=""
                src={item.img}
                alt={item.title}
                style={{ height: 135, width: "100%", cursor: "pointer" }}
                onClick={() => navigateToPodcastDetails(index)}
              />
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h6 title={item.title} className="fs-16 mt-1 fw-600 wrapText">
                      {item.title}
                    </h6>
                    <p className="fs-14 mt-3 fw-400 wrapText" title={item.artistName}>
                      {item.artistName}
                    </p>
                    <div className="line"></div>
                  </div>
                </div>
                <div className="col-12 col-md-8" style={{ marginTop: -8, marginLeft: -12 }}>
                  <div className="form-row">
                    <div className="col-auto">
                      <div>
                        {disableItem && mId === item.id ? DisableButtonLoader() :
                          <>
                            {renderLikes(item)}{" "}
                            <span className="fs-14 fw-500 pl-1">
                              {item.likes !== undefined ? item.likes.length : 0}
                            </span>
                          </>
                        }
                      </div>
                    </div>
                    <div className="col-auto">
                      <div>
                        <FaRegCommentDots
                          color="#b09b2f"
                          title="Comments"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (VerifyLoginExistOrNot()) {
                              setSelectedPodcasts(item);
                              setShowCommentTopPodcastsModal(true);
                            } else {
                              history.push({
                                pathname: `/login`,
                                state: {
                                  prevPath: path.pathname,
                                  userId:
                                    currentUserInfo !== undefined
                                      ? currentUserInfo.id
                                      : 0,
                                },
                              });
                            }
                          }}
                        />
                        <span className="fs-14 fw-500 pl-3">
                          {item.comments !== undefined ? item.comments.length : 0}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="no-data-found" style={{ paddingLeft: 15 }}>
          {constant.noDataFoundLabel}
        </div>
      );
    }
  };
  return (
    <div>
      <section className="community-room-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-4">
              <img
                className="img-fluid "
                src={podcastBanner}
                alt="Podcast"
              />
            </div>
            <div className="col-12 col-md-8">
              <div className="communicate-right-box">
                <div className="wrapText fs-40 fs-sm-30 mt-3 mt-md-0 fw-700">Update yourself</div>
                <div className="fs-25 fw-500 mt-3 mb-3">With Latest Podcasts</div>
                <p className="fs-15 fw-500 lh-20">
                  Listen to your favorite Podcast episodes with videos totally free. You can watch and listen to the podcasts full episodes anywhere and anytime.
                </p>
                <AnchorLink href="#ExplorePodcast">
                  <button className="btn btn-primary" type="submit">
                    Explore Podcast
                  </button>
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      {verifyLoginUserType() && (
        <UploadHere
          message="Do you have any new Topic on Podcast to share ?"
          buttonText="Upload"
          from="Podcast"
        />
      )}

      <section className="artist" id="ExplorePodcast">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-auto">
              <div className="fs-20 fw-700">{categoryName}</div>
            </div>
          </div>
          <div className="form-row mt-3">
            {categoryId === 1
              ? renderPodcasts(latestPodcastList)
              : renderPodcasts(podcastList)}
          </div>
          {podcastList.length > 0 && showViewMore === true && (
            <div className="row justify-content-center mt-3">
              <div className="col-auto">
                <button className="btn dark" onClick={onClickViewMore}>
                  Show More
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
      <Categories
        onClick={(category, id) => loadPodCastByCategory(category, id)}
      />
      {showCommentTopPodcastsModal === true && selectedPodcasts !== undefined && (
        <CommentsModal
          update={update}
          setUpdate={setCommentUpdate}
          type="CommentHomePodcast"
          show={showCommentTopPodcastsModal}
          id={selectedPodcasts.id}
          title={"Comment - " + selectedPodcasts.title}
          currentUserId={currentUserInfo !== undefined ? currentUserInfo.id : 0}
          handleClose={() => {
            setShowCommentTopPodcastsModal(false);
          }}
          inputText={commentTopPodcastsInputText}
          setInputText={(val) => {
            setCommentTopPodcastsInputText(val);
          }}
          isComment={true}
        />
      )}
    </div>
  );
}

export default Podcast;
