import axios from "axios";

export async function getDataApi(URL) {
  //console.log(URL);
  try {
    let response = await fetch(URL).then((response) => response.json());
    return response;
  } catch (error) {
    return error;
  }
}

export async function getDataApiWithBlob(URL) {
  try {
    let response = await fetch(URL).then((response) => response.blob());
    return response;
  } catch (error) {
    return error;
  }
}

export async function postDataApi(URL, data, header) {
  //console.log(URL, header);
  try {
    let response = await axios
      .post(URL, data, header)
      .then((response) => {
        //console.log(response);
        return response;
      })
      .catch((error) => {
        //console.log("error:", error);
      });
    return response;
  } catch (error) {
    return error;
  }
}

export async function patchDataApi(URL, data, header) {
  //console.log(URL, header);
  try {
    let response = await axios
      .patch(URL, data, header)
      .then((response) => {
        //console.log(response);
        return response;
      })
      .catch((error) => error);
    return response;
  } catch (error) {
    return error;
  }
}

export async function deleteDataApi(URL, header) {
  //console.log(URL, header);
  try {
    let response = await axios
      .delete(URL, header)
      .then((response) => {
        //console.log(response);
        return response;
      })
      .catch((error) => error);
    return response;
  } catch (error) {
    return error;
  }
}
