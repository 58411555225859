import React, { useState } from "react";
import logo from "../../assets/Images/logo.png";
import { Link, NavLink } from "react-router-dom";
import { verifyLoginUserType } from "../../utility/VerifyLoginExistOrNot";
import { GetPath } from "../../utility/GetPath";
import { constant } from "../../utility/constant";
// import AppStoreButton from "../../assets/Images/AppStoreButton.svg";
// import GooglePlayButton from "../../assets/Images/GooglePlayButton.svg";
import AppStoreButton from "../../assets/Images/footer-App-Store.png";
import GooglePlayButton from "../../assets/Images/footer-Google-Play.png";
import DownloadArrowGold from "../../assets/Images/DownloadArrowGold.svg"
import { BrowserView, MobileView, isBrowser, isMobile, IOSView, isAndroid } from 'react-device-detect'

const Footer = () => {
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const onClickGooglePlayStore = () => {
    window.open(constant.playStoreURL, "_blank");
  }
  const onClickAppStore = () => {
    window.open(constant.appStoreURL, "_blank");
  }
  const onClickDownloadApp = () => {
    if (isAndroid) {
      window.open(constant.playStoreURL, "_blank");
    } else {
      window.open(constant.appStoreURL, "_blank");
    }
  }
  return (
    <>
      {GetPath() && (
        <>
          <section className="footer d-none d-md-block">
            <div className="container">
              <div className="footer-line"></div>
              <div className="footer-logo">
                <img className="img-fluid footer-custom-logo" src={logo} alt="" />
              </div>
              <div className="footer-menu">
                <NavLink className="nav-link footer-main-menu" to="/">
                  {constant.homeLabel} <span className="sr-only">(current)</span>
                </NavLink>
                <NavLink className="nav-link footer-main-menu" to="/BigStage">
                  {constant.theBigStageLabel}
                </NavLink>
                <NavLink
                  className="nav-link footer-main-menu"
                  to="/CommunityRoom"
                >
                  {constant.communityRoomLabel}
                </NavLink>
                <NavLink
                  className="nav-link footer-main-menu"
                  to="/FeaturedArtist"
                >
                  {constant.featuredArtistLabel}
                </NavLink>
                {verifyLoginUserType() && (
                  <NavLink
                    className="nav-link footer-main-menu"
                    as={Link} to="/UploadMusic"
                  >
                    {constant.uploadMusicLabel}
                  </NavLink>
                )}

                <NavLink className="nav-link footer-main-menu" to="/Podcast">
                  {constant.podcastLabel}
                </NavLink>
                <NavLink className="nav-link footer-main-menu" to="/News">
                  {constant.newsLabel}
                </NavLink>
                <NavLink className="nav-link footer-main-menu" to="/Events">
                  {constant.eventsLabel}
                </NavLink>

              </div>
              <div className="footer-menu">
                <NavLink className="nav-link footer-main-menu" to="/Aboutus">
                  {constant.aboutusLabel}
                </NavLink>
                <NavLink className="nav-link footer-main-menu" to="/Privacy">
                  {constant.privacyLabel}
                </NavLink>
                {currentUserInfo ? <NavLink className="nav-link footer-main-menu" to="/Support">
                  {constant.supportLabel}
                </NavLink> : null}
              </div>
              <div className="copyright col-12 col-md-12 app-store-width-set" style={{ display: 'flex' }}>
                <div className="col-4 col-md-4" style={{ cursor: 'pointer' }}>
                  <img className="App-Store" src={GooglePlayButton} onClick={() => onClickGooglePlayStore()} alt="Google Play Store" />
                </div>
                <div className="col-4 col-md-4" style={{ top: 18 }}>
                  Copyright {new Date().getFullYear()} By Multi Music Moguls
                </div>
                <div className="col-4 col-md-4" style={{ cursor: 'pointer' }}>
                  <img className="Google-Store" src={AppStoreButton} alt="App Store" onClick={() => onClickAppStore()} />
                </div>

              </div>
            </div>
          </section>

          {/* <div className="footer-phone d-block d-md-none ">
            <div className="footer-contain">
              <div className="one">
                <div className="d-flex">
                  <div className="image mr-4">
                    <img className="logofooter" src={logo} alt="" />
                  </div>
                  <div className="image">
                    <p className="footer-text">Download the Multi  Moguls App</p>
                    <p className="footer-text-sub">A digital music, podcast & video serice app</p>
                  </div>
                </div>
              </div>
              <div className="two">
                <img className="footer-aro" src={DownloadArrow} alt="" />
              </div>
            </div>
          </div> */}
           <button className="button-footer-show d-block d-md-none" onClick={() => onClickDownloadApp()} title={"Download App"}>
            <img className="" src={DownloadArrowGold} alt="Download App" />
          </button>
        </>
      )}
    </>
  );
};
export default Footer;
