import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    let flag = pathname.search("FeaturedArtistDetail");

    if (flag === 1) {
      //console.log(",");
    }
    else window.scrollTo(0, 0);
    // window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
