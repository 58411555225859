import ModalDialog from "react-bootstrap/ModalDialog";
import Modal from "react-bootstrap/Modal";
import reactDom from "react-dom";
import ReactDOM from "react-dom";
import Loader from "react-loader-spinner";
export function addLoader() {
  ReactDOM.render(
    <Modal
      centered
      fullscreen={true}
      show={true}
      contentClassName="custom-loader"
      //   open={true}
      //   fullWidth
      //   fullScreen
      //   PaperComponent="div"
      //   PaperProps={{
      style={{
        justifyContent: "center",
        alignItems: "center",
        background: "transparent",
        backgroundColor: "transparent",
      }}
      //   }}
    >
      {/* <div> hello</div> */}
      <Loader
        type="Circles"
        // color="#00BFFF"
        color="#ffd874"
        // secondaryColor="blue"
        height={100}
        width={100}
        // timeout={3000}
      />
      {/* <CircularProgress color="secondary" size={100} thickness={1.5} /> */}
    </Modal>,
    document.getElementById("loader")
  );
}
export function removeLoader() {
  reactDom.unmountComponentAtNode(document.getElementById("loader"));
}

export function DisableButtonLoader() {
  return (
    <Loader
      type="Circles"
      style={{ marginRight: "2px" }}
      // color="#202020"
      color="#fff"
      height={20}
      width={20}
    />
  );
}
