import React, { useState, useEffect } from "react";
import { constant } from "../../utility/constant";
import {
  getDataApi,
  postDataApi,
  patchDataApi,
  deleteDataApi,
} from "../../utility/apiCall";
import { useHistory } from "react-router-dom";
import close from "../../assets/Images/close.png";
import uploadImage1 from "../../assets/Images/uploadImage1.png";
import uploadMusic2 from "../../assets/Images/uploadMusic2.png";
import upload from "../../assets/Images/upload.png";
import musicImg from "../../assets/Images/music.png";
import videoImg from "../../assets/Images/video.png";
import { FileDrop } from "react-file-drop";
import ImageUploader from "react-images-upload";
import Multiselect from "multiselect-react-dropdown";
import { colors } from "../../utility/colors";
import {
  addErrorMesage,
  addSuccessMesage,
} from "../../components/CustomToster/CustomToaster";
import { CircularProgressbar } from "react-circular-progressbar";
import { AiFillCloseSquare } from "react-icons/ai";
import { addLoader, removeLoader } from "../../utility/CustomLoader";

function UploadMusic({ location }) {
  let history = useHistory();
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const multiSelectStyle = {
    multiselectContainer: {
      color: "white",
      fontSize: 14,
    },
    option: {
      // To change css for dropdown options
      color: colors.colors13,
    },
    chips: {
      background: colors.colors13,
      fontSize: 15,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 8,
      paddingBottom: 8,
      borderRadius: 8,
    },
    searchBox: {
      border: "1px solid #8186AF",
      borderRadius: "2px",
      //fontFamily: 'Mr Eaves Mod OT',
      fontSize: 15,
      minHeight: 40,

      //width: 395,
    },
  };
  const [showAlbumSection, setShowAlbumSection] = useState(true);
  const [showMusicSection, setShowMusicSection] = useState(false);
  let audioReg = /\.(?:mp3|ogg|acc|wav|m4p)$/i;
  //let videoReg = /\.(?:webm|flv|vob|mp4|mov|mpeg)$/i;
  let videoReg = /\.(?:ogm|wmv|flv|vob|mpg|webm|ogv|mov|asx|mpeg|mp4|m4v|avi|mpeg4)$/i;
  const [loginUser, setLoginUser] = useState(undefined);
  const [coverImg, setCoverImg] = useState(null);
  const [musicCategories, setMusicCategories] = useState([]);
  const [albumCoverImg, setAlbumCoverImg] = useState("");
  const [disableImgDelete, setDisableImgDelete] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [dragNDropText, setDragNDropText] = useState(
    "Drag & Drop your image here or browse"
  );
  const [isMusicImgeErrShow, setisMusicImgeErrShow] = useState(false);

  const [albumDetails, setAlbumDetails] = useState({
    albumName:
      location.state !== undefined && location.state !== null && location.state.item !== undefined
        ? location.state.item.name
        : "",
    albumType:
      location.state !== undefined && location.state !== null && location.state.item !== undefined
        ? location.state.item.albumType
        : "music",
    albumurlImage:
      location.state !== undefined && location.state !== null && location.state.item !== undefined
        ? location.state.item.urlImage
        : "",
    categoryList: [],
    selectedCategoryList: [],
    existingCategoryList: [],
    id:
      location.state !== undefined && location.state !== null && location.state.id !== undefined
        ? location.state.id
        : 0,
    existingMusicList: [],
  });

  const [isAlbumNameErrShow, setAlbumNameErr] = useState(false);
  const [isAddCategoryErrShow, setAddCategoryErr] = useState(false);
  const [isUploadSongsSectionShow, setUploadSongsSection] = useState(false);
  const [songsList, setSongsList] = useState([]);
  const [removeSongsList, setRemoveSongsList] = useState([]);
  const [songsListIDCount, setSongsListIDCount] = useState(0);
  const [isUploadSong, setUploadSong] = useState(false);

  const onDrop = (file) => fileValidation(file[0]);

  const onUploadImg = (file) => fileValidation(file[0]);

  const fileValidation = (file) => {
    if (file !== undefined) {
      // Allowing file type
      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(file.name)) {
        addErrorMesage("Please upload a image file!");
        //enqueueSnackbar("Please upload a image file!", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right', } });
        file = "";
        setDragNDropText("Drop your image here or browse");
        return false;
      } else {
        let imageSrc = URL.createObjectURL(file);
        setDragNDropText("Drop your image here or browse");
        setCoverImg(imageSrc);
        setAlbumCoverImg(file);
      }
    }
  };
  const getMusicsByAlbum = async () => {
    let resMusics = await getDataApi(
      constant.baseURL + "musics?filter=album.id||$eq||" + albumDetails.id
    ); //location.state.id
    //console.log("resMusics: ", resMusics);
    if (resMusics != undefined && resMusics.length > 0) {
      let itms = [];
      resMusics.map((m, i) => {
        itms.push({
          id: i + 1,
          file: {
            name: m.title,
            size:
              m.size !== undefined && m.size !== null && m.size !== ""
                ? m.size
                : 0,
          },
          isExists: true,
          itemId: m.id,
        });
      });
      setTimeout(() => {
        setSongsListIDCount(resMusics.length + 1);
      });
      setSongsList([...itms]);
      setAlbumDetails((prevState) => {
        return { ...prevState, existingMusicList: resMusics };
      });
    }
  };
  const onSelectCategory = (selectedList) => {
    setAlbumDetails((prevState) => {
      return { ...prevState, selectedCategoryList: selectedList };
    });
    setAddCategoryErr(false);
    // console.log(
    //   "albumDetails.selectedCategoryList: ",
    //   albumDetails.selectedCategoryList
    // );
  };
  const onRemoveSelectCategory = (selectedList) => {
    setAlbumDetails((prevState) => {
      return { ...prevState, selectedCategoryList: selectedList };
    });
  };
  const onNext = () => {
    const { albumName, selectedCategoryList } = albumDetails;
    if (!albumName && selectedCategoryList.length === 0 && coverImg === null) {
      setAlbumNameErr(true);
      setAddCategoryErr(true);
      addErrorMesage("Upload a cover image!");
    } else if (!albumName) {
      setAlbumNameErr(true);
    } else if (
      //albumCoverImg.length === 0
      albumDetails.id === 0
        ? albumCoverImg.length === 0
        : albumDetails.albumurlImage.length === 0
    ) {
      // console.log(albumCoverImg);
      // console.log("error in Music");
      setisMusicImgeErrShow(true);
    } else if (selectedCategoryList.length === 0) {
      setAddCategoryErr(true);
    }
    else {
      setShowAlbumSection(false);
      setShowMusicSection(true);
      if (albumDetails.id !== 0) {
        getMusicsByAlbum();
      } else {
        setSongsList([]);
      }
      setDragNDropText("Drag & Drop your file here or browse");
    }
  };
  const onBack = () => {
    setShowAlbumSection(true);
    setShowMusicSection(false);

    setUploadSongsSection(false);
    setDragNDropText("Drag & Drop your image here or browse");
  };
  const onSelectMusicOrVideoFileValidate = (file, fromFun) => {
    //console.log("jay3")
    if (songsList.length === 0) {
      //console.log("jay4")
      //console.log("Empty list...");
      return loadFileDuration(file).then((resp) => {
        // console.log("resp: ", resp);
        // console.log("jay6")
        addSongFileToSongList(resp, fromFun);
      });
    } else {
      let newFiles = [];
      return loadFileDuration(file).then((resp) => {
        //console.log("resp: ", resp);
        for (let i = 0; i < resp.length; i++) {
          let fileExits = songsList.filter((f) => f.file.name == file[i].name);
          if (fileExits.length == 0) {
            newFiles.push(file[i]);
          } else {
            addErrorMesage(file[i].name + "already added");
          }
        }
        //console.log("newFiles: ", newFiles);
        addSongFileToSongList(newFiles, fromFun);
      });
    }
  };
  const onSelectMusicOrVideoFileValidate_old = (file, fromFun) => {
    if (songsList.length === 0) {
      //console.log("Empty list...");
      addSongFileToSongList(file, fromFun);
    } else {
      //console.log("Not empty list...", file[0].name);
      //if(file.length > 0){
      let fileExits = songsList.filter((f) => f.file.name == file[0].name);
      //console.log("Song File Exists: ", fileExits);
      if (fileExits.length == 0) {
        addSongFileToSongList(file, fromFun);
      } else {
        addErrorMesage("Audio file already added");
      }
    }
  };
  const addSongFileToSongList = (file, fromFun) => {
    //console.log("jayraj",file)
    //console.log("jay7")
    let song_upload_input = document.getElementById("song_upload_input");
    let count = songsListIDCount;
    let songsitems = [];
    if (albumDetails.albumType === "music") {
      //console.log("jay8")
      for (let i = 0; i < file.length; i++) {
        if (!audioReg.exec(file[i].name)) {
          addErrorMesage("Please upload a audio file!");
          if (fromFun === "fromDragNDropFun") {
            setDragNDropText("Drag & Drop your file here or browse");
          }
          return false;
        } else {
          //console.log("jay9")
          count = count + 1;
          songsitems.push({ id: count, file: file[i] });
        }
      }
      setSongsListIDCount(count);
      setTimeout(() => {
        //console.log("jay10")
        //console.log("file: ", songsListIDCount, "--", file);
        setSongsList([...songsList, ...songsitems]);
        song_upload_input.value = "";
      });
      //console.log("jay11")
    } else if (albumDetails.albumType === "video") {
      for (let i = 0; i < file.length; i++) {
        if (!videoReg.exec(file[i].name)) {
          addErrorMesage("Please upload a video file!");
          if (fromFun === "fromDragNDropFun") {
            setDragNDropText("Drag & Drop your file here or browse");
          }
          return false;
        } else {
          count = count + 1;
          songsitems.push({ id: count, file: file[i] });
        }
      }
      setSongsListIDCount(count);
      setTimeout(() => {
        //console.log("file: ", songsListIDCount, "--", file);
        setSongsList([...songsList, ...songsitems]);
        song_upload_input.value = "";
      });
    }
    //console.log("jay12")
  };
  const addSongFileToSongList_old = (file, fromFun) => {
    let song_upload_input = document.getElementById("song_upload_input");
    if (albumDetails.albumType === "music") {
      if (!audioReg.exec(file[0].name)) {
        addErrorMesage("Please upload a audio file!");
        if (fromFun === "fromDragNDropFun") {
          setDragNDropText("Drag & Drop your file here or browse");
        }
        return false;
      } else {
        //console.log("songsListIDCount: ", songsListIDCount);
        setSongsListIDCount(songsListIDCount + 1);
        setTimeout(() => {
          //console.log("file: ", songsListIDCount, "--", file);
          if (file[0] != undefined) {
            setSongsList([
              ...songsList,
              { id: songsListIDCount, file: file[0] },
            ]);
          }
          song_upload_input.value = "";
        });
      }
    } else if (albumDetails.albumType === "video") {
      if (!videoReg.exec(file[0].name)) {
        addErrorMesage("Please upload a video file!");
        if (fromFun === "fromDragNDropFun") {
          setDragNDropText("Drag & Drop your file here or browse");
        }
        return false;
      } else {
        setSongsListIDCount(songsListIDCount + 1);
        setTimeout(() => {
          setSongsList([...songsList, { id: songsListIDCount, file: file[0] }]);
          song_upload_input.value = "";
        });
      }
    }
    //console.log("jay1")
  };
  const onSelectMusicOrVideoFile = (event) => {
    if (event) {
      if (albumDetails.albumType === "music") {
        //console.log("jay1")
        for (let i = 0; i < event.target.files.length; i++) {
          if (!audioReg.exec(event.target.files[i].name)) {
            addErrorMesage("Please upload a audio file!");
            return false;
          }
        }

      } else if (albumDetails.albumType === "video") {
        for (let i = 0; i < event.target.files.length; i++) {
          if (!videoReg.exec(event.target.files[i].name)) {
            addErrorMesage("Please upload a video file!");
            return false;
          }
        }
      }
      //console.log("jay2")
      onSelectMusicOrVideoFileValidate(event.target.files);
    }
  };
  const onDeleteSongList = (id) => {
    if (songsList.length !== 0) {
      let newArr = songsList.filter((song) => song.id !== id);
      setSongsList(newArr);

      let fltDelSong = songsList.filter((song) => song.id === id);
      //console.log("Remvoed Existing Song: ", fltDelSong);
      if (
        fltDelSong.length > 0 &&
        fltDelSong[0].isExists != undefined &&
        fltDelSong[0].isExists == true
      ) {
        let dSong = albumDetails.existingMusicList.filter(
          (m) => m.id == fltDelSong[0].itemId
        );
        setRemoveSongsList([...removeSongsList, ...dSong]);
      }
    }
  };
  const getSubcategories = async () => {
    let resSubcategories = await getDataApi(
      constant.baseURL + "subcategories?filter=category.id||$eq||1&filter=isDeleted||$eq||0"
    );
    //console.log("resSubcategories: ", resSubcategories);
    if (resSubcategories != undefined && resSubcategories.length > 0) {
      setMusicCategories([...resSubcategories]);
      setAlbumDetails((prevState) => {
        return {
          ...prevState,
          categoryList: [...resSubcategories],
        };
      });
      if (albumDetails.id !== 0) {
        //location.state.id != undefined) {
        getAlbumCategories(resSubcategories);
      }
    }
  };
  const getAlbumCategories = async (categories) => {
    let resSubcategories = await getDataApi(
      constant.baseURL +
      "selectedalbumcategories?filter=album.id||$eq||" +
      albumDetails.id
    ); //location.state.id
    //console.log("getAlbumCategories: ", resSubcategories);
    if (resSubcategories != undefined && resSubcategories.length > 0) {
      let sItems = [];
      resSubcategories.map((p) => {
        let fltCategories = categories.filter((c) => c.id == p.subcategory.id);
        if(fltCategories.length>0){
          sItems.push(fltCategories[0]);

        }
      });
      setAlbumDetails((prevState) => {
        return {
          ...prevState,
          selectedCategoryList: [...sItems],
          existingCategoryList: [...resSubcategories],
        };
      });
    }
  };
  const uploadCoverImage = async () => {
    addLoader();
    if (albumCoverImg != "") {
      //console.log("raj1")
      let customHeaders = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer ",
      };
      let data = new FormData();
      data.append(constant.uplFileParameter, albumCoverImg);
      let res = await postDataApi(
        //constant.baseURL + "albumfiles",
        constant.albumFileUploadURL,
        data,
        customHeaders
      );
      if (
        res != undefined &&
        res.data != undefined &&
        res.data.status != undefined &&
        res.data.status === 200
      ) {
        //console.log("raj2")
        removeLoader();
        setAlbumCoverImg("");
        setUploadProgress(25);
        createAlbum(res.data.data.filename);
      }
    } else {
      removeLoader();
      setUploadProgress(25);
      createAlbum(albumDetails.id !== 0 ? albumDetails.albumurlImage : ""); //createAlbum(location.state.id != undefined ? albumDetails.albumurlImage : "");
    }
  };
  const getAlbumByName = async () => {
    let resAlbum = await getDataApi(
      // constant.baseURL + "albums?filter=name||$eq||" + albumDetails.albumName
      constant.baseURL + "albums?filter=name||$eq||" + albumDetails.albumName+"&filter=isDeleted||$eq||0"
    );
    return resAlbum;
  }
  const createAlbum = async (coverImageName) => {
    if (currentUserInfo !== undefined) {
      //console.log("raj3")
      addLoader();
      let jsonData = {
        name: albumDetails.albumName,
        user: currentUserInfo.id,
        albumType: albumDetails.albumType,
        urlImage: coverImageName,
      };
      //console.log(jsonData);
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let respAlbum;
      let sStatus = "";
      if (albumDetails.id !== 0) {
        respAlbum = await patchDataApi(
          constant.baseURL + "albums/" + albumDetails.id,
          formBody,
          authHeader
        );
        if (
          respAlbum != undefined &&
          respAlbum.status != undefined &&
          respAlbum.status == 200
        ) {
          sStatus = "Success";
        }
      } else {

        //console.log("raj4")
        respAlbum = await postDataApi(
          constant.baseURL + "albums",
          formBody,
          authHeader
        );
        if (
          respAlbum != undefined &&
          respAlbum.status != undefined &&
          respAlbum.status == 201
        ) {
          sStatus = "Success";
        }
      }
      //if (respAlbum != undefined && respAlbum.status != undefined && respAlbum.status == 201) {
      if (sStatus == "Success") {
        //console.log("raj5")
        setUploadProgress(50);
        uploadMusicFiles(respAlbum.data.id);
      }
    }
  };
  const uploadMusicFiles = async (albumId) => {
    //console.log("raj6")
    addLoader();
    let customHeaders = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer ",
    };
    let data = new FormData();
    songsList.map((m) => {
      if (m.isExists == undefined || m.isExists == false) {
        data.append(constant.uplFileParameter, m.file);
      }
    });
    let res = await postDataApi(
      constant.musicFileUploadURL,//process.env.REACT_APP_NODE_ENV === "development" ? constant.baseURL + "musicfiles/uploadMultipleFiles" : constant.baseURL + "musicfiles" ,
      data,
      customHeaders
    );
    //console.log("Upload Music Files: ", res);
    if (
      res != undefined &&
      res.status != undefined &&
      res.status === 201  &&
      res.data != undefined &&
      res.data.data != undefined
    ) {
      //console.log("raj7")
      setUploadProgress(75);
      return saveAllMusics(albumId, res.data.data.length>=0 ? res.data.data : [res.data.data]).then((response) => {
        //console.log("FFFF",response,location)
        if (
          (response != undefined &&
            response.length > 0 &&
            response[0] == true) ||
          (location.state !== undefined && location.state.id !== undefined)
        ) {
          //console.log("PPPPPPPPPPPP")
          return saveAlbumCategories(albumId).then((response) => {
            if (
              response != undefined &&
              response.length > 0 &&
              response[0] == true
            ) {
              //console.log("qqqqqqqqqq")
              removeLoader();
              setUploadProgress(100);
              addSuccessMesage(
               location?.state?.id != undefined
                  ? "Album Updated"
                  : "Album Created"
              );
              //console.log("raj12")
              if (albumDetails.id !== 0) {
                history.goBack();
              } else {
                removeLoader();
                history.push("/MyAlbums");
              }
            } else {
              resetUploadMusic(0);
            }
          });
        } else {
          resetUploadMusic(0);
        }
      });
    } else {
      resetUploadMusic(0);
      addErrorMesage("Error in uploading music in album");
    }
  };
  const resetUploadMusic = (progressValue) => {
    setDisableImgDelete(false);
    setUploadProgress(progressValue);
    setUploadSongsSection(false);
    setCoverImg(null);
    setAlbumCoverImg("");
    setUploadSong(false);
    //console.log("musicCategories: ", musicCategories);
    removeLoader();
    setAlbumDetails({
      albumName: "",
      albumType: "music",
      //categoryList: musicCategories,
      selectedCategoryList: [],
    });
    if (albumDetails.id !== 0) {
      //if (location.state.id != undefined) {
      history.goBack();
    } else {
      removeLoader();
      history.push("/MyAlbums");
    }
  };
  const loadFileDuration = (uplFiles) => {
    let promises_array = [];
    //uplFiles.map((file) => {
    for (let i = 0; i < uplFiles.length; i++) {
      promises_array.push(
        new Promise((resolve, reject) => {
          // Create instance of FileReader
          let reader = new FileReader();
          // When the file has been succesfully read
          reader.onload = (event) => {
            // Create an instance of AudioContext
            let audioContext = new (window.AudioContext ||
              window.webkitAudioContext)();
            // Asynchronously decode audio file data contained in an ArrayBuffer.
            audioContext.decodeAudioData(event.target.result, (buffer) => {
              // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
              uplFiles[i]["duration"] = buffer.duration;
              resolve(uplFiles[i]);
            });
          };
          // In case that the file couldn't be read
          reader.onerror = (event) => {
            console.error("An error ocurred reading the file: ", event);
            reject(false);
          };
          //console.log("jay5")
          // Read file as an ArrayBuffer, important !
          reader.readAsArrayBuffer(uplFiles[i]);
        })
      );
    }
    return Promise.all(promises_array);
  };
  const saveAllMusics = async (albumId, uploadedMusicFiles) => {

    //console.log("raj8")
    let promises_array = [];
    uploadedMusicFiles.map((item) => {
      //console.log("raj9")
      promises_array.push(
        new Promise((resolve, reject) => {
          //get file size
          let fItem = songsList.filter(
            (f) => f.file.name === item.originalname
          );
          //console.log(item)
          //prepare json
          let jsonData = {
            title: item.originalname.split(".")[0],
            album: albumId,
            musicType: albumDetails.albumType,
            urlImage: item.filename,
            size: fItem.length > 0 ? fItem[0].file.size : 0,
            duration: fItem.length > 0 ? (fItem[0].file.duration != undefined) ? fItem[0].file.duration : 0 : 0, //Hitendra
          };
          return createAlbumMusic(jsonData)
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(false);
            });
        })
      );
    });
    //Delete existing song if removed by user
    if (albumDetails.id !== 0 && removeSongsList.length > 0) {
      //if (location.state.id != undefined && removeSongsList.length > 0) {
      removeSongsList.map((item) => {
        promises_array.push(
          new Promise((resolve, reject) => {
            return deleteAlbumMusic(item.id)
              .then(() => {
                resolve(true);
              })
              .catch((error) => {
                reject(false);
              });
          })
        );
      });
    }

    //console.log("DDDDDDD",promises_array)
    return Promise.all(promises_array);
  };
  const createAlbumMusic = async (jsonData) => {
    //console.log("raj10")
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let resExits = await getDataApi(
      constant.baseURL +
      "musics?filter=album.id||$eq||" +
      jsonData.album +
      "&filter=title||$eq||" +
      jsonData.title
    );
    //console.log("resExits: ", resExits);
    if (resExits.length == 0) {
      return await Promise.resolve(
        postDataApi(constant.baseURL + "musics", formBody, authHeader)
      )
        .then((response) => {
          if (
            response != undefined &&
            response.status != undefined &&
            response.status == 201
          ) {
            //console.log("raj11")
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          return false;
        });
    } else {
      return false;
    }
  };
  const deleteAlbumMusic = async (id) => {
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return await Promise.resolve(
      await deleteDataApi(constant.baseURL + "musics/" + id, authHeader)
    )
      .then((resDel) => {
        //console.log("resDel: ", resDel);
        if (resDel != undefined && resDel.status == 200) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
  };
  const saveAlbumCategories = async (albumId) => {
    let promises_array = [];
    albumDetails.selectedCategoryList.map((item) => {
      promises_array.push(
        new Promise((resolve, reject) => {
          let jsonData = { album: albumId, subcategory: item.id };
          return createAlbumCategories(jsonData)
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(false);
            });
        })
      );
    });
    //
    if (albumDetails.id !== 0 && albumDetails.existingCategoryList.length > 0) {
      // if (location.state.id != undefined && albumDetails.existingCategoryList.length > 0) {
      albumDetails.existingCategoryList.map((item) => {
        promises_array.push(
          new Promise((resolve, reject) => {
            let fExists = albumDetails.selectedCategoryList.filter(
              (x) => x.id == item.subcategory.id
            );
            if (fExists.length == 0) {
              return deleteAlbumCategories(item.id)
                .then(() => {
                  resolve(true);
                })
                .catch((error) => {
                  reject(false);
                });
            } else {
              resolve(true);
            }
          })
        );
      });
    }
    return Promise.all(promises_array);
  };
  const createAlbumCategories = async (jsonData) => {
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let resExits = await getDataApi(
      constant.baseURL +
      "selectedalbumcategories?filter=album.id||$eq||" +
      jsonData.album +
      "&filter=subcategory.id||$eq||" +
      jsonData.subcategory
    );
    //console.log("resExits: ", resExits);
    if (resExits.length == 0) {
      return await Promise.resolve(
        postDataApi(
          constant.baseURL + "selectedalbumcategories",
          formBody,
          authHeader
        )
      )
        .then((response) => {
          if (
            response != undefined &&
            response.status != undefined &&
            response.status == 201
          ) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          return false;
        });
    } else {
      return false;
    }
  };
  const deleteAlbumCategories = async (id) => {
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return await Promise.resolve(
      await deleteDataApi(
        constant.baseURL + "selectedalbumcategories/" + id,
        authHeader
      )
    )
      .then((resDel) => {
        //console.log("resDel: ", resDel);
        if (resDel != undefined && resDel.status == 200) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
  };
  const onUploadSonglist = async () => {
    if (songsList.length === 0) {
      addErrorMesage("Upload atleast one music file!");
    } else {
      //Check if album with same exists or not
      let respName = await getAlbumByName();
      //console.log("respName: ", respName ,albumDetails);
      if (albumDetails.id === 0 && respName.length > 0) {
        addErrorMesage("Album with the same name already exists!");
        return;
      } else if (albumDetails.id !== 0 && respName.length > 0 && albumDetails.id !== respName[0].id) {
        addErrorMesage("Album with the same name already exists!");
        return;
      }
      //Check if album with same exists or not
      setUploadSong(true);
      setDisableImgDelete(true);
      uploadCoverImage();
    }
  };
  const renderSongsList = () => {
    //console.log("songsList: ", songsList);
    if (songsList.length !== 0) {
      return songsList.map((song) => {
        let fileName = song.file.name.split(".")[0];
        let fileSizeInMB = song.file.size / 1024 / 1024;
        return (
          <div key={song.id} className="row mt-3">
            <div className="col-12">
              <div className="download-music-box">
                <div className="row">
                  <div className="col-3 col-md-2">
                    <img
                      className="episode-img"
                      src={
                        albumDetails.albumurlImage != undefined &&
                          albumDetails.albumurlImage != ""
                          ? constant.albumFileURL + albumDetails.albumurlImage
                          : coverImg === null
                            ? albumDetails.albumType === "music"
                              ? musicImg
                              : videoImg
                            : coverImg
                      }
                      alt=""
                    />
                  </div>
                  <div className="col-9 col-md-10">
                    <div className="row justify-content-between">
                      <div className="col-auto">
                        <div className="download-title">{fileName}</div>
                      </div>
                      <div className="col-auto">
                        {disableImgDelete == false && (
                          <img
                            className="close-btn"
                            onClick={() => onDeleteSongList(song.id)}
                            src={close}
                            alt="cover-image"
                          />
                        )}
                      </div>
                    </div>
                    <div className="row justify-content-around align-items-center">
                      <div className="col-12">
                        <div className="download-size">
                          {fileSizeInMB.toFixed(1)}MB
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div style={{ fontSize: 13 }}>
          Song list is empty! Please upload a song.
        </div>
      );
    }
  };
  useEffect(() => {
    getSubcategories();
  }, []);
  return (
    <section className="community-room-banner">
      <div className="container">
        {showAlbumSection == true && (
          <div className="row">
            <div className="col-12">
              <div className="upload-music-background">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    {coverImg === null ? (
                      
                      <FileDrop
                        onDragOver={() =>
                          setDragNDropText("Drop your image here!!")
                        }
                        onDragLeave={() =>
                          setDragNDropText("Drop your file here")
                        }
                        onDrop={onDrop}
                      >
                        <div className="upload-background">
                          <img
                            src={
                              albumDetails.albumurlImage != undefined &&
                                albumDetails.albumurlImage != ""
                                ? constant.albumFileURL +
                                albumDetails.albumurlImage
                                : uploadImage1
                            }
                            alt="upload picture"
                            style={{ height: 70, width: 70 }}
                          />
                          <div className="upload-text">Upload Cover Image</div>
                          <div className="upload-text-subtitle" style={{ textAlign: 'center' }}>
                            Drop your file here or browse 
                            <ImageUploader
                              className="imageUploaderNew"
                              withIcon={false}
                              buttonText="Browse"
                              label=""
                              onChange={(file) => onUploadImg(file)}
                              imgExtension={constant.supportedImageFiles}
                              singleImage={true}
                            />
                          </div>
                        </div>
                      </FileDrop>
                    ) : (
                      <FileDrop
                        onDragOver={() =>
                          setDragNDropText("Drop your image here!!")
                        }
                        onDragLeave={() =>
                          setDragNDropText("Drop your file here")
                        }
                        onDrop={onDrop}
                      >
                        <div className="upload-background">
                          <img
                            className="upload-image-icon"
                            style={{ height: 70, width: 70 }}
                            onClick={() => setCoverImg(null)}
                            id="uploaded_cover_img"
                            src={coverImg}
                            alt="cover-image"
                          />
                          <ImageUploader
                            className="imageUploaderNew"
                            withIcon={false}
                            buttonText="Browse"
                            label=""
                            onChange={(file) => onUploadImg(file)}
                            imgExtension={constant.supportedImageFiles}
                            singleImage={true}
                          />
                        </div>
                      </FileDrop>
                    )}
                    {isMusicImgeErrShow && (
                      <div className="errorText" style={{ marginTop: "0px" }}>
                        Please Upload Image File
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="uppload-music-right-box">
                      <div className="row featured-artists-title">
                        <div className="col-10">Album Details</div>
                        {isUploadSong ? (
                          <div className="col-2 progress_bar">
                            <CircularProgressbar
                              value={uploadProgress}
                              maxValue={100}
                              minValue={0}
                              text={`${uploadProgress}%`}
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className="floating-label mt-3">
                        <input
                          className="floating-input"
                          type="text"
                          maxLength={255}
                          placeholder=" "
                          onChange={(e) => {
                            setAlbumDetails({
                              ...albumDetails,
                              albumName: e.target.value,
                            });
                            setAlbumNameErr(false);
                          }}
                          value={albumDetails.albumName}
                          required={true}
                        />
                        <span className="highlight"></span>
                        <label className="label">Name</label>
                        {isAlbumNameErrShow && (
                          <div
                            className="errorText"
                            style={{ marginTop: "0px" }}
                          >
                            Enter Name
                          </div>
                        )}
                      </div>
                      <div className="login-sub-title mt-3">
                        Join us and Listen your favourite music
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 col-md-6">
                          <div className="selection-page">
                            Video
                            <span>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  id="inlineRadio1"
                                  checked={albumDetails.albumType === "video"}
                                  disabled={
                                    albumDetails.id !== 0
                                      ? albumDetails.albumType !== "video" &&
                                      true
                                      : false
                                  }
                                  onChange={() => {
                                    setAlbumDetails((prevState) => {
                                      return {
                                        ...prevState,
                                        albumType: "video",
                                      };
                                    });
                                  }}
                                  value="video"
                                />
                                <label
                                  className="form-check-label"
                                  for="inlineRadio1"
                                ></label>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mt">
                          <div className="selection-page">
                            Music
                            <span>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  id="inlineRadio1"
                                  disabled={
                                    albumDetails.id !== 0
                                      ? albumDetails.albumType !== "music" &&
                                      true
                                      : false
                                  }
                                  checked={albumDetails.albumType === "music"}
                                  onChange={() => {
                                    setAlbumDetails((prevState) => {
                                      return {
                                        ...prevState,
                                        albumType: "music",
                                      };
                                    });
                                  }}
                                  value="music"
                                />
                                <label
                                  className="form-check-label"
                                  for="inlineRadio1"
                                ></label>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="add-category mt-4">
                        <div className="floating-label0">
                          <Multiselect
                            options={albumDetails.categoryList}
                            selectedValues={albumDetails.selectedCategoryList}
                            onSelect={onSelectCategory}
                            onRemove={onRemoveSelectCategory}
                            displayValue="name"
                            placeholder="Add Category"
                            style={multiSelectStyle}
                            // ClearIcon
                            // closeIcon="circle"
                            customCloseIcon={
                              <AiFillCloseSquare className="multiselect-close-icon" />
                            }
                          />
                          {isAddCategoryErrShow && (
                            <div
                              className="errorText"
                              style={{ marginTop: "0px" }}
                            >
                              Add Category
                            </div>
                          )}
                          {/* <span className="highlight"></span>
                                                <label className="label">Select Type</label> */}
                        </div>
                      </div>
                      {albumDetails.id !== 0 ? (
                        <div className="row mt-2">
                          <div className="col-6">
                            <button
                              className="btn btn-primary upload-btn w100"
                              onClick={() => {
                                history.goBack();
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                          <div className="col-6">
                            <button
                              className="btn btn-primary upload-btn w100"
                              onClick={() => onNext()}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="mt-5">
                          <button
                            className="btn btn-primary upload-btn w100"
                            onClick={() => onNext()}
                          >
                            Next
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showMusicSection == true && (
          <div className="row">
            <div className="col-12">
              <div className="upload-music-background">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    {/* <img className="drag-and-download" src={uploadMusic2} alt=""/> */}
                    <FileDrop
                      onDragOver={() =>
                        setDragNDropText("Drop your file here!!")
                      }
                      onDragLeave={() =>
                        setDragNDropText("Drag & Drop your file here or browse")
                      }
                      onDrop={(e) =>
                        onSelectMusicOrVideoFileValidate(e, "fromDragNDropFun")
                      }
                    >
                      <div className="centerTextInDiv" style={{ height: 350 }}>
                        <img
                          src={uploadMusic2}
                          style={{ marginTop: 55 }}
                          alt="upload file"
                        />
                        <input
                          onChange={(event)=>{
                            //window.alert("--event.target.files--"+event.target.files.length);
                            if(event && event.target.files.length > 0){
                              //window.alert("--type--"+event.target.files[0].type);
                              if(event.target.files.length > 0 && event.target.files[0].type == "video/quicktime"){
                                window.alert("Please use Choose Files instead of Photo Library option to select video.");
                                return false;
                              }else{
                                onSelectMusicOrVideoFile(event);
                              }
                            }
                          }}
                          className="custom-file-input"
                          type="file"
                          style={{ fontSize: 0, height: 350 }}
                          id="song_upload_input"
                          accept={
                            albumDetails.albumType === "music"
                              ? constant.acceptedAudio
                              : constant.acceptedVideo
                          }
                          multiple 
                        // accept={
                        //   albumDetails.albumType === "music"
                        //     ? "audio/opus,audio/flac,audio/webm,audio/weba,audio/wav,audio/ogg,audio/m4a,audio/mp3,audio/oga,audio/mid,audio/amr,audio/aiff,audio/wma,audio/au,audio/aac,audio/mp4,audio/avi,audio/mov,audio/*"
                        //     : "video/mp3,video/mpeg,video/ogm,video/mpg,video/wmv,video/webm,video/mp4,video/avi,video/mov,video/x-m4v,video/*"
                        // }
                        />

                        {/* <p style={{ marginTop: 15 }}>{dragNDropText}</p> */}
                      </div>
                    </FileDrop>
                  </div>
                  <div className="col-12 col-lg-6 ">
                    <div className="uppload-music-right-box">
                      <div className="row album-song-title">
                        <div className="col-10">Album Songs</div>
                        {isUploadSong ? (
                          <div className="col-2 progress_bar">
                            <CircularProgressbar
                              value={uploadProgress}
                              maxValue={100}
                              minValue={0}
                              text={`${uploadProgress}%`}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className={
                        songsList.length === 0 ? "centerTextInDiv" : ""
                      }
                      style={{
                        height: 350,
                        overflowX: "hidden",
                        overflowY: "auto",
                      }}
                    >
                      {renderSongsList()}
                    </div>
                    <div className="row mt-2">
                      <div className="col-6">
                        <button
                          className="btn btn-primary upload-btn w100"
                          onClick={() => onBack()}
                        >
                          Back
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          className="btn btn-primary upload-btn w100"
                          onClick={onUploadSonglist}
                        >
                          {albumDetails.id !== 0 ? "Update" : "Save"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default UploadMusic;
