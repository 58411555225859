import React, { useState, useEffect } from "react";
import { constant } from "../../utility/constant";
import { deleteDataApi, getDataApi, postDataApi } from "../../utility/apiCall";
import { colors } from "../../utility/colors";
import moment from "moment";
import { BsFillEyeFill, BsFillChatDotsFill } from "react-icons/bs";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { FaShareAlt } from "react-icons/fa";
import Comments from "../../components/Comments/Comments";
import ShareModal from "../../components/ShareModal/ShareModal";
import { VerifyLoginExistOrNot } from "../../utility/VerifyLoginExistOrNot";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { hashCodeUrlDecode } from "../../utility/EncodeAndDecodeUrl";
import mike from "../../assets/Images/mike.svg";
import mikeFill from "../../assets/Images/mikeFill.svg";
import { DisableButtonLoader } from "../../utility/CustomLoader";


function NewsDetails(props) {
  //console.log(props);
  const [showViewMore, setShowViewMore] = useState(false);
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  let searchData = useParams();
  //console.log(searchData, "Search Data");
  const { id } = hashCodeUrlDecode(searchData.itemId);

  // const { id } = props.location.state.id;
  const [disable,setdisable] = useState(false)
  const [newsData, setNewsData] = useState(undefined);
  const [commentsList, setCommentsList] = useState([]);
  const [commentInputVal, setCommentInputVal] = useState("");
  const [errorCommentMessage, setCommentError] = useState(false);
  const [likesList, setLikesList] = useState([]);
  const [showShareToModal, setShowShareToModal] = useState(false);
  const [commentsPage, setCommentsPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [newsComments, setNewsComments] = useState(undefined);
  var history = useHistory();
  var path = useLocation();
  const getNewsDetails = async () => {
    //console.log("---hashCodeUrlDecode(searchData.itemId)---",hashCodeUrlDecode(searchData.itemId));
    let resNews = await getDataApi(
      constant.baseURL + "news/" + hashCodeUrlDecode(searchData.itemId)
    );
    //console.log("resNews: ", resNews);
    if (resNews != undefined) {
      setNewsData(resNews);
    }
  };
  const getNewsComments = async (pageNumber) => {
    let resNewsComments = await getDataApi(
      constant.baseURL +
      "newscomments?filter=news.id||$eq||" +
      // props.location.state.id +
      hashCodeUrlDecode(searchData.itemId) +
      "&page=" +
      pageNumber +
      "&limit=" +
      limit +
      "&sort=id,DESC"
    );
    //console.log("resNewsComments: ", resNewsComments);
    if (
      resNewsComments !== undefined &&
      resNewsComments.data !== undefined &&
      resNewsComments.data.length > 0
    ) {
      setNewsComments(resNewsComments);
      let itms = [];
      resNewsComments.data.map((c) => {
        itms.push({
          id: c.id,
          img:
            c.user.urlImage != undefined &&
              c.user.urlImage != null &&
              c.user.urlImage != ""&&
              c.user.urlImage != "user.png"
              ? constant.profileURL + c.user.urlImage
              : c.user.usertype=="Artist"?constant.artistImageURL : constant.userImageURL,
          name: c.user.firstName + " " + c.user.lastName,
          location: c.user.address,
          comment: c.comment,
          date: moment(c.created).format("DD MMM yyyy"),
        });
      });
      if (pageNumber == 1) {
        setCommentsList(itms);
      } else {
        setCommentsList([...commentsList, ...itms]);
      }
      //show hide view more page
      if (resNewsComments.page < resNewsComments.pageCount) {
        setShowViewMore(true);
      } else {
        setShowViewMore(false);
      }
    }
  };
  const setComments = (data) => {
    setCommentsList([
      {
        id: data.id,
        img:
          data.user.urlImage != undefined &&
            data.user.urlImage != null &&
            data.user.urlImage != ""&&
            data.user.urlImage != "user.png"
            ? constant.profileURL + data.user.urlImage
            :data.user.usertype=="Artist"?constant.artistImageURL : constant.userImageURL,
        name: data.user.firstName + " " + data.user.lastName,
        location: data.user.address,
        comment: data.comment,
        date: moment(data.created).format("DD MMM yyyy"),
      },
      ...commentsList,
    ]);
    setCommentInputVal("");
  };
  const viewMoreComments = () => {
    setCommentsPage(commentsPage + 1);
    getNewsComments(commentsPage + 1);
  };
  const getNewsLikes = async () => {
    let resNewsLikes = await getDataApi(
      constant.baseURL +
      "newslikes?filter=news.id||$eq||" +
      // props.location.state.id
      hashCodeUrlDecode(searchData.itemId)
    );
    if (resNewsLikes != undefined && resNewsLikes.length > 0) {
      setLikesList(resNewsLikes);
      setdisable(false)
    }
  };
  const resetNewsLike = async (newsId, likeId) => {
    if (VerifyLoginExistOrNot()) {
      setdisable(true)
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resp = await deleteDataApi(
        constant.baseURL + "newslikes/" + likeId,
        authHeader
      );
      if (resp != undefined && resp.status != undefined && resp.status == 200) {
        getNewsLikes();
        // setLikes(newsId, undefined, likeId);//setLikes(newsId);
      }
    }
  };
  const createNewsLikes = async (id) => {
    if (VerifyLoginExistOrNot()) {
      setdisable(true)
      let jsonData = {
        user: currentUserInfo.id,
        // news: props.location.state.id,
        news: hashCodeUrlDecode(searchData.itemId),
      };
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let respNewsLike = await postDataApi(
        constant.baseURL + "newslikes",
        formBody,
        authHeader
      );
      if (
        respNewsLike != undefined &&
        respNewsLike.status != undefined &&
        respNewsLike.status == 201
      ) {
        getNewsLikes();
      }
    } else {
      history.push({
        pathname: `/login`,
        state: {
          prevPath: path.pathname,
          // userId: props.location.state.id,
          userId: hashCodeUrlDecode(searchData.itemId),
        },
      });
    }
  };
  const renderLikes = (id) => {
    if (likesList.length !== 0) {
      let flt = likesList.filter(
        (f) => f.user.id !== undefined && f.user.id === currentUserInfo?.id
      );
      //console.log(flt)
      if (flt.length > 0) {
        return (
          <img
            src={mikeFill}
            title={constant.unmic}
            key={id}
            onClick={() => {
              if (VerifyLoginExistOrNot()) resetNewsLike(id, flt[0].id);
              else {
                history.push({
                  pathname: `/login`,
                  state: {
                    prevPath: path.pathname,
                    userId:
                      currentUserInfo !== undefined ? currentUserInfo.id : 0,
                  },
                });
              }
            }}
            style={{ cursor: "pointer", width: 25 }}
          />
        );
      } else {
        return (
          <img
            src={mike}
            title={constant.mic}
            key={id}
            onClick={() => {
              if (VerifyLoginExistOrNot()) createNewsLikes(id);
              else {
                history.push({
                  pathname: `/login`,
                  state: {
                    prevPath: path.pathname,
                    userId:
                      currentUserInfo !== undefined ? currentUserInfo.id : 0,
                  },
                });
              }
            }}
            style={{ cursor: "pointer", width: 25 }}
          />
        );
      }
    } else {
      return (
        <img
          src={mike}
          title={constant.mic}
          key={id}
          onClick={() => {
            if (VerifyLoginExistOrNot()) createNewsLikes(id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
          style={{ cursor: "pointer", width: 25 }}
        />
      );
    }
    
  };
  useEffect(() => {
    getNewsDetails();
    getNewsLikes();
    getNewsComments(commentsPage);
  }, []);
  return (
    <div>
      <section className="more-newz">
        <div className="container">
          <div className="master-newz">

            <div className="more-newz-banner">
              <img
                className="morenewz-banner"
                height="450"
                width="930"
                src={
                  newsData !== undefined &&
                    newsData.urlImage !== undefined &&
                    newsData.urlImage !== null &&
                    newsData.urlImage !== ""
                    ? constant.newsFileURL + newsData.urlImage
                    : ""
                }
                alt="News"
              />
            </div>
            <div className="fs-16 mt-3 fw-600 ">
              {newsData !== undefined
                ? moment(newsData.created).format("DD-MMM-yyyy")
                : ""}
            </div>
            <div
              className="fs-25 mt-3 fw-700  "
              title={newsData !== undefined && newsData.title !== undefined ? newsData.title : ""}
            >
              {newsData !== undefined && newsData.title !== undefined ? String(newsData.title) : ""}
            </div>
            <div
              className="fs-16 mt-3 fw-600"
              title={newsData !== undefined ? newsData.subtitle : ""}
            >
              {newsData !== undefined ? String(newsData.subtitle) : ""}
            </div>
            <div className="fs-16 mt-3 fw-600">
              WORDS BY :-{" "}
              {newsData !== undefined && newsData.artist !== undefined
                ? newsData.artist.firstName + " " + newsData.artist.lastName
                : ""}
            </div>
          </div>
        </div>
      </section>
      <section className="more-news-detail">
        <div className="container">
          <div className="row">

            <div className="col-12 col-lg-12">
              <div className="fs-15 fw-500 lh-20">
                {newsData !== undefined ? newsData.description : ""}
              </div>
              <div className="more-newz-line mt-5"></div>
              <div className="row mt-3 align-items-center justify-content-between">
                <div className="col-6 col-md-auto">
                  <div className="review-detail">
                    <img
                      className="review-images"
                      style={{ height: 30 }}
                      src={
                        newsData !== undefined && newsData.artist !== undefined
                        && newsData.artist !== "user.png"
                        ? constant.profileURL + newsData.artist.urlImage
                        : newsData !== undefined && newsData.artist !== undefined && newsData.artist.usertype=="Artist"
                        ?constant.artistImageURL : constant.userImageURL
                      }
                      alt={
                        newsData !== undefined && newsData.artist !== undefined
                          ? newsData.artist.firstName +
                          " " +
                          newsData.artist.lastName
                          : ""
                      }
                    />
                    <span className="pl-3 fs-15 fw-500">
                      {newsData !== undefined && newsData.artist !== undefined
                        ? newsData.artist.firstName +
                        " " +
                        newsData.artist.lastName
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="col-6 col-md-auto">
                  <div className="row">
                    {/* <div className="col-auto">
                        <i className="fas fa-eye review-icon"><span className="review-name">12345</span></i>
                    </div> */}
                    {/* <div className="col-auto">
                      <BsFillChatDotsFill
                        size="18px"
                        title="Comments"
                        style={{ position: "relative" }}
                        color={colors.color7}
                      />
                      <span className="review-name">
                        {newsComments !== undefined
                          ? newsComments.total
                          : commentsList.length}
                      </span>
                    </div> */}
                    <div>
                      {disable ? DisableButtonLoader() :
                      <>
                      {renderLikes(newsData?.id)}{" "}
                      <span className="fs-14 fw-500 pl-1">
                      {likesList.length}
                      </span>
                    </>}
                      {/* // <>{renderLikes(newsData?.id)}{" "}</>}
                      // <span style={{ fontSize: "15px" }}>
                      //   {likesList.length}
                      // </span> */}
                    </div>
                    <div className="col-auto">
                      <FaShareAlt
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowShareToModal(true);
                        }}
                        size="17px"
                        color={colors.color6}
                      />
                      <ShareModal
                        show={showShareToModal}
                        id={id}
                        title={"Share To"}
                        currentUserId={
                          currentUserInfo !== undefined ? currentUserInfo.id : 0
                        }
                        handleClose={() => {
                          setShowShareToModal(false);
                        }}
                        from="News"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="more-newz-line mt-3"></div>
              <div className="col-2"></div>
            </div>
          </div>
          <br />
          <Comments
            type="news"
            data={commentsList}
            setComments={setComments}
            currentUserId={
              currentUserInfo !== undefined ? currentUserInfo.id : 0
            }
            id={
              // props.location.state !== undefined ? props.location.state.id : 0
              hashCodeUrlDecode(searchData.itemId) !== undefined
                ? hashCodeUrlDecode(searchData.itemId)
                : 0
              // hashCodeUrlDecode(searchData.itemId)
            }
            errorCommentMessage={errorCommentMessage}
            commentInputVal={commentInputVal}
            viewMoreComments={viewMoreComments}
            showViewMore={showViewMore}
            setCommentError={() => setCommentError(true)}
            setCommentInputVal={(val) => {
              setCommentInputVal(val);
              errorCommentMessage && setCommentError(false);
            }}
          />
        </div>
      </section>
    </div>
  );
}
export default NewsDetails;
