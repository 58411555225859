import React, { useState, useEffect } from "react";
import MyItems from "../../components/MyItems/MyItems";

function MyNews() {
  return (
    <>
        <MyItems type="News" headerTitle="My News" showLikes={true}/>
    </>    
  );
}

export default MyNews;
