import React, { useState, useEffect } from "react";
import { constant } from "../../utility/constant";
import { getDataApi, postDataApi, deleteDataApi } from "../../utility/apiCall";
import { colors } from "../../utility/colors";
import map from "../../assets/Images/map.jpg";
import { FaShareAlt } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import moment from "moment";
import ShareModal from "../../components/ShareModal/ShareModal";
import { VerifyLoginExistOrNot } from "../../utility/VerifyLoginExistOrNot";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { hashCodeUrlDecode } from "../../utility/EncodeAndDecodeUrl";
import blackmike from "../../assets/Images/blackmike.svg";
import blackmikefill from "../../assets/Images/blackmikefill.svg";


function EventsDetails(props) {
  let searchData = useParams();
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const [buttonDisable, setbuttonDisable] = useState(false);
  const [showShareToModal, setShowShareToModal] = useState(false);
  const [eventsLikes, setEventsLikes] = useState(undefined);
  const [eventItem, setEventItem] = useState({
    id: props.location !== undefined &&
      props.location.state !== undefined &&
      props.location.state.id !== undefined
      ? props.location.state.id
      : parseInt(window.location.href.split("/")[window.location.href.split("/").length-1]),
    name: "",
    description: "",
    address: "",
    eventDate: "",
    img: "",
    eventlink: "",
    artist: "",
    categories: [],
    liked: false,
  });
  // const { id } = hashCodeUrlDecode(searchData.itemId);
  var path = useLocation();
  var history = useHistory();
  // console.log(typeof window.location.href.split("/")[window.location.href.split("/").length-1])
  const getEventDetailsById = async () => {
    //console.log("Event ID: ", hashCodeUrlDecode(searchData.itemId));
    let resEvent = await getDataApi(
      constant.baseURL + "events/" + hashCodeUrlDecode(searchData.itemId)//props.location.state.id
    );
    //console.log("resEvent: ", resEvent);
    if (resEvent !== undefined) {
      setEventItem((prevState) => {
        return {
          ...prevState,
          id: resEvent.id,
          name: resEvent.name,
          description:
            resEvent.description !== undefined ? resEvent.description : "",
          address: resEvent.address !== undefined ? resEvent.address : "",
          eventDate: resEvent.eventdate, // != undefined && resEvent.eventdate !== null && resEvent.eventdate !== "" ? moment(resEvent.eventdate).format('DD-MMM-yyyy hh:mm A') : "",
          img:
            resEvent.urlImage !== undefined &&
              resEvent.urlImage !== null &&
              resEvent.urlImage !== ""
              ? constant.eventsFileURL + resEvent.urlImage
              : "",
          eventlink: resEvent.eventlink,
          artist:
            resEvent.artist !== undefined
              ? resEvent.artist.firstName + " " + resEvent.artist.lastName
              : "",
        };
      });
      getEventCategoriesByEventId();
    }
  };
  const getEventCategoriesByEventId = async () => {
    let resCategory = await getDataApi(
      constant.baseURL +
      "selectedeventcategories?filter=events.id||$eq||" +
      hashCodeUrlDecode(searchData.itemId)
    );
    //console.log("resCategory: ", resCategory);
    if (resCategory !== undefined && resCategory.length > 0) {
      setEventItem((prevState) => {
        return { ...prevState, categories: [...resCategory] };
      });
    }
  };
  const getEventsLikes = async (eventId) => {
    if (currentUserInfo !== undefined) {
      let resEventsLikes = await getDataApi(
        constant.baseURL +
        "Eventslikes?filter=user.id||$eq||" +
        currentUserInfo.id +
        "&filter=events.id||$eq||" +
        eventId
      );
      if (resEventsLikes != undefined && resEventsLikes.length > 0) {
        setEventsLikes(resEventsLikes[0]);
        setEventItem((prevState) => {
          return { ...prevState, liked: true };
        });
      }
    }
  };
  const createEventsLike = async () => {
    if (VerifyLoginExistOrNot()) {
      if (currentUserInfo != undefined) {
        setbuttonDisable(!buttonDisable);
        let jsonData = { user: currentUserInfo.id, events: hashCodeUrlDecode(searchData.itemId) };
        var formBody = [];
        for (var property in jsonData) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(jsonData[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        var authHeader = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        };
        let respEventsLike = await postDataApi(
          constant.baseURL + "eventslikes",
          formBody,
          authHeader
        );
        if (
          respEventsLike != undefined &&
          respEventsLike.status != undefined &&
          respEventsLike.status == 201
        ) {
          setbuttonDisable(false);
          setEventsLikes(respEventsLike.data);
          setEventItem((prevState) => {
            return { ...prevState, liked: true };
          });
        }
      }
    } else {
      history.push({
        pathname: `/login`,
        state: {
          prevPath: path.pathname,
          userId: hashCodeUrlDecode(searchData.itemId),
        },
      });
    }
  };
  const resetEventsLike = async () => {
    if (eventsLikes != undefined) {
      //console.log("Disable UnLike");
      setbuttonDisable(!buttonDisable);
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resp = await deleteDataApi(
        constant.baseURL + "eventslikes/" + eventsLikes.id,
        authHeader
      );
      if (resp != undefined && resp.status != undefined && resp.status == 200) {
        setbuttonDisable(false);
        setEventsLikes(undefined);
        setEventItem((prevState) => {
          return { ...prevState, liked: false };
        });
      }
    }
  };
  const renderCategories = () => {
    //console.log("eventItem.categories: ", eventItem.categories);
    if (eventItem.categories.length > 0) {
      return eventItem.categories.map((item, index) => {
        return (
          <div key={index} className="tags-btn mr-3 ">
            {item.subcategory.name}
          </div>
        );
      });
    }
  };

  useEffect(() => {
    getEventDetailsById();
    getEventsLikes(hashCodeUrlDecode(searchData.itemId));
  }, []);
  return (
    <div>
      <section
        className="events-detail-banner"
        style={{ backgroundImage: `url(${encodeURI(eventItem.img)})` }}
      >
        <div className="container">
          <div className="events-main">
            <div className="row">
              <div className="col-12">
                <div className="row justify-content-between align-items-center">
                  <div className="col-12 col-md-auto">
                    <div className="events-text">
                      <div
                        className="event-wrapText fs-40 fw-700 wrapText"
                        title={eventItem.name}
                      >
                        {String(eventItem.name)}
                      </div>
                      <p className="fs-15 fw-500 lh-20 wrapText">
                        {eventItem.description}
                      </p>
                      <div className="form-row">
                        <div className="col-auto">
                          <button className="btn btn-primary round-btn">
                            <MdLocationOn size={22}></MdLocationOn>
                            <span className="play-btn d-mobile-none">Location</span>
                          </button>
                        </div>
                        <div
                          className="col-auto"
                          title={eventItem.liked === true ? constant.unmic : constant.mic}
                        >
                          {eventItem.liked === true ? (
                            <button
                              disabled={buttonDisable}
                              className="btn btn-primary round-btn "
                              onClick={() => resetEventsLike()}
                            >
                              {/* <AiFillHeart size={22} /> */}
                              <img src={blackmikefill} title={constant.unmic} style={{ width: 26 }} />
                              <span className="play-btn d-mobile-none">{constant.unmic}</span>
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary round-btn"
                              disabled={buttonDisable}
                              onClick={() => {
                                createEventsLike();
                              }}
                            >
                              {/* <AiOutlineHeart size={22} /> */}
                              <img src={blackmike} title={constant.mic} style={{ width: 23 }} />
                              <span className="play-btn d-mobile-none">{constant.mic}</span>
                            </button>
                          )}
                        </div>
                        <div className="col-auto ">
                          <button
                            className="btn btn-primary round-btn "
                            onClick={() => {
                              setShowShareToModal(true);
                            }}
                          >
                            <FaShareAlt size="22px" />
                            <span className="play-btn d-mobile-none">Share</span>
                          </button>
                          {/* <button className="btn btn-share-more" onClick={()=> {setShowShareToModal(true);}}>
                            <FaShareAlt style={{ cursor: 'pointer' }}
                            size='17px'
                            color={colors.color6}/>
                          </button> */}
                          <ShareModal
                            show={showShareToModal}
                            id={hashCodeUrlDecode(searchData.itemId)}
                            title={"Share To"}
                            currentUserId={
                              currentUserInfo !== undefined ? currentUserInfo.id : 0
                            }
                            handleClose={() => {
                              setShowShareToModal(false);
                            }}
                            from="EventsDetails"
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="col-auto d-none d-md-block mt-5">
                    <div className="calender  ">
                      <div className="date-time">Date & Time</div>
                      <span className="choose-date">
                        {eventItem.eventDate !== undefined &&
                          eventItem.eventDate !== null &&
                          eventItem.eventDate !== ""
                          ? moment(eventItem.eventDate).format("DD-MM-yyyy hh:mm A")
                          : ""}
                      </span>
                      <a href={eventItem.eventlink} target="_blank"><button className="btn btn-primary-2" type="submit">
                        Book Now
                      </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      <section className="moreinfo-detail">
        <div className="container">
          <div className="calender-mobile d-blockd d-md-none">
            <div className="date-time">Date & Time</div>
            <span className="choose-date">
              {eventItem.eventDate !== undefined &&
                eventItem.eventDate !== null &&
                eventItem.eventDate !== ""
                ? moment(eventItem.eventDate).format("DD-MM-yyyy hh:mm A")
                : ""}
            </span>
            <a href={eventItem?.eventlink} target="_blank"><button className="btn btn-primary-2" type="submit">
              Book Now
            </button>
            </a>
          </div>
          <div className="row">
            <div className="col-12 col-lg-9">
              <div className="fs-20 fw-700 ">Description</div>
              <div className="fs-15 fw-500 ">{eventItem.description}</div>
              <div className="row mt-4">
                <div className="col-auto">
                  <div className="fs-20 fw-700">Address</div>
                </div>
              </div>
              <div className="row">
                <div className="col-auto">
                  <div className="fs-15 fw-500">{eventItem.address}</div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-auto">
                  <div className="fs-20 fw-700">Date & Time</div>
                </div>
              </div>
              <div className="row">
                <div className="col-auto">
                  <div className="fs-15 fw-500">
                    {eventItem.eventDate !== undefined &&
                      eventItem.eventDate !== null &&
                      eventItem.eventDate !== ""
                      ? moment(eventItem.eventDate).format(
                        "dddd, MMM DD yyyy [at] hh:mmA"
                      )
                      : ""}
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 col-md-auto">
                  <div className="fs-20 fw-700 ">Event Link</div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-auto">
                  <div className="fs-15 fw-500 wrapText" title={eventItem.eventlink}>{eventItem.eventlink}</div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 ">
              <div className="fs-20 fw-700 mt-3 mt-md-0">Event Location</div>
              <div className="row">
                <div className="col-auto">
                  <div className="map mt-4">
                    <img className="img-fluid" src={map} alt="Event Location" />
                  </div>
                </div>
                {/* <div className="col-auto">
                            <div className="biography-title mt-4">Dream World Wide In Jakarta</div>
                            <div className="biogrphy-detail">The other day the grass was brown, now it’s green</div>
                        </div> */}
                <div className="col-12 col-lg-auto">
                  <div className="fs-20 fw-700 mt-3 ">Tags</div>
                  <div className="tags-programe mt-3">
                    {/* <div className="tags-btn mr-3 d-none d-sm-block ">Concert</div> */}
                    {renderCategories()}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-auto mt-sm-4 ml-0 ">
                <a href={eventItem?.eventlink} target="_blank"><button className="btn btn-primary-2 cus-book" type="submit">
                    Book Ticket Now
                  </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EventsDetails;
