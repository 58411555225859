import React, { useState, useEffect } from "react";
import { getDataApi } from "../../utility/apiCall";
import ShareModal from "../../components/ShareModal/ShareModal";
import { BsPlayFill } from "react-icons/bs";
import { FaShareAlt, FaPlay } from "react-icons/fa";
import { colors } from "../../utility/colors";
import { constant } from "../../utility/constant";
import Musics from "../../components/Musics/Musics";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";
import { DisableButtonLoader } from "../../utility/CustomLoader";
import { useHistory, useParams } from "react-router-dom";
import { hashCodeUrlDecode } from "../../utility/EncodeAndDecodeUrl";

function PlaylistDetails(props) {
  //console.log(props);
  let searchData = useParams();
  //console.log("--playlistdeatils--searchData",searchData);
  //console.log("--playlistdetails--props.location?.state",props.location?.state);
  const [idd, setidd] = useState(hashCodeUrlDecode(searchData.itemId));
  const [playListItemObj, setPlayListItemObj] = useState(undefined);
  let history = useHistory();
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  //const { id, item } = props.location?.state;
  const [id, setId] = useState(searchData.itemId != undefined ? hashCodeUrlDecode(searchData.itemId) : props.location?.state.id);
  const [item,setItem] = useState(props.location != undefined && props.location.state != undefined ? props.location.state.item : undefined);

  const [buttonDisable, setbuttonDisable] = useState(false);
  const [showViewMore, setShowViewMore] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showShareToModal, setShowShareToModal] = useState(false);
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const [CurrentPlayMusic, setCurrentPlayMusic] = useState(false);
  const [playIndex, setPlayIndex] = useState(0);
  const [showMusics, setShowMusics] = useState(false);
  const [typeext,settype] = useState("")
  const [playlistInfo, setPlaylistInfo] = useState({
    id: searchData.itemId != undefined ? hashCodeUrlDecode(searchData.itemId) : props.location?.state.id,//id,
    playlistName: "",
    img: "",
    musicList: [], //{ id: 1,rank: '01',title: 'Underwater Whales Sound',album: 'From The Deep Ocean',time: '3:22',like: true,},
    musicLikesList: [],
    musicDownloadList: [],
  });
  //console.log(playlistInfo)
  const setLikes = (id, data,likeId) => {
    // if (playlistInfo.musicLikesList.length !== 0) {
    //   let fltLikes = playlistInfo.musicLikesList.filter(
    //     (f) => f.music != undefined && f.music.id == id
    //   );
    //   if (fltLikes.length == 0) {
    //     setPlaylistInfo((prevState) => {
    //       return {
    //         ...prevState,
    //         musicLikesList: [...playlistInfo.musicLikesList, data],
    //       };
    //     });
    //   } else {
    //     let newArr = playlistInfo.musicLikesList.filter(
    //       (i) => i.music != undefined && i.music.id !== id
    //     );
    //     setPlaylistInfo((prevState) => {
    //       return { ...prevState, musicLikesList: [...newArr] };
    //     });
    //   }
    // } else {
    //   let arr = [];
    //   arr.push(data);
    //   setPlaylistInfo((prevState) => {
    //     return { ...prevState, musicLikesList: [...arr] };
    //   });
    // }
    let bgItems = [...playlistInfo.musicList]
    //console.log(bgItems)
    if (data !== undefined) {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike.push(data);
          itm.likes.push(data);
        }
      });
    } else {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike = [];
          itm.likes.map((lkitm, index0) => {
            if (lkitm.id === likeId) {
              itm.likes.splice(index0, 1);
            }
          });
        }
      });
    }
    setPlaylistInfo({...playlistInfo,  musicList: bgItems})
    // setSelectedAlbumMusics(bgItems)
  };
  const funsetcurrentPlaymusic = () =>{
    setShowAudioPlayer(false)
    setCurrentPlayMusic(false)
  }
  const getMusicsByPlayLists = async (pageNumber) => {
    if (currentUserInfo != undefined) {
      let musicIds = "";
      let playListname = "";
      let apiURL = constant.baseURL +
      "musicplaylists/getMusicsByPlayListWithUserLike/" +
      id + "?page="+pageNumber+"&limit="+limit
      if (currentUserInfo) {
        apiURL += "&userId=" + currentUserInfo.id;
      }
      let resPlayListsMusics = await getDataApi(
        apiURL
      );
      //console.log("resPlayListsMusics: ", resPlayListsMusics);
      if (
        resPlayListsMusics != undefined &&
        resPlayListsMusics.data != undefined && resPlayListsMusics.data.length > 0
      ) {
        //console.log(resPlayListsMusics)
        settype(resPlayListsMusics.data.length>0 && resPlayListsMusics.data[0]?.musicType)
        let itms = [];
        let count = 1;
        resPlayListsMusics.data.map((item) => {
          //console.log(item)
          playListname = item.albumname;
          musicIds += item.id + ",";
          itms.push({
            id: item.id,
            title: item.title,
            name: item.title,
            singer:
              item.album.user.firstName +
              " " +
              item.album.user.lastName,
            cover: constant.musicURL,
            albumname: item.albumname,
            albumId: item.album.id,
            time: item.duration,
            urlImage:
              item.urlImage !== undefined
                ? item.urlImage
                : "",
            musicSrc:
              item.urlImage !== undefined
                ? constant.musicFileURL + item.urlImage
                : "",
            likes: item.likes,
            comments: item.comments,
            user:item.album.user,
            userlike:item.userlike
          });
          count++;
        });
        if (pageNumber == 1) {
          setPlaylistInfo((prevState) => {
            return {
              ...prevState,
              playlistName: playListname,
              musicList: [...itms],
            };
          });
        } else {
          setPlaylistInfo((prevState) => {
            return {
              ...prevState,
              playlistName: playListname,
              musicList: [...playlistInfo.musicList, ...itms],
            };
          });
        }
        getMusicLikesByUser(musicIds.substr(0, musicIds.lastIndexOf(",")));
        getMusicDownloadsByUser(musicIds.substr(0, musicIds.lastIndexOf(",")));
        
        //show hide view more page
        if (resPlayListsMusics.page < Math.round(resPlayListsMusics.totalCount / limit)) {
          setShowViewMore(true);
          setbuttonDisable(false);
        } else {
          setShowViewMore(false);
          setbuttonDisable(false);
        }
      }else{
        setShowViewMore(false);
        setbuttonDisable(false);
      }
    }
  };
  const onClickViewMore = () => {
    setbuttonDisable(true);
    setPage(page + 1);
    getMusicsByPlayLists(page + 1);
  };
  const getMusicLikesByUser = async (str) => {
    if (currentUserInfo != undefined) {
      let resMusicsLikes = await getDataApi(
        constant.baseURL +
          "musiclikes?filter=user.id||$eq||" +
          currentUserInfo.id +
          "&filter=music.id||$in||" +
          str
      );
      if (resMusicsLikes != undefined && resMusicsLikes.length > 0) {
        let mlikeIds = [];
        resMusicsLikes.map((m) => {
          mlikeIds.push(m.music.id);
        });
        //setLikesList(resMusicsLikes);
        setPlaylistInfo((prevState) => {
          return { ...prevState, musicLikesList: [...resMusicsLikes] };
        });
      }
    }
  };
  const getMusicDownloadsByUser = async (str) => {
    if (currentUserInfo != undefined) {
      let resMusicsDownloads = await getDataApi(
        constant.baseURL +
          "musicdownloads?filter=user.id||$eq||" +
          currentUserInfo.id +
          "&filter=music.id||$in||" +
          str
      );
      if (resMusicsDownloads != undefined && resMusicsDownloads.length > 0) {
        let mdonwloadIds = [];
        resMusicsDownloads.map((m) => {
          mdonwloadIds.push(m.music.id);
        });
        setPlaylistInfo((prevState) => {
          return { ...prevState, musicDownloadList: [...resMusicsDownloads] };
        });
      }
    }
  };
  const resetShowAudioPlayer = () => {
    setShowAudioPlayer(false);
  };

  const getPlaylistsById = async (id) => {
    if (id != undefined) {
      let apiURL = constant.baseURL +"playlists/" +id;
      let data = await getDataApi(
        apiURL
      );
      //console.log("getPlaylistsById: ", data);
      if(data != undefined){
        let obj = {
          albumType:data.playlistType,
          id:data.id,
          img:data.playlistType == "music" ? constant.musicURL : constant.videoURL,
          name: data.name,
          title: data.name,
          type: data.playlistType
        }
        setItem(obj);
        setPlayListItemObj(obj);
      }
    }
  };
  useEffect(() => {
    if(searchData != undefined && searchData.itemId != undefined){
      getMusicsByPlayLists(1);
      //console.log("--idd---",idd);
      getPlaylistsById(idd);
    }
  }, []);
  return (
    <>
    {item != undefined &&
      <section
        className="favourite-song"
        style={{
          backgroundImage: `url(${encodeURI(item.img)})`,
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-lg-6">
              <div
                className="podcast-playlist-namne wrapText"
                style={{ marginTop: 220 }}
              >
                {item.title}
              </div>
              {playlistInfo.musicList.length > 0 && (
                <div className="row">
                  <div className="col-auto">
                    <button
                      className="btn btn-primary custom-play-icon"
                      onClick={() => {
                        //console.log(playlistInfo)
                        if (playlistInfo.musicList.length > 0 && item.albumType=="music") {
                          setShowAudioPlayer(true);
                          // setcurrentALbumDetails(true)
                        }else if(playlistInfo.musicList.length > 0 && item.albumType=="video"){
                          history.push({ pathname: "/VideoPlayer", state: { variant: "musicjs", url: playlistInfo.musicList[0].musicSrc, list: playlistInfo.musicList, details: playlistInfo.musicList[0] } })
                        }
                      }}
                    >
                      <BsPlayFill size={22} />
                      <span className="play-btn">Play</span>
                    </button>
                  </div>
                  {/* <div className="col-auto custom-pad">
                    <button
                      className="btn btn-like"
                      onClick={() => setShowShareToModal(true)}
                    >
                      <FaShareAlt size={20} color={colors.color6} />
                    </button>
                  </div>
                  <ShareModal
                    show={showShareToModal}
                    id={id}
                    title={"Share To"}
                    currentUserId={
                      currentUserInfo !== undefined ? currentUserInfo.id : 0
                    }
                    handleClose={() => {
                      setShowShareToModal(false);
                    }}
                    from="PlaylistDetails"
                  /> */}
                </div>
              )}
            </div>
            <div className="col-12 col-md-6 d-none d-lg-block">
              <img
                className="img-fluid favourite-right-box-image"
                style={{ height: 450, marginTop: 220 }}
                src={item.img}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    }
      <section className="favourite-music-add">
        <div className="container">
          {playlistInfo.musicList.length === 0 && (
            <div className="no-data-found">{constant.noDataFoundLabel}</div>
          )}
          {playlistInfo.musicList.length > 0  &&(
            <Musics
              currentPlayMusic={CurrentPlayMusic}
              data={playlistInfo.musicList}
              currentALbumDetails={showAudioPlayer}
              // showAudioPlayer={showAudioPlayer}
              funsetcurrentPlaymusic = {funsetcurrentPlaymusic}
              playIndex={playIndex}
              //ArtistUserId={hashCodeUrlDecode(searchData.itemId)}
              currentUserId={
                currentUserInfo !== undefined ? currentUserInfo.id : 0
              }
              musicLikesList={playlistInfo.musicLikesList}
              setLikes={setLikes}
              albumType={typeext=="video"?"video":"music"}
              from="playlistdetails"
              extra={id}
            />

          )}

          {playlistInfo.musicList.length > 0 && showViewMore === true && (
            <div className="row justify-content-center mt-3">
              <div className="col-auto">
                <button 
                className="btn dark" 
                disabled={buttonDisable} 
                onClick={onClickViewMore}
                style={{ cursor: "pointer", width: "100%", display: 'flex' }}
                >
                  {buttonDisable && DisableButtonLoader()}
                  <span style={{paddingLeft: 3}}>{constant.showMoreLabel}</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
      {/* {showAudioPlayer && (
        <AudioPlayer
          musicList={playlistInfo.musicList}
          playIndex={playIndex}
          close
          setShowAudioPlayer={resetShowAudioPlayer}
        ></AudioPlayer>
      )} */}
    </>
  );
}

export default PlaylistDetails;
