import React from "react";
import { Modal, ModalBody, Button } from "react-bootstrap";
import { AiFillWarning } from "react-icons/ai";

export default function ConfirmDeleteModal(props) {
  const { setdeleteModal, deleteModal, type, onconfirmDelete } = props;
  const cancelDelete = () => {
    //console.log("cancel Delete");
    setdeleteModal(false);
  };

  return (
    <>
      <Modal
        show={deleteModal}
        // onHide={cancelUploadProfilePic}
        backdrop={"static"}
        keyboard={false}
        className="userProfileModalDialog"
      >
        <Modal.Header
          // closeButton={!buttonDisable}
          style={{ backgroundColor: "#313131", border: 0 }}
        >
          <Modal.Title>
            <AiFillWarning size={22} /> Please Confirm
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#313131" }}>
          <div style={{ fontSize: 13 }}>
            Are you sure you want to  Delete {type} ?
          </div>
          <div className="form-row custom-playlist-pad">
            <div className="col-6">
              <button
                className="cancel-btn"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  cancelDelete();
                }}
              >
                No
              </button>
            </div>
            <div className="col-6">
              <button
                className="add-to-playlist-btn w-100"
                onClick={onconfirmDelete}
              >
                Yes
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
