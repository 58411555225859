import logo from "./logo.svg";
// import './App.css';
import {
  Route,
  BrowserRouter,
  Redirect,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import "./style/MainStyle.css";
import "./style/style.css";
import { Container } from "react-bootstrap";

import Navigation from "./components/Navigation/Navigation";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
//import HomeBigStage from './components/HomeBigStage/HomeBigStage';
// import BigStage from './components/BigStage/BigStage';
// import FeaturedArtists from './components/FeaturedArtists/FeaturedArtists';
// import Events from './components/Events/Events';
// import News from './components/News/News';
// import Podcasts from './components/Podcasts/Podcasts';

//Components
import Login from "./components/Login/Login";
import CreateAccount from "./components/CreateAccount/CreateAccount";
import OTPVerification from "./components/OTPVerification/OTPVerification";
import CompleteProfile from "./components/CompleteProfile/CompleteProfile";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import FirstAlbumMessage from "./components/FirstAlbumMessage/FirstAlbumMessage";
import CreateAlbum from "./components/CreateAlbum/CreateAlbum";
import Support from "./components/Support/Support"
import Aboutus from "./components/Aboutus/Aboutus"
import Privacy from "./components/Privacy/Privacy"
//Pages

import Home from "./pages/Home/Home";
import BigStage from "./pages/BigStage/BigStage";
import CommunityRoom from "./pages/CommunityRoom/CommunityRoom";
import FeaturedArtist from "./pages/FeaturedArtists/FeaturedArtists";
import Podcast from "./pages/Podcast/Podcast";
import UploadMusic from "./pages/UploadMusic/UploadMusic";
import News from "./pages/News/News";
import Events from "./pages/Events/Events";
import FeaturedArtistDetails from "./pages/FeaturedArtistDetails/FeaturedArtistDetails";
import NewsDetails from "./pages/News/NewsDetails";
import MoreInfo from "./pages/FeaturedArtistDetails/MoreInfo";
import CreateNews from "./pages/News/CreateNews";
import EventsDetails from "./pages/Events/EventsDetails";
import CreateEvents from "./pages/Events/CreateEvents";
import VideoPlayer from "./pages/VideoPlayer/VideoPlayer";
import Profile from "./pages/Profile/Profile";
import MyAlbums from "./pages/Albums/MyAlbums";
import MyPlaylists from "./pages/Playlist/MyPlaylists";
import PlaylistDetails from "./pages/Playlist/PlaylistDetails";
import MyNews from "./pages/News/MyNews";
import MyEvents from "./pages/Events/MyEvents";
import PodcastDetails from "./pages/Podcast/PodcastDetails";
import MyPodcasts from "./pages/Podcast/MyPodcasts";
import UploadPodcast from "./pages/Podcast/UploadPodcast";
import Addepisode from "./pages/Podcast/Addepisode";
import {
  verifyLoginUserType,
  VerifyLoginExistOrNot,
  openCompleteProfile,
  openOtpPage,
} from "./utility/VerifyLoginExistOrNot";

import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import PageNotFound from "./pages/404Page/PageNotFound";
import MyAlbumsDetails from "./pages/Albums/MyAlbumsDetails";

const App = () => {

  return (
    <BrowserRouter>
      {/* {localStorage.getItem("UserToken") !== undefined &&
        localStorage.getItem("UserToken") !== null &&
        localStorage.getItem("UserToken") !== "" && ( */}
      <>
        <ScrollToTop />
        <Header />
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/Home" />} />
          <Route exact path="/Home" component={Home} />
          <Route exact path="/BigStage" component={BigStage} />
          <Route exact path="/CommunityRoom" component={CommunityRoom} />
          <Route exact path="/FeaturedArtist" component={FeaturedArtist} />
          <Route
            // path="/FeaturedArtistDetails/:itemId?/:description?"
            path="/FeaturedArtistDetails/:itemId?/:albumId?"
            component={FeaturedArtistDetails}
          />
          <Route exact path="/MoreInfo" component={MoreInfo} />
          <Route exact path="/Podcast" component={Podcast} />
          <Route exact path="/Addepisode" component={Addepisode} />
          <Route exact path="/News" component={News} />
          <Route exact path="/Aboutus" component={Aboutus} />
          <Route exact path="/Privacy" component={Privacy} />
          <Route
            exact
            // path="/NewsDetails/:itemId?/:description?"
            path="/NewsDetails/:itemId?"
            component={NewsDetails}
          />
          <Route exact path="/CreateNews" component={CreateNews} />
          <Route exact path="/Events" component={Events} />
          <Route
            // path="/EventsDetails/:itemId?/:description?"
            path="/EventsDetails/:itemId?"
            component={EventsDetails}
          />
          <Route exact path="/CreateEvents" component={CreateEvents} />
          <Route exact path="/VideoPlayer" component={VideoPlayer} />
          {/* {verifyLoginUserType() === true ? (
            <div>
              <Route exact path="/Profile" component={Profile} />
              <Route exact path="/MyAlbums" component={MyAlbums} />
              <Route exact path="/MyPlaylists" component={MyPlaylists} />
              <Route
                exact
                path="/PlaylistDetails"
                component={PlaylistDetails}
              />
              <Route exact path="/MyNews" component={MyNews} />
              <Route exact path="/MyEvents" component={MyEvents} />
              <Route exact path="/UploadMusic" component={UploadMusic} />
            </div>
          ) : (
            <PageNotFound />
          )} */}

          <Route
            // path="/PodcastDetails/:itemId?/:description?"
            path="/PodcastDetails/:itemId?"
            component={PodcastDetails}
          />
          <Route exact path="/MyPodcasts" component={MyPodcasts} />
          <Route exact path="/UploadPodcast" component={UploadPodcast} />
          {/* --------------------------satyam */}
          {/* <Route exact path="/" component={Login} /> */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/createAccount" component={CreateAccount} />
          <Route exact path="/otpverification" component={OTPVerification} />
          <Route exact path="/completeProfile" component={CompleteProfile} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/resetpassword" component={ResetPassword} />
          <Route exact path="/firstAlbumMessage" component={FirstAlbumMessage} />
          <Route exact path="/createAlbum" component={CreateAlbum} />
          <Route exact path="/Support" component={Support} />
          
          {/* <Route component={PageNotFound} /> */}
          {/* {openOtpPage()} */}
          {/* {openOtpPage() && (
            <Route exact path="/otpverification" component={OTPVerification} />
          )} */}
          {/* {openOtpPage() === true ? (
          <Route exact path="/otpverification" component={OTPVerification} />
           
          ) : (
            <Redirect exact to="/login" component={Login} />
          )} */}
          {/* {openCompleteProfile() === true ? (
            <Route exact path="/completeProfile" component={CompleteProfile} />
          ) : (
            <Redirect exact to="/login" component={Login} />
          )} */}

          {/* {VerifyLoginExistOrNot() === true ? (
            <Route exact path="/Profile" component={Profile} />
          ) : (
            <PageNotFound />
          )} */}
          <Route exact path="/Profile">
            {VerifyLoginExistOrNot() === true ? <Profile /> : <PageNotFound />}
          </Route>
          {VerifyLoginExistOrNot() === true ? (
            <Route exact path="/PlaylistDetails/:itemId?" component={PlaylistDetails} />
          ) : (
            <PageNotFound />
          )}
          
          {VerifyLoginExistOrNot() === true ? (
            <Route exact path="/MyPlaylists" component={MyPlaylists} />
          ) : (
            <PageNotFound />
          )}
          
          {verifyLoginUserType() === true ? (
            <Route exact path="/UploadMusic" component={UploadMusic} />
          ) : (
            <PageNotFound />
          )}

          {verifyLoginUserType() === true ? (
            <Route
              exact
              path="/MyAlbumsDetails/:itemId"
              component={MyAlbumsDetails}
            />
          ) : (
            <PageNotFound />
          )}

          {verifyLoginUserType() === true ? (
            <Route exact path="/MyAlbums" component={MyAlbums} />
          ) : (
            <PageNotFound />
          )}

          {verifyLoginUserType() === true ? (
            <Route exact path="/MyNews" component={MyNews} />
          ) : (
            <PageNotFound />
          )}
          {verifyLoginUserType() === true ? (
            <Route exact path="/MyEvents" component={MyEvents} />
          ) : (
            <PageNotFound />
          )}
          <Route component={PageNotFound} />
        </Switch>
        <Footer />
      </>
      {/* )
        } */}
      {/* {(localStorage.getItem("UserToken") === undefined ||
          localStorage.getItem("UserToken") === null ||
          localStorage.getItem("UserToken") === "") && (
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/createAccount" component={CreateAccount} />
              <Route exact path="/otpverification" component={OTPVerification} />
              <Route exact path="/completeProfile" component={CompleteProfile} />
              <Route exact path="/forgotpassword" component={ForgotPassword} />
              <Route exact path="/resetpassword" component={ResetPassword} />
            </Switch>
        )} */}
    </BrowserRouter>
  );
};

export default App;
