import React, { useState, useEffect } from "react";
import TinySlider from "tiny-slider-react";
import { constant } from "../../utility/constant";
import { getDataApi, postDataApi, deleteDataApi } from "../../utility/apiCall";
import { useHistory } from "react-router-dom";
import { hashCodeUrl } from "../../utility/EncodeAndDecodeUrl";
import mike from "../../assets/Images/mike.svg";
import { FaRegCommentDots } from "react-icons/fa";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { VerifyLoginExistOrNot } from "../../utility/VerifyLoginExistOrNot";
import CommentsModal from "../CommentsModal/CommentsModal";
import mikeFill from "../../assets/Images/mikeFill.svg";
import { DisableButtonLoader } from "../../utility/CustomLoader";

const HomePodcastsList = (props) => {
  const { podcastDetails, podcast } = props;
  let history = useHistory();
  var path = useLocation();
  const [showCommentHomePodcastModal, setShowCommentHomePodcastModal] =
    useState(false);
  const [commentHomePodcastInputText, setCommentHomePodcastInputText] =
    useState("");
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const [selectedHomePodcast, setSelectedHomePodcast] = useState(undefined);
  const [update, setUpdate] = useState(false);
  const [podcastsList, setPodcastsList] = useState([]);
  const [commentsCount, setCommentsCount] = useState(0);
  const [podcastsLikesList, setPodcastsLikesList] = useState([]);
  const [podcastsLikesData, setPodcastsLikesData] = useState([]);
  const [podcastsResetCount, setPodcastsResetCount] = useState(0);
  const [disableItem, setDisableItem] = useState(false);
  const [pId, setPId] = useState(0);


  const settings = {
    controls: false,
    items: 5,
    loop: false,
    nav: false,
    mouseDrag: true,
    gutter: 10,
    responsiveClass: true,
    preventScrollOnTouch: "force",
    nested: "inner",
    responsive: {
      0: {
        items: 1.5,
        nav: false,
      },
      600: {
        items: 3,
        nav: false,
      },
      1000: {
        items: 4.5,
        nav: false,
        loop: false,
      },
    },
  };
  const navigateToPodcastDetails = (index) => {
    let podcastItem = podcastsList[index];
    if (podcast === "podcast") {
      // history.clear();
      history.push({
        pathname: `/PodcastDetails/${hashCodeUrl(podcastItem.id)}`,
        state: {
          id: podcastItem.id,
          item: podcastItem,
        },
      });
      return podcastDetails(podcastItem);
    } else {
      //console.log(podcastItem);
      if (podcastItem !== undefined) {
        // setchangePodcastById(podcastItem.id);
        history.push({
          // pathname: `PodcastDetails/${hashCodeUrl(
          //   podcastItem.id
          // )}/${hashCodeUrl(podcastItem.title)}`,
          pathname: `PodcastDetails/${hashCodeUrl(podcastItem.id)}`,
          state: {
            id: podcastItem.id,
            item: podcastItem,
          },
        });
        // history.push({
        //   pathname: `PodcastDetails`,
        //   state: {
        //     id: podcastItem.id,
        //     item: podcastItem,
        //   },
        // });
      }
    }
  };

  const getPodcasts = async () => {
    //console.log(constant.baseURL + "podcasts/getLatestPodcasts/10");
    let apiurl = constant.baseURL + "podcasts/getPodcastsWithUserLike?page=1&limit=20&showLatest=Yes"
    if (currentUserInfo) {
      apiurl += "&userId=" + currentUserInfo.id;
    }
    let resPodcasts = await getDataApi(apiurl);
    //console.log(resPodcasts)
    if (resPodcasts !== undefined && resPodcasts.data.length > 0) {
      let itms = [];
      if (resPodcasts !== undefined && resPodcasts.data.length > 0) {
        //console.log("resPodcast:", resPodcasts);
        //let podcastsIds = "";
        resPodcasts.data.map((p) => {
          let dfltURL =
            p.podcastType == "video"
              ? constant.videoURL
              : constant.musicURL;
          itms.push({
            id: p.id,
            name: p.name,
            img:
              p.urlImage !== undefined &&
                p.urlImage !== null &&
                p.urlImage !== ""
                ? constant.podcastFileURL + p.urlImage
                : dfltURL,
            title: p.name,
            description: p.description,
            podcastType: p.podcastType,
            urlPodcast: p.urlPodcast,
            urlImage: p.urlImage,
            artistName:
              p.user !== undefined
                ? p.user.firstName + " " + p.user.lastName
                : "",
            // artistName: p.artist.firstName + " " + p.artist.lastName,
            likes: p.likes,
            comments: p.comments.length,
            userlike: p.userlike
          });
        });
        setPodcastsList(itms);
      }
    }
  };

  // const getPodcastLikes = async (str) => {
  //   if (currentUserInfo !== undefined && str !== "") {
  //     let resPodcastLikes = await getDataApi(
  //       constant.baseURL +
  //       "podcastlikes?filter=user.id||$eq||" +
  //       currentUserInfo.id +
  //       "&filter=podcasts.id||$in||" +
  //       str

  //     );
  //     if (resPodcastLikes != undefined && resPodcastLikes.length > 0) {
  //       setPodcastsLikesList(resPodcastLikes);
  //       setPodcastsLikesData(resPodcastLikes);
  //     } else {
  //       setPodcastsLikesList([]);
  //       setPodcastsLikesData([]);
  //     }
  //     setDisableItem(false);
  //   } else {
  //     setDisableItem(false);
  //   }
  // };
  const setCommentUpdate = () => {
    setCommentsCount(commentsCount + 1);
  }
  const createPodcastLike = async (podcastId) => {
    if (VerifyLoginExistOrNot()) {
      setDisableItem(true);
      setPId(podcastId);
      if (currentUserInfo != undefined) {
        let jsonData = { user: currentUserInfo.id, podcasts: podcastId };
        var formBody = [];
        for (var property in jsonData) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(jsonData[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        var authHeader = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        };
        let respPodcastLike = await postDataApi(
          constant.baseURL + "podcastlikes",
          formBody,
          authHeader
        );
        if (
          respPodcastLike != undefined &&
          respPodcastLike.status != undefined &&
          respPodcastLike.status == 201 && respPodcastLike.data !== undefined
        ) {
          setLikes(podcastId, respPodcastLike.data, undefined);
          setDisableItem(false);
        } else {
          setDisableItem(false);
        }
      } else {
        setDisableItem(false);
      }
    }

  };
  const resetPodcastLike = async (podcastId, likeId) => {
    if (VerifyLoginExistOrNot()) {
      setDisableItem(true);
      setPId(podcastId);
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resp = await deleteDataApi(
        constant.baseURL + "podcastlikes/" + likeId,
        authHeader
      );
      if (resp != undefined && resp.status != undefined && resp.status == 200) {
        setLikes(podcastId, undefined, likeId);//setLikes(podcastId);
        setDisableItem(false);
      } else {
        setDisableItem(false);
      }
    } else {
      setDisableItem(false);
    }
  };
  // const createEpisodeLike = async (episodeId) => {
  //   if (currentUserInfo.id) {
  //     let jsonData = { user: currentUserInfo.id, podcasts: episodeId };
  //     var formBody = [];
  //     for (var property in jsonData) {
  //       var encodedKey = encodeURIComponent(property);
  //       var encodedValue = encodeURIComponent(jsonData[property]);
  //       formBody.push(encodedKey + "=" + encodedValue);
  //     }
  //     formBody = formBody.join("&");
  //     var authHeader = {
  //       Accept: "application/json",
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     };
  //     let resBigStageLike = await postDataApi(
  //       constant.baseURL + "podcastlikes",
  //       formBody,
  //       authHeader
  //     );
  //     console.log(resBigStageLike)
  //     if (
  //       resBigStageLike?.data !== undefined
  //     ) {
  //       setLikes(episodeId, resBigStageLike.data, undefined);
  //     }
  //   }
  // else {
  //   history.push({
  //     pathname: `/login`,
  //     // state: {
  //     //   prevPath: path.pathname,
  //     //   userId: props.currentUserId,
  //     // },
  //   });
  //   }
  // };

  // const resetEpisodeLike = async (episodeId, likeId) => {
  //   console.log(episodeId,likeId)
  //   if (currentUserInfo.id) {
  //     var authHeader = {
  //       Accept: "application/json",
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     };
  //     let resp = await deleteDataApi(
  //       constant.baseURL + "podcastlikes/" + likeId,
  //       authHeader
  //     );
  //     if (
  //       resp !== undefined &&
  //       resp.status !== undefined &&
  //       resp.status === 200
  //     ) {
  //       setLikes(episodeId, undefined, likeId);
  //     }
  //   } else {
  //     history.push({
  //       pathname: `/login`,
  //       // state: {
  //       //   prevPath: path.pathname,
  //       //   userId: props.currentUserId,
  //       // },
  //     });
  //   }
  // };
  const setLikes = (id, data, likeId) => {
    let bgItems = [...podcastsList]
    //console.log(bgItems)
    if (data !== undefined) {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike.push(data);
          itm.likes.push(data);
        }
      });
    } else {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike = [];
          itm.likes.map((lkitm, index0) => {
            if (lkitm.id === likeId) {
              itm.likes.splice(index0, 1);
            }
          });
        }
      });
    }
    setPodcastsList(bgItems)
  };
  const renderLikes = (likeData) => {
    //console.log(likeData)
    if (likeData.userlike.length > 0) {
      return (
        <img className="w-24" src={mikeFill} alt="Unmike" style={{ cursor: "pointer" }}
          //onClick={() => resetPodcastLike(likeData.id, likeData.userlike[0].id)}
          onClick={() => {
            if (VerifyLoginExistOrNot()) resetPodcastLike(likeData.id, likeData.userlike[0].id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    } else {
      return (
        <img className="w-24" src={mike} alt="mike" style={{ cursor: "pointer" }}
          //onClick={() => createPodcastLike(likeData.id)} 
          onClick={() => {
            if (VerifyLoginExistOrNot()) createPodcastLike(likeData.id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    }
  };
  const renderPodcastItems = () => {
    return podcastsList.map((item, index) => {
      return (
        <div
          className="item"
          key={index}
          style={{
            // width: 215,
            // marginRight: 20,
            // border: "10px solid #181A20",
            // padding: "5px",
            width: "-webkit-fill-available",
          }}
        >
          <div className="card mm-card-bg">
            <img
              style={{
                height: 135,
                // width: 185
                width: "-webkit-fill-available",
                cursor: "pointer",
              }}
              onClick={() => navigateToPodcastDetails(index)}
              src={item.img}
              alt={item.name}
            />
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <h6
                    className="fs-16 mt-2 fw-600 wrapText "
                    title={
                      item.name !== undefined && item.name !== null
                        ? item.name
                        : ""
                    }
                  >
                    {item.name !== undefined && item.name !== null
                      ? String(item.name)
                      : ""}
                  </h6>
                  <p
                    className="fs-14 mt-3 fw-400 wrapText"
                    title={item.artistName}
                  >
                    {String(item.artistName)}
                  </p>
                  <div className="line"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-auto">
                  <div>
                    {disableItem && pId === item.id ? DisableButtonLoader() :
                      <>
                        {renderLikes(item)}{" "}
                        <span className="fs-14 fw-500 pl-1">
                          {item.likes.length}
                        </span>
                      </>
                    }
                  </div>
                </div>
                <div className="col-auto">
                  <div>
                    <FaRegCommentDots
                      color="#b09b2f"
                      title="Comments"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (VerifyLoginExistOrNot()) {
                          setSelectedHomePodcast(item);
                          setShowCommentHomePodcastModal(true);
                        } else {
                          history.push({
                            pathname: `/login`,
                            state: {
                              prevPath: path.pathname,
                              userId:
                                currentUserInfo !== undefined
                                  ? currentUserInfo.id
                                  : 0,
                            },
                          });
                        }
                      }}
                    />
                    <span className="fs-14 fw-500 pl-3">
                      {item.comments}
                    </span>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      );
    })
  }
  const renderPodcastsList = () => {
    if (podcastsList.length > 0) {
      return (
        <TinySlider settings={settings}>
          {
            renderPodcastItems()
          }
        </TinySlider>
      );
    } else {
      return <div className="no-data-found">{constant.noDataFoundLabel}</div>;
    }
  };
  useEffect(() => {
    setCommentsCount(0);
    getPodcasts();
  }, []);
  useEffect(() => {
    getPodcasts();
  }, [commentsCount]);


  return (
    <section className="podcasts">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-auto">
            <div className="fs-20 fw-700" >
              Podcasts
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="owl-carousel owl-theme" id="podcasts">
              {renderPodcastsList()}
            </div>
          </div>
        </div>
      </div>
      {showCommentHomePodcastModal === true &&
        selectedHomePodcast !== undefined && (
          <CommentsModal
            update={update}
            setUpdate={setCommentUpdate}
            type="CommentHomePodcast"
            show={showCommentHomePodcastModal}
            id={selectedHomePodcast.id}
            title={"Comment - " + selectedHomePodcast.name}
            currentUserId={
              currentUserInfo !== undefined ? currentUserInfo.id : 0
            }
            handleClose={() => {
              setShowCommentHomePodcastModal(false);
            }}
            inputText={commentHomePodcastInputText}
            setInputText={(val) => {
              setCommentHomePodcastInputText(val);
            }}
            isComment={true}
          />
        )}
    </section>
  );
};
export default HomePodcastsList;
