import { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import OTPVerification from "../components/OTPVerification/OTPVerification";
import { getDataApi, postDataApi } from "./apiCall";
import { constant } from "./constant";
export const VerifyLoginExistOrNot = () => {

  //console.log("jayraj1",localStorage.getItem("UserToken"))
  if (
    localStorage.getItem("UserToken") === undefined ||
    localStorage.getItem("UserToken") === null ||
    localStorage.getItem("UserToken") === ""
  ) {
    //console.log("jayraj2")
    // window.alert("user exist");
    return false;
  } else {
    //console.log("jayraj3")
    return true;
  }
  //   const getVerifyToken = async () => {
  //     var authHeader = {
  //       Accept: "application/json",
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     };
  //     var userToken = localStorage.getItem("UserToken");
  //     var userdata = JSON.parse(localStorage.getItem("UserInfo"));
  //     let jsonData = {
  //       token: userToken,
  //       id: userdata.id,
  //     };
  //     console.log(jsonData);
  //     var formBody = [];
  //     for (var property in jsonData) {
  //       var encodedKey = encodeURIComponent(property);
  //       var encodedValue = encodeURIComponent(jsonData[property]);
  //       formBody.push(encodedKey + "=" + encodedValue);
  //     }
  //     formBody = formBody.join("&");
  //     let responseData = await Promise.resolve(
  //       postDataApi(
  //         "http://45.80.152.23:4527/auth/validateToken",
  //         jsonData,
  //         authHeader
  //       )
  //         .then((response) => {
  //           console.log("responseData: ", responseData);
  //           console.log(
  //             responseData.data === true &&
  //               responseData.status === 201 &&
  //               responseData.statusText === "Created"
  //           );
  //           if (
  //             responseData.data === true &&
  //             responseData.status === 201 &&
  //             responseData.statusText === "Created"
  //           ) {
  //             console.log(
  //               "hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii"
  //             );
  //             return true;
  //           } else {
  //             console.log(
  //               "hellloiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii"
  //             );
  //             return false;
  //           }
  //         })
  //         .catch(() => {})
  //     );
  //   };
  //   return getVerifyToken();
  // }
};
export const verifyLoginUserType = () => {
  // const [currentUserInfo, setCurrentUserInfo] = useState(
  //   localStorage.getItem("UserInfo") !== null
  //     ? )
  //     : undefined
  // );
  if (
    localStorage.getItem("UserToken") === undefined ||
    localStorage.getItem("UserToken") === null ||
    localStorage.getItem("UserToken") === ""
  ) {
    return false;
  } else {
    let data = JSON.parse(localStorage.getItem("UserInfo"));
    if (data !== null && data.usertype === "Artist") return true;
    else return false;
  }
};

// export const openCompleteProfile = () => {
//   let data = JSON.parse(localStorage.getItem("newUser"));
//   console.log(data);
//   if (data !== null) {
//     console.log("hiii-------------------------------");
//     return true;
//   } else {
//     // redirect to login page
//     return false;
//   }
// };

// export const openOtpPage = () => {
//   let data = JSON.parse(localStorage.getItem("userEmail"));
//   console.log(data);
//   if (data !== null) {
//     console.log("hiii-------------------------------");
//     return <Route exact path="/otpverification" component={OTPVerification} />;
//   } else {
//     // redirect to login page
//     return <Redirect exact to="/login" component={Login} />;
//   }
// };
// OTPDetails
