import React, { useState, useEffect } from "react";
import { constant } from "../../utility/constant";
import ReactJkMusicPlayer from "react-jinke-music-player";
import "react-jinke-music-player/assets/index.css";

const AudioPlayer = ({
  musicList,
  playIndex,
  onAudioPlay,
  onAudioPause,
  setShowAudioPlayer,
  getAudioInstance,
  setplayOrPauseMusicindex,
  close,
  setPlayIndex,
  //setIsPlayMusic,
}) => {
    const [count, setcount] = useState(0);
  
  const onAudioPlayTrackChange = async(currentPlayId, audioLists, audioInfo) => {
    //console.log(playIndex,currentPlayId,audioLists,audioInfo)
    const index = audioLists.findIndex(object => {
      return object.__PLAYER_KEY__ === currentPlayId;
    });
    //console.log(index)
    await setplayOrPauseMusicindex(index);
    await setPlayIndex(index)
// if((audioLists.length-1)==playIndex){
//  
// }else{
//   await setplayOrPauseMusicindex(playIndex+1);
//   await setPlayIndex(playIndex+1)
// }
// let seti = audioInfo.playIndex + 1
// console.log(typeof(seti))

setcount(count+1)
  }
  const onAudioReload = (audioInfo) =>{
    //console.log(audioInfo)
  }
  const onBeforeDestroy = (currentPlayId, audioLists, audioInfo) => {
    setShowAudioPlayer(false);
    //console.log(currentPlayId, audioLists, audioInfo)
    // if (close !== true) {
    //   setplayOrPauseMusicindex(0);
    // }
    // return new Promise((resolve, reject) => {
    //   // your custom validate
    //   if (window.confirm('Are you confirm destroy the player?')) {
    //     // if resolve, player destroyed
    //     resolve()
    //   } else {
    //     // if reject, skip.
    //     reject()
    //   }
    // })
  };
  // console.log(playIndex)
  const onDestroyed = (currentPlayId, audioLists, audioInfo) => {
    //console.log('onDestroyed:', currentPlayId, audioLists, audioInfo);
    setShowAudioPlayer(false);
    if (close !== true) {
      setplayOrPauseMusicindex(null);
    }
  };
  // const onAudioPlay = () => {
  //     setIsPlayMusic(false);
  // }
  // const onAudioPause = () => {
  //     setIsPlayMusic(true);
  // }
  // const onPlay = () => {
  //     if(audioInstance !== null){
  //         audioInstance.play();
  //     }
  // }
  // const onPause = () => {
  //     if(audioInstance !== null){
  //         audioInstance.pause();
  //     }
  // }
  // const getAudioInstance = (instance) => {
  //     setTimeout(() => {
  //         setAudioInstance(instance);
  //     }, 500);
  // }

  return (
    <>
      <ReactJkMusicPlayer
        getAudioInstance={getAudioInstance}
        quietUpdate
        clearPriorAudioLists={true}
        audioLists={musicList}
        mode="full"
        theme="dark"
        // autoPlay={false}
        playIndex={playIndex}
        remove={true}
        showMiniModeCover={true}
        responsive={true}
        showDestroy={true}
        onBeforeDestroy={onBeforeDestroy}
        onAudioReload={onAudioReload}
        onAudioPlayTrackChange={onAudioPlayTrackChange}
        onDestroyed={onDestroyed}
        showDownload={false}
        onAudioPlay={onAudioPlay}
        onAudioPause={onAudioPause}
      ></ReactJkMusicPlayer>
    </>
  );
};
export default AudioPlayer;
AudioPlayer.defaultProps = {
  close: false,
};
