import React, { useState, useEffect } from "react";
import MyItems from "../../components/MyItems/MyItems";

function MyPlaylists() {
    return (
        <>
            <MyItems type="Playlist" headerTitle="My Playlists" />
        </>    
    );
}

export default MyPlaylists;
