import React, { useState, useEffect } from "react";
import TinySlider from "tiny-slider-react";
import { constant } from "../../utility/constant";
import { getDataApi, postDataApi, deleteDataApi } from "../../utility/apiCall";
import { NavLink, useHistory } from "react-router-dom";
import { hashCodeUrl } from "../../utility/EncodeAndDecodeUrl";
import mike from "../../assets/Images/mike.svg";
import mikeFill from "../../assets/Images/mikeFill.svg";
import { FaRegCommentDots } from "react-icons/fa";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { VerifyLoginExistOrNot } from "../../utility/VerifyLoginExistOrNot";
import CommentsModal from "../CommentsModal/CommentsModal";
import { DisableButtonLoader } from "../../utility/CustomLoader";

const HomeFeaturedArtistsList = (props) => {
  let history = useHistory();
  var path = useLocation();
  const [showCommentHomeArtistModal, setShowCommentHomeArtistModal] =
    useState(false);
  const [commentHomeArtistInputText, setCommentHomeArtistInputText] =
    useState("");
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const [selectedHomeArtist, setSelectedHomeArtist] = useState(undefined);
  const [update, setUpdate] = useState(false);
  const [featuredArtistsList, setFeaturedArtistsList] = useState([]);
  const [homeArtistList, setHomeArtistList] = useState([]);
  const [commentsCount, setCommentsCount] = useState(0);
  const [artistLikesList, setArtistLikesList] = useState([]);
  const [artistLikesData, setArtistLikesData] = useState([]);
  const [artistResetCount, setArtistResetCount] = useState(0);
  const [disableItem, setDisableItem] = useState(false);
  const [arId, setArId] = useState(0);

  const settings = {
    controls: false,
    items: 5,
    loop: false,
    nav: false,
    mouseDrag: true,
    gutter: 10,
    responsiveClass: true,
    preventScrollOnTouch: "force",
    nested: "inner",
    responsive: {
      0: {
        items: 2,
        nav: false,
      },
      600: {
        items: 3,
        nav: false,
      },
      1000: {
        items: 5,
        nav: false,
        loop: false,
      },
    },
  };

  const getArtists = async () => {
    // let apiURL =
    //   constant.baseURL +
    //   "user?filter=makeFeatured||$eq||1&filter=usertype||$eq||Artist&filter=status||$eq||1&filter=isDeleted||$eq||0&sort=id,DESC";
    let apiURL = constant.baseURL + "user/getHomeFeaturedArtistsWithUserLike";
    let jsonData = { userId: (currentUserInfo) ? currentUserInfo.id : 0 }
    let resArtists = await postDataApi(apiURL, jsonData);


    // let apiURL = constant.baseURL + "user/getFeaturedArtists";
    // if (props.isShowOnHome == true) {
    //   apiURL = constant.baseURL + "user/getHomeArtists";
    // }
    // //let resArtists = await getDataApi(constant.baseURL + 'user?filter=usertype||$eq||Artist&artistTypeId||$eq||2&showOnHome||$eq||1');
    // let resArtists = await getDataApi(apiURL);
    //console.log("resArtists", resArtists);

    let artistIds = "";
    if (
      //props.isShowOnHome == true &&
      resArtists?.data !== undefined &&
      resArtists?.data?.data?.length > 0
    ) {
      //if(props.isShowOnHome == true){
      setHomeArtistList([...resArtists.data.data]);
      //}

    }

  };
  const getArtistLikesByUser = async (str) => {
    if (currentUserInfo !== undefined && str !== "") {
      let resArtistLikes = await getDataApi(
        constant.baseURL +
        "likes?filter=user.id||$eq||" +
        currentUserInfo.id +
        "&filter=artist.id||$in||" +
        str
      );
      //console.log("resArtistLikes: ", resArtistLikes);
      let artistLikeItems = [];
      if (resArtistLikes !== undefined) {
        resArtistLikes.map((m) => {
          artistLikeItems.push(m.artist.id);
        });
        //console.log("likeItems: ", artistLikeItems);
        setArtistLikesList(resArtistLikes);
        setArtistLikesData(resArtistLikes);
      }
      setDisableItem(false);
    } else {
      setDisableItem(false);
    }
  };
  const createArtistLike = async (artistId) => {
    if (VerifyLoginExistOrNot()) {
      setDisableItem(true);
      setArId(artistId);
      if (currentUserInfo !== undefined) {
        let jsonData = { user: currentUserInfo.id, artist: artistId };
        var formBody = [];
        for (var property in jsonData) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(jsonData[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        var authHeader = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        };
        let respArtistLike = await postDataApi(
          constant.baseURL + "likes",
          formBody,
          authHeader
        );
        if (
          respArtistLike !== undefined &&
          respArtistLike.status !== undefined &&
          respArtistLike.status === 201 && respArtistLike.data
        ) {
          setLikes(artistId, respArtistLike.data, undefined);//setLikes(artistId,);
          setDisableItem(false);
        } else {
          setDisableItem(false);
        }
      } else {
        setDisableItem(false);
      }
    }
  };

  const resetArtistLike = async (artistId, likeId) => {
    if (VerifyLoginExistOrNot()) {
      setDisableItem(true);
      setArId(artistId);
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resp = await deleteDataApi(
        constant.baseURL + "likes/" + likeId,
        authHeader
      );
      if (
        resp !== undefined &&
        resp.status !== undefined &&
        resp.status === 200
      ) {
        setLikes(artistId, undefined, likeId);//setLikes(artistId);
        setDisableItem(false);
      } else {
        setDisableItem(false);
      }
    } else {
      setDisableItem(false);
    }
  };

  const navigateToArtistDetails = (index) => {
    let indexItem = homeArtistList[index];
    history.push({
      // pathname: `FeaturedArtistDetails/${hashCodeUrl(
      //   indexItem.id 
      // )}/${hashCodeUrl(indexItem.biography)}`,
      pathname: `FeaturedArtistDetails/${hashCodeUrl(indexItem.id)}/`,
      state: {
        id: indexItem.id,
        from: "featureartistsh"
      },
    });
    window.scrollTo(0, 0);
  };
  const setCommentUpdate = () => {
    setCommentsCount(commentsCount + 1);
  }
  // const createEpisodeLike = async (episodeId) => {
  //   if (currentUserInfo.id) {
  //     let jsonData = { user: currentUserInfo.id, artist: episodeId };
  //     var formBody = [];
  //     for (var property in jsonData) {
  //       var encodedKey = encodeURIComponent(property);
  //       var encodedValue = encodeURIComponent(jsonData[property]);
  //       formBody.push(encodedKey + "=" + encodedValue);
  //     }
  //     formBody = formBody.join("&");
  //     var authHeader = {
  //       Accept: "application/json",
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     };
  //     let resBigStageLike = await postDataApi(
  //       constant.baseURL + "likes",
  //       formBody,
  //       authHeader
  //     );
  //     console.log(resBigStageLike)
  //     if (
  //       resBigStageLike?.data !== undefined
  //     ) {
  //       setLikes(episodeId, resBigStageLike.data, undefined);
  //     }
  //   }
  // else {
  //   history.push({
  //     pathname: `/login`,
  //     // state: {
  //     //   prevPath: path.pathname,
  //     //   userId: props.currentUserId,
  //     // },
  //   });
  //   }
  // };

  // const resetEpisodeLike = async (episodeId, likeId) => {
  //   console.log(episodeId,likeId)
  //   if (currentUserInfo.id) {
  //     var authHeader = {
  //       Accept: "application/json",
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     };
  //     let resp = await deleteDataApi(
  //       constant.baseURL + "likes/" + likeId,
  //       authHeader
  //     );
  //     if (
  //       resp !== undefined &&
  //       resp.status !== undefined &&
  //       resp.status === 200
  //     ) {
  //       setLikes(episodeId, undefined, likeId);
  //     }
  //   } else {
  //     history.push({
  //       pathname: `/login`,
  //       // state: {
  //       //   prevPath: path.pathname,
  //       //   userId: props.currentUserId,
  //       // },
  //     });
  //   }
  // };
  const setLikes = (id, data, likeId) => {
    let bgItems = [...homeArtistList]
    //console.log(bgItems)
    if (data !== undefined) {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike.push(data);
          itm.likes.push(data);
        }
      });
    } else {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike = [];
          itm.likes.map((lkitm, index0) => {
            if (lkitm.id === likeId) {
              itm.likes.splice(index0, 1);
            }
          });
        }
      });
    }

    setHomeArtistList(bgItems)

  };
  const renderLikes = (likeData) => {
    //console.log(likeData)
    if (likeData.userlike.length > 0) {
      return (
        <img className="w-24" src={mikeFill} alt="Unmike" style={{ cursor: "pointer" }}
          // onClick={() => resetEpisodeLike(likeData.id, likeData.userlike[0].id)} 
          onClick={() => {
            if (VerifyLoginExistOrNot()) resetArtistLike(likeData.id, likeData.userlike[0].id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    } else {
      return (
        <img className="w-24" src={mike} alt="Mike" style={{ cursor: "pointer" }}
          // onClick={() => createEpisodeLike(likeData.id)} 
          onClick={() => {
            if (VerifyLoginExistOrNot()) createArtistLike(likeData.id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    }
    // if (artistLikesList.length !== 0) {
    //   let flt = artistLikesData.filter(
    //     (f) => f.artist !== undefined && f.artist.id === id
    //   );
    //   if (flt.length > 0) {
    //     return (
    //       <img
    //         src={mikeFill} title={constant.unmic}
    //         key={id}
    //         onClick={() => {
    //           if (VerifyLoginExistOrNot()) resetArtistLike(id, flt[0].id);
    //           else {
    //             history.push({
    //               pathname: `/login`,
    //               state: {
    //                 prevPath: path.pathname,
    //                 userId:
    //                   currentUserInfo !== undefined ? currentUserInfo.id : 0,
    //               },
    //             });
    //           }
    //         }}
    //         style={{ cursor: "pointer", width: 25 }}
    //       />
    //     );
    //   } else {
    //     return (
    //       <img
    //         src={mike} title={constant.mic}
    //         key={id}
    //         onClick={() => {
    //           if (VerifyLoginExistOrNot()) createArtistLike(id);
    //           else {
    //             history.push({
    //               pathname: `/login`,
    //               state: {
    //                 prevPath: path.pathname,
    //                 userId:
    //                   currentUserInfo !== undefined ? currentUserInfo.id : 0,
    //               },
    //             });
    //           }
    //         }}
    //         style={{ cursor: "pointer", width: 25 }}
    //       />
    //     );
    //   }
    // } else {
    //   return (
    //     <img
    //       src={mike} title={constant.mic}
    //       key={id}
    //       onClick={() => {
    //         if (VerifyLoginExistOrNot()) createArtistLike(id);
    //         else {
    //           history.push({
    //             pathname: `/login`,
    //             state: {
    //               prevPath: path.pathname,
    //               userId:
    //                 currentUserInfo !== undefined ? currentUserInfo.id : 0,
    //             },
    //           });
    //         }
    //       }}
    //       style={{ cursor: "pointer", width: 25 }}
    //     />
    //   );
    // }
  };
  const renderFeaturedArtistList = () => {
    // if (props.isShowOnHome == true) {
    if (homeArtistList.length > 0) {
      return (
        <TinySlider settings={settings}>
          {homeArtistList.map((item, index) => {
            return (
              <div
                className="item"
                key={index}
                style={{
                  width: "-webkit-fill-available",
                }}
              >
                <div className="card mm-card-bg">
                  <img
                    style={{
                      height: props.height,
                      width: "-webkit-fill-available",
                      cursor: "pointer",
                    }}
                    onClick={() => navigateToArtistDetails(index)}
                    src={
                      item.urlImage !== undefined &&
                        item.urlImage !== "user.png"
                        ? constant.profileURL + item.urlImage
                        : item.usertype == "Artist" ? constant.artistImageURL : constant.userImageURL
                    }
                    alt={item.firstName + " " + item.lastName}
                  />
                  <div className="mm-card-body">

                    <h6 className="fs-16 mt-3 fw-600 wrapText">
                      {item.firstName + " " + item.lastName}
                    </h6>
                    <div className="line"></div>
                    <div className="row">
                      <div className="col-12 col-md-10">
                        <div className="row">
                          <div className="col-auto">
                            <div>
                              {disableItem && arId === item.id ? DisableButtonLoader() :
                                <>
                                  {renderLikes(item)}{" "}
                                  <span className="fs-14 fw-500 pl-1">
                                    {item.likes.length}
                                  </span>
                                </>
                              }
                            </div>
                          </div>
                          <div className="col-auto">
                            <div>
                              <FaRegCommentDots
                                color="#b09b2f"
                                title="Comments"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (VerifyLoginExistOrNot()) {
                                    setSelectedHomeArtist(item);
                                    setShowCommentHomeArtistModal(true);
                                  } else {
                                    history.push({
                                      pathname: `/login`,
                                      state: {
                                        prevPath: path.pathname,
                                        userId:
                                          currentUserInfo !== undefined
                                            ? currentUserInfo.id
                                            : 0,
                                      },
                                    });
                                  }
                                }}
                              />
                              <span className="fs-14 fw-500 pl-3">
                                {item.comments.length}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            );
          })}
        </TinySlider>
      );
    }
    else {
      return <div className=" no-data-found">{constant.noDataFoundLabel}</div>;
    }
    // } 
    // else {
    //   if (featuredArtistsList.length > 0) {
    //     return (
    //       <TinySlider settings={settings}>
    //         {featuredArtistsList.map((item, index) => {
    //           return (
    //             <div
    //               className="item"
    //               key={index}
    //               style={{
    //                 width: "-webkit-fill-available",
    //               }}
    //             >
    //               <div className="card mm-card-bg">
    //                 <img
    //                   style={{
    //                     height: props.height,
    //                     width: "-webkit-fill-available",
    //                     cursor: "pointer",
    //                   }}
    //                   onClick={() => navigateToArtistDetails(index)}
    //                   src={
    //                     item.urlImage !== undefined &&
    //                       item.urlImage !== "user.png"
    //                       ? constant.profileURL + item.urlImage
    //                       : constant.userImageURL
    //                   }
    //                   alt={item.firstName + " " + item.lastName}
    //                 />
    //                 <div className="mm-card-body">
    //                   <h6 className="big-stage-song-name wrapText">
    //                     {item.firstName + " " + item.lastName}
    //                   </h6>
    //                 </div>
    //                 <div
    //                   style={{
    //                     marginTop: -8,
    //                     marginLeft: -20,
    //                     paddingBottom: 6,
    //                   }}
    //                   className="col-md-10 col-12"
    //                 >
    //                   <div className="dis-flex justify-content-around">
    //                     <div>
    //                       {renderLikes(item.id)}{" "}
    //                       <span style={{ fontSize: "15px" }}>
    //                         {item.likes.length}
    //                       </span>
    //                     </div>
    //                     <div>
    //                       <FaRegCommentDots color="#b09b2f" />
    //                       <span style={{ fontSize: "15px", paddingLeft: 10 }}>
    //                         45
    //                       </span>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           );
    //         })}
    //       </TinySlider>
    //     );
    //   } else {
    //     return <div className=" no-data-found">{constant.noDataFoundLabel}</div>;
    //   }
    // }
  };
  useEffect(() => {
    setCommentsCount(0);
    getArtists();
  }, []);
  useEffect(() => {
    getArtists();
  }, [artistResetCount, commentsCount]);
  return (
    <section className="featured-artists">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-auto">
            <div
              className="fs-20 fw-700"
            >
              Featured Artists
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="owl-carousel owl-theme" id="featured-artists">
              {renderFeaturedArtistList()}
            </div>
          </div>
        </div>
      </div>
      {showCommentHomeArtistModal === true && selectedHomeArtist !== undefined && (
        <CommentsModal
          update={update}
          setUpdate={setCommentUpdate}
          type="CommentHomeArtist"
          show={showCommentHomeArtistModal}
          id={selectedHomeArtist.id}
          title={"Comment - " + selectedHomeArtist.firstName + " " + selectedHomeArtist.lastName}
          currentUserId={currentUserInfo !== undefined ? currentUserInfo.id : 0}
          handleClose={() => {
            setShowCommentHomeArtistModal(false);
          }}
          inputText={commentHomeArtistInputText}
          setInputText={(val) => {
            setCommentHomeArtistInputText(val);
          }}
          isComment={true}
        />
      )}
    </section>
  );
};
export default HomeFeaturedArtistsList;
