import React, { useState, useEffect } from "react";
import MyItems from "../../components/MyItems/MyItems";

function MyAlbums() {
  return (
    <>
        <MyItems type="Album" headerTitle="My Music"/>
    </>    
  );
}

export default MyAlbums;
