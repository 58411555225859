import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import moment from "moment";
import { constant } from "../../utility/constant";
import { getDataApi, patchDataApi, postDataApi } from "../../utility/apiCall";
import downloadImg from "../../assets/Images/download.png";
import likeSquareImg from "../../assets/Images/likeSquare.png";
import add from "../../assets/Images/add.png";
import { FiMoreVertical } from "react-icons/fi";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { colors } from "../../utility/colors";
import AddEditPlaylist from "../AddEditPlaylist/AddEditPlaylist";
import { FaSearch } from "react-icons/fa";
import { hashCodeUrl } from "../../utility/EncodeAndDecodeUrl";
import ConfirmDeleteModal from "../ConfirmDeleteModal/ConfirmDeleteModal";
import {
  addErrorMesage,
  addSuccessMesage,
} from "../CustomToster/CustomToaster";
import { addLoader, removeLoader } from "../../utility/CustomLoader";

const MyItems = ({ type, headerTitle, showLikes, showDownloads }) => {
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  let history = useHistory();
  const [showViewMore, setShowViewMore] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showAddEditPlaylistModal, setShowAddEditPlaylistModal] =
    useState(false);
  const [itemName, setItemName] = useState("");
  const [playlistType, setplaylistType] = useState("music");
  const [itemId, setItemId] = useState(0);
  const [liked, setLiked] = useState(0);
  const [downloads, setDownloads] = useState(0);
  const [searchInputShow, setSearchInputShow] = useState(false);
  // satyam Start
  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteItemIndex, setdeleteItemIndex] = useState(null);
  // Satyam End
  const [searchTerm, setSearchTerm] = useState("");
  const getMyData = async (pageNumber) => {
    let apiURL = "";
    if (type === "Album") {
      apiURL =
        constant.baseURL +
        "albums?filter=user.id||$eq||" +
        currentUserInfo.id +
        "&filter=status||$eq||1&filter=isDeleted||$eq||0&page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&sort=id,DESC";
    } else if (type === "Playlist") {
      apiURL =
        constant.baseURL +
        "playlists?filter=user.id||$eq||" +
        currentUserInfo.id +
        "&filter=status||$eq||1&filter=isDeleted||$eq||0&page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&sort=id,DESC";
    } else if (type === "News") {
      apiURL =
        constant.baseURL +
        "news?filter=artist.id||$eq||" +
        currentUserInfo.id +
        "&filter=status||$eq||1&filter=isDeleted||$eq||0&page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&sort=id,DESC";
    } else if (type === "Events") {
      apiURL =
        constant.baseURL +
        "events?filter=artist.id||$eq||" +
        currentUserInfo.id +
        "&filter=status||$eq||1&filter=isDeleted||$eq||0&page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&sort=id,DESC";
    } else if (type === "Podcasts") {
      apiURL =
        constant.baseURL +
        "podcasts?filter=artist.id||$eq||" +
        currentUserInfo.id +
        "&filter=status||$eq||1&filter=isDeleted||$eq||0&page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&sort=id,DESC";
    }
    if (apiURL !== "") {
      let resp = await getDataApi(apiURL);
      //console.log("resp: ", resp);
      if (
        resp != undefined &&
        resp.data !== undefined &&
        resp.data.length > 0
      ) {
        let itms = [];
        resp.data.map((p) => {
          if (type === "Album") {
            let dfltURL =
              p.albumType == "video" ? constant.videoURL : constant.musicURL;
            itms.push({
              id: p.id,
              name: p.name,
              img:
                p.urlImage != undefined &&
                  p.urlImage != null &&
                  p.urlImage != ""
                  ? constant.albumFileURL + p.urlImage
                  : dfltURL,
              title: p.name,
              albumType: p.albumType,
              urlImage: p.urlImage,
              artistName: p.user.firstName + " " + p.user.lastName,
            });
          } else if (type === "Playlist") {
            itms.push({
              id: p.id,
              name: p.name,
              title: p.name,
              img: p.playlistType == "music" ? constant.musicURL : constant.videoURL,
              type: p.playlistType,
              albumType: p.playlistType,
            });
          } else if (type === "News") {
            itms.push({
              id: p.id,
              name: p.title,
              img:
                p.urlImage != undefined &&
                  p.urlImage != null &&
                  p.urlImage != ""
                  ? constant.newsFileURL + p.urlImage
                  : constant.musicURL,
              title: p.title,
              subtitle: p.subtitle,
              description: p.description,
              urlImage: p.urlImage,
              artistName:
                p.artist != undefined
                  ? p.artist.firstName + " " + p.artist.lastName
                  : "",
            });
          } else if (type === "Events") {
            itms.push({
              id: p.id,
              name: p.name,
              title: p.name,
              img:
                p.urlImage != undefined &&
                  p.urlImage != null &&
                  p.urlImage != ""
                  ? constant.eventsFileURL + p.urlImage
                  : constant.musicURL,
              eventLink: p.eventlink,
              address: p.address,
              description: p.description,
              urlImage: p.urlImage,
              eventdate: p.eventdate,
              artistName:
                p.artist != undefined
                  ? p.artist.firstName + " " + p.artist.lastName
                  : "",
            });
          } else if (type === "Podcasts") {
            let dfltURL =
              p.podcastType == "video" ? constant.videoURL : constant.musicURL;
            itms.push({
              id: p.id,
              name: p.name,
              img:
                p.urlImage != undefined &&
                  p.urlImage != null &&
                  p.urlImage != ""
                  ? constant.podcastFileURL + p.urlImage
                  : dfltURL,
              title: p.name,
              description: p.description,
              duration:p.duration,
              podcastType: p.podcastType,
              urlPodcast: p.urlPodcast,
              urlImage: p.urlImage,
              artistName: p.artist.firstName + " " + p.artist.lastName,
              size:
                p.size !== undefined && p.size !== null && p.size !== ""
                  ? p.size
                  : 0,
            });
          }
        });
        if (pageNumber == 1) {
          setData([...itms]);
        } else {
          setData([...data, ...itms]);
        }
        //show hide view more page
        if (resp.page < resp.pageCount) {
          setShowViewMore(true);
        } else {
          setShowViewMore(false);
        }
      } else {
        setData([]);
        setShowViewMore(false);
      }
    }
  };
  const onClickViewMore = () => {
    setPage(page + 1);
    getMyData(page + 1);
  };
  const onEdit = (index) => {
    let pageName = "";
    let selectedItem = data[index];
    //console.log(selectedItem)
    let sObj = {
      id: selectedItem.id,
      item: selectedItem,
    };
    if (type === "Album") {
      pageName = `UploadMusic`;
    } else if (type === "News") {
      pageName = `CreateNews`;
    } else if (type === "Podcasts") {
      pageName = `UploadPodcast`;
    } else if (type === "Events") {
      pageName = `CreateEvents`;
    }
    if (
      type === "Album" ||
      type === "News" ||
      type === "Podcasts" ||
      type === "Events"
    ) {
      //console.log("playList Push");
      history.push({
        pathname: pageName,
        state: sObj,
      });
    } else if (type === "Playlist") {
      setShowAddEditPlaylistModal(true);
      setItemId(selectedItem.id);
      setItemName(selectedItem.name);
      //console.log(selectedItem)
      setplaylistType(data[index]?.type);
    }
  };
  const onDelete = (index) => {
    //console.log("Delete");
    setdeleteModal(true);
    setdeleteItemIndex(index);
  };
  const onconfirmDelete = async () => {
    let apiURL = "";
    let selectedItem = data[deleteItemIndex];
    //console.log("selectedItem", selectedItem);
    let jsonData = {
      isDeleted: 1,
    };
    //console.log("jsonData", jsonData);
    setdeleteModal(false);
    if (type === "Album") {
      apiURL = constant.deleteAlbum + "/" + selectedItem.id;
    } else if (type === "News") {
      apiURL = constant.deleteNews + "/" + selectedItem.id;
    } else if (type === "Podcasts") {
      apiURL = constant.deletePodcast + "/" + selectedItem.id;
    } else if (type === "Events") {
      apiURL = constant.deleteEvent + "/" + selectedItem.id;
    } else if (type === "Playlist") {
      apiURL = constant.deletePlaylist + "/" + selectedItem.id;
    }

    if (
      type === "Album" ||
      type === "News" ||
      type === "Podcasts" ||
      type === "Events" ||
      type === "Playlist"
    ) {
      //console.log(apiURL);
      if (apiURL !== "") {
        addLoader();
        let deleteResponse = await patchDataApi(apiURL, jsonData);

        //console.log("deleteResponse", deleteResponse);
        // console.log(
        //   deleteResponse.status === 200 || deleteResponse.statusText === "OK"
        // );
        if (
          deleteResponse.status === 200 ||
          deleteResponse.statusText === "OK"
        ) {
          removeLoader();
          addSuccessMesage(`${type} Deleted`);
          getMyData(1);
        } else {
          removeLoader();
          addErrorMesage(`${type} not Deleted`);
        }
      }
    }
  };
  const onCreate = (index) => {
    let pageName = "";
    if (type === "Album") {
      pageName = `UploadMusic`;
    } else if (type === "Podcasts") {
      pageName = `UploadPodcast`;
    } else if (type === "Events") {
      pageName = `CreateEvents`;
    } else if (type === "News") {
      pageName = `CreateNews`;
    }
    if (
      type === "Album" ||
      type === "Podcasts" ||
      type === "Events" ||
      type === "News"
    ) {
      history.push({
        pathname: pageName,
        state: {
          id: 0,
        },
      });
    } else if (type === "Playlist") {
      setShowAddEditPlaylistModal(true);
      setItemId(0);
      setItemName("");
    }
  };
  const onClickItem = (index) => {
    let pageName = "";
    let sItem = data[index];
    //console.log("sItem", sItem);
    let stateObj = {
      id: sItem.id,
      item: sItem,
    };
    if (type === "Album") {
      if (sItem.albumType == "video") {
        pageName = `VideoPlayer`;
        stateObj = { variant: "musicjs", variant1: "musicjs", id: sItem.id }
      } else {
        pageName = `MyAlbumsDetails/${hashCodeUrl(sItem.id)}`;
      }
      // changed  By Satyam 06/sep/2021
    } else if (type === "Playlist") {
      //pageName = `PlaylistDetails`;
      pageName = `PlaylistDetails/${hashCodeUrl(sItem.id)}`; //Hitendra
    } else if (type === "News") {
      pageName = `NewsDetails/${hashCodeUrl(sItem.id)}`;
      // pageName = `NewsDetails/${hashCodeUrl(sItem.id)}/${hashCodeUrl(
      //   sItem.description
      // )}`;
    } else if (type === "Events") {
      pageName = `EventsDetails/${hashCodeUrl(sItem.id)}`;
      // pageName = `EventsDetails/${hashCodeUrl(sItem.id)}/${hashCodeUrl(
      //   sItem.description
      // )}`;
    } else if (type === "Podcasts") {
      // if (sItem.podcastType == "video") {
      //   pageName = `VideoPlayer`;
      //   stateObj = { variant: "homepodcastjs",id: sItem.id, url: constant.podcastFileURL + sItem.urlPodcast }
      // } else {
        pageName = `PodcastDetails/${hashCodeUrl(sItem.id)}`;
      // }
      // pageName = `PodcastDetails/${hashCodeUrl(sItem.id)}/${hashCodeUrl(
      //   sItem.title
      // )}`;
    }
    //console.log("playList Click");
    if (
      type === "Playlist" ||
      type === "News" ||
      type === "Podcasts" ||
      type === "Events" ||
      type === "Album" // changed  By Satyam 06/sep/2021
    ) {
      // console.log("stateObj", stateObj);
      // console.log("playList Push");
      history.push({
        pathname: pageName,
        state: stateObj,
      });
    }
  };
  //MY PLAYLIST - START
  const getMusicLikes = async () => {
    if (currentUserInfo != undefined) {
      let resMusicLikes = await getDataApi(
        constant.baseURL +
        "musiclikes?filter=user.id||$eq||" +
        currentUserInfo.id
      );
      if (resMusicLikes != undefined && resMusicLikes.length > 0) {
        setLiked(resMusicLikes.length);
      }
    }
  };
  const getMusicDownloads = async () => {
    if (currentUserInfo != undefined) {
      let resMusicDownloads = await getDataApi(
        constant.baseURL +
        "musicdownloads?filter=user.id||$eq||" +
        currentUserInfo.id
      );
      if (resMusicDownloads != undefined && resMusicDownloads.length > 0) {
        setDownloads(resMusicDownloads.length);
      }
    }
  };
  //MY PLAYLIST - END
  //MY NEWS - START
  const getNewsLikes = async () => {
    if (currentUserInfo != undefined) {
      let resNewsLikes = await getDataApi(
        constant.baseURL +
        "newslikes?filter=user.id||$eq||" +
        currentUserInfo.id
      );
      if (resNewsLikes != undefined && resNewsLikes.length > 0) {
        setLiked(resNewsLikes.length);
      }
    }
  };
  const getPodcastLikes = async () => {
    if (currentUserInfo != undefined) {
      let resPodcastsLikes = await getDataApi(
        constant.baseURL +
        "podcastlikes?filter=user.id||$eq||" +
        currentUserInfo.id
      );
      if (resPodcastsLikes != undefined && resPodcastsLikes.length > 0) {
        setLiked(resPodcastsLikes.length);
      }
    }
  };
  const getEventLikes = async () => {
    if (currentUserInfo != undefined) {
      let resPodcastsLikes = await getDataApi(
        constant.baseURL +
        "eventslikes?filter=user.id||$eq||" +
        currentUserInfo.id
      );
      if (resPodcastsLikes != undefined && resPodcastsLikes.length > 0) {
        setLiked(resPodcastsLikes.length);
      }
    }
  };
  //MY NEWS - END
  const renderItems = () => {
    if (data.length > 0) {
      return data.map((item, index) => {
        return (
          <div key={index} className="col-6 col-md-2 cust-pad-add-2 mb-3">
            <img
              className="img-fluid"
              style={{ cursor: "pointer", height: 160, width: 164 }}
              onClick={() => onClickItem(index)}
              src={item.img}
              alt=""
            />
            <div className="row">
              <div
                className="col-8 playlist-player-name wrapText"
                title={item.title}
              >
                {String(item.title.trim())}
              </div>
              <div
                className="col-1 playlist-player-name"
                style={{ marginTop: 3, padding: 0 }}
              >
                {/* <FiMoreVertical style={{ cursor: 'pointer'}} size={22} color={colors.color1}></FiMoreVertical> */}
                <DropdownButton
                  variant="Secondary"
                  title={
                    <FiMoreVertical
                      style={{ cursor: "pointer" }}
                      size={22}
                      color={colors.color1}
                    ></FiMoreVertical>
                  }
                >
                  <Dropdown.Item onClick={() => onEdit(index)}>
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => onDelete(index)}>
                    Delete
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return null;
    }
  };
  useEffect(() => {
    getMyData(page);
    if (showLikes === true || showDownloads === true) {
      if (type === "Playlist") {
        getMusicLikes();
        getMusicDownloads();
      } else if (type === "News") {
        getNewsLikes();
      }
      else if (type === "Podcasts") {
        getPodcastLikes();
      }
      else if (type === "Events") {
        getEventLikes();
      }
    }
  }, []);
  return (
    <section className="my-playlist" style={{ minHeight: "550px" }}>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-auto">
            <div className="featured-artists-title">{headerTitle}</div>
          </div>
          {/* <div className="col-auto">
            <div className="search-cont">
              {searchInputShow ? (
                <div id="search-input">
                  <FaSearch size={22} />
                  <input
                    type="text"
                    value={searchTerm}
                    placeholder="Search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <span
                    onClick={() => {
                      setSearchTerm("");
                      setSearchInputShow(false);
                    }}
                  >
                    x
                  </span>
                </div>
              ) : (
                <div onClick={() => setSearchInputShow(true)}>
                  <FaSearch size={22} />
                </div>
              )}
            </div>
          </div> */}
        </div>
        <div className="row custom-song-add">
          <div
            className="col-6 col-md-2 cust-pad-add-2 mb-3"
            style={{ cursor: "pointer" }}
            onClick={() => onCreate()}
          >
            <img
              className="img-fluid"
              style={{ height: 160, width: 164 }}
              src={add}
              alt=""
            />
            <div className="playlist-player-name">Create</div>
          </div>
          {showLikes === true && (
            <div className="col-6 col-md-2 cust-pad-add-2 mb-3">
              <img
                className="img-fluid"
                style={{ height: 160, width: 164 }}
                src={likeSquareImg}
                alt=""
              />
              <div className="row">
                <div className="col-9 playlist-player-name">Liked</div>
                <div
                  className="col-1 playlist-player-name"
                  style={{ marginTop: 9, padding: 0 }}
                >
                  {liked}
                </div>
              </div>
            </div>
          )}
          {showDownloads === true && (
            <div className="col-6 col-md-2 cust-pad-add-2 mb-3">
              <img
                className="img-fluid"
                style={{ height: 160, width: 164 }}
                src={downloadImg}
                alt="Downloads"
              />
              <div className="row">
                <div className="col-9 playlist-player-name">Downloads</div>
                <div
                  className="col-1 playlist-player-name"
                  style={{ marginTop: 9, padding: 0 }}
                >
                  {downloads}
                </div>
              </div>
            </div>
          )}
          {renderItems()}
        </div>
        {data.length > 0 && showViewMore === true && (
          <div className="row justify-content-center mt-3">
            <div className="col-auto">
              <button className="btn dark" onClick={onClickViewMore}>
                Show More
              </button>
            </div>
          </div>
        )}
      </div>
      {showAddEditPlaylistModal === true && (
        <AddEditPlaylist
          id={itemId}
          show={showAddEditPlaylistModal}
          handleClose={() => setShowAddEditPlaylistModal(false)}
          refreshItems={() => getMyData(1)}
          itemName={itemName}
          playlistTypeext={playlistType}
        ></AddEditPlaylist>
      )}
      {deleteModal && (
        <ConfirmDeleteModal
          deleteModal={deleteModal}
          type={type}
          setdeleteModal={setdeleteModal}
          onconfirmDelete={onconfirmDelete}
        />
      )}
    </section>
  );
};
export default MyItems;
