import React, { useState, useEffect } from "react";
import { constant } from "../../utility/constant";
import {
  getDataApi,
  postDataApi,
  patchDataApi,
  deleteDataApi,
} from "../../utility/apiCall";
import { colors } from "../../utility/colors";
import { useHistory } from "react-router-dom";
import uploadImage1 from "../../assets/Images/uploadImage1.png";
import Multiselect from "multiselect-react-dropdown";
import { FileDrop } from "react-file-drop";
import ImageUploader from "react-images-upload";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  addErrorMesage,
  addSuccessMesage,
} from "../../components/CustomToster/CustomToaster";
import { AiFillCloseSquare } from "react-icons/ai";
import { addLoader, removeLoader } from "../../utility/CustomLoader";

function Support(props) {
    var history = useHistory();
    const [currentUserInfo, setCurrentUserInfo] = useState(
        localStorage.getItem("UserInfo") !== null
          ? JSON.parse(localStorage.getItem("UserInfo"))
          : undefined
      );
    const [isSupportDescErrShow, setSupportDescErr] = useState(false);
    const [isSupportNameErrShow, setSupportNameErr] = useState(false);
    const [supportDetails, setSupportDetails] = useState({
        title:"",
        supportDescription:"",
      });
      const onUpload = async () => {
        const {
          title,
          supportDescription
        } = supportDetails;
        if (
          !title &&
          !supportDescription
        ) {
          setSupportNameErr(true);
          setSupportDescErr(true);
        } else if (!title) {
          setSupportNameErr(true);
        } else if (!supportDescription) {
          setSupportDescErr(true);
        } else {
            let jsonData = {
                title: supportDetails.title,
                description: supportDetails.supportDescription,
                //user: currentUserInfo.id
              };
              if(currentUserInfo !== undefined){
                jsonData["user"] = currentUserInfo.id;
              }
              //console.log(jsonData);
              var formBody = [];
              for (var property in jsonData) {
                var encodedKey = encodeURIComponent(property);
                var encodedValue = encodeURIComponent(jsonData[property]);
                formBody.push(encodedKey + "=" + encodedValue);
              }
              formBody = formBody.join("&");
              var authHeader = {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
              };
              let respSupport = await postDataApi(
                constant.baseURL + "support",
                formBody,
                authHeader
              );
              if (
                respSupport != undefined &&
                respSupport.status != undefined &&
                respSupport.status == 201
              ) {
                addSuccessMesage("Submitted your query successfully")
                history.push("/Home")
              }else{
                addErrorMesage("Something Error")
              }
            //   console.log(respSupport)
        }
      };
  return (
    <div>
      <section className="community-room-banner event-upload-page1">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="upload-music-background">
                <div className="row">
                  <div className="col-12 col-md-12">
                   
                    
                    
                    <div className="featured-artists-title">Support</div>
                    <div className="floating-label mt-4">
                      <input
                        className="floating-input"
                        type="text"
                        placeholder=" "
                        maxLength={255}
                        onChange={(e) => {
                          setSupportDetails({
                            ...supportDetails,
                            title: e.target.value,
                          });
                          setSupportNameErr(false);
                        }}
                        value={supportDetails.title}
                      />
                      <span className="highlight"></span>
                      <label className="label">Title</label>
                      {isSupportNameErrShow && (
                        <div className="errorText" style={{ marginTop: "0px" }}>
                          Enter Title
                        </div>
                      )}
                    </div>
                    
                    <div className="floating-label mt-4">
                      <textarea
                        className="floating-input"
                        rows="4"
                        type="text"
                        placeholder=" "
                        onChange={(e) => {
                          setSupportDetails({
                            ...supportDetails,
                            supportDescription: e.target.value,
                          });
                          setSupportDescErr(false);
                        }}
                        value={supportDetails.supportDescription}
                      ></textarea>
                      <span className="highlight"></span>
                      <label className="label">Description</label>
                      {isSupportDescErrShow && (
                        <div className="errorText" style={{ marginTop: "0px" }}>
                          Enter Descrition
                        </div>
                      )}
                    </div>
                    

                    <div className="row justify-content-around">
                      <div className="col-6 col-md-4">
                        <button
                          className="add-to-playlist-btn"
                          onClick={onUpload}
                          style={{ width: "100%" }}
                        >
                            Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Support;
