import React, { useState, useEffect } from "react";
//import { useHistory } from "react-router-dom";
//import moment from "moment";
import { constant } from "../../utility/constant";
import {
  getDataApi,
  postDataApi,
  patchDataApi,
  deleteDataApi,
} from "../../utility/apiCall";
// import { AiOutlineHeart, AiFillHeart, AiOutlineMore } from "react-icons/ai";
// import downloadImg from "../../assets/Images/download.png";
// import likeSquareImg from "../../assets/Images/likeSquare.png";
// import add from "../../assets/Images/add.png";
// import { FiMoreVertical } from "react-icons/fi";
import { Modal } from "react-bootstrap";
// import { colors } from "../../utility/colors";
// import closeImg from "../../assets/Images/close.png";
// import searchImg from "../../assets/Images/search.png";
// import addSquareImg from "../../assets/Images/addSquare.png";
import musicImg from "../../assets/Images/music.png";
//import TinySlider from "tiny-slider-react";
import plusImg from "../../assets/Images/plus.png";
import minusImg from "../../assets/Images/minus.png";
import {
  addErrorMesage,
  addSuccessMesage,
} from "../CustomToster/CustomToaster";
import { AiFillWarning } from "react-icons/ai";
import { addLoader, removeLoader } from "../../utility/CustomLoader";

const AddEditPlaylist = ({ id, show, handleClose, refreshItems, itemName, playlistTypeext }) => {
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  //let history = useHistory();
  const [selectedPlaylist, setSelectedPlaylist] = useState(undefined);
  const [searchInputShow, setSearchInputShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [playlistName, setPlaylistName] = useState(itemName);
  const [isPlaylistNameErrShow, setPlaylistNameErr] = useState(false);
  const [isCreatePlaylistInputShow, setCreatePlaylistInputShow] =
    useState(false);
  const [showNewPlaylistModal, setShowNewPlaylistModal] = useState(false);
  const [playlistData, setPlaylistData] = useState([]);
  const [showAddSongsModal, setShowAddSongsModal] = useState(false);
  const [isAddSongsSectionShow, setIsAddSongsSectionShow] = useState(false);
  const [playlistType, setplaylistType] = useState(playlistTypeext ? playlistTypeext : "video")
  const [songsListState, setSongsListState] = useState({
    songsList: [], //{ id: 1, img: musicImg, songName: 'In The End', artistName: 'Linkin Park' },
    selectedSongs: [],
  });
  const [selectedSongID, setSelectedSongID] = useState([]);
  const [exitingSongID, setExitingSongID] = useState([]);
  const [newPlaylist, setNewPlayList] = useState("");
  const [showConfirmSongEmptyBox, setShowConfirmSongEmptyBox] = useState(false);
  const getAllMusics = async (i) => {
    let resMusics = await getDataApi(
      constant.baseURL + "musics?filter=musicType||$eq||" + i + "&filter=isDeleted||$eq||0&filter=status||$eq||1&sort=id,DESC"
    );
    if (resMusics != undefined && resMusics.length > 0) {
      let itms = [];
      resMusics.map((m) => {
        itms.push({ id: m.id, img: constant.musicURL, songName: m.title }); //(m.urlImage != undefined && m.urlImage != null && m.urlImage != "") ? constant.musicFileURL+m.urlImage :
      });
      setSongsListState((prevState) => {
        return { ...prevState, songsList: [...itms] };
      });
    }
  };
  const getMusicsByPlayList = async (playlistId, i) => {
    let resMusics = await getDataApi(
      constant.baseURL + "musics?filter=musicType||$eq||" + i + "&filter=isDeleted||$eq||0&filter=status||$eq||1&sort=id,DESC"
    );
    if (resMusics != undefined && resMusics.length > 0) {
      let itms = [];
      resMusics.map((m) => {
        itms.push({ id: m.id, img: constant.musicURL, songName: m.title });
      });
      setSongsListState((prevState) => {
        return { ...prevState, songsList: [...itms] };
      });
      if (currentUserInfo != undefined) {
        let apiURL = constant.baseURL +
        "musicplaylists/getMusicsByPlayList/" +
        playlistId +
        "?page=1&limit=10"
        if (currentUserInfo) {
          apiURL += "&userId=" + currentUserInfo.id;
        }
        let resPlayListsMusics = await getDataApi(
          apiURL
        );
        if (
          resPlayListsMusics != undefined &&
          resPlayListsMusics.data != undefined &&
          resPlayListsMusics.data.length > 0
        ) {
          let selecteditms = [];
          let exItem = [];
          resPlayListsMusics.data.map((p) => {
            selecteditms.push(p.musics.musics_id);
            exItem.push({
              id: p.musicplaylists_id,
              musicId: p.musics.musics_id,
            });
          });
          setSelectedSongID([...selecteditms]);
          setExitingSongID([...exItem]);
        }
      }
    }
  };
  const onCreateAddPlaylist = () => {
    if (!playlistName) return setPlaylistNameErr(true);
    //addLoader();
    createUpdatePlaylists(id);
  };
  const getPlaylistByName = async () => {
    //http://45.80.152.23:4527/playlists?filter=name||$eq||pl04
    let resPlaylist = await getDataApi(
      constant.baseURL +
      "playlists?filter=name||$eq||" +
      playlistName +
      "&filter=user.id||$eq||" +
      currentUserInfo.id +
      "&filter=isDeleted||$eq||0"
    );
    return resPlaylist;
  };
  const createUpdatePlaylists = async (id) => {
    if (currentUserInfo != undefined) {
      let respName = await getPlaylistByName();
      if (id === 0 && respName.length > 0) {
        addErrorMesage("Playlist with the same name already exists!");
        //removeLoader();
        return;
      } else {
        let jsonData = { user: currentUserInfo.id, name: playlistName, playlistType: playlistType };
        var formBody = [];
        for (var property in jsonData) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(jsonData[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        var authHeader = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        };
        let respPlayList;
        if (id !== 0) {
          //update
          respPlayList = await patchDataApi(
            constant.baseURL + "playlists/" + id,
            formBody,
            authHeader
          );
          if (
            respPlayList != undefined &&
            respPlayList.status != undefined &&
            respPlayList.status == 200
          ) {
            setNewPlayList(respPlayList.data);
            getMusicsByPlayList(id, playlistType);
            setIsAddSongsSectionShow(true);
            setCreatePlaylistInputShow(false);
            setShowNewPlaylistModal(false);
            setShowAddSongsModal(true);
            //handleClose();
          } else {
            addErrorMesage("Error encountered while updating playlist");
          }
        } else {
          //create
          respPlayList = await postDataApi(
            constant.baseURL + "playlists",
            formBody,
            authHeader
          );
          if (
            respPlayList != undefined &&
            respPlayList.status != undefined &&
            respPlayList.status == 201
          ) {
            if (respPlayList.data != undefined) {
              setNewPlayList(respPlayList.data);
            }
            getAllMusics(playlistType);
            setIsAddSongsSectionShow(true);
            setCreatePlaylistInputShow(false);
            setShowNewPlaylistModal(true);
          } else {
            addErrorMesage("Error encountered while creating new playlist");
            //removeLoader();
          }
        }
      }
    }
    // else{
    //   removeLoader();
    // }
  };
  const addSongID = (id) => {
    if (selectedSongID.length !== 0) {
      if (!selectedSongID.includes(id)) {
        setSelectedSongID([...selectedSongID, id]);
        //addSuccessMesage("Song added to the playlist!");
      } else {
        let newArr = selectedSongID.filter((i) => i !== id);
        setSelectedSongID(newArr);
        //addErrorMesage("Song removed from the playlist!");
      }
    } else {
      let arr = [];
      arr.push(id);
      setSelectedSongID(arr);
      //addSuccessMesage("Song added to the playlist!");
    }
  };
  const saveSongToPlayList = async () => {
    if (exitingSongID.length === 0) {
      addLoader();
    }
    let promise = new Promise((resolve, reject) => {
      return callSaveSongToPlayList().then((rsp) => {
        if (exitingSongID.length === 0) {
          addSuccessMesage("Playlist Created");
        }
        resetPlayListModal();
        resolve(true);
      });
    });
    return promise;
  };
  const callSaveSongToPlayList = async () => {
    if (newPlaylist != undefined) {
      let promises_array = [];
      selectedSongID.map((item) => {
        promises_array.push(
          new Promise((resolve, reject) => {
            let jsonData = { playlists: newPlaylist.id, music: item };
            var formBody = [];
            for (var property in jsonData) {
              var encodedKey = encodeURIComponent(property);
              var encodedValue = encodeURIComponent(jsonData[property]);
              formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
            return AddMusicToPlaylists(formBody, item)
              .then(() => {
                resolve(true);
              })
              .catch((error) => {
                reject(false);
                resetPlayListModal();
              });
          })
        );
      });
      return Promise.all(promises_array);
    } else {
      return true;
    }
  };

  const updateSongToPlayList = () => {
    addLoader();
    return deleteExistingMusicFromPlayList().then((res) => {
      return saveSongToPlayList().then((r) => {
        addSuccessMesage("Playlist Updated");
        //enqueueSnackbar('Playlist Updated', {variant: 'success', anchorOrigin: {vertical: 'top', horizontal: 'right', },    });
        resetPlayListModal();
      });
    });
  };
  const deleteExistingMusicFromPlayList = () => {
    let promises_array = [];
    if (exitingSongID.length > 0) {
      exitingSongID.map((e) => {
        promises_array.push(
          new Promise((resolve, reject) => {
            let itemFound = selectedSongID.filter((x) => x == e.musicId);
            if (itemFound.length == 0) {
              return deleteMusicFromPlaylist(e.id)
                .then(() => {
                  resolve(true);
                })
                .catch((error) => {
                  reject(false);
                });
            } else {
              resolve(true);
            }
          })
        );
      });
    }
    return Promise.all(promises_array);
  };
  const deleteMusicFromPlaylist = async (id) => {
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return await Promise.resolve(
      await deleteDataApi(constant.baseURL + "musicplaylists/" + id, authHeader)
    )
      .then((response) => {
        if (
          response != undefined &&
          response.status != undefined &&
          response.status == 201
        ) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
  };
  const AddMusicToPlaylists = async (data, musicId) => {
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let resExits = await getDataApi(
      constant.baseURL +
      "musicplaylists?filter=music.id||$eq||" +
      musicId +
      "&filter=playlists.id||$eq||" +
      id
    );
    //console.log("resExits: ", resExits);
    //if(resExits != undefined && resExits.statusCode != 404 && resExits.error != undefined && resExits.error == "Not Found"){
    if (resExits.length == 0) {
      return await Promise.resolve(
        await postDataApi(constant.baseURL + "musicplaylists", data, authHeader)
      )
        .then((response) => {
          if (
            response != undefined &&
            response.status != undefined &&
            response.status == 201
          ) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          return false;
        });
    } else {
      return false;
    }
  };
  const resetPlayListModal = () => {
    //getMyPlayLists();
    removeLoader();
    setShowAddSongsModal(false);
    setShowNewPlaylistModal(false);
    setPlaylistName("");
    setIsAddSongsSectionShow(false);
    handleClose();
    refreshItems();
  };
  const renderSongList = () => {
    if (songsListState.songsList.length === 0) {
      return null;
    } else {
      return songsListState.songsList.map((song, index) => {
        return (
          <div key={index} className="col-12 col-md-6">
            <div className="row justify-content-center mt-3">
              <div className="col-12">
                <div className="follower-box">
                  <div className="row align-items-center ">
                    <div className="col-2">
                      <img
                        className="img-fluid follower-box-img"
                        src={playlistTypeext == "music" ? constant.musicURL : constant.videoURL}
                        alt=""
                      />
                    </div>
                    <div className="col-8">
                      <div className="episode-title">{song.songName}</div>
                      <div className="episode-sub-title"></div>
                    </div>
                    <div className="col-1">
                      <img
                        style={{ height: 30, cursor: "pointer" }}
                        onClick={() => {
                          addSongID(song.id);
                        }}
                        src={renderPlusMinusImg(song.id)}
                        alt="add-song-image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  };
  const renderPlusMinusImg = (id) => {
    if (selectedSongID.length !== 0) {
      if (selectedSongID.includes(id)) return minusImg;
      else return plusImg;
    } else return plusImg;
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop={"static"}
        keyboard={false}
        aria-labelledby="example-custom-modal-styling-title"
        size={"lg"}
      >
        <Modal.Header
          closeButton={false}
          style={{ backgroundColor: "#313131", border: 0 }}
        >
          <Modal.Title id="example-custom-modal-styling-title">
            {id !== 0 ? "Update Playlist" : "Create New Playlist"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#313131" }}>
          <div className="username" >
            <div className="floating-label">
              <input
                className="floating-input"
                type="text"
                placeholder=" "
                onChange={(e) => {
                  setPlaylistName(e.target.value);
                  setPlaylistNameErr(false);
                }}
                value={playlistName}
              />
              <span className="highlight"></span>
              <label className="label">Playlist Name</label>
              {isPlaylistNameErrShow == true && (
                <div className="errorText" style={{ marginTop: "0px" }}>
                  Enter Playlist Name
                </div>
              )}
            </div>
            <div className="login-sub-title mt-4">
              Select Type
            </div>
            <div className="row mt-4">
              <div className="col-12 col-md-6">
                <div className="selection-page">
                  Video
                  <span>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        disabled={
                          id !== undefined && id !== 0
                            ? playlistType !==
                            "video" && true
                            : false
                        }
                        checked={
                          playlistType === "video"
                        }
                        onChange={() => {
                          // if (
                          //   podcastDetails.uploadedPodcast ===
                          //   null
                          // ) {
                          setplaylistType("video");
                        }
                          // }
                        }
                        value="video"
                      />
                      <label
                        className="form-check-label"
                        for="inlineRadio1"
                      ></label>
                    </div>
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 mt">
                <div className="selection-page">
                  Music
                  <span>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        disabled={
                          id !== undefined && id !== 0
                            ? playlistType !==
                            "music" && true
                            : false
                        }
                        checked={
                          playlistType === "music"
                        }
                        onChange={() => {
                          // if (
                          //   podcastDetails.uploadedPodcast ===
                          //   null
                          // ) {
                          setplaylistType("music");
                        }
                        }
                        // }
                        value="music"
                      />
                      <label
                        className="form-check-label"
                        for="inlineRadio1"
                      ></label>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <button
                className="cancel-btn"
                style={{ cursor: "pointer" }}
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">Cancel</span>
              </button>
            </div>
            <div className="col-6">
              <button
                className="add-to-playlist-btn"
                style={{ width: "100%", cursor: "pointer" }}
                // style={{}}
                onClick={() => {
                  onCreateAddPlaylist();
                }}
              >
                {id !== 0 ? "Update" : "Create"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* {showNewPlaylistModal === true && id !== 0 && ( */}
      {showNewPlaylistModal === true && id === 0 && (
        <Modal
          show={showNewPlaylistModal}
          size={"lg"}
          onHide={() => {
            setShowNewPlaylistModal(false);
          }}
          backdrop={"static"}
          keyboard={false}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body style={{ backgroundColor: "#313131" }}>
            <section className="add-to-fav-banner">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="add-favourite-song">
                      <div className="row">
                        <div className="col-12 col-md-12">
                          <div className="add-favorite">
                            <div className="wrapText fs-40 fw-700">
                              {id !== 0 ? "UPDATE PLAYLIST" : "NEW PLAYLIST"}{" "}
                            </div>
                            <img
                              className="add-fav-upoad"
                              src={playlistType == "music" ? musicImg : constant.videoURL}
                              alt=""
                              style={{ height: 200, width: 200 }}
                            />
                            <div className="add-fav-subtitle">
                              Looks like your playlist is empty. Please add
                              songs to the playlist.
                            </div>
                            <button
                              className="btn btn-primary-event mt-0 "
                              type="button"
                              onClick={() => {
                                setShowNewPlaylistModal(false);
                                setShowAddSongsModal(true);
                              }}
                            >
                              Add Songs
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Modal.Body>
        </Modal>
      )}
      {showAddSongsModal === true && (
        <Modal
          show={showAddSongsModal}
          size={"lg"}
          onHide={() => setShowAddSongsModal(false)}
          backdrop={"static"}
          keyboard={false}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header
            closeButton={false}
            style={{ backgroundColor: "#313131", border: 0 }}
          >
            <Modal.Title id="example-custom-modal-styling-title">
              Add Songs to {playlistName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#313131" }}>
            <div className="add-favourite-song-tab">
              <div className="favourite-song-tab">
                <div className="row">{renderSongList()}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <button
                  className="cancel-btn"
                  style={{ cursor: "pointer" }}
                  aria-label="Close"
                  onClick={() => {
                    if (id === 0 && selectedSongID.length === 0) {
                      setShowAddSongsModal(false);
                      setShowConfirmSongEmptyBox(true);
                    } else {
                      resetPlayListModal();
                    }
                  }}
                >
                  <span aria-hidden="true">Cancel</span>
                </button>
              </div>
              <div className="col-6">
                <button
                  className="add-to-playlist-btn"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    exitingSongID.length === 0
                      ? saveSongToPlayList()
                      : updateSongToPlayList();
                  }}
                >
                  {id !== 0 ? "Update To Playlist" : "Add To Playlist"}{" "}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <Modal
        show={showConfirmSongEmptyBox}
        size={"lg"}
        backdrop={"static"}
        keyboard={false}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          closeButton={false}
          style={{ backgroundColor: "#313131", border: 0 }}
        >
          <Modal.Title id="example-custom-modal-styling-title">
            <AiFillWarning size={22} /> Please Confirm
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#313131" }}>
          <div style={{ height: 140 }}>
            <div className="floating-label">
              <label className="label">
                Your playlist is empty. Are you sure you want to cancel this?
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <button
                className="cancel-btn"
                style={{ cursor: "pointer" }}
                aria-label="Close"
                onClick={() => {
                  setShowConfirmSongEmptyBox(false);
                  setShowAddSongsModal(true);
                }}
              >
                <span aria-hidden="true">No</span>
              </button>
            </div>
            <div className="col-6">
              <button
                className="add-to-playlist-btn"
                style={{ cursor: "pointer", width: "100%" }}
                onClick={() => {
                  setShowConfirmSongEmptyBox(false);
                  resetPlayListModal();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddEditPlaylist;
