import { useLocation } from "react-router-dom";
import { hashCodeUrl } from "./EncodeAndDecodeUrl";

export function GetPath() {
  const path = useLocation();
  if (
    path.pathname === "/login" ||
    path.pathname === "/createAccount" ||
    path.pathname === "/otpverification" ||
    path.pathname === "/completeProfile" ||
    path.pathname === "/forgotpassword" ||
    path.pathname === "/resetpassword" ||
    path.pathname === "/completeprofile" ||
    path.pathname === "/firstAlbumMessage" ||
    path.pathname === "/createAlbum"
  )
    return false;
  return true;
}
