import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { constant } from "../../utility/constant";
import { getDataApi, postDataApi } from "../../utility/apiCall";
import { Modal } from "react-bootstrap";
import addSquareImg from "../../assets/Images/addSquare.png";
import musicImg from "../../assets/Images/music.png";
import {
  addErrorMesage,
  addSuccessMesage,
} from "../CustomToster/CustomToaster";

const AddPlayListModal = ({ show, handleClose, title, id, type, extra }) => {
  //console.log(extra)
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );

  const [selectedPlaylist, setSelectedPlaylist] = useState(undefined);
  // const [searchInputShow, setSearchInputShow] = useState(false);
  // const [searchTerm, setSearchTerm] = useState("");
  const [playlistName, setPlaylistName] = useState("");
  const [playlistType, setplaylistType] = useState(type ? type : "video")
  const [isPlaylistNameErrShow, setPlaylistNameErr] = useState(false);
  const [isCreatePlaylistInputShow, setCreatePlaylistInputShow] =
    useState(null);
  const [playlistData, setPlaylistData] = useState([]);
  // const settings = {
  //   controls: false,
  //   items: 5,
  //   loop: false,
  //   nav: false,
  //   mouseDrag: true,
  //   margin: 2,
  //   responsiveClass: true,
  //   responsive: {
  //     0: {
  //       items: 3,
  //       nav: false,
  //     },
  //     600: {
  //       items: 3,
  //       nav: false,
  //     },
  //     1000: {
  //       items: 5,
  //       nav: false,
  //       loop: false,
  //       margin: 20,
  //     },
  //   },
  // };
  const [albumActiveId, setalbumActiveId] = useState("");
  const onCreateAddPlaylist = async () => {
    if (!playlistName) return setPlaylistNameErr(true);
    await createPlaylists();
  };
  const getPlaylistsByUser = async () => {
    if (currentUserInfo != undefined) {
      let resUserPlaylists = await getDataApi(
        constant.baseURL +
        "playlists?filter=user.id||$eq||" +
        currentUserInfo.id + "&filter=playlistType||$eq||" + type +
        "&filter=isDeleted||$eq||0&sort=id,DESC"
      );
      if (resUserPlaylists !== undefined && resUserPlaylists.length > 0) {
        resUserPlaylists.map((p) => {
          p["img"] = constant.musicURL;
        });
        setPlaylistData([...resUserPlaylists]);
        setCreatePlaylistInputShow(false)
      }
    }
  };
  const createPlaylists = async () => {
    if (currentUserInfo != undefined) {
      let jsonData = { user: currentUserInfo.id, name: playlistName, playlistType: playlistType };
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let respCreatePlayList = await postDataApi(
        constant.baseURL + "playlists",
        formBody,
        authHeader
      );
      //console.log("respCreatePlayList: ", respCreatePlayList);
      if (
        respCreatePlayList !== undefined &&
        respCreatePlayList.status !== undefined &&
        respCreatePlayList.status == 201
      ) {
        addSuccessMesage("Playlist Created");
        let newPlaylistItem = [...playlistData];
        respCreatePlayList.data["img"] = type == "music" ? musicImg : constant.videoURL;
        newPlaylistItem.push(respCreatePlayList.data);
        setPlaylistData([...newPlaylistItem]);
        //console.log(newPlaylistItem);
      } else {
        addErrorMesage("Error encountered while creating new playlist");
      }
      setCreatePlaylistInputShow(false);
    }
  };
  const AddMusicToPlaylists = async () => {
    if (selectedPlaylist != undefined && (extra != selectedPlaylist.id)) {
      let jsonData = { playlists: selectedPlaylist.id, music: id };
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resp = await postDataApi(
        constant.baseURL + "musicplaylists",
        formBody,
        authHeader
      );
      //console.log("Create MusicPlaylists: ", resp);
      if (resp !== undefined && resp.status !== undefined && resp.status === 201) {
        addSuccessMesage(
          "Music added to " + selectedPlaylist.name + " playlist"
        );
      } else {
        addErrorMesage("Error encountered while adding music to playlist");
      }
      handleClose();
    } else {
      addErrorMesage("You can't add same playlist");
    }
  };
  const AddSongToSelectedPlaylist = (item, index) => {
    setalbumActiveId(index);
    //console.log("Selected Playlist: ", item);
    setSelectedPlaylist(item);
  };
  const renderPlaylist = () => {
    if (playlistData.length > 0) {
      return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {playlistData.map((item, index) => {
            return (
              <div
                key={index}
                className={`item col-sm-3 col-md-auto cust-pad-add-2`}
                onClick={() => AddSongToSelectedPlaylist(item, index)}
                style={{ cursor: "pointer", width: 125 }}
              >
                <img
                  className={`img-fluid ${(albumActiveId === index && "playlistActive")}`}
                  src={type == "music" ? musicImg : constant.videoURL}
                  alt={item.name}
                  style={{ height: 100, width: 100 }}
                />
                <div className="playlist-player-name wrapText">{item.name}</div>
              </div>
            );
          })}
        </div>
      );
    }
  };
  useEffect(() => {
    getPlaylistsByUser();
  }, []);
  return (
    <>
      <Modal
        show={show}
        size={"lg"}
        onHide={handleClose}
        backdrop={"static"}
        keyboard={false}
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#313131", border: 0 }}
        >
          <Modal.Title
            className="wrapText"
          >
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#313131" }}>
          <div>
            {isCreatePlaylistInputShow == false ?
              <div>
                <div className="row" style={{ marginLeft: 0, marginRight: 10, marginTop: 40 }}>
                  <div
                    className="col-6 col-sm-3 col-md-auto"
                    onClick={() => {
                      setCreatePlaylistInputShow(true);
                    }}
                  >
                    <img
                      className="img-fluid"
                      src={addSquareImg}
                      alt="Add"
                      style={{ cursor: "pointer", height: 100, width: 100 }}
                    />
                    <div className="playlist-player-name">Create Playlist</div>
                  </div>
                  <div
                    style={{
                      // display: "flex",
                      // width: 625,
                      // flexWrap: "initial",
                      // overflowX: "auto",
                    }}
                  >
                    {renderPlaylist()}
                  </div>
                </div><div className="row custom-playlist-pad">
                  <div className="col-6">
                    <button
                      className="cancel-btn"
                      style={{ cursor: "pointer" }}
                      aria-label="Close"
                      onClick={handleClose}
                    >
                      <span aria-hidden="true">Cancel</span>
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="add-to-playlist-btn"
                      style={{ cursor: "pointer", width: "100%" }}
                      onClick={() =>
                        isCreatePlaylistInputShow === true
                          ? onCreateAddPlaylist()
                          : AddMusicToPlaylists()
                      }
                    >
                      Add To Playlist
                    </button>
                  </div>
                </div>
              </div>

              : isCreatePlaylistInputShow == true ? <div>
                <div className="username" style={{ height: "auto" }}>
                  <div className="floating-label">
                    <input
                      className="floating-input"
                      type="text"
                      placeholder=" "
                      onChange={(e) => {
                        setPlaylistName(e.target.value);
                        setPlaylistNameErr(false);
                      }}
                      value={playlistName}
                    />
                    <span className="highlight"></span>
                    <label className="label">Playlist Name</label>
                    {isPlaylistNameErrShow === true && (
                      <div className="errorText" style={{ marginTop: 0 }}>
                        Enter Playlist Name
                      </div>
                    )}
                  </div>
                  <div className="login-sub-title mt-4">
                    Select Type
                  </div>
                  <div className="row mt-4">
                    <div className="col-12 col-md-6">
                      <div className="selection-page">
                        Video
                        <span>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              disabled={
                                id !== undefined && id !== 0
                                  ? playlistType !==
                                  "video" && true
                                  : false
                              }
                              checked={
                                playlistType === "video"
                              }
                              onChange={() => {
                                // if (
                                //   podcastDetails.uploadedPodcast ===
                                //   null
                                // ) {
                                setplaylistType("video");
                              }
                                // }
                              }
                              value="video"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio1"
                            ></label>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mt">
                      <div className="selection-page">
                        Music
                        <span>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              disabled={
                                id !== undefined && id !== 0
                                  ? playlistType !==
                                  "music" && true
                                  : false
                              }
                              checked={
                                playlistType === "music"
                              }
                              onChange={() => {
                                // if (
                                //   podcastDetails.uploadedPodcast ===
                                //   null
                                // ) {
                                setplaylistType("music");
                              }
                              }
                              // }
                              value="music"
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio1"
                            ></label>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-6">
                    <button
                      className="cancel-btn"
                      style={{ cursor: "pointer" }}
                      aria-label="Close"
                      onClick={handleClose}
                    >
                      <span aria-hidden="true">Cancel</span>
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="add-to-playlist-btn"
                      style={{ cursor: "pointer", width: "100%" }}
                      onClick={() =>
                        isCreatePlaylistInputShow === true
                          ? onCreateAddPlaylist()
                          : AddMusicToPlaylists()
                      }
                    >
                      Add To Playlist
                    </button>
                  </div>
                </div>
              </div> : null}
            {/* {isCreatePlaylistInputShow === false ? 
           
          :null} */}
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddPlayListModal;
