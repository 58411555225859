import React, { useState, useEffect } from "react";
import { constant } from "../../utility/constant";
import {
  getDataApi,
  postDataApi,
  patchDataApi,
  deleteDataApi,
} from "../../utility/apiCall";
import { useHistory } from "react-router-dom";
import close from "../../assets/Images/close.png";
import uploadImage1 from "../../assets/Images/uploadImage1.png";
import uploadMusic2 from "../../assets/Images/uploadMusic2.png";
import musicImg from "../../assets/Images/music.png";
import videoImg from "../../assets/Images/video.png";
import { FileDrop } from "react-file-drop";
import ImageUploader from "react-images-upload";
import Multiselect from "multiselect-react-dropdown";
import { colors } from "../../utility/colors";
import {
  addErrorMesage,
  addSuccessMesage,
} from "../../components/CustomToster/CustomToaster";
import { CircularProgressbar } from "react-circular-progressbar";
import { AiFillCloseSquare } from "react-icons/ai";
import { addLoader, removeLoader } from "../../utility/CustomLoader";

function UploadPodcast(props) {
  let history = useHistory();

  const { id, item } = props.location.state;
  let duration = item !== undefined ? item.duration : ""
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  let audioReg = /\.(?:opus|flac|webm|weba|wav|ogg|m4a|mp3|oga|mid|amr|aiff|wma|au|aac|mp4|avi|mov)$/i;
  let videoReg = /\.(?:ogm|wmv|mpg|webm|ogv|mov|asx|mpeg|mp4|m4v|avi)$/i;
  const [podFiles, setPodFiles] = useState([]);
  //jayraj mehta
  const [podFiles1, setPodFiles1] = useState([]);
  //jayraj mehta
  const [showSelectedPodcastFile, setShowSelectedPodcastFile] = useState(
    id !== 0 ? true : false
  );
  const [coverImg, setCoverImg] = useState(null);
  const [dragNDropText, setDragNDropText] = useState("Drop your file here");
  const [podcastCategories, setPodCastCategories] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [podcastDetails, setPodcastDetails] = useState({
    podcastTitle: item !== undefined ? item.name : "",
    podcasturlPodcast: item !== undefined ? item.urlPodcast : "",
    podcastDescription: item !== undefined ? item.description : "",
    podcastType: item !== undefined ? item.podcastType : "music",
    podcasturlImage: item !== undefined ? item.urlImage : "",
    categoryList: [],
    selectedCategoryList: [],
    uploadedPodcast: null,
    existingCategoryList: [],
    id: id !== undefined ? id : 0,
    size: item !== undefined ? item.size : 0,
  });
  const [isUploadPodcast, setUploadPodcast] = useState(false);
  const [isPodcastTitleErrShow, setPodcastTitleErr] = useState(false);
  const [isPodcastDescErrShow, setPodcastDescErr] = useState(false);
  const [isAddCategoryErrShow, setAddCategoryErr] = useState(false);
  const multiSelectStyle = {
    multiselectContainer: {
      color: "white",
      fontSize: 14,
    },
    option: {
      // To change css for dropdown options
      color: colors.colors13,
    },
    chips: {
      background: colors.colors13,
      fontSize: 15,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 8,
      paddingBottom: 8,
      borderRadius: 8,
    },
    searchBox: {
      border: "1px solid #8186AF",
      borderRadius: "2px",
      //fontFamily: 'Mr Eaves Mod OT',
      fontSize: 15,
      minHeight: 40,

      //width: 395,
    },
  };
  const onDrop = (file) => fileValidation(file[0]);
  const onUploadImg = (file) => fileValidation(file[0]);
  const fileValidation = (file) => {
    // Allowing file type
    let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if (!allowedExtensions.exec(file?.name)) {
      addErrorMesage("Please upload a valid image file");
      file = "";
      setDragNDropText("Drop your image here or browse");
      return false;
    } else {
      let imageSrc = URL.createObjectURL(file);
      setDragNDropText("Drop your image here or browse");
      setCoverImg(imageSrc);
      let fileItem = [];
      fileItem.push(file);
      //jayraj mehta
      setPodFiles1([...fileItem]);
      //jayraj mehta
    }
  };

  const onSelectCategory = (selectedList) => {
    //console.log("Selected Category: ", selectedList);
    setPodcastDetails((prevState) => {
      return { ...prevState, selectedCategoryList: selectedList };
    });
    setAddCategoryErr(false);
  };

  const onRemoveSelectCategory = (selectedList) => {
    setPodcastDetails((prevState) => {
      return { ...prevState, selectedCategoryList: selectedList };
    });
  };

  const onDeleteUploadedPodcast = () => {
    setPodcastDetails((prevState) => {
      return { ...prevState, uploadedPodcast: null };
    });
    return false;
  };

  const onSelectPodcast = (event) => {
    if (event) {
      if (podcastDetails.podcastType === "music") {
        if (!audioReg.exec(event.target.files[0].name)) {
          addErrorMesage("Please upload a valid audio file");
          return;
        }
      } else if (podcastDetails.podcastType === "video") {
        if (!videoReg.exec(event.target.files[0].name)) {
          addErrorMesage("Please upload a valid video file");
          return;
        }
      }
      setPodcastDetails((prevState) => {
        return { ...prevState, uploadedPodcast: event.target.files[0] };
      });
      setShowSelectedPodcastFile(false);

      setPodFiles([...event.target.files]);
      //console.log(podFiles)
      //console.log(podcastDetails, podFiles)
      return false;
    }
  };
  //console.log(podcastDetails, podFiles)
  const onUpload = async () => {
    const {
      podcastTitle,
      selectedCategoryList,
      podcastDescription,
      // uploadedPodcast,
    } = podcastDetails;
    if (
      !podcastTitle &&
      !podcastDescription &&
      selectedCategoryList.length === 0 &&
      // uploadedPodcast === null &&
      id === undefined
    ) {
      setPodcastTitleErr(true);
      setAddCategoryErr(true);
      setPodcastDescErr(true);
      // addErrorMesage("Upload a podcast file!");
      //enqueueSnackbar("Upload a podcast file!", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right', } });
    } else if (!podcastTitle) {
      setPodcastTitleErr(true);
    } else if (!podcastDescription) {
      setPodcastDescErr(true);
    } else if (selectedCategoryList.length === 0) {
      setAddCategoryErr(true);
    } 
    // else if (uploadedPodcast === null && id === 0) {
    //   addErrorMesage("Upload a podcast file!");
    //   //enqueueSnackbar("Upload a podcast file!", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right', } });
    // } else if ((id !== 0 && uploadedPodcast !== null && showSelectedPodcastFile == true) || (id !== 0 && uploadedPodcast == null && showSelectedPodcastFile == false)) {
    //   addErrorMesage("Upload a podcast file!");
    // } 
    else {
      //console.log("DONE");
      setUploadPodcast(true);
      setDragNDropText("Drop your file here");
      //Check if podcast with same name exists or not
      let respName = await getPodcastByName();
      //console.log("respName: ", respName);
      if (id === 0 && respName.length > 0) {
        addErrorMesage("Podcast with the same name already exists!");
        return;
      } else if (id !== 0 && respName.length > 0 && id !== respName[0].id) {
        addErrorMesage("Podcast with the same name already exists!");
        return;
      }
      //
      // if (podFiles.length > 0) {

      //   uploadPodcastFiles();

      // } else
       if (podFiles1.length > 0) {

        uploadPodcastFiles1()

      } 
      else {
        if (id !== 0) {
          setUploadProgress(25);
          createPodcast(
            podcastDetails.podcasturlImage,
            // podcastDetails.podcasturlPodcast
          );
        } else {
          resetUploadPodcast(0);
        }
      }
    }
  };

  const resetUploadPodcast = (progressValue) => {
    setUploadProgress(progressValue);
    setCoverImg(null);
    setPodFiles([]);
    setPodcastDetails({
      albumName: "",
      podcastTypeType: "music",
      podcastDescription: "",
      podcastTitle: "",
      //categoryList: musicCategories,
      selectedCategoryList: [],
    });
    history.push("/MyPodcasts");
  };
  const getVideoDuration = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = async () => await resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = error => reject(error);
    });
  const uploadPodcastFiles = async () => {
    addLoader();
    if (podFiles1.length > 0) {
      let customHeaders = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer ",
      };
      let data = new FormData();
      duration = await getVideoDuration(podFiles[0]);
      let extraprodFiles = [...podFiles1, ...podFiles]
      extraprodFiles.map((m) => {
        data.append(constant.uplFileParameter, m);
      });
      let res = await postDataApi(
        constant.podcastFileUploadURL,//process.env.REACT_APP_NODE_ENV === "development" ? constant.baseURL + "podcastfiles/uploadMultipleFiles" : constant.baseURL + "podcastfiles",
        data,
        customHeaders
      );
      //console.log("Upload Podcast Files: ", res.data);
      if (
        res != undefined &&
        res.status != undefined &&
        res.status === 201 &&
        res.data != undefined &&
        res.data.data != undefined
      ) {
        //console.log("Uploaded Podcast file: ", res.data.data);
        let coverFileName = "";
        coverFileName = res.data.data[0].filename
        // coverImg != null
        //   ? res.data.data[0].filename
        //   : id !== 0
        //   ? podcastDetails.podcasturlImage
        //   : "";
        setUploadProgress(25);
        //console.log("jjjjj")
        createPodcast(
          coverFileName,
          res.data.data[1].filename
          // coverImg != null
          //   ? id !== 0
          //     ? podcastDetails.podcasturlPodcast
          //     : res.data.data[1].filename
          //   : id !== 0
          //   ? podcastDetails.podcasturlPodcast
          //   : res.data.data[0].filename
          ,
          res.data.data[1].originalname
          // coverImg != null
          // ? id !== 0
          //   ? podcastDetails.podcasturlPodcast
          //   : res.data.data[1].originalname
          // : id !== 0
          //   ? podcastDetails.podcasturlPodcast
          //   : res.data.data[0].originalname
        );
      } else {
        removeLoader();
        addErrorMesage("Error in uploading podcast files");
      }
    } else {
      let customHeaders = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer ",
      };
      let data = new FormData();
      duration = await getVideoDuration(podFiles[0]);
      let extraprodFiles = [...podFiles]
      extraprodFiles.map((m) => {
        data.append(constant.uplFileParameter, m);
      });
      let res = await postDataApi(
        constant.podcastFileUploadURL,//process.env.REACT_APP_NODE_ENV === "development" ? constant.baseURL + "podcastfiles/uploadMultipleFiles" : constant.baseURL + "podcastfiles",
        data,
        customHeaders
      );
      //console.log("Upload Podcast Files: ", res.data);
      if (
        res != undefined &&
        res.status != undefined &&
        res.status === 201 &&
        res.data != undefined &&
        res.data.data != undefined
      ) {
        //console.log("Uploaded Podcast file: ", res.data.data);
        let extra = []
        if (res.data.data >= 0) {
          extra = res.data.data
        } else {
          extra = [res.data.data]
        }


        let coverFileName = "";
        coverFileName = podcastDetails.podcasturlImage;
        // coverImg != null
        //   ? res.data.data[0].filename
        //   : id !== 0
        //   ? podcastDetails.podcasturlImage
        //   : "";
        setUploadProgress(25);
        createPodcast(
          coverFileName,
          // coverImg != null
          //   ? id !== 0
          //     ? podcastDetails.podcasturlPodcast
          //     : res.data.data[1].filename
          //   : id !== 0
          //   ? podcastDetails.podcasturlPodcast
          //   : 
          extra[0].filename,
          // coverImg != null
          // ? id !== 0
          //   ? podcastDetails.podcasturlPodcast
          //   : res.data.data[1].originalname
          // : id !== 0
          //   ? podcastDetails.podcasturlPodcast
          //   :
          extra[0].originalname
        );
      } else {
        removeLoader();
        addErrorMesage("Error in uploading podcast files");
      }
      // setUploadProgress(25);
      // createPodcast(
      //   podcastDetails.podcasturlImage,
      //   podcastDetails.podcasturlPodcast
      // );
    }


  };

  const uploadPodcastFiles1 = async () => {
    addLoader();
    // if (podFiles.length > 0) {
    //   let customHeaders = {
    //     Accept: "application/json",
    //     "Content-Type": "multipart/form-data",
    //     Authorization: "Bearer ",
    //   };
    //   let data = new FormData();
    //   duration = await getVideoDuration(podFiles[0]);
    //   console.log(duration)
    //   let extraprodFiles = [...podFiles1, ...podFiles]
    //   extraprodFiles.map((m) => {
    //     data.append("image", m);
    //   });
    //   let res = await postDataApi(
    //     constant.podcastFileUploadURL,//process.env.REACT_APP_NODE_ENV === "development" ? constant.baseURL + "podcastfiles/uploadMultipleFiles" : constant.baseURL + "podcastfiles",
    //     data,
    //     customHeaders
    //   );
    //   //console.log("Upload Podcast Files: ", res.data);
    //   if (
    //     res != undefined &&
    //     res.status != undefined &&
    //     res.status === 201 &&
    //     res.data != undefined &&
    //     res.data.data != undefined
    //   ) {
    //     console.log("Uploaded Podcast file: ", res.data.data);
    //     let coverFileName = "";
    //     coverFileName =
    //       coverImg != null
    //         ? res.data.data[0].filename
    //         : id !== 0
    //           ? podcastDetails.podcasturlImage
    //           : "";
    //     setUploadProgress(25);
    //     createPodcast(
    //       coverFileName,
    //       coverImg != null
    //         ? id !== 0
    //           ? podcastDetails.podcasturlPodcast
    //           : res.data.data[1].filename
    //         : id !== 0
    //           ? podcastDetails.podcasturlPodcast
    //           : res.data.data[0].filename,
    //       coverImg != null
    //         ? id !== 0
    //           ? podcastDetails.podcasturlPodcast
    //           : res.data.data[1].originalname
    //         : id !== 0
    //           ? podcastDetails.podcasturlPodcast
    //           : res.data.data[0].originalname
    //     );
    //   } else {
    //     removeLoader();
    //     addErrorMesage("Error in uploading podcast files");
    //   }
    // } else {
      let customHeaders = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer ",
      };
      let data = new FormData();

      let extraprodFiles = [...podFiles1]
      extraprodFiles.map((m) => {
        data.append(constant.uplFileParameter, m);
      });
      let res = await postDataApi(
        constant.podcastFileUploadURL,//process.env.REACT_APP_NODE_ENV === "development" ? constant.baseURL + "podcastfiles/uploadMultipleFiles" : constant.baseURL + "podcastfiles",
        data,
        customHeaders
      );
      //console.log("Upload Podcast Files: ", res.data);
      if (
        res != undefined &&
        res.status != undefined &&
        res.status === 201 &&
        res.data != undefined &&
        res.data.data != undefined
      ) {
        //console.log("Uploaded Podcast file: ", res.data.data);
        let extra = []
        if (res.data.data.length >= 0) {
          extra = res.data.data
        } else {
          extra = [res.data.data]
        }
        // let coverFileName = "";
        // coverFileName = podcastDetails.podcasturlImage;
        // coverImg != null
        //   ? res.data.data[0].filename
        //   : id !== 0
        //   ? podcastDetails.podcasturlImage
        //   : "";
        //console.log(extra)
        setUploadProgress(25);
        createPodcast(
          extra[0].filename,
          // coverImg != null
          //   ? id !== 0
          //     ? podcastDetails.podcasturlPodcast
          //     : res.data.data[1].filename
          //   : id !== 0
          //   ? podcastDetails.podcasturlPodcast
          //   : 
          // podcastDetails.podcasturlPodcast
          // ,
          // coverImg != null
          // ? id !== 0
          //   ? podcastDetails.podcasturlPodcast
          //   : res.data.data[1].originalname
          // : id !== 0
          //   ? podcastDetails.podcasturlPodcast
          //   :
          //  res.data.data[0].originalname
        );
      } else {
        removeLoader();
        addErrorMesage("Error in uploading podcast files");
      }
      // setUploadProgress(25);
      // createPodcast(
      //   podcastDetails.podcasturlImage,
      //   podcastDetails.podcasturlPodcast
      // );
    // }
  };
  const getPodcastByName = async () => {
    let resPodcast = await getDataApi(
      // constant.baseURL + "podcasts?filter=name||$eq||"+podcastDetails.podcastTitle
      constant.baseURL + "podcasts?filter=name||$eq||" + podcastDetails.podcastTitle + "&filter=isDeleted||$eq||0"
    );
    return resPodcast;
  }
  const createPodcast = async (coverImageName, podcastFileName, originalAudioVideoFileName) => {
    // console.log(duration)
    // console.log(coverImageName, podcastFileName, originalAudioVideoFileName)
    if (currentUserInfo !== undefined) {
      addLoader();
      let fItem = podFiles.filter(f => f.name === originalAudioVideoFileName);
      let jsonData = {
        name: podcastDetails.podcastTitle,
        description: podcastDetails.podcastDescription,
        artist: currentUserInfo.id,
        podcastType: podcastDetails.podcastType,
        urlImage: coverImageName,
        // urlPodcast: podcastFileName,
        // size: fItem.length > 0 ? fItem[0].size : 0,
        // duration: duration
      };
      //console.log(jsonData);
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let respPodcast;
      let sStatus = "";
      if (id !== 0) {
        respPodcast = await patchDataApi(
          constant.baseURL + "podcasts/" + id,
          formBody,
          authHeader
        );
        if (
          respPodcast != undefined &&
          respPodcast.status != undefined &&
          respPodcast.status == 200
        ) {
          sStatus = "Success";
        }
      } else {
        respPodcast = await postDataApi(
          constant.baseURL + "podcasts",
          formBody,
          authHeader
        );
        if (
          respPodcast != undefined &&
          respPodcast.status != undefined &&
          respPodcast.status == 201
        ) {
          sStatus = "Success";
        }
      }
      //console.log("respPodcast: ", respPodcast);
      if (sStatus == "Success") {
        setUploadProgress(75);
        return savePodcastCategories(respPodcast.data.id).then((response) => {
          if (
            response != undefined &&
            response.length > 0 &&
            response[0] == true
          ) {
            removeLoader();
            setUploadProgress(100);
            addSuccessMesage(
              id !== 0 ? "Podcast Updated" : "Podcast Created"
            );
            resetUploadPodcast(0);
          } else {
            removeLoader();
            resetUploadPodcast(0);
          }
        });
      } else {
        removeLoader();
        addErrorMesage("Error encountered while creating podcast!");
        resetUploadPodcast(0);
      }
    }
  };

  const getSubcategories = async () => {
    let resSubcategories0 = await getDataApi(
      constant.baseURL + "subcategories?filter=category.id||$eq||2&filter=isDeleted||$eq||0"
    );
    //console.log("resSubcategories: ", resSubcategories0);
    if (resSubcategories0 != undefined && resSubcategories0.length > 0) {
      setPodCastCategories([...resSubcategories0]);
      setPodcastDetails((prevState) => {
        return {
          ...prevState,
          categoryList: [...resSubcategories0],
        };
      });
      if (id !== 0) {
        getPodcastSubcategories(resSubcategories0);
      }
    }
  };

  const getPodcastSubcategories = async (categories) => {
    let resSubcategories = await getDataApi(
      constant.baseURL +
      "selectedPodcastcategories?filter=podcasts.id||$eq||" +
      id
    );
    //console.log("getPodcastSubcategories: ", resSubcategories);
    if (resSubcategories != undefined && resSubcategories.length > 0) {
      let sItems = [];
      resSubcategories.map((p) => {
        let fltCategories = categories.filter((c) => c.id == p.subcategory.id);
        if (fltCategories[0] !== undefined) {
          sItems.push(fltCategories[0]);
        }
      });
      //console.log("sItems: ", sItems);
      setPodcastDetails((prevState) => {
        return {
          ...prevState,
          selectedCategoryList: [...sItems],
          existingCategoryList: [...resSubcategories],
        };
      });
    }
  };
  const savePodcastCategories = async (podcastId) => {
    let promises_array = [];
    podcastDetails.selectedCategoryList.map((item) => {
      promises_array.push(
        new Promise((resolve, reject) => {
          let jsonData = { podcasts: podcastId, subcategory: item.id };
          return createPodcastCategories(jsonData)
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(false);
            });
        })
      );
    });
    if (id !== 0 && podcastDetails.existingCategoryList.length > 0) {
      podcastDetails.existingCategoryList.map((item) => {
        promises_array.push(
          new Promise((resolve, reject) => {
            let fExists = podcastDetails.selectedCategoryList.filter(
              (x) => x.id == item.subcategory.id
            );
            if (fExists.length == 0) {
              return deletePodcastCategory(item.id)
                .then(() => {
                  resolve(true);
                })
                .catch((error) => {
                  reject(false);
                });
            } else {
              resolve(true);
            }
          })
        );
      });
    }
    return Promise.all(promises_array);
  };
  const createPodcastCategories = async (jsonData) => {
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let resExits = await getDataApi(
      constant.baseURL +
      "selectedpodcastcategories?filter=podcasts.id||$eq||" +
      jsonData.podcasts +
      "&filter=subcategory.id||$eq||" +
      jsonData.subcategory
    );
    //console.log("resExits: ", resExits);
    if (resExits.length == 0) {
      return await Promise.resolve(
        postDataApi(
          constant.baseURL + "selectedpodcastcategories",
          formBody,
          authHeader
        )
      )
        .then((response) => {
          if (
            response != undefined &&
            response.status != undefined &&
            response.status == 201
          ) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          return false;
        });
    } else {
      return false;
    }
  };

  const deletePodcastCategory = async (id) => {
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return await Promise.resolve(
      await deleteDataApi(
        constant.baseURL + "selectedPodcastcategories/" + id,
        authHeader
      )
    )
      .then((resDel) => {
        //console.log("resDel: ", resDel);
        if (resDel != undefined && resDel.status == 200) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
  };
  const cancelPostcast = () => {
    if (id !== 0) {
      history.goBack();
    } else {
      if (!isUploadPodcast && props != undefined) {
        history.goBack();
      }
    }
  };
  useEffect(() => {
    getSubcategories();
  }, []);
  const renderSelectedUploadedPodcast = () => {
    return (
      <>
        {showSelectedPodcastFile === true && (
          <div className="download-music-box">
            <div className="row">
              <div className="col-3 col-md-2">
                {/* <img className="episode-img" src={podcastDetails.podcastType == 'video' ? constant.videoURL : constant.musicURL} alt="Cover-Image"/> */}
                <img
                  className="episode-img"
                  src={
                    podcastDetails.podcasturlImage != ""
                      ? constant.podcastFileURL + podcastDetails.podcasturlImage
                      : podcastDetails.podcastType == "video"
                        ? constant.videoURL
                        : constant.musicURL
                  }
                  alt="Cover-Image"
                />
              </div>
              <div className="col-9 col-md-10">
                <div className="row justify-content-between">
                  <div className="col-auto">
                    <div className="download-title">
                      {podcastDetails.podcasturlPodcast}
                    </div>
                  </div>
                  <div className="col-auto">
                    <img
                      className="close-btn"
                      src={close}
                      alt="Close"
                      onClick={() => {
                        setShowSelectedPodcastFile(false);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
                <div className="row justify-content-around align-items-center">
                  <div className="col-12">
                    <div className="download-size">{(podcastDetails.size / 1024 / 1024).toFixed(1)} MB</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  const renderUploadedPodcast = () => {
    const { podcastType, uploadedPodcast } = podcastDetails;
    let fileName = uploadedPodcast.name.split(".")[0];
    let fileSizeInMB = uploadedPodcast.size / 1024 / 1024;
    //console.log("showSelectedPodcastFile: ", showSelectedPodcastFile);
    return (
      <>
        {/* {
                showSelectedPodcastFile === true &&
                <div className="download-music-box">
                    <div className="row">
                        <div className="col-3 col-md-2">
                            <img className="episode-img" src={podcastDetails.podcastType == 'video' ? constant.videoURL : constant.musicURL} alt="Cover-Image"/>
                        </div>
                        <div className="col-9 col-md-10">
                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <div className="download-title">{podcastDetails.podcasturlPodcast}</div>
                                </div>
                                <div className="col-auto">
                                    <img className="close-btn" src={close} alt="Close" onClick={() => {setShowSelectedPodcastFile(false)}} style={{cursor:'pointer'}}/>
                                </div>
                            </div>
                            <div className="row justify-content-around align-items-center">
                                <div className="col-12">
                                    <div className="download-size"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            } */}
        {showSelectedPodcastFile === false && (
          <div className="download-music-box">
            <div className="row">
              <div className="col-3 col-md-2">
                <img
                  className="episode-img"
                  src={
                    coverImg === null
                      ? podcastType === "music"
                        ? musicImg
                        : videoImg
                      : coverImg
                  }
                  alt="Cover-Image"
                />
              </div>
              <div className="col-9 col-md-10">
                <div className="row justify-content-between">
                  <div className="col-auto">
                    <div className="download-title">{fileName}</div>
                  </div>
                  <div className="col-auto">
                    <img
                      className="close-btn"
                      src={close}
                      alt="Close"
                      onClick={() => onDeleteUploadedPodcast()}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
                <div className="row justify-content-around align-items-center">
                  <div className="col-12">
                    <div className="download-size">
                      {fileSizeInMB.toFixed(1)}MB
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  return (
    <div>
      <section className="community-room-banner mypodcast-coustom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="upload-music-background">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    {coverImg === null ? (
                      <FileDrop
                        onDragOver={() =>
                          setDragNDropText("Drop your image here!!")
                        }
                        onDragLeave={() =>
                          setDragNDropText("Drop your file here")
                        }
                        onDrop={onDrop}
                      >
                        <div className="upload-background">
                          <img
                            src={
                              podcastDetails.podcasturlImage != ""
                                ? constant.podcastFileURL +
                                podcastDetails.podcasturlImage
                                : uploadImage1
                            }
                            alt="upload picture"
                            style={{ width: 70, height: 70 }}
                          />
                          <div className="upload-text">Upload Cover Image</div>
                          <div className="upload-text-subtitle">
                            Drop your file here or browse
                            <ImageUploader
                              className="imageUploaderNew"
                              withIcon={false}
                              buttonText="Browse"
                              label=""
                              onChange={(file) => onUploadImg(file)}
                              imgExtension={constant.supportedImageFiles}
                              singleImage={true}
                            />
                          </div>
                        </div>
                      </FileDrop>
                    ) : (
                      <FileDrop
                        onDragOver={() =>
                          setDragNDropText("Drop your image here!!")
                        }
                        onDragLeave={() =>
                          setDragNDropText("Drop your file here")
                        }
                        onDrop={onDrop}
                      >
                        <div className="upload-background">
                          <img
                            className="upload-image-icon"
                            style={{ height: 70, width: 70 }}
                            onClick={() => setCoverImg(null)}
                            id="uploaded_cover_img"
                            src={coverImg}
                            alt="cover-image"
                          />
                          <ImageUploader
                            className="imageUploaderNew"
                            withIcon={false}
                            buttonText="Browse"
                            label=""
                            onChange={(file) => onUploadImg(file)}
                            imgExtension={constant.supportedImageFiles}
                            singleImage={true}
                          />
                        </div>
                      </FileDrop>
                    )}
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="uppload-music-right-box">
                      <div className="row featured-artists-title">
                        <div className="col-10">Podcast Details</div>
                        {/* {isUploadPodcast ? (
                          <div className="col-2 progress_bar">
                            <CircularProgressbar
                              value={uploadProgress}
                              maxValue={100}
                              minValue={0}
                              text={`${uploadProgress}%`}
                            />
                          </div>
                        ) : null} */}
                      </div>
                      <div className="podcast-left-box">
                        <div className="mypodcast-middle">
                          <div className="floating-label mt-4">
                            <input
                              className="floating-input"
                              type="text"
                              maxLength={255}
                              placeholder=" "
                              onChange={(e) => {
                                setPodcastDetails({
                                  ...podcastDetails,
                                  podcastTitle: e.target.value,
                                });
                                setPodcastTitleErr(false);
                              }}
                              value={podcastDetails.podcastTitle}
                            />
                            <span className="highlight"></span>
                            <label className="label">Title</label>
                            {isPodcastTitleErrShow === true && (
                              <div
                                className="errorText"
                                style={{ marginTop: "0px" }}
                              >
                                Enter title
                              </div>
                            )}
                          </div>
                          <div className="floating-label mt-4">
                            <textarea
                              className="floating-input"
                              rows="4"
                              type="text"
                              placeholder=" "
                              onChange={(e) => {
                                setPodcastDetails({
                                  ...podcastDetails,
                                  podcastDescription: e.target.value,
                                });
                                setPodcastDescErr(false);
                              }}
                              value={podcastDetails.podcastDescription}
                            ></textarea>
                            <span className="highlight"></span>
                            <label className="label">Description</label>
                            {isPodcastDescErrShow === true && (
                              <div
                                className="errorText"
                                style={{ marginTop: "0px" }}
                              >
                                Enter description
                              </div>
                            )}
                          </div>
                          <div className="floating-label0 mt-4">
                            <Multiselect
                              options={podcastDetails.categoryList}
                              selectedValues={
                                podcastDetails.selectedCategoryList
                              }
                              onSelect={onSelectCategory}
                              onRemove={onRemoveSelectCategory}
                              displayValue="name"
                              placeholder="Add Category"
                              style={multiSelectStyle}
                              // showCheckbox={true}
                              // closeIcon="circle"
                              customCloseIcon={
                                <AiFillCloseSquare className="multiselect-close-icon" />
                              }
                            />
                            {isAddCategoryErrShow && (
                              <div
                                className="errorText"
                                style={{ marginTop: "0px" }}
                              >
                                Add category
                              </div>
                            )}
                          </div>
                          <div className="login-sub-title mt-4">
                            Select Type
                          </div>
                          <div className="row mt-4">
                            <div className="col-12 col-md-6">
                              <div className="selection-page">
                                Video
                                <span>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="inlineRadioOptions"
                                      id="inlineRadio1"
                                      disabled={
                                        id !== undefined && id !== 0
                                          ? podcastDetails.podcastType !==
                                          "video" && true
                                          : false
                                      }
                                      checked={
                                        podcastDetails.podcastType === "video"
                                      }
                                      onChange={() => {
                                        if (
                                          podcastDetails.uploadedPodcast ===
                                          null
                                        ) {
                                          setPodcastDetails((prevState) => {
                                            return {
                                              ...prevState,
                                              podcastType: "video",
                                            };
                                          });
                                        }
                                      }}
                                      value="video"
                                    />
                                    <label
                                      className="form-check-label"
                                      for="inlineRadio1"
                                    ></label>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 mt">
                              <div className="selection-page">
                                Music
                                <span>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="inlineRadioOptions"
                                      id="inlineRadio1"
                                      disabled={
                                        id !== undefined && id !== 0
                                          ? podcastDetails.podcastType !==
                                          "music" && true
                                          : false
                                      }
                                      checked={
                                        podcastDetails.podcastType === "music"
                                      }
                                      onChange={() => {
                                        if (
                                          podcastDetails.uploadedPodcast ===
                                          null
                                        ) {
                                          setPodcastDetails((prevState) => {
                                            return {
                                              ...prevState,
                                              podcastType: "music",
                                            };
                                          });
                                        }
                                      }}
                                      value="music"
                                    />
                                    <label
                                      className="form-check-label"
                                      for="inlineRadio1"
                                    ></label>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col-12">
                              {/* {podcastDetails.uploadedPodcast !== null &&
                                renderUploadedPodcast()}
                              {podcastDetails.uploadedPodcast == null &&
                                renderSelectedUploadedPodcast()} */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-6">
                          <button
                            className="cancel-btn"
                            aria-label="Close"
                            onClick={cancelPostcast}
                          >
                            <span aria-hidden="true">Cancel</span>
                          </button>
                        </div>
                        <div className="col-6">
                          <button
                            className="add-to-playlist-btn"
                            style={{ width: "100%" }}
                            onClick={onUpload}
                          >
                            {id !== undefined && id !== 0 ? "Update" : "Create"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default UploadPodcast;
