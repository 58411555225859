import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Button,} from "react-bootstrap";
import { propTypes } from "react-bootstrap/esm/Image";
import ReactPlayer from 'react-player';

const VideoModal = ({url, show, handleClose, title,
    height,
    width,
    // getAudioInstance,
}) => {
    const onEnablePIP = () => {
        //handleClose();
    }
    return (
        <div>
            <Modal show={show} 
            onHide={handleClose} 
            backdrop={"static"}
            keyboard={false}
            dialogClassName="videoModalDialog" 
            aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header closeButton style={{backgroundColor: '#313131', border:0,}}>
                <Modal.Title className="wrapText">
                    {title} 
                </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{backgroundColor: '#313131'}}>
                    <ReactPlayer
                        className="reactPlayer"
                        width='100%'
                        controls={true}
                        playing={true}
                        // getAudioInstance={getAudioInstance}
                        //onEnablePIP={onEnablePIP}
                        //pip={false}
                        // playsinline={true}
                        url={url}
                        config={{ file: { attributes: { disablepictureinpicture: 'true' }}}}
                        >
                    </ReactPlayer>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default VideoModal;