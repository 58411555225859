import React, { useState, useEffect } from "react";
import { constant } from "../../utility/constant";
import { getDataApi, postDataApi, deleteDataApi, getDataApiWithBlob, patchDataApi } from "../../utility/apiCall";
import { colors } from "../../utility/colors";
import moment from "moment";
import { BsFillEyeFill, BsFillChatDotsFill } from "react-icons/bs";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { FaShareAlt } from "react-icons/fa";
import Comments from "../../components/Comments/Comments";
import mikeFill from "../../assets/Images/mikeFill.svg";
import { FaRegCommentDots } from "react-icons/fa";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { FiMoreVertical } from "react-icons/fi";
import CommentsModal from "../../components/CommentsModal/CommentsModal";
import ShareModal from "../../components/ShareModal/ShareModal";
import { BsPlayFill, BsPauseFill } from "react-icons/bs";
import { MdReport } from "react-icons/md";
import VideoModal from "../../components/VideoModal/VideoModal";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";
import HomePodcastsList from "../../components/HomePodcastsList/HomePodcastsList";
import { VerifyLoginExistOrNot } from "../../utility/VerifyLoginExistOrNot";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { hashCodeUrlDecode } from "../../utility/EncodeAndDecodeUrl";
import { addErrorMesage, addSuccessMesage } from "../../components/CustomToster/CustomToaster";
import blackmike from "../../assets/Images/blackmike.svg";
import userimg from "../../assets/Images/avatar.png";
import blackmikefill from "../../assets/Images/blackmikefill.svg";
import mike from "../../assets/Images/mike.svg";
import facebookIcon from "../../assets/Images/facebook.svg";
import twitterIcon from "../../assets/Images/twitterIcon.svg";
import youtube from "../../assets/Images/youtube.svg";
import ReactPlayer from 'react-player';
import itune from "../../assets/Images/itune.svg";
import spotify from "../../assets/Images/shopify.svg";
import tiktok from "../../assets/Images/titktok.svg";
import apple from "../../assets/Images/applemusic.svg";
import episodeimg from "../../assets/Images/episode-img.png";

import instagramIcon from "../../assets/Images/instagram.svg";
import podcaste from "../../assets/Images/podcaste.svg";
import { RiUserFollowLine, RiUserUnfollowLine } from "react-icons/ri";
import Musics from "../../components/Musics/Musics";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal/ConfirmDeleteModal";
import { BiPause, BiPlay } from "react-icons/bi";
import { addLoader, DisableButtonLoader, removeLoader } from "../../utility/CustomLoader";
function PodcastDetails(props) {
  const [showViewMore, setShowViewMore] = useState(false);
  const [videostate, setvideostate] = useState(true);
  const [buttonfollowDisable, setbuttonfollowDisable] = useState(false);
  const [buttonDisable, setbuttonDisable] = useState(false);
  const [videovariable, setvideovariable] = useState(true);
  const [idf, setidf] = useState();

  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  let searchData = useParams();
  //console.log(searchData, "Search Data");
  //console.log(props);
  // const { idf } = hashCodeUrlDecode(searchData.itemId);
  //// const { id, item } = props.location.state;
  const [showShareToModal, setShowShareToModal] = useState(false);
  const [selectedMusicItem, setSelectedMusicItem] = useState(undefined);
  // satyam

  const [playOrPauseShow, setplayOrPauseShow] = useState(false);
  const [audioInstance, setAudioInstance] = useState(null);
  const [playOrPauseMusic, setplayOrPauseMusic] = useState(false);
  const [playOrPauseMusicindex, setplayOrPauseMusicindex] = useState(null);
  // satyam ENd
  const [artistLikes, setArtistLikes] = useState();
  const [artistFollow, setArtistFollow] = useState();
  const [commentsList, setCommentsList] = useState([]);
  const [commentInputVal, setCommentInputVal] = useState("");
  const [errorCommentMessage, setCommentError] = useState(false);
  const [commentsPage, setCommentsPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const [playIndex, setPlayIndex] = useState(0);
  const [podcastLikes, setPodcastLikes] = useState(undefined);
  const [likeList, setLikesList] = useState([]);
  const [showReportPodcastModal, setShowReportPodcastModal] = useState(false);
  const [reportPodcastInputText, setreportPodcastInputText] = useState("");
  const [playVideo, setPlayVideo] = useState(false);
  const [currentPlayMusic, setCurrentPlayMusic] = useState(false);
  const [currentALbumDetails, setcurrentALbumDetails] = useState(false);
  const [selectedAlbumMusics, setSelectedAlbumMusics] = useState([]);
  const [alldata, setalldata] = useState({});
  const [artist, setartist] = useState({});
  const [page, setpage] = useState(1)
  const [episode, setepisode] = useState([])
  const [ismore, setismore] = useState(false)
  const [deleteModal, setdeleteModal] = useState(false);
  const [commentindex, setcommentindex] = useState(null);
  const [showCommentSongModal, setShowCommentSongModal] = useState(false);
  const [commentSongInputText, setCommentSongInputText] = useState("");
  const [barHide, setbarHide] = useState(false);
  const [showReportSongModal, setShowReportSongModal] = useState(false);
  const [reportSongInputText, setReportSongInputText] = useState("");
  const [disableItem, setDisableItem] = useState(false);
  const [mId, setMId] = useState(0);
  //console.log(episode)
  var [countData, setcountData] = useState(0);
  // const [changePodcastById, setchangePodcastById] = useState("");
  const [podcastItem, setPodcastItem] = useState({
    id: 0,
    name: "",
    description: "",
    img: "",
    liked: false,
    musicURL: "",
    podcastType: "",
    musicSrc: [],
    categoryList: [],
  });
  const funsetcurrentPlaymusic = () => {
    setCurrentPlayMusic(false)
  }
  const updatecomment = () => {
    //console.log(commentindex)
    if (episode[commentindex].extra1) {
      episode[commentindex].extra1 = episode[commentindex].extra1 + 1
    } else {
      episode[commentindex].extra1 = episode[commentindex].comments.length + 1
    }

  }
  const navigateToExternalLink = (url) => {
    //console.log("url: ", url);
    if (url.indexOf("http://") !== -1 || url.indexOf("https://") !== -1) {
      window.open(url, "_blank");
      return null;
    } else {
      addErrorMesage("URL should start with http or https");
      return;
    }
  };
  var path = useLocation();
  var history = useHistory();
  const onviewmore = () => {
    setpage(page + 1)
    callepisode(page + 1)
  }
  const callepisode = async (page,i) => {
    let apiURL = constant.baseURL + "episode/getEpisodesByPodcastsWithUserLike?page=" + page + "&limit=5&podcastsId=" + i;
    if (currentUserInfo) {
      apiURL += "&userId=" + currentUserInfo.id
    }
    let resPodcast = await getDataApi(apiURL);
    //console.log(resPodcast)
    await resPodcast.data.map((itm) => {
      itm.musicSrc = itm.urlImage !== undefined
        ? constant.podcastepisodeFileURL + itm.urlImage
        : ""
      itm.time = itm.duration
      itm.name = itm.title
      itm.singer =
        itm.artist
      itm.user =
        itm.podcasts.artist
      itm.cover = constant.podcastepisodeFileURL + itm.thumbnailUrlImage
    });
    //console.log(resPodcast)
    if (page == 1) {
      setepisode(resPodcast.data)
    } else {
      setepisode([...episode, ...resPodcast.data])
    }
    if (page == resPodcast.pageCount) {
      setismore(false)

    } else {
      setismore(true)

    }

  }
  const onconfirmDelete = async () => {
    let apiURL = "";
    // let selectedItem = episode[deleteItemIndex];
    // console.log("selectedItem", selectedItem);
    let jsonData = {
      isDeleted: 1,
    };
    //console.log("jsonData", jsonData);
    setdeleteModal(false);

    apiURL = constant.deleteEpisode + "/" + selectedMusicItem.id;

    //console.log(apiURL);
    if (apiURL !== "") {
      addLoader();
      let deleteResponse = await patchDataApi(apiURL, jsonData);

      // console.log("deleteResponse", deleteResponse);
      // console.log(
      //   deleteResponse.status === 200 || deleteResponse.statusText === "OK"
      // );
      if (
        deleteResponse.status === 200 ||
        deleteResponse.statusText === "OK"
      ) {
        removeLoader();
        addSuccessMesage(`Episode Deleted`);
        callepisode(1);
      } else {
        removeLoader();
        addErrorMesage(`Episode not Deleted`);
      }
    }

  };
  const onDelete = (index) => {
    //console.log("Delete");
    setdeleteModal(true);
    setSelectedMusicItem(index);
  };
  const downloadMusic = async (item) => {
    //console.log(item);
    if (item.urlImage !== "") {
      let respFile = await getDataApiWithBlob(
        constant.podcastepisodeFileURL + item.urlImage
      );
      // console.log(respFile);
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(
        new Blob([respFile], { type: "application/octet-stream" })
      );
      link.download = item.urlImage;
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(link);
        createMusicDownload(item.id);
      }, 200);
    }
  };
  const createMusicDownload = async (musicId) => {
    let jsonData = { user: currentUserInfo.id, episode: musicId };
    var formBody = [];
    for (var property in jsonData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(jsonData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    var authHeader = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let respMusicDownload = await postDataApi(
      constant.baseURL + "episodedownloads",
      formBody,
      authHeader
    );
    // console.log("respMusicDownload: ", respMusicDownload);
    if (
      respMusicDownload !== undefined &&
      respMusicDownload.status !== undefined &&
      respMusicDownload.status === 201
    ) {
      // console.log("Music download recorded..");
    }
  };
  const playMusic = (index) => {


    let item = episode[index];
    //console.log(item)
    if (item.episodeType === "music") {
      setTimeout(() => {
        setShowAudioPlayer(true)
        setPlayIndex(index);
        setplayOrPauseMusic(true);
        setplayOrPauseShow(true);
        setplayOrPauseMusicindex(index);

      }, 10);
    } else if (item.episodeType === "video") {

      history.push({ pathname: "/VideoPlayer", state: { variant: "podcast1", url: item.musicSrc, list: episode, details: item } })
      // show={showAudioPlayer}
      //     url={selectedSong.musicSrc}
      //     title={selectedSong.name}
      //     height={790}
      //     width={790}
    }
  };

  const createEpisodeLike = async (episodeId) => {
    if (currentUserInfo.id) {
      setDisableItem(true);
      setMId(episodeId);
      let jsonData = { user: currentUserInfo.id, episode: episodeId };
      let respEpisodeLike = await postDataApi(
        constant.baseURL + 'episodelikes',
        jsonData,
        false,
      );
      //console.log("----create like----", respEpisodeLike);
      if (
        respEpisodeLike.data !== undefined
      ) {
        setLikes(episodeId, respEpisodeLike.data, undefined);
        setDisableItem(false);
      }
      setDisableItem(false);
    }
    else {
      history.push({
        pathname: `/login`,
        state: {
          prevPath: path.pathname,
          userId: props.currentUserId,
        },
      });
      setDisableItem(false);
    }
  };

  const resetEpisodeLike = async (episodeId, likeId) => {
    //console.log(episodeId, likeId)
    if (currentUserInfo.id) {
      setDisableItem(true);
      setMId(episodeId);
      let resp = await deleteDataApi(constant.baseURL + 'episodelikes/' + likeId);
      if (
        resp !== undefined &&
        resp.status !== undefined &&
        resp.status === 200
      ) {
        setLikes(episodeId, undefined, likeId);
        setDisableItem(false);
      }
    } else {
      history.push({
        pathname: `/login`,
        state: {
          prevPath: path.pathname,
          userId: props.currentUserId,
        },
      });
      setDisableItem(false);
    }
  };
  const setLikes = (id, data, likeId) => {
    let bgItems = [...episode];
    if (data !== undefined) {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike.push(data);
          itm.likes.push(data);
        }
      });
    } else {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike = [];
          itm.likes.map((lkitm, index0) => {
            if (lkitm.id === likeId) {
              itm.likes.splice(index0, 1);
            }
          });
        }
      });
    }
    setepisode(bgItems);
  };
  const getPodcastDeatilsById = async (podcastId) => {
    //console.log(podcastId);
    // try {
    let resPodcast = await getDataApi(
      constant.baseURL + "podcasts/" + podcastId
    );
    await callepisode(page,podcastId)
    //console.log("resPodcast: ", resPodcast);
    if (resPodcast != undefined) {
      setalldata(resPodcast)
      setartist(resPodcast.artist)
      getLikesByArtist(resPodcast?.artist?.id);
      getFollowByArtist(resPodcast?.artist?.id);
      let dfltURL =
        resPodcast.podcastType == "video"
          ? constant.videoURL
          : constant.musicURL;
      let itmMusic = [];
      itmMusic.push({
        musicSrc: constant.podcastFileURL + resPodcast.urlPodcast,
        cover:
          resPodcast.urlImage != undefined &&
            resPodcast.urlImage != null &&
            resPodcast.urlImage != ""
            ? constant.podcastFileURL + resPodcast.urlImage
            : dfltURL,
        name:
          resPodcast.name !== undefined &&
            resPodcast.name !== null &&
            resPodcast.name !== ""
            ? resPodcast.name
            : "",
        singer:
          resPodcast.artist !== undefined &&
          resPodcast.artist.firstName + " " + resPodcast.artist !== undefined &&
          resPodcast.artist.lastName,
      });
      // let musicsData = [];
      // resPodcast.episode.map((item) => {
      //     musicsData.push({
      //       id: item.id,
      //       title: item.title,
      //       // name: item.musics_title,
      //       time: item.duration,
      //       size:
      //         item.size !== undefined && item.size !== null
      //           ? item.size
      //           : 0,
      //       singer:
      //       resPodcast.artist.firstName +
      //         " " +
      //         resPodcast.artist.lastName,
      //       // cover: constant.musicURL,
      //       // albumname: itm.album_name,
      //       albumId: resPodcast.id,
      //       urlImage:
      //         item.thumbnailUrlImage !== undefined ? item.thumbnailUrlImage : "",
      //       musicSrc:
      //         item.urlImage !== undefined
      //           ? constant.musicFileURL + item.urlImage
      //           : "",
      //       // likes: item.likes,
      //       // comments: item.comments,
      //       user: resPodcast.artist.firstName +
      //       " " +
      //       resPodcast.artist.lastName,
      //     });
      //   });
      //   setSelectedAlbumMusics([...musicsData]);
      setPodcastItem((prevState) => {
        return {
          ...prevState,
          id: resPodcast.id,
          name:
            resPodcast.name !== undefined &&
              resPodcast.name !== null &&
              resPodcast.name !== ""
              ? resPodcast.name
              : "",
          description:
            resPodcast.description !== undefined &&
              resPodcast.description !== null &&
              resPodcast.description !== ""
              ? resPodcast.description
              : "",
          img:
            resPodcast.urlImage != undefined &&
              resPodcast.urlImage != null &&
              resPodcast.urlImage != ""
              ? constant.podcastFileURL + resPodcast.urlImage
              : dfltURL,
          musicURL: resPodcast.urlPodcast,
          podcastType: resPodcast.podcastType,
          musicSrc: itmMusic,
        };
      });
      window.scrollTo(0, 0);
    }
    // } catch (error) {
    //   console.log(error);
    // }
  };
  const getPodcastSubcategories = async (podcastId) => {
    let resSubcategories = await getDataApi(
      constant.baseURL +
      "selectedPodcastcategories?filter=podcasts.id||$eq||" +
      podcastId
    );
    //console.log("getPodcastSubcategories: ", resSubcategories);
    if (resSubcategories != undefined && resSubcategories.length > 0) {
      setPodcastItem((prevState) => {
        return {
          ...prevState,
          categoryList: [...resSubcategories],
        };
      });
    }
  };
  const getPodcastComments = async (commentItemId, pageNumber) => {
    //console.log("commentItemId", commentItemId, "pageNumber", pageNumber);
    let resPodcastComments = await getDataApi(
      constant.baseURL +
      "podcastcomments?filter=podcasts.id||$eq||" +
      // props.location.state.id +
      commentItemId +
      "&page=" +
      pageNumber +
      "&limit=" +
      limit +
      "&sort=id,DESC"
    );
    //console.log("resPodcastComments: ", resPodcastComments);
    if (
      resPodcastComments !== undefined &&
      resPodcastComments.data !== undefined &&
      resPodcastComments.data.length > 0
    ) {
      let itms = [];
      resPodcastComments.data.map((c) => {
        itms.push({
          id: c.id,
          img:
            c.user.urlImage != undefined &&
              c.user.urlImage != null &&
              c.user.urlImage != "" &&
              c.user.urlImage != "user.png"
              ? constant.profileURL + c.user.urlImage
              : c.user.usertype == "Artist" ? constant.artistImageURL : constant.userImageURL,
          name: c.user.firstName + " " + c.user.lastName,
          location: c.user.address,
          comment: c.comment,
          date: moment(c.created).format("DD MMM yyyy"),
        });
      });
      if (pageNumber == 1) {
        setCommentsList(itms);
      } else {
        setCommentsList([...commentsList, ...itms]);
      }
      //show hide view more page
      if (resPodcastComments.page < resPodcastComments.pageCount) {
        setShowViewMore(true);
      } else {
        setShowViewMore(false);
      }
    }else{
      if(pageNumber==1){
        let itms = [];
        setCommentsList(itms);
      }
    }
  };
  const setComments = (data) => {
    setCommentsList([
      {
        id: data.id,
        img:
          data.user.urlImage != undefined &&
            data.user.urlImage != null &&
            data.user.urlImage != "" &&
            data.user.urlImage != "user.png"
            ? constant.profileURL + data.user.urlImage
            : data.user.usertype == "Artist" ? constant.artistImageURL : constant.userImageURL,
        name: data.user.firstName + " " + data.user.lastName,
        location: data.user.address,
        comment: data.comment,
        date: moment(data.created).format("DD MMM yyyy"),
      },
      ...commentsList,
    ]);
    setCommentInputVal("");
  };
  const viewMoreComments = () => {
    setCommentsPage(commentsPage + 1);
    getPodcastComments(hashCodeUrlDecode(searchData.itemId), commentsPage + 1);
  };
  const displayReportArtistModal = () => {
    if (VerifyLoginExistOrNot()) setShowReportPodcastModal(true);
    else {
      history.push({
        pathname: `/login`,
        state: {
          prevPath: path.pathname,
          userId: podcastItem.id,
        },
      });
    }
  };
  const hideReportArtistModal = () => {
    setShowReportPodcastModal(false);
  };
  const getPodcastLikes = async (podcastId) => {
    if (currentUserInfo !== undefined) {
      let resPodcastLikes = await getDataApi(
        constant.baseURL +
        "podcastlikes?filter=user.id||$eq||" +
        currentUserInfo.id +
        "&filter=podcasts.id||$eq||" +
        podcastId
        // props.location.state.id
        // hashCodeUrlDecode(searchData.itemId)
      );
      if (resPodcastLikes != undefined && resPodcastLikes.length > 0) {
        setPodcastLikes(resPodcastLikes[0]);
        setPodcastItem((prevState) => {
          return { ...prevState, liked: true };
        });
      }
    }
  };
  const createPodcastLike = async () => {
    if (VerifyLoginExistOrNot()) {
      //console.log("Disable Like");
      if (currentUserInfo != undefined) {
        setbuttonDisable(!buttonDisable);
        let jsonData = { user: currentUserInfo.id, podcasts: podcastItem.id };
        var formBody = [];
        for (var property in jsonData) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(jsonData[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        var authHeader = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        };
        let respPodcastLike = await postDataApi(
          constant.baseURL + "podcastlikes",
          formBody,
          authHeader
        );
        if (
          respPodcastLike != undefined &&
          respPodcastLike.status != undefined &&
          respPodcastLike.status == 201
        ) {
          setbuttonDisable(false);
          setPodcastLikes(respPodcastLike.data);
          setPodcastItem((prevState) => {
            return { ...prevState, liked: true };
          });
        }
      }
    } else {
      history.push({
        pathname: `/login`,
        state: {
          prevPath: path.pathname,
          userId: podcastItem.id,
        },
      });
    }
  };
  const addepisodepage = () => {
    history.push({
      pathname: `/Addepisode`,
      state: {
        id: 0,
        type: podcastItem.podcastType,
        podcastId: podcastItem.id
      },
    });
  }
  const editepisodepage = (id, item) => {
    history.push({
      pathname: `/Addepisode`,
      state: {
        id: id,
        item: item,
        type: podcastItem.podcastType,
        podcastId: podcastItem.id
      },
    });
  }
  const resetPodcastLike = async () => {
    if (podcastLikes != undefined) {
      //console.log("Disable UnLike");
      setbuttonDisable(!buttonDisable);
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resp = await deleteDataApi(
        constant.baseURL + "podcastlikes/" + podcastLikes.id,
        authHeader
      );
      if (resp != undefined && resp.status != undefined && resp.status == 200) {
        setbuttonDisable(false);
        setPodcastLikes(undefined);
        setPodcastItem((prevState) => {
          return { ...prevState, liked: false };
        });
      }
    }
  };
  const onPlay = (e) => {
    setvideostate(false)
  }
  const onPause = (e) => {
    setvideostate(true)
  }
  // satyam Start
  const getAudioInstance = (instance) => {
    //console.log("Getting audio instance", instance);
    setAudioInstance(instance);
  };

  const onAudioPlay = () => {
    audioInstance.play();
    setplayOrPauseShow(true);
    setbarHide(false);

  };
  const onAudioPause = () => {
    audioInstance.pause();
    setplayOrPauseShow(false);
    setbarHide(true);

  };
  // satyam End
  // const playPodcastVideo = (i) => {
  //   if (podcastItem !== undefined && podcastItem.musicSrc.length > 0) {
  //     if (podcastItem.podcastType === "video") {
  //       if (i == "play") {
  //         setvideovariable(true)
  //       } else {
  //         setvideovariable(false)
  //       }
  //       setPlayVideo(true);
  //       setvideostate(!videostate)
  //       setShowAudioPlayer(false);
  //     } else {
  //       console.log("hello");
  //       setplayOrPauseMusicindex(0);
  //       setplayOrPauseShow(true);
  //       setplayOrPauseMusic(true);
  //       setPlayVideo(false);
  //       setShowAudioPlayer(true);
  //     }
  //   } else {
  //     addErrorMesage("No music or video found to play");
  //   }
  // };
  const closeVideoModal = () => {
    setPlayVideo(false);
  };
  const resetShowAudioPlayer = () => {
    setShowAudioPlayer(false);
  };
  const podcastDetails = async(data) => {
    await getPodcastDeatilsById(data.id);
    await getPodcastComments(data.id, 1);
    await getPodcastSubcategories(data.id);
    await getPodcastLikes(data.id);
    await setidf(data.id)
  };
  const renderCategories = () => {
    //console.log("podcast.categories: ", podcastItem.categoryList);
    if (podcastItem.categoryList.length > 0) {
      return podcastItem.categoryList.map((item, index) => {
        return (
          <div key={index} className="tags-btn mr-3 ">
            {item.subcategory.name}
          </div>
        );
      });
    }
  };
  const formatTime = (second) => {
    var i = 0;
    var h = 0;
    var s = parseInt(second);
    if (s >= 60) {
      i = parseInt(s / 60);
      s = parseInt(s % 60);

      if (i >= 60) {
        h = parseInt(i / 60);
        i = parseInt(i % 60);
      }
    }
    if (h > 0) return [zero(h), zero(i), zero(s)].join(":");
    return [zero(i), zero(s)].join(":");
    //console.log("formatTime- ",[zero(i), zero(s)].join(':'));
  };
  const zero = (v) => {
    return v >> 0 < 10 ? "0".concat(v) : v;
  };
  const getLikesByArtist = async (id) => {
    //console.log(id)
    //console.log("getLikesByArtist - currentUserInfo: ", currentUserInfo);
    if (currentUserInfo !== undefined) {
      let resArtistLikes = await getDataApi(
        constant.baseURL +
        "likes?filter=user.id||$eq||" +
        currentUserInfo.id +
        "&filter=artist.id||$eq||" +
        id
      );
      //console.log("getLikesByArtist - resArtistLikes: ", resArtistLikes);
      if (resArtistLikes !== undefined && resArtistLikes.length > 0) {
        setArtistLikes(resArtistLikes[0]);
        
      } 
    }
  };
  const getFollowByArtist = async (id) => {
    if (currentUserInfo !== undefined) {
      let resArtistFollow = await getDataApi(
        constant.baseURL +
        "follows?filter=user.id||$eq||" +
        currentUserInfo.id +
        "&filter=follower.id||$eq||" +
        id
      );
      //console.log("resArtistFollow: ", resArtistFollow);
      if (resArtistFollow !== undefined && resArtistFollow.length > 0) {
        setArtistFollow(resArtistFollow[0]);
        
      }
    }
  };
  const createArtistLike = async () => {
    if (VerifyLoginExistOrNot()) {
      if (currentUserInfo !== undefined) {
        setbuttonDisable(true);
        let jsonData = { user: currentUserInfo.id, artist: artist?.id };
        var formBody = [];
        for (var property in jsonData) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(jsonData[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        var authHeader = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        };
        let respArtistLike = await postDataApi(
          constant.baseURL + "likes",
          formBody,
          authHeader
        );
        if (
          respArtistLike !== undefined &&
          respArtistLike.status !== undefined &&
          respArtistLike.status === 201
        ) {
          setbuttonDisable(false);
          setArtistLikes(respArtistLike.data);
          // setArtistData((prevState) => {
          //   return {
          //     ...prevState,
          //     artistLiked: true,
          //   };
          // });
        }
      }
    } else {
      history.push({
        pathname: `/login`,
        state: {
          prevPath: path.pathname,
        },
      });
    }
  };
  const resetArtistLike = async () => {
    if (VerifyLoginExistOrNot()) {
      if (artistLikes !== undefined) {
        setbuttonDisable(true);
        var authHeader = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        };
        let resp = await deleteDataApi(
          constant.baseURL + "likes/" + artistLikes.id,
          authHeader
        );
        if (
          resp !== undefined &&
          resp.status !== undefined &&
          resp.status === 200
        ) {
          setbuttonDisable(false);
          setArtistLikes();
         
        }
      }
    } else {
    }
  };
  const createFollow = async () => {
    if (VerifyLoginExistOrNot()) {
      if (currentUserInfo !== undefined) {
        setbuttonfollowDisable(true);
        let jsonData = { user: currentUserInfo.id, follower: artist?.id };
        var formBody = [];
        for (var property in jsonData) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(jsonData[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        var authHeader = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        };
        //console.log(jsonData);
        let respFollow = await postDataApi(
          constant.baseURL + "follows",
          formBody,
          authHeader
        );
        //console.log("respFollow: ", respFollow);
        if (
          respFollow !== undefined &&
          respFollow.status !== undefined &&
          respFollow.status == 201
        ) {
          setbuttonfollowDisable(false);
          setArtistFollow(respFollow.data);
          // setArtistData((prevState) => {
          //   return {
          //     ...prevState,
          //     followed: true,
          //   };
          // });
        } 
      }
    } else {
      history.push({
        pathname: `/login`,
        state: {
          prevPath: path.pathname,
          userId: artist?.id,
        },
      });
    }
  };
  // satyam End
  const resetFollow = async () => {
    if (artistFollow !== undefined) {
      setbuttonfollowDisable(true);
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resp = await deleteDataApi(
        constant.baseURL + "follows/" + artistFollow.id,
        authHeader
      );
      if (
        resp !== undefined &&
        resp.status !== undefined &&
        resp.status === 200
      ) {
        setbuttonfollowDisable(false);
        setArtistFollow();
       
      }
    }
  };
  useEffect(() => {
    //console.log("ddfsdfsdf",hashCodeUrlDecode(searchData.itemId))
    setidf(hashCodeUrlDecode(searchData.itemId))
    setTimeout(() => {
      getPodcastDeatilsById(
        hashCodeUrlDecode(searchData.itemId) //props.history.location.state.id
      );
      
      getPodcastLikes(hashCodeUrlDecode(searchData.itemId));
      getPodcastComments(hashCodeUrlDecode(searchData.itemId), commentsPage);
      getPodcastSubcategories(hashCodeUrlDecode(searchData.itemId));
    }, 50);
  }, []);
  return (
    <div>
      <section
        className="podcast-banner-2"
        style={{
          backgroundImage: `url(${podcastItem !== undefined ? encodeURI(podcastItem.img) : ""
            })`,
        }}
      >
        <div className="container">
          <div className="">
            <div className="row align-items-center ">
              <div className="col-12 col-lg-8">
                <div
                  className=" wrapText fs-40 fw-700 wrapText"
                  title={podcastItem.name}
                >
                  {String(podcastItem.name)}
                </div>
                <p
                  className="fs-15 fw-500 lh-20 wrapText"
                  title={podcastItem.description}
                >
                  {String(podcastItem.description.trim())}
                </p>
                {/* <div className="form-row">
                  <div className="col-auto">

                    {showAudioPlayer === false ?
                      videostate ? <button
                        className="btn btn-primary round-btn"
                        onClick={() => playPodcastVideo("play")}
                      >
                        <BsPlayFill size="22px" title="Click to play" />
                        <span className="play-btn d-mobile-none">Play</span>
                      </button> : <button
                        className="btn btn-primary "
                        onClick={() => playPodcastVideo("pause")}
                      >
                        <BsPlayFill size="22px" title="Click to play" />
                        <span className="play-btn d-mobile-none">Pause</span>
                      </button>
                      : (
                        <div>
                          {playOrPauseMusicindex === 0 && (
                            <div>
                              {playOrPauseShow === true ? (
                                <button
                                  className="btn btn-primary round-btn"
                                  onClick={() => onAudioPause()}
                                >
                                  <BsPauseFill size="22px" title="Click to pause" />
                                  <span className="play-btn d-mobile-none">Pause</span>
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary round-btn"
                                  onClick={() => onAudioPlay()}
                                >
                                  <BsPlayFill size="22px" title="Click to play" />
                                  <span className="play-btn d-mobile-none">Play</span>
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                  <div
                    className="col-auto "
                    title={podcastItem.liked === true ? constant.unmic : constant.mic}
                  >
                    {podcastItem.liked === true ? (
                      <button
                        disabled={buttonDisable}
                        className="btn btn-primary round-btn"
                        onClick={() => resetPodcastLike()}
                      >

                        <img src={blackmikefill} title={constant.unmic} style={{ width: 25 }} />
                        <span className="play-btn d-mobile-none">{constant.unmic}</span>
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary round-btn"
                        disabled={buttonDisable}
                        onClick={() => {
                          createPodcastLike();
                        }}
                      >

                        <img src={blackmike} title={constant.mic} style={{ width: 23 }} />
                        <span className="play-btn d-mobile-none">{constant.mic}</span>
                      </button>
                    )}
                  </div>
                  <div className="col-auto">
                    <button
                      className="btn btn-primary round-btn"
                      onClick={() => {
                        setShowShareToModal(true);
                      }}
                    >
                      <FaShareAlt size="22px" />
                      <span className="play-btn d-mobile-none">Share</span>
                    </button>
                  </div>
                  <div className="col-auto custom-pad">
                    <button
                      className="btn btn-primary round-btn"
                      onClick={displayReportArtistModal}
                    >
                      <MdReport size="22px" />
                      <span className="play-btn d-mobile-none">Report</span>
                    </button>
                  </div>
                  <CommentsModal
                    type="ReportPodcast"
                    show={showReportPodcastModal}
                    id={podcastItem.id}
                    title={"Report Podcast"}
                    currentUserId={
                      currentUserInfo !== undefined ? currentUserInfo.id : 0
                    }
                    handleClose={hideReportArtistModal}
                    inputText={reportPodcastInputText}
                    setInputText={(val) => {
                      setreportPodcastInputText(val);
                    }}
                    isComment={false}
                    setUpdate={() => { console.log("DONE"); }}
                  />
                  <ShareModal
                    show={showShareToModal}
                    id={podcastItem.id}
                    title={"Share To"}
                    currentUserId={
                      currentUserInfo !== undefined ? currentUserInfo.id : 0
                    }
                    handleClose={() => {
                      setShowShareToModal(false);
                    }}
                    from="PodcastDetails"
                  />
                </div> */}

              </div>

            </div>
          </div>
        </div>
      </section>
     <section className="comment-main-podcast">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12">
              <div className="fs-20 fw-700">Description</div>
              <p className="fs-15 fw-500 lh-20 mt-3">{podcastItem.description}</p>
            </div>
            <div className="col-12">
              <div className="mt-2 fs-20 fw-700">Tags</div>
              <div className="tags-programe mt-3 ">
                {renderCategories()}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="comments mt-5">
        <div className="container">
          <div className="fs-20 fw-700 mt-30">Artist Details</div>
          <div className="row mt-4">
            <div className="col-auto">
              <img src={artist?.urlImage !== undefined &&
                artist?.urlImage !== null &&
                artist?.urlImage !== "" &&
                artist?.urlImage !== "user.png"
                ? constant.profileURL + artist?.urlImage
                : artist?.usertype == "Artist" ? constant.artistImageURL : constant.userImageURL} className='img-fluid user-img'></img>
            </div>
            <div className="col-lg">
              <div className=" wrapText fs-40 fw-700 wrapText mt-3 mt-md-0">{artist?.firstName} {artist?.lastName}</div>
              <div className="fs-15 fw-500 lh-20 mt-3">{artist?.biography}</div>
            </div>
          </div>
          <div className="row mt-4">
          <div
                className="col-auto "
                title={artistLikes ? constant.unmic : constant.mic}
              >
                {artistLikes ? (
                  <button
                    className="btn btn-primary round-btn"
                    disabled={buttonDisable}
                    onClick={() => {
                      resetArtistLike();
                    }}
                  >

                    <img src={blackmikefill} title={constant.unmic} style={{ width: 25 }} />
                    <span className="play-btn d-mobile-none">{constant.unmic}</span>
                  </button>
                ) : (
                  <button
                    disabled={buttonDisable}
                    className="btn btn-primary round-btn"
                    onClick={() => createArtistLike()}
                  >
                    {/* <AiOutlineHeart size={22} /> */}
                    <img src={blackmike} title={constant.mic} style={{ width: 23 }} />
                    <span className="play-btn d-mobile-none">{constant.mic}</span>
                  </button>
                )}
              </div>
              <div
                className="col-auto"
                title={artistFollow ? "UnFollow" : "Follow"}
              >
                <button
                  className="btn btn-primary round-btn"
                  disabled={buttonfollowDisable}
                  onClick={() =>
                    artistFollow ? resetFollow() : createFollow()
                  }
                >
                  {artistFollow ? (
                    <RiUserUnfollowLine size={22} />
                  ) : (
                    <RiUserFollowLine size={22} />
                  )}
                  <span className="play-btn d-mobile-none">
                    {artistFollow ? "UnFollow" : "Follow"}
                  </span>
                </button>
              </div>
          </div>
          <div className="fs-20 fw-700 mt-5">Connect with Artist</div>
          <div className="form-row mt-4">
            {artist?.appleMusicURL && (<div className="col-auto">
              <div
                title="Apple Music"
                className="cursor"
                onClick={() => {
                  navigateToExternalLink(artist?.appleMusicURL);
                }}
              >
                <img
                  style={{ height: 40, width: 40 }}
                  src={apple}
                  alt="Location"
                ></img>
              </div>
            </div>
            )}
            {artist?.applePodcastURL && (<div className="col-auto">
              <div
                title="Apple Podcast"
                className="cursor"
                onClick={() => {
                  navigateToExternalLink(artist?.applePodcastURL);
                }}
              >
                <img
                  style={{ height: 40, width: 40 }}
                  src={podcaste}
                  alt="Location"
                ></img>
              </div>
            </div>
            )}
            {artist?.facebookURL && (<div className="col-auto">
              <div
                title="Facebook"
                className="cursor"
                onClick={() => {
                  navigateToExternalLink(artist?.facebookURL);
                }}
              >
                <img
                  style={{ height: 40, width: 40 }}
                  src={facebookIcon}
                  alt="Location"
                ></img>
              </div>
            </div>
            )}
            {artist?.instagramURL && (<div className="col-auto">
              <div
                title="Instagram"
                className="cursor"
                onClick={() => {
                  navigateToExternalLink(artist?.instagramURL);
                }}
              >
                <img
                  style={{ height: 40, width: 40 }}
                  src={instagramIcon}
                  alt="Location"
                ></img>
              </div>
            </div>)}
            {artist?.iTunesURL && (<div className="col-auto">
              <div
                title="iTune"
                className="cursor"
                onClick={() => {
                  navigateToExternalLink(artist?.iTunesURL);
                }}
              >
                <img
                  style={{ height: 40, width: 40 }}
                  src={itune}
                  alt="Location"
                ></img>
              </div>
            </div>)}

            {artist?.spotifyURL && (<div className="col-auto">
              <div
                title="Spotify"
                className="cursor"
                onClick={() => {
                  navigateToExternalLink(artist?.spotifyURL);
                }}
              >
                <img
                  style={{ height: 40, width: 40 }}
                  src={spotify}
                  alt="Location"
                ></img>
              </div>
            </div>)}
            {artist?.tikTokURL && (<div className="col-auto">
              <div
                title="Tiktok"
                className="cursor"
                onClick={() => {
                  navigateToExternalLink(artist?.tikTokURL);
                }}
              >
                <img
                  style={{ height: 40, width: 40 }}
                  src={tiktok}
                  alt="Location"
                ></img>
              </div>
            </div>)}
            {artist?.youtubeURL && (<div className="col-auto">
              <div
                title="Youtube"
                className="cursor"
                onClick={() => {
                  navigateToExternalLink(artist?.youtubeURL);
                }}
              >
                <img
                  style={{ height: 40, width: 40 }}
                  src={youtube}
                  alt="Location"
                ></img>

              </div>
            </div>)}
          </div>
        </div>
      </section>
      <section className="sec-episode">
        <div className="container">
          <div className="row justify-content-between align-items-center mb-4" >
            <div className="col-auto">
              <div className="biography-title fs-20 fw-600">
                Episode
              </div>
            </div>
            <div className="col-auto">
              {currentUserInfo?.id==artist?.id ?<button className="btn btn-primary dis-flex" onClick={addepisodepage}>
                Add Episode
              </button>:null}
            </div>
          </div>
          {episode.length > 0 &&
            <div className="sec-header d-none d-md-block">
              <div className="row" >
                <div className="col-1" >
                </div>
                <div className="col-4" >
                  <div className="title-header">
                    Title
                  </div>
                </div>
                <div className="col-2" >
                  <div className="title-header">
                    Posted Date
                  </div>
                </div>
                <div className="col-1" >
                  <div className="title-header">
                    Time
                  </div>
                </div>
              </div>
            </div>
          }
          {episode.length > 0 && episode?.map((c, i) => {
            return <div className="episode-box" >
              <div className="row align-items-center" >
                <div className="col-auto col-md-1" >
                  {playOrPauseMusic === false ? (
                    <BiPlay
                      title="Click to play"
                      className="play-song"
                      style={{
                        cursor: "pointer",
                        // marginLeft: props.showMusicLike ? 0 : 25,
                      }}
                      onClick={() => playMusic(i)}
                    />
                  ) : (
                    <div>
                      {playOrPauseMusicindex === i ? (
                        <div>
                          {playOrPauseShow === true ? (
                            <BiPause
                              title="Click to pause"
                              className="play-song"
                              style={{
                                cursor: "pointer",
                                // marginLeft: props.showMusicLike ? 0 : 25,
                              }}
                              onClick={() => {
                                // console.log("pause Circle");
                                onAudioPause(i);
                              }}
                            />
                          ) : (
                            <BiPlay
                              title="Click to play"
                              className="play-song"
                              style={{
                                cursor: "pointer",
                                // marginLeft: props.showMusicLike ? 0 : 25,
                              }}
                              onClick={() => {
                                // console.log("play Circle");
                                onAudioPlay(i);
                              }}
                            />
                          )}
                        </div>
                      ) : (
                        <BiPlay
                          title="Click to play"
                          className=" play-song"
                          style={{
                            cursor: "pointer",
                            // marginLeft: props.showMusicLike ? 0 : 25,
                          }}
                          onClick={() => playMusic(i)}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="col-auto col-md-1" >
                  <img className="episode-img-main" src={constant.podcastepisodeFileURL + c.thumbnailUrlImage} alt="search-image" />
                </div>
                <div className="col-5 col-md-3">
                  <div className="fs-14 fw-600 wrapText">
                    {c.title}
                  </div>
                  <div className="fs-14  fw-400 wrapText mt-3" title={c.description}>
                    {c.description}
                  </div>
                  <div className="col-md-1 d-none d-md-block">
                    {showAudioPlayer === false && <div>&nbsp;</div>}
                    {showAudioPlayer === true &&
                      playOrPauseMusicindex === i
                      &&
                      (
                        <>
                          <div id="bars">
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                            <div
                              className={
                                barHide === true ? "bar animat-pause" : "bar"
                              }
                            ></div>
                          </div>
                        </>
                      )}
                  </div>
                </div>

                <div className="col-2 d-none d-md-block">
                  <div className="fs-14 fw-400 wrapText">
                    {moment(c.created).format("DD-MMM-yyyy")}
                  </div>
                </div>
                <div className="col-1 d-none d-md-block">
                  <div className="fs-14 fw-400 wrapText">
                    {c.duration !== undefined &&
                      c.duration !== null &&
                      c.duration !== NaN
                      ? formatTime(c.duration)
                      : "0:00"}
                  </div>
                </div>
                <div className="col-5 col-md-3">
                  <div className="dis-flex justify-content-around align-items-center">
                    <div className="fs-14 fw-400 wrapText">
                    {disableItem && mId === c.id ? DisableButtonLoader() :
                          <>
                      <img className="w-24" src={c.userlike.length > 0 ? mikeFill : mike} alt="Mike-Unmike" style={{ cursor: "pointer" }}
                        onClick={c.userlike.length > 0 ? () =>
                        //resetEpisodeLike(c.id, c.userlike[0].id, c.userlike) 
                        {
                          {
                            if (VerifyLoginExistOrNot()) {
                              resetEpisodeLike(c.id, c.userlike[0].id, c.userlike);
                            }
                            else {
                              history.push({
                                pathname: `/login`,
                                state: {
                                  prevPath: path.pathname,
                                  userId:
                                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                                },
                              });
                            }
                          }
                        }
                          : () =>
                          //createEpisodeLike(c.id)
                          {
                            {
                              if (VerifyLoginExistOrNot()) {
                                createEpisodeLike(c.id);
                              }
                              else {
                                history.push({
                                  pathname: `/login`,
                                  state: {
                                    prevPath: path.pathname,
                                    userId:
                                      currentUserInfo !== undefined ? currentUserInfo.id : 0,
                                  },
                                });
                              }
                            }
                          }
                        } /> <span>
                        {c.likes.length}
                      </span>
                      </>
                        }
                    </div>
                    <div className="fs-14 fw-400 wrapText">
                      <div>
                        <FaRegCommentDots className="comments-dot" style={{ cursor: "pointer" }} onClick={() => {
                          if (VerifyLoginExistOrNot()) {
                            setcommentindex(i)
                            setSelectedMusicItem(c);
                            setShowCommentSongModal(true);
                          } else {
                            history.push({
                              pathname: `/login`,
                              state: {
                                prevPath: path.pathname,
                                userId: props.currentUserId,
                              },
                            });
                          }
                        }} />
                        <span >
                          {c.extra1 == undefined ? c?.comments?.length : c?.extra1}
                        </span>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-1 col-md-1">
                  <div className="dropdown show dropdown-arrow-hide">
                    <DropdownButton
                      className="artistMore"
                      variant="Secondary"
                      title={
                        <FiMoreVertical
                          style={{ cursor: "pointer" }}
                          size="22px"
                          color={colors.color1}
                        ></FiMoreVertical>
                      }
                    >
                      <Dropdown.Item
                        onClick={() => {
                          setSelectedMusicItem(c);
                          setShowShareToModal(true);
                        }}>
                        Share
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          if (VerifyLoginExistOrNot()) downloadMusic(c);
                          else {
                            history.push({
                              pathname: `/login`,
                              state: {
                                prevPath: path.pathname,
                                userId: props.currentUserId,
                              },
                            });
                          }
                        }}
                      >
                        Download
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => {
                          if (VerifyLoginExistOrNot()) {
                            setSelectedMusicItem(c);
                            setShowReportSongModal(true);
                          } else {
                            history.push({
                              pathname: `/login`,
                              state: {
                                prevPath: path.pathname,
                                userId: props.currentUserId,
                              },
                            });
                          }
                        }}
                      >
                        Report Song
                      </Dropdown.Item>
                      {currentUserInfo?.id==artist?.id ? 
                      <><Dropdown.Item onClick={() => editepisodepage(c.id, c)}
                      >
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => onDelete(c)}
                      >
                        Delete
                      </Dropdown.Item></>:null}
                    </DropdownButton>
                  </div>
                </div>
              </div>
            </div>
          })}
          {
            episode.length === 0 &&
            <div className="no-data-found" style={{ paddingLeft: 15 }}>
              {constant.noDataFoundLabel}
            </div>
          }
          {episode.length > 0 && ismore && (
            <div className="row justify-content-center mt-3">
              <div className="col-auto">
                <button className="btn dark" onClick={onviewmore}>
                  Show More
                </button>
              </div>
            </div>
          )}
          {/*  */}



        </div>
      </section>
      {/* <section className="comment-main-podcast">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12">
              <div className="fs-20 fw-700">Description</div>
              <p className="fs-15 fw-500 lh-20 mt-3">{podcastItem.description}</p>
            </div>
            <div className="col-12">
              <div className="mt-2 fs-20 fw-700">Tags</div>
              <div className="tags-programe mt-3 ">
                {renderCategories()}
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {showShareToModal === true &&
        selectedMusicItem !== undefined && (
          <>
            <ShareModal
              show={showShareToModal}
              id={selectedMusicItem.id}
              title={"Share To"}
              currentUserId={
                currentUserInfo !== undefined
                  ? currentUserInfo.id
                  : 0
              }
              handleClose={() => {
                setShowShareToModal(false);
              }}
              from="Episode"
            />
          </>
        )}
      {deleteModal && (
        <ConfirmDeleteModal
          deleteModal={deleteModal}
          type={"Episode"}
          setdeleteModal={setdeleteModal}
          onconfirmDelete={onconfirmDelete}
        />
      )}
      {showReportSongModal === true &&
        selectedMusicItem !== undefined && (
          <CommentsModal
            type="ReportEpisode"
            show={showReportSongModal}
            id={selectedMusicItem.id}
            title={"Report Episode - " + selectedMusicItem.title}
            currentUserId={
              currentUserInfo !== undefined
                ? currentUserInfo.id
                : 0
            }
            handleClose={() => {
              setShowReportSongModal(false);
            }}
            inputText={reportSongInputText}
            setInputText={(val) => {
              setReportSongInputText(val);
            }}
            isComment={false}
            setUpdate={() => { //console.log("DONE"); 
            }}
          />
        )}
      {showCommentSongModal === true &&
        selectedMusicItem !== undefined && (
          <CommentsModal
            type="CommentEpisode"
            show={showCommentSongModal}
            id={selectedMusicItem.id}
            title={"Comment - " + selectedMusicItem.title}
            currentUserId={
              currentUserInfo !== undefined
                ? currentUserInfo.id
                : 0
            }
            handleClose={() => {
              setShowCommentSongModal(false);
            }}
            inputText={commentSongInputText}
            setInputText={(val) => {
              setCommentSongInputText(val);
            }}
            isComment={true}
            setUpdate={() => updatecomment()}
          />
        )}
      <Comments
        type="podcast"
        data={commentsList}
        setComments={setComments}
        currentUserId={currentUserInfo !== undefined ? currentUserInfo.id : 0}
        id={
          idf !== undefined
            ? idf
            : 0
        }
        errorCommentMessage={errorCommentMessage}
        commentInputVal={commentInputVal}
        viewMoreComments={viewMoreComments}
        showViewMore={showViewMore}
        setCommentError={() => setCommentError(true)}
        setCommentInputVal={(val) => {
          setCommentInputVal(val);
          errorCommentMessage && setCommentError(false);
        }}
      />
      <HomePodcastsList
        podcastDetails={podcastDetails}
        podcast="podcast"

      />
      {showAudioPlayer && (
        <AudioPlayer
          getAudioInstance={getAudioInstance}
          playOrPauseShow={playOrPauseShow}
          setplayOrPauseShow={setplayOrPauseShow}
          onAudioPlay={onAudioPlay}
          onAudioPause={onAudioPause}
          musicList={episode}
          playIndex={playIndex}
          onPlay={onPlay}
          setplayOrPauseMusicindex={setplayOrPauseMusicindex}
          close={false}
          setShowAudioPlayer={resetShowAudioPlayer}
          setPlayIndex={setPlayIndex}
        ></AudioPlayer>
      )}
    </div>
  );
}

export default PodcastDetails;
