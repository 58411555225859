import React, { useState, useEffect } from "react";
import { constant } from "../../utility/constant";
import { deleteDataApi, getDataApi, postDataApi } from "../../utility/apiCall";
import { useHistory } from "react-router-dom";
import { hashCodeUrl } from "../../utility/EncodeAndDecodeUrl";
import mike from "../../assets/Images/mike.svg";
import mikeFill from "../../assets/Images/mikeFill.svg";
import { VerifyLoginExistOrNot } from "../../utility/VerifyLoginExistOrNot";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { FaRegCommentDots } from "react-icons/fa";
import CommentsModal from "../CommentsModal/CommentsModal";
import { DisableButtonLoader } from "../../utility/CustomLoader";

const AllArtists = (props) => {
  //console.log(props)
  let history = useHistory();
  let path = useLocation();
  const [currentUserInfo, setCurrentUserInfo] = useState(
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : undefined
  );
  const [disableItem, setDisableItem] = useState(false);
  const [arId, setArId] = useState(0);
  const [updated, setUpdated] = useState(false);
  const [showViewMore, setShowViewMore] = useState(false);
  const [page, setPage] = useState(1);
  //const [pageCount, setPageCount] = useState(0);
  const [limit, setLimit] = useState(18);
  const [artistList, setArtistList] = useState([]);
  const [artistLikesList, setArtistLikesList] = useState([]);
  const [artistLikesData, setArtistLikesData] = useState([]);
  const [artistResetCount, setArtistResetCount] = useState(0);
  //const [artId, setArtId] = useState(0);
  const [showCommentHomeArtistModal, setShowCommentHomeArtistModal] = useState(false);
  const [commentHomeArtistInputText, setCommentHomeArtistInputText] = useState("");
  const [update, setUpdate] = useState(false);
  const [commentsCount, setCommentsCount] = useState(0);
  const [selectedArtistItem, setSelectedArtistItem] = useState(undefined);

  const setCommentUpdate = () => {
    setCommentsCount(commentsCount + 1);
  }
  // http://45.80.152.23:4527/user/getAllArtistsWithUserLike?page=1&limit=10&userId=1
  const getArtists = async (pageNumber) => {
    let apiURL
    if(props.isFeatured === true){
      apiURL = constant.baseURL + 'user/getArtistsWithUserLike?page=' +
      pageNumber + '&limit=' +
      limit + '&isFeatured=' +  'Yes' + '&userId=' + ((currentUserInfo) ? currentUserInfo.id : 0)
    }else{
      apiURL = constant.baseURL + 'user/getAllArtistsWithUserLike?page=' +
      pageNumber + '&limit=' +
      limit + '&userId=' + ((currentUserInfo) ? currentUserInfo.id : 0)
    }
   

    let resAllArtists = await getDataApi(apiURL);
    //console.log("resAllArtists: ", resAllArtists);
    if (
      resAllArtists !== undefined &&
      resAllArtists.data !== undefined &&
      resAllArtists.data.length > 0
    ) {
      //let artistIds = "";
      if (pageNumber === 1) {

        setArtistList([...resAllArtists.data]);
      } else {
        setArtistList([...artistList, ...resAllArtists.data]);

      }

      if (
        resAllArtists.page < resAllArtists.totalCount &&
        resAllArtists.data.length === limit
      ) {
        setShowViewMore(true);
        setDisableItem(false);
      } else {
        setShowViewMore(false);
        setDisableItem(false);
      }
    } else {
      setShowViewMore(false);
      setDisableItem(false);
    }
  };
  // const getArtists1 = async (pageNumber) => {
  //   let apiURL
  //   if(props.isFeatured === true){
  //     apiURL = constant.baseURL + 'user/getArtistsWithUserLike?page=' +
  //     1 + '&limit=' +
  //     limit + '&isFeatured=' +  'Yes' + '&userId=' + ((currentUserInfo) ? currentUserInfo.id : 0)
  //   }else{
  //     apiURL = constant.baseURL + 'user/getArtistsWithUserLike?page=' +
  //     1 + '&limit=' +
  //     limit + '&userId=' + ((currentUserInfo) ? currentUserInfo.id : 0)
  //   }
  //   let apiURL = constant.baseURL + 'user/getArtistsWithUserLike?page=' +
  //     1 + '&limit=' +
  //     limit * pageNumber + '&isFeatured=' + ((props.isFeatured === true) ? 'Yes' : 'No') + '&userId=' + ((currentUserInfo) ? currentUserInfo.id : 0)
  //   let resAllArtists = await getDataApi(apiURL);
  //   console.log("resAllArtists: ", resAllArtists);
  //   if (
  //     resAllArtists !== undefined &&
  //     resAllArtists.data !== undefined &&
  //     resAllArtists.data.length > 0
  //   ) {

  //     setArtistList([...resAllArtists.data]);
  //     // }
  //     //  else {
  //     //   setArtistList([...artistList, ...resAllArtists.data]);
  //     //   resAllArtists.data.map((item) => {
  //     //     artistIds += item.id + ",";
  //     //   });
  //     //   artistList.map((item) => {
  //     //     artistIds += item.id + ",";
  //     //   });
  //     //   getArtistLikesByUser(artistIds.substr(0, artistIds.lastIndexOf(",")));
  //     // }

  //     // if (
  //     //   resAllArtists.page < resAllArtists.totalCount &&
  //     //   resAllArtists.data.length === limit
  //     // ) {
  //     //   setShowViewMore(true);
  //     //   setDisableItem(false);
  //     // } else {
  //     //   setShowViewMore(false);
  //     //   setDisableItem(false);
  //     // }
  //   } else {
  //     setShowViewMore(false);
  //     setDisableItem(false);
  //   }
  // };
  const onClickViewMore = () => {
    setDisableItem(true);
    setPage(page + 1);
    getArtists(page + 1);
  };
  const navigateToArtistDetails = (index, isMainArtist) => {

    let indexItem = artistList[index];
    // let featuredUserId = hashCodeUrl(indexItem.id);
    history.push({
      pathname: `FeaturedArtistDetails/${hashCodeUrl(indexItem.id)}/`,
      state: {
        id: indexItem.id,
        from:props.isFeatured==true?"featureartists":"featureartistsc"
      },
    });

    window.scrollTo(0, 0);
  };
  const createEpisodeLike = async (episodeId) => {
    if (currentUserInfo.id) {
      let jsonData = { user: currentUserInfo.id, artist: episodeId };
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resBigStageLike = await postDataApi(
        constant.baseURL + "likes",
        formBody,
        authHeader
      );
      //console.log(resBigStageLike)
      if (
        resBigStageLike?.data !== undefined
      ) {
        setLikes(episodeId, resBigStageLike.data, undefined);
      }
    }
    else {
      history.push({
        pathname: `/login`,
        // state: {
        //   prevPath: path.pathname,
        //   userId: props.currentUserId,
        // },
      });
    }
  };

  const resetEpisodeLike = async (episodeId, likeId) => {
    //console.log(episodeId, likeId)
    if (currentUserInfo.id) {
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resp = await deleteDataApi(
        constant.baseURL + "likes/" + likeId,
        authHeader
      );
      if (
        resp !== undefined &&
        resp.status !== undefined &&
        resp.status === 200
      ) {
        setLikes(episodeId, undefined, likeId);
      }
    } else {
      history.push({
        pathname: `/login`,
        // state: {
        //   prevPath: path.pathname,
        //   userId: props.currentUserId,
        // },
      });
    }
  };
  const setLikes = (id, data, likeId) => {
    let bgItems = [...artistList]
    //console.log(bgItems)
    if (data !== undefined) {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike.push(data);
          itm.likes.push(data);
        }
      });
    } else {
      bgItems.map((itm) => {
        if (itm.id === id) {
          itm.userlike = [];
          itm.likes.map((lkitm, index0) => {
            if (lkitm.id === likeId) {
              itm.likes.splice(index0, 1);
            }
          });
        }
      });
    }

    setArtistList(bgItems)

  };
  // // ------------------------------------------satyam Agarwal
  // const getArtistLikesByUser = async (str) => {
  //   // console.log("currentUserInfo", currentUserInfo, "str", str);
  //   if (currentUserInfo !== undefined && str !== "") {
  //     let resArtistLikes = await getDataApi(
  //       constant.baseURL +
  //       "likes?filter=user.id||$eq||" +
  //       currentUserInfo.id +
  //       "&filter=artist.id||$in||" +
  //       str
  //     );
  //     // console.log("resArtistLikes: ", resArtistLikes);
  //     let artistLikeItems = [];

  //     if (resArtistLikes !== undefined) {
  //       resArtistLikes.map((m) => {
  //         artistLikeItems.push(m.artist.id);
  //       });
  //       setArtistLikesList(resArtistLikes);
  //       setArtistLikesData(resArtistLikes);
  //     }
  //     setDisableItem(false);
  //   } else {
  //     setDisableItem(false);
  //   }
  // };
  const createArtistLike = async (artistId) => {
    if (VerifyLoginExistOrNot()) {
      setDisableItem(true);
      setArId(artistId);
      if (currentUserInfo !== undefined) {
        let jsonData = { user: currentUserInfo.id, artist: artistId };
        var formBody = [];
        for (var property in jsonData) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(jsonData[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        var authHeader = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        };
        let respArtistLike = await postDataApi(
          constant.baseURL + "likes",
          formBody,
          authHeader
        );
        if (
          respArtistLike !== undefined &&
          respArtistLike.status !== undefined &&
          respArtistLike.status === 201
        ) {
          setUpdated(!updated);
          setLikes(artistId, respArtistLike.data, undefined);//setLikes(artistId, respArtistLike.data);
          setDisableItem(false);
        } else {
          setDisableItem(false);
        }
      } else {
        setDisableItem(false);
      }
    }
  };

  const resetArtistLike = async (artistId, likeId) => {
    if (VerifyLoginExistOrNot()) {
      setDisableItem(true);
      setArId(artistId);
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let resp = await deleteDataApi(
        constant.baseURL + "likes/" + likeId,
        authHeader
      );
      if (
        resp !== undefined &&
        resp.status !== undefined &&
        resp.status === 200
      ) {
        setUpdated(!updated);
        setLikes(artistId, undefined, likeId);//setLikes(artistId);
        setDisableItem(false);
      }
      else {
        setDisableItem(false);
      }
    } else {
      setDisableItem(false);
    }
  };
  // const setLikes = (id, data) => {
  //   //setArtId(id);
  //   setArtistResetCount(artistResetCount + 1);
  // };
  const renderLikes = (likeData) => {
    //console.log(likeData)
    if (likeData?.userlike?.length > 0) {
      return (
        <img className="w-24" src={mikeFill} alt="Unmike" style={{ cursor: "pointer" }}
          //onClick={() => resetEpisodeLike(likeData.id, likeData.userlike[0].id)} 
          onClick={() => {
            if (VerifyLoginExistOrNot()) resetArtistLike(likeData.id, likeData.userlike[0].id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    } else {
      return (
        <img className="w-24" src={mike} alt="Mike" style={{ cursor: "pointer" }}
          //onClick={() => createEpisodeLike(likeData.id)} 
          onClick={() => {
            if (VerifyLoginExistOrNot()) createArtistLike(likeData.id);
            else {
              history.push({
                pathname: `/login`,
                state: {
                  prevPath: path.pathname,
                  userId:
                    currentUserInfo !== undefined ? currentUserInfo.id : 0,
                },
              });
            }
          }}
        />
      )
    }
  };

  const renderArtists = () => {
    if (artistList.length > 0) {
      return artistList.map((item, index) => {
        return (
          <div className="col-6 col-md-3 col-lg-2" key={index}>
            <div className="communityRoomArtist">
              <div className="card mm-card-bg">
                <img
                  style={{
                    width: "100%",
                    cursor: "pointer",
                    height: 140,
                  }}
                  onClick={() => navigateToArtistDetails(index, false)}
                  src={
                    item.urlImage !== undefined &&
                      item.urlImage !== null &&
                      item.urlImage !== "" &&
                      item.urlImage !== "user.png"
                      ? constant.profileURL + item.urlImage
                      : item.usertype == "Artist" ? constant.artistImageURL : constant.userImageURL
                  }
                  alt=""
                />
                <div className="card-body">
                  <h6
                    className="fs-16 mt-1 fw-600 wrapText"
                    title={String(
                      item.firstName + " " + item.lastName
                    )}
                  >
                    {String(item.firstName + " " + item.lastName)}
                  </h6>
                  <p className="fs-14 mt-3 fw-400 wrapText">
                    {item.albums.length} Albums
                  </p>
                  <div className="line"></div>
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-auto">
                          <div>
                            {disableItem && arId === item.id ? DisableButtonLoader() :
                              <>
                                {renderLikes(item)}
                                <span className="fs-14 fw-500 pl-1">
                                  {item.likes.length}
                                </span>
                              </>
                            }
                          </div>
                        </div>
                        <div className="col-auto">
                          <div>
                            <FaRegCommentDots
                              color="#b09b2f"
                              title="Comments"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (VerifyLoginExistOrNot()) {
                                  setSelectedArtistItem(item);
                                  setShowCommentHomeArtistModal(true);
                                } else {
                                  history.push({
                                    pathname: `/login`,
                                    state: {
                                      prevPath: path.pathname,
                                      userId:
                                        currentUserInfo !== undefined
                                          ? currentUserInfo.id
                                          : 0,
                                    },
                                  });
                                }
                              }}
                            />
                            <span className="fs-14 fw-500 pl-3">
                              {item.comments.length}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
    else {
      return (
        <div className="no-data-found" style={{ paddingLeft: 15 }}>
          {constant.noDataFoundLabel}
        </div>
      );
    }
  };
  useEffect(() => {
    setCommentsCount(0);
    getArtists(page);
  }, []);
  // useEffect(() => {
  //   getArtists1(page);
  // },[]);
  return (
    <section className="upload-section-singer-name">
      <div className="container">
        <div className="fs-20 fw-700">Artists</div>
        <div className="form-row mt-2 mt-md-3">{renderArtists()}</div>
        {artistList.length > 0 && showViewMore === true && (
          <div className="row justify-content-center mt-3">
            <div className="col-auto">
              <button
                className="btn dark"
                onClick={onClickViewMore}
                disabled={disableItem}
                style={{ cursor: "pointer", width: "100%", display: 'flex' }}>
                {disableItem && DisableButtonLoader()}
                <span style={{ paddingLeft: 3 }}>{constant.showMoreLabel}</span>
              </button>
            </div>
          </div>
        )}
      </div>
      {showCommentHomeArtistModal === true &&
        <CommentsModal
          update={update}
          setUpdate={setCommentUpdate}
          type="CommentHomeArtist"
          show={showCommentHomeArtistModal}
          id={selectedArtistItem.id}
          title={"Comment - " + selectedArtistItem.firstName + " " + selectedArtistItem.lastName}
          currentUserId={currentUserInfo !== undefined ? currentUserInfo.id : 0}
          handleClose={() => {
            setShowCommentHomeArtistModal(false);
          }}
          inputText={commentHomeArtistInputText}
          setInputText={(val) => {
            setCommentHomeArtistInputText(val);
          }}
          isComment={true}
        />
      }
    </section>
  );
};
export default AllArtists;

