import React, { useEffect, useState } from "react";
import homeBg from "../../assets/Images/homeBg.png";
import { constant } from "../../utility/constant";
import { getDataApi, postDataApi } from "../../utility/apiCall";
import { useHistory } from "react-router-dom";
import "./CreateAccount.css";
import { addErrorMesage, addSuccessMesage } from "../CustomToster/CustomToaster";
import { BsEyeFill, BsEyeSlashFill, BsPlus } from "react-icons/bs";
import { addLoader, removeLoader } from "../../utility/CustomLoader";
import Loginprofile from "../../assets/Images/avatar.png";
import ImageUploader from "react-images-upload";
import { AiFillWarning, AiFillCloseSquare, AiFillEdit } from "react-icons/ai";
import validator from "validator";


const CreateAccount = () => {
  const [passwordShow, setPasswordShow] = useState({
    isCreatePasswordShow: true,
    isRepeatPasswordShow: true,
  });
  const [audience, setAudience] = useState("User");
  const [subscribe, setSubscribe] = useState(false);
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [firstNameErrMsg, setFirstNameErrMsg] = useState("");
  const [lastNameErr, setLastNameErr] = useState(false);
  const [lastNameErrMsg, setLastNameErrMsg] = useState("");
  const [emailErr, setEmailErr] = useState(false);

  const [emailErrMsg, setEmailErrMsg] = useState("");

  const [createPasswordErr, setCreatePasswordErr] = useState(false);
  const [createPasswordErrMsg, setCreatePasswordErrMsg] = useState("");
  const [phoneNoErr, setPhoneNoErr] = useState(false);
  const [phoneErrorMsg, setphoneErrorMsg] = useState("");
  const [commonUserNameErr, setcommonUserNameErr] = useState(false);
  const [commonUserNameErrMsg, setcommonUserNameErrMsg] = useState("");
  const [repeatPasswordErr, setRepeatPsswordErr] = useState(false);
  const [repeatPasswordErrMsg, setRepeatPasswordErrMsg] = useState("");
  const [termConditions, setTermConditions] = useState(false);
  const [termConditionsErr, setTermConditionsErr] = useState(false);
  const [nextPage, setnextPage] = useState(false);

  const [selectedUserPictue, setSelectedUserPicture] = useState([]);
  const [profilePictureErrMsg, setProfilePictureErrMsg] = useState("");
  const [profilePictureErr, setProfilePictureErr] = useState(false);
  const [subscribeText, setSubscribeText] = useState("Subscribe");

  var history = useHistory();
  //console.log("history", history);
  const [inputVal, setInputVal] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    createPassword: "",
    repeatPassword: "",
    commonUserName: "",
    urlImage: "",
  });

  const getInputVal = (e) => {
    let text = e.target.value;
    let name = e.target.name;
    setInputVal({ ...inputVal, [name]: text });
    if (name === "firstName") setFirstNameErr(false);
    if (name === "lastName") setLastNameErr(false);
    if (name === "email") setEmailErr(false);
    if (name === "createPassword") setCreatePasswordErr(false);
    if (name === "repeatPassword") setRepeatPsswordErr(false);
    if (name === "phoneNo") setPhoneNoErr(false);
    if (name === "commonUserName") setcommonUserNameErr(false);
    if (!text.replace(/\s/g, "").length)
      setInputVal({ ...inputVal, [name]: text });
  };
  useEffect(() => {
    if (history.action === "POP") {
      return history.push("/login");
    }
  }, []);

  const onSignup0 = () => { };
  const onSignup = () => {
    let flag = false;
    const emailReg =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //const passwordReg = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,20}$/;
    const passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    const textRegex =
      /^[/\a-zA-Z0-9\s\[\]\{\}\.\`\~\!\@\$\^\&\(\)\_\+\-\=\;\,]*$/;
    let phonenoReg = /^[0-9]/;;

    const {
      firstName,
      lastName,
      email,
      createPassword,
      repeatPassword,
      phoneNo,
      commonUserName,
    } = inputVal;

    if (audience === "Artist" && selectedUserPictue.length === 0) {
      setProfilePictureErr(true);
      setProfilePictureErrMsg(constant.profilePictureRequiredLabel);
      flag = false;
    }

    if (
      !firstName &&
      !lastName &&
      !email &&
      !createPassword &&
      !repeatPassword &&
      !phoneNo &&
      !commonUserName
    ) {
      setFirstNameErr(true);
      setFirstNameErrMsg(constant.firstNameRequired);
      setLastNameErr(true);
      setLastNameErrMsg(constant.lastNameRequired);
      setEmailErr(true);
      setEmailErrMsg(constant.emailRequired);
      setCreatePasswordErr(true);
      setCreatePasswordErrMsg(constant.passwordRequired);
      setRepeatPsswordErr(true);
      setRepeatPasswordErrMsg(constant.passwordRequired);
      setPhoneNoErr(true);
      setphoneErrorMsg(constant.phoneRequired);
      setcommonUserNameErr(true);
      setcommonUserNameErrMsg(constant.commonUserNameRequired);
      flag = false;
    } else {
      flag = true;
      if (audience === "Artist" && selectedUserPictue.length === 0) {
        setProfilePictureErr(true);
        setProfilePictureErrMsg(constant.profilePictureRequiredLabel);
        flag = false;
      } else {
        setProfilePictureErr(false);
      }
      if (!firstName) {
        setFirstNameErr(true);
        setFirstNameErrMsg(constant.firstNameRequired);
        flag = false;
      } else if (textRegex.test(firstName) === false) {
        setFirstNameErr(true);
        setFirstNameErrMsg(constant.invalidText);
        flag = false;
      }
      if (!lastName) {
        setLastNameErr(true);
        setLastNameErrMsg(constant.lastNameRequired);
        flag = false;
      } else if (textRegex.test(lastName) === false) {
        setLastNameErr(true);
        setLastNameErrMsg(constant.invalidText);
        flag = false;
      }
      if (!commonUserName) {
        setcommonUserNameErr(true);
        setcommonUserNameErrMsg(constant.commonUserNameRequired);
        flag = false;
      } else if (textRegex.test(commonUserName) === false) {
        setcommonUserNameErr(true);
        setcommonUserNameErrMsg(constant.commonUserNameRequired);
        flag = false;
      }
      if (!phoneNo) {
        setPhoneNoErr(true);
        setphoneErrorMsg(constant.phoneRequired);
        flag = false;
      } else if (phonenoReg.test(phoneNo) === false) {
        setPhoneNoErr(true);
        setphoneErrorMsg(constant.onlyNumbersAllowed);
        flag = false;
      } else if (phoneNo.length !== 10) {
        setPhoneNoErr(true);
        setphoneErrorMsg(constant.phoneRequired);
        flag = false;
      }
      if (!email) {
        setEmailErr(true);
        setEmailErrMsg(constant.emailRequired);
        flag = false;
      } else if (!emailReg.test(email)) {
        setEmailErr(true);
        setEmailErrMsg(constant.invalidEmailAddress);
        flag = false;
      }
      if (!createPassword) {
        setCreatePasswordErr(true);
        setCreatePasswordErrMsg(constant.passwordRequired);
        flag = false;
      } else if (!passwordReg.test(createPassword)) {
        setCreatePasswordErr(true);
        setCreatePasswordErrMsg(constant.passwordValidationMsg);
        flag = false;
      }
      if (!repeatPassword) {
        setRepeatPsswordErr(true);
        setRepeatPasswordErrMsg(constant.passwordRequired);
        flag = false;
      }
      if (createPassword !== repeatPassword) {
        setRepeatPsswordErr(true);
        setRepeatPasswordErrMsg(constant.passwordShouldSame);
        flag = false;
      }
      if (!termConditions) {
        setTermConditionsErr(true);
        flag = false;
      }
      if (flag) {
        addLoader();
        verifyEmailAndSendOTP();
      }
    }
  };
  const verifyEmailAndSendOTP = async () => {
    //check if user email exists
    let allowToCreateUser = true;
    let respUser = await getDataApi(
      constant.baseURL + "user?filter=email||$eq||" + inputVal.email
    );
    if (respUser !== undefined && respUser.length > 0) {
      allowToCreateUser = false;
      setEmailErr(true);
      removeLoader();
      setEmailErrMsg(constant.emailRegistered);
      addErrorMesage(constant.emailRegistered);
    }
    if (allowToCreateUser === true) {
      let jsonData0 = {
        firstName: inputVal.firstName,
        lastName: inputVal.lastName,
        userName: inputVal.commonUserName,
        email: inputVal.email,
        phone:
          inputVal.phoneNo !== undefined ? inputVal.phoneNo.toString() : "",

        password: inputVal.createPassword,
        usertype: audience,
        subscribeYouTubeChannel: subscribe == true ? 1 : 0,
        urlImage: inputVal.urlImage,
      };
      //console.log(jsonData0);
      localStorage.setItem("userEmail", inputVal.email);
      localStorage.setItem("createAccountData", JSON.stringify(jsonData0));

      let otpCode = Math.floor(10000 + Math.random() * 90000);
      let jsonData = {
        firstName: inputVal.firstName,
        lastName: inputVal.lastName,
        email: inputVal.email,
        code: otpCode,
        subject: constant.otpSubject,
        body:
          "You requested for a create account, kindly use this code <b>" +
          otpCode +
          "</b> to create your account.",
      };
      //console.log(jsonData);
      var formBody = [];
      for (var property in jsonData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(jsonData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var authHeader = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      let responseOTP = await postDataApi(
        constant.baseURL + "user/verifyEmailAndSendOTP",
        jsonData,
        authHeader
      );
      //console.log("responseOTP: ", responseOTP);
      if (
        responseOTP !== undefined &&
        responseOTP.data !== undefined &&
        responseOTP.data.status !== undefined &&
        responseOTP.data.status === true
      ) {
        removeLoader();
        localStorage.setItem("OTPDetails", JSON.stringify(responseOTP));
        localStorage.setItem("fromComponent", "Login1");
        history.push("otpverification");
      } else {
        removeLoader();
        addErrorMesage(constant.unableToValidateEmail);
        //history.push("otpverification");
      }
    }
  };

  const onUploadProfilePicture = async (picture) => {
    //console.log("Selected Profile Image: ", picture);
    if (picture.length > 0) {
      setSelectedUserPicture(picture);
      setProfilePictureErr(false);
      setProfilePictureErrMsg("");
      let customHeaders = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer ",
      };
      let data = new FormData();
      data.append(constant.uplFileParameter, picture[0]);
      let res = await postDataApi(
        //constant.baseURL + "profilefiles",
        constant.profileFileUploadURL,
        data,
        customHeaders
      );
      //console.log("res", res);
      if (res.data.status === 200) {
        setInputVal({ ...inputVal, ["urlImage"]: res.data.data.filename });
      }
    }
  };

  return (
    <>
      <section className="login-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 d-none d-xl-block">
              <div className="login-bg">
                <img src={homeBg} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-xl-6">
              <div className="create-an-account-page ">
                <div className="row justify-content-center align-items-center">
                  <div className="col-12">
                    <div className="title">
                      {nextPage === true
                        ? constant.createAnAccountTitle
                        : constant.loginTitle}
                    </div>

                    <div className="login-sub-title">
                      {nextPage === true
                        ? constant.createAnAccountSubtitle
                        : constant.createAccountTitle}
                    </div>
                    {nextPage &&
                      <div className="row justify-content-center login-profile-upload">
                        <div className="col-auto">
                          <img className="login-uplaod"
                            src={selectedUserPictue.length > 0 ? URL.createObjectURL(selectedUserPictue[0]) : Loginprofile}
                            style={{
                              height: 100,
                              width: 100,
                              borderRadius: 55,
                            }}

                          />
                          <div id="divProfileImageUpload1">
                            <ImageUploader
                              buttonClassName="plusButton"
                              buttonText={
                                <BsPlus
                                  style={{
                                    color: "white",
                                    fontSize: "23px",
                                  }}
                                />
                              }
                              label=""
                              onChange={(pic) => onUploadProfilePicture(pic)}
                              imgExtension={constant.supportedImageFiles}
                              singleImage={true}
                            ></ImageUploader>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="col-auto" style={{ textAlign: 'center' }}>
                      {profilePictureErr === true && (
                        <div className="errorText">
                          {profilePictureErrMsg}
                        </div>
                      )}
                    </div>
                    <div>
                      {nextPage && (
                        <div className="row " style={{ marginTop: "2rem" }}>
                          <div className="col-12 col-md-6">
                            <div className="create-an-account">
                              <div className="floating-label mt-10">
                                <input
                                  className="floating-input"
                                  type="text"
                                  placeholder=" "
                                  onChange={getInputVal}
                                  name="firstName"
                                  value={inputVal.firstName}
                                  maxLength={250}
                                  required={true}
                                />
                                <span className="highlight"></span>
                                <label className="label">First Name</label>
                              </div>
                              {firstNameErr === true && (
                                <div className="errorText">
                                  {firstNameErrMsg}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="create-an-account">
                              <div className="floating-label mt-10">
                                <input
                                  className="floating-input"
                                  type="text"
                                  placeholder=" "
                                  onChange={getInputVal}
                                  name="lastName"
                                  value={inputVal.lastName}
                                  required={true}
                                  maxLength={250}
                                />
                                <span className="highlight"></span>
                                <label className="label">Last Name</label>
                              </div>
                              {lastNameErr && (
                                <div className="errorText">
                                  {lastNameErrMsg}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {nextPage && (
                        <>
                          {audience === "User" ? (
                            <div className="row ">
                              <div className="col-12">
                                <div className="create-an-account">
                                  <div className="floating-label mt-10">
                                    <input
                                      className="floating-input"
                                      type="text"
                                      placeholder=" "
                                      onChange={getInputVal}
                                      name="commonUserName"
                                      value={inputVal.commonUserName}
                                      required={true}
                                      maxLength={250}
                                    />
                                    <span className="highlight"></span>
                                    <label className="label">User Name</label>
                                  </div>
                                  {commonUserNameErr && (
                                    <div className="errorText">
                                      {commonUserNameErrMsg}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="row">
                              <div className="col-12">
                                <div className="create-an-account">
                                  <div className="floating-label mt-10">
                                    <input
                                      className="floating-input"
                                      type="text"
                                      placeholder=" "
                                      onChange={getInputVal}
                                      name="commonUserName"
                                      value={inputVal.commonUserName}
                                      required={true}
                                      maxLength={250}
                                    />
                                    <span className="highlight"></span>
                                    <label className="label">Artist Name</label>
                                  </div>
                                  {commonUserNameErr && (
                                    <div className="errorText">
                                      {commonUserNameErrMsg}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      {nextPage && (
                        <div className="row">
                          <div className="col-12">
                            <div className="create-an-account">
                              <div className="floating-label mt-10">
                                <input
                                  className="floating-input"
                                  type="text"
                                  placeholder=" "
                                  onChange={getInputVal}
                                  name="email"
                                  value={inputVal.email}
                                  required={true}
                                  maxLength={250}
                                />
                                <span className="highlight"></span>
                                <label className="label">Email</label>
                              </div>
                              {emailErr && (
                                <div className="errorText">{emailErrMsg}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {nextPage && (
                        <div className="username">
                          <div className="floating-label mt-10">
                            <input
                              className="floating-input"
                              type="text"
                              placeholder=" "
                              onChange={getInputVal}
                              name="phoneNo"
                              maxLength={10}
                              value={inputVal.phoneNo}
                              required={true}
                            />
                            <span className="highlight"></span>
                            <label className="label">Phone Number</label>
                          </div>
                          {phoneNoErr === true && (
                            <div className="errorText">{phoneErrorMsg}</div>
                          )}
                        </div>
                      )}
                      {nextPage && (
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="create-an-account">
                              <div className="floating-label mt-10">
                                <input
                                  className="floating-input"
                                  // type="password"
                                  placeholder=" "
                                  type={
                                    !passwordShow.isCreatePasswordShow
                                      ? "text"
                                      : "password"
                                  }
                                  onChange={getInputVal}
                                  name="createPassword"
                                  value={inputVal.createPassword}
                                  required={true}
                                  minLength={6}
                                  maxLength={20}
                                />
                                {!passwordShow.isCreatePasswordShow ? (
                                  <div
                                    className="eye-icon-show-hide"
                                    onClick={() => {
                                      setPasswordShow((prevState) => {
                                        return {
                                          ...prevState,
                                          isCreatePasswordShow: true,
                                        };
                                      });
                                    }}
                                  >
                                    <BsEyeFill />
                                  </div>
                                ) : (
                                  <div
                                    className="eye-icon-show-hide"
                                    onClick={() => {
                                      setPasswordShow((prevState) => {
                                        return {
                                          ...prevState,
                                          isCreatePasswordShow: false,
                                        };
                                      });
                                    }}
                                  >
                                    <BsEyeSlashFill />
                                  </div>
                                )}
                                <span className="highlight"></span>
                                <label className="label">Create Password</label>
                              </div>
                              {createPasswordErr && (
                                <div className="errorText">
                                  {createPasswordErrMsg}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="create-an-account">
                              <div className="floating-label mt-10">
                                <input
                                  className="floating-input"
                                  // type="password"
                                  placeholder=" "
                                  type={
                                    !passwordShow.isRepeatPasswordShow
                                      ? "text"
                                      : "password"
                                  }
                                  onChange={getInputVal}
                                  name="repeatPassword"
                                  value={inputVal.repeatPassword}
                                  required={true}
                                  minLength={6}
                                  maxLength={20}
                                />
                                {!passwordShow.isRepeatPasswordShow ? (
                                  <div
                                    className="eye-icon-show-hide"
                                    onClick={() => {
                                      setPasswordShow((prevState) => {
                                        return {
                                          ...prevState,
                                          isRepeatPasswordShow: true,
                                        };
                                      });
                                    }}
                                  >
                                    <BsEyeFill />
                                  </div>
                                ) : (
                                  <div
                                    className="eye-icon-show-hide"
                                    onClick={() => {
                                      setPasswordShow((prevState) => {
                                        return {
                                          ...prevState,
                                          isRepeatPasswordShow: false,
                                        };
                                      });
                                    }}
                                  >
                                    <BsEyeSlashFill />
                                  </div>
                                )}
                                <span className="highlight"></span>
                                <label className="label">Repeat Password</label>
                              </div>
                              {repeatPasswordErr && (
                                <div className="errorText">
                                  {repeatPasswordErrMsg}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      {nextPage === false && (
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="selection-page">
                              I want be an user
                              <span>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    checked={audience === "User"}
                                    onChange={() => setAudience("User")}
                                    value="User"
                                    name="inlineRadioOptions"
                                    id="inlineRadio1"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineRadio1"
                                  ></label>
                                </div>
                              </span>
                            </div>
                          </div>
                          <div className="col-12 col-md-12 mt-10">
                            <div className="selection-page">
                              I want to be an Artist
                              <span>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="inlineRadio1"
                                    checked={audience === "Artist"}
                                    onChange={() => setAudience("Artist")}
                                    value="Artist"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineRadio1"
                                  ></label>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      {nextPage && (
                        <div className="row">
                          <div className="col-12">
                            <div className="selection-page-2 mt-10">
                              Subscribe to our YouTube Channel
                              <div className="custom-control custom-checkbox mr-sm-2" style={{marginTop: 0}}>
                              <button
                                  className="btn btn-color w100 btnPadding"
                                  onClick={() => {
                                    setSubscribe(!subscribe);
                                    setSubscribeText("Subscribed");
                                    window.open(constant.youTubeSubscribeURL);
                                  }}
                                >
                                  {subscribeText}
                                </button>
                                </div>
                              {/* <span>
                                <div className="custom-control custom-checkbox mr-sm-2">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="customControlAutosizing"
                                    onClick={() => setSubscribe(!subscribe)}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customControlAutosizing"
                                  ></label>
                                </div>
                              </span> */}
                            </div>
                          </div>
                        </div>
                      )}
                      {nextPage && (
                        <div className="row mt-3">
                          <div className="col-md-auto col-auto">
                            <div className="custom-control custom-checkbox mr-sm-2">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customControlAutosizing1"
                                onClick={() => {
                                  setTermConditions(!termConditions);
                                }}
                              />
                              <label
                                className="custom-control-label iagreewith"
                                htmlFor="customControlAutosizing1"
                              >
                                I agree with{" "}
                                <span className="terms-hihglight">
                                  Terms & Condition
                                </span>
                              </label>
                            </div>
                            {termConditionsErr === true && (
                              <div
                                className="errorText"
                                style={{ marginTop: "0px" }}
                              >
                                {constant.tickOnTermCondition}
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      <div className="row">
                        {nextPage === true && (
                          <div className="col-4">
                            <button
                              className="btn btn-color w100 btnPadding"
                              onClick={() => {
                                setProfilePictureErrMsg("");
                                setnextPage(false);
                              }}
                            >
                              Back
                            </button>
                          </div>
                        )}
                        <div className={nextPage === true ? "col-8" : "col-12"}>
                          <button
                            className="btn btn-color w100 btnPadding"
                            onClick={() => {
                              nextPage === true
                                ? onSignup()
                                : setnextPage(true);
                            }}
                          >
                            {nextPage === true ? " Create Account" : "Continue"}
                          </button>
                        </div>
                      </div>
                      <div className="option-text">Or</div>
                      <div className="option-text">
                        I'm already a User.{" "}
                        <span
                          className="login-highligh"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            history.push("login");
                          }}
                        >
                          Login
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default CreateAccount;
